.Enterprise .section-1 {
  background-image: url(/assets/img/enterprise-img/enterprise-1-img.png) !important;
  background-position: right!important;
  height: 100%;
  background-size: cover;
  display: flex;
  align-items: center;
}
.Enterprise .protfolio-ul {
  padding: 0px;
  margin-bottom: 0px;
}
.Enterprise .protfolio-ul li {
  list-style: none;
  margin-bottom: 30px;
}
.Enterprise .protfolio-ul li .portfolio-cards {
  width: 475px;
  min-height: 300px;
  background: #FFFFFF;
  box-shadow: 3px 3px 0px #FFD028, 2px 2px 5px rgba(30, 75, 94, 0.3);
  border-radius: 3px;
}
.Enterprise .protfolio-ul li .portfolio-card-info h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 37px;
  color: #1E4B5E;
  margin-top: 15px;
}
.Enterprise .protfolio-ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.tab-card {
  max-width: 75%;
  min-width: 75%;
  width: 75%;
  margin: auto;
}
@media only screen and (max-width: 980px) {
  .Enterprise .work-with-row {
    position: relative;
  }
  .Enterprise .work-with-row .work-with-col {
    flex: inherit;
    max-width: 100%;
  }
  .Enterprise .work-with-row .comp-info-right-col {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.25;
    position: absolute;
    left: 0;
    height: 100%;
    right: 0;
    top: 110px;
    flex: inherit;
    max-width: 100%;
    bottom: auto;
  }
  .Enterprise .our-process-col {
    margin-top: 40px;
  }
}
@media only screen and (max-width: 768px) {
  .Enterprise .company-info .company-offer-imgs svg {
    width: 300px;
    height: 300px;
  }
  .Enterprise .head-cap p {
    font-size: 30px;
  }
  .Enterprise .work-class ul li {
    width: 100%;
  }
}
@media only screen and (max-width: 678px) {
  .Enterprise .section-1 {
    background-image: url(/assets/img/enterprise-img/enterprise-mob-bg.png) !important;
    background-position: right!important;
    height: 100%;
    background-size: cover;
  }
}
@media only screen and (max-width: 425px) {
  .Startup .work-with-row .comp-info-right-col {
    top: 30px;
  }
  .work-class ul li {
    list-style-type: none;
    width: 100%!important;
  }
  .Enterprise .app-p {
    font-size: 20px;
  }
  .Enterprise .head-cap p {
    font-size: 30px;
  }
}
