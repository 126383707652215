.sales-sec .add-head h4 {
  color: #1e4b5e;
  font-weight: 600;
  font-size: 40px;
  font-family: "Heldane Display";
  margin-bottom: 0px;
}
.sales-sec .add-capt p,
.sales-sec .add-capt a {
  color: #1e4b5e;
  color: #007cc1;
  font-family: "Object Sans Heavy";
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 0px;
  text-decoration: underline;
}
.sales-sec .add-capt a:hover {
  text-decoration: underline;
}
.med-text {
  color: #7e97a1 !important;
  font-size: 18px !important;
  font-weight: 800 !important;
  text-decoration: none !important;
  margin-bottom: 0px;
}
.enqui-div {
  margin-top: 70px;
}
.enqui-div .margin-class {
  margin-bottom: 25px;
}
.enqui-div .main-head-div {
  margin-bottom: 30px;
}
.contact-us .sales-sec .add-head h4 {
  word-break: break-word;
}
.contact-us .top-section {
  background: linear-gradient(298.16deg, #1e4b5e 32.42%, #24a989 107.35%);
  background-size: cover;
  padding: 150px 0px 100px;
  position: relative;
  z-index: 1;
}
.contact-us .top-section::after {
  content: "";
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: url("/assets/img/contact-us/contact-bg.png");
  position: absolute;
  z-index: -1;
}
.contact-us .top-section .main-para-div {
  margin-top: 50px;
}
.contact-us .top-section .main-head-1 .primary-span {
  margin-bottom: 15px;
  color: #ffcc4a;
}
.contact-us .top-section .main-head-1 .secondary-span {
  font-size: 24px;
  font-weight: 400;
  color: #fff;
  font-family: "avenir_lt_std95_black";
  width: 100%;
}
.contact-us .top-section .main-head-1 .secondary-span.heading {
  color: #ffcc4a;
  font-size: 60px;
  margin-bottom: 30px;
  font-family: "avenir_lt_std95_black";
}
.contact-us .top-section .main-head-1 .secondary-span br {
  display: block !important;
}
.contact-us .top-section .contact-form {
  width: 75%;
  margin: 30px auto;
  background: #ffffff;
  padding: 30px;
}
.contact-us .top-section .contact-form .form-group {
  position: relative;
  text-align: left;
  margin-bottom: 40px;
  /* Optional: Change border color when input is focused */
}
.contact-us .top-section .contact-form .form-group:nth-of-type(3) {
  margin-bottom: 15px;
}
.contact-us .top-section .contact-form .form-group:nth-of-type(1) {
  margin-top: 30px;
}
.contact-us .top-section .contact-form .form-group textarea {
  min-height: 120px !important;
  max-height: 120px !important;
  line-height: 18px !important;
  font-size: 14px;
}
.contact-us .top-section .contact-form .form-group textarea::placeholder {
  color: #8ba1ab;
  font-size: 13px;
}
.contact-us .top-section .contact-form .form-group .error {
  color: #f00;
  position: absolute;
  top: 100%;
  left: 0;
  font-size: 10px;
}
.contact-us .top-section .contact-form .form-group:last-child {
  margin-bottom: 0;
}
.contact-us .top-section .contact-form .form-group button {
  border: 1px solid #ffcc4a;
  border-radius: 4px;
  padding: 12px 50px;
  font-size: 18px;
  background-color: #ffcc4a;
  color: #1e4b5e;
  margin-top: 0px;
  font-family: "avenir_lt_std95_black";
}
.contact-us .top-section .contact-form .form-group .label {
  font-size: 16px;
  transition: transform 0.3s ease-in-out;
  pointer-events: none;
  color: #1e4b5e;
  font-weight: 800;
  font-family: "avenir_lt_std95_black";
  position: absolute;
  top: -30px;
  transform: none !important;
  left: 0;
}
.contact-us .top-section .contact-form .form-group .input {
  width: 100%;
  padding: 10px 0;
  border: 0;
  background: transparent;
  border: 1px solid #ced4da;
  outline: none;
  resize: none;
  font-size: 14px;
  color: #8ba1ab;
  padding: 10px 15px;
}
.contact-us .top-section .contact-form .form-group .input::placeholder {
  color: #8ba1ab;
  font-size: 13px;
}
.contact-us .top-section .contact-form .form-group .input.select-box {
  color: #fff;
  font-size: 20px !important;
  line-height: 40px;
}
.contact-us .top-section .contact-form .form-group .input.select-box option {
  color: #000000;
  background: #ffffff;
  font-size: 20px !important;
}
.contact-us .top-section .contact-form .form-group .input.select-box:selected {
  color: #ffffff;
}
.contact-us .top-section .contact-form .form-group textarea {
  min-height: 45px;
  max-height: 45px;
  line-height: 18px;
  padding: 10px 15px;
}
.contact-us .top-section .contact-form .form-group .input:focus + .label,
.contact-us .top-section .contact-form .form-group .input.filled + .label {
  transform: translateY(-20px);
  font-size: 16px;
  /* optional: reduce font size when label is translated */
}
.contact-us .top-section .contact-form .error-message {
  position: absolute;
  bottom: 70px;
  color: red;
}
.contact-us .top-section .main-head-div {
  width: 100%;
  text-align: center;
  margin-bottom: 0px;
}
.contact-us .top-section .wizard-section {
  display: flex;
  margin: 50px 0px;
}
.contact-us .top-section .wizard-section .client-feedback {
  background: #153e4f;
  width: 30%;
  border-right: 1px solid rgba(255, 255, 255, 0.7);
  border-radius: 10px 0px 0px 10px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.contact-us .top-section .wizard-section .client-feedback span {
  color: #79c9bc;
  font-family: "Object Sans Heavy";
  font-size: 21px;
  display: inline-flex;
  justify-content: flex-start;
  text-align: left;
  position: relative;
  padding: 50px 20px 0px 65px;
}
.contact-us .top-section .wizard-section .client-feedback span::before {
  content: "";
  position: absolute;
  left: 5px;
  top: 20px;
  width: 69px;
  height: 56px;
  background: url("/assets/img/contact-us/contact-feedback-quote.png") no-repeat;
}
.contact-us .top-section .wizard-section .client-feedback .client-info {
  width: 100%;
  display: flex;
  padding: 15px;
  padding-bottom: 50px;
}
.contact-us .top-section .wizard-section .client-feedback .client-info .client-img {
  width: 72px;
  height: 72px;
  border-radius: 100%;
  box-shadow: 3px 3px 0px #ffd028, 2px 2px 5px rgba(30, 75, 94, 0.3);
  position: relative;
}
.contact-us .top-section .wizard-section .client-feedback .client-info .client-img::before {
  content: "";
  background: linear-gradient(90deg, #24a989 0%, rgba(30, 75, 94, 0) 70.11%);
  position: absolute;
  z-index: 0;
  width: 76px;
  height: 76px;
  border-radius: 100%;
}
.contact-us .top-section .wizard-section .client-feedback .client-info .client-img img {
  position: relative;
  z-index: 1;
}
.contact-us .top-section .wizard-section .client-feedback .client-info .client-detail {
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  text-align: left;
  padding-left: 15px;
}
.contact-us .top-section .wizard-section .client-feedback .client-info .client-detail strong {
  width: 100%;
  font-family: "Object Sans Heavy";
}
.contact-us .top-section .wizard-section .client-feedback .client-info .client-detail em {
  font-style: normal;
}
.contact-us .top-section .wizard-section .wizard-main-content {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background: #133f41;
  border-radius: 0px 10px 10px 0px;
  width: 70%;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  padding: 30px 50px;
  position: relative;
}
.new-ln-sp {
  display: inline-block;
}
.custom-add-row:last-child .add-capt p {
  max-width: 235px;
}
.add-group .add-capt .med-text {
  margin-top: 20px;
}
.contact-us .email-div {
  margin-top: 25px;
}
.section-4.sales-sec {
  position: relative;
}
.section-4.sales-sec::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: linear-gradient(195.37deg, #24a989 7.35%, #8ba1ab 89.22%);
  opacity: 0.1;
}
.section-4.sales-sec::after {
  content: "";
  position: absolute;
  top: 100px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: url("/assets/img/contact-us/world-bg.png") no-repeat;
  z-index: -1;
}
.section-4.sales-sec .connect-with-custom-section .card-sect {
  padding-left: 40px;
}
.section-4.sales-sec .connect-with-custom-section .custom-add-row {
  background: #fff;
  opacity: 1;
  width: 31%;
  max-width: 30%;
  flex: 0 0 30%;
  margin-right: 5%;
  margin-bottom: 5%;
  box-shadow: 2px 0px 0px #ffd028, 1px 1px 5px rgba(30, 75, 94, 0.6);
  padding: 50px 40px;
  padding-top: 100px;
  position: relative;
}
.section-4.sales-sec .connect-with-custom-section .custom-add-row h4 {
  position: absolute;
  background: #1e4b5e;
  border: 1px solid #ffcc4a;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  color: #fff;
  font-size: 20px;
  padding: 10px 15px;
  min-width: 150px;
  text-align: center;
  text-transform: uppercase;
  top: 20px;
  left: -20px;
  font-family: inherit;
  font-weight: initial;
}
.section-4.sales-sec .connect-with-custom-section .custom-add-row:nth-of-type(3) {
  margin-right: 0px;
}
.section-4.sales-sec .connect-with-custom-section .custom-add-row:nth-of-type(4),
.section-4.sales-sec .connect-with-custom-section .custom-add-row:nth-of-type(5) {
  margin-bottom: 0px;
}
.section-4.sales-sec .connect-with-custom-section .custom-add-row .add-capt p {
  color: #1e4b5e;
  font-size: 16px;
  font-family: "Object sans";
  text-decoration: none;
  font-weight: bold;
}
.section-4.sales-sec .connect-with-custom-section .custom-add-row .add-capt p br {
  display: block !important;
}
.section-4.sales-sec .connect-with-custom-section .custom-add-row .add-capt p:nth-of-type(2) {
  margin-top: 15px;
}
.section-4.sales-sec .connect-with-custom-section .custom-add-row em {
  display: none;
  background: #bde5de;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 5px 50px;
  color: #007cc1;
  font-style: normal;
  font-family: "Object Sans Heavy";
}
.contact-us .our-location {
  position: relative;
}
.contact-us .our-location::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  background: linear-gradient(254.54deg, #79c9bc -0.67%, #9ae8db 122.73%);
  opacity: 0.2;
}
.contact-us .our-location::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: url("/assets/img/contact-us/location-bg.png") no-repeat;
  height: 165px;
  background-size: 100%;
}
.contact-us .our-location .for-enquiries {
  padding-bottom: 150px;
}
.contact-us .our-location .for-enquiries .col-lg-4 {
  background: #1e4b5e;
  border: 1px solid #24a989;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  padding: 40px;
  width: 30%;
  max-width: 30%;
  margin-right: 5%;
}
.contact-us .our-location .for-enquiries .col-lg-4:nth-of-type(3) {
  margin-right: 0px;
}
.contact-us .our-location .for-enquiries .col-lg-4 strong {
  color: #fff;
  font-family: "Heldane Test";
  font-size: 26px;
}
.contact-us .our-location .for-enquiries .col-lg-4 .phone-num {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
}
.contact-us .our-location .for-enquiries .col-lg-4 .phone-num b {
  color: #ced4da;
  font-weight: 400;
  width: 100%;
}
.contact-us .our-location .for-enquiries .col-lg-4 .phone-num span,
.contact-us .our-location .for-enquiries .col-lg-4 .phone-num a {
  color: #24a989;
  font-family: "Object Sans Heavy";
  width: 100%;
  display: block;
  margin-top: 5px;
  word-break: break-all;
  text-decoration: underline;
}
.custom-contact-form {
  max-width: 50%;
  border-radius: 5px;
}
@media only screen and (max-width: 860px) {
  .custom-contact-form {
    width: 100% !important;
    max-width: 80%;
  }
}
@media only screen and (max-width: 1280px) {
  .sales-sec .add-group .add-head h4 {
    font-size: 32px;
  }
  .card-sect .add-head h4 {
    font-size: 32px;
  }
  .contact-us .our-location:after {
    background-size: cover;
  }
}
@media only screen and (max-width: 1024px) {
  .sales-sec .add-group .add-head h4 {
    font-size: 28px;
  }
  .contact-us .top-section .wizard-section {
    flex-direction: column;
  }
  .contact-us .top-section .wizard-section .client-feedback {
    width: 100%;
  }
  .contact-us .top-section .wizard-section .wizard-main-content {
    width: 100%;
  }
  .section-4.sales-sec .connect-with-custom-section .custom-add-row,
  .section-4.our-location .connect-with-custom-section .col-lg-4 {
    width: 45%;
    max-width: 45%;
    flex: 0 0 45%;
    margin-bottom: 5%;
  }
  .section-4.sales-sec .connect-with-custom-section .custom-add-row:nth-of-type(3),
  .section-4.our-location .connect-with-custom-section .col-lg-4:nth-of-type(3) {
    margin-right: 5%;
  }
  .section-4.sales-sec .connect-with-custom-section .custom-add-row:nth-of-type(2n),
  .section-4.our-location .connect-with-custom-section .col-lg-4:nth-of-type(2n) {
    margin-right: 0px;
  }
  .contact-us .top-section .wizard-section .client-feedback {
    border-radius: 10px 10px 0px 0px;
  }
  .contact-us .top-section .wizard-section .wizard-main-content {
    border-radius: 0px 0px 10px 10px;
  }
  .contact-us .top-section .wizard-section .client-feedback {
    border-right: 0px;
  }
  div.wizard-wrapper .wizard-content .wizard-detail .err-msg {
    font-size: 12px;
    line-height: 20px;
    text-align: left;
  }
  div.wizard-wrapper .wizard-content .wizard-detail .err-msg-info {
    position: static;
    margin-top: 15px;
  }
  .section-4.sales-sec .connect-with-custom-section .custom-add-row:nth-of-type(4) {
    margin-bottom: 10%;
  }
}
@media only screen and (max-width: 768px) {
  .contact-us .top-section .main-head-1 .secondary-span br {
    display: none !important;
  }
  .contact-us .top-section .main-head-1 .secondary-span {
    width: 100%;
  }
}
@media only screen and (max-width: 576px) {
  .contact-us .email-div {
    margin-top: 0px;
  }
  .custom-contact-form {
    max-width: none !important;
  }
}
@media only screen and (max-width: 480px) {
  .contact-us .top-section .wizard-section .client-feedback .client-info {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .contact-us .top-section .wizard-section .client-feedback .client-info .client-detail {
    padding-left: 0px;
    margin-top: 15px;
    text-align: center;
  }
  .contact-us .top-section .wizard-section .client-feedback .client-info .client-detail em {
    width: 100%;
  }
  .contact-us .top-section .wizard-section .client-feedback {
    border-right: 0px;
  }
  .contact-us .top-section .wizard-section .wizard-main-content {
    padding: 40px 15px 20px;
  }
  div.wizard-wrapper .wizard-content .count {
    right: 15px;
    top: 15px;
    font-size: 14px;
  }
  div.wizard-wrapper .wizard-content .next-btn {
    right: 15px;
  }
  div.wizard-wrapper .wizard-content .prev-btn {
    right: 45px;
  }
  div.wizard-wrapper .wizard-content .wizard-detail input,
  .wizard-wrapper .wizard-content .wizard-detail select {
    font-size: 13px;
  }
  div.wizard-wrapper .wizard-content .wizard-detail label {
    font-size: 13px;
  }
  .contact-us .top-section .wizard-section .client-feedback span {
    padding: 50px 20px 0px 20px;
  }
  .section-4.sales-sec .connect-with-custom-section .custom-add-row,
  .section-4.our-location .connect-with-custom-section .col-lg-4 {
    width: 100%;
    max-width: 100% !important;
    flex: 0 0 100%;
    margin-bottom: 10%;
    margin-right: 0px;
  }
  .section-4.sales-sec .connect-with-custom-section .custom-add-row:nth-of-type(3),
  .section-4.our-location .connect-with-custom-section .col-lg-4:nth-of-type(3) {
    margin-right: 0px;
  }
  .section-4.sales-sec .connect-with-custom-section .custom-add-row:nth-of-type(2n),
  .section-4.our-location .connect-with-custom-section .col-lg-4:nth-of-type(2n) {
    margin-right: 0px;
  }
}
