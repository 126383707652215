.Header {
  /* Position and sizing of burger button */
  /* Color/shape of burger icon bars */
  /* Color/shape of burger icon bars on hover*/
  /* Position and sizing of clickable cross button */
  /*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
  /* General sidebar styles */
  /* Morph shape necessary with bubble or elastic */
  /* Wrapper for item list */
  /* Individual item */
  /* Styling of overlay */
  /* Color/shape of burger icon bars */
  /* Position and sizing of clickable cross button */
  /* Morph shape necessary with bubble or elastic */
  /* Wrapper for item list */
  /* Individual item */
  /* Styling of overlay */
}
.Header .right-menu {
  display: inline-flex;
  align-items: center;
}
.Header .right-menu a.connect-btn {
  border: 2px solid #007CC1;
  border-radius: 3px;
  font-size: 15px;
  font-family: "Object Sans heavy";
  color: #007CC1;
  margin-right: 30px;
  padding: 7px 28px;
  background: #ffffff;
  -webkit-transition: all 0.7s ease;
  transition: all 0.7s ease;
}
.Header .right-menu .ham-menu-hold {
  display: inline-flex;
  align-items: center;
  padding: 15px 0px 15px 15px;
}
.Header .bm-burger-button {
  position: relative;
  width: 45px;
  height: 29px;
  display: inline-flex;
}
.Header .mob-logo {
  display: none;
  transition: all 0.7s ease;
  opacity: 0;
}
.Header .resouce-logo-text {
  font-size: 14px;
  letter-spacing: 7px;
  color: #444544;
}
.Header .bm-burger-bars {
  background: #373a47;
}
.Header .bm-burger-bars-hover {
  background: #a90000;
}
.Header .bm-cross-button {
  height: 45px;
  width: 45px;
}
.Header .bm-menu-wrap {
  position: fixed;
  height: 100%;
  width: 50% !important;
  top: 0px;
}
.Header .bm-menu {
  background: #F9F9F9;
  box-shadow: -5px 0px 15px rgba(0, 0, 0, 0.25);
  padding: 40px 0px;
  font-size: 1.15em;
}
.Header .bm-morph-shape {
  fill: #373a47;
}
.Header .bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}
.Header .bm-item {
  display: inline-block;
}
.Header .bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
.Header .bm-icon {
  position: absolute;
  width: 45px !important;
  height: 45px !important;
}
.Header .bm-burger-button button {
  top: 10px !important;
  left: 3px !important;
}
.Header .bm-burger-bars {
  background: #282b35;
  height: 6% !important;
}
.Header .bm-cross-button {
  height: 24px;
  width: 24px;
  right: 30px !important;
  top: 20px !important;
}
.Header .bm-morph-shape {
  fill: #373a47;
}
.Header .bm-item-list {
  color: #b8b7ad;
  padding: 0px;
  display: flex;
  align-items: center;
}
.Header .bm-item {
  display: inline-block;
}
.Header .bm-item:focus {
  outline: none;
}
.Header.design-studio.digital-header.scrollHeader + .ham-menu .web-menu-list .menu-list ul li.dropdown .sub-menu {
  background: #ffffff;
}
.Header.design-studio.digital-header.scrollHeader + .ham-menu .web-menu-list .menu-list ul li.dropdown .sub-menu li a {
  color: #000000 !important;
}
.Header.design-studio.digital-header + .ham-menu .web-menu-list .menu-list ul li {
  font-weight: 600;
  font-family: 'Inter';
}
.Header.design-studio.digital-header + .ham-menu .web-menu-list .menu-list ul li a {
  font-family: 'Inter';
  font-size: 16px;
}
.Header.design-studio.digital-header + .ham-menu .web-menu-list .menu-list ul li a:hover {
  background: none !important;
}
.Header.design-studio.digital-header + .ham-menu .web-menu-list .menu-list ul li.dropdown a:hover {
  background: transparent !important;
}
.Header.design-studio.digital-header + .ham-menu .web-menu-list .menu-list ul li.dropdown .sub-menu {
  top: 35px;
  background: linear-gradient(90.95deg, rgba(255, 255, 255, 0.5) -6.34%, rgba(255, 255, 255, 0.2) 90.34%);
  border-radius: 10px;
  z-index: 1;
  border-right: 1px solid #1dc1b3cc;
  border-left: 1px solid #dc477fcc;
  border-top: 1px solid #1dc1b3cc;
  border-bottom: 1px solid #dc477fcc;
  background-image: linear-gradient(90deg, #1dc1b3cc, #dc477fcc), linear-gradient(90deg, #1dc1b3cc, #dc477fcc);
  background-size: 100% 1px;
  background-position: 0 0, 0 100%;
  background-repeat: no-repeat;
}
.Header.design-studio.digital-header + .ham-menu .web-menu-list .menu-list ul li.dropdown .sub-menu::after {
  content: "";
  position: absolute;
  background: linear-gradient(90.9deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.1) 100%);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0px;
  border-radius: 10px;
  z-index: -1;
}
.Header.design-studio.digital-header + .ham-menu .web-menu-list .menu-list ul li.dropdown .sub-menu li:hover {
  background: transparent !important;
}
.Header.design-studio.digital-header + .ham-menu .web-menu-list .menu-list ul li.dropdown .sub-menu li:hover a {
  background: transparent !important;
}
.Header.design-studio.digital-header + .ham-menu .web-menu-list .menu-list ul li.dropdown .sub-menu li a {
  border-bottom: 0px;
  position: relative;
  color: #ffffff !important;
}
.Header.design-studio.digital-header + .ham-menu .web-menu-list .menu-list ul li.dropdown .sub-menu li a::after {
  content: "";
  position: absolute;
  width: 80%;
  margin: auto;
  border: 1px solid #ABABAB;
  bottom: 0px;
  left: 10%;
  right: 10%;
}
.Header.design-studio.digital-header + .ham-menu .web-menu-list .menu-list ul li.dropdown .sub-menu li:last-child a::after {
  display: none;
}
.Header.design-studio .right-menu .connect-btn {
  position: relative;
  background: #000000 !important;
  z-index: 1;
  color: #ffffff;
  box-shadow: 0px 5px 20px 0px #D84D8133;
}
.Header.design-studio .right-menu .connect-btn::before {
  content: "";
  position: absolute;
  left: -1px;
  right: -1px;
  top: -1px;
  bottom: -1px;
  margin: -1px;
  z-index: -1;
  border-radius: 5px;
  background: linear-gradient(85.48deg, rgba(216, 77, 129, 0.8) 0%, rgba(43, 195, 182, 0.8) 98.61%);
}
.Header.design-studio .right-menu .connect-btn::after {
  content: "";
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  margin: auto;
  z-index: -1;
  background: #000000;
  border-radius: 5px;
}
.menu-list .nav-item {
  transition: all 0.5s ease;
}
.menu-list .nav-item .nav-link {
  font-family: 'Object Sans Heavy';
  font-size: 24px;
  line-height: 25px;
  color: #1E4B5E;
  padding: 12px 50px;
  margin: 8px 0px;
  position: relative;
  transition: all 0.5s ease;
}
.menu-list .nav-item .nav-link:hover {
  color: #007CC1;
}
.menu-list .nav-item .nav-link.active {
  color: #24A989;
}
.menu-list .nav-item .nav-link.active::before {
  position: absolute;
  content: '';
  background: #24A989;
  width: 10px;
  height: 100%;
  display: inline-block;
  top: 0px;
  left: 0px;
}
.Header {
  -webkit-transition: all 0.7s ease;
  transition: all 0.7s ease;
}
.Header.scrollHeader {
  -webkit-transition: all 0.7s ease;
  transition: all 0.7s ease;
  position: fixed;
  width: 100%;
  z-index: 1;
}
.Header .custom-inx-logo,
.Header .bm-burger-button {
  -webkit-transition: all 0.7s ease;
  transition: all 0.7s ease;
}
.Header .bm-menu-wrap {
  transition: all 0.7s ease;
  width: 50% !important;
  top: 0px;
  transform: translate3d(800px, 0px, 0px) !important;
  /* right: 0!important; */
}
.Header .bm-overlay {
  width: 100%!important;
  right: 0px;
  top: 0;
}
.active div#sidebar {
  right: 0!important;
  transform: translate3d(0px, 0px, 0px) !important;
}
.custom-inx-logo {
  cursor: pointer;
}
.Header .onscroll-logo {
  display: none;
}
.Header.scrollHeader .custom-inx-logo .onscroll-logo {
  display: none;
}
.custom-inx-navbar {
  transition: all ease-in 0.7s;
  background: rgba(255, 255, 255, 0.15);
  padding-top: 90px;
}
div.digital-header.Header .custom-inx-navbar {
  background: transparent;
}
div.digital-header.scrollHeader div.custom-inx-navbar {
  background: rgba(0, 0, 0, 0.25) !important;
}
.Header.scrollHeader:hover .custom-inx-navbar {
  background: rgba(255, 255, 255, 0.27);
  transition: all ease-in 0.7s;
}
.tool-banner {
  background: linear-gradient(90deg, #482e70 0%, #010d50 47%, #482e70 100%);
  padding: 14px 30px;
  background-size: 100%;
  position: fixed;
  top: 0;
  text-align: center;
  z-index: 1;
  width: 100%;
}
.tool-banner span {
  color: #ffffff;
  font-family: "Object Sans";
  font-size: 16px;
}
.tool-banner span em {
  font-style: normal;
  text-decoration: underline;
  margin-left: 20px;
  cursor: pointer;
}
.header-label ul {
  list-style-type: none;
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #fff;
  padding: 7px 30px;
  background: #1E4B5E;
  margin: 0;
  transition: all ease-in 0.7s;
  position: relative;
  top: 0;
}
.Header.contact-us-page + .ham-menu .web-menu-list {
  right: 50px;
}
.custom-inx-navbar.kyc-container {
  top: 0px;
}
.header-label ul li p span img {
  width: 11px;
  margin-right: 5px;
}
.header-label ul li p {
  margin: 0;
}
.header-label ul li label {
  margin: 0;
}
.header-label ul li:last-child {
  margin-left: auto;
}
.Header .right-menu .mobile-support-menu {
  display: none;
}
@media only screen and (max-width: 768px) {
  .Header .right-menu a.connect-btn {
    font-size: 14px;
    padding: 8px 20px;
    margin-right: 15px;
  }
}
@media only screen and (max-width: 1100px) {
  .tool-banner span {
    font-size: 14px;
  }
}
@media only screen and (max-width: 1024px) {
  .Header .right-menu .mobile-support-menu {
    display: block;
  }
  .tool-banner span {
    font-size: 12px;
  }
  .tool-banner {
    padding: 7px 30px;
  }
  div.custom-inx-navbar.home-header {
    top: 38px;
  }
}
@media only screen and (max-width: 768px) {
  .tool-banner span {
    font-size: 12px;
  }
  div.custom-inx-navbar.kyc-container.home-header {
    top: 46px;
  }
  .tool-banner {
    padding: 16px 24px;
    line-height: 12px;
    left: 0;
    right: 0;
    width: 100%;
    margin: auto;
    text-align: center;
  }
}
@media only screen and (max-width: 600px) {
  div.custom-inx-navbar.kyc-container.home-header {
    top: 31px;
  }
}
