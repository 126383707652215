.new_portfolio {
  padding-top: 90px;
}
.new_portfolio .new_portfolio-container {
  width: 90%;
  min-width: 90%;
  max-width: 90%;
  margin: 0 auto;
}
.new_portfolio .new_portfolio-container p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.new_portfolio .new_portfolio-container ul {
  list-style: none;
  padding: 0;
}
.new_portfolio .executive-summary .summery-key {
  position: absolute;
  left: 10px;
  top: 0px;
  bottom: 0;
  margin: auto;
  z-index: 0;
  opacity: 0.2;
  width: 210px;
}
.new_portfolio .executive-summary h5 {
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  display: flex;
}
.new_portfolio .executive-summary h5 img {
  width: 200px;
  margin-left: 20px;
}
.new_portfolio .executive-summary p {
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  margin: 20px 0 0 0;
}
.new_portfolio .layer_bg {
  position: relative;
}
.new_portfolio .layer_bg .executive-layers {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  z-index: -1;
}
.new_portfolio .layer_bg .executive-layers span {
  display: block;
  height: 100%;
}
.stickyNotePostTrade {
  background: #fff;
  box-shadow: 0 0 15px #ddd;
  border-radius: 13px 0px 0px 13px;
  position: fixed;
  width: 150px;
  padding: 10px 20px;
  right: 0;
  top: 300px;
  z-index: 99;
}
.stickyNotePostTrade a {
  font-size: 14px;
  color: #000A4F;
}
.new_caseStudy_DriveWealth .section-1 {
  background-image: url('/assets/img/case-study-images/Frame.png');
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
}
.new_caseStudy_DriveWealth .section-1 .banner_sec {
  margin: 60px 0 100px;
}
.new_caseStudy_DriveWealth .section-1 .banner_sec img {
  width: 240px;
  margin-bottom: 30px;
}
.new_caseStudy_DriveWealth .section-1 .banner_sec h1 {
  font-size: 54px;
  font-weight: 400;
  line-height: 62px;
  letter-spacing: 0em;
  text-align: left;
  color: #00AEEF;
  margin-bottom: 30px !important;
}
.new_caseStudy_DriveWealth .section-1 .banner_sec h2 {
  color: #fff;
  font-size: 40px;
  font-weight: 400;
  line-height: 57px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 30px !important;
}
.new_caseStudy_DriveWealth .section-1 .banner_sec p {
  font-size: 24px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  margin-bottom: 50px;
}
.new_caseStudy_DriveWealth .section-1 .banner_sec button {
  border: 0;
  font-size: 22px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0px;
  padding: 10px;
  border-radius: 3px;
  width: 200px;
  text-align: center;
}
.new_caseStudy_DriveWealth .section-2 {
  padding: 50px 0;
}
.new_caseStudy_DriveWealth .section-2 .executive-layers span.bg_1 {
  background: #2456B5;
  width: 5%;
}
.new_caseStudy_DriveWealth .section-2 .executive-layers span.bg_2 {
  background: #1F458D;
  width: 10%;
}
.new_caseStudy_DriveWealth .section-2 .executive-layers span.bg_3 {
  background: #193873;
  width: 15%;
}
.new_caseStudy_DriveWealth .section-2 .executive-layers span.bg_4 {
  background: #152C5A;
  width: 15%;
}
.new_caseStudy_DriveWealth .section-2 .executive-layers span.bg_5 {
  background: #0D1D3C;
  width: 15%;
}
.new_caseStudy_DriveWealth .section-2 .executive-layers span.bg_6 {
  background: #151921;
  width: 40%;
}
.new_caseStudy_DriveWealth .section-2 h2 {
  font-size: 30px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0;
  color: #fff;
  margin-top: 0;
}
.new_caseStudy_DriveWealth .section-3 {
  background: #fff;
}
.new_caseStudy_DriveWealth .section-3 .executive-layers span.bg_1 {
  background: #2456B5;
  width: 5%;
}
.new_caseStudy_DriveWealth .section-3 .executive-layers span.bg_2 {
  background: #1F458D;
  width: 10%;
}
.new_caseStudy_DriveWealth .section-3 .executive-layers span.bg_3 {
  background: #193873;
  width: 15%;
}
.new_caseStudy_DriveWealth .section-3 .executive-layers span.bg_4 {
  background: #152C5A;
  width: 15%;
}
.new_caseStudy_DriveWealth .section-3 .executive-layers span.bg_5 {
  background: #0D1D3C;
  width: 15%;
}
.new_caseStudy_DriveWealth .section-3 .executive-layers span.bg_6 {
  background: #151921;
  width: 40%;
}
.new_caseStudy_DriveWealth .section-3 p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000BF;
}
.new_caseStudy_DriveWealth .section-3 p:nth-child(3) {
  color: #000;
  font-weight: 700;
}
.new_caseStudy_DriveWealth .section-3 .banner-heading-bg-black {
  background: #283349;
  color: #fff;
  font-size: 26px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  padding: 10px;
  margin: 30px 0 0;
}
.new_caseStudy_DriveWealth .section-3 .outcomes {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #2456B5;
  display: flex;
  align-items: flex-start;
  padding: 20px 0;
}
.new_caseStudy_DriveWealth .section-3 .outcomes svg {
  margin: 0px 10px 0 0;
  width: 18px;
  min-width: 18px;
}
.new_caseStudy_DriveWealth .section-3 .outcome-info {
  color: #000000;
  border: 1px solid #1AB372;
  padding: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 4px;
  display: flex;
  align-items: start;
  margin-top: 20px;
}
.new_caseStudy_DriveWealth .section-3 .outcome-info img {
  margin: 2px 10px 0 0;
  width: 16px;
  min-width: 18px;
}
.new_caseStudy_DriveWealth .section-4 .executive-layers span.bg_1 {
  background: #0C2056;
  width: 15%;
}
.new_caseStudy_DriveWealth .section-4 .executive-layers span.bg_2 {
  background: #0A183F;
  width: 15%;
}
.new_caseStudy_DriveWealth .section-4 .executive-layers span.bg_3 {
  background: #071029;
  width: 15%;
}
.new_caseStudy_DriveWealth .section-4 .executive-layers span.bg_4 {
  background: #050B1D;
  width: 15%;
}
.new_caseStudy_DriveWealth .section-4 .executive-layers span.bg_5 {
  background: linear-gradient(180deg, #03060E 0%, #000000 100%), linear-gradient(0deg, #071437, #071437);
  width: 40%;
}
.new_caseStudy_DriveWealth .section-5 {
  position: relative;
  padding: 0 0 100px 0;
  background-color: #fff;
}
.new_caseStudy_DriveWealth .section-5:before {
  content: '';
  position: absolute;
  left: -80px;
  top: 0;
  width: 70px;
  height: 200px;
  border-radius: 100%;
  box-shadow: 100px 40px 150px #1a7cb3;
}
.new_caseStudy_DriveWealth .section-5 .features {
  position: relative;
  padding: 10px;
}
.new_caseStudy_DriveWealth .section-5 .features::before {
  content: '';
  border-right: 1px solid #CECECE;
  position: absolute;
  left: 99px;
  width: 1px;
  height: 100px;
}
.new_caseStudy_DriveWealth .section-5 .features::after {
  content: '';
  position: absolute;
  left: 0;
  height: 68%;
  width: 1px;
  background: #cecece;
  bottom: 0;
}
.new_caseStudy_DriveWealth .section-5 .features .svg-box {
  position: relative;
  padding-top: 70px;
}
.new_caseStudy_DriveWealth .section-5 .features .svg-box svg {
  height: 75px;
  width: 75px;
}
.new_caseStudy_DriveWealth .section-5 .features .svg-box::before {
  content: '';
  width: 70px;
  height: 100px;
  border: 1px solid #CECECE;
  border-radius: 0 0px 40% 0px;
  border-left: 0;
  border-top: 0;
  display: block;
  position: absolute;
  left: 20px;
}
.new_caseStudy_DriveWealth .section-5 .features h5 {
  position: relative;
  padding-top: 70px;
}
.new_caseStudy_DriveWealth .section-5 .features h5::before {
  content: '';
  width: 50px;
  display: block;
  height: 40vh;
  border: 1px solid #CECECE;
  border-radius: 25px 0 0 0;
  border-right: 0;
  border-bottom: 0;
  position: absolute;
  top: 24.6px;
  left: -10px;
}
.new_caseStudy_DriveWealth .section-5 .features ul {
  list-style: none;
  padding: 0;
  margin: 50px 0 0 0;
}
.new_caseStudy_DriveWealth .section-5 .features ul li {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  border-top: 1px solid #2254E3;
  padding: 12px 0;
  margin-top: 5px;
}
.new_caseStudy_DriveWealth .section-5 .features ul li:first-child {
  border-top: 0;
  margin-bottom: 10px;
}
.new_caseStudy_DriveWealth .section-6 .executive-layers span.bg_1 {
  background: #0E5B87;
  width: 5%;
}
.new_caseStudy_DriveWealth .section-6 .executive-layers span.bg_2 {
  background: #0C3B56;
  width: 15%;
}
.new_caseStudy_DriveWealth .section-6 .executive-layers span.bg_3 {
  background: #0A2C3F;
  width: 17%;
}
.new_caseStudy_DriveWealth .section-6 .executive-layers span.bg_4 {
  background: #001622;
  width: 17%;
}
.new_caseStudy_DriveWealth .section-6 .executive-layers span.bg_5 {
  background: #05141D;
  width: 17%;
}
.new_caseStudy_DriveWealth .section-6 .executive-layers span.bg_6 {
  background: #030A0E;
  width: 29%;
}
.new_caseStudy_DriveWealth .section-6 .executive-summary .summery-key {
  width: 180px;
  opacity: 0.4;
}
.new_caseStudy_DriveWealth .section-7 {
  padding: 50px 0;
}
.new_caseStudy_DriveWealth .section-7 .processing_steps {
  padding: 30px;
  width: 80%;
}
.new_caseStudy_DriveWealth .section-7 .processing_steps svg {
  margin-bottom: 20px;
}
.new_caseStudy_DriveWealth .section-7 .processing_steps h3 {
  font-size: 22px;
  font-weight: 500;
  line-height: 31px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #0E5B87;
}
.new_caseStudy_DriveWealth .section-7 .processing_steps p {
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: justify;
  color: #000;
}
.new_caseStudy_DriveWealth .section-8 .executive-layers span.bg_1 {
  background: #870E0E;
  width: 4%;
}
.new_caseStudy_DriveWealth .section-8 .executive-layers span.bg_2 {
  background: #560C0C;
  width: 16%;
}
.new_caseStudy_DriveWealth .section-8 .executive-layers span.bg_3 {
  background: #3F0A0A;
  width: 17%;
}
.new_caseStudy_DriveWealth .section-8 .executive-layers span.bg_4 {
  background: #290707;
  width: 17%;
}
.new_caseStudy_DriveWealth .section-8 .executive-layers span.bg_5 {
  background: #1D0505;
  width: 17%;
}
.new_caseStudy_DriveWealth .section-8 .executive-layers span.bg_6 {
  background: #03060E;
  width: 29%;
}
.new_caseStudy_DriveWealth .section-8 .executive-summary .summery-key {
  width: 180px;
  opacity: 0.4;
}
.new_caseStudy_DriveWealth .section-9 {
  padding: 100px 0;
}
.new_caseStudy_DriveWealth .section-9 .detailed-breakdown {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 30px;
}
.new_caseStudy_DriveWealth .section-9 .deliver-manager {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}
.new_caseStudy_DriveWealth .section-9 .deliver-manager img {
  border-radius: 10px;
  background: #BCBCBC;
}
.new_caseStudy_DriveWealth .section-9 .deliver-manager .about {
  padding: 15px 15px;
  border: 1px solid #DDDDDD;
  border-radius: 11px;
  box-shadow: 0 0 10px #eaeaea;
  width: 88%;
  margin-left: 20px;
  position: relative;
}
.new_caseStudy_DriveWealth .section-9 .deliver-manager .about:before {
  content: '';
  position: absolute;
  left: 1px;
  bottom: -10px;
  width: 103%;
  height: 90px;
  background: #fff;
  z-index: 0;
}
.new_caseStudy_DriveWealth .section-9 .deliver-manager .about:after {
  content: '';
  position: absolute;
  left: 12px;
  bottom: -2px;
  width: 90%;
  height: 20px;
  background: #fbfbfb;
  box-shadow: 0 9px 40px 0px #0000004D;
  border-radius: 60%;
  z-index: -1;
}
.new_caseStudy_DriveWealth .section-9 .deliver-manager .about p {
  margin-bottom: 3px;
  position: relative;
}
.new_caseStudy_DriveWealth .section-9 .deliver-manager .about h6 {
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0;
  z-index: 1;
  position: relative;
}
.new_caseStudy_DriveWealth .section-9 .legacy-architeture {
  width: 80%;
}
.new_caseStudy_DriveWealth .section-9 .legacy-architeture h6 {
  font-family: HelveticaNeue;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 20px;
}
.new_caseStudy_DriveWealth .section-9 .legacy-architeture h6 span {
  color: #D72626;
}
.new_caseStudy_DriveWealth .section-9 .legacy-architeture p {
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: justify;
}
.new_caseStudy_DriveWealth .section-9 .legacy-architeture.mr-autto {
  margin-left: auto;
}
.new_caseStudy_DriveWealth .section-10 {
  display: flex;
  align-items: center;
  padding: 100px 0;
}
.new_caseStudy_DriveWealth .section-10 .executive-layers span.bg_1 {
  background: #0E873F;
  width: 3%;
}
.new_caseStudy_DriveWealth .section-10 .executive-layers span.bg_2 {
  background: #0C5637;
  width: 16%;
}
.new_caseStudy_DriveWealth .section-10 .executive-layers span.bg_3 {
  background: #0A3F29;
  width: 17%;
}
.new_caseStudy_DriveWealth .section-10 .executive-layers span.bg_4 {
  background: #07291F;
  width: 17%;
}
.new_caseStudy_DriveWealth .section-10 .executive-layers span.bg_5 {
  background: #051C1D;
  width: 17%;
}
.new_caseStudy_DriveWealth .section-10 .executive-layers span.bg_6 {
  background: #030E0E;
  width: 29%;
}
.new_caseStudy_DriveWealth .section-11 {
  padding: 100px 0;
}
.new_caseStudy_DriveWealth .section-11 .team-composition svg {
  width: 100%;
}
.new_caseStudy_DriveWealth .section-11 .team-composition label {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  font-size: 14px;
}
.new_caseStudy_DriveWealth .section-11 .team-composition label svg {
  width: 20px;
  height: 20px;
}
.new_caseStudy_DriveWealth .section-11 .team-composition label span {
  padding: 10px;
}
.new_caseStudy_DriveWealth .section-11 .tech-stach {
  display: flex;
  align-items: center;
}
.new_caseStudy_DriveWealth .section-11 .tech-stach p {
  font-size: 26px;
  font-weight: 300;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
  margin: 0;
  width: 30%;
}
.new_caseStudy_DriveWealth .section-11 .tech-stach ul {
  list-style: none;
  display: flex;
  flex-flow: wrap;
  padding: 0;
  width: 70%;
}
.new_caseStudy_DriveWealth .section-11 .tech-stach ul li {
  width: 40%;
  background: #EEFAF3;
  margin: 20px auto;
  padding: 20px;
  border-radius: 40px 0 40px;
  font-size: 14px;
  font-weight: 600;
}
.new_caseStudy_DriveWealth .section-11 .tech-stach ul li span {
  display: block;
  font-weight: 400;
}
.new_caseStudy_DriveWealth .section-12 {
  padding: 100px 0;
}
.new_caseStudy_DriveWealth .section-12 .summery-key {
  width: 180px;
}
.new_caseStudy_DriveWealth .section-12 .executive-layers span.bg_1 {
  background: #0A4660;
  width: 15%;
}
.new_caseStudy_DriveWealth .section-12 .executive-layers span.bg_2 {
  background: #063042;
  width: 15%;
}
.new_caseStudy_DriveWealth .section-12 .executive-layers span.bg_3 {
  background: #04212D;
  width: 15%;
}
.new_caseStudy_DriveWealth .section-12 .executive-layers span.bg_4 {
  background: #02141C;
  width: 15%;
}
.new_caseStudy_DriveWealth .section-12 .executive-layers span.bg_5 {
  background: #020C10;
  width: 40%;
}
.new_caseStudy_DriveWealth .section-13 {
  padding: 100px 0;
  position: relative;
}
.new_caseStudy_DriveWealth .section-13::before {
  content: '';
  position: absolute;
  left: -120px;
  top: 130px;
  width: 120px;
  height: 120px;
  box-shadow: 70px 10px 170px 0 #19AEE2;
  border-radius: 100%;
}
.new_caseStudy_DriveWealth .section-13 ul li {
  display: flex;
  align-items: center;
  padding: 20px 0 30px 0;
  border-bottom: 1px solid #C3C3C3;
}
.new_caseStudy_DriveWealth .section-13 ul li svg {
  margin-right: 15px;
}
.new_caseStudy_DriveWealth .section-13 ul li h6 {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  width: 30%;
}
.new_caseStudy_DriveWealth .section-13 ul li p {
  margin-bottom: 0;
  width: 70%;
}
.new_caseStudy_DriveWealth .section-14 {
  padding: 100px 0;
}
.new_caseStudy_DriveWealth .section-14 .executive-layers span.bg_1 {
  background: #59870E;
  width: 5%;
}
.new_caseStudy_DriveWealth .section-14 .executive-layers span.bg_2 {
  background: #3F600A;
  width: 17%;
}
.new_caseStudy_DriveWealth .section-14 .executive-layers span.bg_3 {
  background: #2B4206;
  width: 17%;
}
.new_caseStudy_DriveWealth .section-14 .executive-layers span.bg_4 {
  background: #1D2D04;
  width: 17%;
}
.new_caseStudy_DriveWealth .section-14 .executive-layers span.bg_5 {
  background: #121C02;
  width: 17%;
}
.new_caseStudy_DriveWealth .section-14 .executive-layers span.bg_6 {
  background: #0B1002;
  width: 40%;
}
.new_caseStudy_DriveWealth .section-15 {
  padding: 100px 0;
}
.new_caseStudy_DriveWealth .section-15 .work-maintanance p {
  margin-bottom: 25px;
}
.new_caseStudy_DriveWealth .section-15 .work-maintanance h5 {
  font-size: 17px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}
.new_caseStudy_DriveWealth .section-15 .work-maintanance h6 {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.new_caseStudy_DriveWealth .section-16 {
  padding: 100px 0;
}
.new_caseStudy_DriveWealth .section-16 .summery-key {
  width: 130px;
}
.new_caseStudy_DriveWealth .section-16 .executive-layers span.bg_1 {
  background: #875E0E;
  width: 5%;
}
.new_caseStudy_DriveWealth .section-16 .executive-layers span.bg_2 {
  background: #60430A;
  width: 17%;
}
.new_caseStudy_DriveWealth .section-16 .executive-layers span.bg_3 {
  background: #422E06;
  width: 17%;
}
.new_caseStudy_DriveWealth .section-16 .executive-layers span.bg_4 {
  background: #2D1F04;
  width: 17%;
}
.new_caseStudy_DriveWealth .section-16 .executive-layers span.bg_5 {
  background: #1C1302;
  width: 17%;
}
.new_caseStudy_DriveWealth .section-16 .executive-layers span.bg_6 {
  background: #100C02;
  width: 40%;
}
.new_caseStudy_DriveWealth .section-17 {
  padding: 100px 0;
}
.new_caseStudy_DriveWealth .section-17 .summery-key {
  width: 130px;
}
.new_caseStudy_DriveWealth .section-17 .executive-layers span.bg_1 {
  background: #9B3B36;
  width: 5%;
}
.new_caseStudy_DriveWealth .section-17 .executive-layers span.bg_2 {
  background: #701E1A;
  width: 17%;
}
.new_caseStudy_DriveWealth .section-17 .executive-layers span.bg_3 {
  background: #591814;
  width: 17%;
}
.new_caseStudy_DriveWealth .section-17 .executive-layers span.bg_4 {
  background: #400C09;
  width: 17%;
}
.new_caseStudy_DriveWealth .section-17 .executive-layers span.bg_5 {
  background: #2C0604;
  width: 17%;
}
.new_caseStudy_DriveWealth .section-17 .executive-layers span.bg_6 {
  background: #02100C;
  width: 40%;
}
.new_caseStudy_DriveWealth .section-18 {
  padding: 100px 0;
}
.new_caseStudy_DriveWealth .section-18 .summery-key {
  width: 130px;
  left: 30px;
}
.new_caseStudy_DriveWealth .section-18 .executive-layers span.bg_1 {
  background: #2C6981;
  width: 5%;
}
.new_caseStudy_DriveWealth .section-18 .executive-layers span.bg_2 {
  background: #225B72;
  width: 17%;
}
.new_caseStudy_DriveWealth .section-18 .executive-layers span.bg_3 {
  background: #18495C;
  width: 17%;
}
.new_caseStudy_DriveWealth .section-18 .executive-layers span.bg_4 {
  background: #113E50;
  width: 17%;
}
.new_caseStudy_DriveWealth .section-18 .executive-layers span.bg_5 {
  background: #0B3241;
  width: 17%;
}
.new_caseStudy_DriveWealth .section-18 .executive-layers span.bg_6 {
  background: #062430;
  width: 40%;
}
.new_caseStudy_DriveWealth .section-19 {
  box-shadow: 0px 4px 4px 0px #00000040;
  background: linear-gradient(180deg, #BEBEBE 0%, rgba(255, 255, 255, 0) 100%);
  padding: 100px 0;
}
.new_caseStudy_DriveWealth .section-19 .wrapping-up {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.new_caseStudy_DriveWealth .section-19 .wrapping-up .wrapping-up-img {
  width: 200px;
  margin-right: 20px;
}
.new_caseStudy_DriveWealth .section-19 .wrapping-up h6 {
  font-size: 25px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 16px;
}
.new_caseStudy_DriveWealth .section-19 .wrapping-up:last-child .wrapping_para {
  margin-right: 20px;
}
.new_caseStudy_DriveWealth .section-20 .background-bg {
  padding: 100px 0;
  background-image: url('/assets/img/case-study-images/Frame_2.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  display: flex;
  align-items: center;
}
.new_caseStudy_DriveWealth .section-20 .background-bg .new_portfolio-container {
  min-width: 250px;
  max-width: 250px;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row wrap;
  width: 200px;
  display: flex;
}
.new_caseStudy_DriveWealth .section-20 .background-bg .new_portfolio-container h3.customer {
  color: #fff;
  font-size: 35px;
  font-family: "Object Sans Heavy";
  font-weight: 700;
}
.new_caseStudy_DriveWealth .section-20 .background-bg .new_portfolio-container label {
  width: 100%;
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.04em;
  text-align: left;
  margin-bottom: 35px;
}
.new_caseStudy_DriveWealth .section-20 .background-bg .new_portfolio-container img {
  width: 200px;
}
.new_caseStudy_DriveWealth .section-20 .yellow-bg,
.new_caseStudy_DriveWealth .section-20 .blue-bg {
  background: #FEC109;
}
.new_caseStudy_DriveWealth .section-20 .yellow-bg .new_portfolio-container,
.new_caseStudy_DriveWealth .section-20 .blue-bg .new_portfolio-container {
  width: 75%;
  min-width: 75%;
  max-width: 75%;
  justify-content: flex-start;
}
.new_caseStudy_DriveWealth .section-20 .blue-bg {
  background: #01AEEF;
}
.new_caseStudy_DriveWealth .section-20 .blue-bg ul li a {
  color: #000000;
  font-size: 13px;
  font-weight: 500;
  line-height: 8px;
  letter-spacing: -0.04em;
  text-align: center;
}
.new_caseStudy_DriveWealth .section-20 .blue-bg ul li a img {
  width: 12px;
  height: 12px;
  min-width: 12px;
  max-width: 12px;
  margin-right: 5px;
}
.new_caseStudy_DriveWealth .section-20 .background-bg_2 {
  background-image: url('/assets/img/case-study-images/Frame_3.png');
  background-position: center;
}
@media only screen and (max-width: 991px) and (min-width: 320px) {
  .new_caseStudy_DriveWealth .section-7 .processing_steps,
  .new_caseStudy_DriveWealth .section-9 .legacy-architeture {
    width: 100%;
    padding: 20px;
  }
  .new_caseStudy_DriveWealth .section-9 .legacy-architeture {
    padding: 0;
  }
  .new_caseStudy_DriveWealth .section-11 .tech-stach {
    flex-flow: row wrap;
  }
  .new_caseStudy_DriveWealth .section-11 .tech-stach p,
  .new_caseStudy_DriveWealth .section-11 .tech-stach ul {
    width: 100%;
  }
  .new_caseStudy_DriveWealth .section-20 .background-bg {
    height: auto;
  }
}
@media only screen and (max-width: 767px) and (min-width: 320px) {
  .new_caseStudy_DriveWealth .section-1,
  .new_caseStudy_DriveWealth .section-2,
  .new_caseStudy_DriveWealth .section-3,
  .new_caseStudy_DriveWealth .section-5,
  .new_caseStudy_DriveWealth .section-9,
  .new_caseStudy_DriveWealth .section-10,
  .new_caseStudy_DriveWealth .section-11,
  .new_caseStudy_DriveWealth .section-12,
  .new_caseStudy_DriveWealth .section-13,
  .new_caseStudy_DriveWealth .section-14,
  .new_caseStudy_DriveWealth .section-15,
  .new_caseStudy_DriveWealth .section-19,
  .new_caseStudy_DriveWealth .section-20 .background-bg,
  .new_caseStudy_DriveWealth .layer_bg {
    padding: 50px 0;
  }
  .new_caseStudy_DriveWealth .section-1 .banner_sec {
    margin: 20px 0 50px;
  }
  .new_caseStudy_DriveWealth .section-1 .banner_sec img {
    width: 200px;
  }
  .new_caseStudy_DriveWealth .section-1 .banner_sec h1 {
    font-size: 54px;
    line-height: 42px;
  }
  .new_caseStudy_DriveWealth .section-1 .banner_sec h2 {
    font-size: 35px;
    line-height: 40px;
  }
  .new_caseStudy_DriveWealth .section-1 .banner_sec p {
    font-size: 20px;
    line-height: 8px;
    margin-bottom: 40px;
  }
  .new_caseStudy_DriveWealth .section-1 .banner_sec button {
    font-size: 20px;
  }
  .new_caseStudy_DriveWealth .section-7 .processing_steps {
    display: flex;
    flex-flow: row wrap;
    padding: 0;
  }
  .new_caseStudy_DriveWealth .section-7 .processing_steps svg {
    margin-right: 20px;
    margin-bottom: 0;
    position: relative;
    top: 9px;
  }
  .new_caseStudy_DriveWealth .section-7 .processing_steps h3 {
    width: 70%;
  }
  .new_caseStudy_DriveWealth .section-7 .processing_steps p {
    margin-left: 56px;
  }
  .new_caseStudy_DriveWealth .section-20 .background-bg_2,
  .new_caseStudy_DriveWealth .section-20 .background-bg {
    height: 300px;
  }
  .new_caseStudy_DriveWealth .section-20 .blue-bg {
    height: auto;
  }
  .new_caseStudy_DriveWealth .section-5 .features::after {
    height: 70%;
    top: 28%;
  }
}
@media only screen and (max-width: 567px) and (min-width: 320px) {
  .new_caseStudy_DriveWealth .executive-summary h5 {
    font-size: 25px;
    line-height: 35px;
    flex-flow: row wrap;
  }
  .new_caseStudy_DriveWealth .executive-summary h5 img {
    width: 140px;
    margin: 0;
  }
  .new_caseStudy_DriveWealth .section-1 .banner_sec {
    margin: 20px 0 50px;
  }
  .new_caseStudy_DriveWealth .section-1 .banner_sec h1 {
    font-size: 37px;
    line-height: 32px;
  }
  .new_caseStudy_DriveWealth .section-1 .banner_sec h2 {
    font-size: 28px;
    line-height: 30px;
  }
  .new_caseStudy_DriveWealth .section-1 .banner_sec p {
    font-size: 18px;
    line-height: 8px;
    margin-bottom: 30px;
  }
  .new_caseStudy_DriveWealth .section-1 .banner_sec button {
    font-size: 20px;
  }
  .new_caseStudy_DriveWealth .section-3 .banner-heading-bg-black {
    font-size: 18px;
  }
  .new_caseStudy_DriveWealth .section-7 .processing_steps h3 {
    font-size: 15px;
    line-height: 22px;
  }
  .new_caseStudy_DriveWealth .section-7 .processing_steps p {
    font-size: 14px;
  }
  .new_caseStudy_DriveWealth .section-9 {
    width: 100%;
  }
  .new_caseStudy_DriveWealth .section-9 .deliver-manager {
    flex-flow: row wrap;
    justify-content: center;
  }
  .new_caseStudy_DriveWealth .section-9 .deliver-manager img {
    margin-bottom: 20px;
    width: 120px;
  }
  .new_caseStudy_DriveWealth .section-9 .deliver-manager .about {
    width: 100%;
    margin: 0;
  }
  .new_caseStudy_DriveWealth .section-11 .tech-stach p {
    text-align: left;
  }
  .new_caseStudy_DriveWealth .section-11 .tech-stach ul li {
    width: 100%;
  }
  .new_caseStudy_DriveWealth .section-13 ul li {
    flex-flow: wrap;
  }
  .new_caseStudy_DriveWealth .section-13 ul li svg {
    position: relative;
    top: 20px;
  }
  .new_caseStudy_DriveWealth .section-13 ul li h6 {
    width: 70%;
  }
  .new_caseStudy_DriveWealth .section-13 ul li h6 br {
    display: none;
  }
  .new_caseStudy_DriveWealth .section-13 ul li p {
    display: block;
    margin-left: 60px;
    width: 100%;
  }
  .new_caseStudy_DriveWealth .section-19 .wrapping-up {
    flex-flow: column-reverse;
    align-items: flex-start;
  }
  .new_caseStudy_DriveWealth .section-19 .wrapping-up .wrapping-up-img {
    margin: 20px auto;
  }
  .new_caseStudy_DriveWealth .section-19 .wrapping-up .wrapping_para {
    width: 100%;
  }
  .new_caseStudy_DriveWealth .section-19 .wrapping-up:last-child {
    flex-flow: column;
  }
}
@media only screen and (max-width: 425px) and (min-width: 320px) {
  .new_caseStudy_DriveWealth .section-1 .banner_sec img {
    width: 180px;
    margin-bottom: 20px;
  }
  .new_caseStudy_DriveWealth .section-1 .banner_sec h1 {
    font-size: 30px;
    margin-bottom: 20px !important;
  }
  .new_caseStudy_DriveWealth .section-1 .banner_sec h2 {
    font-size: 23px;
    line-height: 22px;
    margin-bottom: 25px !important;
  }
  .new_caseStudy_DriveWealth .section-1 .banner_sec p {
    font-size: 16px;
  }
  .new_caseStudy_DriveWealth .section-1 .banner_sec button {
    font-size: 16px;
    width: 160px;
  }
  .new_caseStudy_DriveWealth .section-2 h2 {
    font-size: 25px;
  }
  .new_caseStudy_DriveWealth .section-3 p,
  .new_caseStudy_DriveWealth .section-3 .outcome-info {
    font-size: 14px;
  }
  .new_caseStudy_DriveWealth .section-3 .outcomes {
    font-size: 14px;
    padding-bottom: 0;
  }
  .new_caseStudy_DriveWealth .section-5 {
    padding: 50px 0 !important;
  }
  .new_caseStudy_DriveWealth .section-11 .tech-stach p {
    font-size: 20px;
  }
}
@media only screen and (max-width: 425px) and (min-width: 320px) {
  .new_caseStudy_DriveWealth .section-1 .banner_sec img {
    width: 140px;
    margin-bottom: 20px;
  }
  .new_caseStudy_DriveWealth .section-1 .banner_sec h1 {
    font-size: 24px;
    margin-bottom: 17px !important;
  }
  .new_caseStudy_DriveWealth .section-1 .banner_sec h2 {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 25px !important;
  }
  .new_caseStudy_DriveWealth .section-1 .banner_sec p {
    font-size: 15px;
  }
  .new_caseStudy_DriveWealth .section-1 .banner_sec button {
    font-size: 15px;
    width: 160px;
  }
  .new_caseStudy_DriveWealth .section-20 .background-bg .new_portfolio-container label {
    font-size: 15px;
    margin-bottom: 20px;
  }
  .new_caseStudy_DriveWealth .section-20 .background-bg .new_portfolio-container h3.customer {
    font-size: 22px;
  }
}
