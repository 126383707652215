.display-block {
  display: block !important;
}
.company-info.section-4 {
  background-image: url(/assets/img/hero-images/comp-inf-bg-2x.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  padding: 150px 0px;
}
video.hero-bg {
  position: absolute;
  top: 0;
  z-index: 0;
  width: 100%;
}
video.hero-bg {
  position: absolute;
  top: 0;
  z-index: 0;
  width: 100%;
}
.section-1.design-cont {
  background-image: url(/assets/img/home-img/Background-try.png) !important;
  background-position: center !important;
  background-size: cover !important;
  background: transparent!important;
  position: relative;
  overflow: hidden;
}
.design-cont .main-para-div p {
  color: #1E4B5E;
  font-family: 'Object Sans Heavy';
}
.design-slide {
  background: #FFFFFF;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(16, 51, 98, 0.45);
  -moz-box-shadow: 0px 0px 10px 0px rgba(16, 51, 98, 0.45);
  box-shadow: 0px 0px 10px 0px rgba(16, 51, 98, 0.45);
  transform: scale(0.99);
  border-radius: 3px;
  width: 99%!important;
  min-height: 350px;
  margin: auto;
  overflow: hidden;
  cursor: pointer;
  display: block!important;
}
.design-slide:focus {
  outline: none;
}
.mp-slide {
  margin-top: -170px;
  padding-bottom: 90px;
}
.mp-slide .slick-list {
  margin: 10px auto;
  padding: 10px 0;
}
.mp-slide .slick-dots li button:before {
  color: #C7D0D3;
  font-size: 12px;
  opacity: 0.75;
}
.mp-slide .slick-dots li button:hover:before {
  opacity: 1;
  color: #acb5b7 !important;
}
.mp-slide .slick-slider .slick-dots {
  bottom: 20px;
}
.slick-dots li {
  margin: 0 2px;
}
.slick-dots {
  bottom: 15px;
}
.slick-slider .slick-dots li.slick-active button:before {
  opacity: 1;
  color: #FFCC4A !important;
}
.design-home .slick-slide img {
  width: 100%;
}
.home-page-slider .slick-slider .slick-arrow {
  z-index: 99;
}
/* custom css */
.design-home .section-1 .main-head-1 .primary-span {
  margin-bottom: 25px;
}
.home-card .home-tit h4 {
  font-family: "object sans heavy";
  font-size: 18px;
  line-height: 22px;
}
.home-card.home-card-resp.post-trade .home-img {
  padding-top: 20px;
}
.home-card.home-card-resp.post-trade .home-img img {
  width: 90px;
}
.home-card.home-card-resp.post-trade .home-tit {
  margin-top: 25px;
}
.design-home .main-head-1 .home-short-span {
  max-width: 800px;
}
.design-home .main-para-div .para-short-span {
  max-width: 630px;
}
.driven-types-common .company-info.section-4 {
  background-image: none;
  padding: 0px!important;
}
.design-home .driven-types-common .company-info .main-para-div p {
  max-width: 100%;
}
.design-home .driven-types-common .company-info .main-para-div .main-para-div .connect-btn {
  font-weight: 600;
  color: #1e4b5e;
}
.design-home .driven-common-hold {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #C7D0D3;
  padding-bottom: 30px;
}
.design-home .find-col:last-child .driven-common-hold {
  border-bottom: none;
  margin-bottom: 0px;
}
.design-home .driven-types-common .full-paper-btn button {
  background: #007CC1;
  border: 1px solid #007cc1;
  border-radius: 3px;
  font-family: 'Object Sans Heavy';
  font-size: 18px;
  line-height: 19px;
  text-align: center;
  color: #FFFFFF;
  padding: 15px 20px;
  height: 60px;
  min-width: 190px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: no-drop;
}
.design-home .driven-types-common .home-design-link {
  color: #007CC1;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 600;
  font-family: 'Object Sans Heavy';
  display: inline-block;
}
.design-home .driven-types-common .home-design-link:hover {
  text-decoration: underline!important;
}
.service-img.ylow-sqr-bx {
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  display: block;
  box-sizing: border-box;
  padding: 10px;
  text-align: center;
  box-shadow: 3px 3px 0 #ffd028, 2px 2px 1px rgba(0, 0, 0, 0.2);
  background: #fff;
  border-radius: 5px;
  width: 227px;
  height: 145px;
  transition: all 1.2s ease;
  margin-right: 10%;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
}
.service-img.ylow-sqr-bx .criteria-image-hold {
  width: 150px;
  height: 100px;
  max-width: 90%;
  align-items: center;
  margin: auto;
  display: inline-flex;
  justify-content: center;
}
.service-img.ylow-sqr-bx .criteria-image-hold img {
  width: 100%;
}
.service-img.ylow-sqr-bx:hover {
  transition: all 1.2s ease;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2), 5px 5px 0 #ffd028;
}
@media only screen and (max-width: 576px) and (min-width: 426px) {
  .design-home .solutions-blue-cards .blue-card-col {
    max-width: 70%!important;
  }
  .design-home .custom-section .appr-col {
    width: 100%!important;
    max-width: 100%!important;
  }
}
@media only screen and (max-width: 480px) and (min-width: 426px) {
  .home-img {
    width: 100px;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 768px) {
  .design-home .custom-section .appr-col {
    width: 50%;
    flex: inherit;
    max-width: 50%;
    margin: 0px!important;
  }
  .design-home .left-info-hold {
    background: none;
  }
  .design-home .solutions-blue-cards .blue-card-col {
    max-width: 50%;
    flex: inherit;
    margin-bottom: 30px;
  }
  video.hero-bg {
    width: 190vh;
  }
  .mp-slide {
    margin-top: -155px;
    padding-bottom: 90px;
  }
  .home-page-slider .slick-slider .slick-prev {
    left: -12px;
  }
  .home-page-slider .slick-slider .slick-next {
    right: -12px;
  }
  .home-page-slider .slick-prev,
  .home-page-slider .slick-next {
    width: 30px;
    height: 30px;
  }
  .home-page-slider .slick-prev:before,
  .home-page-slider .slick-next:before {
    background-position: 6px center;
  }
  .design-home .solutions-blue-cards .blue-card-col div.home-card.home-card-resp.post-trade div.home-img img {
    width: 50px;
  }
}
@media only screen and (max-width: 860px) {
  .design-home .driven-common-hold {
    display: block;
  }
  .design-home .driven-types-common .company-info .main-para-div p {
    max-width: 100%;
  }
}
@media only screen and (max-width: 576px) and (min-width: 424px) {
  .solutions-blue-cards .home-tit h4 {
    font-size: 22px;
    line-height: 27px;
  }
  .solutions-blue-cards .home-tit {
    min-height: 30px;
  }
  .solutions-blue-cards .home-des p {
    font-size: 16px;
  }
  .solutions-blue-cards .home-btn .view-btn {
    font-size: 16px;
    line-height: 19px;
    padding: 15px 20px;
  }
  .design-slide {
    transform: scale(0.95);
  }
}
@media only screen and (max-width: 425px) {
  video.hero-bg {
    width: 210vh;
  }
  .design-home .custom-section .appr-col {
    width: 100%;
    max-width: 100%;
  }
  .design-home .service-head h4 {
    font-size: 24px;
    line-height: 30px;
  }
  .design-home .custom-section .appr-col {
    margin-bottom: 40px!important;
  }
  .design-home .custom-section .appr-col .main-para-div {
    margin-bottom: 0px;
  }
  .find-col {
    margin-bottom: 50px;
  }
  .design-home .company-info .highlight-text h4 span {
    font-size: 30px;
    line-height: 37px;
  }
  .design-home .company-info .main-para-div p {
    font-size: 16px;
    max-width: 100%;
  }
  .design-home .company-info .main-para-div {
    margin-bottom: 15px;
  }
  .design-home .company-info .more-text a {
    font-size: 16px;
  }
  .design-home .solutions-blue-cards .blue-card-col {
    max-width: 100%;
  }
  .design-home .solutions-blue-cards .blue-card-col .home-card-resp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 205px;
  }
  .design-home .solutions-blue-cards .blue-card-col .home-card-resp .home-btn {
    position: relative;
    width: 100%;
    left: inherit;
    bottom: inherit;
  }
  .design-home .solutions-blue-cards .blue-card-col .home-card-resp .home-tit h4 {
    font-size: 18px!important;
    line-height: 22px!important;
    text-align: left;
  }
  .design-home .solutions-blue-cards .blue-card-col .home-card-resp .home-tit {
    min-height: auto;
  }
  .design-home .solutions-blue-cards .blue-card-col .home-card-resp .home-des p {
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: left;
    letter-spacing: -1px;
  }
  .design-home .solutions-blue-cards .blue-card-col .home-card-resp .home-img {
    width: 100%;
    padding: 10px;
  }
  .design-home .solutions-blue-cards .blue-card-col .home-card-resp .home-img img {
    width: 100%;
  }
  .design-home .solutions-blue-cards .blue-card-col .home-card-resp .home-btn .view-btn {
    font-size: 16px;
    padding: 0px;
    width: 130px;
    height: 45px;
    vertical-align: middle;
  }
  .design-home .solutions-blue-cards .blue-card-col .home-card-resp .home-btn {
    text-align: left;
  }
}
@media only screen and (max-width: 375px) {
  video.hero-bg {
    width: 220vh;
  }
  .section-1 .main-head-1 .secondary-span {
    font-size: 26px!important;
    line-height: 32px!important;
  }
}
@media only screen and (max-width: 576px) and (min-width: 320px) {
  .design-slide {
    transform: scale(0.95);
  }
  .driven-types-common .find-col {
    margin-bottom: 0px;
  }
  .driven-types-common .find-col:last-child {
    margin-bottom: 0px;
    padding-top: 35px;
  }
  .design-home .find-col:last-child .driven-common-hold {
    padding-bottom: 0px!important;
  }
  .design-home .c-cards-mg {
    margin-bottom: 0px!important;
  }
  .design-home .solutions-blue-cards .blue-card-col:last-child {
    margin-bottom: 0px;
  }
  .ix-media .criteria-col {
    flex: 0 0 50%;
    max-width: 50%;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0;
  }
  .service-img.ylow-sqr-bx {
    width: 100%;
    height: auto;
    margin-right: 0;
  }
}
