.talk-to-us .top-section {
  margin-top: 100px;
  background: url('/assets/img/contact-us/contact-us-bg.png');
  background-size: cover;
  padding: 100px 0px;
  position: relative;
  z-index: 1;
}
.talk-to-us .top-section::after {
  content: '';
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: linear-gradient(92.32deg, rgba(0, 0, 0, 0.87) 1.23%, rgba(0, 0, 0, 0) 98.42%);
  position: absolute;
  z-index: -1;
}
.talk-to-us .top-section .main-head-1 .primary-span {
  margin-bottom: 15px;
}
.talk-to-us .top-section .main-head-1 .secondary-span {
  font-size: 24px;
  font-weight: 400;
  color: #fff;
  font-family: "Object Sans";
  width: 580px;
}
.talk-to-us .main-head-div {
  margin-bottom: 0px;
}
.talk-to-us .main-head-1 .secondary-span {
  font-size: 28px;
  font-family: "Heldane Display";
  line-height: 35px;
}
.talk-to-us div.custom-section {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-top: 0px;
}
.talk-to-us div.custom-section .box-heading {
  color: #1E4B5E;
  font-size: 20px;
  font-family: "Object Sans heavy";
  margin-bottom: 10px;
  display: block;
}
.talk-to-us div.custom-section .main-para {
  color: #1E4B5E;
  font-size: 16px;
  font-family: "Object Sans";
  width: 350px;
}
.talk-to-us div.custom-section .contact-info-detail {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  background: #fff;
  padding: 30px;
  width: 85%;
  display: flex;
}
.talk-to-us div.custom-section .contact-info-detail span {
  display: block;
  margin-bottom: 30px;
  color: #1E4B5E;
  font-size: 16px;
  line-height: 30px;
}
.talk-to-us div.custom-section .contact-info-detail span a {
  color: #24A989;
  text-decoration: underline;
  font-weight: 800;
}
.talk-to-us div.custom-section .contact-info-detail span:nth-of-type(3) {
  margin-bottom: 0px;
}
.talk-to-us .content-section {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.talk-to-us .section-1.design-cont {
  height: auto;
}
.talk-to-us .c-form-select {
  display: block;
  width: 100%;
  height: 45px;
  line-height: 45px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.talk-to-us .c-form-select .selected-value {
  line-height: 46px;
  text-indent: 15px;
  font-size: 13px;
}
.talk-to-us .c-form-select .cfsel-drop-span {
  top: 20px;
}
.talk-to-us .select-option {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.talk-to-us .select-option .d-block {
  padding: 6px 15px;
  color: #1E4B5E;
  font-size: 13px;
  cursor: pointer;
  font-family: "Object Sans";
  border: 1px solid transparent;
}
.talk-to-us .select-option .d-block:hover {
  background: rgba(36, 169, 137, 0.1);
  border: 1px solid rgba(36, 169, 137, 0.4);
  font-weight: 800;
}
.talk-to-us .connect-form {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  background: #fff;
  padding: 30px;
  margin-left: 70px;
  max-width: 45%;
  flex: 0 0 45%;
}
.talk-to-us .connect-form .form-group {
  margin-bottom: 10px;
}
.talk-to-us .connect-form b {
  color: #1E4B5E;
  font-size: 30px;
  margin-bottom: 20px;
  display: block;
  font-family: "Object Sans heavy";
}
.talk-to-us .connect-form .contact-form-label {
  color: #1E4B5E;
  font-weight: 800;
  font-family: "Object Sans heavy";
  font-size: 15px;
}
.talk-to-us .connect-form .react-tel-input .selected-flag {
  width: 40px;
}
.talk-to-us .connect-form .react-tel-input .selected-flag .flag {
  margin-right: 5px;
}
.talk-to-us .connect-form .react-tel-input .form-control {
  padding-left: 45px;
}
.talk-to-us .connect-form .form-control {
  padding: 10px 15px;
  height: 45px;
  font-size: 13px;
}
.talk-to-us .connect-form .form-control#formBasicName {
  font-size: 13px;
}
.talk-to-us .connect-form .form-control.contact-form-txt-area {
  height: auto;
}
.talk-to-us .check-content {
  display: flex;
  align-items: center;
}
.talk-to-us .check-content label {
  display: inline-flex;
  align-items: center;
}
.talk-to-us .check-custom-text {
  font-size: 12px;
  color: #1E4B5E;
  font-family: "Object Sans";
  display: inline-flex;
  align-items: center;
}
.talk-to-us .contact-submit.btn-primary {
  background-color: #FFCC4A;
  border: 1px solid #FFCC4A;
  display: block;
  width: 100%;
  border-radius: 4px;
  font-size: 16px;
  color: #1E4B5E;
  font-weight: 800;
  font-family: "Object Sans";
  padding: 10px;
}
.talk-to-us .contact-submit.btn-primary:disabled {
  cursor: not-allowed;
}
.talk-to-us .no-pad {
  padding: 0px !important;
}
@media only screen and (max-width: 768px) {
  .talk-to-us .content-section {
    flex-direction: column;
  }
  .talk-to-us div.custom-section .contact-info-detail {
    width: 100%;
  }
  .talk-to-us .connect-form {
    max-width: 100%;
    flex: 0 0 100%;
    margin-left: 0px;
    margin-top: 30px;
  }
}
@media only screen and (max-width: 425px) {
  .talk-to-us div.custom-section .contact-info-detail {
    width: 100%;
  }
  .talk-to-us div.custom-section .contact-info-detail span {
    font-size: 14px;
  }
  .talk-to-us .top-section .main-head-1 .secondary-span,
  .talk-to-us div.custom-section .main-para {
    width: 100%;
  }
}
