.testimonials .testi-detailed-review a {
  font-family: 'object sans heavy';
  margin-bottom: 20px;
}
.slick-slider .slick-dots {
  bottom: -35px;
}
.section-4.MarketPlace {
  padding-top: 80px!important;
  padding-bottom: 80px!important;
}
.testi-detailed-review {
  margin: 20px 0px;
}
.testimonials .main-head-div {
  margin-bottom: 25px;
}
.mp-common-hold .more-text {
  margin-top: 50px!important;
  margin-left: 25px !important;
}
.testimonial-design .slick-next::before {
  background-color: #FFD028;
}
.testimonial-design .slick-prev::before {
  background-color: #FFD028;
}
.testimonial-design .testi-card .port-logo {
  display: flex;
  padding: 20px 10px 10px;
  justify-content: center;
  align-items: center;
  border-bottom: 5px solid #FFD028;
  width: 95%;
  margin: 0px auto;
  min-height: 75px;
}
.testimonial-design .testi-card .port-logo img {
  width: 40%;
}
.testimonial-design .testi-card .port-logo img.icon {
  width: 10%;
  margin-right: 10px;
}
.testimonial-design .testi-card .port-logo b {
  font-size: 21px;
  font-family: "Object Sans Heavy";
  color: #1E4B5E;
}
.testimonial-design .testi-card .testi-review-common {
  padding: 30px;
}
.testimonial-design .testi-card .testi-review-common .testi-client-head {
  background: transparent;
  padding: 0px;
  min-height: auto;
  margin-bottom: 10px;
}
.testimonial-design .testi-card .testi-review-common .testi-client-head p {
  color: #1E4B5E;
  font-family: "Object Sans Heavy";
}
.testimonial-design .testi-card .testi-review-common .testi-client-review {
  min-height: auto !important;
}
.testimonial-design .testi-card .testi-review-common .testi-client-review::before {
  display: none;
}
.testimonial-design .testi-card .testi-footer {
  padding: 10px 20px !important;
  background: #1E4B5E;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  height: auto !important;
  min-height: 95px !important;
  max-height: none !important;
  position: absolute;
  bottom: 0px;
}
.testimonial-design .testi-card .testi-footer .testi-comp-info p {
  color: #fff;
  font-family: "Object Sans Heavy";
}
.testimonial-design .testi-card .testi-footer .testi-comp-info p span {
  color: #FFD028 !important;
  font-weight: 400 !important;
  font-family: "Object Sans";
}
.testimonial-design .testi-card .testi-footer .testi-comp-info p span.role {
  color: #FFD028 !important;
  font-weight: 400 !important;
  font-family: "Object Sans";
}
@media only screen and (max-width: 2560px) and (min-width: 1440px) {
  .testi-card {
    height: 454px!important;
  }
  .testi-client-review p {
    font-size: 16px!important;
  }
  .testi-detailed-review {
    margin-bottom: 0px!important;
    margin-top: 10px!important;
  }
  .testi-review-common {
    padding: 20px 60px!important;
    min-height: 230px!important;
  }
  .testi-client-review {
    min-height: 146px!important;
    max-height: 146px!important;
  }
  .testi-comp-info p:first-child {
    font-size: 26px!important;
    line-height: 30px!important;
  }
  .testi-comp-logo {
    width: 70px!important;
    height: 70px!important;
    min-width: 70px!important;
  }
  .testi-comp-info p:last-child {
    font-size: 16px!important;
  }
  .mp-common-hold .more-text {
    margin-top: 20px!important;
  }
}
@media only screen and (max-width: 1439px) and (min-width: 1281px) {
  .testi-card {
    height: 500px!important;
  }
  .testi-card .testi-client-review p {
    font-size: 16px!important;
  }
  .testi-detailed-review {
    margin-bottom: 0px!important;
    margin-top: 0px!important;
  }
  .testi-card .testi-comp-info p:first-child {
    font-size: 24px!important;
    line-height: 28px!important;
  }
  .testi-card .testi-client-review {
    margin-bottom: 10px;
    min-height: 132px;
    max-height: 132px;
  }
  .testi-card .testi-review-common {
    min-height: 233px!important;
  }
  .testi-comp-logo {
    width: 70px!important;
    height: 70px!important;
    min-width: 70px!important;
  }
  .testi-comp-info p:last-child {
    font-size: 16px!important;
  }
  .testimonials .main-head-div {
    margin-bottom: 10px;
  }
  .mp-common-hold .more-text {
    margin-top: 20px!important;
  }
  .testi-card {
    height: 440px!important;
  }
}
@media only screen and (max-width: 1280px) and (min-width: 1024px) {
  .testi-card {
    height: 420px!important;
    min-height: 420px!important;
  }
  .testi-card .testi-client-review p {
    font-size: 14px!important;
    line-height: 22px!important;
  }
  .testi-detailed-review {
    margin-bottom: 0px!important;
    margin-top: 0px!important;
  }
  .testi-card .testi-comp-info p:first-child {
    font-size: 20px!important;
    line-height: 24px!important;
  }
  .testi-card .testi-client-review {
    margin-bottom: 10px;
    min-height: 130px;
    max-height: 130px;
  }
  .testi-card .testi-review-common {
    min-height: 213px;
  }
  .testi-comp-logo {
    width: 60px!important;
    height: 60px!important;
    min-width: 60px!important;
  }
  .testi-comp-info p:last-child {
    font-size: 16px!important;
  }
  .testi-client-review p {
    -webkit-line-clamp: 8!important;
  }
  .testi-client-head p {
    font-size: 14px!important;
  }
  .testi-detailed-review a {
    font-size: 14px!important;
  }
  .mp-common-hold .more-text {
    margin-top: 20px!important;
  }
  .testi-card .testi-client-review:before {
    background-size: 50px;
    background-position: center;
    width: 55px;
    height: 55px;
  }
  .testimonials .main-head-div {
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 1097px) and (min-width: 1068px) {
  .card.testi-card {
    margin: 0 auto!important;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .testi-card {
    width: 415px!important;
    min-width: 415px!important;
  }
  .testi-card .testi-comp-info p:last-child {
    font-style: normal;
    font-size: 14px;
  }
}
@media only screen and (max-width: 767px) and (min-width: 576px) {
  .testi-card .testi-client-review {
    min-height: 236px!important;
    max-height: 236px!important;
  }
  .testi-col .testi-card {
    max-height: 100%;
    min-height: 100%;
  }
  .testi-card .testi-comp-info p:last-child {
    font-style: normal;
    font-size: 14px;
  }
  .testi-col .testi-card .testi-client-head p {
    font-size: 14px;
  }
}
@media only screen and (max-width: 575px) and (min-width: 375px) {
  .testi-col .testi-card .testi-client-review {
    min-height: 150px!important;
    max-height: 150px!important;
  }
  .testi-col .card.testi-card .testi-client-head p {
    font-size: 14px!important;
  }
  .testi-col .card.testi-card .testi-client-review p {
    font-size: 14px!important;
  }
  .testi-col .testi-card .testi-detailed-review {
    margin-bottom: 0px!important;
  }
  .testi-col .testi-card {
    max-height: 100%;
    min-height: 100%;
    min-width: 100%;
    max-width: 100%;
  }
  .testi-col .testi-card .testi-review-common {
    padding: 30px 30px!important;
  }
  .testi-card .testi-comp-info p:last-child {
    font-style: normal;
    font-size: 14px;
  }
  .testi-client-review:before {
    left: -10px !important;
  }
}
@media only screen and (max-width: 992px) {
  .testi-card {
    min-height: 455px;
    height: 455px;
  }
  .testi-review-common {
    min-height: 215px!important;
  }
}
@media only screen and (max-width: 992px) and (min-width: 425px) {
  .testi-card .testi-client-head p {
    font-size: 14px!important;
  }
  .testi-card .testi-client-review p {
    font-size: 14px!important;
  }
  .mp-testimonials .testi-col .testi-card .testi-client-review {
    margin-bottom: 10px;
    min-height: 166px!important;
    max-height: 166px!important;
  }
  .testi-card .testi-detailed-review {
    margin-top: 10px;
    margin-bottom: 0px;
  }
  .testi-col .testi-card {
    min-height: 460px;
    height: 460px;
  }
  .mp-testimonials .slick-dots {
    display: none!important;
  }
  .mp-testimonials .testi-col {
    margin-bottom: 0px!important;
  }
  .mp-common-hold .more-text {
    margin-top: 5px!important;
  }
  .testi-col .testi-card .testi-comp-logo {
    width: 70px;
    height: 70px;
    min-width: 70px;
  }
}
@media only screen and (max-width: 768px) {
  .testi-card {
    min-height: 515px;
    height: 515px;
  }
  .testimonial-design .testi-card .testi-footer .testi-comp-info p span.role {
    display: inline-block;
  }
  .testimonial-design .testi-card .testi-footer {
    min-height: auto !important;
    max-height: auto !important;
  }
  .testi-col .card.testi-card .testi-client-head p span {
    display: inline-block !important;
  }
  .testi-col .card.testi-card .testi-client-head p span:last-child {
    padding-left: 0px;
  }
}
@media only screen and (max-width: 576px) {
  .testi-client-review {
    min-height: 180px!important;
    max-height: 180px!important;
  }
  .testimonials .testi-card {
    height: 100%!important;
  }
  .testimonial-design .testi-card .testi-review-common .testi-client-review {
    max-height: none !important;
    min-height: auto !important;
  }
  .testimonial-design .testi-card .testi-review-common {
    max-height: 500px !important;
    min-height: 500px !important;
  }
}
@media only screen and (max-width: 440px) and (min-width: 425px) {
  .testi-col .testi-card .testi-client-review {
    min-height: 150px!important;
    max-height: 150px!important;
  }
  .testi-col .testi-card .testi-detailed-review a {
    font-size: 16px!important;
  }
  .testimonials.testimonial-design .testi-col .testi-card .testi-client-head {
    min-height: auto !important;
    max-height: auto !important;
  }
}
@media only screen and (max-width: 425px) and (min-width: 320px) {
  .testi-col .testi-card .testi-comp-info p:first-child {
    font-size: 16px !important;
  }
  .tab-content .Resources .section-2 .in-news-common .news-cards-common ul li .in-news-card.card {
    margin-top: 20px!important;
    margin-bottom: 35px!important;
  }
  .testi-col .testi-card .testi-comp-logo {
    width: 50px;
    height: 50px;
    min-width: 50px;
  }
  .testimonials .main-head-div {
    margin-bottom: 10px;
  }
  .testi-col .testi-card .testi-comp-info p:first-child {
    font-size: 20px;
    line-height: 26px;
  }
}
@media only screen and (max-width: 375px) {
  .mp-testimonials .testi-col .card.testi-card .testi-client-review p {
    font-size: 13px!important;
    line-height: 21px!important;
  }
}
@media only screen and (max-width: 320px) {
  .mp-testimonials .testi-col .card.testi-card .testi-client-head p {
    font-size: 12px!important;
  }
  .mp-testimonials .testi-col .card.testi-card .testi-client-head {
    padding: 18px 15px;
    min-height: 90px;
  }
  .mp-testimonials .testi-col .testi-card .testi-comp-info p:first-child {
    font-size: 14px;
    line-height: 20px;
  }
  .mp-testimonials .testi-comp-info p:last-child {
    font-size: 14px!important;
    line-height: 18px!important;
  }
}
@media only screen and (max-width: 424px) and (min-width: 320px) {
  .testi-col .testi-card .testi-client-review {
    min-height: 150px!important;
    max-height: 150px!important;
  }
  .testi-col .testi-card .testi-detailed-review a {
    font-size: 14px!important;
  }
  .testi-comp-info p:first-child {
    font-size: 16px;
    line-height: 20px;
  }
  .testi-comp-info p:last-child {
    font-size: 14px!important;
    line-height: 20px!important;
  }
  .testi-col .card.testi-card .testi-client-review p {
    font-size: 14px!important;
  }
  .testi-col .card.testi-card .testi-client-head p {
    font-size: 14px!important;
  }
  .testi-client-review:before {
    left: -10px !important;
  }
}
