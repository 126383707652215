.digital-brokerage .section-2,
.digital-brokerage .section-3,
.digital-brokerage .section-4,
.digital-brokerage .section-5,
.digital-brokerage .section-6 {
  padding: 150px 0px;
}
.digital-brokerage .section-1 {
  background: url('/assets/img/digital-brokerage/digital-brokerage-bg.png') no-repeat;
  position: relative;
  background-size: cover;
}
.digital-brokerage .section-1 .custom-section {
  padding: 0px;
}
.digital-brokerage .section-1 .main-head-1 {
  text-align: center;
  line-height: 56px;
}
.digital-brokerage .section-1 .main-head-1 .secondary-span {
  display: inline-block;
  color: #fff;
  font-family: 'Heldane Display';
  font-size: 46px;
  text-align: center;
  width: 950px;
}
.digital-brokerage .section-1 .main-head-1 .secondary-span .primary-span {
  display: inline-block;
  line-height: 95px;
}
.digital-brokerage .section-1 .main-para-div {
  text-align: center;
}
.digital-brokerage .section-1 .main-para-div p {
  color: #fff;
  padding: 0px 50px;
  width: 850px;
  margin: 0px auto;
}
.digital-brokerage .section-1 span.connect-btn.btn-primary.btn {
  font-size: 24px;
  color: #1E4B5E !important;
  font-family: "Object Sans heavy";
  line-height: 24px;
  background-color: #FFCC4A !important;
  margin-top: 10px;
}
.digital-brokerage .section-1 .floating-section {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: -15%;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  width: 900px;
  padding: 25px;
}
.digital-brokerage .section-1 .floating-section p {
  color: #1E4B5E;
  font-size: 18px;
}
.digital-brokerage .section-1 .floating-section strong {
  font-family: 'Heldane Display';
  color: #24A989;
  font-size: 18px;
  display: block;
}
.digital-brokerage .section-1 .floating-section span {
  display: block;
  margin: 10px 0;
}
.digital-brokerage .section-1 .floating-section span b {
  color: #1E4B5E;
  display: block;
}
.digital-brokerage .section-1 .floating-section a {
  font-style: normal;
  color: #004064;
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
}
.digital-brokerage .section-2 {
  margin: 120px 0px 0px;
}
.digital-brokerage .section-2 .main-head-1 {
  text-align: center;
  width: 700px;
  margin: 0px auto;
}
.digital-brokerage .section-2 .main-head-1 span {
  color: #1E4B5E;
  font-family: "Object Sans heavy";
  font-size: 36px;
  line-height: 46px;
}
.digital-brokerage .section-2 p {
  margin: 30px auto;
  color: #1E4B5E;
  font-size: 16px;
  text-align: center;
  width: 800px;
  padding: 0px 30px;
}
.digital-brokerage .section-2 .one-stop-solution {
  margin: 20px auto 0px;
  text-align: center;
}
.digital-brokerage .section-2 .one-stop-solution img {
  width: 50%;
}
.digital-brokerage .section-2 .cta-wrapper {
  display: block;
  text-align: center;
  margin: 80px 0px 0px;
}
.digital-brokerage .section-2 a.connect-btn {
  background: #1E4B5E;
  box-shadow: 0px 6px 10px rgba(30, 75, 94, 0.2);
  border-radius: 3px;
  color: #FFCC4A;
  padding: 15px 25px;
  margin: 0px auto;
  font-weight: 800;
  font-size: 18px;
}
.digital-brokerage .section-3 .oms-solution,
.digital-brokerage .section-4 .oms-solution,
.digital-brokerage .section-5 .oms-solution,
.digital-brokerage .section-6 .oms-solution {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.digital-brokerage .section-3 .oms-solution .main-head-1,
.digital-brokerage .section-4 .oms-solution .main-head-1,
.digital-brokerage .section-5 .oms-solution .main-head-1,
.digital-brokerage .section-6 .oms-solution .main-head-1 {
  line-height: 24px;
}
.digital-brokerage .section-3 .oms-solution .main-head-1 .secondary-span,
.digital-brokerage .section-4 .oms-solution .main-head-1 .secondary-span,
.digital-brokerage .section-5 .oms-solution .main-head-1 .secondary-span,
.digital-brokerage .section-6 .oms-solution .main-head-1 .secondary-span {
  display: inline-block;
  color: #fff;
  font-family: 'Heldane Display';
  font-size: 32px;
  line-height: 6px;
}
.digital-brokerage .section-3 .oms-solution .main-head-1 .secondary-span .primary-span,
.digital-brokerage .section-4 .oms-solution .main-head-1 .secondary-span .primary-span,
.digital-brokerage .section-5 .oms-solution .main-head-1 .secondary-span .primary-span,
.digital-brokerage .section-6 .oms-solution .main-head-1 .secondary-span .primary-span {
  display: inline-block;
  font-size: 32px;
}
.digital-brokerage .section-3 .oms-solution .real-time,
.digital-brokerage .section-4 .oms-solution .real-time,
.digital-brokerage .section-5 .oms-solution .real-time,
.digital-brokerage .section-6 .oms-solution .real-time {
  margin: 40px 0px;
  color: #FFFFFF;
  font-size: 20px;
  font-weight: 800;
  font-family: "Object Sans heavy";
}
.digital-brokerage .section-3 .oms-solution p.para-div,
.digital-brokerage .section-4 .oms-solution p.para-div,
.digital-brokerage .section-5 .oms-solution p.para-div,
.digital-brokerage .section-6 .oms-solution p.para-div {
  font-size: 16px;
  color: #FFFFFF;
}
.digital-brokerage .section-3 .oms-solution img,
.digital-brokerage .section-4 .oms-solution img,
.digital-brokerage .section-5 .oms-solution img,
.digital-brokerage .section-6 .oms-solution img {
  width: 100%;
}
.digital-brokerage .section-3 .oms-solution-list,
.digital-brokerage .section-4 .oms-solution-list,
.digital-brokerage .section-5 .oms-solution-list,
.digital-brokerage .section-6 .oms-solution-list {
  display: flex;
  align-items: center;
  margin: 100px 0px 0px;
  justify-content: space-between;
}
.digital-brokerage .section-3 .oms-solution-list .oms-features,
.digital-brokerage .section-4 .oms-solution-list .oms-features,
.digital-brokerage .section-5 .oms-solution-list .oms-features,
.digital-brokerage .section-6 .oms-solution-list .oms-features {
  padding: 0;
  margin: 0;
  list-style-type: none;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.digital-brokerage .section-3 .oms-solution-list .oms-features li,
.digital-brokerage .section-4 .oms-solution-list .oms-features li,
.digital-brokerage .section-5 .oms-solution-list .oms-features li,
.digital-brokerage .section-6 .oms-solution-list .oms-features li {
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 50px;
}
.digital-brokerage .section-3 .oms-solution-list .oms-features li span,
.digital-brokerage .section-4 .oms-solution-list .oms-features li span,
.digital-brokerage .section-5 .oms-solution-list .oms-features li span,
.digital-brokerage .section-6 .oms-solution-list .oms-features li span {
  display: block;
  font-size: 14px;
  margin: 10px auto 0px;
  width: 170px;
}
.digital-brokerage .section-3 .oms-solution-list .oms-features li:nth-of-type(5),
.digital-brokerage .section-4 .oms-solution-list .oms-features li:nth-of-type(5),
.digital-brokerage .section-5 .oms-solution-list .oms-features li:nth-of-type(5),
.digital-brokerage .section-6 .oms-solution-list .oms-features li:nth-of-type(5) {
  margin-bottom: 0px;
}
.digital-brokerage .section-3 .oms-solution-list .oms-features li:nth-of-type(6),
.digital-brokerage .section-4 .oms-solution-list .oms-features li:nth-of-type(6),
.digital-brokerage .section-5 .oms-solution-list .oms-features li:nth-of-type(6),
.digital-brokerage .section-6 .oms-solution-list .oms-features li:nth-of-type(6) {
  margin-bottom: 0px;
}
.digital-brokerage .section-3 .oms-solution-list .oms-feature-list,
.digital-brokerage .section-4 .oms-solution-list .oms-feature-list,
.digital-brokerage .section-5 .oms-solution-list .oms-feature-list,
.digital-brokerage .section-6 .oms-solution-list .oms-feature-list {
  padding: 0px 0px 0px 20px;
  display: inline-flex;
  flex-wrap: wrap;
}
.digital-brokerage .section-3 .oms-solution-list .oms-feature-list strong,
.digital-brokerage .section-4 .oms-solution-list .oms-feature-list strong,
.digital-brokerage .section-5 .oms-solution-list .oms-feature-list strong,
.digital-brokerage .section-6 .oms-solution-list .oms-feature-list strong {
  color: #FFFFFF;
  font-size: 26px;
  font-family: 'Heldane Display';
  margin-bottom: 30px;
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
.digital-brokerage .section-3 .oms-solution-list .oms-feature-list ul,
.digital-brokerage .section-4 .oms-solution-list .oms-feature-list ul,
.digital-brokerage .section-5 .oms-solution-list .oms-feature-list ul,
.digital-brokerage .section-6 .oms-solution-list .oms-feature-list ul {
  padding: 0px;
  margin: 0px;
  list-style-type: none;
  color: #FFFFFF;
}
.digital-brokerage .section-3 .oms-solution-list .oms-feature-list ul li,
.digital-brokerage .section-4 .oms-solution-list .oms-feature-list ul li,
.digital-brokerage .section-5 .oms-solution-list .oms-feature-list ul li,
.digital-brokerage .section-6 .oms-solution-list .oms-feature-list ul li {
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 34px;
  position: relative;
  padding-left: 20px;
}
.digital-brokerage .section-3 .oms-solution-list .oms-feature-list ul li::before,
.digital-brokerage .section-4 .oms-solution-list .oms-feature-list ul li::before,
.digital-brokerage .section-5 .oms-solution-list .oms-feature-list ul li::before,
.digital-brokerage .section-6 .oms-solution-list .oms-feature-list ul li::before {
  width: 12px;
  height: 12px;
  background: #FFCC4A;
  border-radius: 100%;
  content: '';
  position: absolute;
  left: 0;
  top: 10px;
}
.digital-brokerage .section-3 .oms-solution-list .oms-feature-list .read-more-btn,
.digital-brokerage .section-4 .oms-solution-list .oms-feature-list .read-more-btn,
.digital-brokerage .section-5 .oms-solution-list .oms-feature-list .read-more-btn,
.digital-brokerage .section-6 .oms-solution-list .oms-feature-list .read-more-btn {
  background: #FFCC4A;
  color: #000;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  font-size: 16px;
  font-weight: 800;
  border: 0px;
  padding: 17px 30px;
  font-family: "Object Sans heavy";
  margin-top: 30px;
  cursor: pointer;
}
.digital-brokerage .section-4 .oms-solution .main-head-1 .secondary-span,
.digital-brokerage .section-6 .oms-solution .main-head-1 .secondary-span {
  color: #1E4B5E;
}
.digital-brokerage .section-4 .oms-solution .real-time,
.digital-brokerage .section-6 .oms-solution .real-time {
  margin: 40px 0px;
  color: #1E4B5E;
  font-size: 20px;
  font-weight: 800;
}
.digital-brokerage .section-4 .oms-solution p.para-div,
.digital-brokerage .section-6 .oms-solution p.para-div {
  font-size: 16px;
  color: #1E4B5E;
}
.digital-brokerage .section-4 .oms-solution-list .oms-features li,
.digital-brokerage .section-6 .oms-solution-list .oms-features li {
  text-align: center;
  color: #103F48;
  margin-bottom: 50px;
}
.digital-brokerage .section-4 .oms-solution-list .oms-feature-list strong,
.digital-brokerage .section-6 .oms-solution-list .oms-feature-list strong {
  color: #1E4B5E;
}
.digital-brokerage .section-4 .oms-solution-list .oms-feature-list ul,
.digital-brokerage .section-6 .oms-solution-list .oms-feature-list ul {
  color: #1E4B5E;
}
.digital-brokerage .section-6 .oms-solution-list .oms-feature-list {
  padding: 0px;
}
.digital-brokerage .section-6 .oms-solution-list .oms-feature-list ul li {
  line-height: 28px;
}
.digital-brokerage .section-6 .oms-solution-list .oms-feature-list ul li::before {
  top: 8px;
}
.digital-brokerage .section-6 .oms-solution-list {
  margin: 0px;
}
.digital-brokerage .section-6 .oms-solution-list .oms-feature-list ul {
  width: 100%;
}
.digital-brokerage .section-6 .application-design img {
  width: 400px;
}
.digital-brokerage .blue-bg {
  background: linear-gradient(107.15deg, #0E3141 0%, #1B8068 100%);
}
.digital-brokerage .white-bg {
  background: #FFFFFF;
}
@media only screen and (min-width: 1470px) and (max-width: 1600px) {
  .digital-brokerage .section-1 .floating-section {
    bottom: -20%;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1460px) {
  .digital-brokerage .section-1 {
    height: calc(100vh + 40px);
  }
  .digital-brokerage .section-1 .floating-section {
    bottom: -23%;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .digital-brokerage .section-1 div.main-head-div,
  .digital-brokerage .section-1 .main-para-div {
    margin-bottom: 40px;
  }
  .digital-brokerage .section-1 div.floating-section {
    bottom: -15%;
  }
}
@media only screen and (max-width: 1200px) {
  .digital-brokerage .section-1 .main-head-1 .secondary-span,
  .digital-brokerage .section-1 .main-para-div p,
  .digital-brokerage .section-2 p,
  .digital-brokerage .section-2 .main-head-1 {
    width: 80%;
  }
  .digital-brokerage .section-3 .oms-solution img,
  .digital-brokerage .section-4 .oms-solution img,
  .digital-brokerage .section-5 .oms-solution img,
  .digital-brokerage .section-6 .oms-solution img,
  .digital-brokerage .section-3 .oms-solution-list .oms-features li span,
  .digital-brokerage .section-4 .oms-solution-list .oms-features li span,
  .digital-brokerage .section-5 .oms-solution-list .oms-features li span,
  .digital-brokerage .section-6 .oms-solution-list .oms-features li span {
    width: 100%;
  }
  .digital-brokerage .main-head-1 .secondary-span {
    line-height: 36px !important;
  }
  .digital-brokerage .section-1 .main-head-1 .secondary-span,
  .digital-brokerage .section-1 .main-head-1 .secondary-span .primary-span {
    font-size: 36px;
  }
  .digital-brokerage .section-1 .floating-section {
    width: 90%;
    bottom: -20%;
  }
  .digital-brokerage .section-1 .floating-section p {
    font-size: 14px;
  }
  .digital-brokerage .section-1 .main-para-div p {
    padding: 0px;
  }
  .digital-brokerage .section-1 .main-head-div {
    margin-bottom: 25px;
  }
  .digital-brokerage .section-2 {
    margin: 0px;
  }
  .digital-brokerage .section-3 .oms-solution-list .oms-feature-list,
  .digital-brokerage .section-4 .oms-solution-list .oms-feature-list,
  .digital-brokerage .section-5 .oms-solution-list .oms-feature-list,
  .digital-brokerage .section-6 .oms-solution-list .oms-feature-list {
    padding: 0px;
  }
  .digital-brokerage .section-1 .main-head-1 .secondary-span .primary-span {
    line-height: 52px;
  }
}
@media only screen and (max-width: 768px) {
  .digital-brokerage .section-1 .floating-section {
    bottom: -30%;
  }
  .digital-brokerage .section-1 {
    background-size: cover;
  }
  .digital-brokerage .section-2 .main-head-1 {
    margin-top: 100px;
  }
}
@media only screen and (max-width: 425px) {
  .digital-brokerage .section-1 .main-head-1 .secondary-span .primary-span {
    line-height: 42px;
    font-size: 26px;
  }
  .digital-brokerage .section-2 .main-head-1 span,
  .digital-brokerage .section-3 .oms-solution .main-head-1 .secondary-span,
  .digital-brokerage .section-4 .oms-solution .main-head-1 .secondary-span,
  .digital-brokerage .section-5 .oms-solution .main-head-1 .secondary-span,
  .digital-brokerage .section-6 .oms-solution .main-head-1 .secondary-span {
    font-size: 30px;
  }
  .digital-brokerage .section-2 .main-head-1 {
    width: 100%;
  }
  .digital-brokerage .section-2 .main-head-1 {
    margin-top: 0px;
  }
  .digital-brokerage .section-1 .floating-section {
    position: static;
    width: 100%;
  }
  .oms-solution,
  .oms-solution-list {
    flex-direction: column;
  }
  .digital-brokerage .section-1 .custom-container {
    flex-direction: column;
  }
  .digital-brokerage .section-1 {
    background-size: auto;
    padding-bottom: 0px;
  }
  .digital-brokerage .section-2,
  .digital-brokerage .section-3 .oms-solution-list,
  .digital-brokerage .section-4 .oms-solution-list,
  .digital-brokerage .section-5 .oms-solution-list,
  .digital-brokerage .section-6 .oms-solution-list {
    margin-top: 50px;
    width: 100%;
  }
  .digital-brokerage .section-6 .application-design img {
    width: 100%;
  }
  .digital-brokerage .main-head-1 .secondary-span {
    line-height: 36px !important;
  }
  .digital-brokerage .section-3 .oms-solution-list .oms-features li:nth-of-type(6),
  .digital-brokerage .section-3 .oms-solution-list .oms-features li,
  .digital-brokerage .section-3 .oms-solution-list .oms-features li:nth-of-type(5),
  .digital-brokerage .section-4 .oms-solution-list .oms-features li,
  .digital-brokerage .section-6 .oms-solution-list .oms-features li,
  .digital-brokerage .section-5 .oms-solution-list .oms-features li,
  .digital-brokerage .section-6 .oms-solution-list .oms-features li:nth-of-type(6),
  .digital-brokerage .section-6 .oms-solution-list .oms-features li:nth-of-type(5),
  .digital-brokerage .section-5 .oms-solution-list .oms-features li:nth-of-type(6),
  .digital-brokerage .section-5 .oms-solution-list .oms-features li:nth-of-type(5),
  .digital-brokerage .section-4 .oms-solution-list .oms-features li:nth-of-type(6),
  .digital-brokerage .section-4 .oms-solution-list .oms-features li:nth-of-type(5) {
    margin-bottom: 30px;
  }
  .digital-brokerage .section-2,
  .digital-brokerage .section-3,
  .digital-brokerage .section-4,
  .digital-brokerage .section-5,
  .digital-brokerage .section-6 {
    padding: 50px 0px;
  }
  .digital-brokerage .section-6 .oms-feature-list {
    margin-bottom: 30px;
  }
}
.why-digi-brkge .main-head-div.heading_lines .main-head-1 .primary-span {
  color: #fff;
  font-size: 32px;
}
.why-digi-brkge.section-5.development-solution .text p {
  font-weight: 800;
  color: #fff;
  font-size: 18px;
  font-family: 'Object Sans';
}
.why-digi-brkge.section-5.development-solution .text span {
  display: inline-flex;
  font-weight: 400;
  font-size: 15px;
  line-height: 28px;
}
