.search-box {
  width: 30%;
  position: absolute;
  right: 0;
  z-index: 1;
}
.search-box input {
  background: transparent;
  border: 0px;
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid #F2F7F4;
  padding: 14px 15px 14px 0px;
  width: 100%;
  z-index: 1;
  color: #fff;
  font-size: 14px;
}
.search-box input::placeholder {
  color: #fff;
}
.search-box input:focus {
  outline: none;
}
.search-box i {
  position: absolute;
  right: 0;
  bottom: 1px;
  cursor: pointer;
}
.resource-tab-wrapper {
  display: inline-flex;
  background: transparent;
  width: 100%;
  flex-wrap: wrap;
  align-items: end;
}
.resource-tab-wrapper .MuiFormControl-root {
  vertical-align: bottom;
  align-items: center;
  width: 180px;
  margin-right: 20px;
}
.resource-tab-wrapper .MuiFormControl-root .MuiInputBase-root,
.resource-tab-wrapper .MuiFormControl-root .category-list {
  position: relative;
  width: 100%;
}
.resource-tab-wrapper .MuiFormControl-root .MuiInputBase-root span,
.resource-tab-wrapper .MuiFormControl-root .category-list span {
  color: #fff;
  border: 1px solid #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 10px 25px;
  display: block;
  position: relative;
  cursor: pointer;
  font-family: "Object Sans Heavy";
}
.resource-tab-wrapper .MuiFormControl-root .MuiInputBase-root ul,
.resource-tab-wrapper .MuiFormControl-root .category-list ul {
  padding: 0px;
  margin: 5px 0px;
  list-style-type: none;
  border-radius: 0px 0px 8px 8px;
  border: 2px solid rgba(21, 105, 130, 0.99);
  position: absolute;
  top: 100%;
  width: 100%;
}
.resource-tab-wrapper .MuiFormControl-root .MuiInputBase-root ul li,
.resource-tab-wrapper .MuiFormControl-root .category-list ul li {
  padding: 10px 20px;
  color: #fff;
  border-bottom: 1px solid #208F71;
  cursor: pointer;
  position: relative;
}
.resource-tab-wrapper .MuiFormControl-root .MuiInputBase-root ul li::before,
.resource-tab-wrapper .MuiFormControl-root .category-list ul li::before {
  content: "";
  position: absolute;
  width: 8px;
  height: 100%;
  background: #FFDF70;
  left: 0;
  top: 0;
  display: none;
}
.resource-tab-wrapper .MuiFormControl-root .MuiInputBase-root ul li:hover::before,
.resource-tab-wrapper .MuiFormControl-root .category-list ul li:hover::before {
  display: block;
}
.resource-tab-wrapper .MuiFormControl-root .MuiInputBase-root ul li:last-child,
.resource-tab-wrapper .MuiFormControl-root .category-list ul li:last-child {
  border-bottom: 0px;
}
.resource-tab-wrapper .MuiFormControl-root .MuiInputBase-root ul li.selected-option,
.resource-tab-wrapper .MuiFormControl-root .category-list ul li.selected-option {
  font-weight: 800;
}
.resource-tab-wrapper .MuiFormControl-root .MuiInputBase-root ul li.selected-option::before,
.resource-tab-wrapper .MuiFormControl-root .category-list ul li.selected-option::before {
  content: "";
  position: absolute;
  width: 8px;
  height: 100%;
  background: #FFDF70;
  left: 0;
  top: 0;
  display: block;
}
.resource-tab-wrapper .MuiFormControl-root .MuiInputBase-root .MuiSelect-select.MuiSelect-select,
.resource-tab-wrapper .MuiFormControl-root .category-list .MuiSelect-select.MuiSelect-select {
  padding: 10px 30px 10px 15px;
  min-width: 100px;
}
.resource-tab-wrapper .MuiFormControl-root .MuiInputBase-root .MuiSelect-select.MuiSelect-select em,
.resource-tab-wrapper .MuiFormControl-root .category-list .MuiSelect-select.MuiSelect-select em {
  font-style: normal;
}
.resource-tab-wrapper .MuiFormControl-root .MuiInputBase-root svg,
.resource-tab-wrapper .MuiFormControl-root .category-list svg {
  display: none;
}
.resource-tab-wrapper .MuiFormControl-root .MuiInputBase-root::after,
.resource-tab-wrapper .MuiFormControl-root .category-list::after {
  content: '';
  position: absolute;
  right: 10px;
  background: url("/assets/img/events/dropdown-white.svg");
  width: 15px;
  height: 8px;
  left: auto;
  transform: none;
  top: 0px;
  margin: auto;
  bottom: 0px;
  border: 0px;
  cursor: pointer;
}
.resource-tab-wrapper .tab-content {
  display: flex;
}
.resource-tab-wrapper .tab-content .MuiBox-root {
  width: 100%;
  padding: 25px 0px;
}
.resource-tab-wrapper .tab-content .MuiBox-root p {
  text-align: left;
  justify-content: flex-end;
  display: flex;
}
.resource-tab-wrapper .MuiTabs-root {
  border-bottom: 1px solid rgba(242, 247, 244, 0.5);
  width: 100%;
}
.resource-tab-wrapper .MuiTabs-root .MuiButtonBase-root .MuiTab-wrapper {
  font-weight: 800;
  color: #fff;
  font-family: "Object Sans Heavy";
  text-transform: capitalize;
  font-size: 16px;
}
.MuiTabs-indicator {
  background-color: #24A989 !important;
  height: 3px !important;
}
.resources-tab-view {
  display: flex;
  align-items: flex-start;
  position: relative;
}
.events-wrapper .events-inner header.MuiAppBar-root {
  background: transparent;
  box-shadow: none;
  width: 50%;
}
.MuiPagination-ul {
  justify-content: center;
}
.MuiPagination-ul li .MuiPaginationItem-root {
  color: #fff;
  font-family: "Object Sans";
  font-size: 16px;
}
.MuiPagination-ul li .MuiPaginationItem-root.Mui-selected {
  background: #009CA4;
}
.no-card-record {
  display: flex;
  justify-content: center;
  color: #fff;
  flex-direction: column;
  text-align: center;
  min-height: 400px;
}
.no-card-record b {
  display: flex;
  font-size: 16px;
  justify-content: center;
}
.no-card-record strong {
  font-size: 21px;
}
.no-card-record em {
  font-style: normal;
  font-size: 16px;
}
