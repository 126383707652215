.events-wrapper .events-inner {
  padding: 30px 0px;
  max-width: 85%;
  margin: 0px auto;
  position: relative;
  z-index: 1;
}
.events-wrapper .events-inner p,
.events-wrapper .events-inner h1 {
  color: #fff;
  text-align: center;
  margin-bottom: 0px;
  font-size: 24px;
}
.events-wrapper .events-inner header.MuiAppBar-root {
  display: block !important;
}
.events-wrapper .events-inner .events-top-wrap {
  margin: 80px 0px;
}
.events-inner-content.events-main-wrapper {
  width: calc(100% - 200px);
}
.events-inner-content .latest-events,
.events-inner-content .past-events {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  margin-bottom: 50px;
}
.events-inner-content .latest-events h3,
.events-inner-content .past-events h3 {
  font-size: 18px;
  margin-bottom: 25px;
  display: block;
}
.events-inner-content .latest-events .event-card-wrapper,
.events-inner-content .past-events .event-card-wrapper {
  border: 2px solid #24A989;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  background: rgba(41, 45, 70, 0.5);
}
.events-inner-content .latest-events .event-card-wrapper .event-img,
.events-inner-content .past-events .event-card-wrapper .event-img {
  width: 45%;
  overflow: hidden;
}
.events-inner-content .latest-events .event-card-wrapper .event-img img,
.events-inner-content .past-events .event-card-wrapper .event-img img {
  width: 100%;
  object-fit: cover;
}
.events-inner-content .latest-events .event-card-wrapper .event-content,
.events-inner-content .past-events .event-card-wrapper .event-content {
  width: 55%;
  padding: 0px 15px;
  display: flex;
  flex-wrap: wrap;
}
.events-inner-content .latest-events .event-card-wrapper .event-content .content-top,
.events-inner-content .past-events .event-card-wrapper .event-content .content-top {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.events-inner-content .latest-events .event-card-wrapper .event-content .content-top span,
.events-inner-content .past-events .event-card-wrapper .event-content .content-top span {
  color: #D9D9D9;
  font-size: 14px;
  display: inline-flex;
}
.events-inner-content .latest-events .event-card-wrapper .event-content .content-top span.subtext-content,
.events-inner-content .past-events .event-card-wrapper .event-content .content-top span.subtext-content {
  margin: 0 0px 20px;
  color: #fff;
}
.events-inner-content .latest-events .event-card-wrapper .event-content .content-top strong,
.events-inner-content .past-events .event-card-wrapper .event-content .content-top strong {
  width: 100%;
  display: inline-flex;
  margin: 15px 0px;
  color: #fff;
}
.events-inner-content .latest-events .event-card-wrapper .event-content .content-top p,
.events-inner-content .past-events .event-card-wrapper .event-content .content-top p {
  font-size: 14px;
  margin-bottom: 15px;
}
.events-inner-content .latest-events .event-card-wrapper .event-content .content-top a,
.events-inner-content .past-events .event-card-wrapper .event-content .content-top a {
  background: #FFCC4A;
  color: #35363D;
  font-size: 14px;
  font-family: "Object Sans Heavy";
  width: auto;
  padding: 10px;
  border-radius: 4px;
  display: inline-flex;
}
.events-inner-content .latest-events .event-card-wrapper .event-content .content-top a i,
.events-inner-content .past-events .event-card-wrapper .event-content .content-top a i {
  margin-left: 5px;
}
.events-inner-content .latest-events .event-card-wrapper .event-content .content-bottom,
.events-inner-content .past-events .event-card-wrapper .event-content .content-bottom {
  display: flex;
  align-items: flex-end;
}
.events-inner-content .latest-events .event-card-wrapper .event-content .content-bottom em,
.events-inner-content .past-events .event-card-wrapper .event-content .content-bottom em {
  font-style: normal;
  color: #92F2FF;
  font-size: 16px;
}
.events-inner-content .past-events {
  margin-bottom: 0px;
  min-height: 550px;
}
.events-inner-content .past-events .event-card-wrapper {
  margin-bottom: 30px;
}
.events-inner-content .past-events .event-card-wrapper:hover {
  background: rgba(1, 26, 35, 0.75);
  border: 2px solid #FFCC4A;
}
.events-inner-content .past-events .event-card-wrapper:nth-of-type(3) {
  margin-bottom: 0px;
}
.events-inner-content .past-events .event-card-wrapper .event-img {
  width: 30%;
  height: 165px;
  overflow: hidden;
}
.events-inner-content .past-events .event-card-wrapper .event-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.events-inner-content .past-events .event-card-wrapper .event-content {
  width: 70%;
  display: flex;
  align-items: flex-start;
}
.events-inner-content .past-events .event-card-wrapper .event-content span {
  width: 100%;
}
.events-inner-content .past-events .event-card-wrapper .event-content strong {
  width: 100%;
  margin: 15px 0px;
}
.news-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.news-wrapper .latest-news {
  width: 100%;
  margin-bottom: 30px;
}
.news-wrapper .latest-news h3 {
  font-size: 18px;
  margin-bottom: 25px;
  display: block;
}
.news-wrapper .latest-news .latest-news-wrapper {
  border-radius: 0px 0px 10px 10px;
  border: 2px solid #1F6BAA;
  background: rgba(1, 26, 35, 0.75);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  padding: 15px;
  position: relative;
}
.news-wrapper .latest-news .latest-news-wrapper::after {
  content: '';
  position: absolute;
  width: 90%;
  height: 90%;
  top: 0;
  left: 0;
  background: url('/assets/img/resource/world-bg.png') no-repeat;
  z-index: -1;
}
.news-wrapper .latest-news .latest-news-wrapper .slick-slider .slick-arrow.slick-next::before,
.news-wrapper .latest-news .latest-news-wrapper .slick-slider .slick-arrow.slick-prev::before {
  background-color: #FFCC4A;
}
.news-wrapper .latest-news .latest-news-wrapper .slick-slider .slick-arrow.slick-next {
  right: -40px;
}
.news-wrapper .latest-news .latest-news-wrapper .slick-slider .slick-arrow.slick-prev {
  left: -40px;
}
.news-wrapper .latest-news .latest-news-wrapper .latest-news-card {
  background: #fff;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  width: 95% !important;
  margin: 0 5% 0 2.5%;
  position: relative;
  z-index: 1;
  cursor: pointer;
}
.news-wrapper .latest-news .latest-news-wrapper .latest-news-card:nth-of-type(2n) {
  margin-right: 0px;
}
.news-wrapper .latest-news .latest-news-wrapper .latest-news-card .card-img {
  height: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.news-wrapper .latest-news .latest-news-wrapper .latest-news-card .card-img img {
  width: 100%;
  height: 100%;
}
.news-wrapper .latest-news .latest-news-wrapper .latest-news-card span {
  border-radius: 5px;
  background: rgba(146, 242, 255, 0.7);
  color: #292D46;
  font-size: 14px;
  padding: 5px;
  font-family: "Object Sans";
}
.news-wrapper .latest-news .latest-news-wrapper .latest-news-card b {
  color: #292D46;
  width: 100%;
  display: block;
  margin: 10px 0px;
  min-height: 72px;
  font-size: 16px;
}
.news-wrapper .latest-news .latest-news-wrapper .latest-news-card .news-location {
  display: flex;
  flex-direction: column;
}
.news-wrapper .latest-news .latest-news-wrapper .latest-news-card .news-location em {
  color: #292D46;
  font-style: normal;
  text-transform: uppercase;
  font-size: 14px;
}
.news-wrapper .past-news {
  width: 100%;
}
.news-wrapper .past-news h3 {
  font-size: 18px;
  margin-bottom: 25px;
  display: block;
}
.news-wrapper .past-news .news-card-wrapper {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100%;
}
.news-wrapper .past-news .news-card-wrapper .news-card {
  width: 31%;
  padding: 20px;
  border-radius: 0px 0px 10px 10px;
  border: 2px solid #24A989;
  background: rgba(41, 45, 70, 0.5);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  margin-bottom: 2%;
  margin-right: 2%;
  cursor: pointer;
}
.news-wrapper .past-news .news-card-wrapper .news-card:nth-of-type(3n) {
  margin-right: 0;
}
.news-wrapper .past-news .news-card-wrapper .news-card .news-img {
  background: #D9D9D9;
  width: 100%;
  height: 165px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.news-wrapper .past-news .news-card-wrapper .news-card .news-img img {
  max-width: 90%;
}
.news-wrapper .past-news .news-card-wrapper .news-card .news-category label {
  border-radius: 5px;
  background: rgba(146, 242, 255, 0.4);
  padding: 5px 10px;
  margin-top: 15px;
  color: #292D46;
  text-align: center;
  font-family: "Object Sans";
  font-size: 14px;
}
.news-wrapper .past-news .news-card-wrapper .news-card .news-title {
  margin: 15px 0px;
}
.news-wrapper .past-news .news-card-wrapper .news-card .news-title b {
  font-size: 18px;
  font-family: "Object Sans";
  line-height: 24px;
}
.news-wrapper .past-news .news-card-wrapper .news-card .news-location {
  color: #D9D9D9;
  font-family: "Object Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  flex-direction: column;
}
.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 0px !important;
}
@media only screen and (max-width: 767px) {
  .events-inner-content.events-main-wrapper {
    width: 100%;
  }
  .events-inner-content .latest-events .event-card-wrapper,
  .events-inner-content .past-events .event-card-wrapper {
    flex-direction: column;
  }
  .events-inner-content .latest-events .event-card-wrapper .event-img,
  .events-inner-content .past-events .event-card-wrapper .event-img {
    width: 100%;
    height: auto;
  }
  .events-inner-content .latest-events .event-card-wrapper .event-content,
  .events-inner-content .past-events .event-card-wrapper .event-content {
    width: 100%;
    padding: 0px;
    margin-top: 15px;
  }
}
@media only screen and (max-width: 480px) {
  div.news-wrapper .past-news .news-card-wrapper div.news-card {
    width: 100%;
    margin-bottom: 15px;
  }
}
