.portfolio .main-head-1 .primary-span {
  line-height: 45px;
  font-size: 58px;
}
.portfolio .mp-common-hold .more-text {
  margin-top: 5px!important;
  font-size: 15px;
}
.portfolio .mp-common-hold .more-text a {
  margin-top: 5px;
}
.portfolio .mp-common-hold .more-text a img {
  width: 115px;
}
.tab-sec .nav-pills {
  justify-content: center;
  margin-top: -50px;
}
.tab-sec .nav-pills .nav-link {
  font-family: 'Object Sans Heavy';
  font-size: 18px;
  line-height: 20px;
  color: #1E4B5E;
  border-radius: 0px;
  padding: 8px 0px;
  margin: 0px 20px;
  cursor: pointer;
  transition: all 0.5s ease;
  border-bottom: 5px solid transparent;
}
.tab-sec .nav-pills .nav-link:hover {
  color: #007CC1;
  background-color: transparent;
  border-bottom: 5px solid #007cc1;
}
.tab-sec .nav-pills .nav-link.active {
  color: #007CC1;
  background-color: transparent;
  border-bottom: 5px solid #007cc1;
}
.pad-class {
  padding: 50px 0px !important;
}
.tab-content {
  width: 100%;
}
.tab-content .tab-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}
.tab-content .tab-head .caption-sec p {
  margin-bottom: 0px;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #1E4B5E;
  width: 70%;
}
.port-card {
  margin: 0px 0px 40px;
  background: #fff;
  border-radius: 10px;
  padding: 15px;
  min-height: 320px;
  transition: all 0.5s ease-in;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.port-card:hover {
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
  transition: all 0.5s ease-in;
}
.port-card .card-img {
  background: #FFFFFF;
  box-shadow: 1px 1px 0px #FFD028;
  border-radius: 3px;
  margin-bottom: 20px;
  width: 100%;
  height: auto;
  overflow: hidden;
}
.port-card .card-img img {
  width: 100%;
}
.port-card .card-head h4 {
  font-weight: bold;
  font-size: 32px;
  line-height: 36px;
  color: #1E4B5E;
  margin-bottom: 10px;
  font-family: 'Heldane Display';
}
.port-card .card-cap p {
  font-weight: normal;
  font-size: 16px !important;
  line-height: 22px;
  color: #1E4B5E;
  margin-bottom: 10px;
  padding: 0px;
  min-height: 90px;
  font-weight: 800;
  font-family: "Object Sans Heavy";
}
.view-more {
  text-align: center;
  width: 100%;
}
.view-more button {
  background: #FFCC4A;
  border: 2px solid #FFCC4A;
  box-sizing: border-box;
  border-radius: 3px;
  color: #1E4B5E;
  font-family: 'Object Sans Heavy';
  font-size: 18px;
  line-height: 19px;
  text-align: center;
  padding: 8px 35px;
  height: 60px;
  min-width: 190px;
  transition: all 0.2s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
.view-more button span {
  display: block;
}
.view-more button:focus {
  outline: none;
  box-shadow: none;
}
.view-more button:hover {
  box-shadow: 0px 6px 10px 0px rgba(30, 75, 94, 0.2);
}
.rotate-view-more {
  transform: rotate(180deg);
  margin-top: 7px;
}
.list {
  border-top: 1px solid #C7D0D3;
  padding: 20px 0px;
  margin: 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.list .list-capt {
  width: 75%;
  padding-right: 20px;
}
.list .list-capt h4 {
  font-family: 'Heldane Display';
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 52px;
  color: #1E4B5E;
  position: relative;
  z-index: 1;
  display: inline-block;
}
.list .list-capt h4 span {
  background-position: 0 23px;
  background-size: 40px 10px;
  background-repeat: repeat-x;
}
.list .list-capt p {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 15px;
  color: #1E4B5E;
  line-height: 28px;
}
.list .download-btn button {
  background: #007CC1;
  border: 1px solid #007cc1;
  border-radius: 3px;
  font-family: 'Object Sans Heavy';
  font-size: 18px;
  line-height: 19px;
  text-align: center;
  color: #FFFFFF;
  padding: 15px 20px;
  height: 60px;
  min-width: 190px;
  display: flex;
  align-items: center;
}
.list .download-btn button span img {
  margin-top: -5px;
  margin-right: 10px;
}
.list:last-child {
  border-bottom: 1px solid #C7D0D3;
  margin-bottom: 30px;
}
.cardClick .content {
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: auto;
}
.cardClick .content .content-overlay {
  background: rgba(10, 11, 11, 0.8);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
  z-index: 1;
}
.cardClick:hover .content .content-overlay {
  opacity: 1;
}
.content-image {
  width: 100%;
}
.content-details {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  z-index: -1;
}
.cardClick:hover .content .content-details {
  top: 50%;
  left: 50%;
  opacity: 1;
  z-index: 2;
}
.content-details p {
  font-size: 15px;
  line-height: 19px;
  color: #FFFFFF;
}
.content-details.fadeIn-bottom {
  top: 100%;
}
a.port-btn {
  border: 3px solid #FFFFFF;
  font-family: 'Object Sans Heavy';
  background-color: transparent;
  font-size: 14px;
  line-height: 25px;
  color: #FFFFFF;
  height: auto;
  padding: 15px;
  margin-top: 5px;
  display: inline-block;
}
a.port-btn:hover {
  color: #fff;
}
.tab-case .view-more {
  margin: 10px 0px 25px;
}
.tab-design .view-more {
  margin: 50px 0px 0px;
}
.tab-card.porti-card {
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  margin: auto;
}
div#pills-home {
  margin-bottom: 20px !important;
}
.portfolio .section-1 {
  background: linear-gradient(101.06deg, #0E2E40 0%, #145554 52.87%, #208F71 99.96%) !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
}
.portfolio .section-1 .custom-container {
  flex-wrap: wrap;
  justify-content: center;
}
.portfolio .section-1 h1 {
  font-family: "Heldane display";
  font-weight: 800;
  color: #FFCC4A;
  margin-bottom: 20px !important;
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
  font-size: 46px;
  line-height: 60px;
}
.portfolio .section-1 p {
  text-align: center;
  font-family: "object sans";
  color: #fff;
  line-height: 30px;
  margin: 0px;
  width: 100%;
}
.portfolio .section-1 p br {
  display: block !important;
}
.portfolio .section-1 .top-section-btn .connect-btn {
  background: #FFCC4A !important;
  color: #1E4B5E !important;
  font-size: 28px;
  font-family: "Object Sans Heavy";
  margin: 50px 0px 0px;
}
.portfolio .section-2 {
  padding-bottom: 0px;
  padding-top: 50px;
}
.portfolio .section-2 .main-head-1 .primary-span {
  font-size: 54px;
  line-height: 65px;
}
.portfolio .section-2 .main-head-1 .secondary-span {
  font-size: 30px;
  line-height: 35px;
}
.portfolio .section-2 .custom-container {
  flex-wrap: wrap;
}
.portfolio .section-2 .custom-container .tab-wrapper {
  position: relative;
  padding-bottom: 20px;
  width: 100%;
}
.portfolio .section-2 .custom-container .tab-wrapper::before {
  content: '';
  background: rgba(255, 204, 74, 0.4);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 59px;
  left: 0px;
  height: 5px;
  width: 100%;
  border-radius: 70px;
}
.portfolio .section-2 .custom-container .tab-wrapper .MuiAppBar-root.MuiAppBar-positionStatic {
  display: block !important;
  box-shadow: none;
  background: transparent;
  position: relative;
}
.portfolio .section-2 .custom-container .tab-wrapper .MuiAppBar-root.MuiAppBar-positionStatic button.MuiTab-root {
  padding: 6px 12px 15px 6px;
}
.portfolio .section-2 .custom-container .tab-wrapper .MuiAppBar-root.MuiAppBar-positionStatic button.MuiTab-root .MuiTab-wrapper img {
  width: 60%;
  filter: grayscale(100%);
  min-height: 45px;
}
.portfolio .section-2 .custom-container .tab-wrapper .MuiAppBar-root.MuiAppBar-positionStatic button.MuiTab-root.Mui-selected .MuiTab-wrapper img {
  width: 80%;
  filter: grayscale(0%);
}
.portfolio .section-2 .custom-container .tab-wrapper .MuiAppBar-root.MuiAppBar-positionStatic .MuiTabs-indicator {
  background: #1E4B5E !important;
  border-radius: 30px;
  height: 5px!important;
  bottom: 2px;
  z-index: 1;
}
.portfolio .section-2 .custom-container .tab-wrapper .MuiBox-root {
  padding: 10px 0px;
  max-height: 500px;
  overflow: hidden;
}
.portfolio .section-2 .custom-container .tab-wrapper .MuiBox-root .inner-content {
  position: relative;
  min-height: 350px;
  background-size: cover;
  align-items: center;
  justify-content: flex-start;
  display: inline-flex;
  background-size: cover !important;
  background-attachment: fixed !important;
  background-position: 0px 60% !important;
}
.portfolio .section-2 .custom-container .tab-wrapper .MuiBox-root .inner-content::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0px;
  width: 100%;
  background: rgba(20, 81, 82, 0.3);
}
.portfolio .section-2 .custom-container .tab-wrapper .MuiBox-root .inner-content img {
  width: 100%;
}
.portfolio .section-2 .custom-container .tab-wrapper .MuiBox-root .inner-content .inner-detail {
  background: #fff;
  padding: 30px;
  z-index: 1;
  width: 40%;
  border-top-left-radius: 50px;
  border-bottom-right-radius: 50px;
  display: flex;
  flex-wrap: wrap;
  margin: 30px 30px 30px 50px;
  min-height: 200px;
}
.portfolio .section-2 .custom-container .tab-wrapper .MuiBox-root .inner-content .inner-detail b {
  color: #1E4B5E;
  width: 100%;
  line-height: 24px;
  font-family: "Object Sans Heavy";
  margin-bottom: 20px;
  min-height: 100px;
}
.portfolio .section-2 .custom-container .tab-wrapper .MuiBox-root .inner-content .inner-detail a {
  font-family: "Object Sans";
  font-weight: 800;
  font-size: 14px;
  display: flex;
  color: #007CC1;
  align-items: center;
}
.portfolio .section-2 .custom-container .tab-wrapper .MuiBox-root .inner-content .inner-detail a i {
  margin-left: 5px;
}
.portfolio .section-2 .custom-container .tab-wrapper::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 4px;
  bottom: 0px;
  border-radius: 70px;
  background: #FFF;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
}
.portfolio .section-2 .main-head-div {
  width: 100%;
  margin-bottom: 30px;
}
.portfolio .section-3 {
  padding-top: 50px;
}
.portfolio .section-4.pad-class {
  padding-top: 20px!important;
}
.portfolio .section-4 .testi-card {
  height: 380px!important;
}
.portfolio .section-4 .testi-card .port-logo {
  min-height: 65px;
  padding-top: 10px;
}
.portfolio .section-4 .testi-card .testi-review-common {
  padding: 20px;
}
.portfolio .section-4 .testi-detailed-review a {
  font-size: 14px;
}
.portfolio .section-4 .testi-footer {
  min-height: 75px!important;
}
.portfolio .section-4 .testi-footer .testi-comp-logo {
  width: 50px!important;
  height: 50px!important;
  min-width: 50px!important;
}
.portfolio .section-4 .testi-footer .testi-comp-info p:first-child {
  font-size: 18px!important;
  line-height: 20px!important;
}
.portfolio .section-4 .testi-footer .testi-comp-info p:last-child {
  font-size: 15px!important;
  line-height: 19px;
}
.portfolio .section-5 .custom-container {
  position: relative;
}
.portfolio .section-5 .custom-container::after {
  content: '';
  position: absolute;
  right: 0;
  background: url("/assets/img/portfolio-img/social-media.svg") no-repeat;
  width: 320px;
  opacity: 0.5;
  height: 280px;
}
.portfolio .section-5 .custom-container a.connect-btn {
  background: #FFCC4A !important;
  color: #1E4B5E !important;
  border: 1px solid #FFCC4A !important;
  width: 320px !important;
}
.portfolio-drop button {
  background: #FFFFFF;
  border: 1px solid #C7D0D3;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  color: #007CC1;
  padding: 10px 15px;
  min-width: 190px;
}
.portfolio-drop button span {
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #1E4B5E;
  background: #C7D0D3;
  border-radius: 3px;
  padding: 0px 6px;
  margin: 0px 10px;
  display: inline-block;
  vertical-align: middle;
  font-family: 'Poppins', sans-serif;
}
.portfolio-drop button::after {
  vertical-align: 2px;
  content: "";
  border: solid #007CC1;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(45deg);
  transition: all 0.2s ease;
  margin-left: 10px;
}
.portfolio-drop .dropdown-menu {
  display: block;
  padding: 0px;
  min-width: 100%;
}
.portfolio-drop .dropdown-menu .dropdown-item {
  padding: 7px 18px;
  color: #1E4B5E;
  cursor: pointer;
}
.portfolio-drop .dropdown-menu .dropdown-item:hover {
  color: #007CC1;
  background-color: transparent;
}
.portfolio-drop .dropdown-menu .dropdown-item:focus {
  color: #007CC1;
  background-color: transparent;
  border: 1px solid transparent;
}
.no-data {
  width: 100%;
  font-family: 'Object Sans Heavy';
  font-size: 20px;
  line-height: 20px;
  color: #1E4B5E;
}
header {
  display: none !important;
}
.container {
  width: 90% !important;
  margin: 0 auto;
  max-width: 1400px !important;
}
.heading {
  text-align: center;
  width: 70%;
  margin: 0 auto;
  font-size: 30px;
  color: #2CAAE1;
  line-height: 40px;
  letter-spacing: 1px;
  font-family: 'proxima_nova_thextrabold';
}
.inner-heading {
  width: 100%;
  color: #2CAAE1;
  font-size: 32px;
  font-family: 'proxima_nova_thextrabold';
  line-height: 40px;
}
.left-content {
  width: 50%;
  float: left;
  display: inline-block;
}
.inner-task-flow .left-content strong {
  color: #000;
  font-weight: 600;
  font-size: 16px;
}
.inner-task-flow .left-content p {
  margin: 0;
  padding-bottom: 10px;
}
.inner-task-flow .left-content .what-p {
  padding: 15px 0;
}
.inner-task-flow .left-content .how-p {
  padding-top: 10px;
}
.inner-task-flow .left-content p:first-child {
  padding: 15px 0px;
}
.right-content {
  width: 50%;
  display: inline-block;
}
.right-content .sub-heading {
  margin: 0;
  margin-top: 30px;
  padding-bottom: 25px;
}
.right-content p {
  margin-bottom: 25px;
  line-height: 22px;
}
.logo img {
  width: 150px;
}
.p2e-portfolio p {
  color: #323232;
  font-size: 14px;
  line-height: 24px;
}
h3.sub-heading {
  font-family: 'proxima_novabold';
  color: #f24f00 !important;
  font-size: 22px !important;
  /*	padding-bottom: 25px;*/
  margin: 20px 0px;
}
.sub-heading p {
  padding: 10px 0px;
  margin: 0;
}
.p2e-portfolio .blue-bg h3:after {
  display: none;
}
.p2e-portfolio .right-content img {
  width: 100%!important;
}
/*=====first section style======*/
.bb {
  border-bottom: 1px dotted #33c0d3;
}
.first-section {
  padding: 50px 0;
  background: url(/assets/img/portfolio-img/p2e/top-bg.png) no-repeat;
  background-size: cover;
}
.first-section .left-content i {
  display: block;
  text-align: center;
  padding: 50px 0;
  position: relative;
}
.first-section .left-content i::after {
  position: absolute;
  content: '';
  top: 20px;
  right: 50px;
  width: 60px;
  height: 40px;
  border-bottom: 3px solid #33c0d3;
  border-right: 3px solid #33c0d3;
}
.first-section .left-content i::before {
  position: absolute;
  content: '';
  top: 20px;
  left: 50px;
  width: 60px;
  height: 40px;
  border-bottom: 3px solid #33c0d3;
  border-left: 3px solid #33c0d3;
}
.first-section .right-content {
  position: relative;
}
.first-section .right-content img {
  margin-left: 50px;
}
.first-section .right-content::after {
  position: absolute;
  content: '';
  height: 50px;
  width: 60px;
  border-right: 3px solid #fff;
  border-top: 3px solid #fff;
  top: -25px;
  right: 80px;
}
.first-section .right-content::before {
  position: absolute;
  content: '';
  height: 50px;
  width: 60px;
  border-right: 3px solid #fff;
  border-bottom: 3px solid #fff;
  bottom: 30px;
  right: 80px;
}
.first-section .logo {
  padding: 50px 0 20px;
}
.p2e-portfolio .blue-bg {
  background: rgba(158, 217, 225, 0.54) !important;
  float: left;
  width: 100%;
}
.first-section p {
  margin: 30px 0;
}
.first-section p b {
  color: #F2631F;
}
/*=======second section========*/
.second-section h2,
.third-section h2 {
  margin-bottom: 50px;
}
.second-section,
.inner-third-section {
  padding: 60px 0;
  position: relative;
}
.second-section .right-content {
  position: relative;
}
.second-section .right-content .the-need {
  margin-left: -120px;
}
.second-section .right-content .these-challenges {
  margin-top: -50px;
  animation-delay: 0.3s;
}
.second-section .right-content .card {
  background: #fff;
  box-shadow: -1px 0px 15px #ddd;
  padding: 60px;
  position: relative;
  width: 90%;
  height: auto;
  padding-bottom: 45px;
}
.second-section .left-content .need-img {
  margin-left: 140px;
  z-index: 1;
  margin-top: 60px;
}
.second-section .left-content .challenge-img {
  margin-left: 260px;
  z-index: 1;
  margin-top: 70px;
}
.second-section .right-content .card::after {
  width: 60px;
  height: 40px;
  position: absolute;
  right: 0;
  top: 0;
  content: '';
  border-top: 3px solid #2CAAE1;
  border-right: 3px solid #2CAAE1;
}
.second-section .right-content .card::before {
  width: 60px;
  height: 40px;
  position: absolute;
  left: 0;
  bottom: 0;
  content: '';
  border-bottom: 3px solid #2CAAE1;
  border-left: 3px solid #2CAAE1;
}
.second-section .right-content .card strong {
  font-size: 22px;
  color: #000;
  font-weight: 600;
}
.second-section .right-content .card p {
  padding: 10px 0;
}
.second-section .left-content .need-img,
.second-section .left-content .challenge-img {
  width: 180px;
  height: 180px;
  border-radius: 100%;
  border: 10px solid #e3e2e2;
  position: relative;
}
.second-section .left-content .need-img::after,
.second-section .left-content .challenge-img::after {
  width: 500px;
  height: 250px;
  position: absolute;
  content: '';
  background: url(/assets/img/portfolio-img/p2e/circle-outline.png) no-repeat;
  top: -30px;
  right: -275px;
}
.second-section .left-content img {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.inner-third-section .heading {
  position: relative;
  margin-bottom: 120px;
}
.inner-third-section .heading::after {
  position: absolute;
  content: '';
  height: 70px;
  width: 25px;
  background: url(/assets/img/portfolio-img/p2e/small-straight.png);
  left: 0;
  right: 0;
  bottom: -70px;
  margin: auto;
}
.inner-third-section::before {
  position: absolute;
  content: '';
  height: 70px;
  width: 635px;
  background: url(/assets/img/portfolio-img/p2e/line.png) no-repeat;
  top: 170px;
  left: -130px;
  right: 0;
  margin: auto;
}
.inner-third-section {
  padding-bottom: 100px;
}
.inner-third-section::after {
  position: absolute;
  content: '';
  height: 70px;
  width: 635px;
  background: url(/assets/img/portfolio-img/p2e/line1.png) no-repeat;
  bottom: 50px;
  left: -120px;
  right: 0;
  margin: auto;
}
.inner-third-section .left-content img {
  width: 60%;
  margin-left: 15%;
}
.inner-ideation,
.inner-wireframe,
.inner-visual {
  padding: 150px 0 250px;
}
.inner-wireframe {
  padding-bottom: 180px;
}
.inner-visual {
  padding-bottom: 100px;
}
.inner-ideation .left-content img {
  margin: 0 auto;
  display: block;
}
.task-flow,
.visual-design,
.inner-user-story,
.second-section {
  clear: both;
}
.inner-task-flow {
  padding: 100px 0;
}
.inner-user-story {
  padding: 50px 0;
  position: relative;
  float: left;
}
.inner-user-story::before,
.inner-user-story::after {
  position: absolute;
  content: '';
  top: -60px;
  height: 150px;
  width: 25px;
  left: 0;
  right: 0;
  background: url(/assets/img/portfolio-img/p2e/big-straight.png) no-repeat;
  margin: auto;
}
.inner-user-story::after {
  bottom: -90px;
  top: inherit;
  z-index: 1;
}
.user-admin {
  display: flex;
  padding-top: 25px;
}
.user-stories {
  text-align: center;
}
.user-stories p {
  padding-bottom: 10px;
}
.user-stories .user-ex {
  width: 50%;
  margin: 0 auto;
}
.ideation {
  position: relative;
  z-index: -1;
}
.ideation::before {
  position: absolute;
  content: '';
  top: 80px;
  left: 0;
  right: 0;
  margin: auto;
  background: url(/assets/img/portfolio-img/p2e/line.png) no-repeat;
  width: 635px;
  height: 45px;
}
.ideation::after {
  position: absolute;
  content: '';
  bottom: 80px;
  left: 0;
  right: 0;
  margin: auto;
  background: url(/assets/img/portfolio-img/p2e/line1.png) no-repeat;
  width: 635px;
  height: 55px;
}
.inner-visual .left-content .inner-visual-content {
  padding: 0 150px 0 0;
  margin-top: 50px;
}
.task-flow {
  position: relative;
  z-index: 1;
}
.task-flow::after,
.visual-design::after {
  position: absolute;
  content: '';
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 30%;
  background: rgba(246, 246, 246, 0.84);
  z-index: -1;
}
.first-section .left-content .inner-management-process {
  padding: 0 50px 0 0;
}
.business-value li {
  list-style-type: none;
  width: 49%;
  display: inline-block;
  vertical-align: middle;
  padding: 40px 0px;
}
.business-value li i {
  width: 82px;
  display: inline-block;
  float: left;
  text-align: center;
  height: 82px;
  position: relative;
  border: 6px solid #e3e2e2;
  border-radius: 100%;
  vertical-align: middle;
  margin-right: 40px;
}
.business-value li i img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.business-value li span {
  display: inline-block;
  vertical-align: middle;
  width: 66%;
  padding: 20px 0;
  font-size: 14px;
}
.super-admin,
.organization-admin {
  float: left;
  width: 50%;
  display: inline-block;
  padding: 0;
}
.super-admin li,
.organization-admin li {
  list-style-type: none;
  position: relative;
  padding: 20px 0;
  text-align: center;
}
.super-admin .main-label,
.organization-admin .main-label {
  display: block;
  text-align: center;
  font-family: 'proxima_novabold';
  font-size: 14px;
  color: #4a4a4a;
}
.super-admin button,
.organization-admin button {
  background: #33c0d3;
  border: 0;
  color: #fff;
  padding: 12px 30px;
  width: 210px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.12);
  margin: 0 auto;
  display: inherit;
  position: relative;
}
.organization-admin button {
  background: #e35d1c;
}
.super-admin button::after,
.organization-admin button::after {
  position: absolute;
  content: '';
  right: -130px;
  top: 20px;
  height: 40px;
  width: 100px;
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
.super-admin button::before,
.organization-admin button::before {
  position: absolute;
  content: '';
  left: -130px;
  top: 20px;
  height: 40px;
  width: 100px;
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
}
.super-admin li label,
.organization-admin li label {
  position: absolute;
  left: -50px;
  top: 35px;
  z-index: 1;
  bottom: 0;
  margin: auto;
  width: 70px;
  text-align: right;
  color: #4a4a4a;
  font-size: 16px;
}
/*.super-admin li span, .organization-admin li span{
	background: #fff;
	padding: 10px;
}*/
.super-admin li:nth-of-type(2) span,
.organization-admin li:nth-of-type(2) span {
  display: inline-block;
  text-align: center;
  width: 67%;
  background: #fff;
  padding: 15px 0;
  position: relative;
  box-shadow: -1px 0px 15px #ddd;
  font-size: 14px;
  font-weight: 600;
}
.super-admin li:nth-of-type(2) span::before,
.organization-admin li:nth-of-type(2) span::before,
.super-admin li:nth-of-type(3) span::before,
.organization-admin li:nth-of-type(3) span::before {
  position: absolute;
  content: '';
  height: 100%;
  width: 3px;
  background: #33c0d3;
  left: 0;
  top: 0;
}
.organization-admin li:nth-of-type(2) span::before,
.organization-admin li:nth-of-type(3) span::before {
  background: #e35d1c;
}
.super-admin li label::after,
.organization-admin li label::after {
  position: absolute;
  content: '';
  right: -60px;
  width: 50px;
  top: 10px;
  height: 10px;
  border-top: 1px dashed #e35d1c;
}
.super-admin li label::before,
.organization-admin li label::before {
  position: absolute;
  content: '';
  right: -80px;
  top: 5px;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: #e35d1c;
  box-shadow: 0px 0px 10px #e35d1c;
}
.super-admin li:nth-of-type(3) span,
.organization-admin li:nth-of-type(3) span,
.super-admin li:nth-of-type(4) span,
.organization-admin li:nth-of-type(4) span {
  width: 20%;
  display: inline-block;
  background: #fff;
  padding: 15px 0;
  position: relative;
  box-shadow: -1px 0px 15px #ddd;
  font-size: 14px;
  font-weight: 600;
  margin: 0 12px;
  vertical-align: top;
}
.super-admin li:nth-of-type(4) span {
  background: #e6fcff;
  font-size: 13px;
  color: #4a4a4a;
  font-weight: 400;
  min-height: 65px;
  vertical-align: top;
}
.organization-admin li:nth-of-type(4) span {
  font-size: 13px;
  color: #4a4a4a;
  background: #ffece3;
  font-weight: 400;
}
.inner-task-flow,
.visual-design {
  position: relative;
}
.inner-task-flow .left-content {
  position: relative;
}
.inner-task-flow .left-content::after {
  position: absolute;
  content: '';
  height: 40px;
  width: 60px;
  bottom: -50px;
  right: 0;
  border-bottom: 3px solid #e7e7e7;
  border-right: 3px solid #e7e7e7;
}
.inner-task-flow::before,
.inner-visual::before {
  position: absolute;
  content: '';
  top: -50px;
  left: -20%;
  right: 0;
  margin: auto;
  background: url(/assets/img/portfolio-img/p2e/ztype.png);
  width: 320px;
  height: 160px;
}
.inner-task-flow::after,
.inner-visual::after {
  position: absolute;
  content: '';
  z-index: 1;
  top: inherit;
  bottom: -60px;
  left: -20%;
  right: 0;
  margin: auto;
  background: url(/assets/img/portfolio-img/p2e/ztype-opp.png);
  width: 320px;
  height: 185px;
}
.wireframe,
.inner-visual {
  position: relative;
}
.wireframe::before {
  position: absolute;
  content: '';
  width: 635px;
  height: 45px;
  top: 70px;
  left: 0;
  right: 0;
  margin: auto;
  background: url(/assets/img/portfolio-img/p2e/line.png) no-repeat;
}
.wireframe::after {
  position: absolute;
  content: '';
  width: 635px;
  height: 55px;
  bottom: 70px;
  left: 0;
  right: 0;
  margin: auto;
  background: url(/assets/img/portfolio-img/p2e/line1.png) no-repeat;
}
.fourth-section {
  padding: 80px 0 60px;
  position: relative;
}
.fourth-section::before {
  position: absolute;
  content: '';
  left: 0;
  height: 100%;
  width: 60%;
  top: 0;
  background: url(/assets/img/portfolio-img/p2e/side-bar.png) no-repeat;
}
.fourth-section::after {
  position: absolute;
  content: '';
  right: 0;
  height: 100%;
  width: 9px;
  top: 0;
  background: url(/assets/img/portfolio-img/p2e/right-bar.png) no-repeat;
}
.fourth-section ul {
  padding: 0 50px;
  margin: 0;
}
.business-value li i::after {
  position: absolute;
  content: '';
  height: 110px;
  width: 140px;
  left: -28px;
  top: -18px;
  margin: auto;
  background: url(/assets/img/portfolio-img/p2e/small-circle.png) no-repeat;
}
.super-admin li:nth-of-type(4) span,
.organization-admin li:nth-of-type(4) span {
  padding: 15px 10px;
  width: 18%;
}
.port_back_section {
  position: absolute;
  right: 0;
  top: -25px;
  z-index: 3;
  left: inherit;
}
.port_back_section a {
  font-weight: 500;
}
.inner-wireframe strong {
  color: #000;
  font-size: 16px;
  font-weight: 500;
}
.inner-wireframe .entire-p {
  padding-top: 20px;
}
.port_back_section a,
.port_back_section i,
.port_back_section a:hover,
.port_back_section a:hover > i {
  color: #ee621f;
}
/*======responsive style=======*/
@media only screen and (max-width: 1400px) {
  .container {
    width: 90% !important;
  }
  .first-section .right-content::after,
  .first-section .right-content::before {
    right: 3%;
  }
  .second-section .left-content .need-img {
    margin-left: 10%;
  }
  .second-section .left-content .challenge-img {
    margin-left: 30%;
  }
  .super-admin li label,
  .organization-admin li label {
    left: -100px;
  }
  .super-admin,
  .organization-admin {
    width: 48%;
    margin-left: 2%;
  }
  .business-value li span {
    width: 70%;
  }
  .first-section .right-content img {
    width: 85%;
  }
}
@media only screen and (max-width: 1280px) {
  .first-section .right-content img {
    width: 80%;
  }
  .inner-heading,
  .heading {
    font-size: 24px;
  }
  .first-section {
    padding-bottom: 100px;
  }
  .second-section .right-content .the-need {
    margin-left: -50px;
  }
  .second-section .right-content .these-challenges {
    margin-left: 60px;
  }
  .second-section .right-content .card {
    width: 80%;
  }
  .super-admin li:nth-of-type(3) span,
  .organization-admin li:nth-of-type(3) span,
  .super-admin li:nth-of-type(4) span,
  .organization-admin li:nth-of-type(4) span,
  .super-admin li:nth-of-type(2) span,
  .organization-admin li:nth-of-type(2) span {
    font-size: 12px;
  }
  .super-admin li label,
  .organization-admin li label {
    font-size: 13px;
  }
  h3 {
    font-size: 18px;
  }
}
@media only screen and (max-width: 1230px) {
  .tab-sec .nav-pills {
    margin-top: 0px;
  }
}
@media only screen and (max-width: 1025px) {
  .container {
    width: 90% !important;
  }
  .second-section .right-content .card {
    width: 90%;
    margin-left: 15px;
  }
  .second-section .right-content .these-challenges {
    margin-left: 60px;
  }
  .first-section .left-content i {
    display: none;
  }
  .first-section {
    padding-bottom: 50px;
  }
}
@media only screen and (max-width: 768px) {
  .left-content,
  .right-content,
  .heading,
  .super-admin,
  .organization-admin {
    width: 100%;
  }
  .portfolio .section-1 p br {
    display: none !important;
  }
  .Resources .custom-mob-logo {
    width: 20%;
  }
  .first-section .left-content i {
    display: none;
  }
  .first-section .right-content img {
    margin: 0 auto;
    width: 60%;
    margin-left: 0;
  }
  .inner-heading,
  .heading {
    font-size: 24px;
  }
  .first-section .right-content::after,
  .first-section .right-content::before,
  .second-section .left-content .need-img::after,
  .second-section .left-content .challenge-img::after,
  .second-section .left-content .need-img,
  .second-section .left-content .challenge-img,
  .inner-third-section::before,
  .inner-third-section::after,
  .ideation::before,
  .ideation::after,
  .task-flow::after,
  .visual-design::after,
  .wireframe::before,
  .wireframe::after,
  .inner-task-flow::before,
  .inner-visual::before,
  .inner-task-flow::after,
  .inner-visual::after,
  .fourth-section::before,
  .fourth-section::after,
  .business-value li i::after,
  .super-admin button::before,
  .organization-admin button::before,
  .super-admin button::after,
  .organization-admin button::after,
  .super-admin li label::before,
  .organization-admin li label::before,
  .super-admin li label::after,
  .organization-admin li label::after {
    display: none;
  }
  .second-section .right-content .card {
    width: 100%;
    margin-left: 0;
    padding: 30px 20px 10px;
  }
  .second-section .right-content .these-challenges {
    margin-top: 20px;
  }
  p,
  .super-admin li label,
  .organization-admin li label {
    font-size: 16px;
  }
  .second-section .right-content .card strong {
    font-size: 18px;
  }
  .first-section .left-content .inner-management-process,
  .inner-visual .left-content .inner-visual-content {
    padding-right: 0;
  }
  .inner-task-flow,
  .inner-visual,
  .first-section,
  .fourth-section {
    padding: 40px 0;
  }
  .inner-ideation,
  .inner-wireframe {
    padding: 50px 0;
  }
  .business-value li {
    padding: 10px 0;
    width: 100%;
  }
  .business-value li span {
    width: 60%;
  }
  .fourth-section ul {
    padding: 0;
  }
  .business-value li i img {
    width: 60%;
  }
  .logo {
    text-align: center;
  }
  .super-admin li label,
  .organization-admin li label {
    left: -34px;
  }
  .super-admin li:nth-of-type(3) span,
  .organization-admin li:nth-of-type(3) span,
  .super-admin li:nth-of-type(4) span,
  .organization-admin li:nth-of-type(4) span {
    width: 68%;
    margin: 10px;
  }
  .super-admin li:nth-of-type(4) span {
    min-height: auto;
  }
  .first-section .right-content,
  .inner-third-section .left-content,
  .inner-task-flow .right-content,
  .inner-wireframe .left-content,
  .inner-visual .right-content {
    text-align: center;
  }
  .super-admin li:nth-of-type(3) span,
  .organization-admin li:nth-of-type(3) span,
  .super-admin li:nth-of-type(4) span,
  .organization-admin li:nth-of-type(4) span,
  .super-admin li:nth-of-type(2) span,
  .organization-admin li:nth-of-type(2) span {
    font-size: 14px;
  }
  .list .list-capt h4 span {
    background-position: 0px 23px;
    background-size: 40px 10px;
    background-repeat: repeat-x;
  }
}
@media only screen and (max-width: 768px) {
  .user-stories .user-ex {
    width: 100%;
  }
}
@media only screen and (max-width: 480px) {
  img,
  .first-section .right-content img {
    width: 100%;
    margin: 0 auto;
  }
  .first-section .right-content img {
    margin-left: 0;
  }
  .inner-third-section .left-content img {
    width: 100%;
    margin-left: inherit;
  }
  .inner-heading,
  .heading {
    font-size: 18px;
    margin: 0;
    line-height: 30px;
  }
  .second-section,
  .inner-third-section {
    padding: 40px 0;
  }
  .second-section h2 {
    padding-bottom: 20px;
  }
  .second-section .right-content .card {
    padding: 30px 20px;
  }
  .right-content p {
    line-height: 25px;
  }
  .right-content p:last-child {
    margin-bottom: 0px;
  }
  .inner-third-section .heading {
    margin-bottom: 80px;
  }
  .inner-user-story::before,
  .inner-user-story::after {
    top: -44px;
    left: -30px;
  }
  .inner-user-story {
    float: inherit;
  }
  .bb {
    border-bottom: none;
  }
  .user-admin {
    display: block;
  }
  .inner-user-story::after {
    display: none;
  }
  p,
  .super-admin li label,
  .organization-admin li label {
    font-size: 14px;
  }
  .inner-task-flow p {
    font-size: 14px;
  }
  .inner-wireframe .right-content .sub-heading {
    padding-bottom: 20px;
  }
  .inner-visual .left-content .inner-visual-content {
    margin-top: 0px;
  }
  .inner-task-flow,
  .inner-visual,
  .first-section,
  .fourth-section {
    padding: 25px 0;
  }
  .inner-visual .left-content {
    padding-bottom: 20px;
  }
  .first-section .inner-management-process p {
    font-size: 14px;
  }
  .port_back_section {
    top: -10px;
  }
  .inner-visual p {
    font-size: 14px;
  }
  .business-value li {
    display: grid;
    justify-content: center;
  }
  .business-value li i {
    margin: 0 auto;
  }
  .business-value li span {
    width: 80%;
    margin: 0 auto;
  }
  .second-section .right-content .card {
    margin-left: 0px;
  }
  .list .list-capt h4 span {
    background-position: 0px 17px;
    background-size: 40px 10px;
    background-repeat: repeat-x;
  }
}
.tenshey-port .ix-container {
  max-width: 1280px;
  width: 90%;
  margin: 0 auto;
}
.ix-logo img {
  width: 150px;
  position: absolute;
  top: 80px;
}
.ten-portfolio {
  background: url('/assets/img/portfolio-img/tenshey-img/banner-bg.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.case-btn {
  background-color: #102d4c !important;
  border: 1px solid #102d4c !important;
}
.case-btn:hover {
  background-color: #0a4f98 !important;
  border: 1px solid #0a4f98 !important;
}
.tenshey-coach {
  margin-bottom: 150px;
}
.tenshey,
.sec1,
.top-img,
.rect,
.idea-text,
.border-circle,
.border-dashed,
.tensheylogo,
.mob-wireframe,
.mob-img,
.visual-img,
.list-content ul li .list-border,
.list-content .owl-carousel .owl-stage-outer {
  position: relative;
}
.tenshey img {
  position: absolute;
  top: -75px;
}
.sec1 {
  padding-top: 295px;
}
.pad-0 {
  padding: 0 !important;
}
.icon-text {
  display: flex;
  flex-wrap: wrap;
}
.ix-main-wrapper {
  clear: both;
}
.top-img {
  display: block;
  float: left;
  width: 100%;
}
.note-pad {
  width: 100%;
}
.image-space {
  background: url(/assets/img/portfolio-img/tenshey-img/banner-bg.jpg);
  background-size: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
}
.tenshey-bg {
  width: 80%;
}
.tensheylogo {
  width: 30%;
  position: absolute;
  top: 15px !important;
  left: 35px;
}
.notepad img {
  width: 40%;
  position: absolute;
  top: -515px;
  right: 15%;
}
.alovera img {
  position: absolute;
  width: 20%;
  right: -8%;
  top: -170px;
}
.sub-txt h1 {
  font-size: 18px;
  color: #0a2f4b;
  font-weight: 600;
  line-height: 30px;
}
.sub-txt > div {
  margin: 20px 0;
}
.sub-txt b {
  font-size: 15px;
  color: #353738;
  line-height: 28px;
}
.sub-txt p {
  font-size: 15px;
  color: #353738;
  line-height: 25px;
}
.content-box1 {
  box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.07);
  background-color: #addad7;
  width: 47%;
  padding: 35px 35px;
  position: absolute;
  bottom: -60px;
}
.content-1 h4 {
  height: auto;
  font-size: 24px;
  font-weight: 500;
  color: #000000;
}
.content-1 p {
  font-size: 16px;
  color: #000000;
}
.content-box2 {
  width: 42% !important;
  border: solid 1px #95bcb9;
  background-color: #ffffff;
  padding: 55px 58px;
  position: absolute !important;
  top: -75px;
  left: 575px;
  right: 0;
}
.content-box2:before {
  content: '';
  border-bottom: 5px solid #f95d5e;
  border-right: 5px solid #f95d5e;
  width: 50px;
  height: 50px;
  position: absolute;
  right: -3px;
  bottom: -3px;
  z-index: 1;
}
.content-box2:after {
  content: '';
  border-top: 5px solid #f95d5e;
  border-left: 5px solid #f95d5e;
  width: 50px;
  height: 50px;
  position: absolute;
  left: -3px;
  top: -3px;
  z-index: 1;
}
/*.rect {
    margin: 250px 0 205px;
}*/
.row.rect {
  margin: 200px 0 90px;
}
.sec-margin {
  margin: 100px 0;
}
.content-2 h4 {
  height: auto;
  font-size: 34px;
  font-weight: 500;
  color: #000000;
  text-align: center;
  margin: 0;
}
.content-2 p {
  font-size: 23px;
  color: #000000;
  margin: 0;
}
.content-idea span {
  font-size: 15px;
  text-align: right;
  color: #353738;
}
.ideation {
  padding-left: 70px;
}
.ideation img:last-child {
  padding: 0 60px;
}
.task-section {
  background-image: url(/assets/img/portfolio-img/tenshey-img/familiar-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 80px 0 0px;
  background-position: bottom;
  padding-top: 0;
}
.taskflow img {
  float: left;
  position: absolute;
  top: 15px;
  left: -70px;
}
.task {
  text-align: center;
}
.task h3 {
  opacity: 0.7;
  font-size: 32px;
  font-weight: 900;
  color: #d6edeb;
  margin: 0;
  position: absolute;
  left: 410px;
  top: 180px;
}
.task h4 {
  font-size: 32px;
  font-weight: 900;
  color: #353738;
}
.task p {
  font-size: 16px;
  color: #353738;
}
.idea-text {
  margin-top: 200px;
  width: 85%;
}
.wireframe {
  margin-top: 200px;
}
.idea-sub-content > div {
  text-align: right;
  margin: 25px 0px;
}
.idea-text fieldset,
.wireframe-text fieldset,
.visual fieldset {
  border: solid 1px rgba(112, 112, 112, 0.18);
}
.idea-text fieldset legend {
  position: absolute;
  top: -24px;
  right: 30px;
  background: #fff;
  width: 125px;
  padding: 0 8px;
  font-size: 30px;
  font-weight: 900;
  color: #353738;
  margin: 0;
}
.wireframe-img {
  text-align: right;
  padding-left: 500px;
}
.mob-wireframe {
  border: solid 4px #0000001c;
  min-height: 360px;
}
.mob-wireframe img:first-child {
  padding-left: 35px;
  position: absolute;
  top: -60px;
}
.mob-wireframe img:last-child {
  padding: 15px 50px;
  float: right;
}
.wireframe-text {
  padding-top: 90px;
  margin: 0 65px 0 20px;
}
.wireframe img {
  position: absolute;
  right: 0px;
  transform: rotate(-90deg);
  top: 300px;
}
.wireframe-text fieldset legend {
  position: absolute;
  top: 67px;
  background: #fff;
  width: 180px;
  padding: 0 10px;
  font-size: 30px;
  font-weight: 900;
  color: #353738;
  margin: 0;
}
.visual fieldset legend {
  width: 220px;
  background: #f7fbfa;
}
.visual-text-img img {
  position: absolute;
  right: 80px;
  top: 100%;
  transform: none;
}
.visual-img {
  padding-bottom: 100px;
  margin-left: -75px;
}
.visual-img img {
  padding-top: 115px;
}
.mob-img img {
  position: absolute;
  top: -350px;
  left: -90px;
  padding: 0;
}
.visual-bg {
  background-image: url(/assets/img/portfolio-img/tenshey-img/visual-img.png);
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: left;
  background-color: #eff8f5;
  margin-top: 180px;
  padding: 15px 0;
  margin-bottom: 0px;
}
.idea-sub-content,
.frame-sub-content {
  padding: 32px 20px;
}
.idea-sub-content h6 {
  text-align: right;
  font-size: 15px;
  color: #000000;
  font-weight: bold;
}
ul.owl-carousel.owl-theme.owl-loaded.owl-drag,
.bg-3 .row,
.bg-4 .row {
  margin: 0;
}
.bg-3 {
  background-color: #eff8f7;
}
.list-content ul > li {
  width: calc(98% / 6);
  font-size: 14px;
  color: #000000;
  display: inline-block;
  vertical-align: top;
}
.owl-dots {
  display: none;
}
.list-content {
  padding: 20px 0;
}
.list-content .owl-item.active {
  margin-right: 0 !important;
}
.list-content .item:last-child::after {
  content: none;
}
.list-content .item:first-child::after {
  content: none;
}
.list-content .item::after {
  border: 1px dashed #82a09d;
  content: '';
  width: 100%;
  position: absolute;
  top: -20px;
  left: 0;
}
.list-content ul li .list-border {
  padding: 5px 40px !important;
  color: #000;
  font-weight: 500;
  line-height: 20px;
  font-size: 14px;
}
.list-content ul li .list-border:before {
  content: '';
  position: absolute;
  display: inline-block;
  top: -38px;
  right: 0;
  left: 40px;
  border-radius: 50%;
  background: #ffe9ea;
  width: 37px;
  height: 37px;
}
.list-content ul li .list-border:after {
  content: '';
  position: absolute;
  top: -22px;
  left: 55px;
  border-radius: 50%;
  background: #f76869;
  width: 6px;
  height: 6px;
}
.list-content .owl-carousel .owl-stage-outer {
  overflow: visible;
}
.item:hover {
  box-shadow: none;
}
.idea-img-pd {
  padding-top: 230px;
}
.frame-pd {
  padding-top: 180px;
}
.frame-sub-content p {
  color: #000;
  font-weight: 500;
  font-size: 15px;
}
.tenshey-build h1 {
  font-size: 30px;
  font-weight: 500;
  line-height: 1;
  color: #353738;
  margin: 0;
  text-align: center;
}
.tenshey-build {
  margin: 80px 0;
}
.icon1 {
  background-color: #f8f8f8;
  padding: 60px;
  text-align: center;
  vertical-align: middle;
  min-height: 250px;
  margin: 0 auto;
}
.pad-45 {
  padding: 45px;
}
.card-text.bg-lightgreen h4,
.card-text.bg-lightgreen p {
  color: #4a4d4e;
}
.idea-sub-content > div > b {
  color: #000;
}
.card-text {
  background: #252525;
  min-height: 250px;
  padding: 30px 45px;
}
.card-text h4 {
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  color: #fff;
  margin-bottom: 20px;
  margin-top: 0;
}
.card-text p {
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  margin: 0;
}
.bg-lightgreen {
  background-color: #e3ebea;
}
.task-section .bg-2 .row {
  margin: 0 0 50px;
}
.bg-1 {
  background: url(/assets/img/portfolio-img/tenshey-img/img1-1.png);
  background-repeat: no-repeat;
  background-position: 52% -80%;
  background-size: 10%;
  padding-bottom: 70px;
}
.bg-2 {
  background: url(/assets/img/portfolio-img/tenshey-img/img2-2.png);
  background-repeat: no-repeat;
  background-size: 3%;
  background-position: 48% 100%;
  padding: 5px 0 150px;
}
.bg-4 {
  background: url(/assets/img/portfolio-img/tenshey-img/img4-4.png);
  background-repeat: no-repeat;
  background-size: 3%;
  background-position: 48% 0%;
}
.frame-pd:after {
  background: url(/assets/img/portfolio-img/tenshey-img/img4-4.png);
  background-repeat: no-repeat;
  background-size: 7%;
  background-position: 100% 0%;
  content: '';
  position: absolute;
  top: 100%;
  width: 100%;
  height: 100%;
  left: -10px;
  right: 0;
  bottom: 0;
}
.ten-back {
  background: #addad7;
  padding: 10px 20px;
  left: 20px;
  top: 60px;
  border-radius: 25px;
  color: #000;
}
.ten-back a {
  color: #102d4c;
}
.ten-back i {
  color: #102d4c;
}
.ten-back:hover i,
.ten-back:hover a {
  color: #102d4c;
  opacity: 0.8;
}
.port_back_section {
  position: absolute;
  top: 120px;
  z-index: 3;
  left: 120px;
}
/***********************
    Media Query Starts 
***********************/
@media (max-width: 1440px) {
  .card-text {
    padding: 25px;
  }
}
@media (max-width: 1199px) {
  .card-text {
    padding: 25px;
  }
  .ideation {
    padding-left: 0;
    text-align: center;
  }
  .idea-img-pd {
    padding-top: 150px;
  }
  .visual-img {
    margin-left: 0;
  }
  .wireframe {
    margin-top: 50px;
  }
  .task h3 {
    left: 240px;
  }
  .bg-2,
  .bg-3,
  .bg-4,
  .frame-pd:after {
    background: none;
  }
  .frame-pd {
    padding-top: 50px;
  }
  .mob-wireframe img:last-child {
    padding: 20px 150px;
  }
  /*    .list-content {
        width: 900px;
        width: 100%;
    }*/
  .tensheylogo {
    width: 14%;
    /*  position: absolute;*/
    top: -104px !important;
    left: 14px;
  }
  .tenshey img {
    position: absolute;
    top: -152px;
  }
  .tenshey-bg {
    width: 33%;
  }
}
@media (max-width: 770px) {
  .tenshey-bg {
    width: 45%;
  }
  .tensheylogo {
    width: 20%;
  }
  .mob-wireframe img:last-child {
    padding: 20px;
  }
  .wireframe-img img {
    width: 220px;
  }
  .wireframe-img {
    padding-left: 350px;
  }
  .ix-abt-ten {
    margin-top: 200px;
  }
  .row.rect {
    margin: 250px 0 80px;
  }
  .content-box1,
  .content-box2 {
    width: 60%;
  }
  .content-box1 {
    padding: 20px;
    left: -40px;
  }
  .content-box2 {
    padding: 30px 38px;
    left: 310px;
    top: -80px;
    width: 55%;
  }
  .mob-scroll {
    overflow: hidden;
    overflow-x: auto;
    max-width: 100%;
  }
  .list-content {
    width: 1000px;
    overflow: hidden;
  }
  .content-box2 {
    left: 360px;
  }
  .ix-abt-ten {
    margin-top: 0px;
  }
}
@media (max-width: 425px) {
  .portfolio .section-4.MarketPlace .custom-container {
    min-width: 80%;
    max-width: 80%;
  }
  .portfolio .section-4.MarketPlace .custom-container .main-head-1 {
    padding: 0px 20px;
  }
  .ix-abt-ten {
    margin-top: 100px;
  }
  .content-box1 {
    left: 0;
  }
  .content-box1,
  .content-box2 {
    width: 100%;
    padding: 15px 15px;
  }
  .content-box2 {
    top: 90px;
    left: 0;
  }
  .tensheylogo {
    width: 35%;
    position: absolute;
    top: -75px !important;
    left: 15px;
  }
  .tenshey-img {
    position: absolute;
    top: -122px;
  }
  .tenshey-bg {
    width: 80%;
    position: absolute;
    top: -125px !important;
  }
  .task {
    margin-top: 100px;
  }
  .task h3 {
    left: 130px;
  }
  .idea-text {
    margin-top: 200px;
    width: 100%;
  }
  .idea-img-pd {
    padding-top: 50px;
  }
  .ideation,
  .mob-wireframe {
    padding: 10px;
    text-align: center;
  }
  .ideation img:last-child {
    transform: rotate(90deg);
    padding: 0;
    top: 170px;
    position: relative;
    right: 140px;
  }
  .bg-1,
  .bg-2 {
    padding-bottom: 0px;
  }
  .mob-scroll {
    overflow: hidden;
    overflow-x: auto;
    max-width: 100%;
  }
  /*    .list-content {
        width: 1190px;
        width: 130%
    }*/
  .mob-scroll {
    overflow: hidden;
    overflow-x: auto;
    max-width: 100%;
  }
  .list-content {
    width: 1000px;
    overflow: hidden;
  }
  .list-content .item::after {
    top: -20px;
    width: 330%;
  }
  .frame-pd {
    padding-top: 100px;
  }
  .frame-pd:after {
    display: none;
  }
  .mob-wireframe {
    border: none;
    height: 0;
    text-align: start;
  }
  .wireframe-img,
  .mob-wireframe img:first-child {
    padding-left: 0;
  }
  .wireframe-img img,
  .mob-wireframe img:first-child {
    width: 100%;
  }
  .bg-4 {
    background: none;
  }
  .mob-wireframe img:first-child {
    top: 0px;
  }
  .mob-wireframe img:last-child {
    float: none;
    margin-left: 90px;
    margin-top: 330px;
    width: 50%;
  }
  .wireframe {
    margin-top: 400px;
  }
  .wireframe-text {
    margin: 0;
  }
  .wireframe-text fieldset legend {
    width: 150px;
    font-size: 24px;
  }
  .wireframe img {
    right: 65px;
    transform: rotate(360deg);
    top: 460px;
  }
  .visual fieldset legend {
    width: 180px;
  }
  .visual-img img {
    width: 100%;
    margin: 0 auto;
  }
  .mob-img img {
    width: 60%;
    margin: 0 90px;
    margin-top: 140px;
  }
  .visual-bg {
    margin-top: 100px;
  }
  .mob-frame {
    margin: 0;
  }
  .ten-back {
    padding: 10px;
    top: 90px;
  }
  .port_back_section a {
    font-size: 14px;
  }
}
@media (max-width: 320px) {
  .content-box1,
  .content-box2 {
    width: 100%;
    padding: 15px 15px;
  }
  .content-box2 {
    top: 90px;
    left: 0;
  }
  .tensheylogo {
    width: 45%;
    position: absolute;
    top: -75px !important;
    left: 15px;
  }
  .tenshey-img {
    position: absolute;
    top: -122px;
  }
  .tenshey-bg {
    width: 100%;
    position: absolute;
    top: -125px !important;
  }
  .idea-text {
    margin-top: 200px;
    width: 100%;
  }
  .idea-img-pd {
    padding-top: 50px;
  }
  .ideation,
  .mob-wireframe {
    padding: 10px;
    text-align: center;
  }
  .ideation img:last-child {
    transform: rotate(90deg);
    padding: 0;
  }
  .bg-1,
  .bg-2 {
    padding-bottom: 0px;
  }
  .mob-scroll {
    overflow: hidden;
    overflow-x: auto;
    max-width: 100%;
  }
  .list-content {
    width: 1000px;
    overflow: hidden;
  }
  /*    .list-content {
        width: 1190px;
        width: 130%;
    }*/
  .list-content .item::after {
    top: -20px;
    width: 330%;
  }
  .frame-pd {
    padding-top: 100px;
  }
  .frame-pd:after {
    display: none;
  }
  .mob-wireframe {
    border: none;
    height: 0;
    text-align: start;
  }
  .wireframe-img,
  .mob-wireframe img:first-child {
    padding-left: 0;
  }
  .wireframe-img img,
  .mob-wireframe img:first-child {
    width: 100%;
  }
  .bg-4 {
    background: none;
  }
  .mob-wireframe img:first-child {
    top: 0px;
  }
  .mob-wireframe img:last-child {
    float: none;
    margin-left: 10px;
    margin-top: 250px;
    width: 100%;
  }
  .wireframe {
    margin-top: 350px;
  }
  .wireframe-text {
    margin: 0;
  }
  .wireframe-text fieldset legend {
    width: 150px;
    font-size: 24px;
  }
  .wireframe img {
    right: 15px;
    transform: rotate(360deg);
    top: 570px;
  }
  .visual fieldset legend {
    width: 180px;
  }
  .visual-img img {
    width: 100%;
    margin: 0 auto;
  }
  .mob-img img {
    width: 60%;
    margin: 0 90px;
    margin-top: 140px;
  }
  .visual-bg {
    margin-top: 100px;
  }
  .mob-frame {
    margin: 0;
  }
}
.Gaadibazaar-portfolio .row {
  margin-right: auto;
  margin-left: auto;
}
.owner-of-site p {
  font-weight: 400;
  line-height: 30px;
  font-size: 18px;
  color: #00000091;
  margin-bottom: 30px;
}
.port_back_section {
  position: absolute;
  right: 200px;
  top: 20px;
  left: auto;
}
.ix-gd-background {
  position: relative;
}
.gaadi-bazaar-mob-top {
  width: 36%;
}
.ix-gd-background h1 {
  position: absolute;
  right: -73px;
  border: 5px solid #c3e5f2;
  color: #c3e5f2;
  top: 120px;
  margin: 0;
  padding: 16px 48px;
  transform: rotate(-90deg);
  font-size: 40px;
  font-weight: 600;
}
.owner-of-site {
  position: relative;
}
.specifications .owner-of-site p {
  padding: 0 110px 0 50px;
}
.owner-of-site p strong {
  font-weight: 600;
  color: #484848;
}
.gd-front-img {
  position: relative;
  text-align: center;
}
.gd-front-img img {
  position: absolute;
  right: 100px;
  top: -160px;
  z-index: 9;
}
.gd-portfolio-logo img {
  width: 400px;
  border: 1px solid #86cdeb;
  margin: 100px -17px 60px;
  padding: 25px 55px;
}
.ix-gd-container {
  width: 92%;
  margin: 0 auto;
  margin-right: auto !important;
  margin-left: auto !important;
}
.mob-p {
  padding: 0;
}
.gd-ecosystem h1 {
  width: 58%;
  font-size: 34px;
  color: #0092d2;
  font-weight: 600;
  line-height: 46px;
}
.bussiness-requritment:before {
  height: 240px;
  background: #c6ecff;
  width: 95%;
  content: '';
  position: absolute;
  z-index: -1;
}
.ix-gd-background img {
  position: absolute;
  right: 0px;
}
.specifications small:first-child svg {
  transform: rotate(36deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(-180deg);
}
.specifications small:last-child svg {
  right: 10%;
  bottom: -20px;
}
.bussiness-requritment {
  position: relative;
}
.specifications {
  border: 1px solid #474747;
  width: 95%;
  height: auto;
  background: transparent;
  position: absolute;
  top: 60px;
  right: 0;
  padding: 30px 15px 40px 30px;
}
.specifications ul li {
  width: calc(98% / 4);
  display: inline-block;
  vertical-align: middle;
}
.specifications ul {
  margin: 100px 0 0 0;
}
.specifications ul li:nth-child(2n):before,
.specifications ul li:nth-child(3n):before {
  background: #e6e6e6;
  content: '';
  position: absolute;
  width: 180px;
  height: 80px;
  z-index: -1;
}
.specifications ul li:nth-child(3n):before,
.bussiness-value,
.bussiness-value .wire-frame.visual-design,
.bussiness-value .wire-frame.visual-design .challenges {
  background: #c6ecff;
}
.flex-wireframe {
  display: flex;
}
.specifications ul li h6 {
  z-index: 9;
  background: transparent;
  border: 1px solid #474747;
  padding: 17px 20px;
  margin: 15px 20px;
  font-weight: 600;
  word-break: break-word;
  width: 180px;
  height: 80px;
  line-height: 20px;
  text-align: center;
  font-style: italic;
}
.specifications ul li:first-child h6:first-child {
  font-style: normal;
  font-size: 30px;
  padding: 25px 0;
  border: 1px solid transparent;
  font-weight: 400;
  color: #474747;
  margin: 0;
  width: auto;
}
.specifications small svg {
  width: 22px;
  height: 22px;
  position: absolute;
}
.gd-ecosystem {
  height: 600px;
}
.challenges p {
  font-size: 16px;
  font-weight: 500;
  line-height: 36px;
  text-align: center;
  color: #000;
  padding: 10px 35px;
  width: 80%;
  margin: 0 auto;
}
.challenges.challenges2 p {
  color: #4a4a4a;
  font-weight: 600;
}
.challenges small svg {
  width: 31px;
  height: 31px;
  transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
  position: absolute;
  left: 15%;
}
.challenges {
  background: #e6e6e6;
  padding: 20px 0px 0px;
  margin: 0px 10px 0px 50px;
  width: 90%;
}
.we-provided {
  background: #c6ecff;
  width: 95%;
  display: block;
  float: left;
  margin-left: 65px;
  z-index: -9;
}
.challenges small:last-child svg {
  transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  position: relative;
  left: 88%;
  top: -30px;
}
.challenges h3 {
  text-align: center;
  font-weight: 600;
  color: #4890d3;
  font-size: 50px;
  margin: 0;
}
.decision-making {
  position: relative;
  background: #c6ecff;
  padding: 32px 63px 1px 90px;
}
.decision-making h3 {
  position: absolute;
  transform: rotate(-90deg);
  left: -149px;
  bottom: 150px;
  line-height: 55px;
  font-weight: 600;
  font-size: 56px;
  color: #488fd3;
  margin: 0;
  z-index: 9;
  letter-spacing: 1.4px;
}
.our-desing h4 {
  color: #000;
  font-size: 25px;
  line-height: 35px;
  font-weight: 600;
}
.our-desing p {
  color: #2d2626;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 15px;
  font-weight: 500;
}
.our-desing p.list-para:before {
  content: "\A";
  width: 10px;
  height: 10px;
  border-radius: 25%;
  background: #488fd3;
  display: inline-block;
  margin: 0 10px;
  border: 2px solid #fff;
}
.our-desing h6 {
  font-weight: 600;
  color: #000;
  font-size: 18px;
  line-height: 65px;
  margin-left: 58px;
}
.provide h2 {
  color: #4a4a4a;
  background: #c6ecff;
  margin: 0;
  padding: 19px 0px;
  width: 100%;
  line-height: 52px;
  font-size: 46px;
  font-weight: 600;
  letter-spacing: 4px;
  margin-left: 42px;
  text-align: right;
}
.provide h2 span,
.headline span {
  width: 50px;
  height: 9px;
  background: #4a4a4a;
  display: inline-block;
  border-radius: 35px;
  position: relative;
  top: -12px;
}
.headline span {
  background: #0092d2;
  z-index: 999;
  top: -16px;
  left: -25px;
}
.provide h2 span.joint {
  left: 5px;
  z-index: 9;
}
.row.margin-minus {
  margin: 0 0px;
}
.brainstorming h3,
.wire-frame.visual-design .brainstorming h3 {
  margin: 0 auto;
  background: #c6ecff;
  border: 2px solid #9b9b9b;
  font-weight: 400;
  text-align: center;
  width: 400px;
  padding: 9px 0;
  position: relative;
  top: -58px;
  color: #000;
  font-size: 40px;
  line-height: 62px;
}
.brainstorming .challenges {
  padding: 0px;
  padding-bottom: 40px;
}
.wire-frame small:last-child svg {
  left: 8px;
  top: 12px;
}
.wire-frame small svg {
  margin: 0px 15px;
  left: 17%;
}
.wire-frame small svg path {
  fill: #777 !important;
}
.brainstorming {
  margin: 70px 0 40px 0;
  border: 2px solid #474747;
  text-align: center;
  padding: 20px 0 40px 0;
  position: relative;
}
.brainstorming .challenges p {
  width: 100%;
}
.brainstorming h4 {
  left: 28%;
  bottom: 8%;
}
.brainstorming h4,
.brainstorming h5,
.brainstorming h6 {
  color: #0092d2;
  font-style: italic;
  position: absolute;
  font-size: 20px;
}
.brainstorming h6 {
  top: 15%;
  left: 17%;
}
.brainstorming h5 {
  right: 8%;
  top: 48%;
}
.wire-frame {
  background: #e6e6e6;
  display: inline-block;
  width: 100%;
  float: inherit;
  margin: 10px 0;
  padding-bottom: 150px;
}
.wire-frame .brainstorming {
  padding: 10px 0;
  height: 770px;
}
.wire-frame-images img {
  padding-top: 0!important;
  vertical-align: top;
}
.wire-frame-images img.wire-frame1,
.wire-frame-images img.wire-frame3 {
  padding-top: 100px!important;
}
.wire-frame.visual-design,
.wire-frame.visual-design .challenges,
.visual-design .brainstorming h3,
.brainstorming.wr h3,
.wire-frame.features,
.features .challenges,
.bussiness-value .wire-frame.visual-design .brainstorming h3 {
  background: #fff;
}
.bussiness-value {
  display: block;
  width: 100%;
  float: left;
}
.bussiness-value .wire-frame.visual-design {
  width: 50%;
}
.bussiness-value .border-right {
  border-right: 0px solid transparent;
}
.bussiness-value .border-left {
  border-left: 0px solid transparent;
}
.bussiness-value h5 {
  font-size: 29px;
  color: #000;
  font-weight: 600;
  margin-top: 60px;
  line-height: 40px;
}
.bussiness-value .wire-frame .brainstorming {
  margin-top: 70px;
}
.bussiness-value p {
  font-size: 16px;
  color: #000;
}
.wire-frame.features .brainstorming .challenges p,
.wire-frame.features .brainstorming .challenges h6 {
  text-align: left!important;
  left: auto;
  font-size: 16px!important;
}
.wire-frame.features .brainstorming .challenges h6 {
  color: #030002;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 30px;
  position: relative;
}
.wire-frame.features .brainstorming .challenges p {
  font-weight: 300;
  color: #030002;
  line-height: 25px;
  padding: 6px 0;
  margin-bottom: 10px;
}
.wire-frame.features .challenges.m-0,
.bussiness-value .wire-frame.visual-design .challenges {
  padding-bottom: 40px;
  padding-top: 0;
}
.bussiness-value .wire-frame.visual-design .challenges {
  margin-bottom: 0;
}
.wire-frame.features .wire-frame-images h6 {
  color: #000;
  position: relative;
  left: auto;
  font-size: 30px;
  font-style: normal;
}
.features .wire-frame-images {
  position: absolute;
}
.brainstorming.wr .challenges p,
.visual-design .challenges p {
  width: 670px;
  margin: 0 auto;
}
img.gd-emi1-1 {
  top: 53%;
  position: absolute;
  left: -9%;
}
img.gd-emi2-2 {
  position: absolute;
  top: 52%;
  left: 27%;
}
img.gd-emi3-3 {
  position: absolute;
  top: 52%;
  right: -14%;
}
.bussiness-value .challenges p {
  width: auto;
  text-align: left;
  line-height: 40px;
}
.gd-footer {
  background: #c6ecff;
  display: block;
  width: 100%;
  float: left;
  padding: 5px  50px 0px 50px;
  margin-top: 0px;
}
.gd-footer ul {
  display: flex;
  align-items: center;
  padding: 15px 0 10px;
}
.gd-footer ul li {
  list-style-type: none;
}
.gd-footer ul li:last-child {
  margin-left: auto;
}
.logo-black {
  width: 100%;
}
.mb-0 {
  margin-bottom: 0px;
}
.port_back_section a {
  color: #000;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
}
.port_back_section i {
  color: #000;
  font-size: 20px;
  margin-right: 10px;
}
.port_back_section:hover i,
.port_back_section:hover a {
  color: #0f1ac7;
  opacity: inherit;
}
.case-btn {
  background-color: #0a4f98 !important;
  border: 1px solid #0a4f98 !important;
  color: #fff !important;
  border-radius: 25px!important;
  margin-top: 25px!important;
  position: relative!important;
}
.case-btn:hover {
  background-color: #16578a !important;
  border: 1px solid #16578a !important;
  color: #fff !important;
  box-shadow: 0 3px 3px 0 rgba(36, 87, 138, 0.43), 0 1px 7px 0 rgba(36, 87, 138, 0.43), 0 3px 1px -1px rgba(36, 87, 138, 0.43) !important;
}
.case-btn:focus {
  outline: none!important;
  box-shadow: none!important;
}
img.gd-brain-storm {
  width: 65%;
}
.third-section .wireframe.custom-wireframe img {
  position: relative;
  right: inherit;
  transform: none;
  top: inherit;
}
.third-section .wireframe.custom-wireframe {
  margin-top: 0px;
}
.Gaadibazaar-portfolio .case-btn {
  margin-bottom: 25px;
}
@media (min-width: 1920px) and (max-width: 2560px) {
  .gd-front-img {
    height: 320px;
  }
}
@media (max-width: 1440px) {
  .challenges p {
    font-size: 16px;
    font-weight: 500;
    line-height: 34px;
    text-align: center;
    color: #4a4a4a;
    padding: 10px 35px 0px;
    width: 80%;
    margin: 0 auto;
  }
}
@media (max-width: 1024px) {
  .port_back_section {
    right: 200px;
    top: 40px;
  }
  .brainstorming h6 {
    top: 8%;
  }
  .challenges p {
    line-height: 33px;
    font-size: 15px;
  }
  .brainstorming h5 {
    right: 4%;
  }
  img.gd-brain-storm {
    width: 70%;
  }
  .brainstorming h4 {
    left: 21%;
  }
  .specifications {
    top: 20px;
    left: 20px;
  }
  .specifications .owner-of-site p {
    padding: 20px 54px 0 50px;
    font-size: 18px;
  }
  .specifications ul li:first-child h6:first-child {
    font-size: 24px;
    padding: 0;
    line-height: 54px;
  }
  .provide h2 {
    line-height: 22px;
    font-size: 40px;
    margin-left: 5px;
  }
  .decision-making h3 {
    font-size: 50px;
    left: -135px;
    bottom: 155px;
  }
  .provide h2 span.joint {
    left: 25px;
  }
  .our-desing p {
    font-size: 14px;
    line-height: 24px;
  }
  .our-desing h4 {
    font-size: 22px;
    line-height: 30px;
  }
  .our-desing h6 {
    font-size: 16px;
    line-height: 26px;
    margin-left: 50px;
  }
  .gd-ecosystem h1 {
    line-height: 40px;
    font-size: 34px;
  }
  .brainstorming h3,
  .wire-frame.visual-design .brainstorming h3 {
    padding: 0 0;
    font-size: 30px;
  }
  .wire-frame-images img {
    width: 95%;
  }
  .wire-frame.features .wire-frame-images h6 {
    font-size: 24px;
  }
  .headline span {
    left: -34px;
  }
  img.gd-emi1-1,
  img.gd-emi2-2,
  img.gd-emi3-3 {
    width: 55%;
  }
  .wire-frame small svg {
    left: 12%;
  }
  .challenges {
    width: auto;
  }
}
@media only screen and (max-width: 991px) {
  .provide h2 {
    font-size: 39px;
  }
  .specifications ul li:nth-child(2n):before,
  .specifications ul li:nth-child(3n):before,
  .specifications ul li h6 {
    width: 160px;
  }
  .specifications ul li:first-child h6:first-child {
    font-size: 22px;
  }
  .wire-frame .brainstorming {
    height: 890px;
  }
  .ix-gd-background h1 {
    top: -86px;
  }
  .ix-gd-background img {
    top: -209px;
    right: -72px;
  }
}
@media (max-width: 991px) {
  .ix-gd-background img {
    width: 60%;
  }
  .gd-ecosystem h1 {
    font-size: 30px;
  }
  .provide h2 {
    font-size: 30px;
  }
  .brainstorming h3,
  .wire-frame.visual-design .brainstorming h3 {
    width: 330px;
  }
  .bussiness-value .challenges p {
    height: auto;
  }
  .wire-frame small svg {
    left: 7%;
  }
}
@media (max-width: 768px) {
  .specifications ul li {
    width: calc(96% / 4);
  }
  .port_back_section {
    top: 40px;
    right: 110px;
  }
  .wire-frame-images {
    margin-top: 40px;
  }
  .provide h2 span.joint {
    left: 37px;
  }
  .provide h2 span,
  .headline span {
    top: -7px;
  }
  .we-provided {
    margin-left: 50px;
  }
  .challenges h3 {
    padding: 30px 0;
    font-size: 40px;
  }
  .gd-front-img img {
    top: 0px;
    right: 40px;
    width: 72%;
  }
  .gd-portfolio-logo img {
    width: 45%;
    padding: 20px 30px;
    margin: 40px -17px 10px;
  }
  .ix-gd-background img {
    top: -155px;
  }
  .ix-gd-background h1 {
    top: -47px;
    right: -62px;
    padding: 10px 10px;
    font-size: 30px;
  }
  .owner-of-site p {
    line-height: 26px;
    font-size: 16px;
    margin-bottom: 25px;
  }
  .gd-ecosystem h1 {
    line-height: 26px;
    font-size: 16px;
    color: #4a4a4a;
    margin-bottom: 25px;
  }
  .ix-gd-background img {
    right: -16px;
    width: 56%;
  }
  .specifications .owner-of-site p {
    font-size: 15px;
  }
  .specifications small:last-child {
    top: 90px;
  }
  .specifications ul li:first-child h6:first-child {
    font-size: 20px;
  }
  .specifications ul li h6 {
    width: 120px;
    padding: 11px 0;
  }
  .specifications ul li:nth-child(2n):before,
  .specifications ul li:nth-child(3n):before {
    width: 128px;
  }
  .provide h2 {
    font-size: 25px;
    margin-left: 12px;
  }
  .our-desing h6 {
    margin: 0 auto 0 auto;
    width: 75%;
  }
  .challenges p {
    line-height: 38px;
    font-size: 14px;
    width: 100%;
    padding: 0;
  }
  .decision-making {
    padding: 30px 25px 1px 75px;
  }
  .our-desing p {
    margin-bottom: 13px;
    font-size: 14px;
  }
  .decision-making h3 {
    left: -138px;
  }
  .brainstorming h3,
  .wire-frame.visual-design .brainstorming h3 {
    width: 275px;
    font-size: 26px;
  }
  .brainstorming h4,
  .brainstorming h5,
  .brainstorming h6 {
    font-size: 15px;
  }
  .challenges {
    margin: 0px 10px 0px 35px;
    padding: 0 20px;
  }
  .brainstorming.wr .challenges p,
  .visual-design .challenges p {
    width: auto;
    font-size: 15px;
    line-height: 30px;
  }
  .brainstorming {
    margin: 60px 0 40px 0;
  }
  .bussiness-requritment:before {
    width: 103%;
  }
  .bussiness-value .wire-frame .brainstorming {
    margin-top: 70px;
  }
  .gd-footer {
    margin-top: 100px;
  }
  .brainstorming h6 {
    top: 12%;
  }
  .wire-frame .brainstorming small svg {
    left: 0;
  }
  .gd-ecosystem {
    height: 550px;
  }
}
@media (max-width: 767px) {
  .gd-front-img img {
    display: none;
  }
  .owner-of-site p {
    padding-right: 80px;
  }
  .specifications {
    padding: 15px 15px 40px 22px;
    position: relative;
    margin: 0 0 30px 0;
  }
  .specifications ul li {
    width: 100%;
  }
  .bussiness-requritment:before {
    width: 95%;
  }
  .gd-ecosystem {
    height: auto;
  }
  .specifications ul li h6 {
    margin: 20px auto;
    width: 230px;
  }
  .specifications ul li:nth-child(2n):before,
  .specifications ul li:nth-child(3n):before {
    width: 238px;
    right: 85px;
  }
  .challenges small svg {
    top: 79px;
    width: 19px;
    height: 19px;
  }
  .challenges small:last-child svg {
    top: 0;
  }
  .we-provided {
    margin-left: 3px;
    width: 97%;
  }
  h2.wow.animated {
    text-align: center;
    padding: 14px 0px;
    letter-spacing: 6px;
    font-size: 36px;
  }
  .provide h2 span.joint {
    position: absolute;
    top: 23px;
    left: 50px;
  }
  .our-desing h6 {
    margin: 0 auto 15px auto;
    width: 87%;
  }
  .wire-frame-images img,
  img.wire-frame2,
  img.visual3,
  img.comparecar,
  img.recommendcar {
    width: 60%;
    margin-top: 20px;
  }
  .wire-frame-images img.wire-frame1,
  .wire-frame-images img.wire-frame3 {
    padding: 0!important;
  }
  .bussiness-value .wire-frame.visual-design .challenges {
    margin: 0;
  }
  .wire-frame .brainstorming {
    height: auto;
  }
  .brainstorming h3,
  .wire-frame.visual-design .brainstorming h3 {
    width: 190px;
    top: -42px;
    line-height: 50px;
    font-size: 18px;
  }
  .wire-frame-images {
    margin-top: 0;
  }
  .bussiness-value .wire-frame-images img {
    width: 100%;
  }
  .features .wire-frame-images {
    position: relative;
    margin: 0 27px 20px;
  }
  .wire-frame.features .wire-frame-images h6 {
    font-size: 20px;
  }
  .features .wire-frame-images {
    margin: 0 10px 50px;
  }
  .features .wire-frame-images img {
    margin-top: 0;
  }
}
@media (max-width: 425px) {
  .port_back_section {
    top: 32px;
    left: 36px;
  }
  .gd-front-img img {
    display: none;
  }
  .gd-portfolio-logo img {
    width: 58%;
    margin: 85px -17px 0;
  }
  .owner-of-site p {
    padding-right: 30px;
    font-size: 14px;
  }
  .bussiness-requritment:before {
    height: 250px;
  }
  .provide h2 span.joint {
    left: 55px;
  }
  .provide h2 {
    font-size: 29px;
  }
  .specifications ul li:first-child h6:first-child {
    line-height: 100px;
  }
  .owner-of-site {
    padding: 0px;
  }
  .row.margin-minus {
    margin: 0 -20px;
  }
  .we-provided {
    margin-left: 0;
  }
  .our-desing h6 {
    margin: 0 0 0 25px;
    width: auto;
    padding: 0 0 30px 0;
  }
  .gd-ecosystem h1 {
    width: auto;
  }
  .specifications ul {
    margin: 0;
  }
  .specifications ul li {
    width: 100%;
    margin: 0 auto;
  }
  .gd-ecosystem {
    height: 800px;
  }
  .specifications {
    padding: 25px 0;
  }
  .specifications .owner-of-site p {
    padding: 0 40px;
  }
  .specifications ul li h6 {
    margin: 16px auto;
    width: 200px;
  }
  .specifications ul li:nth-child(2n):before,
  .specifications ul li:nth-child(3n):before {
    margin: 5px auto;
    left: 26%;
    width: 200px;
  }
  .we-provided .provide h2 {
    letter-spacing: 4px;
    font-size: 28px;
    margin: 0 50px;
  }
  .Gaadibazaar-portfolio .we-provided .provide h2 {
    margin: 0 0px;
  }
  .challenges small svg {
    width: 25px;
    height: 25px;
  }
  .margin-minus .challenges small svg.left-svg {
    left: 36px;
    top: 70px;
  }
  .bussiness-value .wire-frame.visual-design {
    width: 100%;
  }
  .challenges {
    margin: 0 ;
  }
  .wire-frame-images img.wire-frame1,
  .wire-frame-images img.wire-frame3 {
    padding: 0;
  }
  img.wire-frame2,
  img.visual3,
  img.comparecar,
  img.recommendcar {
    width: 60%;
  }
  .bussiness-value .border-right,
  .bussiness-value .border-left {
    border: 2px solid #000;
  }
  .gd-footer {
    padding: 5px 10px;
    margin-top: 0px;
  }
  .gd-footer ul li img {
    width: 70%;
  }
  .gd-footer ul li .playstore-app img {
    width: 100%;
  }
  .port_back_section a {
    font-size: 14px;
  }
  .ix-gd-background h1 {
    top: -102px;
    right: -53px;
    padding: 10px 10px;
    font-size: 16px;
  }
  .gd-footer ul {
    padding: 15px 15px 10px;
  }
  .brainstorming h4 {
    bottom: 2%;
    left: 8%;
  }
  .brainstorming h5 {
    right: 5%;
    top: 40%;
  }
  .challenges {
    width: 100%;
  }
  .challenges p {
    padding: 0px;
  }
  .wire-frame.features .brainstorming .challenges p {
    line-height: 30px;
  }
  .wire-frame .brainstorming small .left-svg1 {
    top: 35px;
  }
  .brainstorming {
    margin-top: 70px;
  }
  .wire-frame-images img,
  img.wire-frame2,
  img.visual3,
  img.comparecar,
  img.recommendcar {
    width: 90%;
  }
  .decision-making h3 {
    font-size: 40px;
    left: -104px;
  }
  .decision-making h3 {
    bottom: 128px;
  }
  .wire-frame.features .challenges.m-0,
  .bussiness-value .wire-frame.visual-design .challenges {
    padding: 0 20px;
  }
  .challenges .quote-p {
    padding: 20px;
  }
}
@media (max-width: 375px) {
  .provide h2 span.joint {
    top: 20px;
    left: 54px;
  }
  .challenges p {
    line-height: 35px;
    font-size: 13px;
  }
  .owner-of-site {
    padding: 0px;
  }
  .bussiness-requritment:before {
    height: 250px;
  }
  .provide h2 span,
  .headline span {
    top: -5px;
  }
  .we-provided .provide h2 {
    margin: 0;
    font-size: 24px;
    letter-spacing: 3px;
    text-align: right;
  }
  .specifications .owner-of-site p {
    padding: 0 27px 0 30px;
    font-size: 14px;
  }
  .bussiness-value .wire-frame .brainstorming {
    margin-top: 30px;
  }
  .bussiness-value h5 {
    font-size: 24px;
  }
  .brainstorming h4 {
    font-size: 13px;
  }
  .bussiness-value p {
    font-size: 14px;
  }
  .decision-making h3 {
    bottom: 130px;
    left: -109px;
  }
}
@media (max-width: 320px) {
  .owner-of-site p {
    font-size: 14px;
  }
  .bussiness-requritment:before {
    height: 280px;
  }
  .gd-ecosystem {
    height: 800px;
  }
  .specifications ul li:nth-child(2n):before,
  .specifications ul li:nth-child(3n):before {
    left: 14%;
  }
  .decision-making h3 {
    font-size: 40px;
    left: -106px;
    bottom: 125px;
  }
  .challenges h3 {
    font-size: 40px;
  }
  .our-desing h4 {
    font-size: 18px;
  }
  .our-desing p {
    font-size: 14px;
  }
  .we-provided {
    width: 95%;
    margin-left: auto;
    width: 96%;
  }
  .we-provided .provide h2 {
    font-size: 18px;
  }
  .our-desing h6 {
    font-size: 14px;
    margin: 0 0px 0 40px;
  }
  .brainstorming h3,
  .wire-frame.visual-design .brainstorming h3 {
    width: 200px;
    font-size: 18px;
    line-height: 42px;
    top: -38px;
  }
  .bussiness-value h5 {
    font-size: 18px;
    line-height: 28px;
    margin: 20px 0;
  }
  .bussiness-value p {
    font-size: 14px;
  }
  .wire-frame .brainstorming {
    height: auto;
  }
  .brainstorming h4,
  .brainstorming h5,
  .brainstorming h6 {
    font-size: 7px;
  }
  .brainstorming h4 {
    bottom: 10%;
    left: 14%;
  }
  .wire-frame .brainstorming small .left-svg1 {
    left: 100px;
    top: 35px;
  }
}
.main-intro-bg {
  background-image: url("/assets/img/portfolio-img/dvs-portfolio-img/laptop-bg.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #f2f5f866;
  padding-bottom: 35px;
}
.ix-container.blockchain-all-contents {
  max-width: 1400px;
  width: 90%;
  margin: 0px auto;
}
header.header {
  display: none;
}
.brief-row {
  /* display: flex;*/
  align-items: center;
}
.dvs-logo {
  margin: 110px 0px 40px;
}
.dvs-logo img {
  width: 110px;
}
.dvs-brief-card {
  border: 1px solid #ddd;
  padding: 25px 53px;
  position: relative;
  background: #fff;
  z-index: 0;
}
.port_back_section {
  position: absolute;
  top: 56px;
  z-index: 3;
  left: inherit;
  right: 50px;
}
.port_back_section a {
  color: #fff;
  text-decoration: none;
  font-size: 13px;
  font-weight: 400;
  background: #5a5dfc;
  padding: 10px 15px;
  text-align: center;
  border-radius: 25px;
}
.port_back_section i {
  color: #fff;
  font-size: 16px;
  margin-right: 10px;
}
.top-blue-border {
  position: relative;
}
.top-blue-border:before {
  content: '';
  position: absolute;
  top: -50px;
  left: -30px;
  width: 100px;
  height: 50px;
  background: transparent;
  border: 1px solid #57b1ff;
  animation: animate 1s linear forwards;
  border-right: 0;
  border-bottom: 0;
}
.bottom-blue-border {
  position: relative;
}
.bottom-blue-border:before {
  content: '';
  position: absolute;
  bottom: -50px;
  right: -30px;
  width: 100px;
  height: 50px;
  background: transparent;
  border: 1px solid #57b1ff;
  animation: animate 1s linear forwards;
  border-top: 0;
  border-left: 0;
}
.prototype-frame .top-blue-border:before {
  top: -20px;
  left: -30px;
}
.prototype-frame .bottom-blue-border:before {
  bottom: -30px;
}
.focus-content-frame .top-blue-border:before {
  top: -10px;
  left: -20px;
}
.focus-content-frame .bottom-blue-border:before {
  bottom: -20px;
  right: -20px;
}
.dvs-port-head h5 {
  font-weight: 700;
  color: #000;
  font-size: 22px;
}
.port_back_section:hover i,
.port_back_section:hover a {
  color: #fff;
  opacity: inherit;
}
.ribbon-head {
  font-size: 33px;
  color: #fff;
  font-weight: 700;
  padding: 0 14px;
}
.prob-ul {
  padding: 15px 0px  15px 20px !important;
}
.prob-li {
  position: relative;
}
.prob-li p {
  max-width: 480px;
  font-size: 15px;
  color: #343434;
  line-height: 25px;
  font-family: 'Montserrat', sans-serif;
  padding-bottom: 25px;
}
.dvs-brief-card-inner-frame {
  padding-top: 80px;
}
.dvs-ribbon {
  position: absolute;
  top: 14px;
  left: -11px;
  padding: 11px 50px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  background-image: linear-gradient(97deg, #5f55ff, #4ebde3);
  box-shadow: 0px 0px 2px 1px #a0a0a0;
}
.dvs-ribbon:before {
  content: '';
  left: 0px;
  border-bottom: 10px solid #737070;
  border-left: 11px solid transparent;
  position: absolute;
  top: -9px;
}
.prob-li:before {
  content: '';
  position: absolute;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  /* background-color: rgb(90, 111, 247); */
  left: -21px;
  top: 6px;
  background: linear-gradient(300deg, #51a6e8 25%, #5a6ff7 50%);
}
.prob-li:after {
  content: '';
  position: absolute;
  width: 1px;
  height: 100%;
  left: -17px;
  border: 1px dashed #9adafc;
  top: 16px;
  z-index: 1;
}
.prob-li:last-child:after {
  display: none;
}
.pulse {
  animation: pulse 1s infinite;
  animation-duration: 2s;
}
@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(245, 87, 86, 0.6);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(245, 87, 86, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(245, 87, 86, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(245, 87, 86, 0.6);
    box-shadow: 0 0 0 0 rgba(86, 205, 245, 0.5);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(245, 87, 86, 0);
    box-shadow: 0 0 0 10px rgba(245, 87, 86, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(245, 87, 86, 0);
    box-shadow: 0 0 0 0 rgba(245, 87, 86, 0);
  }
}
.bottom-bubble .circle {
  width: 10px;
  height: 10px;
  margin: 0 auto;
  margin-top: 0vh;
  background: #bbe7fd;
  border-radius: 100%;
  position: absolute;
  top: 6px;
  left: -21px;
  z-index: -1;
}
.dvs-login-screen-img {
  width: 700px;
  margin-left: -185px;
  margin-top: -100px;
}
.dvs-login-screen-img img {
  width: 100%;
}
.solution-section {
  background-color: #fff;
}
.solution-section-head {
  text-align: center;
  padding: 50px 0px;
}
.solution-section-head h1 {
  font-weight: 700;
  color: #000;
  position: relative;
  z-index: 1;
}
.solution-section-head h1 span {
  background: #fff;
  padding: 0px 25px;
  position: relative;
  font-size: 40px;
}
.solution-section-head h1 span:before {
  height: 15px;
  width: 15px;
  background-color: #9b9b9b;
  border-radius: 50%;
  content: '';
  position: absolute;
  left: 0;
  top: 22px;
}
.solution-section-head h1 span:after {
  height: 15px;
  width: 15px;
  background-color: #9b9b9b;
  border-radius: 50%;
  content: '';
  position: absolute;
  top: 22px;
  right: 0;
}
.solution-section-head h1:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  /* background: #eee; */
  left: 0;
  top: 19px;
  z-index: -1;
  border: 1px dashed #c2c2c2;
}
.unified-section {
  /*padding-bottom: 69px;*/
  border-top: 1px dashed #26b2fe;
  border-bottom: 1px dashed #26b2fe;
  background-image: url(/assets/img/portfolio-img/dvs-portfolio-img/BG-1.png);
  background-size: 90%;
  background-repeat: no-repeat;
  background-color: #f2f5f8;
  background-position: center bottom;
}
.unified-head {
  text-align: center;
}
.sub-heads {
  color: #000;
  font-weight: 700;
  top: -29px;
  left: 0;
  right: 0;
  font-size: 22px;
}
.unified-head p {
  margin: 0px;
  padding: 10px 0px;
  color: #363636;
  font-size: 15px;
  font-family: 'Montserrat', sans-serif;
  line-height: 25px;
}
.block-enabled-platform-content {
  padding: 45px 0px;
}
.block-enabled-platform-head {
  text-align: center;
}
.block-enabled-platform-head h6 {
  color: #0b498f;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 18px;
}
.cards-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0!important;
}
.block-card {
  height: auto!important;
  background-color: #fff;
  min-height: 162px;
  padding: 15px;
  align-items: center;
  justify-content: center;
  display: flex;
  box-shadow: 0px 1px 3px 0px rgba(212, 203, 203, 0.25);
}
.card-para {
  font-size: 15px;
  color: #363636;
  font-family: 'Montserrat', sans-serif;
  line-height: 25px;
}
.block-platform-cards {
  padding: 40px 0px;
}
.creating-prototype-section {
  /*   margin: 20px 0px;*/
  border-bottom: 1px dashed #26b2fe;
  margin: 0px;
}
.creating-prototype-head,
.focus-main-head {
  text-align: center;
  border: 1px solid #dfdfdf;
  padding: 40px;
  position: relative;
  margin: 40px 0;
  background: #fff;
}
.creating-prototype-head .sub-heads span {
  background: #fff;
  font-size: 22px;
}
.focus-main-head .sub-heads span {
  background: #f2f5f866;
  font-size: 22px;
}
.proto-para {
  margin: 0px;
  padding: 10px 0px;
  font-size: 16px;
  font-weight: 400;
  color: #494037;
  line-height: 30px;
}
.focus-content-hold {
  margin-top: 50px;
}
.focus-content-hold fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 10px 50px 20px;
  min-width: 0;
  background: #fff;
}
.focus-content-hold legend {
  display: block;
  width: 65%;
  padding: 0;
  margin-bottom: 10px;
  font-size: 22px;
  line-height: inherit;
  border: 0;
  padding: 0px 12px;
  color: #000;
  font-weight: 700;
}
.prototype-main-images {
  padding: 45px 0px;
}
.focus-content-frame,
.prototype-frame {
  padding: 40px 20px;
}
.creating-prototype-head .sub-heads,
.focus-main-head .sub-heads {
  position: absolute;
  top: -16px;
}
.new-request-left-img img {
  width: 95%;
}
.contact-owner-right-img {
  text-align: right;
}
.contact-owner-right-img img {
  width: 95%;
}
.focus-design {
  background-image: url("/assets/img/portfolio-img/dvs-portfolio-img/design-section-new-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #f2f5f8;
  z-index: -1;
}
.dvs-left-mac img {
  width: 100%;
}
.focus-contents {
  padding: 50px 0px;
}
.contact-owner-right-img:before {
  position: absolute;
  content: '';
  top: -32px;
  left: -68px;
  right: 0;
  background: url(/assets/img/portfolio-img/dvs-portfolio-img/line-2.png) no-repeat;
  width: 500px;
  height: 300px;
}
/*bubbles*/
.bottom-bubble {
  position: relative;
}
.bottom-bubble:before {
  content: '';
  position: absolute;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: linear-gradient(300deg, #51a6e8 25%, #5a6ff7 50%);
  left: -21px;
  top: 6px;
  z-index: 1;
}
.bottom-bubble .circle {
  z-index: 0;
}
.bottom-bubble .pulse::before {
  z-index: 0;
}
.bottom-bubble:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 33px;
  left: -17px;
  border: 1px dashed #9adafc;
  top: 17px;
  z-index: 1;
}
.creating-prototype-content {
  position: relative;
}
.creating-prototype-content:before {
  content: '';
  position: absolute;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: linear-gradient(300deg, #51a6e8 25%, #5a6ff7 50%);
  left: 0;
  right: 0;
  top: 38px;
  margin: 0 auto;
  z-index: 0;
}
.creating-prototype-content:after {
  content: '';
  position: absolute;
  width: 1px;
  height: 7%;
  left: 0;
  border: 1px dashed #9adafc;
  top: -20px;
  z-index: -1;
  right: 0;
  margin: 0 auto;
}
.new-request-left-img {
  position: relative;
}
.new-request-left-img:before {
  content: '';
  position: absolute;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: linear-gradient(300deg, #51a6e8 25%, #5a6ff7 50%);
  left: 0;
  right: 0;
  bottom: -44px;
  margin: 0 auto;
  z-index: 0;
}
.new-request-left-img:after {
  content: '';
  position: absolute;
  width: 1px;
  height: 10%;
  left: 0;
  border: 1px dashed #9adafc;
  bottom: -34px;
  z-index: -1;
  right: 0;
  margin: 0 auto;
}
.left-request-col {
  position: relative;
}
.left-request-col:before {
  content: '';
  position: absolute;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: linear-gradient(300deg, #51a6e8 25%, #5a6ff7 50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  top: -38px;
}
.left-request-col:after {
  content: '';
  position: absolute;
  width: 2px;
  height: 9%;
  left: -17px;
  border: 1px dashed #9adafc;
  top: -28px;
  z-index: 1;
  margin: 0 auto;
  left: 0;
  right: 0;
}
.focus-right-col {
  position: relative;
}
.focus-right-col:before {
  content: '';
  position: absolute;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: linear-gradient(300deg, #51a6e8 25%, #5a6ff7 50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  top: -11px;
  z-index: 2;
}
.focus-right-col:after {
  content: '';
  position: absolute;
  width: 2px;
  height: 12%;
  left: -17px;
  border: 1px dashed #9adafc;
  top: -49px;
  z-index: 1;
  margin: 0 auto;
  left: 0;
  right: 0;
}
.main-intro-bg {
  position: relative;
}
.grad-bubbles-img {
  position: absolute;
  right: 0px;
  bottom: 23px;
  height: 120px;
  width: 120px;
}
.grad-bubbles-img img {
  width: 100%;
}
.unified-section {
  position: relative;
}
.grad-bubbles-img-unified {
  position: absolute;
  left: 0px;
  /* bottom: 0px; */
  height: 120px;
  width: 120px;
  top: 28px;
}
.grad-bubbles-img-unified img {
  width: 100%;
  transform: rotate(180deg);
}
.creating-prototype-section {
  position: relative;
}
.grad-bubbles-img-prototype {
  position: absolute;
  right: 0px;
  bottom: -69px;
  height: 120px;
  width: 120px;
}
.grad-bubbles-img-prototype img {
  width: 100%;
}
.focus-design {
  position: relative;
}
.grad-bubbles-img-focus {
  position: absolute;
  left: 0px;
  /* bottom: 0px; */
  height: 120px;
  width: 120px;
  top: 28px;
}
.grad-bubbles-img-focus img {
  width: 100%;
  transform: rotate(180deg);
}
.landing-strip-top-left {
  position: absolute;
  bottom: 50px;
  left: 0;
}
.landing-strip-top-left img {
  width: 100%;
}
.landing-strip-top-right {
  position: absolute;
  top: 50px;
  right: 0px;
}
.landing-strip-top-right img {
  width: 100%;
  transform: scaleX(-1);
}
/*
    .landing-strip-bottom-left {
    position: absolute;
    bottom: 50px;
    left: 0;
}
    .landing-strip-bottom-left img
    {
        width: 100%;
    }*/
.landing-strip-bottom-right {
  position: absolute;
  top: 50px;
  right: 0px;
}
.landing-strip-bottom-right img {
  width: 100%;
  transform: scaleX(-1);
}
.unified-card-img {
  width: 100px;
}
.unified-card-img img {
  width: 100%;
}
.bottom-bubble-one .circle {
  width: 10px;
  height: 10px;
  margin-top: 0vh;
  background: #bbe7fd;
  border-radius: 100%;
  position: absolute;
  top: 38px;
  left: 0;
  z-index: -1;
  right: 0;
  margin: 0 auto;
}
.top-pulse {
  width: 10px;
  height: 10px;
  margin: 0 auto;
  margin-top: 0px;
  background: #bbe7fd;
  border-radius: 100%;
  position: absolute;
  top: -38px;
  left: 0;
  z-index: -1;
  right: 0;
}
.bottom-pulse {
  width: 10px;
  height: 10px;
  margin: 0 auto;
  margin-top: 0px;
  background: #bbe7fd;
  border-radius: 100%;
  position: absolute;
  bottom: -44px;
  left: 0;
  z-index: -1;
  right: 0;
}
.bottom-pulse-last {
  width: 10px;
  height: 10px;
  margin: 0 auto;
  margin-top: 0px;
  background: #bbe7fd;
  border-radius: 100%;
  position: absolute;
  top: -11px;
  left: 0;
  z-index: -2;
  right: 0;
}
.unified-solution-content .unified-head {
  margin-top: 50px;
}
/*RESPONSIVE FOR DVS PORTFOLIO*/
@media (max-width: 1440px) {
  .port_back_section {
    right: 15px;
  }
  .dvs-login-screen-img {
    width: 650px;
    margin-left: -140px;
  }
  .focus-content-hold legend {
    width: 70%;
  }
}
@media (max-width: 991px) {
  .prob-li p {
    max-width: 100%;
  }
  .dvs-login-screen-img {
    width: 460px;
    margin: 30px auto!important;
  }
  .unified-solution-content .unified-head {
    margin-top: 50px;
  }
  .card-body {
    width: 100%;
  }
  .unified-card-img {
    margin: 0px auto;
    width: 100px;
  }
  .contact-owner-right-img {
    text-align: inherit;
  }
  .contact-owner-right-img {
    margin-top: 70px;
  }
}
@media (max-width: 1024px) {
  .brief-container {
    width: 100%;
  }
  .dvs-login-screen-img {
    width: 460px;
    margin-left: -65px;
  }
  .block-platform-cards .row .block-col {
    padding: 0px;
  }
  .card-para {
    font-size: 14px;
  }
  .block-card {
    min-height: 177px;
    padding: 10px!important;
  }
  .left-request-col:after {
    content: '';
    position: absolute;
    width: 2px;
    height: 12%;
    left: -17px;
    border: 1px dashed #9adafc;
    top: -28px;
    z-index: 1;
    margin: 0 auto;
    left: 0;
    right: 0;
  }
  .new-request-left-img:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 15%;
    left: 0;
    border: 1px dashed #9adafc;
    bottom: -34px;
    z-index: -1;
    right: 0;
    margin: 0 auto;
  }
  .focus-right-col:after {
    content: '';
    position: absolute;
    width: 2px;
    height: 10%;
    left: -17px;
    border: 1px dashed #9adafc;
    top: -49px;
    z-index: 1;
    margin: 0 auto;
    left: 0;
    right: 0;
  }
  .dvs-left-mac {
    padding: 64px 0px;
  }
  .focus-content-frame,
  .prototype-frame {
    padding: 40px 0px;
    padding-bottom: 0px;
  }
  .block-card-img-col {
    padding: 0px;
  }
  .port_back_section {
    right: 0;
  }
  .solution-section-head h1 span {
    font-size: 32px;
  }
  .solution-section-head h1 span:before {
    top: 17px;
  }
  .solution-section-head h1 span:after {
    top: 17px;
  }
  .creating-prototype-content:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 9%;
    left: 0;
    border: 1px dashed #9adafc;
    top: -20px;
    z-index: -1;
    right: 0;
    margin: 0 auto;
  }
  .focus-content-hold legend {
    width: 100%;
    font-size: 21px;
  }
}
@media (max-width: 1023px) {
  .contact-owner-right-img:before {
    display: none;
  }
}
@media (max-width: 768px) {
  .dvs-mac-col {
    display: none;
  }
  .prob-li p {
    max-width: inherit;
  }
  .solution-section-head h1 span {
    font-size: 22px;
  }
  .solution-section-head h1 span:before {
    top: 0px;
  }
  .solution-section-head h1 span:after {
    top: 0px;
  }
  .sub-heads {
    font-size: 18px;
  }
  .unified-head p {
    font-size: 13px;
  }
  .grad-bubbles-img-unified {
    width: 90px;
    height: 90px;
  }
  .block-card {
    margin-bottom: 20px!important;
  }
  .block-card-img-col {
    width: 25%;
  }
  .creating-prototype-content:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 4%;
    left: 0;
    border: 1px dashed #9adafc;
    top: -20px;
    z-index: -1;
    right: 0;
    margin: 0 auto;
  }
  .left-request-col {
    padding: 20px 0px;
  }
  .right-owner-col {
    padding: 30px 0px;
  }
  .grad-bubbles-img {
    width: 90px;
    height: 90px;
    bottom: -51px;
  }
  .grad-bubbles-img-prototype {
    position: absolute;
    right: 0px;
    bottom: -45px;
    height: 90px;
    width: 90px;
  }
  .grad-bubbles-img-focus {
    width: 90px;
    height: 90px;
  }
  .ribbon-head {
    font-size: 22px;
  }
  .focus-right-col:before {
    content: '';
    position: absolute;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: linear-gradient(300deg, #51a6e8 25%, #5a6ff7 50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 46px;
  }
  .focus-right-col:after {
    content: '';
    position: absolute;
    width: 2px;
    height: 16%;
    left: -17px;
    border: 1px dashed #9adafc;
    z-index: 1;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 2px;
  }
  .bottom-pulse-last {
    width: 10px;
    height: 10px;
    margin: 0 auto;
    margin-top: 0px;
    background: #bbe7fd;
    border-radius: 100%;
    position: absolute;
    top: 46px;
    left: 0;
    z-index: -2;
    right: 0;
  }
  .bottom-pulse {
    width: 10px;
    height: 10px;
    margin: 0 auto;
    margin-top: 0px;
    background: #bbe7fd;
    border-radius: 100%;
    position: absolute;
    bottom: -24px;
    left: 0;
    z-index: -1;
    right: 0;
  }
  .port_back_section {
    right: 18px;
  }
  .focus-content-hold legend {
    width: 57%;
  }
}
@media (max-width: 768px) {
  .dvs-ribbon {
    padding: 11px 15px;
  }
  .dvs-brief-card {
    padding: 25px 15px;
  }
  .solution-section-head h1 span {
    font-size: 16px;
  }
  .unified-solution-content {
    padding-top: 40px;
  }
  .sub-heads {
    font-size: 16px;
  }
  .unified-head p {
    font-size: 14px;
  }
  .unified-card-img {
    width: 60px;
    margin: 0 auto;
  }
  .creating-prototype-head,
  .focus-main-head {
    padding: 20px;
  }
  .creating-prototype-head .sub-heads span {
    font-size: 18px;
  }
  .block-enabled-platform-content {
    padding: 30px 0px;
  }
  .unified-section {
    padding-bottom: 0px;
  }
  .dvs-left-mac {
    padding: 0px;
  }
  .bottom-pulse {
    width: 10px;
    height: 10px;
    margin: 0 auto;
    margin-top: 0px;
    background: #bbe7fd;
    border-radius: 100%;
    position: absolute;
    bottom: -24px;
    left: 0;
    z-index: -1;
    right: 0;
  }
}
@media only screen and (max-width: 766px) and (min-width: 426px) {
  .cards-row {
    display: block;
  }
  .block-card-img-col {
    width: 100%;
    margin: 20px auto;
  }
  .port_back_section {
    top: -100px;
  }
}
@media (max-width: 425px) {
  .dvs-logo {
    margin: 40px 0px;
  }
  .port_back_section {
    position: absolute;
    top: -110px;
    z-index: 3;
  }
  .sub-heads {
    margin-top: 30px;
    line-height: 25px;
    letter-spacing: 0.8px;
  }
  .creating-prototype-head .sub-heads,
  .focus-main-head .sub-heads {
    top: -15px;
  }
  .focus-content-hold fieldset {
    padding: 10px 20px;
  }
  .focus-content-hold legend {
    width: 84%;
    padding: 0px 10px;
    font-size: 16px;
  }
  .creating-prototype-head .sub-heads {
    margin-top: 0px;
  }
  .prob-li p {
    font-size: 14px;
  }
  .proto-para {
    font-size: 14px;
  }
  .blockchain-all-contents .dvs-logo-sec,
  .blockchain-all-contents .brief-col {
    padding: 0px;
  }
  .block-platform-cards .top-blue-border:before {
    top: -20px;
    left: -15px;
  }
  .block-platform-cards .bottom-blue-border:before {
    bottom: -18px;
    right: -15px;
  }
  .cards-row {
    display: block;
  }
  .block-card {
    min-height: 280px;
  }
  .block-card-img-col {
    width: 100%;
  }
  .card-para {
    padding-top: 20px;
    text-align: center;
  }
  .prototype-frame .top-blue-border:before {
    top: -3px;
    left: -14px;
  }
  .prototype-frame .bottom-blue-border:before {
    bottom: -15px;
    right: -13px;
  }
  .solution-section-head h1 span:before {
    width: 10px;
    height: 10px;
    top: 3px;
  }
  .solution-section-head h1 span:after {
    width: 10px;
    height: 10px;
    top: 3px;
  }
  .creating-prototype-content:before {
    top: 24px;
  }
  .bottom-bubble-one .circle {
    top: 24px;
  }
  .left-request-col:after {
    top: -9px;
  }
  .left-request-col:before {
    top: -19px;
  }
  .top-pulse {
    top: -19px;
  }
  .grad-bubbles-img-focus {
    top: 14px;
  }
  .grad-bubbles-img-prototype {
    bottom: -30px;
  }
  .solution-section-head {
    padding: 25px 0px;
  }
  .bottom-bubble:before {
    content: '';
    position: absolute;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: linear-gradient(300deg, #51a6e8 25%, #5a6ff7 50%);
    left: -21px;
    top: -7px;
    z-index: 1;
  }
  .bottom-bubble .circle {
    top: -7px;
  }
  .bottom-bubble:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 20px;
    left: -17px;
    border: 1px dashed #9adafc;
    top: 5px;
    z-index: 1;
  }
  .block-card {
    min-height: auto;
    padding: 11px!important;
  }
  .block-row {
    margin-bottom: 0px;
  }
}
@media only screen and (max-width: 537px) and (min-width: 426px) {
  .focus-right-col:after {
    content: '';
    position: absolute;
    width: 2px;
    height: 10%;
    left: -17px;
    border: 1px dashed #9adafc;
    z-index: 1;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 2px;
  }
}
@media only screen and (max-width: 400px) and (min-width: 376px) {
  .focus-content-hold legend {
    width: 94%!important;
  }
}
@media only screen and (max-width: 370px) and (min-width: 354px) {
  .focus-content-hold legend {
    width: 100%!important;
  }
}
@media (max-width: 375px) {
  .focus-content-hold legend {
    width: 94%;
  }
  .solution-section-head h1:before {
    display: none;
  }
  .solution-section-head h1 span:before {
    display: none;
  }
  .solution-section-head h1 span:after {
    display: none;
  }
  .landing-strip-top-left {
    display: none;
  }
  .landing-strip-top-right {
    display: none;
  }
  .solution-section-head h1 {
    margin-top: 0px;
  }
  .solution-section-head h1 span {
    padding: 0px;
  }
  .top-blue-border:before {
    width: 50px;
    height: 50px;
  }
  .bottom-blue-border:before {
    width: 50px;
    height: 50px;
  }
  .new-request-left-img:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 15%;
    left: 0;
    border: 1px dashed #9adafc;
    bottom: -28px;
  }
  .bottom-pulse {
    bottom: -18px !important;
  }
  .new-request-left-img:before {
    bottom: -38px;
  }
}
@media (max-width: 320px) {
  .focus-content-hold legend {
    width: 87%;
  }
  .port_back_section {
    right: 0px;
  }
  .solution-section-head {
    padding: 25px 0px;
  }
  .solution-section-head h1 {
    margin: 0;
    padding: 20px 0;
    line-height: 25px;
    padding-top: 0px;
  }
  .solution-section-head h1 span {
    padding: 0;
    font-size: 14px;
  }
  .left-request-col:after {
    height: 15%;
  }
  .new-request-left-img:after {
    height: 20%;
  }
  .focus-right-col:after {
    height: 12%;
  }
  .prototype-frame .top-blue-border:before {
    left: -11px;
  }
}
.Loan-portfolio .ix-container {
  width: 97%;
  margin: 0px auto;
}
.epl-portfolio:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0px;
  width: 45%;
  height: 100%;
  background-position: right;
  background-image: url(/assets/img/portfolio-img/epl-portfolio-img/mob-bg.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: -1;
}
.ix-logo img {
  width: 150px;
  position: absolute;
  top: 30px;
}
.sec1-content {
  padding: 100px 0px 100px 40px;
}
.sec1-content h1 {
  color: #3fd0d2;
  font-size: 50px;
  font-weight: 900;
  margin: 50px 0px;
}
.sec1-image img {
  padding: 100px 0px;
  width: 60%;
}
.sec1-image {
  text-align: center;
}
.sec1-subcnt {
  width: 65%;
  position: relative;
}
.sec1-subcnt:after {
  content: '';
  position: absolute;
  background-image: url(/assets/img/portfolio-img/epl-portfolio-img/sec1-1.png);
  width: 100%;
  height: 100%;
  top: 200px;
  left: 100%;
  background-repeat: no-repeat;
}
.sec1-subcnt fieldset {
  border: dashed 1px rgba(12, 74, 143, 0.4);
  border-radius: 0px 100px 100px 100px;
  padding: 10px;
  margin: 0px;
}
.sec1-subcnt legend {
  display: block;
  width: auto;
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin: 0;
  padding: 0 10px 0 10px;
}
.sec1-subcnt .product {
  padding: 15px;
}
.sec1-subcnt .product p {
  font-size: 15px;
  font-weight: normal;
  line-height: 25px;
  color: #2a2a2a;
}
.sec1-subcnt .product p:last-child {
  margin: 0 0 35px;
}
.sec1-subcnt .product h3 {
  position: relative;
  font-size: 24px;
  font-weight: bold;
  margin: 35px 0 15px;
}
.sec1-subcnt .product h3:after {
  content: '';
  position: absolute;
  border: dashed 1px rgba(12, 74, 143, 0.4);
  width: 100%;
  left: 0px;
  top: 10px;
  z-index: -1;
}
.sec1-subcnt .product h3 span {
  background: #fff;
  z-index: 2;
  padding-right: 15px;
}
/** section 2 **/
.sec2 {
  position: relative;
  padding-bottom: 40px;
}
.sec2-content {
  position: relative;
  display: inline-flex;
}
.epl-portfolio {
  position: relative;
}
.sec2 {
  background-image: url(/assets/img/portfolio-img/epl-portfolio-img/sec2-bg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-top: -20px;
  padding: 100px 0px;
}
.mob-frame img {
  margin: 100px 0;
}
.sec-2-one:before {
  right: 0;
  left: 50%;
  background-image: url(/assets/img/portfolio-img/epl-portfolio-img/sec2-2.png) !important;
  width: 75% !important;
}
.sec2-img img {
  width: 300px;
}
.sec2-img {
  z-index: 1;
}
.unique-point {
  padding-top: 55px;
  margin-left: -64px;
}
.unique-point-two {
  margin-left: 0px !important;
  margin-right: -55px;
  padding-top: 360px;
}
.unique-point h2 {
  color: #3fd0d2;
  font-size: 38px;
  font-weight: 900;
  margin: 0;
  position: absolute;
  left: -30%;
  top: 47%;
}
.unique-point-two {
  border-radius: 0px 100px 100px 100px !important;
}
/*.unique-point h2 {
    color: #3fd0d2;
    font-size: 45px;
    font-weight: bold;
    padding: 230px 0 10px 0px;
    margin: 0;
}*/
.unique-point fieldset {
  border: dashed 1px rgba(12, 74, 143, 0.4);
  border-radius: 100px 0px 100px 100px;
  margin: 0;
  padding: 15px;
  width: 100%;
  display: inline-block;
  background: #fff;
  position: relative;
}
.unique-point legend {
  font-size: 20px;
  font-weight: 700;
  width: auto;
  display: inline-block;
  padding: 0px 15px;
  margin: 0;
}
.unique-point .data p {
  font-size: 15px;
  line-height: 25px;
  color: #2a2a2a;
  text-align: left;
  font-weight: 500;
  padding: 0 28px;
  margin: 0;
}
.unique-point p span,
.design p span {
  font-size: 15px;
  font-weight: 900;
  color: #0c4a8f;
}
.unique-point .data1 p:last-child {
  text-align: right;
  font-size: 15px;
  line-height: 25px;
  color: #2a2a2a;
  margin: 0;
  padding: 15px 10px;
}
.unique-point legend:last-child {
  font-size: 24px;
  font-weight: bold;
  width: 100%;
  padding: 0 0 0 20px;
  margin: 0;
}
.unique-point fieldset::before {
  content: '';
  position: absolute;
  background-image: url(/assets/img/portfolio-img/epl-portfolio-img/sec2-1.png);
  width: 45%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  top: 100%;
  right: 45%;
}
.unique-point .sec-2-one::after {
  position: absolute;
  background-image: url(/assets/img/portfolio-img/epl-portfolio-img/sec2-2.png);
  width: 90%;
  height: 95%;
  background-repeat: no-repeat;
  background-size: 95% 58%;
  top: 170px;
  left: 45%;
}
/** section 3 ***/
.fields li fieldset {
  border: 1px dashed rgba(12, 74, 143, 0.51);
  border-radius: 0px 100px 100px 150px;
  margin: 0;
  padding: 10px 40px 30px;
  background: #fff;
  z-index: 999;
  min-width: 178px;
}
.fields legend:last-child {
  padding: 0 0;
  width: 100%;
  margin: 10px 0px;
}
.fields legend p:last-child {
  margin-bottom: 20px;
}
.fields2 li fieldset {
  border: 1px dashed #c0c0c0;
  border-radius: 0px 100px 100px 200px;
  margin: 0;
  padding: 30px 35px 20px 40px;
  background: #fff;
}
.fields fieldset legend {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 700;
  color: #000;
  width: auto;
  padding: 0px 10px;
}
.width-class {
  width: 100px;
}
.fields li fieldset p {
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  color: #2a2a2a;
  margin-bottom: 0px;
}
.sec-head .part-1 {
  margin-top: -15px;
}
.sec-head1 {
  padding: 18px 0;
}
.sec3-head {
  padding-bottom: 0px;
}
.sec3-head ul li {
  padding: 0px 40px;
  z-index: 9;
  min-width: 240px;
  flex: 0 0 25%;
  position: relative;
  list-style-type: none;
}
.sec-bg1 {
  background: #fff;
  margin-top: 15px;
  padding: 0 5px;
}
.sec-bg1 h1 {
  float: left;
}
.padd-5 {
  padding: 0 5px;
}
.sec-head {
  padding: 50px 0;
}
.sec-head2 {
  padding: 70px 0;
}
.sec3-2 {
  display: inline-flex;
}
.sec3-1 {
  display: inline-flex;
  margin-left: 230px;
}
.sec3-img1 {
  margin-top: 0px;
}
.sec3-img1 img {
  width: 255px;
  bottom: -58px;
  position: relative;
  left: 20px;
}
.sec3-img2 img {
  width: 265px;
  position: relative;
  bottom: -18px;
}
.sec3-img3 {
  margin-top: -100px;
  margin-left: -110px;
}
.sec3-img3 img {
  width: 380px;
  position: relative;
  bottom: 30px;
  left: -15px;
}
.sec3 {
  margin-top: -20px;
  background-image: url(/assets/img/portfolio-img/epl-portfolio-img/sec3-bg.jpg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  clear: both;
  padding: 30px 0px;
}
.part-1 {
  padding-bottom: 140px;
}
.part-1 .fields {
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 0px;
  width: 100%;
}
.field-list .fields li fieldset {
  width: 300px;
  padding: 15px 40px;
}
.li-mar {
  margin-top: 15px;
}
.sec-head h1 {
  font-size: 50px;
  font-weight: 900;
  color: #3fd0d2;
  margin: 0;
  text-align: right;
  padding-right: 60px;
}
.sec-head2 .fields li fieldset {
  width: 260px;
}
.sec-head2 h3 {
  font-size: 50px;
  font-weight: 900;
  color: #3fd0d2;
  text-align: center;
  margin: 0px 0px 0px 0px;
}
.sec3-head .fields li::after {
  content: '';
  position: absolute;
  border: 1px dashed rgba(12, 74, 143, 0.51);
  display: inline-block;
  width: 100%;
  top: 30%;
  z-index: -1;
}
.sec3-head .fields .no-border::after {
  content: none;
}
.pre-line {
  position: relative;
}
.pre-line fieldset::after {
  content: '';
  position: absolute;
  width: 771px;
  height: 684px;
  left: -140%;
  top: 140px;
  background-size: 680px;
  background-repeat: no-repeat;
  background-image: url(/assets/img/portfolio-img/epl-portfolio-img/image-g.png);
}
.no-border fieldset::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 463px;
  left: -55px;
  top: 142px;
  background-size: 246px;
  background-repeat: no-repeat;
  background-image: url(/assets/img/portfolio-img/epl-portfolio-img/image-h.png);
}
/*** section 4 ***/
.sec4 {
  background-image: url(/assets/img/portfolio-img/epl-portfolio-img/sec4-bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 70px 0px;
}
.sec4 h1 {
  font-size: 50px;
  font-weight: 900;
  text-align: center;
  color: #3fd0d2;
  position: relative;
  margin: 0px;
}
.sec4 h1 span {
  color: #fff;
  font-size: 50px;
  font-weight: 900;
  text-align: center;
}
.sec4-image {
  width: 100%;
  display: inline-flex;
}
.sec4-image .sec4-1,
.sec4-image .sec4-2 {
  display: inline-block;
  width: 100%;
}
.sec4-2 {
  margin-left: -10%;
}
.sec4-1 img,
.sec4-2 img {
  width: 100%;
}
.sec4-2 img {
  margin-top: 150px;
}
.design fieldset {
  background: #fff;
  margin-top: 50px;
  border: dashed 1px rgba(12, 74, 143, 0.4);
  border-radius: 0 100px 100px 100px;
  display: inline-block;
  margin-left: 5%;
  padding: 10px 0px 0 15px;
  width: 80%;
}
.design fieldset:last-child {
  margin-top: 100px;
}
.design legend {
  font-size: 22px;
  font-weight: bold;
  color: #000;
  margin: 0;
  padding: 0 5px;
  width: auto;
}
.design legend:last-child {
  width: 50%;
  font-size: 24px;
  font-weight: bold;
  color: #000;
  margin: 0;
  padding: 0 13px;
}
.design .data1 p {
  font-size: 15px;
  font-weight: 500;
  line-height: 25px;
  color: #2a2a2a;
  margin-bottom: 20px;
  padding: 0 10px;
}
.design .data1 {
  padding: 10px 8px;
}
.design fieldset:first-child::before {
  content: '';
  position: absolute;
  background-image: url(/assets/img/portfolio-img/epl-portfolio-img/sec4-1.png);
  width: 55%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  right: 93%;
}
.design fieldset:last-child::after {
  content: '';
  position: absolute;
  background-image: url(/assets/img/portfolio-img/epl-portfolio-img/sec4-2.png);
  width: 14%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  top: 75%;
  right: 93%;
}
/*** last section ***/
.last-sec {
  background-image: url(/assets/img/portfolio-img/epl-portfolio-img/last-sec-bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 100px 0px 20px;
  margin-top: 0px;
}
.last-head-sec h1 {
  font-size: 50px;
  font-weight: 900;
  color: #3fd0d2;
  margin: 0;
  padding: 0px 32px;
  text-align: center;
}
.last-head-sec h1 span {
  font-size: 50px;
  font-weight: 900;
  color: #fff;
  margin: 0;
  text-align: center;
}
.last-process {
  margin: 100px 0px;
  position: relative;
}
.last-process::after {
  content: '';
  background-image: url(/assets/img/portfolio-img/epl-portfolio-img/image-L.png);
  width: 40%;
  height: 100%;
  position: absolute;
  background-repeat: no-repeat;
  background-size: 100%;
  top: 45%;
  right: -40%;
}
.last-process fieldset {
  border: 1px dashed rgba(12, 74, 143, 0.4);
  border-radius: 0px 100px 100px 100px;
  margin: 0 2px;
  padding: 20px 30px 20px 20px;
  background: #fff;
}
.last-sec-img {
  margin: 10px;
  display: inline-block;
}
.last-process ul {
  width: 100%;
  display: inline-flex;
}
.last-process ul li {
  display: inline-block;
}
.last-process .pad-left {
  padding: 0px 10px;
}
.last-process .head-1 h6 {
  font-size: 24px;
  font-weight: 700;
  color: #000000;
  font-family: Raleway;
  margin-bottom: 10px;
}
.last-process .head-2 p {
  font-family: Roboto;
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: normal;
  color: #2a2a2a;
  margin-bottom: 0px;
}
.right-sec-img {
  text-align: right;
}
.right-sec-img img {
  width: 400px;
}
/*back to portfolio */
.port_back_section {
  position: absolute;
  top: 120px;
  z-index: 3;
  left: 120px;
}
.port_back_section {
  top: 60px;
  left: 60px;
}
.port_back_section i {
  color: #fff;
  font-size: 20px;
  margin-right: 10px;
}
a:hover {
  text-decoration: none!important;
}
.port_back_section a,
.port_back_section i {
  color: #000;
  font-weight: 600;
}
.port_back_section:hover a,
.port_back_section:hover i {
  color: #204a8f;
  font-weight: 600;
}
.case-study-read-more {
  justify-content: center;
}
@media only screen and (max-width: 1300px) {
  .pre-line fieldset::after {
    content: '';
    position: absolute;
    width: 771px;
    height: 684px;
    left: -118%;
    top: 165px;
    background-size: 520px;
    background-repeat: no-repeat;
    background-image: url(/assets/img/portfolio-img/epl-portfolio-img/image-g.png);
  }
  .no-border fieldset::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 464px;
    left: 74px;
    top: 166px;
    background-size: 108px;
    background-repeat: no-repeat;
    background-image: url(/assets/img/portfolio-img/epl-portfolio-img/image-h.png);
  }
  .Loan-portfolio .ix-container {
    width: 87%;
    margin: 0px auto;
  }
}
@media only screen and (max-width: 1200px) {
  .sec1-content h1 {
    font-size: 32px;
  }
  .sec1-subcnt legend {
    font-size: 22px;
  }
  .sec1-subcnt .product h3 {
    font-size: 22px;
  }
  .unique-point legend {
    font-size: 18px;
    padding: 0px 5px;
  }
  .unique-point h2 {
    font-size: 32px;
  }
  .sec-head2 h3 {
    font-size: 32px;
  }
  .sec4 h1 span {
    font-size: 32px;
  }
  .sec4 h1 {
    font-size: 32px;
  }
  .design legend {
    font-size: 19px;
  }
  .last-head-sec h1 span {
    font-size: 32px;
  }
  .last-head-sec h1 {
    font-size: 32px;
  }
  .last-process .head-1 h6 {
    font-size: 20px;
  }
  .sec1-content {
    padding: 100px 20px 0px 20px;
  }
  .pre-line fieldset::after {
    content: none;
  }
  .no-border fieldset::after {
    content: none;
  }
  .unique-point fieldset::before {
    content: none;
  }
  .ix-logo img {
    top: 20px;
    left: 38px;
  }
  .epl-portfolio::after {
    content: none;
  }
  .sec1-image {
    text-align: center;
  }
  .sec1-image img {
    padding: 0px 0px 20px;
    width: 60%;
    margin-left: 0px;
  }
  .sec1-subcnt {
    width: 100%;
    position: relative;
  }
  .sec1-subcnt:after {
    content: none;
  }
  .unique-point h1 {
    color: #3fd0d2;
    font-size: 50px;
    font-weight: bold;
    padding: 0px 0px 20px;
    margin: 0;
    margin-left: -20px;
  }
  .unique-point fieldset:first-child::before {
    content: none;
  }
  .sec4 {
    padding: 0px 0px 70px;
  }
  .design fieldset:first-child::before {
    content: none;
  }
  .design fieldset:last-child::after {
    content: none;
  }
  .design fieldset:last-child {
    margin-top: 50px;
  }
  .sec4-2 img {
    margin-bottom: 0px;
  }
  .last-process::after {
    content: none;
  }
  .last-process {
    margin: 40px 0px;
    position: relative;
  }
  .right-sec-img {
    text-align: center;
  }
  .last-sec {
    margin-top: 0px;
  }
  .sec2-content {
    display: inline-flex;
    padding: 0px 0px;
  }
  .unique-point h1 {
    color: #3fd0d2;
    font-size: 50px;
    font-weight: bold;
    padding: 0px 0 25px 40px;
    margin: 0;
    margin-left: -20px;
  }
  .sec2 {
    height: auto;
    padding: 40px 0px;
  }
  .sec2-img {
    text-align: center;
  }
  .part-1 .fields {
    overflow-x: auto;
    max-width: 100%;
    padding: 20px 0px;
    justify-content: flex-start;
  }
  .last-head-sec h1 {
    padding: 30px;
  }
  .sec3-1 {
    display: inline-flex;
    margin-left: 0;
    justify-content: center;
    width: 100%;
  }
  .sec3-img1 img {
    width: 130px;
    margin-left: 0px;
    bottom: -53px;
    position: relative;
    left: 0px;
  }
  .sec3-img2 img {
    width: 150px;
    bottom: -4px;
  }
  .sec3-img3 img {
    width: 245px;
    position: relative;
    top: -29px;
    left: -15px;
  }
  .part-1 {
    padding-bottom: 190px;
  }
  .sec-head2 {
    padding: 50px 0 20px;
  }
  .right-sec-img img {
    width: 300px;
  }
  .sec3-head {
    padding-bottom: 0px;
  }
  .last-head-sec h1 span {
    font-weight: 900;
    color: #3fd0d2;
  }
  .unique-point {
    margin-left: 0px;
  }
  .unique-point-two {
    margin-right: 0px;
    padding: 140px 0px 0px;
  }
  .unique-point h2 {
    left: 0px;
    top: 50px;
  }
  .sec4 h1 {
    padding-top: 50px;
  }
  .sec3-head ul li {
    padding: 0px 10px;
  }
}
@media only screen and (max-width: 576px) {
  .sec2-content {
    display: block;
    padding: 0px 0px;
  }
  .part-1 .fields {
    display: block;
  }
  .sec3-head .fields li::after {
    content: none;
  }
  .sec3-head ul li {
    padding: 15px 10px;
    flex: 0 0 100%;
    min-width: 100%;
    justify-content: center;
    display: inline-flex;
  }
  .tab-content .tab-head .caption-sec p {
    width: 100% !important;
  }
}
@media only screen and (max-width: 400px) {
  .part-1 {
    padding-bottom: 120px;
  }
  .sec3-img1 img {
    width: 110px;
    margin-left: 0px;
  }
  .sec3-img2 img {
    width: 130px;
    margin-top: -46px;
  }
  .sec3-img3 img {
    width: 218px;
    position: relative;
    top: -12px;
  }
}
@media only screen and (max-width: 1280px) {
  .content-details p {
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
  }
  .port-btn {
    font-size: 15px;
    line-height: 22px;
    padding: 8px;
  }
}
@media only screen and (max-width: 1210px) {
  .port-card {
    min-height: 342px;
  }
}
@media only screen and (max-width: 1024px) {
  .portfolio .section-2 .custom-container .tab-wrapper .MuiBox-root,
  .portfolio .section-2 .custom-container .tab-wrapper .MuiBox-root .inner-content {
    min-height: 350px;
  }
  .portfolio .section-2 .custom-container .tab-wrapper .MuiBox-root .inner-content .inner-detail {
    width: 50%;
  }
}
@media only screen and (max-width: 991px) {
  .portfolio .section-2 .custom-container .tab-wrapper .MuiBox-root .inner-content .inner-detail {
    width: 80%;
    bottom: 0;
    top: 0;
    margin: auto;
    height: 60%;
  }
  .portfolio .section-5 .custom-container::after {
    width: 250px;
    background-size: contain;
  }
}
@media only screen and (max-width: 480px) {
  .portfolio .section-2 .custom-container .tab-wrapper .MuiBox-root .inner-content {
    padding: 10px;
  }
  .portfolio .section-2 .custom-container .tab-wrapper .MuiBox-root .inner-content .inner-detail {
    width: 100%;
    bottom: 0;
    top: 0;
    margin: auto;
    height: 100%;
    left: 0;
    right: 0;
  }
  .portfolio .section-2 .custom-container .tab-wrapper .MuiBox-root .inner-content .inner-detail b {
    font-size: 14px;
  }
  .portfolio .section-5 .custom-container::after {
    top: 75%;
    width: 50%;
    margin: 0px auto;
    left: 0;
    bottom: 0;
  }
  .portfolio .section-5 .custom-container .connect-with-custom-section {
    padding-bottom: 80px;
  }
  .portfolio .section-5 .custom-container a.connect-btn {
    width: auto !important;
    padding: 0px 15px !important;
    font-size: 16px;
  }
  .portfolio .section-5 .custom-container .main-head-div {
    margin-bottom: 30px;
  }
}
