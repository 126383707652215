.technology .work-class ul li .head-img {
  min-width: 180px;
  max-width: 180px;
  min-height: 180px;
  max-height: 180px;
}
.technology .section-1 a.connect-btn {
  font-size: 24px;
  color: #1E4B5E;
  font-family: "Object Sans heavy";
  line-height: 24px;
}
.technology .head-img.ylow-bx:before {
  position: absolute;
  width: 180px;
  height: 180px;
}
.technology .section-2 .work-with-sub-head {
  padding-left: 0px;
}
.technology .section-2 .work-class {
  padding: 0px;
}
.technology .section-2 .main-para-div {
  max-width: 405px;
}
.technology .card-imga {
  width: 64px;
  height: auto;
}
.tech-crack-resp-img {
  display: none;
}
.technology #f1_card {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  /** fixes non transitive 3d from parent and child **/
  transition: all 1s linear;
  cursor: inherit;
}
.technology .align-crct {
  transform: rotateY(180deg);
}
.technology .face {
  box-shadow: 3px 3px 0px #007CC1;
  background: #fff;
  border-radius: 5px;
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transition: all 1.2s ease;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
}
.technology .face.front {
  transform: rotateY(0deg);
}
.technology .face.back {
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  display: block;
  transform: rotateY(180deg);
  box-sizing: border-box;
  padding: 10px;
  text-align: center;
  box-shadow: 3px 3px 0px #FFD028, 2px 2px 1px rgba(0, 0, 0, 0.2);
  background: #fff;
  border-radius: 5px;
  min-height: 303px;
  transition: all 1.2s ease;
}
.technology .shadow {
  box-shadow: none!important;
}
.technology .shadow:hover .face {
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2), 5px 5px 0px #FFD028;
}
@media only screen and (max-width: 1455px) {
  .technology .work-class ul li .head-img {
    min-width: 160px;
    max-width: 160px;
    min-height: 160px;
    max-height: 160px;
  }
  .technology .head-img.ylow-bx:before {
    position: absolute;
    width: 160px;
    height: 160px;
  }
  .technology .head-img.ylow-bx svg {
    width: 115px;
    height: 115px;
  }
}
@media only screen and (max-width: 1280px) {
  .technology .work-class ul li .head-img {
    min-width: 140px;
    max-width: 140px;
    min-height: 140px;
    max-height: 140px;
  }
  .technology .head-img.ylow-bx:before {
    position: absolute;
    width: 140px;
    height: 140px;
  }
  .technology .head-img.ylow-bx svg {
    width: 105px;
    height: 105px;
  }
  .back .inner .hover-card ul li img {
    min-width: 25px!important;
    width: 25px!important;
  }
  .back .inner .hover-card .hover-text {
    font-size: 12px;
  }
  .tech-card .hover-card {
    padding: 30px 15px!important;
  }
  .more-tech .hover-card {
    padding: 30px 15px!important;
  }
  .back .inner .hover-card ul li img.small-img {
    width: 45px!important;
  }
  .technology.mob-view .section-1 .custom-section {
    padding-top: 0;
  }
}
@media only screen and (max-width: 1067px) {
  .technology .work-class ul li .head-img {
    min-width: 120px!important;
    max-width: 120px!important;
    min-height: 120px!important;
    max-height: 120px!important;
  }
  .technology .head-img.ylow-bx:before {
    position: absolute;
    width: 120px!important;
    height: 120px!important;
  }
  .technology .head-img.ylow-bx svg {
    width: 95px;
    height: 95px;
  }
  .head-img.ylow-bx:before {
    position: absolute;
    width: 120px!important;
    height: 120px!important;
  }
  .more-tech .hover-card {
    padding: 30px 10px!important;
  }
  .back .inner .hover-card .hover-text {
    font-size: 10px;
  }
  .tech-card .hover-card {
    padding: 30px 10px!important;
  }
  .technology.mob-view .section-1 {
    height: 110vh;
  }
}
@media only screen and (max-width: 980px) {
  .resp-apprch-col {
    flex: inherit!important;
    max-width: 100%!important;
  }
  .technology.mob-view .section-1 {
    height: 100vh;
  }
}
@media only screen and (max-width: 768px) {
  .back .inner .hover-card ul li img.small-img {
    width: 45px!important;
  }
  .back .inner .hover-card .hover-text {
    font-size: 12px;
  }
  .tech-card .hover-card {
    padding: 30px 15px!important;
  }
  .more-tech .hover-card ul li {
    width: auto !important;
    padding: 0px 10px 25px 0px;
  }
  .more-tech .hover-card ul li {
    min-width: 110px;
  }
}
@media only screen and (max-width: 576px) {
  .mob-resp-ult {
    text-align: left!important;
  }
  .tech-crack-resp-img {
    display: block;
    width: 245px;
    margin: 0 auto;
  }
  .tech-crack-resp-img img {
    width: 100%;
  }
  .tech-crack-main-img {
    display: none;
  }
  .more-tech .hover-card ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .technology .section-1 .main-head-1 .primary-span {
    font-size: 42px;
    line-height: 45px;
    margin: 10px 0px;
  }
}
@media only screen and (max-width: 425px) {
  .Home .technology .section-1 {
    background-size: cover;
    background-position: center;
  }
  .technology .section-2 .work-with-sub-head .app-p {
    font-size: 20px;
    line-height: 25px;
  }
  .technology .section-2 .work-class ul {
    width: 100%;
  }
  .technology .section-2 .work-class ul li {
    list-style-type: none;
    width: 100%;
  }
  .technology .section-2 .work-class ul li .head-img {
    min-width: 150px;
    max-width: 150px;
    min-height: 150px;
    max-height: 150px;
  }
  .technology .section-2 .work-class ul li .head-img.ylow-bx:before {
    position: absolute;
    width: 150px;
    height: 150px;
  }
  .technology .section-2 .work-class ul li .head-cap {
    margin-bottom: 30px;
  }
  .technology .section-2 .work-class ul li:last-child .head-cap {
    margin-bottom: 0px;
  }
  .technology .section-2 .work-class ul {
    margin-bottom: 0px;
  }
  .technology .main-button-div {
    margin-bottom: 0px;
  }
  .technology .section-2 .head-cap p {
    font-size: 30px;
  }
  .technology .section-3 .tech-crack {
    margin: 30px 0px;
  }
  .technology .whole-card {
    min-height: 260px;
    min-width: 260px;
    position: relative;
  }
  .card.tech-card .whole-card {
    padding: 50px 30px 50px 10px !important;
  }
  .more-tech .hover-card ul li {
    padding: 0px 10px 25px 0px;
  }
}
