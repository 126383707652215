/* Developed by Praveenkumar.G */
@font-face {
  font-family: 'avenir_lt_std55_roman';
  src: url('/assets/fonts/avenir/avenirltstd-roman-webfont.woff2') format('woff2'),
       url('/assets/fonts/avenir/avenirltstd-roman-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'avenir_lt_std95_black';
  src: url('/assets/fonts/avenir/avenirltstd-black-webfont.woff2') format('woff2'),
       url('/assets/fonts/avenir/avenirltstd-black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
html {
  scroll-behavior: smooth;
}
body{
  font-family: 'Object Sans';
  font-style: normal;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
  text-rendering: optimizeLegibility; 
  overflow-x: hidden !important;
}
h1{
  margin: 0 !important;
}
.Header.scrollHeader .custom-inx-navbar.kyc-container{
  background: #ffffff;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
}
.err-msg{
  color: #ff4242;
  font-size: 16px;
  padding-top: 5px;
  line-height: 24px;
  font-family: 'Object Sans';
  display: block;
}
.short-loader {
  width: 12px;
  height: 12px;
  border: .2em solid transparent;
  border-top-color: currentcolor;
  border-bottom-color: currentcolor;
  border-radius: 50%;
  position: relative;
  animation: 1s loader-30 linear infinite;
  display: inline-block;
 
}
.short-loader:before,
.short-loader:after {
content: '';
display: block;
width: 0;
height: 0;
position: absolute;
border: .1em solid transparent;
border-bottom-color: currentcolor;
}
.short-loader:before {
  transform: rotate(135deg);
  right: -.2em;
  top: -.04em;
}
.short-loader:after {
  transform: rotate(-45deg);
  left: -.2em;
  bottom: -.04em;
}
::-webkit-scrollbar {
  width: 10px;
  border-radius: 30px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
    border-radius: 30px;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

@keyframes loader-30 {
0% {
  transform: rotate(0deg);
}
100% {
  transform: rotate(360deg);
}
}
button:focus{
  outline: none;
  box-shadow: none;
}
h1,h2,h3,h4,h5,h6{
  font-weight: normal;  
  line-height: initial;
}
.Toast{
  border: 1px solid green;
  color: black;
}
div#pills-home {
  margin-bottom: 50px;
}
/*major common font colors*/

/*primary head color: #1E4B5E;*/
/*secondary head color: #24A989;*/
/*primary para color: #1E4B5E;*/


/*common-background-color class*/

.white-bg
{
background: linear-gradient(90deg, #FFFFFF 0%, #F9F9F9 100%), #F0F0F0;
}
.grey-bg
{
background: #232323
;
}
.blue-bg
{
background: linear-gradient(90deg, #F7FFFD 0%, #FDFDFD 100%), #F9F9F9;
}
.blue-bg_2{
  background: linear-gradient(90deg, #EEFEFA 0%, #FDFDFD 100%);

}
/*container css*/

.custom-container{
    max-width: 75%;
    min-width: 75%;
    width: 75%;
    margin: auto;
    display: flex;
    align-items: center;
    z-index: 1;
}

/*heading common style starts*/

/* selct css start */
.contact-modal .makeStyles-selectEmpty-2{
  margin-top: 0;
  display: block;
  min-height: 38px;
  height: 38px;
 
}

.contact-modal .MuiInput-underline:before{
  border: 1px solid rgb(206 212 218);
  border-radius: 4px;
  min-height: 38px;
}
.contact-modal .MuiSelect-select.MuiSelect-select{
  padding-right: 24px;
  padding: 10px;
  font-size: 12px;
  background: transparent;
  color: #495057;
  font-family: 'Object Sans';
}
.contact-modal em{
      font-style: normal;
      color: #495057;
      font-weight: 400;
      font-size: 12px;
      font-family: Object Sans;
  }
.contact-modal .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid rgb(206 212 218);
}
.contact-modal .MuiInput-underline:after{
  border-bottom: 0;
}
.MuiMenu-list li, .MuiMenu-list li em {
  font-size: 13px;
  font-style: normal;
  font-family: Object Sans;

}
/* selct css end */
/*popup  css start*/
.contact-modal.theme-change .modal-title.h4 {
  font-size: 42px;
  margin: 0px 0 20px 0;
}
.contact-modal.theme-change .contact-submit.btn.disabled,.contact-modal.theme-change .contact-submit.btn:disabled {
  color: #7e97a1!important;
  background: #c7d0d3!important;
}
.contact-modal.theme-change .modal-content {
  padding: 70px 60px;
}
.contact-modal.theme-change .modal-header .modal-side-close,
.contact-modal.theme-change  button.close{
  top: -50px;
}
.contact-modal.theme-change .contact-submit {
  min-width: 295px;
  height: 80px;
  background: #FFCC4A;
  margin: 20px 0 0 0;
}

.contact-modal.theme-change .form-element.sp-form.pd-tb.ripple-effect.check-content {
  margin-top: 35px;
}
.contact-modal .check-content label.cbx.theme-change {
  display: flex;
  align-items: flex-start;
  padding-right: 0;
}

.contact-modal .check-content label.cbx.theme-change span.check-custom-text {
  margin-top: -3px;
  padding-left: 5px;
}
/*popup css end*/
.main-head-div{
margin-bottom: 50px;
}
.main-head-1,.main-head{
  margin-bottom: 0px;
}
.main-head-1 .primary-span{
font-size: 60px;
line-height: 75px;
display: block;
color: #24A989;
font-family: 'Heldane Display';
margin: 0px 0px;
}
.main-head-1 .secondary-span{
font-size: 36px;
line-height: 45px;
display: block;
color: #1E4B5E;
font-family: 'Object Sans Heavy';
}

.main-head-1.main-head-2 .primary-span{
  font-size: 48px;
  line-height: 64px;
}


/*heading common style ends*/

/*paragraph common style starts*/

.main-para-div {
  margin-bottom: 30px;
}
.main-para-div p{
font-weight: 500;
font-size: 16px;
margin-bottom: 15px;
color: #1E4B5E;
line-height: 22px;
}
.short-div{
  width: 600px;
}

/*paragraph common style ends*/


.quick-contact a {
  list-style-type: none;
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #fff;
  background: #fff;
  transition: all ease-in 0.3s;
  position: fixed;
  right: 30px;
  bottom: 0;
  top: 0;
  margin: auto;
  height: 58px;
  z-index: 9;
  width: 58px;
  border-radius: 5px;
  justify-content: center;
  border: 0;
  box-shadow: 3px 12px 19px 0px #00000026;
  overflow: hidden;
}
.quick-contact a:hover {
  width: 200px;
  background-color: #017CC0;
  border-color: #007cc1;
  align-items: center;
  justify-content: flex-start;
  color: #007cc1;
  box-shadow: none;
}
.quick-contact a:hover span img {
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.quick-contact a:hover:before {
  content: 'Connect with Us';
  color: #fff;
  font-weight: 600;
  word-break: keep-all;
  transition: 0.4s;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 22px;
}
.quick-contact a span img.hover-show,.quick-contact a:hover span img {
  display: none;
}

.quick-contact a:hover span img.hover-show {
  display: block;
  margin-right: 6px;
  width: 30px;
}


.quick-contact a span img{
  width: 35px;
}
.quick-contact a strong {
  font-size: 0;
  transition: in ease 0.6s;
}
.zsiq_theme1 .siqico-chat:before {
  color: #1e4b5e!important;
  font-size: 22px!important;
}

.zsiq_theme1 .zsiq_user.siqico-chat {
  background-color: #fff!important;
}
.zsiq_theme1 .zsiq_flt_rel {
  width: 58px!important;
  height: 58px!important;
  background-color: #fff!important;
  border-radius: 5px!important;
  box-shadow: 3px 12px 19px 0px #00000026;
}

.zsiq_theme1.zsiq_floatmain {
  width: 58px!important;
  /* top: 0!important; */
  bottom: 10px!important;
  margin: auto;
  right: 10px!important;
}

.zsiq_theme1 .zsiq_user.siqico-chat:before{
  background-image: url("/assets/img/chat.png");
  content: '';
  background-size: 42px;
  background-position: center;
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}
.zsiq_theme1.zsiq_floatmain:hover .zsiq_user.siqico-chat:before{
  background-image: url("/assets/img/chat.svg");
    background-position: 1px 12px;

}
.zsiq_theme1.zsiq_floatmain:hover .zsiq_user.siqico-chat{
  width: 58px!important;
    height: 50px!important;
}
.zsiq_theme1 div.zsiq_cnt {
  display: none!important;
}
.zsiq_theme1.zsiq_floatmain:hover .zsiq_flt_rel:before {
  content: 'Chat with Us';
  color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 22px;
  font-weight: 800;
}

.zsiq_theme1.zsiq_floatmain:hover,.zsiq_theme1.zsiq_floatmain:hover .zsiq_flt_rel {
  width: 190px!important;
  background: #017CC0;
  border-radius: 5px;
}
.zsiq_theme1:hover .zsiq_flt_rel{
  box-shadow: none!important;
  background-color: #017CC0!important;
}
.zsiq_theme1.zsiq_floatmain:hover {
    display: flex!important;
    align-items: center;
    justify-content: center;
}

.zsiq_theme1.zsiq_floatmain:hover 
 .zsiq_user.siqico-chat {
    width: 40px;
    height: 30px;
    position: absolute;
    right: 0;
    background-color: #017CC0!important;
}
/*navbar css starts*/

.custom-inx-navbar{
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  padding: 15px 30px;
  top: 0px;
  z-index: 1;
}
.custom-inx-navbar.home-header{
  top: 52px;
}
.custom-inx-logo svg{
  width: 124px;
  height: 60px;
}
.ham-menu svg{
  width: 40px;
  height: 30px;
}    
/*navbar css ends*/


/*first section bg css starts*/

.section-1{
  background-image: url(/assets/img/hero-images/tech-new-hero-bg.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: center;
}

.section-1 .custom-section{
  padding-top: 50px;
}

/*first section bg css ends*/



/*Second section css starts*/

.section-1,.section-2,.section-3,.section-4,.section-5{
  padding: 100px 0px;
}
.work-with-sub-head{
font-size: 24px;
color: #1E4B5E;
margin-bottom: 30px;
padding-left: 30px;
font-family: 'Object Sans Heavy';
}
.work-class{
  padding:0px 5px;
}
.head-cap
{
  display: flex;
    align-items: center;
    justify-content: center;
}
    .head-cap p{
font-size: 24px;
color: #1E4B5E;
margin: 15px 0px;
line-height: 32px;
text-align: center;
font-family: 'Heldane Display';
text-align: center;
}
.Enterprise .head-cap p
{
  min-height: 64px;
}
.sec-2-row{
  align-items:center;
}

/*Second section css ends*/


/*third csection css starts*/
.custom-container.connect-container{
min-height: 446px;
}
.main-para-div .connect-btn{
min-width: 295px;
height: 80px;
background: #1E4B5E;
color: #FFCC4A;
border-radius: 3px;
outline: transparent;
border:transparent;
padding: 25px;
font-weight: 800;
line-height: 24px;
font-size: 24px;
transition: all 0.2s cubic-bezier(0.18, 0.89, 0.32, 1.28);
font-family: "Object sans Heavy";
}
.main-para-div .connect-btn:hover{
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
}
.main-para-div .connect-btn:focus{
box-shadow: none!important;
background-color: #FFCC4A;
border:transparent;
}
.main-para-div .connect-btn:active{
box-shadow: none!important;
background-color: #FFCC4A!important;
border:transparent!important;
}

.main-para-div .connect-btn span{
font-family: 'Object Sans Heavy';
font-size: 24px;
line-height: 25px;
text-align: center;
color: #1E4B5E;
}
.app-p{
font-family: 'Object Sans Heavy';
font-size: 24px;
text-align: center;
color: #1E4B5E;
}
/*third section css ends*/


/* technology styles */

.work-class ul{
  display: flex;
  align-items: center;
  padding-left: 0px;
  justify-content: space-between;
}
.work-class ul li{
  list-style-type: none;
  width: 33.33%;

}
.work-class ul li .head-img{
  padding: 5px;
  border-radius: 100%;
  background: #FFFFFF;
  /* box-shadow: 3px 3px 0px #FFD028, 2px 2px 5px rgba(30, 75, 94, 0.3); */
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 145px;
  max-width: 145px;
  min-height: 145px;
  max-height: 145px;
  margin:0 auto;
}

.tech-crack{
  width: 100%;
  display: block;
  margin: 50px 0px;
}

.tech-crack img{
  width: 100%;
  /* min-width: 1020px; */
}
.tech-crack-main-img svg{
  width: 100%;
  height: 100%;
}
.more-text a{
  color: #007CC1;
  font-size: 18px;
  text-decoration: underline;
  font-family: 'Object Sans Heavy';
  font-style: normal;
  cursor: pointer;
  transition: all 0.5s ease;
  padding: 0;
}
.more-text a:hover{
  text-decoration: underline;
  color: #4283a7;
}
.card-sect {
  width: 100%;
}
.card-bod {
  background: #FFFFFF;
  box-shadow: 3px 3px 0px #FFD028, 2px 2px 1px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: .5s ease-in;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.whole-card{
  display: flex;
  align-items: center;
  justify-content:space-between;
  padding: 10px 20px;
  /* padding: 50px 15px 50px 50px; */
  /* min-height: 295px;
  min-width: 295px; */
}

.card-sec-2 .arr-btn svg{
  fill:#007CC1;
}

.button:hover .whole-card .arr-btn svg{
  fill:#2692AA;
}

.card-sec-2 button{
  background: transparent;
  border: 1px solid transparent;
}

.card-sec-2 button:focus{
  outline: none;
}

.card-cap p{
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #1E4B5E;
  padding: 15px 0px;
  margin-bottom: 0px;
}

.custom-section{
  width: 100%;
}

.pad-col{
  padding-right: 50px;
  padding-bottom: 50px;
}

.hover-card{
  /* background: #EBEEEF; */
  background: #FFFAE8;
  padding: 30px 25px;
  max-height: 215px;
  min-height: 215px;
  display: flex;
  align-items: center;
}

.hover-card ul{
  padding:0px;
  margin: 0px;
}
.hover-card ul li{ 
  list-style-type: none;
  padding: 0px 0px 15px;
}
.hover-card ul li:last-child{
  padding: 0px 0px 0px;
}
.hover-text{
  font-size: 14px;
  line-height: 20px;
  color: #1E4B5E;
  padding-left: 25px;
  text-align: left;
}
.hover-div {
  display: flex;
  align-items: center;
}

/* footer-styles */

.explore-hold{
    margin-bottom:25px;
}
.explore-hold p{
    font-family: 'Object Sans Heavy';
    font-size: 24px;
    line-height: 30px;
    color: #1E4B5E;
    margin-bottom: 0px;
}

/* end footer-styles */

.d-hide
{
  display: none;
}

/* startup-page */
.company-info .highlight-text h4,.company-info .highlight-text h3
{
    font-family: 'Heldane Display';
    font-style: normal;
    font-weight: bold;
    font-size: 42px;
    line-height: 52px;
    color: #1E4B5E;
    position: relative;
    z-index: 1;
}
.company-info .highlight-text h4 span,.company-info .highlight-text h3 span ,
.otc-solution .crypto-banner-otc.white-bg .professionals-hold .professionals-ul li .professionals-info strong,
.algorith-bot .crypto-banner-otc.white-bg .professionals-hold .professionals-ul li .professionals-info strong

{
  /* background-image: url("/assets/img/bar-one.png"); */
  background-position: 0 34px;
  background-size: 40px 10px;
  background-repeat: repeat-x;
}

.connect-us button
{
    min-width: 190px;
    width: auto;
    padding: 10px 20px;
    background: #007CC1;
    border-radius: 3px;
    height: 60px;
    transition: all 0.2s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
.connect-us button:hover{
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
}
.connect-us button:focus{
    outline: none;
    box-shadow: none;
}
.connect-us span{
    font-family: 'Object Sans Heavy';
    font-size: 18px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
}

.company-info .main-para-div p {
  line-height: 22px;
  width: 100%;
}
.design-home .company-info .main-para-div p
{
  max-width: 400px;
  /* max-width: 330px; */
}
.design-home .design-cont .short-div
{
  width: 615px;
}
.design-home .c-cards-mg
{
  margin-bottom: 35px;
}
.comp-info-right-img svg{
  width: 100%;
}


/* VIEW BTN LINK STYLE STARTS */

.home-btn .view-btn a
{
  color: #007CC1!important;
}
.home-btn .view-btn a:hover
{
  text-decoration:none;
}



/* VIEW BTN LINK STYLE ENDS */


/* modal css starts */




/* resource webinar modal css starts */


.webinar-modal .webinar-modal-header .modal-title.h4
{
  font-family: Object Sans Heavy;
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  color: #1E4B5E;
  margin-bottom: 0px;
}

.webinar-modal .modal-header {
  position: relative;
}
.webinar-modal .close {
  color: transparent;
  opacity: 0!important;
}
.webinar-modal span.modal-side-close {
  position: absolute;
  right: 9px;
  left: auto;
  width: 24px;
  height: 24px;
  z-index: 0;
  top: 0;
  display: flex;
  bottom: 0;
  align-items: center;
  justify-content: center;
  margin: auto;
}


/* resource webinar modal css ends */
.ppr-dnld-custom-sec  .detialed-news-sub-info.media-info h4
{
  font-family: Heldane Display;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: #1E4B5E;
}
.ppr-dnld-custom-sec  .detialed-news-sub-info.media-info .media-contact-info-hold span
{
  padding-right: 10px;
}
/* contact us modal css starts */

.contact-modal .modal-title.h4
{
  font-size: 26px;
  line-height: 30px;
  font-family: 'Heldane Display';
  color: #24A989;
}
.modal-side-close svg
{
  width: 24px;
  height: 24px;
}
.modal-side-close svg rect
{
  fill:#1E4B5E;
}
.contact-modal .modal-header
{
  align-items:center;
  position: relative;
}
.contact-modal .contact-form-label
{
  font-size: 16px;
  color: #1E4B5E;
  font-family: 'Object Sans Heavy';
  margin-bottom: 10px;
}
.contact-modal .form-control:focus
{
  outline:none;
  border-color: #1E4B5E;
  box-shadow: none;
}
.contact-modal .form-group
{
  margin-bottom: 15px;
}
.contact-modal .c-form-select {
  display: block;
  width: 100%;
  height: 45px;
  line-height: 45px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.contact-modal .c-form-select .selected-value {
  line-height: 42px;
  text-indent: 15px;
  font-size: 13px;
}
.contact-modal .c-form-select .cfsel-drop-span {
  top: 18px;
}
.contact-modal .select-option {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.contact-modal .select-option .d-block {
  padding: 6px 15px;
  color: #1E4B5E;
  font-size: 13px;
  cursor: pointer;
  font-family: "Object Sans";
  border: 1px solid transparent;
}
.contact-modal .select-option .d-block:hover {
  background: rgba(36, 169, 137, 0.1);
  border: 1px solid rgba(36, 169, 137, 0.4);
  font-weight: 800;
}
.contact-modal .form-group .form-control::placeholder
{
  font-size: 13px!important;
  color: #1e4b5e85;
}

.contact-modal .form-group input
{
  min-height: 45px;
  font-size: 13px !important;
}
.cfsel-drop-span
{
position: absolute;
right: 13px;
left: auto;
display: flex;
top: auto;
bottom: 12px;
}
.contact-modal .form-group input, .form-group select
{
  font-size: 12px!important;
}
.contact-modal .form-check .form-check-label
{
  font-size: 14px;
  color: #1E4B5E;
  font-family: 'Object Sans';
}
.contact-modal .text-muted.form-text
{
  font-size: 12px;
  margin-top: 5px!important;
  padding-left: 18px;
  color: #1E4B5E!important;
}
.contact-modal .close
{
  color:transparent;
  opacity: 0!important;
  padding-right: 0px!important;
  margin: 0px!important;
  padding-top: 0px;
    padding-bottom: 0px;
}

/* new style */

/* for contact form  */


.contact-modal button.close {
  position: absolute;
  right: -40px;
  top: -15px;
  bottom: auto;
  left: auto;
}
.modal-side-close svg path {
  fill: #1E4B5E;
}
.contact-modal .modal-header .modal-side-close
{
  position: absolute;
    right: -40px;
    top: -15px;
    bottom: auto;
    left: auto;
}

.custom-rct-phn .react-tel-input input
{
  width:100%;
}
.custom-rct-phn .react-tel-input .flag-dropdown
{
  background-color: transparent;
  border: none;
  border-right: 1px solid #cacaca;
}
.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
  background-color: inherit!important;
}
.react-tel-input .flag-dropdown.open .selected-flag {
  background: #fff;
  border-radius: 3px 0 0 3px!important;
}

/* .react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus
{
  background: inherit;
}
.custom-rct-phn .react-tel-input .selected-flag:hover {
  background: inherit;
} */

.selected-flag.open
{
  border: 1px solid #cacaca;
  border-right: none;
  border-radius: 3px 0 0 3px;
}
.react-tel-input .flag-dropdown.open .selected-flag.open {
  border-radius: 5px 0 0 5px!important;
}
/* for download modal */

.white-paper-modal.modal button.close {
  position: absolute!important;
  right: -50px!important;
  top: -50px!important;
  bottom: auto!important;
  left: auto!important;
}
.white-paper-modal.modal  .modal-side-close
{
  position: absolute;
    right: -50px;
    top: -50px;
    bottom: auto;
    left: auto;
}

.ph-num::-webkit-inner-spin-button 
{
  -webkit-appearance: none!important;
}
.ph-num[type=number] {
  -moz-appearance: textfield;
}

.custom-country-code-common
{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.country-code-select
{
width: 20%;
margin-right: 15px;
}
.country-code-select .country-code-select
{
  min-height: 38px;
  padding-right: 25px;
  margin-right: 0px!important;
  width: 100%;
}
.ph-common-hold
{
  width: 80%;
}

/* new style */

.contact-modal .close:hover
{
  color:transparent;
}
.contact-modal .modal-side-close
{
  position: absolute;
  right: 0px;
  left: auto;
  width: 24px;
  height: 24px;
  z-index: 0;
  top: -11px;
  display: flex;
  bottom: 0;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.contact-modal .contact-form-group
{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 0px;
}
.contact-modal .contact-submit 
{
  background: #FFCC4A!important;
  color: #1E4B5E!important;
  border:transparent;
  font-family: 'object sans heavy';
  padding:8px 25px
}
.contact-modal .contact-submit:focus 
{
  box-shadow: none!important;
  outline:none!important;
}
.c-form-select
{
  -moz-appearance: none; 
  /* for Safari, Chrome, Opera */ 
  -webkit-appearance: none; 
}
.c-form-select-grp
{
  position: relative;
}
.c-form-select-grp::after {
  vertical-align: 4px;
  /* content: ""; */
  border: solid #000000;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  right: 14px;
  left: auto;
  top: auto;
  position: absolute;
  bottom: 14px;

}


/* check box */

.contact-modal .inp-cbx:checked + .cbx span:first-child {
  background: #1E4B5E;
  border-color: #1E4B5E;
  animation: wave 0.4s ease;
}
.contact-modal .cbx span:first-child {
  width: 16px;
  height: 16px;
  top: 0px;
}
.contact-modal .cbx span:first-child {
  position: relative;
  min-width: 15px;
  height: 15px;
  max-width: 15px;
  border-radius: 2px;
  transform: scale(1);
  vertical-align: middle;
  border: 1px solid #9098A9;
  transition: all 0.2s ease;
}
.contact-modal .cbx span {
  display: inline-block;
}
.contact-modal .inp-cbx
{
  display: none;
}
.contact-modal .inp-cbx:checked + .cbx span:first-child:before {
  transform: scale(1.5);
  opacity: 0;
  transition: all 0.6s ease;
  border-radius: 50%;
}

.contact-modal .form-element.ripple-effect span:before {
  content: "";
  width: 32px;
  height: 32px;
  left: -8px;
  top: -8px;
  margin: auto;
  position: absolute;
  background: #1E4B5E;
  display: block;
  transform: scale(0);
  opacity: 1;
  border-radius: 50%;
}
.contact-modal .cbx span:first-child:before {
  content: "";
  width: 32px;
  height: 32px;
  left: -8px;
  top: -8px;
  margin: auto;
  position: absolute;
  background: #1E4B5E;
  display: block;
  transform: scale(0);
  opacity: 1;
  border-radius: 50%;
}
.contact-modal .inp-cbx:checked + .cbx span:first-child svg {
  stroke-dashoffset: 0;
}

.contact-modal .cbx span:first-child svg {
  left: 1px;
}
.contact-modal .cbx span:first-child svg {
  position: absolute;
  top: 1px;
  left: 1px;
  fill: none;
  stroke: #FFFFFF;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
  transform: rotate(5deg);
  width: 10px;
  height: 10px;
}
.contact-modal .form-element.ripple-effect span:before {
  content: "";
  width: 32px;
  height: 32px;
  left: -8px;
  top: -8px;
  margin: auto;
  position: absolute;
  background: #1E4B5E;
  display: block;
  transform: scale(0);
  opacity: 1;
  border-radius: 50%;
}
.contact-modal .even-btn .check-content label {
  align-items: inherit;
}
.contact-modal  .check-content label {
  padding-right: 15px;
  display: inherit;
  align-items: center;
}
.contact-modal .check-custom-text
{
  font-size: 12px;
  color: #1E4B5E;
  font-family: 'Object Sans';
}
.contact-modal .contact-form-txt-area
{
  max-height: 60px;
  min-height: 60px;
  font-size: 12px;
}
.contact-modal .modal-dialog {
  max-width: 600px;
  margin: 1.75rem auto;
  transform: scale(0.9) translateY(20px)!important;
  margin: auto!important;
}

.contact-modal .modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 10px 0px 0px 0px;
}

/* contact modal new css */

.contact-modal .modal-header {
  border-bottom: none;
  padding: 0px 0px 10px 0px;
}
.contact-modal .modal-content
{
  padding: 30px 60px;
  border-radius: 8px;
  border: none;
}

/* contact modal new css ends */
/* contact us modal css ends */
/* modal css ends */
/* contact toast css starts */

.toast-body {
  padding: 30px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0,0,0,.1);
}
.toast
{
  max-width: 600px;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  bottom: 0;
  top: 90px;
  z-index: 99999;
  background: transparent !important;
  box-shadow: none !important;
  backdrop-filter: none !important;
  border: none !important;
  margin: auto;

}
.Toast.custom-toast
{
  border:none;
  margin: 0 auto;
}
.toast-check-anim
{
  display: flex;
  justify-content: center;
}
.custom-toast .inner-toast-head {
  color: #24A989;
  font-size: 34px;
  display: block;
  font-family: 'Heldane Display';
  margin: 15px 0px;
  text-align: center;
  
}

.custom-toast .inner-toast-message {
  color: #1E4B5E;
  font-family: 'Object Sans Heavy';
  font-size: 18px;
  text-align: center;
  line-height: 28px;
  margin-bottom: 0px;

}

.custom-toast .checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #24A989;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.custom-toast .checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #24A989;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.custom-toast .checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #fff;
  }
}

.clutch-logo{
  display: block;
  margin: 15px 0px;
}

.clutch-logo img{
  width: 130px;
}
/* contact toast css ends */

/* start animation cards */

.wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}
.card.tech-card {
  height: 295px;
  min-width: 295px;
  perspective: 1500px;
  border:1px solid transparent;
  border-radius: 0px;
  background:transparent;
}


.card .content {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  /* transition: transform 1.2s cubic-bezier(0.75, 0, 0.85, 1); */
  transition: all 1.2s ease;
  border:3px solid transparent;
  box-shadow: 3px 3px 0px #007CC1;
  background:#fff;
  border-radius: 5px;
  min-height: 300px;
}

.card.tech-card:hover .content {
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25), 5px 5px 0px #007CC1;
}

.more {
  display: none;
}

.content.rotate-content {
  transform: rotateY(180deg);
  box-shadow: -3px 3px 0px #007CC1;
}

.card.tech-card:hover .more:checked ~ .content {
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25), -5px 5px 0px #007CC1;
}

.card.tech-card:hover .content.rotate-content {
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25), -5px 5px 0px #007CC1;
}

.front,
.back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 294px;
}
.front .inner,
.back .inner {
  transform: translateZ(80px) scale(0.94);
}

.front {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.back {
  transform: rotateY(180deg);
  padding: 0px 15px;
}

.back .inner .card-sec-1 {
  display: flex;
  align-items: center;
  padding: 0px 7px;
  min-height: 70px;
}
.back .inner .card-sec-1 .card-imga img{
  width: 36px;
  margin-right: 10px;
}
.back .inner .hover-card ul li img{
  min-width: 36px;
  width: 36px;
}

.back .inner .hover-card ul li .custom-tech-img img{
  min-width: 20px;
  width: 20px;
}
.more-tech .hover-card {
  padding: 30px 20px;
}
.more-tech .hover-card ul{
  display: flex;
  flex-wrap: wrap;
}
.more-tech .hover-card ul li{
  width: 50%;
}
.more-tech .hover-text {
  padding-left: 10px;
}
.more-tech .back .inner .hover-card ul li img {
  min-width: 30px;
  width: 30px;
}
.back .inner .hover-card ul li img.django{
  border-radius: 4px;
}
.back .inner .hover-card ul li img.small-img{
  width: 70px;
}
.back .inner .card-cap p{
  padding: 8px 0px;
}
.button {
 display: block;
 margin-bottom: 0px;
 cursor: pointer;
 transition: all 0.5s ease;
}
.technology .card-sec-1 .card-cap p{
  width: 165px;
}
.tech-card-item .card.tech-card .back .card-imga svg{
  width: 36px !important;
  margin-right: 15px;
}
/* end animation cards */


/* new stack design */

ul.new-card-design-ul {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding-left: 0px;
  margin-bottom: 0px;
}
ul.new-card-design-ul li.new-card-design-li {
  display: flex;
  align-items: center;
  flex-direction: column;
  list-style-type: none;
  width: 20%;
  margin-bottom: 25px;
  position: relative;
  max-height: 80px;
  min-height: 80px;
  justify-content: center;
}
ul.new-card-design-ul li.new-card-design-li::after{
  position: absolute;
  width: 1px;
  height: 60px;
  display: flex;
  align-items: center;
  background-color: #dbe0e2;
  content: '';
  vertical-align: middle;
  top: 20%;
  right: 0px;
}
ul.new-card-design-ul li.new-card-design-li.no-border::after{
  content: none; 
}
ul.new-card-design-ul li.new-card-design-li:last-child::after{
  content: none; 
}

li.new-card-design-li .card-image-class {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 60px;
}
.new-card-design-li .txt-class p{
  font-family: "Object Sans Heavy";
  color: #7E97A1 !important;
  font-size: 12px;
  line-height: 30px;
  margin-bottom: 0px;
}
/* .new-card-design-li .card-image-class img{
  width: 100%;
} */

.new-card-design-li .card-image-class img.java-img{
  width:60px;
}



/* end new tech stack design */

/* slick corousel styles */
.slick-track:focus{
  outline: none;
}
.testi-col:focus{
  outline: none;
}
.slick-prev, .slick-next{
  width: 45px;
  height: 45px;
}
.slick-prev:before, .slick-next:before{
  font-size: 40px;
  line-height: 1;
  opacity: 1;
  color: rgba(199, 208, 211, 0.5); 
}
.slick-prev {
  left: -55px;
}
.slick-next {
  right: -55px;
}
.slick-disabled.slick-prev:before
{
  content: '';
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transform: rotate(360deg);
  font-size: inherit;
  background: url(/assets/img/arrow-new-disabled.svg);
  background-repeat: no-repeat;
  background-size: 30%;
  background-position: 14px center;
  background-color: #ffffff;
}
.slick-disabled.slick-next:before
{
  content: '';
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  font-size: inherit;
  background: url(/assets/img/arrow-new-disabled.svg);
  background-repeat: no-repeat;
  background-size: 30%;
  transform: rotate(180deg);
  background-position: 14px center;
  background-color: #ffffff;
}

.slick-prev:before
  {
    content: '';
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    font-size: inherit;
    background: url(/assets/img/arrow-new.svg);
    background-repeat: no-repeat;
    background-size: 45%;
    transform: rotate(180deg);
    background-position: 10px center;
    background-color: #ffffff;
  }
  .slick-next:before
  {
    content: '';
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    font-size: inherit;
    background: url(/assets/img/arrow-new.svg);
    background-repeat: no-repeat;
    background-size: 45%;
    background-position: 10px center;
    background-color: #ffffff;
  }
  /* for button common mob css ends */

/* new-style-for-crypto-services */
.overall-sec-11{
  display: flex;
  align-items: center;
}
.sec-22{
  padding-left: 30px;
}
.row.c-cards-mg .offset-xl-2 {
  margin-left: 11.666667%;
}
.row.c-cards-mg .offset-xl-4 {
  margin-left: 39.333333%;
}
/* end-new-style-for-crypto-services */

/* portfolio crousel */

.port-slide .slick-prev {
  left: -20px;
  z-index: 9;
}
.port-slide .slick-next {
  right: -20px;
  z-index: 9;
}
.port-slide .port-card{
  margin: 0px 5px 20px !important;
  min-height: 310px;
}

.port-slide .slick-prev,.port-slide .slick-next {
  width: 40px;
  height: 40px;
}

/*end portfolio crousel */



/* page not found css starts*/

.no-page-section
{
  padding: 100px 0px;
  padding-bottom: 70px;
}
.no-page-section .custom-section
{
  display: flex;
  align-items: center;
  justify-content: center;
}
.no-page-section .no-page-numb-text
{
  font-size: 60px;
  line-height: 75px;
  display: block;
  color: #1E4B5E;
  font-family: 'Object Sans Heavy';
}
.no-page-section .not-found-head
{
  font-size: 42px;
  line-height: 48px;
  display: block;
  color: #24A989;
  font-family: 'Heldane Display';
}
.no-page-section .not-found-container
{
  text-align: center;
}
.no-page-section .back-home-btn 
{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}
.no-page-section .back-home-btn button
{
  background: #007CC1;
  border: 1px solid #007cc1;
  border-radius: 3px;
  font-family: 'Object Sans Heavy';
  font-size: 18px;
  line-height: 19px;
  text-align: center;
  color: #FFFFFF;
  padding: 15px 20px;
  height: 60px;
  min-width: 190px;
  display: flex;
  align-items: center;
  justify-content: center;
}


/* page not found css ends */



.Resources .section-2 .tab-card .press-row .port-card .card-img {
  width: 100%;
  min-height: 165px;
  max-height: 165px;
  margin-bottom: 10px;
  overflow: hidden;
  }




/* thankyou page css starts */



.thankyou-section.section-1
{
    padding: 50px 0px;
    background-image: none;
    height: 80vh;
    display: flex;
    align-items: center;
}
.thankyou-section.section-1 .custom-toast .checkmark {
    width: 70px;
    height: 70px;

}
.thankyou-section.section-1 .backto-link-hold
{
  text-align: center;
  margin-top: 30px;
}
.thankyou-section.section-1 .backto-link-hold a
{
display: flex;
align-items: center;
justify-content: center;
}
.thankyou-section.section-1 .backto-link-hold a span
{
  cursor: pointer;
}
.thankyou-section.section-1 .custom-toast .inner-toast-head {
  font-size: 42px;
  margin: 16px 0px;
}
.thankyou-section.section-1  .thank-para-head
{
  font-size: 26px;
}
.thankyou-section.section-1  .thank-design-at-inx
{
  color: #007CC1;
  text-decoration: underline!important;
  cursor: pointer;
}
.thankyou-section.section-1  .custom-toast .inner-toast-message {
  line-height: 36px;
}

.thankyou-section.section-1 .thank-back-to-home-text
{
  color: #24A989;
}

.talk-to-us-btn{
  font-size: 24px;
  color: #1E4B5E;
  font-family: "Object Sans heavy";
  min-width: auto;
  border: 2px solid #FFCC4A;
  background: #FFCC4A;
}

@media only screen and (max-width: 576px) and (min-width: 320px) {
.thankyou-section.section-1  .thank-para-head
{
  width: 90%;
  margin: 20px auto;
}
.thankyou-section.section-1 .thank-design-at-inx{
  display: block;
}
}

@media only screen and (max-width: 425px) and (min-width: 320px) 
{
  .thankyou-section.section-1 .thank-para-head {
    font-size: 24px;
    line-height: 32px;
  }
}

/* thank you page css ends */


/* ebook-design */
/* .ebook-sec{
  padding: 20px 0px;
} */

.ebook-form-sec .modal-title h4 {
  font-family: Heldane Display;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;
  color: #24a989;
}
.ebook-sec .main-para-div p {
  font-weight: 500;
  font-size: 15px;
}
.ebook-sec .ebook-form-sec .modal-title h4 {
  font-size: 28px;
  margin-bottom: 15px;
}
.ebook-sec .main-head-div {
  margin-bottom: 25px;
}
.invalid-div{
  color: #ee4444;
  font-size: 13px;
  font-weight: 600;
  margin: 3px 0px 0px;
}
.blog-content.newsletter-content .list .download-btn span {
  font-family: 'Object Sans Heavy';
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  text-decoration-line: underline;
  color: #007CC1;
  transition: all 0.5s ease;
  cursor: pointer;
}
.blog-content.newsletter-content .list .download-btn span:hover {
  text-decoration-line: none;
}


























  
  


/* media queries */

@media only screen and (max-width: 1480px){
  .card.tech-card {
    height: 295px !important;
    min-width: 230px !important;
  }
}

@media only screen and (max-width: 1280px){
  .testi-card {
    min-width: 415px;
    width: 415px;
    min-height: 580px;
    height: 580px;
  }
  .card.tech-card {
    height: 295px !important;
    min-width: 230px !important;
  }
  .card.tech-card .whole-card {
    padding: 50px 15px 50px 15px !important;
  }
  .testi-footer{
    min-height:159px;
  }
  .main-head-1 .primary-span{
    font-size: 42px;
    line-height: 56px;
  }
  .main-head-1 .secondary-span{
    font-size: 28px;
    line-height: 36px;
  }
  .section-1 .main-head-1 .primary-span{
    font-size:48px;
    line-height: 60px;
  }
  .section-1 .main-head-1 .secondary-span{
    font-size: 36px;
    line-height: 46px;
  }
  .main-head-1.main-head-2 .primary-span{
    font-size: 36px;
    line-height: 42px;
  }
  .company-info .highlight-text h4,.company-info .highlight-text h3{
    font-size: 34px;
    line-height: 50px;
  }
  .company-info .highlight-text h4 span,.company-info .highlight-text h3 span  {
    /* background-image: url(/assets/img/bar-one.png); */
    background-position: 0 27px;
    background-size: 40px 10px;
    background-repeat: repeat-x;
  }
  .secondary-footer .follow-us-common .follow-us-head h4{
    font-size: 16px;
  }
}


@media only screen and (max-width:1067px)
{
  .work-class ul li .head-img
  {
    min-width: 120px;
    max-width: 120px;
    min-height: 120px;
    max-height: 120px;
  }
  .head-img.ylow-bx:before 
  {
      position: absolute;
      width:120px;
      height:120px;
  }
  .head-cap p
  {
    font-size:20px;
  }
  .tab-sec .nav-pills {
    justify-content: center;
    margin-top: -20px;
  }
}
@media (min-width: 768px) and  (max-width: 1400px){
  .port-card {
    min-height: auto;
}
.card-cap {
  min-height: 70px;
}
.port-card .card-head h4{
  font-size: 30px;
}
.port-card .card-cap p{
  font-size: 17px;
}

}
@media only screen and (max-width: 1024px) {
  .tech-crack img {
    width: 100%;
    min-width: 100%;
  }
  .card-bod{
    min-width: auto;
  }
  .testi-card{
    width: 100%;
  }
  .testi-footer {
    min-height: 191px;
  }
  .design-home .blue-card-col{
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 30px;
  }
  .design-home .section-1
  {
    height: 100%;
    padding-bottom:85px;
  }
  .main-head-1 .primary-span{
    font-size: 36px;
    line-height: 42px;
  }
  .main-head-1 .secondary-span{
    font-size: 24px;
    line-height: 30px;
  }
  .section-1 .main-head-1 .primary-span{
    font-size:48px;
    line-height: 52px;
  }
  .section-1 .main-head-1 .secondary-span{
    font-size: 30px;
    line-height: 37px;
  }
  .main-head-1.main-head-2 .primary-span{
    font-size: 30px;
    line-height: 38px;
  }
  .main-head-1.main-head-2 .secondary-span{
    font-size: 22px;
    line-height: 28px;
  }
  .service-head h4 ,.service-head h3{
    line-height: 30px;
    font-size: 24px;
  }
}

@media only screen and (max-width: 998px) {
  .mob-view .section-2 .col-md-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .mob-view .section-4 .col-md-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .mob-view .work-class {
    padding-left:0px;
  }
  .mob-view .work-with-sub-head{
    padding-left:0px;
  }
  .pad-col {
    padding-right: 15px;
    padding-bottom: 50px;
  }
  .footer-logo svg {
    width: 180px;
  }
  .secondary-footer .footer-col-head {
    margin-top: 0px;
    margin-left: 0px;
  }
  .secondary-footer .email-signup-common h4 {
    line-height: 30px;
  }
}

@media only screen and (max-width:992px){
  .design-home .section-1.design-cont{
      height: auto;
      padding-bottom: 75px;
  }
  .row.c-cards-mg .offset-xl-2 {
    margin-left: 0px;
  }
  .row.c-cards-mg .offset-xl-4 {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 768px)
{
  div.custom-inx-navbar.kyc-container {
    height: 85px;
  }
  
  .Header .custom-inx-logo,.Header .right-menu {
    top: 0;
  }
  .mobile-progress-hide{ 
    -webkit-animation: 1s ease-in bottomFadeOut;
     animation: 1s ease-in bottomFadeOut;
     opacity: 0 ;
   }
   @keyframes bottomFadeOut {
     0% {
       position: absolute;
       opacity: 1;
       bottom: -5rem;
     }
    
     75% {
       position: absolute;
       bottom: -25%;
       opacity: 0.5;
     }
    
     100% {
       opacity: 0;
     }
     
    }
  .mp-testimonials .slick-prev 
  {
    left: -24px!important;
  }
  .mp-testimonials .slick-next {
    right: -24px!important;
  }
  .mp-testimonials .slick-arrow
  {
    z-index: 99;
  }
  .design-home .design-slide
  {
    min-height: 100%;
  }
  .add-head h4 {
    font-size: 28px !important;  
  }
  .tab-sec .nav-pills {
    justify-content: flex-start;
    margin-top: 0px;
  }
  .tab-sec .nav-pills .nav-link {
    font-family: 'Object Sans Heavy';
    font-size: 16px;
    line-height: 20px;
    margin: 10px 25px 0px 0px;
  }
  .more-tech .hover-card ul li {
    width: auto !important;
    padding: 0px 10px 8px 0px;
  }
  .more-tech .hover-card {
    padding: 30px 10px;
  }
  .technology .section-2 .main-para-div{
    width: 100%;
    max-width: 100%;
  }
  ul.new-card-design-ul li.new-card-design-li {
    width: 33.33%;
    padding: 0px 15px;
  }
  ul.new-card-design-ul li.new-card-design-li.no-border::after{
    position: absolute;
    width: 1px;
    height: 60px;
    display: flex;
    align-items: center;
    background-color: #dbe0e2;
    content: '';
    vertical-align: middle;
    top: 20%;
    right: 0px; 
  }
  ul.new-card-design-ul li.new-card-design-li:nth-child(3n+3)::after{
    content: none; 
  }
  .new-card-design-li .card-image-class img{
    width: 65%;
  }
  .new-card-design-li .txt-class p {
    font-size: 10px;
  }
  .career .section-1{
    padding-bottom: 30px;
  }
  .career-inner .MuiAccordionSummary-content{
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px;
  }
  .career-inner .MuiTypography-root{
    font-size: 15px;
  }
  .career-inner .MuiAccordionSummary-content .secondaryHeading{
    margin-top: 5px;
  }
  .career-inner .MuiAccordionDetails-root{
    padding: 8px 10px;
  }
  .career-inner .MuiAccordion-root{
    padding: 0px 5px;
  }
}
@media only screen and (max-width: 576px)
{
  .progressbar-container{
     position: absolute !important;
     height: 65vh !important;
  }
  .Resources .Header .resouce-logo-text {
    font-size: 12px!important;
    letter-spacing: 5px!important;
  }
  .tab-sec .nav-pills {
    justify-content: space-between;
  }
  .tab-sec .nav-pills .nav-link {
    margin: 10px 0px 0px 0px;
  }
  .Design-content .progressbar 
  {
    padding-left: 15px;
    margin-top: 20px!important;
  }
  .Design-content .progressbar .progressbar-active .circle {
    /* transform: scale(1.25); */
  }
  .Design-content .design-header .custom-container .row.ww-row .main-head-div {
    padding: 20px 10px 0;
  }
  .Design-content .progressbar-active .circle.text-white {
    width: 40px;
    left: -7px;
    height: 40px;
  }
  .Design-content .progressbar-active .circle.text-white{
    font-size: 14px;
  }
  .design-home .design-cont .short-div
  {
    width: 100%;
  }
  .contact-modal .check-content label
  {
    padding-right: 0px;
  }
  .contact-modal .form-element.check-content 
  {
    display: flex;
  }
  .contact-modal .modal-content {
    padding: 30px 20px;
  }
  .white-paper-modal .modal-content {
    padding: 35px 20px!important;
  }

  .slick-prev:before{
    content: '';
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    font-size: inherit;
    background: url(/assets/img/arrow-new.svg);
    background-repeat: no-repeat;
    background-size: 45%;
    transform: rotate(180deg);
    background-position: 8px center;
    background-color: #ffffff;
  }
  .slick-next:before
  {
    content: '';
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    font-size: inherit;
    background: url(/assets/img/arrow-new.svg);
    background-repeat: no-repeat;
    background-size: 45%;
    background-position: 8px center;
    background-color: #ffffff;
  }


}

@media only screen and (max-width: 576px) and (min-width: 320px)
{
.contact-modal .modal-header .modal-side-close 
{
  right: 0px;
}
.contact-modal button.close {
  right: 0px;
  top: -15px;
}
.white-paper-modal.modal .modal-side-close
{
  right: 0px;
  top: -15px;
}
.white-paper-modal.modal button.close {
  right: 0px!important;
  top: -15px!important;
}
}

@media only screen and (max-width: 678px){
  .custom-container {
    max-width: 80%;
    min-width: 80%;
    width: 80%;
    margin: auto;
    display: flex;
    align-items: center;
    z-index: 1;
  }
  .Resources .section-2 .blog-content .upcoming-detail-card .event-detail-card-img
  {
    width: 100%;
    min-width: 100%!important;
  }
  .section-1 {
    background-image: url(/assets/img/tech-img/tech-2.png);
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
  }
  .design-home .mp-slide .slick-slider .slick-dots {
    bottom: 15px;
  }
  .design-home .mp-slide .slick-dots li button:before {
    color: #C7D0D3;
    font-size: 8px;
  }
  .short-div {
    width: auto;
  }
  .work-with-sub-head{
    padding-left: 0px;
  }
  .work-class {
    padding-left:0px;
    text-align: center;
  }
  .work-class ul {
    display: inline-block;
    text-align: center;
    padding-left: 0px;
  }
  .work-class ul li{
    min-width: auto;
    width: 100%!important;
  }
  .mob-view .section-4 .col-md-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .main-para-div .connect-btn {
    min-width: 245px;
  }
  .port-card .card-head h4{
    font-size: 30px;
    line-height: 37px;
  }
  .port-card .card-cap p {
    font-size: 16px;
    line-height: 20px;
  }
  .more-text a{
    line-height: 20px;
    font-size: 16px;
  }
  .port-card {
    margin: 0px 0px 20px!important;
    min-height: 300px;
  }
  .add-capt p:last-child{
    margin-bottom: 40px;
  }
  .sales-sec .add-head h4 {
    margin-bottom: 8px;
  }
  .mob-pad{
    margin-top: 50px;
  }

  .tab-content .tab-head {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 50px;
  }
  .caption-sec {
    margin-bottom: 30px;
  }
  .portfolio .section-3.blue-bg{
    padding: 100px 0px 0px !important;
  }
  .list{
    margin: 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .list .list-capt {
    width: 100%;
    display: block;
    padding-right: 20px;
  }
  .view-more button img{
    width: 24px;
  }
  .list .download-btn button span img{
    width: 24px;
  }
  .flex-wireframe {
    display: inline-block;
  }
  .Resources .press-col {
    max-width: 100%!important;
  }
  .Resources .section-2 .tab-card .press-col .port-card {
    width: 100%!important;
  }
}



/* IN PAGE PORTFOLIO SECTION */

.protfolio-common-hold .portfolio-cards
{ 
  width: auto;
  max-width: 400px;
  min-height: 300px;
  background: #FFFFFF;
  box-shadow: 3px 3px 0px #FFD028, 2px 2px 5px rgba(30, 75, 94, 0.3);
  border-radius: 3px;
}
.protfolio-common-hold .portfolio-card-info h4
{
    font-family: Heldane Display;
    font-size: 24px;
    font-style: normal;
    line-height: 37px;
    color: #1E4B5E;
    margin-top: 15px;
}
.protfolio-common-hold .com-port{
  margin-bottom: 30px;
}


@media only screen and (max-width:425px)
{
  .connect-us button
{
  max-height: 50px;
}
  .custom-inx-logo svg 
  {
    width: 90px;
    height: 70px;
  }
  .footer-logo svg {
    width: 120px;
    height: auto;
  }
  .protfolio-ul li
  {
    width:100%;
  }
  .protfolio-ul li .portfolio-cards
  {
    width:100%;
  }
  .section-1
  {
    padding: 140px 0px 140px 0px;
    height: 100%;
  }
  .section-2, .section-3, .section-4, .section-5 
  {
    padding: 60px 0px 60px 0px!important;
    height: 100%;
  }
  .section-1 .custom-section
  {
    padding-top:0px;
  }

  /* for button common mob css */



  .custom-section .main-para-div .connect-btn span 
  {
    font-size: 20px;
    line-height: 21px;
  }
  .d-hide
  {
    font-family: 'Object Sans Heavy';
    display: block;
    text-decoration:underline;
    color: #007CC1;
  }
  .secondary-footer .copyright-text {
    position: relative!important;
    bottom: inherit;
    top: inherit;
    display: block;
    /* margin-top: 80px;
    margin-bottom: 20px; */
}
.secondary-footer .footer-col-text
{
  font-size:30px;
  line-height: 37px;
}
.secondary-footer .all-branches-ul li p,.secondary-footer .quick-links-hold .quick-links-ul li a
{
  font-size: 16px;
}
.secondary-footer .email-signup-common h4,.secondary-footer .follow-us-common .follow-us-head h4
{
  font-size: 16px;
}
.copyright-text span
{
  font-size: 14px;
}
  .mp-testimonials .slick-prev
  {
    left: -20px!important;
  }
  .mp-testimonials .slick-next {
    right: -20px!important;
  }
  .mp-testimonials .slick-arrow
  {
    width: 35px;
    height: 35px;
  }
  .connect-with-custom-section .main-para-div .connect-btn
  {
    min-width: 204px;
    min-height: 60px;
    padding: 20px;
  }
  .custom-section .main-para-div .connect-btn, a.connect-btn.btn-primary, .new-design-home .section-1 .custom-section .connect-btn, .portfolio .section-1 .top-section-btn .connect-btn
  {
    min-width: 204px !important;
    width: 204px !important;
    height: 60px !important;
    max-height: 60px;
    line-height: 60px !important;
  }
  .new-design-home .section-1 .custom-section .connect-btn{
    padding: 20px 0px;
  }
  .contact-modal .check-content label {
    align-items: flex-start;
    justify-content: flex-start;
  }
  .contact-modal .cbx span:first-child 
  {
    top: 3px;
  }
  .contact-modal .check-custom-text {
    font-size: 12px;
  }
  .mp-slide {
    margin-top: -95px;
    padding-bottom: 45px;
}
.section-1 .main-head-1 .primary-span {
  font-size: 42px;
  line-height: 45px;
}
}
@media only screen and (max-width: 414px){
  .Design-content .progressbar {
    padding-left: 15px;
    margin-top: 10px!important;
}
}



@media only screen and (max-width: 980px)
{
    .about-us .main-para-div.main-para-intro {
        width: 100%!important;
    }
}

/* end startup-page */

/* start blockchain-page */
.Design-content .crypto-service  .main-para-div p{
  max-width: 295px;
}

.block-chain .crypto-service .section-2   .main-para-div p{
  max-width: 470px;
}
.service-img{
  margin-bottom: 30px;
  width: 180px;
  height: 180px;
  display: flex;
  background: #fff;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #FFFFFF;
}
.service-head{
  margin-bottom: 15px;
}
.service-head h4,.service-head h3{
font-style: normal;
line-height: 37px;
font-size: 30px;
color: #1E4B5E;
font-family: 'Heldane Display';
}

/* end blockchain-page */


/* start location styles */
.add-head h4{
  color: #1E4B5E;
  font-weight: 600;
  font-size: 42px;
  margin-bottom: 20px;
  font-family: 'Heldane Display';
}
.add-capt p{
  color: #1E4B5E;
  font-weight: normal;
  font-size: 18px;
  margin-bottom: 30px;
  line-height: 28px;
}
/* end location styles */

/* landing page styles */
.home-card{
width: 100%;
min-height: 425px;
background: #2692AA;
box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.1);
border-radius: 5px;
text-align: center;
padding: 20px;
position: relative;
}
.home-tit h4{
font-family: 'Heldane Display';
font-size: 30px;
line-height: 37px;
text-align: center;
color: #FFFFFF;
margin-bottom: 0px;
}
.home-tit {
  min-height: 72px;
}
.home-des p{
font-size: 18px;
letter-spacing: -1px;
line-height:22px;
text-align: center;
color: #FFFFFF;
}
.home-btn .view-btn{
background: #FFFFFF;
border:1px solid #fff;
border-radius: 3px;
font-size: 18px;
line-height: 20px;
text-align: center;
color: #007CC1;
font-family: 'Object Sans Heavy';
padding: 10px;
width: 70%;
height: 50px;
transition: all 0.2s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

/* 10.7.2020 */

.home-card .home-btn .view-btn {
  background: #2692AA;
  border: 2px solid #fff;
  color: #fdfdfd;
}
.home-card
{
  min-height: 295px;
}
.home-card .home-tit
{
  min-height: 40px;
}

/* 10.7.2020 */

.home-btn .view-btn:hover{
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
}
.home-btn{
  position: absolute;
  width: 100%;
  left: 0px;
  bottom: 30px;
}

.home-img,.home-des,.home-tit {
  margin-bottom: 7px;
}

/* left text right img css starts */

.company-info .company-offer-imgs svg{
    width:372px;
    height:412px;
}
.company-info .comp-info-right-col
{
    display: flex;
    align-items: center;
    justify-content: center;
}
.right-img-hold{
  margin-left: 40px;
}
.comp-info-right-img{
    width: 375px;
}
.comp-info-right-img img{
  width: 100%;
}
.comp-info-img-hold {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.company-info .mar-class,.crypto-service .mar-class{
  margin-right: 50px;
}

/* box shadow rotate css */

/* for enterprise and startup */

.ylow-bx {
    position: relative;
    transition:0.5s ease;
}

.head-img.ylow-bx:before {
  box-shadow: 3px 3px 0px #FFD028, 2px 2px 5px rgba(30, 75, 94, 0.3);
    content: '';
    position: absolute;
    width: 145px;
    height: 145px;
    border-radius: 50%;
    transition:0.5s ease;
}
.head-img.ylow-bx:hover::before 
{
  box-shadow: 3px 3px 0px #FFD028, 2px 2px 5px rgba(30, 75, 94, 0.3);
  -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
    transition:0.5s ease;
}

/* for awards cards */

.about-us .award-common-hold .award-card-ul li .award-card.ylow-bx::before
{
  box-shadow: 3px 3px 0px #FFD028, 2px 2px 5px rgba(30, 75, 94, 0.3);
  content: '';
  position: absolute;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  transition:0.5s ease;
}
.about-us .award-common-hold .award-card-ul li .award-card.ylow-bx:hover::before 
{
  box-shadow: 3px 3px 0px #FFD028, 2px 2px 5px rgba(30, 75, 94, 0.3);
  -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
    transition:0.5s ease;
}


/* for home page and blockchain */

.service-img.ylow-bx:before 
{
    box-shadow: 3px 3px 0px #FFD028, 2px 2px 5px rgba(30, 75, 94, 0.3);
      content: '';
      position: absolute;
      width: 180px;
      height: 180px;
      border-radius: 50%;
      transition:0.5s ease;
}
.service-img.ylow-bx:hover::before 
{
  /* box-shadow: 3px 3px 0px #FFD028, 2px 2px 5px rgba(30, 75, 94, 0.3);
  -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
    transition:0.5s ease; */
}





/* box shadow rotate ends */



/* 21.10.2020 */

.work-with-col 
{
  z-index: 9;
}

/* 21.10.2020 */


@media only screen and (max-width: 1455px)
{
  .design-home .design-slide
  {
    min-height: 100%;
  }
  .design-home .design-slide img
  {
    width: 100%;
  }

}

@media only screen and (max-width: 1280px)
{
    .comp-info-right-img
    {
        width: 300px;
    }
    .work-class ul li .head-img
    {
      min-width: 130px;
      max-width: 130px;
      min-height: 130px;
      max-height: 130px;
    }
    .head-img.ylow-bx:before 
    {
        position: absolute;
        width: 130px;
        height: 130px;
    }
    .work-class
    {
      padding: 0px 0px;
    }
    .Resources .section-1 .custom-pill-links-section .custom-nav .nav-item .nav-link{
      margin: 0px 0px ;
    }
}


@media only screen and (max-width:767px) and (min-width:320px)
{
  
  .Resources .section-2 .custom-container
  {
    min-width: 80%;
    max-width: 80%;
  }
  .FinTech .company-offer-imgs,.Healthcare .company-offer-imgs {
    width: 200px;
    display: block;
    margin: 0 auto;
  }
  .FinTech .company-info .comp-info-right-col,.Healthcare .company-info .comp-info-right-col
  {
    justify-content:center;
  }
}

@media only screen and (max-width:520px){
.overall-sec-11 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.sec-22 {
  padding-left: 0px;
} 
}

@media only screen and (max-width:425px)
{
  .quick-contact a,.zsiq_theme1.zsiq_floatmain{
    right: 23px!important;
  }
  .contact-modal.theme-change .modal-header .modal-side-close, .contact-modal.theme-change button.close {
    top: -45px;
    right: -40px!important;
}
  .custom-container
  {
    min-width: 80%;
    max-width: 80%;
  }
  .secondary-footer-common-section .custom-container
  {
    min-width: 80%;
    max-width: 80%;
  }
  .sec-footer-row .footer-col-1,.sec-footer-row .footer-col-2,.sec-footer-row .footer-col-3
  {
    padding:0px;
  }
  .connect-with-custom-section .explore-hold p
  {
    font-size: 20px;
  }
  .Design-content .custom-container
  {
    max-width: 70%;
    min-width: 70%;
  }
  /* .Design-content .scrollable-container .column .section-2 {
    padding-top: 175px !important;
    padding-bottom: 100px !important;
  } */

  .Design-content .progressbar
  {
    padding-left: 15px;
    /* margin-top: 90px; */
  }
  .Design-content .progressbar .circle {
    width: 20px;
    height: 20px;
    font-size: 10px;
  }
  .Design-content .progressbar .circle.text-white{
    font-size: 10px;
  }
  .Design-content ul.progressbar li .line-style{
    margin-left: 9px!important;
  }
  .row.ww-row {
    margin: 0px;
  }

  .design-home .mp-slide .slick-slider .slick-dots {
    bottom: 15px;
  }
  .design-home .mp-slide .slick-dots li button:before {
   font-size:8px ;
  }
  .design-home  .slick-dots li button {
    width: 8px;
    height: 8px;
  }
  .Design-content .scrollable-container  .design-header .custom-container .custom-section {
    max-width: 70%;
    min-width: 70%;
    width: 70%;
  }

/* resp-background-image for all pages */

.MarketPlace.section-1 
{
  background-image: url(/assets/img/hero-resp-bgs/mp-resp-bg.png) !important;
}
.Design-content .section-1.design-page
{
  background-image: url(/assets/img/hero-resp-bgs/design-resp-bg.png) !important;
}
.Home .technology .section-1
{
  background-image: url(/assets/img/hero-resp-bgs/tech-resp-bg.png) !important;
}
.Startup .section-1
{
  background-image: url(/assets/img/hero-resp-bgs/startup-resp-bg.png) !important;
}

  .company-info .highlight-text h4,.company-info .highlight-text h3
  {
      font-size: 30px;
      line-height:  37px;
  }
  .company-info .highlight-text h4 span,.company-info .highlight-text h3 span  {
      /* background-image: url(/assets/img/bar-one.png); */
      background-position: 0 23px;
      background-size: 40px 10px;
      background-repeat: repeat-x;
   }

   .Design-content .progressbar-active .circle.text-white {
    width: 30px;
    left: -5px;
    height: 30px;
  }
  .Design-content .progressbar-active .circle.text-white{
    font-size: 14px!important;
  }

}


@media only screen and (max-width:490px){
  .app-p{
    font-size: 15px;
  }
}


@media only screen and (max-width:348px){
  .company-info .highlight-text h4 ,.company-info .highlight-text h3{
    font-size: 26px;
    line-height: 37px;
  }
  .company-info .highlight-text h4 span ,.company-info .highlight-text h3 span {
    /* background-image: url(/assets/img/bar-one.png); */
    background-position: 0 20px;
    background-size: 40px 10px;
    background-repeat: repeat-x;
   }
  .Design-content .progressbar{
    padding-left: 10px;
  }
}

@media only screen and (max-width:991px)
{
  .Header .right-menu a.connect-btn, .Resources .section-1 .custom-pill-links-section .right-menu a.connect-btn{
    min-width: auto;
  }
    section#stickyBar.section-1.sticky{
        padding-top: 65px;
        width: 95%;
    }
    /* .web-logo {
        display: none;
    }
    .Header .mob-logo {
        display: inline-block;
    }
    .Header.scrollHeader .custom-inx-logo .onscroll-logo{
      display: none;
    } */
    .custom-inx-navbar{
      /* transition: all 0.7s ease; */
    }
    .custom-inx-logo .web-logo {
      width: 90px;
      height: 75px;
      /* transition: all 0.7s ease; */
    }
    .Header.scrollHeader .custom-inx-logo .onscroll-logo {
      opacity: 0;
      display: none;
    }
    .Header.scrollHeader .custom-inx-logo .web-logo {
      opacity: 0;
      display: none;
    }
    .Header.scrollHeader .mob-logo {
      display: none;
      opacity: 0;
      width: 40px;
    }
    .Header.scrollHeader .custom-inx-navbar{
      /* height: 60px; */
      background: #FFFFFF!important;
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
      padding: 10px 20px;
    }
    .Resources .custom-inx-navbar {
        height: 90px;
        padding: 15px;
        background: linear-gradient(90deg, #FFFFFF 0%, #F1F1F1 100%), #F0F0F0;
    }

    .Header.scrollHeader .custom-inx-logo, .Header.scrollHeader .bm-burger-button {
        /* transform: scale(1);
        transition: all 0.7s ease;
        -webkit-transform: scale(.85);
        transform: scale(.85);
        transition: all .7s ease; */
    }
    .Resources .Header .ham-menu-hold{
        margin: 10px 0px;
        transform: scale(0.65);
        width: 30px;
        height: 17px;
        transition: all 0.7s ease;
    }
    .Header .ham-menu-hold{
      transform: scale(0.65);
      /* width: 30px;
      height: 17px; */
      transition: all 0.7s ease;
    }

    /* .Resources .section-1 .custom-container .custom-pill-links-section.sticky {
      position: unset;
      top: auto;
      width: 100%;
      margin: auto;
      padding: 0px 0px;
      z-index: 99;
      transition: none;
    }
    .Resources .section-1 .custom-pill-links-section.sticky .custom-nav{
      justify-content: flex-start;
      height: auto;
    }
    .Resources .section-1 .custom-pill-links-section.sticky .custom-nav .nav-item .nav-link {
      padding: 15px 0px!important;
      margin: 0px 0px!important;
    }
    section#stickyBar.section-1.sticky {
        padding-top: 57px;
        width: 95%;
    }
    section#stickyBar.section-1 .custom-container {
        width: 100%;
        max-width: 100%;
    } */
}


@media only screen and (max-width:375px){
.mp-slide {
  margin-top: -100px;
  padding-bottom: 50px;
}
.Design-content .progressbar {
  padding-left: 15px;
}
.tech-crack img {
  width: 85%;
  min-width: 85%;
}
}

@media only screen and (max-width:320px)
{
  .Resources .section-2 .custom-container
  {
    min-width: 80%;
    max-width: 80%;
  }
  .Design-content .progressbar {
    padding-left: 10px!important;
  }
  .section-1 .main-head-1 .primary-span{
    font-size: 36px;
    line-height: 42px;
  }
  .section-1 .main-head-1 .secondary-span{
    font-size: 24px;
    line-height: 30px;
  }
}

.animate{ 
  -webkit-animation: 1s ease-in smoothScroll;
  animation: 1s ease-in smoothScroll;
  opacity: 1!important;
}
@-webkit-keyframes smoothScroll {
  0% {
      opacity: 0;
      transform: translateZ(0) scale(0.99);
  }
  100% {
      opacity: 1;
      transform: translateZ(0) scale(1);
  }
}
@keyframes smoothScroll {
  0% {
      opacity: 0;
      transform: translateZ(0) scale(0.99);
  }
  100% {
      opacity: 1;
      transform: translateZ(0) scale(1);
}
}
.customHeader .custom-inx-navbar {
    background: #FFFFFF;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}
.view-more{
  margin-top: 25px;
}
.footer-logo {
  cursor: pointer;
}


/* kyc style */

.kyc-doc {
  max-width: 1300px;
  margin: 0 auto;
}

section.section_1 {
  background: #F4E7E7;
  position: relative;
  height: 85vh;
  width: 95%;
  margin: 0 auto;
  margin-bottom: 35%;
  margin-top: 110px;
}

.bc-pink {
  background: #F4E7E7;
  height: 60vh;
  position: absolute;
  bottom: -37%;
  z-index: 0;
  width: 100%;
  transform: skewY(2.5deg);
  left: 0;
}
section.kyc-doc {
  background: #F4E7E7;
  width: 100%;
  height: 100%;
}
.kyc-index{
  text-align: center;
  height: calc(100vh - -113px);
  position: relative;
}
.kyc-index h1 {
  color: #2A2A2A;
  font-size: 95px;
  text-align: center;
  line-height: 53px;
  padding: 8% 0 2%;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  margin: 0 0 20px 0;
}
.kyc-index p{
  font-size: 35px;
  color: #2A2A2A;
  line-height: 46px;
  font-family: "Bree Serif", serif;
  margin-bottom: 2%;
}
.kyc-index h6{
  color: #4E4848;
  font-size: 18px;
  line-height: 30.4px;
  font-family: "Open Sans", sans-serif;
  opacity: 0.9;
  font-weight: 600;
}
.break-up{
  display: block;
}
img.bc-img2 {
  width: 100%;
  position: unset!important;
}

.bc-img img.bc-img1 {
  width: 73%;
  margin: auto;
  left: 0;
  right: 0;
  top: 35px;
  z-index: -1;
  opacity: 0.3;
}

.bc-img {
  position: absolute;
  top: 0;
  width: 35%;
  left: 0;
  z-index: 9;
  right: 0;
  margin: auto;
}

.images {
  position: relative;
  top: -66%;
  /* width: 35%; */
  margin: auto;}

.bc-img img {
  position: absolute;
  width: 100%;
}

img.bc-img3 {
  top: 21%;
  right: 0%;
  width: 52%;
}

img.bc-img4 {
  bottom: 13%;
  width: 66%;
  left: -13%;
}

.images p {
  font-size: 335px;
  z-index: -3;
  color: #2A2A2A;
  opacity: 0.05;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
}
.images p span{
  position: absolute;
  width: 100%;
  left: 0;
  top: 185px;
  display: block;
  text-align: center;
}

section.section_1 a{
  padding: 10px 0;
  position: absolute;
  bottom: 42%;
  z-index: 99;
  cursor: pointer;
  left: 0;
  right: 0;
}
/* // section 2 */
.heading-line a {
  color: #2A2A2A;
}
section#section_2 .heading-line {
  padding: 10% 0 6% 0;
}
.section_2 .heading-line {
  padding: 7% 0 10% 0;
}
.sec2-images {
  position: relative;
  right: 0;
  z-index: 9;
  width: 57%;
  left: 0;
  margin-left: auto;
}

img.img2_1 {
  z-index: -1;
  width: 36%;
  position: absolute;
  right: 40px;
  bottom: 41px;
}
.sec2-img {
  z-index: 1;
  position: absolute;
  right: 0;
}
img.img2_2 {
  width: 100%;
}

section.section_2 {
  text-align: center;
  width: 95%;
  margin: 0 auto;
}

section.section_2 .kyc-solution {
  width: 90%;
  margin-left: 50px;
  padding-top: 5%;
}
.heading-line {
  font-size: 50px;
  line-height: 60px;
  font-family: 'Roboto';
  font-weight: 900;
  letter-spacing: 0.05px;
  padding: 50px 0;
  text-align: center;
}

.kyc-doc {
  width: 100%;
  margin: 0 auto;
}

.kyc-solution {
  background: #F4E7E7;
  width: 96%;
  padding: 40px;
  font-size: 18px;
  text-align: left;
  font-family: "Open Sans";
  color: #4E4848;
  line-height: 30px;
  padding-right: 30%;
}

.inline-solution {
  display: flex;
  align-items: center;
  position: relative;
  padding: 20px 0 120px;
}


.section_2 .kyc-solution:after {content: '';width: 89%;height: 100px;border: 2px solid #D9BBBB;position: absolute;left: 100px;top: -14px;z-index: -1;}

.section_2 .kyc-solution,section.section_3 {
  position: relative;
  width: 95%;
  margin: 0 auto;
}
/* // Section 3 */

.section_3 .inline-solution{
  margin-top: 10px;
  padding-bottom: 0px;
  align-items: flex-start;
}
.section_3 .bc-pink{
  z-index: -1;
  height: 47%;
  bottom: -10%;
}
.section_3 .kyc-solution{
  padding:7% 0 4% 40%;
  width: 100%;
}

.section_3 .kyc-solution h4 {
  font-size: 40px;
  line-height: 55px;
  font-family: 'Bree Serif';
}
.sec3-img {
  position: relative;
}

.sec3-images {
  position: absolute;
  top: 0;
  left: 30px;
}
.section_3 .kyc-solution p {
  font-family: 'Open Sans';
}
/* // section 4 */

.arrow-point {
  width: 100%;
  position: absolute;
  height: 4px;
  right: 11%;
  background: #749a8a;
  top: 100px;
}

.arrow-point img {
  margin-top: -17px;
  margin-left: -18px;
}

.sec4-img {
  position: relative;
  top: -38px;
}

.arrow-point img {
  width: 45px;
}

.sec4-images {
  position: relative;
  width: 95%;
  top: -30px;
  z-index: 9;
  margin-left: auto;
}

img.img4_2 {
  width: 100%;
}
.kyc-solution p.mrgn-adjest {
  margin-bottom: 5px;
}
img.img4_1 {
  width: 73%;
  position: absolute;
  right: 7%;
  z-index: -1;
  top: 4%;
}

section.section_4 {
  margin-top: 20%;
  background: #FAF5F5;
  padding: 0 9%;
}

section.section_4 .kyc-solution{
  background: #FAF5F5;
    padding: 0;
    width: 100%;
}
.kyc-solution h4 {
  font-size: 40px;
  line-height: 45px;
  font-family: "Bree Serif";
  color: #2A2A2A;
  margin-bottom: 0;
  background-image: url(/assets/img/kyc/ellipse.svg);
  background-repeat: no-repeat;
  background-size: 80px;
  background-position: 0 0px;
  padding: 10px 15px;
  margin-left: -15px;
  padding-right: 0;
  margin-bottom: 30px;
}

.kyc-solution  h4.digital {
  margin-bottom: 0;
  padding-bottom: 25px;
  background-position: 0 20px;
  line-height: 103px;
}
.kyc-solution p {
  margin-bottom: 28px;
  font-weight: 600;
  font-family: 'Open Sans';
  letter-spacing: 0.2px;
}
.kyc-doc.even .solution-img {
  right: auto;
  left: 0;
}

.kyc-doc.even .solution-img img.solution_1 {
  right: auto;
  top: 385px;
  left: 23px;
}

.kyc-doc.even .solution-img img.solution_2 {
  left: 0px;
  top: -50px;
  z-index: 9;

}

.kyc-doc.even .kyc-solution {
  padding-right: 0;
  margin-left: 4%;
  padding-left: 0;
}
.kyc-doc.even .inline-solution {
  padding-bottom: 80px;
}
.kyc-doc.even img.img4_1 {
  top: auto;
  bottom: 23px;
}
  .section_4 .kyc-solution {
      padding: 5% 0;
  }
  .kyc-doc.even .arrow-point {
    transform: rotate(180deg);
    right: 0;
    left: auto;
    width: 71%;
    top: 70px;
}

.kyc-doc.even .sec4-images {
    margin-right: auto;
    margin-left: -50px;
    top: -50px;
}
.section_4 .inline-solution {
  padding: 0;
  align-items: flex-start;
}

section.section_4 .heading-line {
  margin: 0 0 50px 0;
  padding: 8% 0 6% 0;
}
.arrow-point.last-img {
  top: 72px;
}

@media (min-width: 1025px) and  (max-width: 1400px){
  section.section_1 a{
    bottom: 28%;
  }
  .kyc-index{
    height: calc(100vh - -21px);
  }
  .images{
    top: -49%;
  }
  section.section_1 {
     margin-bottom: 40%;
}
.images p span{
  top: 69px;
}
}
  @media (min-width: 320px) and (max-width: 1024px){
    .kyc-solution h4.digital{
      line-height: normal;
    }
    .images p{
      font-size: 270px;
    }
    .bc-img{
      width: 41%;
    }
    .sec2-images{
      width: 48%;
    }
    .sec4-img {
      width: 90%;
  }
    .section_2 .kyc-solution:after{
      left: 60px;
    }
    
      section.section_1 {
          padding: 0 50px;
      }
      
      
      
      .solution-img {
          bottom: 0;
          top: -120px;
          right: -10px;
      }
      
  
      .section_3 .kyc-solution {
          padding-left: 44%;
          padding-right: 20px;
      }
      
      
      section.section_3 .heading-line {
          padding-top: 0;
      }
      
      section.section_4 .heading-line {
          padding-bottom: 0;
          width: 100%;
      }
      span.break-up {display: contents;}

      section.section_4 .kyc-solution,.kyc-doc.even .kyc-solution {
          padding: 0%;
          margin: 0;
      }
      .heading-line {
        width: 80%;
        margin: 0 auto;
    }
    .kyc-doc.even .sec4-img {
      top: 0;
    }     
  }
  @media (min-width:320px) and (max-width: 952px){
    .header-label ul {
      padding: 7px 20px;
      flex-flow: row wrap;
      align-items: center;
      justify-content: center;
      line-height: 20px;
  }
  /* .custom-inx-navbar.kyc-container {
    top: 54px;
  } */
  .header-label ul li:last-child{
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
  }
@media (min-width:320px) and (max-width: 991px){
 
 
.sec2-images {
  width: 44%;
  top: -30px;
}
section.section_4{
  padding: 0 4%;
}
.sec4-images {
    width: 85%;
}
.images p span{
  top: 70px;
}
.bc-img{
  width: 45%;
}
section.section_1{
  margin-bottom: 51%;
}
}
  @media(min-width:320px) and (max-width:768px){
    .Resources .section-1 .custom-nav .nav-item{
      padding: 0px 15px;
    }
    section.section_4 .inline-solution {
      padding-bottom: 20px;
      }
      .sec4-img{
        top: 30px;
      }
  .kyc-solution {
      padding-right: 25%;
  }  
  .section_2 .heading-line {
    font-size: 40px;
    line-height: 45px;
    padding-top: 0px;
    padding-bottom: 20px;
}

.inline-solution {
    display: block;
}

.kyc-solution,.section_3 .kyc-solution {
    padding: 30px;
    padding-bottom: 340px;
}
section.section_3 .heading-line{
  padding: 40px 0 20px;
}
.section_3 .kyc-solution {
  padding-bottom: 68%;
}
.sec3-img{
  position: absolute;
    width: 70%;
    margin: auto;
    left: 0;
    right: 0;
    bottom: -180px;
}
img.img3 {
  width: 78%;
}
.sec3-images{
    text-align: center;
    position: unset;
}
section.section_4 {
    margin-top: 200px;
}


.kyc-solution p{
  margin-bottom: 13px;
  font-size: 15px;
  line-height: 22px;
}
.kyc-index h6{
  font-size: 16px;
}
.kyc-index h1{
  font-size: 80px;
}
.kyc-index p,.kyc-solution h4,.section_3 .kyc-solution h4{
  font-size: 30px;
  line-height: 42px;
  margin-bottom: 20px;
}
.heading-line{
  font-size: 40px;
}
section.section_1 img.downarrow{
  width: 45px;
}
.section_2 .kyc-solution:after {
  left: -13px;
}
.sec4-images {
  margin: 0 auto;
  margin-top: 0;
  width: 40%;
}

.arrow-point {
  transform: rotate(90deg);
  right: 0;
  margin: auto;
  width: 50%;
  bottom: 220px;
  top: 0;
  left: 0;
}

.kyc-doc.even .sec4-images {
  margin: 0 auto;
}

.kyc-doc.even .arrow-point {transform: rotate(-90deg);left: 0px;right: 0px;top: 310px;width: 45%;}
.sec2-img{
  bottom: 0;
  width: 96%;
  left: 0;
  margin: auto;
}
.sec2-images {
  margin: 0 auto;
  top: 0;
}
section.section_4 .kyc-solution{
  padding: 0 0px 10% 0!important;
}
.kyc-doc.even .inline-solution {
  padding-bottom: 0;
}

.arrow-point.last-img {
  top: 0;
}
.images p{
  font-size: 200px;
}
img.img2_1{
  right: 30px;
  bottom: 33px;
}

.header-label ul li p {
  font-size: 13px;
}
  }
  @media (min-width:320px) and (max-width:767px){
    .heading-line {
      width: 100%;
  }
  section.section_4,.section_3 .kyc-solution,section.section_2 .kyc-solution{
    padding-left: 20px;
    padding-right: 20px;
  }
  /* header line css start */
  /* .custom-inx-navbar.kyc-container {
    top: 74px;
} */


.header-label ul li:last-child ,.header-label ul li{
  margin: 0 auto;text-align: center;
  width: 100%;
}
.header-label ul{
  display: none;
}
.custom-inx-navbar.kyc-container{
  top: 0;
}
   /* header line css start*/
  }
  @media (min-width:320px) and (max-width: 567px){
    /* .custom-inx-navbar.kyc-container {
      top: 94px;
  } */
  div.custom-inx-navbar.kyc-container {
    height: 70px;
}
  }
  @media (min-width:320px) and (max-width: 425px){
   
    .custom-inx-navbar
    .images p{
      font-size: 118px;
    }
    .images p span{
      top: 120px;
    }
    .sec3-img{
      width: 100%;
      bottom: -220px;
    }
    .sec2-images {
      width: 75%;
  }
  .kyc-index h1 {
      font-size: 60px;
      margin-bottom: 0;
  }
  
  .kyc-index p {
      font-size: 20px;
      line-height: 30px;
  }
  
  .kyc-index h6 {
      font-size: 14px;
      line-height: 25px;
  }
  
 
  
  section.section_1 {
      padding: 0 20px;
      margin-bottom: 100%;
  }
  .bc-img{
    width: 85%;
  }
  
  .heading-line ,.section_2 .heading-line {
    font-size: 30px;
    line-height: 35px;
    width: 92%;
}

.kyc-solution p {
    font-size: 13px;
}

/* .custom-inx-navbar{
  padding-right: 0!important;
} */

section.section_4 .kyc-solution,.kyc-doc.even .kyc-solution  {
    padding-left: 0;
    padding-right: 0;
}
section.section_2 .kyc-solution{
  margin-left: 20px;
}


section.section_4 {
    padding-bottom: 40px;
}
.sec4-images{
  width: 100%;
}
.kyc-doc.even .arrow-point{
  top: 780px;
}
.arrow-point{
  bottom: 460px;
}
img.img3{
  width: 90%;
}
  }
  @media (min-width: 320px) and (max-width: 375px){
    .contact-modal.theme-change .modal-content{
      padding: 60px 40px;
    }
    .contact-modal.theme-change .modal-header .modal-side-close, .contact-modal.theme-change button.close{
      top: -40px;
      right: -20px!important;
    }
    .bc-img{
      width: 100%;
    }
    .images p{
      font-size: 84px;
    }
    section.section_1 img.downarrow {
        width: 35px;
    }
   
    section.section_1 {
      margin-bottom: 110%;
  }
    
   
    
    .inline-solution {
        padding-bottom: 30px;
    }
    
    .heading-line, .section_2 .heading-line {
        font-size: 26px;
        line-height: 30px;
    }
    
    .section_3 .kyc-solution h4 {
        font-size: 26px;
        line-height: 34px;
    }
    
    .kyc-solution {
        padding-left: 20px;
        padding-right: 20px;
    }
    
    section.section_3 .heading-line {
        padding-top: 80px;
        padding-bottom: 0px;
    }
        
    section.section_4 {
        padding-left: 20px;
        padding-right: 20px;
    }
    
    .kyc-solution h4 {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 0;
        padding-bottom: 30px;
    }
    
    .kyc-solution p {
        line-height: 20px;
        margin-bottom: 18px;
    }
    .sec2-images{
      width: 88%;
      top: 40px;
    }
  }
  @media  (max-width: 320px){
    .contact-modal.theme-change .modal-title.h4{
      font-size: 35px;
      margin: 0px 0 10px 0;
    }
    .contact-modal.theme-change .modal-content{
      padding: 40px 20px;
    }
    .contact-modal.theme-change .modal-header .modal-side-close, .contact-modal.theme-change button.close {
      top: -25px;
      right: -5px!important;
  }
  .contact-modal.theme-change .contact-submit{
    min-width: 100%;
  }
  
.arrow-point{
  width: 21%;
}
.kyc-doc.even .arrow-point{
  top: 720px;
}
  }
/* end kyc styles */


.download-case-study.contact-submit {
  background: #FFCC4A !important;
  color: #1E4B5E !important;
  border: transparent;
  font-family: 'object sans heavy';
  padding: 8px 25px;
}
.company-info .main-para-div p.txt-bold{
  font-weight: bold;
}

.default-custom-link
{
  color:inherit;
}
.default-custom-link:hover
{
  color:inherit;
}
a.connect-btn.btn-primary, a.connect-btn.btn-primary:focus, a.connect-btn.btn-primary:active{
  background: #1E4B5E !important;
  color: #FFCC4A !important;
  border: 1px solid #1E4B5E !important;
  box-shadow: 0px 6px 10px rgba(30, 75, 94, 0.2) !important;
  padding: 0px !important; 
  width: 295px !important;
  height: 80px !important;
  line-height: 75px !important;
}
a.connect-btn.btn-primary:hover{
  box-shadow: 0px 6px 10px rgba(30, 75, 94, 0.4) !important;
}
/*resource page css start*/

.Resources .section-1 {
  background: #fff;
}

.Resources .section-1 .custom-nav .nav-item .nav-link {
  color: #1E4B5E;
}
.Resources .section-1 .custom-nav .nav-item .nav-link.active {
  color: #007CC1;
  font-weight: 600;
  border-bottom: 5px solid #007CC1;
}
.Resources .section-1 .custom-pill-links-section .right-menu .ham-menu-hold svg rect{
fill: #1E4B5E;
}
.Resources .section-1 .custom-nav .nav-item .nav-link:hover {
  color: #1E4B5E;
}
/*resource page css end*/
.Header.showBanner.scrollHeader + .ham-menu .web-menu-list{
  z-index: 0;
}
div.digital-header.Header + .ham-menu .web-menu-list .menu-list ul li a:hover, 
div.digital-header.Header + .ham-menu .web-menu-list .menu-list ul li a.active {
  /* color: #FFD028 ; */
  background: rgba(121, 201, 188, 0.30);
}
.web-menu-list .menu-list ul li a.active, div.digital-header.Header.scrollHeader + .ham-menu .web-menu-list .menu-list ul li a:hover{
  /* color: #FFD028 !important; */
  background: #24A989;
}

/*modal styles*/
.modal-body ul.health-care-list{
   padding-left: 20px;
   margin-top: 15px;
}
.modal-body ul.health-care-list li{
  margin-bottom: 15px;
  font-size: 14px;
}
.modal-title.health-care-heading{
  color: #1E4B5E;
  font-size: 24px;
  font-weight: 800;
}
