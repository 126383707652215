.algorith-bot-withForm-development {
  margin: 0!important;
  width: 100%;
}
.algorith-bot-withForm-development .lets-connect-form {
  padding: 20px 80px 50px 150px;
  display: flex;
}
.algorith-bot-withForm-development .lets-connect-form .heading.main-para-div .secondary-span {
  font-size: 30px;
  line-height: 37px;
  color: #1E4B5E;
  font-family: 'Roboto';
}
.algorith-bot-withForm-development .lets-connect-form .heading.main-para-div .primary-span {
  font-size: 45px;
  line-height: 63px;
  font-weight: 700;
  color: #24A989;
  font-family: 'Object Sans';
  margin-top: 10px;
}
.algorith-bot-withForm-development .lets-connect-form .contact-modal {
  width: 44%;
  margin: auto;
  margin-top: 40px;
}
.algorith-bot-withForm-development .lets-connect-form .contact-modal form {
  display: flex;
  align-items: flex-start;
  flex-flow: row wrap;
  justify-content: space-between;
}
.algorith-bot-withForm-development .lets-connect-form .contact-modal form .form-group {
  width: 100%;
}
.algorith-bot-withForm-development .lets-connect-form .contact-modal form .form-group .contact-form-label {
  color: #000;
  font-size: 14px;
  font-family: 'Object Sans';
  font-weight: 400;
}
.algorith-bot-withForm-development .lets-connect-form .contact-modal form .form-group .contact-form-label i {
  font-style: normal;
  opacity: 0.5;
}
.algorith-bot-withForm-development .lets-connect-form .contact-modal form .form-group.w-70 {
  width: 63%!important;
}
.algorith-bot-withForm-development .lets-connect-form .contact-modal form .form-group.w-30 {
  width: 35%!important;
}
.algorith-bot-withForm-development .lets-connect-form .contact-modal form .form-group input,
.algorith-bot-withForm-development .lets-connect-form .contact-modal form .form-group .contact-form-txt-area {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  border: 0;
  resize: none;
}
.algorith-bot-withForm-development .lets-connect-form .contact-modal .contact-submit {
  float: right;
  margin-top: 20px;
}
.algorith-bot-withForm-development .crypto-banner-otc {
  margin-top: 100px;
  padding: 50px 80px 30px 150px;
  background-image: url(/assets/img/algo-bot/version-new/algorithm-bot.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.algorith-bot-withForm-development .crypto-banner-otc.white-bg {
  background-image: unset;
  background: #fff;
  font-family: 'Object Sans';
  margin-top: 0;
  padding-top: 150px;
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
}
.algorith-bot-withForm-development .crypto-banner-otc.white-bg .custom-section em {
  display: block;
  font-style: normal;
  font-weight: 400;
  color: #1E4B5E;
  margin-bottom: 100px;
  line-height: 0;
  margin-top: 25px;
  font-size: 34px;
  font-family: "Object Sans Heavy";
}
.algorith-bot-withForm-development .crypto-banner-otc.white-bg .professionals-hold .professionals-ul li .read-more {
  font-size: 14px;
  font-weight: 500;
  font-style: italic;
  color: #24A989;
}
.algorith-bot-withForm-development .crypto-banner-otc.white-bg .professionals-hold .professionals-ul li button {
  margin-top: 30px;
  font-weight: 700;
  color: #1e4b5e;
  font-size: 18px;
}
.algorith-bot-withForm-development .crypto-banner-otc.white-bg .professionals-hold .professionals-ul li .professionals-info {
  color: #1E4B5E;
  margin-left: 0;
}
.algorith-bot-withForm-development .crypto-banner-otc.white-bg .professionals-hold .professionals-ul li .professionals-info span {
  display: block;
  margin: 12px 0 5px 0;
  font-size: 14px;
}
.algorith-bot-withForm-development .crypto-banner-otc.white-bg .professionals-hold .professionals-ul li .professionals-info strong {
  background-position: 0 8px!important;
  font-weight: 800;
  font-size: 16px;
  background-position: 0 34px;
  background-size: 100% 10px;
  background-repeat: no-repeat;
}
.algorith-bot-withForm-development .crypto-banner-otc.white-bg .contact-details {
  padding: 0;
  margin: 0;
}
.algorith-bot-withForm-development .crypto-banner-otc.white-bg .contact-details .main-head-div {
  margin-top: -70px;
  text-align: center;
}
.algorith-bot-withForm-development .crypto-banner-otc.white-bg .contact-details .main-head-div img {
  width: 90%;
}
.algorith-bot-withForm-development .crypto-banner-otc .main-head-1 .primary-span,
.algorith-bot-withForm-development .crypto-banner-otc .main-head-1 .secondary-span {
  color: #24A989;
}
.algorith-bot-withForm-development .crypto-banner-otc .custom-section .main-head-1 .primary-span {
  font-size: 48px;
  line-height: 48px;
}
.algorith-bot-withForm-development .professionals-hold {
  padding-right: 180px;
  margin-top: -30px;
}
.algorith-bot-withForm-development .professionals-hold .professionals-ul li {
  width: 100%;
  margin-bottom: 20px;
  align-items: flex-start;
}
.algorith-bot-withForm-development .professionals-hold .professionals-ul li .professionals-info.highlight-text h3 {
  font-size: 15px;
  color: #fff;
  letter-spacing: 0.5px;
  line-height: 24px;
}
.algorith-bot-withForm-development .professionals-hold .professionals-ul li .professionals-info.highlight-text h3 strong {
  letter-spacing: 0px;
  font-size: 16px;
}
.algorith-bot-withForm-development .contact-details {
  padding-right: 90px;
  margin-top: -60px;
}
.algorith-bot-withForm-development .contact-details .main-head-1 .secondary-span {
  font-size: 13px;
  line-height: 18px;
  margin: 0px 0;
  color: #fff;
  letter-spacing: 0.2px;
  font-family: 'Object Sans';
}
.algorith-bot-withForm-development .contact-details .contact-modal {
  padding: 20px;
  border-radius: 5px;
  background: rgba(10, 122, 128, 0.5);
}
.algorith-bot-withForm-development .contact-details .contact-modal .secondary-span {
  font-size: 15px;
  color: #24A989;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  font-family: 'Object Sans Heavy';
}
.algorith-bot-withForm-development .contact-details form {
  padding: 0px;
  border-radius: 12px;
}
.algorith-bot-withForm-development .contact-details form .form-group label.contact-form-label.form-label {
  color: #fff;
  font-size: 14px;
}
.algorith-bot-withForm-development .contact-details form .form-group p {
  font-size: 11px;
  margin: 0;
  margin-top: 5px;
}
.algorith-bot-withForm-development .contact-details form .contact-submit-btn {
  text-align: right;
  margin-top: 30px;
}
.algorith-bot-withForm-development .contact-details .main-head-div {
  margin-bottom: 20px;
}
.algorith-bot-withForm-development .contact-details .mail-to-ix a {
  color: #078ffa !important;
  font-weight: 700;
  text-decoration: underline!important;
  cursor: pointer;
}
.algorith-bot-withForm-development .contact-details .mail-to-ix {
  font-size: 14px;
  line-height: 22px;
  margin: 10px 0 0 0;
  color: #fff;
}
.algorith-bot-withForm-development .vedio-explanation {
  padding: 150px 80px 50px 150px;
  margin: 0;
}
.algorith-bot-withForm-development .vedio-explanation .video-file {
  width: 60%;
}
.algorith-bot-withForm-development .vedio-explanation .main-head-div {
  margin: 0;
}
@media only screen and (max-width: 1347px) and (min-width: 1025px) {
  .algorith-bot-withForm-development .professionals-hold {
    padding-right: 100px;
  }
}
@media only screen and (max-width: 1347px) and (min-width: 1201px) {
  .algorith-bot-withForm-development .professionals-hold .professionals-ul li .professionals-info {
    font-size: 14px;
    line-height: 20px;
  }
  .algorith-bot-withForm-development .contact-details .contact-modal span.secondary-span {
    top: 9px;
  }
  .algorith-bot-withForm-development .contact-details {
    padding-right: 0;
  }
  .algorith-bot-withForm-development .testimonials.crypto-blog-slider ul.crypt-blog-ul.principles-common-ul li.crypt-blog-li .blog-colunm-card {
    min-height: 435px;
  }
  br {
    display: none!important;
  }
  .algorith-bot-withForm-development .contact-details .main-head-1 .secondary-span {
    font-size: 12px;
    margin-top: 10px;
  }
  .algorith-bot-withForm-development .contact-details .main-head-div {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 1200px) and (min-width: 768px) {
  .algorith-bot-withForm-development .crypto-banner-otc.white-bg {
    padding-top: 120px;
  }
  br {
    display: none!important;
  }
  .algorith-bot-withForm-development .contact-details {
    margin-top: -65px;
  }
  .algorith-bot-withForm-development .contact-details .main-head-1 .secondary-span {
    font-size: 11px;
  }
  .algorith-bot-withForm-development .contact-details .main-head-div {
    margin-bottom: 20px;
  }
  .algorith-bot-withForm-development .contact-details {
    padding-right: 0;
  }
  .algorith-bot-withForm-development .crypto-banner-otc .custom-section .main-head-1 .primary-span,
  .algorith-bot-withForm-development .vedio-explanation .main-head-div .main-head-1 .primary-span,
  .algorith-bot-withForm-development .offer-crypto .main-head-1 .primary-span,
  .testimonials.crypto-blog-slider .custom-container .main-head-1 .primary-span {
    font-size: 33px;
    line-height: 42px;
  }
  .algorith-bot-withForm-development .professionals-hold .professionals-ul li .professionals-info {
    font-size: 14px!important;
    line-height: 19px!important;
    margin-left: 5px;
  }
  .algorith-bot-withForm-development .professionals-hold .professionals-ul li .professionals-info strong {
    font-size: 15px!important;
  }
}
@media only screen and (max-width: 1099px) and (min-width: 899px) {
  .algorith-bot-withForm-development .contact-details .main-head-1 .secondary-span {
    font-size: 13px;
  }
}
@media only screen and (max-width: 1200px) and (min-width: 991px) {
  .algorith-bot-withForm-development .professionals-hold,
  .algorith-bot-withForm-development .contact-details {
    padding-right: 0px;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 769px) {
  .algorith-bot-withForm-development .lets-connect-form {
    padding: 30px 90px 30px 110px;
  }
  .algorith-bot-withForm-development .crypto-banner-otc.white-bg .custom-section em {
    font-size: 25px;
  }
  .algorith-bot-withForm-development .contact-details .mail-to-ix {
    font-size: 12px;
    line-height: normal;
  }
  .algorith-bot-withForm-development .contact-details {
    margin-top: -50px;
  }
  .algorith-bot-withForm-development .contact-details .main-head-div {
    margin-bottom: 10px;
  }
  .algorith-bot-withForm-development .contact-details .main-head-1 .secondary-span {
    font-size: 10px;
  }
  .algorith-bot-withForm-development .professionals-hold,
  .algorith-bot-withForm-development .contact-details {
    padding-right: 0px;
  }
  .algorith-bot-withForm-development .testimonials.crypto-blog-slider ul.crypt-blog-ul.principles-common-ul li.crypt-blog-li .blog-colunm-card .principles-info-hold p {
    font-size: 22px;
    line-height: 26px;
  }
  .algorith-bot-withForm-development .testimonials.crypto-blog-slider ul.crypt-blog-ul.principles-common-ul li.crypt-blog-li .blog-colunm-card {
    min-height: 390px;
  }
  .algorith-bot-withForm-development .crypto-banner-otc {
    padding: 30px 90px 30px 110px;
    padding-left: 110px;
    margin-top: 90px;
  }
  .algorith-bot-withForm-development .crypto-otc .crypto-banner-otc:before {
    background-size: 100%;
  }
  .algorith-bot-withForm-development .contact-details form {
    padding: 10px;
  }
  .algorith-bot-withForm-development .vedio-explanation {
    padding: 60px 90px;
    padding-left: 110px;
  }
  .algorith-bot-withForm-development .custom-section.offer-crypto {
    padding: 50px 80px 10px;
    padding-left: 110px;
  }
  .algorith-bot-withForm-development .testimonials.crypto-blog-slider {
    padding: 0px 80px;
    padding-left: 110px;
  }
  .algorith-bot-withForm-development .contact-details .contact-modal {
    padding: 0;
  }
  .algorith-bot-withForm-development .contact-details .contact-modal .secondary-span {
    padding: 10px;
  }
}
@media only screen and (max-width: 991px) and (min-width: 320px) {
  .algorith-bot-withForm-development {
    background-image: none;
  }
  .blog-colunm-card {
    margin-top: 20px;
  }
  .algorith-bot-withForm-development .contact-details .main-head-1 .secondary-span {
    font-size: 12px;
  }
  .algorith-bot-withForm-development .contact-details .contact-modal {
    padding: 10px!important;
  }
  .algorith-bot-withForm-development .professionals-hold {
    padding-right: 0;
  }
  .algorith-bot-withForm-development .contact-details form {
    padding: 10px;
  }
  .algorith-bot-withForm-development .crypto-banner-otc,
  .algorith-bot-withForm-development .vedio-explanation,
  .algorith-bot-withForm-development .custom-section.offer-crypto,
  .algorith-bot-withForm-development .testimonials.crypto-blog-slider,
  .algorith-bot-withForm-development .lets-connect-form {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media only screen and (max-width: 949px) and (min-width: 900px) {
  .algorith-bot-withForm-development .contact-details .main-head-1 .secondary-span {
    font-size: 13px;
  }
}
@media only screen and (max-width: 831px) and (min-width: 769px) {
  .algorith-bot-withForm-development .contact-details .contact-modal span.secondary-span {
    top: 12px;
    line-height: 14px;
    left: 15px;
    font-size: 12px;
  }
}
@media only screen and (max-width: 799px) and (min-width: 320px) {
  .algorith-bot-withForm-development .professionals-hold .professionals-ul li {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 768px) and (min-width: 320px) {
  .algorith-bot-withForm-development .lets-connect-form.responsive-mode {
    flex-flow: row wrap;
  }
  .algorith-bot-withForm-development .lets-connect-form.responsive-mode .heading.main-para-div .secondary-span {
    font-size: 24px;
    line-height: 37px;
  }
  .algorith-bot-withForm-development .lets-connect-form.responsive-mode .heading.main-para-div .primary-span {
    font-size: 36px;
    line-height: 43px;
  }
  .algorith-bot-withForm-development .lets-connect-form.responsive-mode .contact-modal {
    display: block;
    width: 100%;
    margin: 0;
  }
  .algorith-bot-withForm-development .contact-details .main-head-1 .secondary-span {
    font-size: 13px!important;
  }
  .algorith-bot-withForm-development .contact-details .mail-to-ix {
    font-size: 13px;
    margin-top: 10px;
  }
  .algorith-bot-withForm-development .testimonials.crypto-blog-slider ul.crypt-blog-ul.principles-common-ul li.crypt-blog-li .blog-colunm-card .principles-info-hold p {
    font-size: 24px;
    line-height: 30px;
  }
  .algorith-bot-withForm-development .contact-details .contact-modal span.secondary-span {
    top: 28px;
    left: 25px;
    font-size: 12px;
  }
  .algorith-bot-withForm-development {
    margin-left: 0!important;
    margin-right: 0!important;
    margin-top: 40px;
  }
  .algorith-bot-withForm-development .professionals-ul li .professionals-info {
    font-size: 14px;
    line-height: 18px;
    margin-left: 0;
  }
  .algorith-bot-withForm-development .professionals-ul li .professionals-num {
    min-width: 30px;
  }
  .algorith-bot-withForm-development .contact-details {
    padding: 10px;
    margin-bottom: 40px;
  }
  .algorith-bot-withForm-development .contact-details .main-head-1 .secondary-span {
    font-size: 15px;
    line-height: 16px;
    margin: 10px 0;
  }
  .algorith-bot-withForm-development .contact-details .contact-modal {
    padding: 0px;
  }
  .algorith-bot-withForm-development .contact-details form {
    padding: 15px;
  }
  .algorith-bot-withForm-development .contact-details form .contact-submit-btn {
    margin-top: 10px;
  }
  .algorith-bot-withForm-development .vedio-explanation {
    padding: 30px;
  }
  .algorith-bot-withForm-development .vedio-explanation .main-head-1 .primary-span {
    font-size: 45px;
    line-height: 35px;
    margin-bottom: 20px;
  }
  .algorith-bot-withForm-development .offer-crypto .main-head-1 .primary-span {
    margin-bottom: 20px;
  }
  .algorith-bot-withForm-development .offer-crypto .main-head-1 .primary-span br {
    display: none;
  }
  .algorith-bot-withForm-development .offer-crypto .main-head-1 .secondary-span {
    font-size: 28px;
    line-height: 32px;
    margin-top: 10px;
  }
  .algorith-bot-withForm-development .offer-crypto .main-head-1 .secondary-span br {
    display: none;
  }
  .algorith-bot-withForm-development .custom-section.offer-crypto {
    padding: 30px;
  }
  .algorith-bot-withForm-development .c-cards-mg .service-img,
  .algorith-bot-withForm-development .c-cards-mg .service-img.ylow-bx:before {
    width: 100px;
    height: 100px;
  }
  .algorith-bot-withForm-development .testimonials.crypto-blog-slider {
    padding: 30px;
  }
  .algorith-bot-withForm-development .testimonials.crypto-blog-slider .section-4.MarketPlace {
    padding-bottom: 0!important;
    padding-top: 0!important ;
  }
  .algorith-bot-withForm-development .testimonials.crypto-blog-slider .testi-col {
    margin-bottom: 0;
  }
  .algorith-bot-withForm-development .testimonials.crypto-blog-slider .custom-container .main-head-1 .primary-span {
    font-size: 47px;
    line-height: 35px;
    margin-bottom: 20px;
  }
  .algorith-bot-withForm-development .testimonials.crypto-blog-slider .custom-container .principles-common-ul .principles-info-hold h4 {
    font-size: 34px;
    line-height: 43px;
  }
  .algorith-bot-withForm-development .testimonials.crypto-blog-slider ul.crypt-blog-ul.principles-common-ul li.crypt-blog-li {
    margin-bottom: 0;
  }
  .algorith-bot-withForm-development .testimonials.crypto-blog-slider ul.crypt-blog-ul.principles-common-ul li.crypt-blog-li .blog-colunm-card {
    min-height: 430px;
  }
}
@media only screen and (max-width: 767px) and (min-width: 320px) {
  .algorith-bot-withForm-development .lets-connect-form.responsive-mode {
    flex-flow: row wrap;
  }
  .algorith-bot-withForm-development .lets-connect-form.responsive-mode .contact-modal {
    display: block;
  }
  .algorith-bot-withForm-development .crypto-banner-otc.white-bg {
    padding-top: 110px;
  }
  .algorith-bot-withForm-development .crypto-banner-otc.white-bg .contact-details .main-head-div img {
    width: 90%;
  }
  .algorith-bot-withForm-development .crypto-banner-otc.white-bg .contact-details .main-head-div {
    text-align: center;
  }
  .algorith-bot-withForm-development .crypto-banner-otc.white-bg .custom-section em {
    margin-bottom: 30px;
    display: inline;
    font-size: 24px;
  }
  .algorith-bot-withForm-development .contact-details {
    border-top: 0!important;
  }
  .algorith-bot-withForm-development .crypto-banner-otc.white-bg .contact-details .main-head-div {
    margin-top: 0;
  }
  .algorith-bot-withForm-development .testimonials.crypto-blog-slider ul.crypt-blog-ul.principles-common-ul li.crypt-blog-li .blog-colunm-card {
    min-height: auto;
  }
  .blog-colunm-card {
    margin-top: 0px;
  }
  .algorith-bot-withForm-development .crypto-banner-otc .custom-section .main-head-div {
    margin-bottom: 20px;
  }
  .algorith-bot-withForm-development .testimonials.crypto-blog-slider ul.crypt-blog-ul.principles-common-ul li.crypt-blog-li .blog-colunm-card .principles-info-hold p {
    font-size: 23px;
    line-height: 28px;
    width: 100%;
    min-width: 100%;
  }
  .algorith-bot-withForm-development .contact-details .contact-modal span.secondary-span {
    top: 70px;
    left: 25px;
  }
  .algorith-bot-withForm-development .professionals-hold,
  .algorith-bot-withForm-development .contact-details {
    margin-top: 0;
  }
  .algorith-bot-withForm-development .crypto-banner-otc,
  .algorith-bot-withForm-development .vedio-explanation,
  .algorith-bot-withForm-development .custom-section.offer-crypto,
  .algorith-bot-withForm-development .testimonials.crypto-blog-slider,
  .algorith-bot-withForm-development .lets-connect-form.responsive-mode {
    padding-left: 20px ;
    padding-right: 20px ;
  }
  .algorith-bot-withForm-development .contact-details form .form-group label.contact-form-label.form-label {
    font-size: 14px;
  }
  .algorith-bot-withForm-development .custom-section .main-head-1 .primary-span,
  .algorith-bot-withForm-development .offer-crypto .main-head-1 .primary-span,
  .algorith-bot-withForm-development .vedio-explanation .main-head-1 .primary-span,
  .algorith-bot-withForm-development .testimonials.crypto-blog-slider .custom-container .main-head-1 .primary-span,
  .algorith-bot-withForm-development .vedio-explanation .main-head-div .main-head-1 .primary-span {
    font-size: 36px!important;
    line-height: 47px!important;
  }
  .algorith-bot-withForm-development .contact-details {
    border-top: 1px solid #ddd;
    padding: 10px;
    padding-top: 0;
    margin-top: 0px;
  }
  .algorith-bot-withForm-development .contact-details form .contact-submit-btn {
    margin: 30px 20px 0px 20px;
    text-align: center;
  }
  .algorith-bot-withForm-development .custom-section .main-head-1 .primary-span,
  .algorith-bot-withForm-development .custom-section .main-head-1 .secondary-span,
  .algorith-bot-withForm-development .custom-section .testimonials.crypto-blog-slider .custom-container .principles-common-ul .principles-info-hold h4 {
    font-size: 24px;
    line-height: 30px;
  }
  .algorith-bot-withForm-development .vedio-explanation .video-file {
    width: 100%;
  }
  .algorith-bot-withForm-development .contact-details .main-head-1 .secondary-span {
    line-height: 20px;
    margin-top: 30px;
    margin-bottom: 40px;
  }
  .algorith-bot-withForm-development .professionals-hold {
    margin-bottom: 20px;
  }
  .algorith-bot-withForm-development .crypto-banner-otc {
    padding-bottom: 0;
    background-position: center;
  }
  .algorith-bot-withForm-development .vedio-explanation .main-head-div {
    margin-bottom: 30px;
  }
  .algorith-bot-withForm-development .crypto-banner-otc .custom-section .main-head-1 .primary-span br {
    display: none;
  }
  .algorith-bot-withForm-development .testimonials.crypto-blog-slider ul.crypt-blog-ul.principles-common-ul li.crypt-blog-li .blog-colunm-card {
    margin: 10px 0;
  }
}
@media only screen and (max-width: 425px) and (min-width: 320px) {
  .algorith-bot-withForm-development .lets-connect-form.responsive-mode {
    flex-flow: row wrap;
  }
  .algorith-bot-withForm-development .lets-connect-form.responsive-mode .heading.main-para-div {
    margin-bottom: 0;
  }
  .algorith-bot-withForm-development .lets-connect-form.responsive-mode .heading.main-para-div .secondary-span {
    font-size: 20px;
    line-height: 30px;
  }
  .algorith-bot-withForm-development .lets-connect-form.responsive-mode .heading.main-para-div .primary-span {
    font-size: 30px;
    margin-top: 0;
  }
  .algorith-bot-withForm-development .lets-connect-form.responsive-mode .contact-modal {
    display: block;
    width: 100%;
    margin: 0;
  }
  .algorith-bot-withForm-development .lets-connect-form.responsive-mode .contact-modal .form-group.w-30,
  .algorith-bot-withForm-development .lets-connect-form.responsive-mode .contact-modal .form-group.w-70 {
    width: 100%!important;
  }
  .algorith-bot-withForm-development .crypto-banner-otc.white-bg .professionals-hold .professionals-ul li button {
    font-size: 16px;
  }
  .algorith-bot-withForm-development .crypto-banner-otc,
  .algorith-bot-withForm-development .vedio-explanation,
  .algorith-bot-withForm-development .custom-section.offer-crypto,
  .algorith-bot-withForm-development .testimonials.crypto-blog-slider {
    padding-left: 20px ;
  }
  .algorith-bot-withForm-development .contact-details .contact-modal span.secondary-span {
    top: 80px;
  }
}
@media only screen and (max-width: 375px) and (min-width: 320px) {
  .algorith-bot-withForm-development .contact-details .contact-modal span.secondary-span {
    top: 73px;
  }
}
@media only screen and (max-width: 349px) {
  .algorith-bot-withForm-development .contact-details .contact-modal span.secondary-span {
    top: 98px;
  }
  .algorith-bot-withForm-development .crypto-banner-otc.white-bg .professionals-hold .professionals-ul li .main-para-div,
  .algorith-bot-withForm-development .crypto-banner-otc.white-bg .professionals-hold .professionals-ul li .main-para-div button {
    width: 100%;
    min-width: 100%;
  }
}
