.news-heading {
  background: #1E4B5E;
  padding: 30px;
  text-align: center;
  color: #fff;
  position: relative;
  margin-bottom: 100px;
  width: 100vw;
  left: 0%;
  right: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.news-heading em.new-category {
  font-style: normal;
  font-size: 24px;
  border: 1px solid #fff;
  line-height: 20px;
  color: #fff;
  border-radius: 112px;
  padding: 15px 24px;
  display: inline-block;
}
.news-heading em.new-category.healthtech {
  background: #33C0A7;
}
.news-heading em.new-category.web3 {
  background: #F05DEA;
}
.newdesigned_issue_details.issue_83_design.issue-85 .new-detail-option .news-first-section .heading h5 {
  text-transform: none;
}
.newdesigned_issue_details.issue_83_design .new-detail-option .news-first-section .heading h5 {
  font-size: 54px;
  line-height: 76px;
}
.newdesigned_issue_details.issue_83_design .news-first-section:nth-child(5) .heading h5 {
  line-height: 60px;
  font-size: 40px;
}
.news-heading .main-head-1 h4 span {
  font-size: 54px;
  line-height: 60px;
  padding: 60px 0 30px 0;
  color: #fff;
  text-transform: uppercase;
  font-family: 'Object Sans';
  font-weight: 600;
}
.news-heading .main-head-1 p {
  font-size: 30px;
  line-height: 43px;
  padding: 10px 0;
  width: 65%;
  margin: 30px auto;
}
.news-heading .selected-news-heading {
  font-size: 24px;
  border: 1px solid #fff;
  line-height: 20px;
  color: #fff;
  border-radius: 112px;
  padding: 15px 24px;
  width: auto;
  left: initial;
  right: initial;
  margin: auto;
  bottom: -25px;
  text-align: center;
  display: inline-flex;
  position: absolute;
  align-items: center;
  justify-content: center;
}
.subscribe-button .err-msg {
  font-size: 14px;
  margin: 5px 0 20px 0;
}
.to-newsfeed {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 15px;
  padding: 25px;
  font-family: "Object Sans";
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.newsissuedetailed .news-letter.whats-on-hold h4,
.newsissuedetailed .news-letter.top-picks-hold h4,
.newsissuedetailed .news-letter.other-reads-hold h4 {
  background: #1E4B5E;
  border-radius: 100px;
  color: #fff;
  font-size: 18px;
  padding: 12px;
  width: auto;
  font-family: 'Object Sans';
  text-align: center;
  display: inline-flex;
  line-height: 22px;
}
.newsissuedetailed .news-letter.whats-on-hold .issue-main-info .issue-sub-head a h4,
.newsissuedetailed .news-letter.top-picks-hold .issue-main-info .issue-sub-head a h4,
.newsissuedetailed .news-letter.other-reads-hold .issue-main-info .issue-sub-head a h4 {
  width: 100%;
  display: block;
  background: transparent;
  color: #1E4B5E;
  text-align: left;
  padding: 0;
  line-height: 32px;
  font-size: 16px;
  font-family: 'Object Sans';
  font-weight: 600;
}
.newdesigned_issue_details .sticky-button.banner-modal-button {
  background: #839ca76b;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
  border-radius: 0 13px 13px 0;
  padding: 12px;
  z-index: 1;
  width: 200px;
  position: absolute;
  left: 0;
  top: 525px;
}
.newdesigned_issue_details .sticky-button.banner-modal-button button {
  font-size: 14px;
  color: #000A4F;
  background: transparent;
  border: 0;
}
.organized_by {
  background: #FFFFFF;
  box-shadow: 0 0 30px #ddd;
  border-radius: 0 13px 13px 0;
  padding: 10px 20px;
  z-index: 1;
  width: 200px;
  position: absolute;
  left: 0;
  top: 645px;
  font-size: 14px;
  transition: ease 0.5s;
  cursor: pointer;
}
.organized_by:hover {
  box-shadow: 0 0 50px #baafaf;
}
.organized_by .news-sticky {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.organized_by .news-sticky .left-text {
  flex-direction: column;
  display: inline-flex;
}
.organized_by .news-sticky .left-text span {
  background: #007CC1;
  border-radius: 39px;
  border: 0;
  color: #fff;
  font-size: 14px;
  padding: 5px 15px;
  width: fit-content;
}
.organized_by .news-sticky .news-pic {
  width: 70px;
  height: 55px;
  overflow: hidden;
  object-fit: contain;
  border-radius: 100%;
  display: inline-flex;
}
.organized_by .news-sticky .news-pic img {
  width: 100%;
  height: 100%;
}
.organized_by.sticky.left-sticky {
  position: fixed;
  top: 160px;
}
.newsletter-modal.height-changes .banner-section {
  height: 410px;
}
.newsletter-modal .modal-dialog {
  max-width: 700px;
  width: 700px;
}
.newsletter-modal .modal-dialog .modal-content {
  border-radius: 7px;
}
.newsletter-modal .modal-dialog .modal-content .modal-header {
  padding: 0;
  border-top-left-radius: 3px;
  border-bottom: 0;
}
.newsletter-modal .banner-section {
  display: flex;
}
.newsletter-modal .banner-section .banner-left {
  width: 50%;
  display: inline-flex;
}
.newsletter-modal .banner-section .banner-left img {
  width: 100%;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}
.newsletter-modal .banner-section .banner-right {
  width: 50%;
  padding: 30px;
  position: relative;
}
.newsletter-modal .banner-section .banner-right p {
  font-size: 14px;
}
.newsletter-modal .banner-section .banner-right span {
  font-weight: 800;
  margin-bottom: 10px;
  display: block;
}
.newsletter-modal .banner-section .banner-right strong {
  width: 100%;
  display: flex;
  margin-top: 20px;
}
.newsletter-modal .banner-section .banner-right a.read-more {
  border: 3px solid #007CC1;
  border-radius: 30px;
  padding: 12px 35px;
  position: absolute;
  left: 30px;
  bottom: 30px;
  font-size: 16px;
  font-weight: 400;
  background: #007CC1;
  color: #ffffff;
}
.newsletter-modal button.close {
  position: absolute;
  background-image: url(/assets/img/header/closeOrange.svg);
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: center;
  width: 30px;
  height: 30px;
  padding: 0;
  border-radius: 35px;
  opacity: 1;
  top: 2px;
  right: 3px;
  background-color: #fff;
}
.newsletter-modal button.close:hover,
.newsletter-modal button.close:focus {
  opacity: 1!important;
}
.newsletter-modal button.close span {
  visibility: hidden;
}
.newsissuedetailed .news-letter.whats-on-hold .issue-news-info {
  font-size: 16px;
  color: #1E4B5E;
  line-height: 26px;
}
.to-newsfeed img {
  margin-right: 5px;
}
.to-newsfeed:hover {
  text-decoration: none;
  color: #fff;
}
.news-letter {
  background: #FFF7E4;
  box-shadow: 0px 4px 4px rgba(191, 191, 191, 0.25);
  border-radius: 26px;
  padding: 35px 25px;
  margin: 80px 0;
  position: relative;
}
.news-letter:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 1px;
  background: #1E4B5E;
  bottom: -40px;
}
.news-letter:last-child:after {
  background: none;
}
.inspired-newsletter button {
  background: #007CC1;
  border-radius: 39px;
  border: 0;
  color: #fff;
  font-size: 14px;
  padding: 5px 15px;
}
.inspired-newsletter ul {
  list-style: none;
  display: flex;
  margin-top: 14px;
  padding: 0;
  margin-bottom: 0;
}
.inspired-newsletter ul li {
  padding: 5px 10px 0 0px;
}
.inspired-newsletter p {
  font-size: 14px;
  color: #000A4F;
}
.inspired-newsletter {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
  border-radius: 13px;
  padding: 20px;
  z-index: 1;
  width: 200px;
  position: absolute;
  right: 2%;
  top: 635px;
}
.inspired-newsletter.sticky {
  position: fixed;
  top: 170px;
  right: 27px;
}
.news-heading .selected-news-heading.web3 {
  background: #F05DEA;
}
.news-heading .fintech.selected-news-heading {
  background: #3E8BFF;
}
.news-heading .design.selected-news-heading {
  background: #F45757;
}
.news-heading .selected-news-heading.healthtech {
  background: #33C0A7;
}
.inspired-newsletter .subscribe-our-news .form-group.newsletter-form-group {
  width: 100%;
  margin: 10px 0;
  position: unset;
}
.inspired-newsletter .subscribe-our-news .form-group.newsletter-form-group input.form-control {
  font-size: 12px;
  width: 130px;
  padding: 10px;
  margin-left: 0px;
}
.inspired-newsletter .subscribe-our-news .form-group.newsletter-form-group .newsletter-arrow button {
  font-size: 12px;
  padding: 11px;
}
.inspired-newsletter .subscribe-our-news .form-group.newsletter-form-group .newsletter-arrow button img {
  position: unset;
  width: 14px;
  height: 14px;
  transform: rotate(180deg);
}
.inspired-newsletter .subscribe-our-news {
  width: 100%;
  background: none;
  left: 0;
}
.inspired-newsletter .subscribe-our-news .err-msg {
  font-size: 13px;
  text-align: left;
  padding: 0;
  line-height: 15px;
  width: 100%;
}
.subscribe-our-news h4 {
  font-size: 32px;
  line-height: 44px;
  font-weight: 800;
}
.subscribe-our-news p {
  font-size: 14px;
}
.subscribe-our-news {
  width: 85vw;
  left: 0%;
  position: relative;
  right: 0;
  background-image: url(/assets/newsletter_images/subscribe-cta-wrapper.svg);
  margin: auto;
  background-repeat: no-repeat;
  background-size: cover;
}
.subscribe-our-news .subscribe-button {
  color: #1E4B5E;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 16px;
  margin: 50px auto;
  width: 100%;
  padding: 50px;
}
.newsissuedetailed {
  width: 65%;
  margin: 100px auto 0px;
}
.subscribe-our-news img {
  position: absolute;
  right: 50px;
  top: 0;
  width: 300px;
  bottom: 0;
  margin: auto;
}
.subscribe-our-news .form-group.newsletter-form-group {
  display: flex;
  align-items: center;
  width: 300px;
  justify-content: center;
  margin: 40px 0 0 0;
}
.subscribe-our-news .form-group.newsletter-form-group input {
  border: 1px solid #DEDEDE;
  font-size: 14px;
  border-right: 0;
  padding: 10px;
  outline: none;
  height: auto;
  box-shadow: none;
}
.subscribe-our-news .form-group.newsletter-form-group .newsletter-arrow button {
  background: #007CC1;
  border: 1px solid #DEDEDE;
  border-radius: 0px 8px 8px 0px;
  font-size: 14px;
  padding: 10px;
  color: #fff;
}
.subscribe-our-news ul {
  list-style: none;
  display: flex;
  align-items: center;
  padding: 0;
  margin-top: 20px;
}
.subscribe-our-news ul li svg {
  width: 40px;
  height: 40px;
}
.subscribe-our-news ul li {
  margin-right: 15px;
}
.Header .right-menu a.connect-btn,
.Resources .section-1 .custom-pill-links-section .right-menu a.connect-btn {
  min-width: 165px;
}
.mob-news-letter {
  display: none;
}
div.top-subscribe div.subscribe-our-news {
  width: 100%;
  left: 0;
  z-index: 3;
  background: transparent;
  padding-top: 5px;
}
div.top-subscribe div.subscribe-our-news .form-group.newsletter-form-group {
  width: 95%;
  margin: 10px 0;
  position: unset;
  background: #FFFFFF;
  border-top-right-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
}
div.top-subscribe div.subscribe-our-news .form-group.newsletter-form-group input.form-control {
  font-size: 12px;
  width: 180px;
  padding: 10px;
  margin-left: 15px;
}
div.top-subscribe div.subscribe-our-news .err-msg {
  position: absolute;
  top: 5px;
  font-size: 12px;
}
div.top-subscribe div.subscribe-our-news div.newsletter-arrow button {
  font-size: 12px;
  padding: 11px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
div.top-subscribe div.subscribe-our-news div.newsletter-arrow button img {
  position: unset;
  width: 14px;
  height: 14px;
  transform: rotate(180deg);
}
.list-style {
  list-style: none;
  padding: 0;
}
.newdesigned_issue_details.issue-86 .new-detail-option .news-first-section .heading h5 {
  font-size: 52px;
}
@media only screen and (max-width: 1024px) and (min-width: 320px) {
  .newdesigned_issue_details.issue-86 .new-detail-option .news-first-section .heading h5 {
    font-size: 45px;
    line-height: 57px;
  }
}
@media only screen and (max-width: 991px) and (min-width: 320px) {
  .newdesigned_issue_details .banner-section .banner-left img {
    border-radius: 5px 5px 0 0;
  }
  .newdesigned_issue_details .headlines-warp .headlines-content {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 767px) and (min-width: 320px) {
  .news-heading .main-head-1 h4 span {
    font-size: 36px;
    line-height: 48px;
    margin: 10px 0 40px 0;
    padding-bottom: 0;
  }
  .news-heading em.new-category {
    font-size: 20px;
    padding: 12px 24px;
  }
  .newdesigned_issue_details .new-detail-option .news-first-section .newsissuedetailed p,
  .newdesigned_issue_details .headlines-warp .top-news span,
  .newdesigned_issue_details .headlines-warp .headlines-content .headlines-list p {
    text-align: justify;
  }
  .news-heading .main-head-1 p {
    width: 90%;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 10px;
  }
  .inspired-newsletter {
    position: unset;
    margin-bottom: 30px;
    width: 90%;
    margin: 0 auto 30px auto;
  }
  .inspired-newsletter .subscribe-our-news .form-group.newsletter-form-group {
    left: 0;
    width: 100%;
    left: -17px;
  }
  .news-heading {
    flex-flow: row wrap;
  }
  a.to-newsfeed {
    top: 0px;
  }
  .news-heading .selected-news-heading {
    font-size: 16px;
    width: max-content;
    padding: 9px;
    bottom: -20px;
  }
  .newsissuedetailed .news-letter.whats-on-hold h4,
  .newsissuedetailed .news-letter.top-picks-hold h4,
  .newsissuedetailed .news-letter.other-reads-hold h4 {
    font-size: 16px !important;
    width: max-content;
    padding: 10px 15px;
  }
  .newsissuedetailed {
    width: 90%;
  }
  .newsissuedetailed .news-letter.whats-on-hold .issue-main-info .issue-sub-head a h4,
  .newsissuedetailed .news-letter.top-picks-hold .issue-main-info .issue-sub-head a h4,
  .newsissuedetailed .news-letter.other-reads-hold .issue-main-info .issue-sub-head a h4 {
    font-size: 14px!important;
    line-height: 22px;
  }
  .subscribe-our-news img {
    width: 100%;
    z-index: 0;
    opacity: 0.5;
    left: 0;
    right: 0;
  }
  .subscribe-our-news p {
    z-index: 6;
    position: relative;
    font-size: 12px;
  }
  .subscribe-our-news h4 {
    font-size: 26px;
    line-height: 32px;
  }
  .subscribe-our-news .form-group.newsletter-form-group {
    width: 100%;
    position: relative;
    z-index: 9;
  }
  .subscribe-our-news .subscribe-button {
    padding: 25px;
  }
  .news-letter {
    padding: 25px;
  }
  .inspired-newsletter .subscribe-our-news .form-group.newsletter-form-group input.form-control {
    width: 100%;
    margin-left: 0;
  }
  .inspired-newsletter.web-news-letter {
    display: none;
  }
  .mob-news-letter {
    display: block;
  }
  .inspired-newsletter.mob-news-letter ul {
    justify-content: center;
  }
  .inspired-newsletter.mob-news-letter ul li {
    padding: 0 10px;
  }
  .newdesigned_issue_details .banner-section {
    width: 85%;
    margin: 20px auto;
  }
  .newsissuedetailed {
    margin: 0 auto;
  }
  .newdesigned_issue_details .headlines-warp .headlines-content .headlines-list a {
    font-size: 15px;
  }
  .newdesigned_issue_details .headlines-warp .headlines-content .headlines-list:nth-of-type(1)::before {
    top: 5px;
  }
  .newdesigned_issue_details .headlines-warp .top-news a {
    font-size: 15px;
    border-radius: 7px;
    margin-bottom: 30px;
  }
  .newdesigned_issue_details .new-detail-option .news-first-section .newsissuedetailed .news-right ul li {
    padding-left: 50px;
  }
  .newdesigned_issue_details .new-detail-option .news-first-section .newsissuedetailed .news-right ul li span {
    font-size: 46px;
    line-height: 46px;
  }
  .newdesigned_issue_details .new-detail-option .news-first-section .newsissuedetailed .news-right ul li p {
    font-size: 14px;
    text-align: justify;
  }
  .newdesigned_issue_details .new-detail-option .news-first-section .newsissuedetailed .news-right ul li b {
    font-size: 16px;
  }
  ul.stroke-list.list-style b {
    font-size: 15px;
  }
  .newdesigned_issue_details .new-detail-option .news-first-section:nth-of-type(5) .newsissuedetailed .newissuedetailed_banner ul.stroke-list.list-style p {
    padding: 10px 0 30px;
  }
  .newdesigned_issue_details .new-detail-option .news-first-section:nth-of-type(5) .newsissuedetailed .newissuedetailed_banner p {
    padding: 10px 0 30px;
  }
  .newdesigned_issue_details .events-wrap {
    width: 85%;
  }
  .newsissuedetailed {
    width: 85%;
  }
  .newdesigned_issue_details.issue-86 .new-detail-option .news-first-section .heading h5 {
    font-size: 30px;
    line-height: 45px;
  }
  .newdesigned_issue_details .sticky-button.banner-modal-button {
    position: unset;
    margin: 55px auto 0 auto;
    border-radius: 5px;
  }
  .newsletter-modal .modal-dialog {
    max-width: 300px;
    width: 300px;
    margin: 0 auto;
  }
  .newsletter-modal .modal-dialog .banner-section {
    flex-flow: row wrap;
    height: unset;
  }
  .newsletter-modal .modal-dialog .banner-section .banner-left {
    width: 100%;
  }
  .newsletter-modal .modal-dialog .banner-section .banner-left img {
    height: 200px;
    border-radius: 5px 5px 0 0;
  }
  .newsletter-modal .modal-dialog .banner-section .banner-right {
    padding: 16px;
    width: 100%;
    overflow: auto;
    min-height: 300px;
    height: 300px;
    position: unset;
    padding-bottom: 50px;
  }
  .newsletter-modal .modal-dialog .banner-section .banner-right .read-more {
    padding: 7px 20px;
    font-size: 13px;
    left: 0;
    right: 0;
    margin: auto;
    width: 135px;
  }
  .organized_by,
  .organized_by.sticky.left-sticky {
    left: 0;
    right: 0;
    top: 120%;
    margin: auto;
    border-radius: 13px;
    position: absolute;
    text-align: center;
  }
  .newsissuedetailed {
    margin-top: 15%;
  }
}
@media only screen and (max-width: 567px) and (min-width: 320px) {
  .newsissuedetailed {
    margin-top: 30%;
  }
}
@media only screen and (max-width: 435px) and (min-width: 280px) {
  .organized_by,
  .organized_by.sticky.left-sticky {
    top: 810px;
  }
}
.social-media {
  position: absolute;
  right: 30px;
  margin: 0;
  padding: 0;
  background: #ffffff;
  border-radius: 30px;
  padding: 15px 10px;
  text-align: center;
  top: 470px;
  z-index: 1;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
}
.social-media li {
  list-style-type: none;
  margin-bottom: 15px;
  cursor: pointer;
}
.social-media li:last-child {
  margin-bottom: 0px;
}
.social-media.sticky {
  position: fixed;
  top: 150px;
  right: 30px;
}
.top-subscribe {
  position: absolute;
  right: 60px;
  top: 450px;
  z-index: 1;
  visibility: hidden;
}
.top-subscribe .subscribe-input {
  width: 100%;
}
.top-subscribe .subscribe-input span.stay {
  display: none;
  z-index: 2;
  font-size: 14px;
  color: #000A4F;
}
.top-subscribe .subscribe-input form {
  width: 100%;
}
.top-subscribe .subscribe-input input {
  border: 1px solid #007CC1;
  background: transparent;
  font-size: 14px;
  color: #000A4F;
  width: 100%;
  border-right: 0px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.top-subscribe .subscribe-input input:focus {
  outline: none;
}
.top-subscribe .subscribe-input button {
  background: #007CC1;
  color: #ffffff;
  font-size: 12px;
  border: 0px;
  border-radius: 30px;
  padding: 8px 12px;
}
.top-subscribe .subscribe-input .subscribe-hover {
  right: 0;
  background: #ECECEC;
  border-radius: 30px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
}
.top-subscribe .subscribe-input .subscribe-hover button {
  display: inline-flex;
  align-items: flex-end;
}
.top-subscribe .subscribe-input .subscribe-hover:hover span.stay {
  display: inline-flex;
  justify-content: flex-start;
  font-size: 13px;
  margin: 0px 10px;
  color: #000A4F;
}
.top-subscribe.sticky {
  position: fixed;
  top: 390px;
  right: 5px;
  visibility: visible;
}
.top-subscribe.sticky .subscribe-input {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background: #ffffff;
  padding: 2px;
  border-radius: 15px;
}
.top-subscribe.sticky .subscribe-input .subscribe-our-news .err-msg {
  top: -15px;
  left: 15px;
}
