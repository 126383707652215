.testimonials .testi-detailed-review a {
  font-family: 'object sans heavy';
  margin-bottom: 20px;
}
.slick-slider .slick-dots {
  bottom: -35px;
}
.testimonials.new-testi .section-4.MarketPlace {
  padding-top: 0px!important;
  padding-bottom: 0px!important;
}
.new-testi .testi-detailed-review {
  margin: 20px 0px;
  width: 150px;
  justify-content: flex-end;
  display: inline-flex;
}
.new-testi .testi-detailed-review a {
  margin-bottom: 0px;
}
.new-testi .testi-comp-info {
  padding-left: 10px;
}
.new-testi .testi-comp-info span {
  min-height: 50px;
  display: flex;
}
.new-testi .testi-comp-info span.role {
  min-height: auto;
}
.testimonials.new-testi .main-head-div {
  margin-bottom: 25px;
}
.testimonials.new-testi .main-head-div .main-head-1 .primary-span {
  color: #FFFFFF;
}
.mp-common-hold .more-text {
  margin-top: 50px!important;
  margin-left: 25px !important;
}
.testimonials.new-testi .testi-col .testi-card {
  padding: 30px 0px;
  box-shadow: 0px 6px 30px rgba(45, 44, 85, 0.1);
  border-radius: 30px;
  height: auto !important;
}
.testimonials.new-testi .testi-col .testi-card .testi-client-head {
  background: #FFCC4A;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  text-align: center;
}
.testimonials.new-testi .testi-col .testi-card .testi-client-head b {
  font-size: 26px;
  color: #1E4B5E;
  font-family: "Object Sans Heavy";
  display: inline-flex;
  justify-content: center;
  width: auto;
  margin-left: 10px;
}
.testimonials.new-testi .testi-col .testi-card .testi-client-head .testi-comp-logo {
  display: inline-flex;
  justify-content: flex-start;
}
.testimonials.new-testi .testi-col .testi-card .testi-review-common {
  min-height: auto !important;
  padding: 30px 40px !important;
}
.testimonials.new-testi .testi-col .testi-card .testi-review-common .testi-client-review {
  min-height: 120px !important;
  max-height: inherit !important;
}
.testimonials.new-testi .testi-col .testi-card .testi-footer {
  background: transparent;
  justify-content: space-between;
  min-height: auto !important;
  max-height: inherit !important;
  padding: 0px 30px;
  align-items: flex-end;
}
.testimonials.new-testi .testi-col .testi-card .testi-client-review:before {
  display: none;
}
@media only screen and (max-width: 2560px) and (min-width: 1440px) {
  .testi-card {
    height: 454px!important;
  }
  .testi-client-review p {
    font-size: 16px!important;
  }
  .testi-detailed-review {
    margin-bottom: 0px!important;
    margin-top: 10px!important;
  }
  .testi-review-common {
    padding: 20px 60px!important;
    min-height: 230px!important;
  }
  .testi-client-review {
    min-height: 146px!important;
    max-height: 146px!important;
  }
  .testi-card .testi-footer {
    min-height: 140px!important;
    max-height: 140px!important;
  }
  .testi-comp-info p:first-child {
    font-size: 26px!important;
    line-height: 30px!important;
  }
  .testi-comp-logo {
    width: 70px!important;
    height: 70px!important;
    min-width: 70px!important;
  }
  .testi-comp-info p:last-child {
    font-size: 16px!important;
  }
  .mp-common-hold .more-text {
    margin-top: 20px!important;
  }
}
@media only screen and (max-width: 1439px) and (min-width: 1281px) {
  .testi-card {
    height: 500px!important;
  }
  .testi-card .testi-client-review p {
    font-size: 16px!important;
  }
  .testi-detailed-review {
    margin-bottom: 0px!important;
    margin-top: 0px!important;
  }
  .testi-card .testi-footer {
    min-height: 138px!important;
    max-height: 138px!important;
  }
  .testi-card .testi-comp-info p:first-child {
    font-size: 24px!important;
    line-height: 28px!important;
  }
  .testi-card .testi-client-review {
    margin-bottom: 10px;
    min-height: 132px;
    max-height: 132px;
  }
  .testi-card .testi-review-common {
    min-height: 233px!important;
  }
  .testi-comp-logo {
    width: 70px!important;
    height: 70px!important;
    min-width: 70px!important;
  }
  .testi-comp-info p:last-child {
    font-size: 16px!important;
  }
  .testimonials .main-head-div {
    margin-bottom: 10px;
  }
  .mp-common-hold .more-text {
    margin-top: 20px!important;
  }
  .testi-card {
    height: 440px!important;
  }
}
@media only screen and (max-width: 1280px) and (min-width: 1024px) {
  .testi-card {
    height: 420px!important;
    min-height: 420px!important;
  }
  .testi-card .testi-client-review p {
    font-size: 14px!important;
    line-height: 22px!important;
  }
  .testi-detailed-review {
    margin-bottom: 0px!important;
    margin-top: 0px!important;
  }
  .testi-card .testi-footer {
    min-height: 120px!important;
    max-height: 120px!important;
  }
  .testi-card .testi-comp-info p:first-child {
    font-size: 20px!important;
    line-height: 24px!important;
  }
  .testi-card .testi-client-review {
    margin-bottom: 10px;
    min-height: 130px;
    max-height: 130px;
  }
  .testi-card .testi-review-common {
    min-height: 213px;
  }
  .testi-comp-logo {
    width: 60px!important;
    height: 60px!important;
    min-width: 60px!important;
  }
  .testi-comp-info p:last-child {
    font-size: 16px!important;
  }
  .testi-client-review p {
    -webkit-line-clamp: 8!important;
  }
  .testi-client-head p {
    font-size: 14px;
  }
  .testi-detailed-review a {
    font-size: 14px!important;
  }
  .mp-common-hold .more-text {
    margin-top: 20px!important;
  }
  .testi-card .testi-client-review:before {
    background-size: 50px;
    background-position: center;
    width: 55px;
    height: 55px;
  }
  .testimonials .main-head-div {
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 1097px) and (min-width: 1068px) {
  .card.testi-card {
    margin: 0 auto!important;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .testi-card {
    width: 415px!important;
    min-width: 415px!important;
  }
  .testi-card .testi-comp-info p:last-child {
    font-style: normal;
    font-size: 14px;
  }
}
@media only screen and (max-width: 767px) and (min-width: 576px) {
  .testi-card .testi-client-review {
    min-height: 236px!important;
    max-height: 236px!important;
  }
  .testi-col .testi-card {
    max-height: 100%;
    min-height: 100%;
  }
  .testi-card .testi-comp-info p:last-child {
    font-style: normal;
    font-size: 14px;
  }
  .testi-col .testi-card .testi-client-head p {
    font-size: 14px;
  }
}
@media only screen and (max-width: 575px) and (min-width: 375px) {
  .testi-col .testi-card .testi-client-review {
    min-height: 150px!important;
    max-height: 150px!important;
  }
  .testi-col .card.testi-card .testi-client-head p {
    font-size: 14px!important;
  }
  .testi-col .card.testi-card .testi-client-review p {
    font-size: 14px!important;
  }
  .testi-col .testi-card .testi-detailed-review {
    margin-bottom: 0px!important;
  }
  .testi-col .testi-card {
    max-height: 100%;
    min-height: 100%;
    min-width: 100%;
    max-width: 100%;
  }
  .testi-col .testi-card .testi-review-common {
    padding: 30px 30px!important;
  }
  .testi-card .testi-comp-info p:last-child {
    font-style: normal;
    font-size: 14px;
  }
  .testi-client-review:before {
    left: -10px !important;
  }
}
@media only screen and (max-width: 992px) {
  .testi-card {
    min-height: 455px;
    height: 455px;
  }
  .testi-review-common {
    min-height: 215px!important;
  }
}
@media only screen and (max-width: 992px) and (min-width: 425px) {
  .testi-card .testi-client-head p {
    font-size: 14px!important;
  }
  .testi-card .testi-client-review p {
    font-size: 14px!important;
  }
  .mp-testimonials .testi-col .testi-card .testi-client-review {
    margin-bottom: 10px;
    min-height: 166px!important;
    max-height: 166px!important;
  }
  .testi-card .testi-detailed-review {
    margin-top: 10px;
    margin-bottom: 0px;
  }
  .testi-col .testi-card {
    min-height: 460px;
    height: 460px;
  }
  .mp-testimonials .slick-dots {
    display: none!important;
  }
  .mp-testimonials .testi-col {
    margin-bottom: 0px!important;
  }
  .mp-common-hold .more-text {
    margin-top: 5px!important;
  }
  .testi-col .testi-card .testi-comp-logo {
    width: 70px;
    height: 70px;
    min-width: 70px;
  }
  .testi-col .testi-card .testi-footer {
    min-height: 141px!important;
    max-height: 141px!important;
  }
}
@media only screen and (max-width: 768px) {
  .testi-card {
    min-height: 515px;
    height: 515px;
  }
}
@media only screen and (max-width: 576px) {
  .testi-client-review {
    min-height: 180px!important;
    max-height: 180px!important;
  }
  .testimonials .testi-card {
    height: 100%!important;
  }
}
@media only screen and (max-width: 440px) and (min-width: 425px) {
  .testi-col .testi-card .testi-client-review {
    min-height: 150px!important;
    max-height: 150px!important;
  }
  .testi-col .testi-card .testi-detailed-review a {
    font-size: 16px!important;
  }
  .testi-col .testi-card .testi-client-head {
    min-height: 100px!important;
  }
}
@media only screen and (max-width: 425px) and (min-width: 320px) {
  .tab-content .Resources .section-2 .in-news-common .news-cards-common ul li .in-news-card.card {
    margin-top: 20px!important;
    margin-bottom: 35px!important;
  }
  .testi-col .testi-card .testi-comp-logo {
    width: 50px;
    height: 50px;
    min-width: 50px;
  }
  .testimonials .main-head-div {
    margin-bottom: 10px;
  }
  .testi-col .testi-card .testi-comp-info p:first-child {
    font-size: 20px;
    line-height: 26px;
  }
  .testi-col .testi-card .testi-footer {
    min-height: 131px!important;
    max-height: 131px!important;
  }
}
@media only screen and (max-width: 375px) {
  .mp-testimonials .testi-col .card.testi-card .testi-client-review p {
    font-size: 13px!important;
    line-height: 21px!important;
  }
}
@media only screen and (max-width: 320px) {
  .mp-testimonials .testi-col .card.testi-card .testi-client-head p {
    font-size: 12px!important;
  }
  .mp-testimonials .testi-col .card.testi-card .testi-client-head {
    padding: 18px 15px;
    min-height: 90px;
  }
  .mp-testimonials .testi-col .testi-card .testi-comp-info p:first-child {
    font-size: 14px;
    line-height: 20px;
  }
  .mp-testimonials .testi-comp-info p:last-child {
    font-size: 14px!important;
    line-height: 18px!important;
  }
}
@media only screen and (max-width: 424px) and (min-width: 320px) {
  .testi-col .testi-card .testi-client-review {
    min-height: 150px!important;
    max-height: 150px!important;
  }
  .testi-col .testi-card .testi-detailed-review a {
    font-size: 14px!important;
  }
  .testi-comp-info p:first-child {
    font-size: 16px;
    line-height: 20px;
  }
  .testi-comp-info p:last-child {
    font-size: 14px!important;
    line-height: 20px!important;
  }
  .testi-col .card.testi-card .testi-client-review p {
    font-size: 14px!important;
  }
  .testi-col .card.testi-card .testi-client-head p {
    font-size: 14px!important;
  }
  .testi-client-review:before {
    left: -10px !important;
  }
}
