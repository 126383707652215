.display-block {
  display: block !important;
}
.company-info.section-4 {
  background-image: url(/assets/img/hero-images/comp-inf-bg-2x.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  padding: 150px 0px;
}
video.hero-bg {
  position: absolute;
  top: 0;
  z-index: 0;
  width: 100%;
}
video.hero-bg {
  position: absolute;
  top: 0;
  z-index: 0;
  width: 100%;
}
.link-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.read-more-link-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  font-weight: 800;
  flex-wrap: wrap;
}

@media (max-width: 1024px) {
  .read-more-link-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    font-weight: 800;
    flex-wrap: wrap;
  }
}

@media (max-width: 600px) {
  .read-more-link-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-weight: 800;
  }

  .read-more-link-container > * {
    margin-right: 5px;
    margin-bottom: 0; 
  }

  .read-more-link-container > *:last-child {
    margin-right: 0;
  }
}



.connect-btn {
  margin: 5px;
}

@media (max-width: 600px) {
  .link-container {
    flex-direction: column;
  }
}

.section-5 {
  padding: 40px 0;
}

.custom-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.main-head-div {
  text-align: left;
}

.primary-span {
  color: #007CC1;
}


.blog-card-container .slick-slide {
  padding: 5px 15px;
}

.blog-card-container .slick-list {
  margin: 0 2em;
}

.secondary-span {
  color: #333;
}

.partner-list {
  list-style: none;
  padding: 0;
}

.service-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 20px;
  background-color: #fff;
}

.card-content {
  text-align: left;
}

.card-content strong {
  display: block;
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #333;
}

.card-content span {
  display: block;
  color: #666;
  font-size: 0.95rem;
  margin-bottom: 15px;
}

.learn-more-btn {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007CC1;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 0.9rem;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.learn-more-btn:hover {
  background-color: #005D8C;
}

.blog-card-container {
  width: 80%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.blog-card {
  width: 100%;
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: space-between;
  align-items: left;
  height: 300px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  text-align: left;
  min-height: 700px;
  max-height: auto;
  width: calc(100% - 20px);
  box-sizing: border-box; 
  transition: background-color 0.3s ease;
}

.blog-span {
  color: #1E4B5E;
  font-size: 18px;
  background-color: rgb(237, 232, 232);
  padding: 10px; 
  text-align: center;
  border-radius: 4px;
  margin-bottom: 10px;
  margin-top: 10px;
  display: block;
  height: 70px;
}

.blog-sub-title-span {
  color: #333;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-top: 5px;
  padding: 10px;
  background-color: #fff;
  display: flex;
    align-content: stretch;
    flex-wrap: wrap;
    height: auto;
}

.blog-read-more-link {
  height: 30px;
  padding-top: 10px;
  text-decoration: underline;
  color: #007CC1;
}

@media (max-width: 1024px) {
  .blog-card {
    width: 48%;
    margin: 1%;
    min-height: 800px;
    max-height: auto;
  }
}

@media (max-width: 768px) {
  .blog-card {
    width: 98%;
    margin: 1%;
    min-height: 630px;
    max-height: auto;
    padding: 5px;
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .blog-read-more-link {
    height: 30px;
    padding-top: 40px;
    text-decoration: underline;
    color: #007CC1;
  }
  
}

@media (max-width: 768px) {
.blog-span {
  color: #1E4B5E;
  font-size: 12px;
}
}

.blog-card-title-container {
  width: 73%;
  margin: 0 auto;
  margin-top: 20px;
}

.blog-excerpt {
  margin-top: 10px;
}

.blog-slider-container {
  margin-bottom: 20px;
}

.new-design-home .section-1.design-cont {
  background: linear-gradient(90deg, #FFFBF1 0%, #F9F9F9 50%) !important;
  background-position: center !important;
  background-size: 100% !important;
  position: relative;
  overflow: hidden;
}
.new-design-home .section-1.design-cont::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url(/assets/img/home-img/homepage-first-bg.png) !important;
}
.new-design-home .section-1.design-cont .main-para-div .para-short-span {
  font-size: 28px;
  max-width: 100%;
}
.new-design-home .section-1.design-cont .main-para-div .para-long-span {
  font-size: 15px;
  max-width: 100%;
}
.new-design-home .para-sub-title-span {
  font-size: 15px;
  max-width: 70%;
  color: #1E4B5E;
}
@media (max-width: 768px) {
  .new-design-home .para-sub-title-span {
      max-width: 100%;
  }
}
.new-design-home .para-sub2-title-span {
  font-size: 15px;
  max-width: 70%;
  color: #1E4B5E;
  line-height: 1.5;
}
@media (max-width: 1024px) {
  .new-design-home .para-sub2-title-span {
      font-size: 15px;
      max-width: 70%;
      color: #1E4B5E;
      line-height: 1.5;
  }
}

@media (max-width: 600px) {
  .new-design-home .para-sub2-title-span {
      font-size: 15px;
      max-width: 90%;
      color: #1E4B5E;
      line-height: 1.5;
  }
}
.design-cont .main-para-div p {
  color: #1E4B5E;
  font-family: 'Object Sans Heavy';
}
.design-slide {
  background: #FFFFFF;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(16, 51, 98, 0.45);
  -moz-box-shadow: 0px 0px 10px 0px rgba(16, 51, 98, 0.45);
  box-shadow: 0px 0px 10px 0px rgba(16, 51, 98, 0.45);
  transform: scale(0.99);
  border-radius: 3px;
  width: 99%!important;
  min-height: 350px;
  margin: auto;
  overflow: hidden;
  cursor: pointer;
  display: block!important;
}
.design-slide:focus {
  outline: none;
}
.mp-slide {
  margin-top: -170px;
  padding-bottom: 90px;
}
.mp-slide .slick-list {
  margin: 10px auto;
  padding: 10px 0;
}
.new-design-home .section-1 .custom-section.preHeaderAdded {
  padding-top: 100px;
}
.new-design-home .section-1 .custom-section .connect-btn {
  min-width: 295px;
  border: 2px solid #1E4B5E;
  border-radius: 3px;
  outline: transparent;
  padding: 26px 60px;
  transition: all 0.2s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  background: #1E4B5E;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  color: #FFCC4A;
  font-weight: 800;
  line-height: 24px;
  font-size: 24px;
  margin-top: 20px;
  font-family: "object sans heavy";
}
.new-design-home .section-1 .custom-section .connect-btn:active {
  background: #FFCC4A !important;
  border: 1px solid #FFCC4A !important;
}
.new-design-home .section-1 .custom-section .connect-btn span {
  font-weight: 800;
}
.mp-slide .slick-dots li button:before {
  color: #C7D0D3;
  font-size: 12px;
  opacity: 0.75;
}
.mp-slide .slick-dots li button:hover:before {
  opacity: 1;
  color: #acb5b7 !important;
}
.mp-slide .slick-slider .slick-dots {
  bottom: 20px;
}
.slick-dots li {
  margin: 0 2px;
}
.slick-dots {
  bottom: 15px;
}
.slick-slider .slick-dots li.slick-active button:before {
  opacity: 1;
  color: #FFCC4A !important;
}
.new-design-home .slick-slide img {
  width: 100%;
}
.home-page-slider .slick-slider .slick-arrow {
  z-index: 99;
}
/* custom css */
.new-design-home .section-1 .main-head-1 .primary-span {
  margin-bottom: 25px;
  font-size: 90px;
}
.new-design-home .section-1 .main-head-1 .title-span {
  margin-bottom: 25px;
  font-size: 40px;
  color: #24A989;
  font-family: "Object Sans Heavy";
}
.new-design-home .main-head-1 .title-span {
  margin-bottom: 25px;
  font-size: 40px;
  color: #24A989;
  font-weight: 700;
  font-family: "Object Sans Heavy";
}
.home-card .home-tit h4 {
  font-family: "object sans heavy";
  font-size: 18px;
  line-height: 22px;
}
.new-design-home .section-5 .partner-features ul {
  padding: 0px;
  margin: 0px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.new-design-home .section-5 .partner-features ul li {
  list-style-type: none;
  display: inline-flex;
  flex-direction: column;
  margin-top: 5%;
  padding: 30px;
  width: 30%;
  flex: 0 0 30%;
  margin-right: 5%;
  box-shadow: 2px 0px 0px #FFD028, 1px 1px 5px rgba(30, 75, 94, 0.6);
  position: relative;
  transition: all 1.2s ease;
  cursor: pointer;
}
.new-design-home .section-5 .partner-features ul li:nth-of-type(3n) {
  margin-right: 0px;
}
.new-design-home .section-5 .partner-features ul li:nth-of-type(1),
.new-design-home .section-5 .partner-features ul li:nth-of-type(2),
.new-design-home .section-5 .partner-features ul li:nth-of-type(3) {
  margin-top: 0px;
}
.new-design-home .section-5 .partner-features ul li:hover {
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2), 5px 5px 0px #FFD028;
}
.new-design-home .section-5 .partner-features ul li a strong {
  font-size: 30px;
  color: #1E4B5E;
  font-family: 'Heldane Display';
  line-height: 34px;
  margin-bottom: 25px;
  width: 100%;
  display: block;
}
.new-design-home .section-5 .partner-features ul li a span {
  color: #1E4B5E;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 20px;
}
.new-design-home .section-5 .partner-features ul li a i {
  position: absolute;
  bottom: 15px;
  right: 15px;
}
.new-design-home .section-3 .main-para-div .para-short-span {
  font-weight: 700;
  color: #1E4B5E;
}
.new-design-home .section-3 .content-section {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}
.new-design-home .section-3 .content-section .left-section .experience-list {
  display: flex;
  margin-bottom: 40px;
}
.new-design-home .section-3 .content-section .left-section .experience-list .service-img {
  width: 80px;
  height: 80px;
  display: inline-flex;
}
.new-design-home .section-3 .content-section .left-section .experience-list .service-img b {
  font-size: 30px;
  font-family: 'Heldane Display';
  color: #1E4B5E;
}
.new-design-home .section-3 .content-section .left-section .experience-list .service-img.ylow-bx::before {
  width: 80px;
  height: 80px;
}
.new-design-home .section-3 .content-section .left-section .experience-list .experience-detail {
  display: inline-flex;
  flex-direction: column;
  width: calc(100% - 80px);
  margin-left: 30px;
  justify-content: flex-end;
}
.new-design-home .section-3 .content-section .left-section .experience-list .experience-detail strong {
  color: #1E4B5E;
  font-size: 30px;
  font-weight: 700;
  font-family: 'Heldane Display';
  margin-bottom: 15px;
}
.new-design-home .section-3 .content-section .left-section .experience-list .experience-detail span {
  color: #1E4B5E;
  font-size: 18px;
}
.new-design-home .section-3 .content-section .left-section .experience-list:last-child {
  margin-left: 80px;
}
.new-design-home .section-3 .content-section .left-section .experience-list:last-child a {
  color: #1E4B5E;
  font-size: 21px;
  text-decoration: underline;
  font-family: 'Object Sans Heavy';
}
.new-design-home .section-3 .content-section .right-section {
  display: inline-flex;
  justify-content: flex-end;
}
.new-design-home .section-4 .main-head-div {
  margin-bottom: 35px;
}
.new-design-home .section-4 .content-section {
  display: flex;
  justify-content: space-between;
  margin-top: 0px;
}
.new-design-home .section-4 .content-section .method-list {
  flex-direction: column;
}
.new-design-home .section-4 .content-section .method-list strong {
  color: #1E4B5E;
  font-size: 34px;
  margin-bottom: 15px;
  font-family: 'Heldane Display';
  display: flex;
}
.new-design-home .section-4 .content-section .method-list p {
  color: #1E4B5E;
  min-height: 145px;
  margin-bottom: 10px;
}
.new-design-home .section-4 .content-section .method-list p b {
  text-decoration: underline;
  cursor: pointer;
}
.new-design-home .section-4 .content-section .method-list a {
  color: #1E4B5E;
  text-decoration: underline;
  font-weight: 800;
}
.new-design-home .section-4 .content-section .method-list button {
  border: 1px solid #24A989;
  border-radius: 3px;
  color: #1E4B5E;
  padding: 15px 20px;
  background: #FFFFFF;
  font-weight: 700;
  font-size: 21px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
}
.new-design-home .section-4 .content-section .method-list button:hover {
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
}
.new-design-home .section-2 .overall-sec-11 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.new-design-home .section-2 .second-row {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
}
.new-design-home .section-2 .first-row {
  display: flex;
  justify-content: space-between;
}
.new-design-home .section-6 {
  padding: 80px 0px;
}
.new-design-home .section-6 .slick-slider {
  margin-left: -20%;
}
.new-design-home .section-6 .slick-slider .slick-slide {
  opacity: 0.7;
}
.new-design-home .section-6 .slick-slider .slick-slide.slick-active {
  opacity: 1;
}
.new-design-home .section-6 .criteria-col .service-img.ylow-sqr-bx {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  height: 120px;
  width: 90%;
}
.new-design-home .section-6 .criteria-col .service-img.ylow-sqr-bx .criteria-image-hold {
  height: 60px;
}
.new-design-home .section-5 .connect-with-custom-section span.connect-btn {
  font-size: 24px;
  font-family: "Object Sans heavy";
  line-height: 24px;
}
.new-design-home .section-6.ix-awards .slick-slider {
  margin-left: -26%;
}
.new-design-home .section-6 .award-card-ul li {
  background: #FFFFFF;
  box-shadow: 2px 0px 0px #FFD028, 2px 2px 10px rgba(30, 75, 94, 0.2);
  display: flex !important;
  justify-content: center;
  text-align: center;
  margin-right: 10% !important;
  width: 310px !important;
  padding: 35px 20px;
  height: 285px;
  position: relative;
  margin: 20px 0px;
}
.new-design-home .section-6 .award-card-ul li .award-card.ylow-bx {
  display: flex;
  justify-content: center;
  height: 145px;
  align-items: center;
}
.new-design-home .section-6 .award-card-ul li .award-card.ylow-bx .award-card-img-hold img {
  width: 145px;
  display: flex;
}
.new-design-home .section-6 .award-card-ul li .award-info {
  margin-top: 15px;
}
.new-design-home .section-6 .award-card-ul li .award-info .award-name {
  color: #1E4B5E;
  font-size: 22px;
  font-family: "Heldane display";
}
.new-design-home .section-6 .award-card-ul li .award-info .award-year {
  position: absolute;
  top: 15px;
  right: 15px;
  color: #7E97A1;
  font-size: 18px;
  font-family: "Object Sans Heavy";
}
.home-card.home-card-resp.post-trade .home-img {
  padding-top: 20px;
}
.home-card.home-card-resp.post-trade .home-img img {
  width: 90px;
}
.home-card.home-card-resp.post-trade .home-tit {
  margin-top: 25px;
}
.new-design-home .main-head-1 .home-short-span {
  max-width: 800px;
  font-size: 54px;
}
.new-design-home .main-para-div .para-short-span {
  max-width: 630px;
}
.driven-types-common .company-info.section-4 {
  background-image: none;
  padding: 0px!important;
}
.new-design-home .driven-types-common .company-info .main-para-div p {
  max-width: 100%;
}
.new-design-home .driven-types-common .company-info .main-para-div .main-para-div .connect-btn {
  font-weight: 600;
  color: #1e4b5e;
}
.new-design-home .driven-common-hold {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #C7D0D3;
  padding-bottom: 30px;
}
.new-design-home .find-col:last-child .driven-common-hold {
  border-bottom: none;
  margin-bottom: 0px;
}
.new-design-home .driven-types-common .full-paper-btn button {
  background: #007CC1;
  border: 1px solid #007cc1;
  border-radius: 3px;
  font-family: 'Object Sans Heavy';
  font-size: 18px;
  line-height: 19px;
  text-align: center;
  color: #FFFFFF;
  padding: 15px 20px;
  height: 60px;
  min-width: 190px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: no-drop;
}
.new-design-home .driven-types-common .home-design-link {
  color: #007CC1;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 600;
  font-family: 'Object Sans Heavy';
  display: inline-block;
}
.new-design-home .driven-types-common .home-design-link:hover {
  text-decoration: underline!important;
}
.service-img.ylow-sqr-bx {
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  display: block;
  box-sizing: border-box;
  padding: 10px;
  text-align: center;
  box-shadow: 3px 3px 0 #ffd028, 2px 2px 1px rgba(0, 0, 0, 0.2);
  background: #fff;
  border-radius: 5px;
  width: 227px;
  height: 145px;
  transition: all 1.2s ease;
  margin-right: 10%;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
}
.service-img.ylow-sqr-bx .criteria-image-hold {
  width: 150px;
  height: 100px;
  max-width: 90%;
  align-items: center;
  margin: auto;
  display: inline-flex;
  justify-content: center;
}
.service-img.ylow-sqr-bx .criteria-image-hold img {
  width: 100%;
}
.service-img.ylow-sqr-bx:hover {
  transition: all 1.2s ease;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2), 5px 5px 0 #ffd028;
}
.new-design-home .section-6.ix-awards .slick-slider .slick-slide {
  opacity: 0.5 !important;
}
.new-design-home .section-6.ix-awards .slick-slider .slick-slide.slick-active {
  opacity: 1 !important;
}
@media only screen and (max-width: 1240px) {
  .new-design-home .section-6 .award-card-ul li {
    width: auto !important;
  }
}
@media only screen and (max-width: 1024px) {
  .new-design-home .section-1 .main-head-1 .primary-span {
    font-size: 48px;
    line-height: 52px;
  }
  .new-design-home .section-1.design-cont .main-para-div .para-short-span {
    font-size: 18px;
  }
}
@media only screen and (max-width: 576px) and (min-width: 426px) {
  .new-design-home .solutions-blue-cards .blue-card-col {
    max-width: 70%!important;
  }
  .new-design-home .custom-section .appr-col {
    width: 100%!important;
    max-width: 100%!important;
  }
}
@media only screen and (max-width: 480px) and (min-width: 426px) {
  .home-img {
    width: 100px;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 768px) {
  .new-design-home .custom-section .appr-col {
    width: 50%;
    flex: inherit;
    max-width: 50%;
    margin: 0px!important;
  }
  .new-design-home .left-info-hold {
    background: none;
  }
  .new-design-home .solutions-blue-cards .blue-card-col {
    max-width: 50%;
    flex: inherit;
    margin-bottom: 30px;
  }
  video.hero-bg {
    width: 190vh;
  }
  .mp-slide {
    margin-top: -155px;
    padding-bottom: 90px;
  }
  .home-page-slider .slick-slider .slick-prev {
    left: -12px;
  }
  .home-page-slider .slick-slider .slick-next {
    right: -12px;
  }
  .home-page-slider .slick-prev,
  .home-page-slider .slick-next {
    width: 30px;
    height: 30px;
  }
  .home-page-slider .slick-prev:before,
  .home-page-slider .slick-next:before {
    background-position: 6px center;
  }
  .new-design-home .solutions-blue-cards .blue-card-col div.home-card.home-card-resp.post-trade div.home-img img {
    width: 50px;
  }
  .new-design-home .section-3 .content-section {
    flex-direction: column;
    justify-content: space-between;
  }
  .new-design-home .section-3 .content-section .right-section svg {
    width: 100%;
  }
  .new-design-home .section-4 .content-section {
    display: block;
  }
  .new-design-home .section-4 .content-section .method-list {
    margin-bottom: 30px;
  }
  .new-design-home .section-5 .partner-features ul li strong {
    width: 100%;
    min-height: auto;
  }
  .new-design-home .section-5 .partner-features ul li {
    padding-right: 0px;
    padding-left: 0px;
    margin-top: 30px !important;
    padding-bottom: 25px;
  }
  div.custom-inx-navbar.kyc-container {
    top: 0px;
    height: 120px;
  }
  /*start for event banner*/
  .Header .custom-inx-logo {
    position: relative;
    top: -25px;
  }
  .Header .right-menu {
    position: relative;
    top: -20px;
  }
  /*end for event banner*/
  .new-design-home .section-6 .criteria-col .service-img.ylow-sqr-bx {
    width: auto;
  }
  .new-design-home .section-5 .connect-with-custom-section {
    flex-direction: column;
    align-items: flex-start;
  }
  .new-design-home .section-3 .content-section {
    display: block;
  }
  .new-design-home .section-3 .content-section .left-section .experience-list:last-child {
    margin-left: 0px;
  }
  .company-info .first-row {
    flex-wrap: wrap;
  }
  .new-design-home .section-5 div.partner-features ul.partner-list li {
    width: 45%;
    flex: 0 0 45%;
    padding: 20px;
    padding-bottom: 40px;
  }
  .new-design-home .section-5 div.partner-features ul.partner-list li:nth-of-type(3n) {
    margin-right: auto;
  }
}
@media only screen and (max-width: 860px) {
  .new-design-home .driven-common-hold {
    display: block;
  }
  .new-design-home .driven-types-common .company-info .main-para-div p {
    max-width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .tool-banner {
    background-size: 100%;
    padding: 10px 15px;
    width: 100%;
  }
  .tool-banner span {
    font-size: 10px;
  }
  .tool-banner span em {
    margin-left: 10px;
  }
}
@media only screen and (max-width: 576px) and (min-width: 424px) {
  .solutions-blue-cards .home-tit h4 {
    font-size: 22px;
    line-height: 27px;
  }
  .solutions-blue-cards .home-tit {
    min-height: 30px;
  }
  .solutions-blue-cards .home-des p {
    font-size: 16px;
  }
  .solutions-blue-cards .home-btn .view-btn {
    font-size: 16px;
    line-height: 19px;
    padding: 15px 20px;
  }
  .design-slide {
    transform: scale(0.95);
  }
  .new-design-home .section-3 .content-section .left-section .experience-list {
    flex-direction: column;
    align-items: center;
  }
  .new-design-home .section-3 .content-section .left-section .experience-list .experience-detail {
    width: 100%;
    margin-left: 0px;
    align-items: center;
    text-align: center;
  }
  .new-design-home .section-3 .content-section .left-section .experience-list:last-child {
    margin-left: 0px;
  }
  .new-design-home .section-2 .overall-sec-11 {
    align-items: center;
    text-align: center;
  }
  .become-partner {
    font-size: 14px;
    right: 60px;
  }
  .Header.scrollHeader .become-partner {
    font-size: 12px;
  }
}
@media only screen and (max-width: 425px) {
  .tool-banner {
    width: 100%;
    padding: 8px 15px;
  }
  .tool-banner span {
    font-size: 9px;
  }
  .new-design-home .section-6 .award-card-ul li {
    width: 70% !important;
    margin: 20px auto !important;
    margin-right: 10% !important;
  }
  video.hero-bg {
    width: 210vh;
  }
  .new-design-home .custom-section .appr-col {
    width: 100%;
    max-width: 100%;
  }
  .new-design-home .section-1 .main-head-1 .primary-span {
    font-size: 42px;
    line-height: 45px;
  }
  .new-design-home .service-head h4 {
    font-size: 24px;
    line-height: 30px;
  }
  .new-design-home .custom-section .appr-col {
    margin-bottom: 40px!important;
  }
  .new-design-home .custom-section .appr-col .main-para-div {
    margin-bottom: 0px;
  }
  .find-col {
    margin-bottom: 50px;
  }
  .new-design-home .company-info .highlight-text h4 span {
    font-size: 30px;
    line-height: 37px;
  }
  .new-design-home .company-info .main-para-div p {
    font-size: 16px;
    max-width: 100%;
  }
  .new-design-home .company-info .main-para-div {
    margin-bottom: 15px;
  }
  .new-design-home .company-info .more-text a {
    font-size: 16px;
  }
  .new-design-home .solutions-blue-cards .blue-card-col {
    max-width: 100%;
  }
  .new-design-home .solutions-blue-cards .blue-card-col .home-card-resp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 205px;
  }
  .new-design-home .solutions-blue-cards .blue-card-col .home-card-resp .home-btn {
    position: relative;
    width: 100%;
    left: inherit;
    bottom: inherit;
  }
  .new-design-home .solutions-blue-cards .blue-card-col .home-card-resp .home-tit h4 {
    font-size: 18px!important;
    line-height: 22px!important;
    text-align: left;
  }
  .new-design-home .solutions-blue-cards .blue-card-col .home-card-resp .home-tit {
    min-height: auto;
  }
  .new-design-home .solutions-blue-cards .blue-card-col .home-card-resp .home-des p {
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: left;
    letter-spacing: -1px;
  }
  .new-design-home .solutions-blue-cards .blue-card-col .home-card-resp .home-img {
    width: 100%;
    padding: 10px;
  }
  .new-design-home .solutions-blue-cards .blue-card-col .home-card-resp .home-img img {
    width: 100%;
  }
  .new-design-home .solutions-blue-cards .blue-card-col .home-card-resp .home-btn .view-btn {
    font-size: 16px;
    padding: 0px;
    width: 130px;
    height: 45px;
    vertical-align: middle;
  }
  .new-design-home .solutions-blue-cards .blue-card-col .home-card-resp .home-btn {
    text-align: left;
  }
  .new-design-home .section-3 .content-section .left-section .experience-list {
    flex-direction: column;
    align-items: center;
  }
  .new-design-home .section-3 .content-section .left-section .experience-list .experience-detail {
    width: 100%;
    margin-left: 0px;
    align-items: center;
    text-align: center;
  }
  .new-design-home .section-3 .content-section .left-section .experience-list:last-child {
    margin-left: 0px;
  }
  .new-design-home .section-2 .overall-sec-11 {
    align-items: center;
    text-align: center;
  }
  .become-partner {
    font-size: 14px;
    right: 60px;
  }
  .Header.scrollHeader .become-partner {
    font-size: 12px;
  }
  .new-design-home .section-5 div.partner-features ul.partner-list li {
    width: 100%;
    flex: 0 0 100%;
    padding: 20px;
    padding-bottom: 60px;
  }
  .new-design-home .section-5 div.partner-features ul.partner-list li:nth-of-type(3n) {
    margin-right: auto;
  }
}
@media only screen and (max-width: 375px) {
  video.hero-bg {
    width: 220vh;
  }
  .section-1 .main-head-1 .secondary-span {
    font-size: 26px!important;
    line-height: 32px!important;
  }
}
@media only screen and (max-width: 576px) and (min-width: 320px) {
  .design-slide {
    transform: scale(0.95);
  }
  .driven-types-common .find-col {
    margin-bottom: 0px;
  }
  .driven-types-common .find-col:last-child {
    margin-bottom: 0px;
    padding-top: 35px;
  }
  .new-design-home .find-col:last-child .driven-common-hold {
    padding-bottom: 0px!important;
  }
  .new-design-home .c-cards-mg {
    margin-bottom: 0px!important;
  }
  .new-design-home .solutions-blue-cards .blue-card-col:last-child {
    margin-bottom: 0px;
  }
  .ix-media .criteria-col {
    flex: 0 0 50%;
    max-width: 50%;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0;
  }
  .service-img.ylow-sqr-bx {
    width: 100%;
    height: auto;
    margin-right: 0;
  }
  .new-design-home .section-3 .content-section .left-section .experience-list {
    flex-direction: column;
    align-items: center;
  }
  .new-design-home .section-3 .content-section .left-section .experience-list .experience-detail {
    width: 100%;
    margin-left: 0px;
    align-items: center;
    text-align: center;
  }
  .new-design-home .section-3 .content-section .left-section .experience-list:last-child {
    margin-left: 0px;
  }
  .new-design-home .section-2 .overall-sec-11 {
    align-items: center;
    text-align: center;
  }
  .become-partner {
    font-size: 14px;
    right: 60px;
  }
  .Header.scrollHeader .become-partner {
    font-size: 12px;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 1900px) {
  .new-design-home .section-6.ix-awards .slick-slider {
    margin-left: -20%;
  }
}
@media only screen and (min-width: 2000px) and (max-width: 2500px) {
  .new-design-home .section-6.ix-awards .slick-slider {
    margin-left: -16%;
  }
}
