.neuron .section-1 .main-head-1 .primary-span {
  word-wrap: break-word;
  text-wrap: wrap;
  width: 65%;
  margin: 0px auto 30px;
  line-height: initial;
}
.neuron .neuron-overview p {
  font-size: 16px;
  line-height: 30px;
  margin: 0px auto 30px;
}
.neuron .main-head-1 .secondary-span {
  font-family: 'Heldane Display';
}
.neuron ul.principles-common-ul h3 {
  font-family: 'Heldane Display';
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 52px;
  color: #1E4B5E;
  cursor: pointer;
}
.neuron .features-common-hold {
  display: inline-flex;
  width: 100%;
}
.neuron .features-common-hold ul.features-common-ul {
  display: inline-flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  counter-reset: css-counter 1;
}
.neuron .features-common-hold ul.features-common-ul li {
  display: inline-flex;
  flex: 0 0 50%;
  margin: 20px 0px 20px;
  min-height: 120px;
  position: relative;
  padding-left: 50px;
}
.neuron .features-common-hold ul.features-common-ul li p {
  max-width: 80%;
  margin: 10px 0px 0px;
  display: inline-flex;
}
.neuron .features-common-hold ul.features-common-ul li:before {
  content: counter(css-counter);
  background: #204b5d;
  color: #ffcc4a;
  font-family: 'Heldane Display';
  font-size: 26px;
  font-weight: bold;
  position: absolute;
  left: 0px;
  width: 40px;
  height: 40px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 0 0 10px #c1c1c1;
}
.neuron .features-common-hold ul.features-common-ul li .features-info-hold {
  counter-increment: css-counter 1;
}
.neuron .features-common-hold ul.features-common-ul li h3 {
  font-weight: bold;
  color: #208F71;
  font-family: "Heldane display";
  font-size: 26px;
  line-height: 30px;
}
.neuron .benifits-section .main-head-div {
  margin-bottom: 20px;
}
.neuron .benifits-section .main-head-1.main-head-2 .primary-span {
  color: #fff;
}
.neuron .benifits-section .features-common-hold ul.features-common-ul {
  padding: 0;
}
.neuron .benifits-section .features-common-hold ul.features-common-ul li {
  flex: 1 1 33.3%;
  padding: 10px;
  margin: 0px;
  flex-shrink: initial;
  align-self: stretch;
  justify-self: stretch;
}
.neuron .benifits-section .features-common-hold ul.features-common-ul li:before {
  display: none;
}
.neuron .benifits-section .features-common-hold ul.features-common-ul li .features-info-hold {
  background: #fff;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #295e50;
}
.neuron .benifits-section .features-common-hold ul.features-common-ul li .features-info-hold h3 {
  text-wrap: wrap;
  max-width: 95%;
  color: #1e4b5e;
}
.neuron .benifits-section .features-common-hold ul.features-common-ul li .features-info-hold p {
  color: #1e4b5e;
  line-height: 26px;
  max-width: 100%;
}
.neuron .benifits-section.use-cases .features-common-hold ul.features-common-ul li {
  flex: 1 1 25%;
}
.neuron .benifits-section.use-cases .main-head-1.main-head-2 .primary-span {
  color: #24A989;
}
.neuron .benifits-section.get-start .features-common-hold ul.features-common-ul li {
  flex: 1 1 50%;
}
.neuron .benifits-section.get-start .main-head-1.main-head-2 .primary-span {
  color: #24A989;
}
.neuron .benifits-section.get-start .features-common-hold ul.features-common-ul li .features-info-hold {
  border: none;
  box-shadow: none;
  background: transparent;
  display: inline-flex;
  flex-direction: column;
}
.neuron .benifits-section .features-common-hold ul.features-common-ul li .features-info-hold p {
  max-width: 80%;
}
.neuron .benifits-section.use-cases .features-common-hold ul.features-common-ul li .features-info-hold p {
  max-width: 100%;
}
.neuron .benifits-section.get-start .features-common-hold ul.features-common-ul li .features-info-hold a.btn.btn-primary.connect-btn {
  margin: 20px 0px;
  padding: 15px 20px !important;
  width: 170px !important;
  height: auto !important;
  line-height: initial !important;
  font-weight: bold;
}
@media only screen and (max-width: 768px) {
  .neuron .section-1 .main-head-1 .primary-span {
    width: 100%;
  }
  .neuron .features-common-hold ul.features-common-ul {
    padding: 0;
  }
  .neuron .features-common-hold ul.features-common-ul li {
    flex: 0 0 100%;
    margin: 20px 0px 0px;
    min-height: 120px;
    padding-left: 35px;
  }
  .neuron .features-common-hold ul.features-common-ul li:before {
    font-size: 15px;
    left: 0px;
    width: 25px;
    height: 25px;
  }
  .neuron .features-common-hold ul.features-common-ul li h3 {
    font-size: 18px;
  }
  .neuron .features-common-hold ul.features-common-ul li p {
    max-width: 100%;
    margin: 0;
    font-size: 14px;
  }
  .neuron .benifits-section .features-common-hold ul.features-common-ul li,
  .neuron .benifits-section.use-cases .features-common-hold ul.features-common-ul li,
  .neuron .benifits-section.get-start .features-common-hold ul.features-common-ul li {
    flex: auto;
  }
  .neuron .benifits-section .features-common-hold ul.features-common-ul li .features-info-hold p {
    max-width: 100%;
  }
  .neuron .benifits-section.get-start .features-common-hold ul.features-common-ul li .features-info-hold {
    padding: 0;
  }
}
