.section-1.newblockchain-page {
  background: #1B8168;
  position: relative;
  text-align: center;
}
.section-1.newblockchain-page::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: url('/assets/img/blockchain-img/new_blockchain/web3_bg.svg') no-repeat;
  opacity: 0.25;
  background-size: 100%;
}
.section-1.newblockchain-page .main-head-1 .primary-span {
  color: #FFCC4A;
  font-size: 46px;
  margin-bottom: 30px;
}
.section-1.newblockchain-page .main-head-1 .secondary-span {
  color: #ffffff;
  font-size: 32px;
}
.section-1.newblockchain-page .main-para-div {
  display: block;
  text-align: center;
  width: 100%;
}
.section-1.newblockchain-page .main-para-div p {
  color: #ffffff;
  width: 50%;
  margin: 0px auto 50px;
}
.section-1.newblockchain-page a.connect-btn {
  font-size: 24px;
  color: #1E4B5E;
  font-family: "Object Sans heavy";
  line-height: 24px;
}
.main-head-div.heading_lines .main-head-1 .primary-span {
  font-family: Test Heldane Text;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 44px;
  letter-spacing: 0px;
  text-align: left;
  color: #24A989;
  position: relative;
  padding-left: 0px;
}
.main-head-div.heading_lines .main-head-1 .primary-span em,
.main-head-div.heading_lines .main-head-1 .primary-span h2 {
  font-style: normal;
  color: #24A989;
}
@media screen and (max-width: 768px) {
  .main-head-div.heading_lines .main-head-1 .primary-span {
    font-size: 30px;
    line-height: 40px;
  }
}
.New_Blockchain .company-info strong {
  color: #1E4B5E;
  font-size: 34px;
  font-weight: 800;
  text-align: center;
  display: block;
  font-family: "Object Sans heavy";
}
.New_Blockchain .company-info .customer-list {
  margin-top: 80px;
}
.New_Blockchain .company-info .customer-list .list-wrap {
  margin-right: 30px;
  display: flex !important;
  align-items: center;
  height: 47px;
}
.New_Blockchain .company-info .customer-list .list-wrap img {
  width: 80%;
  display: flex;
  align-items: center;
}
.New_Blockchain .company-info .global-count {
  display: flex;
  background: url('/assets/img/blockchain-img/new_blockchain/map_bg.svg') no-repeat;
  background-size: 100%;
  padding: 100px 0px 0;
  margin: 100px 0px 0;
}
.New_Blockchain .company-info .global-count .global-info {
  display: inline-flex;
  flex-direction: column;
  text-align: center;
}
.New_Blockchain .company-info .global-count .global-info b {
  color: #24A989;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.45);
  font-size: 46px;
  font-family: "Object Sans heavy";
}
.New_Blockchain .company-info .global-count .global-info span {
  color: #1E4B5E;
  font-family: "Object Sans heavy";
  font-size: 18px;
  line-height: 25px;
}
.New_Blockchain .company-info .web-solutions h3 {
  font-family: "Heldane display";
  color: #24A989;
  font-size: 42px;
}
.New_Blockchain .company-info .web-solutions .solution-card {
  display: flex;
  margin: 0px auto 0px;
  flex-wrap: wrap;
}
.New_Blockchain .company-info .web-solutions .solution-card .solution-list {
  display: inline-flex;
  width: 30%;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 2px 0 0 #ffd028, 1px 1px 5px rgba(30, 75, 94, 0.6);
  margin-right: 40px;
  margin-top: 40px;
  align-items: center;
  justify-content: center;
  padding: 30px;
  min-height: 285px;
  cursor: pointer;
  transition: all 1.2s ease;
}
.New_Blockchain .company-info .web-solutions .solution-card .solution-list:nth-of-type(3n) {
  margin-right: 0px;
}
.New_Blockchain .company-info .web-solutions .solution-card .solution-list:hover {
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2), 5px 5px 0 #ffd028;
}
.New_Blockchain .company-info .web-solutions .solution-card .solution-list b {
  font-size: 26px;
  color: #1E4B5E;
  display: flex;
  text-align: center;
  font-family: "Heldane display";
  margin-top: 20px;
}
.New_Blockchain .business-solution {
  padding: 100px 0px;
}
.New_Blockchain .business-solution strong.heading {
  color: #24A989;
  font-family: "Heldane display";
  font-size: 52px;
  margin-bottom: 50px;
  display: block;
}
.New_Blockchain .business-solution .solution-info {
  margin: 0px;
  display: block;
}
.New_Blockchain .business-solution .solution-info b {
  color: #1E4B5E;
  font-size: 40px;
  font-family: "Object Sans Heavy";
  display: block;
  line-height: 40px;
}
.New_Blockchain .business-solution .solution-info p {
  color: #1E4B5E;
  font-family: "Object Sans";
  font-size: 18px;
  margin: 20px 0px;
}
.New_Blockchain .business-solution .solution-info .solution-img {
  display: block;
  text-align: center;
  margin: 60px auto;
}
.New_Blockchain .business-solution .solution-info .solution-img img {
  width: 45%;
}
.New_Blockchain .business-solution .solution-info ul {
  margin: 10px 0px;
  padding: 0px;
}
.New_Blockchain .business-solution .solution-info ul.blockchain-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.New_Blockchain .business-solution .solution-info ul.blockchain-list li {
  list-style-type: none;
  padding-left: 0;
  display: inline-flex;
  align-items: center;
  width: 50%;
  margin-bottom: 50px;
  justify-content: flex-start;
}
.New_Blockchain .business-solution .solution-info ul.blockchain-list li:last-child {
  margin-bottom: 0px;
}
.New_Blockchain .business-solution .solution-info ul.blockchain-list li span {
  color: #1E4B5E;
  font-family: "Heldane display";
  font-size: 24px;
  margin-left: 30px;
  display: inline-flex;
  width: calc(100% - 180px);
  flex-wrap: wrap;
}
.New_Blockchain .business-solution .solution-info ul.blockchain-list li::before {
  display: none;
}
.New_Blockchain .business-solution .solution-info ul.blockchain-list li i {
  width: 180px;
  height: 180px;
  position: relative;
  display: inline-flex;
  justify-content: center;
}
.New_Blockchain .business-solution .solution-info ul.blockchain-list li i::before {
  box-shadow: 3px 3px 0 #ffd028, 2px 2px 5px rgba(30, 75, 94, 0.3);
  content: "";
  position: absolute;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  transition: 0.5s ease;
}
.New_Blockchain .business-solution .solution-info ul.blockchain-list li i svg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.New_Blockchain .business-solution .solution-info ul li {
  position: relative;
  list-style-type: none;
  padding-left: 25px;
  padding-top: 15px;
}
.New_Blockchain .business-solution .solution-info ul li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 15px;
  bottom: 0;
  margin: auto;
  height: 14px;
  width: 14px;
  background: #FFCC4A;
  border-radius: 100%;
}
.New_Blockchain .business-solution .solution-info .cta-btn {
  background: #1E4B5E;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  color: #FFCC4A;
  font-size: 18px;
  padding: 20px 30px;
  border: 0px;
  font-family: "Object Sans Heavy";
  margin-top: 30px;
  display: inline-block;
}
.New_Blockchain .business-solution .solution-info .featured-card {
  display: inline-flex;
  width: 31%;
  margin-right: 2.3%;
  flex-wrap: wrap;
  background: #FFFFFF;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.New_Blockchain .business-solution .solution-info .featured-card:nth-of-type(3n) {
  margin-right: 0px;
}
.New_Blockchain .business-solution .solution-info .featured-card strong {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #EEFEFA;
  padding: 15px;
  color: #1E4B5E;
  font-family: "Object Sans heavy";
  font-size: 24px;
}
.New_Blockchain .business-solution .solution-info .featured-card .featured-content {
  padding: 20px 30px;
  min-height: 287px;
}
.New_Blockchain .business-solution .solution-info .featured-card .featured-content b {
  color: #1E4B5E;
  font-size: 18px;
  line-height: 22px;
}
.New_Blockchain .business-solution .solution-info .featured-card .featured-content p {
  color: #1E4B5E;
  font-size: 14px;
}
.New_Blockchain .business-solution .solution-info .featured-card .featured-bottom {
  background: #EEFEFA;
  border-radius: 0px 0px 5px 5px;
  display: flex;
  justify-content: flex-start;
  padding: 0px;
  width: 100%;
}
.New_Blockchain .business-solution .solution-info .featured-card .featured-bottom a {
  color: #FFCC4A;
  font-family: "Object Sans heavy";
  background: #1E4B5E;
  padding: 18px 15px;
  border-radius: 5px;
  font-size: 16px;
}
.company-info.section-4.development-process {
  background-image: none;
}
.company-info.section-4.development-process .graphics-wrapper {
  padding: 0;
}
.company-info.section-4.development-process .graphics-wrapper .heading-block {
  padding: 0;
}
.company-info.section-4.development-process .hide {
  /* display: none; */
  opacity: 0.6;
}
.company-info.section-4.development-process .heading-block {
  padding: 15px;
}
.company-info.section-4.development-process .list {
  position: relative;
  max-width: 1350px;
  margin: 0 auto;
  border: 0;
}
.company-info.section-4.development-process .svg-block svg {
  width: 100%;
  height: auto;
}
.company-info.section-4.development-process .list-cont,
.company-info.section-4.development-process .list-cont ul,
.company-info.section-4.development-process .list-cont ul li {
  position: absolute;
  list-style: none;
  text-align: center;
}
.company-info.section-4.development-process .list-cont,
.company-info.section-4.development-process .list-cont ul {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.company-info.section-4.development-process .list-circle-1,
.company-info.section-4.development-process .list-10 {
  color: #fff;
  font-size: 28px;
  line-height: 30px;
  font-weight: 900;
  width: 140px;
  background: transparent;
  border: 0;
}
.company-info.section-4.development-process .list-circle-1::after,
.company-info.section-4.development-process .list-10::after {
  display: none;
}
.company-info.section-4.development-process .list-2,
.company-info.section-4.development-process .list-3,
.company-info.section-4.development-process .list-4,
.company-info.section-4.development-process .list-5,
.company-info.section-4.development-process .list-6,
.company-info.section-4.development-process .list-7,
.company-info.section-4.development-process .list-8,
.company-info.section-4.development-process .list-9 {
  font-size: 24px;
  line-height: 23px;
  font-weight: 900;
  width: 140px;
  border: 0;
  background: transparent;
}
.company-info.section-4.development-process .list-2::after,
.company-info.section-4.development-process .list-3::after,
.company-info.section-4.development-process .list-4::after,
.company-info.section-4.development-process .list-5::after,
.company-info.section-4.development-process .list-6::after,
.company-info.section-4.development-process .list-7::after,
.company-info.section-4.development-process .list-8::after,
.company-info.section-4.development-process .list-9::after {
  display: none;
}
.company-info.section-4.development-process .graphics-wrapper .list-cont ul li.list-2 p span,
.company-info.section-4.development-process .graphics-wrapper .list-cont ul li.list-3 p span,
.company-info.section-4.development-process .graphics-wrapper .list-cont ul li.list-4 p span {
  color: #24A989;
}
.company-info.section-4.development-process .list-5,
.company-info.section-4.development-process .list-6,
.company-info.section-4.development-process .list-7,
.company-info.section-4.development-process .list-8,
.company-info.section-4.development-process .list-9 {
  color: #1E4B5E;
}
.company-info.section-4.development-process .list-cont ul li.list-circle-1 {
  top: 16%;
  left: 3%;
}
.company-info.section-4.development-process .list-cont ul li.list-circle-1 span {
  color: #fff;
}
.company-info.section-4.development-process .list-cont ul li.list-2 {
  top: 24%;
  left: 23.5%;
}
.company-info.section-4.development-process .list-cont ul li.list-3 {
  top: 7%;
  left: 44.5%;
}
.company-info.section-4.development-process .list-cont ul li.list-4 {
  top: 24%;
  left: 65.5%;
}
.company-info.section-4.development-process .list-cont ul li.list-5 {
  top: 57%;
  left: 65.5%;
}
.company-info.section-4.development-process .list-cont ul li.list-6 {
  top: 42.5%;
  left: 44.2%;
}
.company-info.section-4.development-process .list-cont ul li.list-7 {
  top: 57%;
  left: 23.5%;
}
.company-info.section-4.development-process .list-cont ul li.list-8 {
  top: 90.1%;
  left: 23.5%;
}
.company-info.section-4.development-process .list-cont ul li.list-9 {
  top: 90.1%;
  left: 65.1%;
  width: 152px;
  line-height: 26px;
}
.company-info.section-4.development-process .list-cont ul li.list-10 {
  top: 78%;
  left: 84%;
}
.company-info.section-4.development-process .list-cont ul li.list-10 span {
  color: #fff;
}
.section-5.development-solution {
  background: linear-gradient(90deg, #EEFEFA 0%, #FDFDFD 100%);
}
.section-5.development-solution .main-head-div.heading_lines .primary-span h2 {
  display: inline;
  font-size: 40px;
  font-weight: 800;
}
.section-5.development-solution .container_tab {
  max-width: 950px;
  width: 100%;
  padding: 50px 40px;
  margin: 0px 20px;
  border-radius: 12px;
  background: #22272c;
  box-shadow: 0px 4px 16px rgba(254, 205, 211, 0.1), 0px 8px 24px rgba(254, 205, 211, 0.1), 0px 16px 56px rgba(254, 205, 211, 0.1);
}
.section-5.development-solution .content input {
  display: none;
}
.section-5.development-solution .content {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .section-5.development-solution .content {
    flex-wrap: wrap;
  }
  .section-5.development-solution .content .list {
    width: 100% !important;
    margin-right: 0px !important;
  }
  .section-5.development-solution .content .text-content {
    width: 100% !important;
    margin-top: 20px;
  }
}
.section-5.development-solution .content .list {
  display: flex;
  flex-direction: column;
  justify-content: unset;
  position: relative;
  width: 50%;
  margin-right: 50px;
  padding: 0;
}
.section-5.development-solution .content .list label {
  cursor: pointer;
  height: 80px;
  padding: 0px 15px;
  transition: all 0.5s ease;
  z-index: 10;
  width: 100%;
  font-family: 'Object Sans Heavy';
  font-size: 20px;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #24A989;
  margin-bottom: 0;
  border-top: 1px solid #24a989;
}
.section-5.development-solution .content .list label.dapps {
  border-bottom: 1px solid #24a989;
}
.section-5.development-solution #nft-solution:checked ~ .list label.nft-solution,
.section-5.development-solution #dao:checked ~ .list label.dao span,
.section-5.development-solution #metaverse-development:checked ~ .list label.metaverse-development span,
.section-5.development-solution #defi-solition:checked ~ .list label.defi-solition span,
.section-5.development-solution #web3-colsulting:checked ~ .list label.web3-colsulting span,
.section-5.development-solution #dapps:checked ~ .list label.dapps span {
  color: #1E4B5E;
}
.section-5.development-solution .content .slider {
  position: absolute;
  left: 0;
  top: 0;
  height: 80px;
  width: 100%;
  transition: all 0.5s ease;
  background: #FFCC4A;
}
.section-5.development-solution #nft-solution:checked ~ .list .slider {
  top: 0;
}
.section-5.development-solution #dao:checked ~ .list .slider {
  top: 80px;
}
.section-5.development-solution #metaverse-development:checked ~ .list .slider {
  top: 160px;
}
.section-5.development-solution #defi-solition:checked ~ .list .slider {
  top: 240px;
}
.section-5.development-solution #web3-colsulting:checked ~ .list .slider {
  top: 320px;
}
.section-5.development-solution #dapps:checked ~ .list .slider {
  top: 400px;
}
.section-5.development-solution .content .text-content {
  width: 80%;
  height: 100%;
}
.section-5.development-solution .content .text {
  display: none;
}
.section-5.development-solution .content .text .title {
  font-size: 25px;
  margin-bottom: 10px;
  font-weight: 500;
}
.section-5.development-solution .container .text p {
  text-align: justify;
}
.section-5.development-solution .content .text-content .home {
  display: block;
}
.section-5.development-solution .content .list label span svg,
.section-5.development-solution .content .list label span img {
  width: 50px;
  height: 50px;
  margin-right: 15px;
}
.section-5.development-solution #nft-solution:checked ~ .text-content .nft-solution,
.section-5.development-solution #dao:checked ~ .text-content .dao,
.section-5.development-solution #metaverse-development:checked ~ .text-content .metaverse-development,
.section-5.development-solution #defi-solition:checked ~ .text-content .defi-solition,
.section-5.development-solution #web3-colsulting:checked ~ .text-content .web3-colsulting,
.section-5.development-solution #dapps:checked ~ .text-content .dapps {
  display: block;
}
.section-5.development-solution #dao:checked ~ .text-content .nft-solution,
.section-5.development-solution #metaverse-development:checked ~ .text-content .nft-solution,
.section-5.development-solution #defi-solition:checked ~ .text-content .nft-solution,
.section-5.development-solution #web3-colsulting:checked ~ .text-content .nft-solution,
.section-5.development-solution #dapps:checked ~ .text-content .nft-solution {
  display: none;
}
.section-5.development-solution .text-content .nft-solution {
  display: block;
}
.section-5.development-solution #dapps:checked ~ .list label.dapps span svg path,
.section-5.development-solution #dapps:checked ~ .list label.dapps span svg path mask,
.section-5.development-solution #nft-solution:checked ~ .list label.nft-solution span svg path,
.section-5.development-solution #nft-solution:checked ~ .list label.nft-solution span svg path mask,
.section-5.development-solution #dao:checked ~ .list label.dao span svg path,
.section-5.development-solution #dao:checked ~ .list label.dao span svg path mask,
.section-5.development-solution #metaverse-development:checked ~ .list label.metaverse-development span svg path,
.section-5.development-solution #metaverse-development:checked ~ .list label.metaverse-development span svg path mask,
.section-5.development-solution #metaverse-development:checked ~ .list label.metaverse-development span svg stroke,
.section-5.development-solution #defi-solition:checked ~ .list label.defi-solition span svg path,
.section-5.development-solution #defi-solition:checked ~ .list label.defi-solition span svg path mask,
.section-5.development-solution #web3-colsulting:checked ~ .list label.web3-colsulting span svg path,
.section-5.development-solution #web3-colsulting:checked ~ .list label.web3-colsulting span svg path mask,
.section-5.development-solution .list label:hover {
  fill: #1E4B5E;
}
.section-5.development-solution .content .list label:hover {
  color: #1E4B5E;
}
.section-5.development-solution .content .list label span svg path,
.section-5.development-solution .content .list label span svg path mask {
  fill: #24A989;
}
.section-5.development-solution .content .list label:hover span svg path {
  fill: #1E4B5E;
}
.section-5.development-solution .content .list label:hover span img,
.section-5.development-solution .section-5.development-solution #defi-solition:checked ~ .list label.defi-solition span img {
  filter: brightness(0.3);
}
.section-5.development-solution .description {
  font-family: Object Sans;
  font-size: 18px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0px;
  text-align: left;
  color: #1E4B5E;
  margin-bottom: 20px;
}
.section-5.development-solution .text h3 {
  font-family: 'Object Sans Heavy';
  font-size: 25px;
  font-weight: 800;
  line-height: 31px;
  letter-spacing: 0px;
  text-align: left;
  margin: 22px 0;
  color: #1E4B5E;
}
.section-5.development-solution .text p {
  font-family: Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: left;
  position: relative;
  padding-left: 20px;
  color: #1E4B5E;
}
.section-5.development-solution .text p::after {
  content: '';
  position: absolute;
  left: 0;
  top: 16px;
  background: #FFCC4A;
  width: 10px;
  height: 10px;
  border-radius: 30px;
}
.section-5.development-solution .text a button {
  font-family: 'Object Sans Heavy';
  font-size: 18px;
  font-weight: 800;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: center;
  box-shadow: 0px 4px 8px 0px #00000040;
  background: #1E4B5E;
  color: #FFCC4A;
  padding: 20px;
  border-radius: 3px;
  border: 0;
}
@media screen and (max-width: 600px) {
  .section-5.development-solution .text a button {
    font-size: 15px;
    padding: 15px;
  }
  .section-5.development-solution .text p,
  .section-5.development-solution .description {
    font-size: 17px;
    line-height: 23px;
  }
}
.section-6.featured-insights .solution-info .featured-card strong {
  background: #57A996;
  color: #fff;
}
.section-6.featured-insights .solution-info .featured-card .featured-bottom {
  background: #57a9966b;
  justify-content: flex-end;
}
.section-6.featured-insights .solution-info .featured-card .featured-bottom a {
  background: transparent;
  color: #007bff;
  text-decoration: underline;
}
.inline-accordion-details em {
  font-style: normal;
  margin: 17px 0;
  display: block;
}
.inline-accordion-details p {
  position: relative;
  padding-left: 32px;
}
.inline-accordion-details p::before {
  content: '';
  position: absolute;
  left: 10px;
  top: 7px;
  width: 10px;
  height: 10px;
  background: #1E4B5E;
  border-radius: 100px;
}
span.query-count {
  display: inline-block;
}
span.query-content {
  display: inline-block;
}
@media screen and (max-width: 1279px) {
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-circle-1,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-10 {
    font-size: 24px;
    line-height: 30px;
  }
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-cont ul li.list-circle-1 {
    top: 13%;
    left: 3%;
  }
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-cont ul li.list-10 {
    top: 81%;
    left: 83.4%;
  }
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-2,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-3,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-4,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-5,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-6,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-7,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-8,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-9 {
    font-size: 20px;
    line-height: 21px;
    margin-left: -1.5%;
  }
}
@media screen and (max-width: 991px) {
  .New_Blockchain .business-solution .solution-info .featured-card {
    width: 45%;
    margin-bottom: 30px;
  }
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-circle-1,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-10 {
    font-size: 20px;
    line-height: 24px;
    width: 100px;
  }
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-cont ul li.list-circle-1 {
    top: 13%;
    left: 3%;
  }
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-cont ul li.list-10 {
    top: 81%;
    left: 83.4%;
  }
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-2,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-3,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-4,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-5,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-6,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-7,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-8,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-9 {
    font-size: 16px;
    line-height: 17px;
    margin-left: -3.5%;
  }
}
@media screen and (max-width: 767px) {
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-circle-1,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-10 {
    font-size: 14px;
    line-height: 17px;
    width: 100px;
  }
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-cont ul li.list-circle-1 {
    top: 13%;
    left: 1%;
  }
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-cont ul li.list-10 {
    top: 81%;
    left: 82.4%;
  }
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-2,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-3,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-4,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-5,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-6,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-7,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-8,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-9 {
    font-size: 15px;
    line-height: 15px;
    margin-left: -6.5%;
  }
}
@media screen and (max-width: 590px) {
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-circle-1,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-10 {
    font-size: 12px;
    line-height: 17px;
    width: 89px;
  }
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-cont ul li.list-circle-1 {
    top: 13%;
    left: 1%;
  }
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-cont ul li.list-10 {
    top: 81%;
    left: 82.4%;
  }
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-2,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-3,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-4,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-5,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-6,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-7,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-8,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-9 {
    font-size: 13px;
    line-height: 13px;
    margin-left: -9.5%;
  }
}
@media screen and (min-width: 479px) {
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-cont ul li p i {
    display: none;
  }
}
@media screen and (max-width: 478px) {
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-cont,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-cont ul,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-cont ul li {
    position: static;
    width: auto;
  }
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-2,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-3,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-4,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-5,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-6,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-7,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-8,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-9,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-cont ul li.list-9 {
    width: auto;
    margin: 0;
    font-size: 20px;
    line-height: 23px;
  }
  .company-info.section-4.development-process .graphics-wrapper .heading-block .svg-block {
    display: none;
  }
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-cont ul {
    position: relative;
  }
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-cont ul::before {
    position: absolute;
    content: '';
    top: 0;
    left: 50%;
    width: 6px;
    height: 100%;
    transform: translate(-50%, 0);
    background: #1e4b5e;
    background: linear-gradient(0deg, #1e4b5e 0%, #24a989 100%);
    box-shadow: 0 0 0 2px rgba(0, 209, 227, 0.42);
  }
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-cont ul li p {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 95%;
    margin: 0 auto;
  }
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-cont ul li p i,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-cont ul li p span {
    width: 50%;
    padding: 20px 45px;
  }
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-cont ul li p i {
    text-align: right;
  }
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-cont ul li p span {
    text-align: left;
    position: relative;
  }
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-cont ul li p span::after {
    position: absolute;
    content: '';
    width: 15px;
    height: 15px;
    left: -8px;
    top: 50%;
    transform: translate(0, -50%);
    background-color: #1E4B5E;
    border-radius: 50%;
    box-shadow: 0 0 0 3px rgba(0, 209, 227, 0.42);
    /* background: linear-gradient(180deg, rgba(0, 209, 227, 0.42) 0%, rgba(36, 169, 137, 0.47) 100%); */
  }
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-cont ul li p span::before {
    position: absolute;
    content: '';
    width: 76px;
    height: 3px;
    background: #95A9CB;
    top: 50%;
    transform: translate(0, -50%);
    left: -38px;
  }
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-cont ul li.list-circle-1,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-cont ul li.list-10 {
    justify-content: center;
    z-index: 1;
  }
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-cont ul li.list-circle-1 p,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-cont ul li.list-10 p {
    width: 150px;
    height: 150px;
    position: relative;
    background-color: #57A996;
    border-radius: 50%;
    box-shadow: 0 0 0 5px rgba(0, 209, 227, 0.42);
  }
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-cont ul li.list-circle-1 p span,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-cont ul li.list-10 p span {
    padding: 0;
    text-align: center;
    display: block;
    width: 100%;
    font-size: 22px;
    line-height: 24px;
  }
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-cont ul li.list-circle-1 p span::before,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-cont ul li.list-circle-1 p span::after,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-cont ul li.list-10 p span::before,
  .company-info.section-4.development-process .graphics-wrapper .heading-block .list-cont ul li.list-10 p span::after {
    display: none;
  }
}
.New_Blockchain .section-8.business-solution .solution-info .solution-img img {
  width: 100%;
}
.New_Blockchain .section-8.business-solution .solution-info .solution-img {
  margin-bottom: 0px;
}
.New_Blockchain .main-head-1 .secondary-span {
  font-size: 42px;
  line-height: 52px;
}
.New_Blockchain .row.work-with-row {
  align-items: center;
}
.New_Blockchain .comp-info-right-img {
  margin: 0 auto;
  text-align: center;
}
.New_Blockchain .crypto-otc-development.blue-bg .lets-connect-form {
  background: linear-gradient(90deg, #F7FFFD 0%, #FDFDFD 100%), #F9F9F9;
}
.main-para-div.hide-web {
  display: none;
}
.New_Blockchain .comp-info-right-img img.w-80 {
  width: 80%;
}
.New_Blockchain .comp-info-right-img img.w-120 {
  width: 120%;
}
@media only screen and (max-width: 1200px) and (min-width: 768px) {
  .New_Blockchain .section-1 .main-head-1 .secondary-span br {
    display: block !important;
  }
}
@media only screen and (max-width: 1024px) {
  .New_Blockchain .section-1 .custom-section {
    padding-top: 0px;
  }
  .New_Blockchain .company-info .web-solutions .solution-card .solution-list {
    margin-right: 30px;
    margin-bottom: 30px;
  }
  .New_Blockchain .business-solution .solution-info ul.blockchain-list li i,
  .New_Blockchain .business-solution .solution-info ul.blockchain-list li i::before {
    width: 140px;
    height: 140px;
  }
  .New_Blockchain .business-solution .solution-info ul.blockchain-list li i svg {
    width: 75px;
  }
  .New_Blockchain .business-solution .solution-info ul.blockchain-list li span {
    font-size: 20px;
  }
  .New_Blockchain .business-solution .solution-info .featured-card {
    margin-right: 25px;
  }
}
@media only screen and (max-width: 768px) and (min-width: 320px) {
  .New_Blockchain .row.work-with-row {
    justify-content: center;
    flex-flow: row wrap;
  }
  .New_Blockchain .section-1 .main-head-1 .secondary-span br,
  .main-para-div.hide-mob {
    display: none;
  }
  .main-para-div.hide-web {
    display: block;
    width: 100%;
    margin-top: 40px;
  }
  .New_Blockchain .comp-info-right-img {
    width: 100%;
  }
  .New_Blockchain .right-img-hold {
    margin: auto;
  }
  .New_Blockchain {
    overflow: hidden;
  }
  .section-1.newblockchain-page .main-para-div p {
    width: 75%;
  }
  .section-1.newblockchain-page .main-head-1 .secondary-span {
    font-size: 24px;
  }
  .section-1.newblockchain-page .main-head-1 .primary-span {
    font-size: 40px;
  }
  .New_Blockchain .company-info .global-count .global-info span {
    font-size: 18px;
  }
  .New_Blockchain .company-info .global-count .global-info b {
    font-size: 28px;
  }
  .New_Blockchain .company-info .web-solutions .solution-card .solution-list {
    width: 47%;
  }
  .New_Blockchain .company-info .web-solutions .solution-card .solution-list:nth-of-type(3n) {
    margin-right: 30px;
  }
  .New_Blockchain .company-info .web-solutions .solution-card .solution-list:nth-of-type(2n) {
    margin-right: 0px;
  }
  .New_Blockchain .company-info .web-solutions h3,
  .New_Blockchain .business-solution strong.heading {
    font-size: 30px;
  }
  .New_Blockchain .business-solution .solution-info b {
    font-size: 24px;
  }
  .New_Blockchain .business-solution .solution-info ul.blockchain-list li {
    width: 100%;
  }
  .New_Blockchain .business-solution .solution-info .featured-card {
    width: 47%;
    margin-bottom: 25px;
  }
  .New_Blockchain .business-solution .solution-info .featured-card:nth-of-type(2n) {
    margin-right: 0px;
  }
}
@media screen and (max-width: 567px) and (min-width: 320px) {
  .New_Blockchain .company-info .global-count {
    margin: 40px 0 0 0;
  }
  .New_Blockchain .company-info .customer-list .list-wrap img {
    width: 60%;
  }
}
@media only screen and (max-width: 425px) and (min-width: 320px) {
  .New_Blockchain .custom-section .main-para-div p {
    font-size: 15px !important;
  }
  .New_Blockchain .comp-info-right-img img.w-80,
  .New_Blockchain .comp-info-right-img img.w-120 {
    width: 80% !important;
  }
  .New_Blockchain .company-info .web-solutions .solution-card .solution-list,
  .New_Blockchain .business-solution .solution-info .featured-card {
    width: 100%;
    margin-right: 0px;
  }
  .New_Blockchain .company-info .web-solutions .solution-card .solution-list:nth-of-type(3n),
  .New_Blockchain .business-solution .solution-info .featured-card:nth-of-type(3n) {
    margin-right: 0px;
  }
  .New_Blockchain .company-info strong {
    font-size: 24px;
  }
  .New_Blockchain .business-solution .solution-info p,
  .New_Blockchain .business-solution .solution-info ul li {
    font-size: 16px;
  }
  .New_Blockchain .business-solution .solution-info .cta-btn {
    font-size: 14px;
    padding: 15px;
  }
  .New_Blockchain .business-solution {
    padding: 60px 0px;
  }
  .New_Blockchain .business-solution .solution-info {
    margin: 0px;
  }
  .New_Blockchain .company-info .global-count .global-info {
    margin-bottom: 20px;
  }
  .New_Blockchain .company-info .global-count .global-info:last-child {
    margin-bottom: 0px;
  }
  .New_Blockchain .company-info .global-count {
    margin: 40px 0 0 0;
  }
  .New_Blockchain .company-info .customer-list .list-wrap img {
    width: 60%;
  }
}
@media only screen and (max-width: 1024px) {
  .New_Blockchain .section-3 .new-custom-container {
    width: 95%;
    max-width: 1368px;
  }
}
.New_Blockchain .section-3 ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
@media only screen and (min-width: 1024px) {
  .New_Blockchain .section-3 ul {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 1807px) and (min-width: 1024px) {
  .New_Blockchain .section-3 ul li {
    width: 20%;
  }
  .New_Blockchain .section-3 ul li .main {
    width: 100% !important;
    max-width: 277px;
    height: auto !important;
    background-image: url(/assets/img/web3/web3bg.svg) !important;
    background-position: center !important;
    background-size: 100% !important;
    background-repeat: no-repeat !important;
    padding: 5px;
  }
  .New_Blockchain .section-3 ul li .main .inner {
    background-image: url(/assets/img/web3/web3bgout.svg) !important;
    background-position: center !important;
    background-size: 100% !important;
    background-repeat: no-repeat !important;
  }
  .New_Blockchain .section-3 ul li .main .inner div p {
    font-size: 16px !important;
  }
}
@media only screen and (max-width: 1050px) and (min-width: 991px) {
  .New_Blockchain .section-3 ul li .main .inner div i {
    margin-bottom: 10px !important;
  }
  .New_Blockchain .section-3 ul li .main .inner div i svg {
    width: 50px;
    height: 50px;
    margin-top: 10px;
  }
  .New_Blockchain .section-3 ul li .main .inner div p {
    font-size: 15px !important;
    line-height: 17px;
  }
}
.New_Blockchain .section-3 ul li .main {
  width: 277px;
  max-width: 277px;
  height: 251px;
  background-image: url(/assets/img/web3/web3bg.png);
  align-items: center;
  margin-bottom: 30px;
  filter: drop-shadow(26px 31px 15px #E4EDEA);
}
.New_Blockchain .section-3 ul li .main .inner {
  width: 100%;
  max-width: 256px;
  height: 230px;
  background-image: url(/assets/img/web3/web3bgout.png);
  align-items: center;
}
.New_Blockchain .section-3 ul li .main .inner div {
  text-align: center;
}
.New_Blockchain .section-3 ul li .main .inner div i {
  margin-bottom: 20px;
}
.New_Blockchain .section-3 ul li .main .inner div p {
  color: #1E4B5E;
  font-family: "Heldane display";
  font-size: 22px;
}
@media only screen and (max-width: 1344px) and (min-width: 768px) {
  .New_Blockchain .section-3 ul li .main .inner div p br {
    display: block !important;
  }
}
.web3-process .sub-heading {
  position: relative;
  text-align: center;
}
.web3-process .sub-heading span {
  color: #1E4B5E;
  font-family: "Heldane display";
  padding: 13px 0px;
  font-size: 22px;
  font-weight: 800;
  line-height: 130%;
  display: inline-flex;
  position: relative;
}
.web3-process .sub-heading span::after,
.web3-process .sub-heading span::before {
  position: absolute;
  width: 100%;
  height: 2px;
  content: '';
  left: 0;
  top: 0;
  background-image: linear-gradient(to right, #1B816800, #1B8168, #1B816800);
}
.web3-process .sub-heading span::after {
  top: auto;
  bottom: 0;
}
.web3-process .main-row .svg-wrp {
  position: relative;
  margin: 50px auto 20px;
}
.web3-process .main-row .svg-wrp .product_discovery {
  width: 25%;
}
.web3-process .main-row .svg-wrp .product_discovery_2 {
  width: 20%;
}
.web3-process .main-row .svg-wrp .svg {
  width: 100%;
  height: auto;
}
@media only screen and (max-width: 1360px) and (min-width: 992px) {
  .web3-process .main-row .svg-wrp .svg img {
    width: 40%;
  }
}
@media only screen and (max-width: 600px) {
  .web3-process .main-row .svg-wrp .svg img {
    width: 50%;
  }
}
.web3-process .main-row .svg-wrp ul {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.web3-process .main-row .svg-wrp ul li {
  position: absolute;
}
.web3-process .main-row .svg-wrp ul li span {
  font-family: 'Object Sans Heavy';
  color: #1E4B5E;
  text-align: right;
  font-size: 18px;
  line-height: 20px;
  display: inline-flex;
}
@media only screen and (max-width: 1344px) and (min-width: 768px) {
  .web3-process .main-row .svg-wrp ul li span br {
    display: block !important;
  }
}
@media only screen and (max-width: 1344px) and (min-width: 992px) {
  .web3-process .main-row .svg-wrp ul li span {
    font-size: 15px;
  }
}
@media only screen and (max-width: 991px) and (min-width: 769px) {
  .web3-process .main-row .svg-wrp ul li span {
    font-size: 17px;
  }
}
@media only screen and (max-width: 600px) {
  .web3-process .main-row .svg-wrp ul li span {
    font-size: 15px;
    line-height: 20px;
  }
}
@media only screen and (max-width: 425px) {
  .web3-process .main-row .svg-wrp ul li span {
    font-size: 11px;
    line-height: 15px;
  }
}
@media only screen and (min-width: 1440px) {
  .web3-process .main-row .left .svg-wrp {
    max-width: 548px;
  }
}
.web3-process .main-row .left .svg-wrp ul li:nth-child(1) {
  top: 10%;
  left: 0;
}
.web3-process .main-row .left .svg-wrp ul li:nth-child(2) {
  top: 45%;
  right: 0;
}
.web3-process .main-row .left .svg-wrp ul li:nth-child(2) span {
  text-align: left;
}
.web3-process .main-row .left .svg-wrp ul li:nth-child(3) {
  bottom: 10%;
  left: 0;
}
@media only screen and (max-width: 1344px) and (min-width: 992px) {
  .web3-process .main-row .left .svg-wrp ul li:nth-child(1) {
    top: 7%;
    left: 30px;
  }
  .web3-process .main-row .left .svg-wrp ul li:nth-child(2) {
    top: 42%;
    right: 20px;
  }
  .web3-process .main-row .left .svg-wrp ul li:nth-child(3) {
    left: 30px;
    bottom: 5%;
  }
}
.web3-process .main-row .right {
  position: relative;
}
.web3-process .main-row .right::before {
  position: absolute;
  width: 2px;
  height: 100%;
  content: '';
  left: 0;
  top: 0;
  background-image: linear-gradient(#1B816800, #1B8168, #1B816800);
}
@media only screen and (max-width: 991px) {
  .web3-process .main-row .right::before {
    display: none;
  }
}
@media only screen and (min-width: 1440px) {
  .web3-process .main-row .right .svg-wrp {
    max-width: 575px;
  }
}
.web3-process .main-row .right .svg-wrp ul li:nth-child(1) {
  top: 6%;
  left: 0;
}
.web3-process .main-row .right .svg-wrp ul li:nth-child(2) {
  top: 28.5%;
  right: 13%;
}
.web3-process .main-row .right .svg-wrp ul li:nth-child(3) {
  top: 49%;
  left: 0;
}
.web3-process .main-row .right .svg-wrp ul li:nth-child(4) {
  bottom: 27%;
  right: 13%;
}
.web3-process .main-row .right .svg-wrp ul li:nth-child(5) {
  bottom: 3.5%;
  left: 0;
}
@media only screen and (max-width: 1344px) and (min-width: 992px) {
  .web3-process .main-row .right .svg-wrp ul li:nth-child(1) {
    top: 4%;
    left: 30px;
  }
  .web3-process .main-row .right .svg-wrp ul li:nth-child(3) {
    left: 40px;
  }
  .web3-process .main-row .right .svg-wrp ul li:nth-child(5) {
    left: 35px;
  }
}
.faq-listwrp .parent .child {
  height: 0;
  visibility: hidden;
  transform: all 0.5s;
}
.faq-listwrp .parent.active .child {
  height: auto;
  visibility: visible;
}
.faq-listwrp .faq-list {
  padding: 0px;
  border-radius: 8px !important;
  border: none;
  box-shadow: none;
  background: transparent !important;
  margin-bottom: 0px;
}
.faq-listwrp .faq-list::before {
  display: none;
}
.faq-listwrp .faq-list.Mui-expanded {
  margin: 0px !important;
  margin-bottom: 40px !important;
}
.faq-listwrp .faq-list .summary {
  border-bottom: 0px solid #9CDDCD;
  padding: 20px 0px !important;
  min-height: auto !important;
}
.faq-listwrp .faq-list .summary.Mui-expanded {
  border-bottom: 0px solid transparent;
}
.faq-listwrp .faq-list .summary .MuiAccordionSummary-content {
  margin: 0px !important;
}
.faq-listwrp .faq-list .summary .MuiAccordionSummary-content .summary-content {
  margin: 0px !important;
  font-size: 20px;
  line-height: 32px;
  font-family: 'Object Sans Heavy';
  color: #386A80;
  font-weight: 800;
  display: flex;
  align-items: flex-start;
}
.faq-listwrp .faq-list .summary .MuiAccordionSummary-content .summary-content .query-count {
  width: 30px;
  min-width: 30px;
  display: inline-block;
}
.faq-listwrp .faq-list .faq-details {
  padding: 20px 0px;
}
.faq-listwrp .faq-list .faq-details P {
  font-family: Object Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  color: #1E4B5E;
}
.faq-listwrp .faq-list .MuiCollapse-entered .faq-details {
  border-bottom: 0px solid #9CDDCD;
}
@media only screen and (max-width: 600px) {
  .faq-listwrp .faq-list {
    margin-bottom: 20px;
  }
  .faq-listwrp .faq-list.Mui-expanded {
    margin-bottom: 20px !important;
  }
  .faq-listwrp .faq-list .summary {
    padding: 15px !important;
  }
  .faq-listwrp .faq-list .summary .MuiAccordionSummary-content .summary-content {
    font-size: 20px;
    line-height: 30px;
  }
  .faq-listwrp .faq-list .faq-details {
    padding: 15px;
  }
  .faq-listwrp .faq-list .faq-details P {
    font-size: 15px;
    line-height: 20px;
  }
}
.faq-listwrp .btn-wrp {
  margin-top: 20px;
}
.faq-listwrp .btn {
  border-radius: 4px;
  padding: 14px 30px;
  border: 2px solid #2CC5A0;
  color: #2CC5A0;
  font-family: Object Sans;
  font-size: 20px;
  box-shadow: none;
  font-weight: 800;
  line-height: 130%;
  outline: none;
}
.faq-listwrp .btn:hover {
  color: #2CC5A0;
}
.faq-listwrp .btn:focus {
  box-shadow: none;
}
@media only screen and (max-width: 600px) {
  .faq-listwrp .btn {
    font-size: 14px;
    line-height: 20px;
    padding: 16px 25px;
  }
}
.develop-sol-new .custom-container.new-common-container .main-head-div {
  margin-bottom: 30px;
}
.develop-sol-new .main-wrp {
  align-items: center;
}
.develop-sol-new .main-wrp ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.develop-sol-new .main-wrp ul li {
  position: relative;
  margin-bottom: 10px;
  width: 95%;
}
.develop-sol-new .main-wrp ul li .main .cont-wrp {
  display: flex;
  align-items: center;
  padding: 5px 30px;
}
.develop-sol-new .main-wrp ul li .main .cont-wrp .img-wrp img {
  width: 66px;
  height: 66px;
}
.develop-sol-new .main-wrp ul li .main .cont-wrp .cont {
  font-family: Object Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 800;
  line-height: 30px;
  color: white;
  max-width: 230px;
}
@media only screen and (max-width: 1344px) and (min-width: 768px) {
  .develop-sol-new .main-wrp ul li .main .cont-wrp .cont br {
    display: block !important;
  }
}
@media only screen and (max-width: 1360px) and (min-width: 992px) {
  .develop-sol-new .main-wrp ul li .main .cont-wrp .cont {
    font-size: 15px;
  }
}
@media only screen and (max-width: 991px) {
  .develop-sol-new .main-wrp ul li .main .cont-wrp .cont {
    font-size: 18px;
    max-width: auto !important;
  }
}
@media only screen and (max-width: 600px) {
  .develop-sol-new .main-wrp ul li .main .cont-wrp {
    padding: 15px 15px;
    min-height: 73px;
  }
  .develop-sol-new .main-wrp ul li .main .cont-wrp .img-wrp img {
    width: 33px;
    height: 33px;
  }
  .develop-sol-new .main-wrp ul li .main .cont-wrp .cont {
    font-size: 15px;
  }
}
.develop-sol-new .main-wrp .left ul li.enbmob {
  display: none;
}
@media only screen and (max-width: 991px) {
  .develop-sol-new .main-wrp .left ul li.enbmob {
    display: block;
  }
}
.develop-sol-new .main-wrp .left ul li .main {
  position: relative;
}
.develop-sol-new .main-wrp .left ul li .main .cont-wrp {
  width: 100%;
  max-width: 400px;
  line-height: 30px;
  background: #57A996;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1));
}
.develop-sol-new .main-wrp .left ul li .main .cont-wrp::before,
.develop-sol-new .main-wrp .left ul li .main .cont-wrp::after {
  content: "";
  position: absolute;
}
.develop-sol-new .main-wrp .left ul li .main .cont-wrp::before {
  height: 0;
  width: 0;
  top: -9.5px;
  left: 0.1px;
  border-bottom: 10px solid #9DDECF;
  border-left: 10px solid transparent;
}
.develop-sol-new .main-wrp .left ul li .main .cont-wrp::after {
  height: 0;
  width: 0;
  right: -40px;
  top: 0;
  border-top: 36px solid transparent;
  border-bottom: 40px solid transparent;
  border-left: 40px solid #57A996;
}
@media only screen and (max-width: 600px) {
  .develop-sol-new .main-wrp .left ul li .main .cont-wrp::after {
    right: -40px;
    border-top: 33px solid transparent;
    border-bottom: 40px solid transparent;
    border-left: 40px solid #57A996;
  }
}
.develop-sol-new .main-wrp .left ul li .main .cont-wrp .img-wrp {
  margin-right: 15px;
}
@media only screen and (max-width: 991px) {
  .develop-sol-new .main-wrp .right {
    display: none;
  }
}
.develop-sol-new .main-wrp .right ul li .main {
  position: relative;
}
.develop-sol-new .main-wrp .right ul li .main .cont-wrp {
  justify-content: end;
  width: 100%;
  max-width: 400px;
  line-height: 50px;
  background: #57A996;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1));
}
.develop-sol-new .main-wrp .right ul li .main .cont-wrp::before,
.develop-sol-new .main-wrp .right ul li .main .cont-wrp::after {
  content: "";
  position: absolute;
}
.develop-sol-new .main-wrp .right ul li .main .cont-wrp::before {
  height: 0;
  width: 0;
  left: -40px;
  top: 0;
  border-top: 36px solid transparent;
  border-bottom: 40px solid transparent;
  border-right: 40px solid #57A996;
}
.develop-sol-new .main-wrp .right ul li .main .cont-wrp::after {
  height: 0;
  width: 0;
  top: -9.5px;
  right: 0.1px;
  border-bottom: 10px solid #9DDECF;
  border-right: 10px solid transparent;
}
.develop-sol-new .main-wrp .right ul li .main .cont-wrp .cont {
  margin-right: 15px;
}
.section-5.block-chain-dev-solution {
  padding-top: 0;
}
@media only screen and (max-width: 1100px) and (min-width: 1025px) {
  .section-1.newblockchain-page .custom-section {
    padding-top: 30px;
  }
  .section-1.newblockchain-page .custom-section .main-head-div {
    margin-bottom: 35px;
  }
  .section-1.newblockchain-page .custom-section .main-head-div p {
    margin-bottom: 33px;
  }
}
@media only screen and (max-width: 768px) and (min-width: 320px) {
  .New_Blockchain .section-5.development-solution .content .list label {
    width: 100%;
    min-width: 100%;
    padding: 0;
  }
  .New_Blockchain .section-5.development-solution .content .list label {
    border-bottom: 1px solid #24a989;
    padding: 20px;
  }
  .New_Blockchain .section-5.development-solution .content .list {
    flex-flow: nowrap;
    overflow: auto;
  }
  .New_Blockchain .section-5.development-solution #nft-solution:checked ~ .list .slider {
    top: 0;
    width: 200px;
    left: 0;
    height: 50px;
  }
  .New_Blockchain .section-5.development-solution #dao:checked ~ .list .slider {
    top: 0;
    left: 200px;
    width: 200px;
    height: 50px;
  }
  .New_Blockchain .section-5.development-solution #metaverse-development:checked ~ .list .slider {
    left: 400px;
    top: 0;
    width: 200px;
    height: 50px;
  }
  .New_Blockchain .section-5.development-solution #defi-solition:checked ~ .list .slider {
    top: 0;
    left: 600px;
    width: 200px;
    height: 50px;
  }
  .New_Blockchain .section-5.development-solution #web3-colsulting:checked ~ .list .slider {
    top: 0;
    left: 800px;
    width: 200px;
    height: 50px;
  }
  .New_Blockchain .section-5.development-solution #dapps:checked ~ .list .slider {
    left: 1000px;
    width: 200px;
    top: 0;
    height: 50px;
  }
  .New_Blockchain .New_Blockchain .company-info .global-count {
    padding-bottom: 0;
  }
  .New_Blockchain .section-5.development-solution .content .list label {
    height: 50px;
    font-size: 15px;
    min-width: 200px;
    width: 200px;
    line-height: 18px;
  }
  .New_Blockchain .section-5.development-solution .content .list label span svg,
  .New_Blockchain .section-5.development-solution .content .list label span img {
    width: 40px;
    height: 40px;
  }
}
@media only screen and (max-width: 567px) and (min-width: 320px) {
  .New_Blockchain .main-head-div.heading_lines .main-head-1 .primary-span,
  .New_Blockchain .main-head-div.heading_lines .main-head-1 .primary-span h2 {
    font-size: 25px;
    line-height: 32px;
  }
  .New_Blockchain .web3-process .sub-heading span {
    font-size: 22px;
  }
  .New_Blockchain .section-5.development-solution .text h3 {
    font-size: 18px;
  }
  .New_Blockchain .section-5.development-solution .text p,
  .New_Blockchain .section-5.development-solution .description {
    font-size: 15px;
  }
  .New_Blockchain .section-5.development-solution .text p::after {
    top: 5px;
  }
  .New_Blockchain .New_Blockchain .business-solution .solution-info .featured-card .featured-content b,
  .New_Blockchain .faq-listwrp .faq-list .summary .MuiAccordionSummary-content .summary-content {
    font-size: 16px;
    line-height: 26px;
  }
  .New_Blockchain .develop-sol-new .main-wrp .left ul li .main .cont-wrp {
    width: 90%;
  }
  .New_Blockchain .develop-sol-new .main-wrp ul li .main .cont-wrp .cont {
    font-size: 13px;
    line-height: 18px;
  }
  .New_Blockchain .section-5.development-solution .MuiAccordionSummary-expandIcon {
    transform: scale(0.6);
  }
  .New_Blockchain .section-6.featured-insights .solution-info .featured-card strong {
    font-size: 17px;
  }
}
