.design-studio-wrap.design-process a {
  color: #fff;
}
.design-studio-wrap.design-process .first-inner h1 {
  justify-content: center;
  padding-bottom: 40px;
}
.design-studio-wrap.design-process .first-inner h2 {
  text-align: center;
}
.design-studio-wrap.design-process .first-inner p {
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
  width: 80%;
  margin: 30px auto 0 auto;
  text-transform: none;
}
.design-studio-wrap.design-process .design-precess-steps {
  width: 85%;
  margin: 0 auto;
  padding: 100px 0;
}
.design-studio-wrap.design-process .design-precess-steps ul {
  list-style: none;
  padding: 0;
}
.design-studio-wrap.design-process .design-precess-steps ul li {
  display: flex;
  align-items: center;
  padding-bottom: 150px;
  background-size: 16%;
}
.design-studio-wrap.design-process .design-precess-steps ul li:last-child {
  padding-bottom: 0;
}
.design-studio-wrap.design-process .design-precess-steps ul li .design-img {
  width: 50%;
}
.design-studio-wrap.design-process .design-precess-steps ul li .design-img img {
  width: 95%;
  border-radius: 8px;
  padding: 4px;
}
.design-studio-wrap.design-process .design-precess-steps ul li .design-explanation {
  width: 50%;
}
.design-studio-wrap.design-process .design-precess-steps ul li .design-explanation h2 {
  font-family: Inter;
  font-size: 40px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 20px;
  color: #1a1a1a;
  width: 95%;
}
.design-studio-wrap.design-process .design-precess-steps ul li .design-explanation p {
  font-family: Inter;
  font-size: 19px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #2a2a2a;
  width: 100%;
}
.design-studio-wrap.design-process .design-precess-steps ul li:nth-child(odd) {
  background-image: url(/assets/img/design-studio/design-process/curve_1.png);
  background-repeat: no-repeat;
  background-position: 45% 100%;
}
.design-studio-wrap.design-process .design-precess-steps ul li:nth-child(even) {
  background-image: url(/assets/img/design-studio/design-process/curve_2.png);
  background-repeat: no-repeat;
  background-position: 45% 100%;
}
.design-studio-wrap.design-process .design-precess-steps ul li:last-child {
  background-image: none;
}
.design-studio-wrap.design-process .contact-us {
  background: url("/assets/img/design-studio/contact-bg.png") no-repeat;
  display: flex;
  justify-content: center;
  background-size: cover;
  padding: 100px 0;
}
.design-studio-wrap.design-process .contact-us .contact-wrap {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 90%;
  margin: 0 auto;
}
.design-studio-wrap.design-process .contact-us .contact-wrap .flex-container {
  display: flex;
  justify-content: center;
  min-width: 90%;
}
.design-studio-wrap.design-process .contact-us .contact-wrap .flex-container .scroll-sec1 {
  flex: 0 0 50%;
  border-width: 1px;
  border: double 1px transparent;
  border-radius: 10px;
  border-left: 1px solid #1dc1b3cc;
  border-right: 1px solid #dc477fcc;
  background-image: linear-gradient(90deg, #1dc1b3cc, #dc477fcc), linear-gradient(90deg, #1dc1b3cc, #dc477fcc);
  background-size: 100% 1px;
  background-position: 0 0, 0 100%;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
}
.design-studio-wrap.design-process .contact-us .contact-wrap .flex-container .scroll-sec1::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: -1;
  border-radius: 15px;
}
.design-studio-wrap.design-process .contact-us .contact-wrap .flex-container .scroll-sec2 {
  flex: 0 0 50%;
  border-width: 1px;
  border-radius: 10px;
  padding: 50px 80px;
  border-left: 1px solid #1dc1b3cc;
  border-right: 1px solid #dc477fcc;
  background-image: linear-gradient(90deg, #1dc1b3cc, #dc477fcc), linear-gradient(90deg, #1dc1b3cc, #dc477fcc);
  background-size: 100% 1px;
  background-position: 0 0, 0 100%;
  background-repeat: no-repeat;
  margin-left: 30px;
  position: relative;
}
.design-studio-wrap.design-process .contact-us .contact-wrap .flex-container .scroll-sec2::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(90.9deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.04) 100%);
  z-index: 0;
  border-radius: 10px;
  background-size: 100%;
}
.design-studio-wrap.design-process .contact-us .contact-detail h2 {
  padding: 30px 50px 10px;
  font-size: 54px;
  background: url("/assets/img/design-studio/good-design-bg.png");
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0;
}
.design-studio-wrap.design-process .contact-us .contact-detail a {
  color: #FFFFFF;
}
.design-studio-wrap.design-process .contact-us .contact-detail ul {
  margin-bottom: 0;
  list-style: none;
  padding: 30px 50px;
}
.design-studio-wrap.design-process .contact-us .contact-detail ul h5,
.design-studio-wrap.design-process .contact-us .contact-detail ul .follow-us-head {
  font-family: Inter;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: -0.02em;
  text-align: left;
  color: #fff;
  margin: 60px 0 40px;
}
.design-studio-wrap.design-process .contact-us .contact-detail ul .follow-us-head {
  margin: 0;
}
.design-studio-wrap.design-process .contact-us .contact-detail ul .follow-us-head h4 {
  position: relative;
  font-family: Inter;
  font-size: 28px;
  font-weight: 400;
  line-height: 97px;
  letter-spacing: -0.02em;
  text-align: left;
}
.design-studio-wrap.design-process .contact-us .contact-detail ul .follow-us-head h4::before {
  content: "";
  position: absolute;
  bottom: 12px;
  left: 0;
  width: 250px;
  height: 1px;
  border: 0px solid;
  background: linear-gradient(270deg, rgba(36, 196, 181, 0) 0%, #24c4b5 100%);
}
.design-studio-wrap.design-process .contact-us .contact-detail ul li {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
.design-studio-wrap.design-process .contact-us .contact-detail ul li svg {
  margin-right: 12px;
  min-width: 25px;
}
.design-studio-wrap.design-process .contact-us .contact-detail ul li:nth-of-type(3) {
  align-items: flex-start;
}
.design-studio-wrap.design-process .contact-us .contact-detail ul li span {
  display: -webkit-inline-box;
  width: 92%;
  margin-left: auto;
  color: #ffffff;
  line-height: 35px;
  font-size: 20px;
}
.design-studio-wrap.design-process .contact-us .contact-detail ul li .follow-us-common {
  width: 100%;
}
.design-studio-wrap.design-process .contact-us .contact-detail ul li .follow-us-common ul.follow-us-ul {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
}
.design-studio-wrap.design-process .contact-us .contact-detail ul li .follow-us-common ul.follow-us-ul li {
  margin-bottom: 0;
}
.design-studio-wrap.design-process .contact-us .contact-detail ul li .follow-us-common ul.follow-us-ul li a span svg {
  width: 40px;
  height: 40px;
}
.design-studio-wrap.design-process .contact-us .contact-form .form-group {
  position: relative;
  margin-bottom: 60px;
  margin-top: 20px;
  /* Optional: Change border color when input is focused */
}
.design-studio-wrap.design-process .contact-us .contact-form .form-group:last-child {
  margin-bottom: 0;
}
.design-studio-wrap.design-process .contact-us .contact-form .form-group button {
  border-width: 1px;
  border-radius: 7px;
  padding: 12px 50px;
  border-left: 1px solid #1dc1b3cc;
  border-right: 1px solid #dc477fcc;
  background-image: linear-gradient(90deg, #1dc1b3cc, #dc477fcc), linear-gradient(90deg, #1dc1b3cc, #dc477fcc);
  background-size: 100% 1px;
  background-position: 0 0, 0 100%;
  background-repeat: no-repeat;
  font-size: 18px;
  background-color: #00000000;
  color: #fff;
  margin-top: 20px;
  box-shadow: 0px 5px 20px 0px #D84D814D;
  box-shadow: 5px 0px 20px 0px #2bc3b64d;
}
.design-studio-wrap.design-process .contact-us .contact-form .form-group .label {
  font-size: 20px;
  position: absolute;
  top: 10px;
  left: 0;
  transition: transform 0.3s ease-in-out;
  pointer-events: none;
  color: #fff;
}
.design-studio-wrap.design-process .contact-us .contact-form .form-group .input {
  width: 100%;
  padding: 10px 0;
  border: 0;
  transition: border-color 0.3s ease-in-out;
  background: transparent;
  border-bottom: 1px solid #fff;
  outline: none;
  resize: none;
  font-size: 20px;
  color: #fff;
}
.design-studio-wrap.design-process .contact-us .contact-form .form-group .input:focus {
  border-color: #fff;
}
.design-studio-wrap.design-process .contact-us .contact-form .form-group textarea {
  min-height: 45px;
  max-height: 45px;
  line-height: 18px;
}
.design-studio-wrap.design-process .contact-us .contact-form .form-group .input:focus + .label,
.design-studio-wrap.design-process .contact-us .contact-form .form-group .input.filled + .label {
  transform: translateY(-20px);
  font-size: 16px;
  /* optional: reduce font size when label is translated */
}
.design-studio-wrap.design-process .contact-us .contact-form .error-message {
  position: absolute;
  bottom: 70px;
  color: red;
}
.design-studio-wrap.design-process section.design-process-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
  padding: 20px 0;
}
@media only screen and (max-width: 1024px) and (min-width: 320px) {
  .design-studio-wrap.design-process .design-precess-steps ul li {
    background-size: 130px;
  }
  .design-studio-wrap.design-process .contact-us .scroll-sec2 {
    flex: 0 0 45%;
  }
}
@media only screen and (max-width: 767px) and (min-width: 320px) {
  .design-studio-wrap.design-process .design-precess-steps ul li .design-explanation h2 {
    font-size: 35px;
  }
  .design-studio-wrap.design-process .design-precess-steps ul li .design-explanation p {
    font-size: 18px;
    text-align: justify;
  }
  .design-studio-wrap.design-process .contact-us,
  .design-studio-wrap.design-process .contact-us .contact-detail ul li .follow-us-common ul.follow-us-ul {
    flex-flow: row wrap;
  }
  .design-studio-wrap.design-process .contact-us .scroll-sec2,
  .design-studio-wrap.design-process .contact-us .contact-detail {
    flex: 0 0 85%;
    margin: 0 auto 50px;
    width: 80%;
  }
  .design-studio-wrap.design-process .contact-us .scroll-sec2 {
    padding: 30px;
  }
  .design-studio-wrap.design-process .contact-us .contact-form .form-group button {
    width: 50%;
  }
  .design-studio-wrap.design-process .contact-us .contact-detail h2 {
    font-size: 35px;
    padding: 20px;
  }
  .design-studio-wrap.design-process .contact-us .contact-detail ul {
    padding: 20px;
  }
  .design-studio-wrap.design-process .contact-us .contact-detail ul h5 {
    font-size: 22px;
  }
  .design-studio-wrap.design-process .contact-us .contact-detail ul .follow-us-head h4 {
    font-size: 20px;
  }
  .design-studio-wrap.design-process .contact-us .contact-detail ul li span {
    font-size: 14px;
  }
  .design-studio-wrap.design-process .contact-us .scroll-sec2 {
    padding: 30px;
  }
  .design-studio-wrap.design-process .first-inner h1 img {
    width: 150px;
  }
  .design-studio-wrap.design-process .design-precess-steps ul li {
    flex-flow: row wrap;
  }
  .design-studio-wrap.design-process .design-precess-steps ul li .design-img,
  .design-studio-wrap.design-process .design-precess-steps ul li .design-img img,
  .design-studio-wrap.design-process .design-precess-steps ul li .design-explanation {
    width: 100%;
  }
  .design-studio-wrap.design-process .design-precess-steps ul li .design-explanation h2,
  .design-studio-wrap.design-process .design-precess-steps ul li .design-explanation p {
    width: 100%;
  }
  .design-studio-wrap.design-process .first-inner h2 {
    font-size: 48px;
  }
  .design-studio-wrap.design-process section.design-process-footer svg {
    width: 100px;
  }
  .design-studio-wrap.design-process .contact-us .contact-wrap .flex-container {
    flex-flow: row wrap;
  }
  .design-studio-wrap.design-process .contact-us .contact-wrap .flex-container .scroll-sec1,
  .design-studio-wrap.design-process .contact-us .contact-wrap .flex-container .scroll-sec2 {
    flex: 0 0 100%;
    margin-bottom: 50px;
    margin-left: 0;
  }
  .design-studio-wrap.design-process section.design-process-footer {
    flex-direction: column;
  }
  .design-studio-wrap.design-process section.design-process-footer.footer-right-link {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
  .design-studio-wrap.design-process section.design-process-footer.footer-right-link span {
    display: flex;
    width: 100%;
  }
}
