.tag-group {
  width: 100%;
}
.tag-group .tab-header {
  display: flex;
  width: 100%;
}
.tag-group .tab-header ul {
  display: flex;
  justify-content: flex-start;
  margin: 0px;
  padding: 0px;
  width: 100%;
  overflow-x: auto;
  border-bottom: 5px solid #FFCC4A;
  border-radius: 4px;
  /* Track */
  /* Handle */
}
.tag-group .tab-header ul::-webkit-scrollbar {
  height: 5px;
  position: absolute;
  left: 0;
  bottom: 0px;
}
.tag-group .tab-header ul::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.tag-group .tab-header ul::-webkit-scrollbar-thumb {
  background: #FFCC4A;
}
.tag-group .tab-header ul li {
  list-style-type: none;
  display: inline-flex;
  padding: 0px 15px 15px 15px;
  position: relative;
}
.tag-group .tab-header ul li img {
  filter: grayscale(100%);
  cursor: pointer;
  width: 90%;
}
.tag-group .tab-header ul li.active img {
  filter: grayscale(0);
}
.tag-group .tab-header ul li.active::after {
  content: '';
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 5px;
  background: #1E4B5E;
  left: 0;
  z-index: 1;
  border-radius: 30px;
}
.tag-group .tab-content {
  padding: 30px 0px;
}
.tag-group .tab-content .inner-content {
  position: relative;
}
.tag-group .tab-content .inner-content::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0px;
  width: 100%;
  background: rgba(20, 81, 82, 0.3);
}
.tag-group .tab-content .inner-content img {
  width: 100%;
}
.tag-group .tab-content .inner-content .inner-detail {
  position: absolute;
  left: 10%;
  bottom: 30%;
  background: #fff;
  padding: 30px;
  z-index: 1;
  width: 40%;
  border-top-left-radius: 50px;
  border-bottom-right-radius: 50px;
  display: flex;
  flex-wrap: wrap;
}
.tag-group .tab-content .inner-content .inner-detail b {
  color: #1E4B5E;
  width: 100%;
  line-height: 24px;
  font-family: "Object Sans Heavy";
  margin-bottom: 20px;
}
.tag-group .tab-content .inner-content .inner-detail a {
  font-family: "Object Sans";
  font-weight: 800;
  font-size: 14px;
  display: flex;
}
.tag-group .tab-content .inner-content .inner-detail a i {
  display: inline-flex;
}
.tag-group .tab-content .tab-inactive {
  display: none;
}
.tag-group .tab-content .tab-active {
  display: block;
}
