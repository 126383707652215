.about-us .section-1 {
  background-image: url(/assets/img/about-us/about-us-new-bg.png);
  height: 100vh;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center right;
}
.about-us .section-1:after {
  position: absolute;
  background: linear-gradient(90deg, #FFFBF1 0%, #F9F9F9 50%);
  width: 100%;
  height: 100vh;
  z-index: -1;
}
.about-us .main-para-div.main-para-intro p {
  font-family: 'Object Sans Heavy';
  font-size: 24px;
  line-height: 30px;
  color: #1E4B5E;
}
.about-us .main-para-intro.short-div {
  margin: 0 auto;
  width: 820px;
}
.about-us .main-para-div.main-para-intro {
  position: relative;
}
.about-us .main-para-div.main-para-intro:before {
  background-image: url(/assets/img/market-place-imgs/quote.png);
  background-repeat: no-repeat;
  background-position: left;
  content: '';
  position: absolute;
  width: 75px;
  height: 75px;
  left: -90px;
  top: -25px;
}
.about-us .main-para-div.main-para-intro:after {
  background-image: url(/assets/img/market-place-imgs/quote.png);
  background-repeat: no-repeat;
  background-position: left;
  content: '';
  position: absolute;
  width: 75px;
  height: 75px;
  right: -90px;
  top: auto;
  transform: rotate(180deg);
  bottom: -15px;
}
.about-us .principles-common-ul {
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.about-us .principles-common-ul li {
  list-style: none;
  width: 45%;
}
.about-us .principles-common-ul .principles-info-hold h4 {
  font-family: 'Heldane Display';
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 52px;
  max-width: 460px;
  color: #1E4B5E;
}
.about-us .principles-common-ul .principles-info-hold h4 span {
  background-position: 0 34px;
  background-size: 40px 10px;
  background-repeat: repeat-x;
}
.about-us .principles-common-ul .principles-info-hold p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #1E4B5E;
  max-width: 480px;
}
.about-us .team-profile-ul {
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
}
.about-us .team-profile-ul li {
  list-style: none;
  width: 33.33%;
  margin-bottom: 30px;
}
.about-us .team-profile-ul li .prof-pic-info {
  display: inline-flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  min-height: 100px;
}
.about-us .team-profile-ul li .prof-pic-info h4 {
  font-family: 'Heldane Display';
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 37px;
  display: flex;
  max-width: 180px;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  color: #24A989;
  padding-bottom: 5px;
}
.about-us .team-profile-ul li .prof-pic-info p {
  font-family: 'Object Sans Heavy';
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #1E4B5E;
  margin: 0 auto;
  line-height: 25px;
}
.about-us .prof-pic-hold {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 145px;
  margin: 0 auto;
}
.about-us .img-prof-pic {
  width: 150px;
  height: 145px;
  margin: 0 auto;
}
.about-us .img-prof-pic img {
  width: 100%;
  border-radius: 50%;
}
.about-us .professionals-ul {
  padding: 0px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 0px;
}
.about-us .professionals-ul li {
  display: flex;
  align-items: center;
  width: 50%;
  margin-bottom: 50px;
}
.about-us .professionals-ul li:nth-child(11),
.about-us .professionals-ul li:nth-child(12) {
  margin-bottom: 0px;
}
.about-us .professionals-ul li .professionals-info {
  margin-left: 15px;
}
.about-us .professionals-ul li .professionals-info h4 {
  font-family: 'Heldane Display';
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 37px;
  color: #1E4B5E;
  margin-bottom: 0;
}
.about-us .expert-talk-hold p {
  font-size: 18px;
  line-height: 22px;
  color: #1E4B5E;
  margin-bottom: 30px;
}
.about-us .expert-talk-hold button {
  min-width: 190px;
  min-height: 60px;
  background: #FFCC4A;
  border: 1px solid #FFCC4A;
  border-radius: 3px;
}
.about-us .expert-talk-hold button span {
  font-family: 'Object Sans Heavy';
  font-size: 18px;
  line-height: 19px;
  text-align: center;
  color: #1E4B5E;
}
.about-us .award-common-hold .award-card-ul {
  padding: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.about-us .award-common-hold .award-card-ul li {
  list-style: none;
  width: 27.33%;
  margin-bottom: 50px;
  min-height: 306px;
}
.about-us .award-common-hold .award-card-ul li .award-card {
  width: 180px;
  height: 180px;
  background: #fff;
  border-radius: 50%;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}
.about-us .award-common-hold .award-card-ul li .award-card .award-card-img-hold {
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-us .award-common-hold .award-card-ul li .award-card .award-card-img-hold img {
  width: 100%;
}
.about-us .award-common-hold .award-card-ul li .award-info {
  cursor: pointer;
}
.about-us .award-common-hold .award-card-ul li .award-info h4 {
  font-family: 'Heldane Display';
  font-size: 30px;
  line-height: 37px;
  color: #1E4B5E;
  margin-bottom: 15px;
  width: 305px;
}
.about-us .award-common-hold .award-card-ul li .award-info p {
  font-family: 'Object Sans Heavy';
  font-size: 18px;
  line-height: 22px;
  color: #7E97A1;
  margin-bottom: 0px;
}
.about-us .principles-common-ul li {
  margin-bottom: 70px;
}
.about-us .principles-common-ul li:nth-last-child(-n+2) {
  margin-bottom: 0px;
}
.mail-a {
  color: #007CC1 !important;
  font-size: 18px;
  text-decoration: underline !important;
  font-family: 'Object Sans Heavy';
  font-style: normal;
  cursor: pointer;
  transition: all 0.5s ease;
}
.mail-a:hover {
  text-decoration: underline;
  color: #007CC1;
}
.pad-sec {
  padding: 50px 0px !important;
}
.pl3 {
  padding-left: 3px;
}
.about-us .award-common-hold .award-card-ul li.empty:last-child {
  min-height: auto;
  min-height: 0;
  margin: 0;
}
@media only screen and (max-width: 1067px) {
  .about-us .award-common-hold .award-card-ul li .award-info h4 {
    font-size: 24px;
    max-width: 285px;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 920px) {
  .about-us .main-para-div.main-para-intro {
    width: 750px!important;
  }
}
@media only screen and (max-width: 768px) {
  .about-us .main-para-div.main-para-intro {
    width: 575px!important;
  }
  .about-us .principles-common-ul li {
    list-style: none;
    width: 100%;
  }
  .about-us .principles-common-ul li {
    margin-bottom: 50px;
  }
  .about-us .principles-common-ul li:nth-last-child(-n+2) {
    margin-bottom: 50px;
  }
  .about-us .principles-common-ul li:last-child {
    margin-bottom: 50px;
  }
  .about-us .team-profile-ul {
    justify-content: space-between;
  }
  .about-us .team-profile-ul li {
    width: 34%;
  }
  .about-us .custom-container {
    max-width: 90%;
    min-width: 90%;
  }
  .about-us .award-common-hold .award-card-ul li {
    width: 45%;
  }
  .about-us .award-common-hold .award-card-ul li .award-info h4 {
    font-size: 26px;
  }
  .about-us .main-para-div.main-para-intro:after {
    right: -25px;
  }
  .about-us .main-para-div.main-para-intro:before {
    left: -25px;
  }
}
@media only screen and (max-width: 680px) and (min-width: 424px) {
  .about-us .award-common-hold .award-card-ul li {
    width: 100%;
  }
  .about-us .award-common-hold .award-card-ul li .award-card {
    margin: 0 auto;
  }
  .about-us .award-common-hold .award-card-ul li .award-info h4 {
    max-width: 100%;
    width: 100%;
    margin: 15px 0px;
  }
  .about-us .award-common-hold .award-card-ul li .award-info {
    text-align: center;
  }
  .custom-add-row {
    flex: inherit!important;
    max-width: 50%!important;
  }
}
@media only screen and (max-width: 425px) {
  .about-us .section-1 .custom-section .main-para-div p {
    font-size: 20px;
    line-height: 25px;
  }
  .about-us .principles-common-ul .principles-info-hold p {
    font-size: 16px;
    line-height: 20px;
  }
  .about-us .main-para-div.main-para-intro {
    width: 100%!important;
    font-size: 20px;
    line-height: 25px;
  }
  .about-us .main-para-div.main-para-intro:before {
    left: -20px;
  }
  .about-us .main-para-div.main-para-intro:after {
    right: -20px;
  }
  .about-us .main-head-div {
    margin-bottom: 30px;
  }
  .about-us .team-profile-ul li {
    width: 100%;
  }
  .about-us .professionals-ul li {
    width: 100%;
  }
  .about-us .principles-common-ul .principles-info-hold h4 {
    font-size: 30px;
    line-height: 37px;
  }
  .about-us .team-profile-ul li .prof-pic-info h4 {
    font-size: 24px;
    line-height: 30px;
    margin-top: 15px;
  }
  .about-us .award-common-hold .award-card-ul li .award-info h4 {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 0px;
  }
  .about-us .award-common-hold .award-card-ul li .award-info p {
    font-size: 16px;
  }
  .about-us .expert-talk-hold button span {
    font-size: 16px;
  }
  .about-us .professionals-ul li {
    margin-bottom: 30px;
  }
  .about-us .team-profile-ul li .prof-pic-info p {
    font-size: 16px;
    line-height: 20px;
  }
  .about-us .professionals-ul li .professionals-num {
    min-width: 40px;
    height: 40px;
  }
  .about-us .professionals-ul li .professionals-info h4 {
    font-size: 24px;
    line-height: 30px;
  }
  .about-us .professionals-ul li .professionals-num span {
    font-size: 20px;
    line-height: 25px;
  }
  .about-us .expert-talk-hold p {
    font-size: 16px;
    line-height: 20px;
  }
  .about-us .award-common-hold .award-card-ul li {
    width: 100%;
  }
  .left-info-hold {
    background-size: 350px;
  }
  .about-us .principles-common-ul .principles-info-hold h4 span {
    background-position: 0 23px;
    background-size: 40px 10px;
    background-repeat: repeat-x;
  }
}
@media only screen and (max-width: 425px) {
  .about-us .main-para-div.main-para-intro:before {
    left: 0px;
  }
  .about-us .main-para-div.main-para-intro:after {
    right: 0px;
  }
  .about-us .custom-container {
    max-width: 75%;
    min-width: 75%;
  }
  .about-us .professionals-ul li:nth-child(11) {
    margin-bottom: 30px;
  }
  .custom-add-row {
    flex: inherit!important;
    max-width: 100%!important;
  }
}
@media only screen and (max-width: 320px) {
  .about-us .main-para-div.main-para-intro {
    width: 100%!important;
  }
  .left-info-hold {
    background-size: 280px;
  }
  .about-us .principles-common-ul .principles-info-hold h4 span {
    background-position: 0 23px;
    background-size: 40px 10px;
    background-repeat: repeat-x;
  }
}
