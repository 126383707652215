/* width */
.menu-list::-webkit-scrollbar {
  width: 8px;
  border-radius: 30px;
}
/* Track */
.menu-list::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 30px;
}
/* Handle */
.menu-list::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 30px;
}
/* Handle on hover */
.menu-list::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 30px;
}
.side-menu-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  width: 33%;
  height: 100%;
  opacity: 1;
  z-index: 10005;
  animation: myfirst 1s forwards;
  animation-direction: alternate;
  overflow: auto;
}
.web-menu-list ul.nav {
  display: inline-flex;
}
.show-mobile-menu {
  display: none;
}
.web-menu-list {
  display: block;
  position: fixed;
  right: 180px;
  z-index: 2;
  top: 25px;
}
.web-menu-list .menu-list {
  padding: 0px 10px;
  overflow: inherit;
}
.web-menu-list .menu-list ul {
  padding: 0px;
  margin: 0px;
}
.web-menu-list .menu-list ul li {
  display: inline-flex;
  position: relative;
  font-size: 0px;
}
.web-menu-list .menu-list ul li a {
  padding: 7px 20px;
  display: inline-block;
  margin: 0px;
  border: none;
  align-items: center;
  justify-content: flex-start;
  display: inline-flex;
  font-family: "Object Sans Heavy";
  font-size: 18px;
}
.web-menu-list .menu-list ul li a:hover {
  background: #24A989;
}
.web-menu-list .menu-list ul li a:hover i.blue-arrow {
  display: none !important;
}
.web-menu-list .menu-list ul li a:hover i.blue-arrow.white-arrow {
  display: inline-block !important;
}
.web-menu-list .menu-list ul li.dropdown {
  height: auto;
}
.web-menu-list .menu-list ul li.dropdown:hover {
  background: #24A989;
}
.web-menu-list .menu-list ul li.dropdown:hover a {
  background: transparent !important;
}
.web-menu-list .menu-list ul li.dropdown:hover .sub-menu {
  display: block;
}
.web-menu-list .menu-list ul li.dropdown:hover .sub-menu li a {
  color: #1F728A !important;
}
.web-menu-list .menu-list ul li.dropdown:hover .sub-menu li a:hover {
  color: #1E4B5E !important;
}
.web-menu-list .menu-list ul li i {
  margin-left: 3px;
}
.web-menu-list .menu-list ul li i img {
  width: 15px;
  height: 15px;
}
.web-menu-list .menu-list ul li i img.blue-arrow {
  display: none;
}
.web-menu-list .menu-list ul li .sub-menu {
  position: absolute;
  left: 0px;
  background: #fff;
  top: 41px;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
  width: 175px;
  display: none;
  padding: 0px;
  font-size: 0px;
}
.web-menu-list .menu-list ul li .sub-menu li {
  display: block;
  padding: 0px;
}
.web-menu-list .menu-list ul li .sub-menu li a {
  color: #1F728A !important;
  display: block;
  padding: 10px 20px;
  margin: 0px;
  border-bottom: 1px solid #e8e8e8;
}
.web-menu-list .menu-list ul li .sub-menu li a:hover {
  background: rgba(23, 164, 188, 0.5) !important;
}
.web-menu-list .menu-list ul li .sub-menu li:last-child a {
  border-bottom: 0px;
}
.web-menu-list .menu-list ul li .sub-menu li:hover {
  background: #fff;
}
.web-menu-list .menu-list ul li .sub-menu li:hover a {
  color: #1E4B5E !important;
}
.web-menu-list .menu-list ul li .sub-menu li.sec-dropdown:hover a i img.white-arrow {
  display: none !important;
}
.web-menu-list .menu-list ul li .sub-menu li.sec-dropdown:hover a i img.blue-arrow {
  display: inline-block !important;
}
.web-menu-list .menu-list ul li a {
  color: #1E4B5E;
  font-family: "Object Sans Heavy";
  font-size: 18px;
}
.Header .custom-inx-navbar {
  background: #fff;
}
.Header.showPreHeader + .ham-menu .web-menu-list {
  top: 70px;
}
div.digital-header.scrollHeader div.custom-inx-navbar {
  background: #fff !important;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
}
div.digital-header.Header .custom-inx-logo .scroll-logo {
  display: none;
}
div.digital-header.Header.scrollHeader .custom-inx-logo .scroll-logo {
  display: inline-block;
}
div.digital-header.Header.scrollHeader .custom-inx-logo .main-logo {
  display: none;
}
div.digital-header.Header + .ham-menu .web-menu-list .menu-list ul li a {
  color: #fff;
}
div.digital-header.Header + .ham-menu .web-menu-list .menu-list ul li a i img.white-arrow {
  display: inline-block;
}
div.Header + .ham-menu .web-menu-list .menu-list ul li .sec-dropdown,
div.digital-header.Header + .ham-menu .web-menu-list .menu-list ul li .sec-dropdown {
  position: relative;
}
div.Header + .ham-menu .web-menu-list .menu-list ul li .sec-dropdown a i img.white-arrow,
div.digital-header.Header + .ham-menu .web-menu-list .menu-list ul li .sec-dropdown a i img.white-arrow {
  display: inline-block;
}
div.Header + .ham-menu .web-menu-list .menu-list ul li .sec-dropdown:hover .sec-sub-menu,
div.digital-header.Header + .ham-menu .web-menu-list .menu-list ul li .sec-dropdown:hover .sec-sub-menu {
  height: 190px;
  transition: 0.2s height ease-in;
  overflow: auto;
  padding: 0px;
}
div.Header + .ham-menu .web-menu-list .menu-list ul li .sec-dropdown:hover .sec-sub-menu li a,
div.digital-header.Header + .ham-menu .web-menu-list .menu-list ul li .sec-dropdown:hover .sec-sub-menu li a {
  font-size: 18px;
  width: 100%;
  transition: 0.7s all ease;
}
div.Header + .ham-menu .web-menu-list .menu-list ul li .sec-dropdown:hover .sec-sub-menu li a:hover,
div.digital-header.Header + .ham-menu .web-menu-list .menu-list ul li .sec-dropdown:hover .sec-sub-menu li a:hover {
  background: #ebf0f2;
}
div.Header + .ham-menu .web-menu-list .menu-list ul li .sec-dropdown .sec-sub-menu,
div.digital-header.Header + .ham-menu .web-menu-list .menu-list ul li .sec-dropdown .sec-sub-menu {
  height: 0;
  transition: 0.3s all ease-in;
}
div.Header + .ham-menu .web-menu-list .menu-list ul li .sec-dropdown .sec-sub-menu li,
div.digital-header.Header + .ham-menu .web-menu-list .menu-list ul li .sec-dropdown .sec-sub-menu li {
  padding-bottom: 0px;
}
div.Header + .ham-menu .web-menu-list .menu-list ul li .sec-dropdown .sec-sub-menu li a,
div.digital-header.Header + .ham-menu .web-menu-list .menu-list ul li .sec-dropdown .sec-sub-menu li a {
  font-size: 0px;
  transition: 0.2s width ease-in;
  width: 0;
}
div.digital-header.Header.scrollHeader + .ham-menu .web-menu-list .menu-list ul li .sub-menu .sec-dropdown i img.white-arrow {
  display: inline-block;
}
div.digital-header.Header.scrollHeader + .ham-menu .web-menu-list .menu-list ul li a {
  color: #fff;
}
div.digital-header.scrollHeader + .ham-menu .web-menu-list .menu-list ul li a {
  color: #1E4B5E !important;
}
div.digital-header.scrollHeader + .ham-menu .web-menu-list .menu-list ul li a.active {
  background: #24A989 !important;
}
div.digital-header.scrollHeader + .ham-menu .web-menu-list .menu-list ul.sub-menu li a {
  color: #1F728A !important;
}
div.digital-header.scrollHeader + .ham-menu .web-menu-list .menu-list ul.sub-menu li:hover a {
  color: #1E4B5E !important;
}
div.Header + .ham-menu .web-menu-list .menu-list ul li i img.blue-arrow {
  display: inline-block;
}
div.Header + .ham-menu .web-menu-list .menu-list ul li i img.white-arrow {
  display: none;
}
div.digital-header.Header + .ham-menu .web-menu-list .menu-list ul li i img.blue-arrow {
  display: none;
}
div.digital-header.Header + .ham-menu .web-menu-list .menu-list ul li.dropdown {
  height: auto;
}
div.digital-header.Header + .ham-menu .web-menu-list .menu-list ul li.dropdown .sub-menu li a {
  color: #1F728A !important;
}
div.digital-header.Header + .ham-menu .web-menu-list .menu-list ul li.dropdown .sub-menu li a:hover {
  background: rgba(23, 164, 188, 0.5);
}
div.digital-header.Header + .ham-menu .web-menu-list .menu-list ul li .sub-menu .sec-sub-menu li {
  padding-bottom: 0px;
}
div.digital-header.Header.scrollHeader + .ham-menu .web-menu-list .menu-list ul li .sub-menu {
  top: 35px;
}
div.digital-header.scrollHeader + .ham-menu .web-menu-list .menu-list ul li i img.blue-arrow {
  display: inline-block;
}
div.digital-header.scrollHeader.Header + .ham-menu .web-menu-list .menu-list ul li i img.white-arrow {
  display: none;
}
div.digital-header.Header.scrollHeader + .ham-menu .web-menu-list .menu-list ul li.dropdown ul.sub-menu li.sec-dropdown a i.white-arrow {
  display: inline-block;
}
div.digital-header.scrollHeader.Header .right-menu a.connect-btn {
  background: #007CC1;
  color: #fff;
}
.Header .right-menu a.connect-btn {
  min-width: 135px !important;
  padding: 7px 15px;
  background: #1E4B5E !important;
  color: #fff !important;
  border: 0px;
  font-size: 18px;
  margin-right: 0px;
}
div.digital-header .custom-inx-navbar .right-menu .bm-burger-button {
  display: none;
}
div.Header .custom-inx-navbar .right-menu .white-menu {
  display: none;
}
div.Header.digital-header .custom-inx-navbar .right-menu .white-menu {
  display: inline-block;
}
div.digital-header.scrollHeader .custom-inx-navbar .right-menu .bm-burger-button {
  display: inline-block;
}
div.digital-header.scrollHeader .custom-inx-navbar .right-menu .white-menu {
  display: none;
}
@keyframes myfirst {
  0% {
    background: #fff;
    right: -30%;
    top: 0;
  }
  100% {
    background: #fff;
    right: 0;
  }
}
@keyframes mysecond {
  0% {
    background: #fff;
    right: 0;
  }
  100% {
    background: #fff;
    right: -30%;
    top: 0;
  }
}
.ham-menu-hold {
  cursor: pointer;
}
.menu-list {
  display: flex;
  align-items: center;
  padding: 130px 0px;
  overflow: auto;
}
.side-close {
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
  width: 40px;
  z-index: 9;
}
.menu-list .nav-item {
  cursor: pointer;
}
.menu-list .dropdown-toggle::after {
  margin-left: 10px;
  vertical-align: 4px;
  content: "";
  border: solid #1E4B5E;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(45deg);
  transition: all 0.2s ease;
}
.nav.flex-column {
  width: 100%;
}
.nav-item.dropdown .collapse {
  background: #EBF0F2;
}
.menu-list .nav-item.dropdown #solution-collapse .nav-link {
  padding: 10px 70px;
}
.nav-item.dropdown.open {
  background: #EBF0F2;
}
.nav-item.dropdown.open .nav-link.dropdown-toggle {
  color: #7E97A1;
}
.nav-item.dropdown.open .dropdown-toggle::after {
  vertical-align: 0px;
  content: "";
  border: solid #7E97A1;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(224deg);
}
.menu-list .nav-item .nav-link {
  position: relative;
}
.menu-list .nav-item .nav-link.sub-link {
  font-size: 20px;
  padding: 15px 5px 5px !important;
}
.menu-list .nav-item .nav-link.sub-link.active {
  color: #7E97A1;
}
.menu-list .nav-item .nav-link.sub-link.active::before {
  display: none;
}
.nav-link .sub-dropdown-toggle::after {
  vertical-align: 4px !important;
  transform: rotate(45deg) !important;
}
.nav-link.active .sub-dropdown-toggle::after {
  transform: rotate(224deg) !important;
}
.menu-list .nav-item .nav-link.active::before {
  content: '';
  position: absolute;
  width: 10px;
  height: 30px;
  background: #24A989;
  left: 0;
  margin: auto;
  right: auto;
  top: 8px;
  display: flex;
  align-items: center;
  transition: 0.5s ease;
}
.menu-list .nav-item .nav-link.dropdown-toggle:hover::before {
  display: none;
}
.menu-list .nav-item .collapse div.nav-link.dropdown-toggle {
  padding: 0px !important;
}
.web-menu-list .menu-list ul li a:hover,
.web-menu-list .menu-list ul li.dropdown:hover {
  background: #79c9bc1a !important;
}
div.digital-header.Header + .ham-menu .web-menu-list .menu-list ul li a.active,
div.digital-header.Header + .ham-menu .web-menu-list .menu-list ul li a:hover,
div.digital-header.scrollHeader + .ham-menu .web-menu-list .menu-list ul li a.active {
  background: #79c9bc1a !important;
}
.web-menu-list .menu-list ul li a.active,
div.digital-header.Header.scrollHeader + .ham-menu .web-menu-list .menu-list ul li a:hover,
.web-menu-list .menu-list ul li .sub-menu li a:hover {
  background: #79c9bc1a !important;
}
.web-menu-list .menu-list ul li .sub-menu,
div.digital-header.Header.scrollHeader + .ham-menu .web-menu-list .menu-list ul li .sub-menu {
  top: 42px;
}
@media only screen and (max-width: 1280px) {
  div.Enterprise.Healthcare .section-1 .main-head-1 .secondary-span {
    font-size: 26px;
  }
  .Enterprise.Healthcare .section-1 .main-para-div.short-div p br {
    display: block !important;
  }
}
@media only screen and (max-width: 1024px) {
  .menu-list {
    padding-top: 100px;
  }
  .side-menu-container {
    width: 45%;
  }
  .show-mobile-menu {
    display: block;
  }
  .web-menu-list {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .side-menu-container {
    width: 60%;
  }
}
@media only screen and (max-width: 430px) {
  .side-menu-container {
    width: 75%;
  }
  .menu-list {
    height: auto;
    display: flex;
    align-items: center;
    padding: 20px 0px;
  }
  .menu-list .nav-item .nav-link {
    font-size: 20px;
    padding: 10px 35px!important;
  }
  .side-close {
    width: 30px;
    position: relative;
    right: inherit;
    margin-left: auto;
    top: inherit;
    display: flex;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 15px;
  }
  .menu-list .nav-item .nav-link:hover::before {
    height: 24px;
  }
}
@media only screen and (max-width: 374px) {
  .Header .right-menu a.connect-btn {
    font-size: 14px;
    text-align: center;
  }
}
