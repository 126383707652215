@font-face {
  font-family: 'Poiret One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poiretone/v16/UqyVK80NJXN4zfRgbdfbk5k.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrFJA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9V1s.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6V1s.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7V1s.ttf) format('truetype');
}
.thank-you-wrap {
  background: linear-gradient(101.06deg, #0E2E40 0%, #145554 42.87%, #208F71 99.96%);
  height: 100vh;
  width: 100%;
  padding: 30px 50px;
  position: relative;
  z-index: 1;
}
.thank-you-wrap .main-logo {
  z-index: 1;
  position: relative;
}
.thank-you-wrap::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("/assets/img/subscribe/sub-bg.png") no-repeat;
  width: 100%;
  height: 80%;
  z-index: -1;
  background-size: cover;
}
.thank-you-wrap .main-content {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  color: #fff;
  padding: 30px 0px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.thank-you-wrap .main-content h1 {
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 90px;
  font-family: "Object Sans Heavy";
}
.thank-you-wrap .main-content p {
  display: flex;
  text-align: center;
  width: 100%;
  justify-content: center;
  margin: 30px 0px;
}
.thank-you-wrap .main-content p br {
  display: block !important;
}
.thank-you-wrap .main-content b {
  display: flex;
  text-align: center;
  font-weight: 800;
}
.thank-you-wrap .main-content .social-icons {
  list-style-type: none;
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 50px 0px;
  padding: 0px;
  position: relative;
  z-index: 1;
}
.thank-you-wrap .main-content .social-icons li {
  padding: 0px 15px;
  display: inline-flex;
  align-items: center;
}
.thank-you-wrap .main-content .social-icons li img {
  height: 23px;
}
.thank-you-wrap .main-content .social-icons li a {
  cursor: pointer;
}
.thank-you-wrap .main-content em {
  display: flex;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  width: 100%;
}
.pst-thanks {
  background: #EDF1FC;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.pst-thanks::after {
  display: none;
}
.pst-thanks .main-logo {
  position: absolute;
  top: 20px;
  left: 20px;
}
.pst-thanks .main-content {
  margin: auto;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  padding: 70px 50px 100px;
  border-radius: 20px;
  border: 2px solid #E6E7E8;
  background: #FFF;
  box-shadow: 0px 15px 40px 0px rgba(0, 0, 0, 0.1);
}
.pst-thanks .main-content i {
  margin: 0px 0 20px;
}
.pst-thanks .main-content i img {
  width: 70px;
}
.pst-thanks .main-content h1 {
  color: #222;
  font-size: 40px;
  font-weight: normal;
  font-family: "Object Sans";
}
.pst-thanks .main-content p {
  margin: 20px 0px;
  margin: 30px 0;
  color: #222;
  font-size: 20px;
  font-weight: 400;
}
.pst-thanks .main-content .social-icons {
  margin: 20px 0px 0;
}
.pst-thanks .main-content .social-icons li img {
  height: 35px;
}
@media only screen and (max-width: 767px) {
  .thank-you-wrap .main-content h1 {
    font-size: 36px;
  }
  .thank-you-wrap {
    padding: 25px;
  }
  .thank-you-wrap .main-content p {
    text-align: center;
    margin: 30px auto;
    max-width: 90%;
  }
  .thank-you-wrap .main-content p br {
    display: none !important;
  }
}
@media screen and (max-width: 767px) and (orientation: landscape) {
  .thank-you-wrap .main-content {
    position: static;
  }
  .thank-you-wrap {
    height: 100%;
  }
  .pst-thanks {
    height: 100vh;
  }
}
@media only screen and (max-width: 1024px) {
  .pst-thanks .main-content {
    width: 80%;
  }
}
@media only screen and (max-width: 656px) {
  .pst-thanks .main-content {
    width: 100%;
    padding: 40px 10px 50px;
  }
  .pst-thanks .main-content p {
    text-align: center;
    margin: 20px 0px;
    max-width: 80%;
    font-size: 14px;
  }
  .pst-thanks .main-content .social-icons li img {
    height: 25px;
  }
}
