.Startup .section-1 {
  background-image: url(/assets/img/hero-images/startup-hero-bg.png) !important;
  background-position: right!important;
  height: 100vh;
  background-size: cover;
}
.Startup .section-1 .main-head-1 .primary-span {
  font-size: 72px;
}
.Startup .section-1 .main-head-1 .secondary-span {
  font-size: 42px;
}
.Startup .section-2 .main-head-1 .primary-span {
  font-size: 36px;
  color: #1E4B5E;
  font-family: Object Sans Heavy;
}
.Startup .section-2 .main-head-1 .secondary-span {
  font-size: 60px;
  font-family: Heldane Display;
  color: #24A989;
}
.Startup .section-2 .portfolio-card-info h4 {
  font-family: Heldane Display;
}
@media only screen and (max-width: 980px) {
  .Startup .work-with-row {
    position: relative;
  }
  .Startup .work-with-row .work-with-col {
    flex: inherit;
    max-width: 100%;
  }
  .Startup .work-with-row .comp-info-right-col {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.25;
    position: absolute;
    left: 0;
    height: 100%;
    right: 0;
    top: 110px;
    flex: inherit;
    max-width: 100%;
    bottom: auto;
  }
}
@media only screen and (max-width: 768px) {
  .Startup .company-info .company-offer-imgs svg {
    width: 300px;
    height: 300px;
  }
}
@media only screen and (max-width: 678px) {
  .Startup .section-1.white-bg {
    background-color: transparent;
  }
  .Startup .section-1 {
    background-image: url(/assets/img/startup-img/startup-mob-bg.png) !important;
    background-position: right!important;
    height: 100vh;
    background-size: contain;
  }
}
@media only screen and (max-width: 425px) {
  .Startup .section-1 .main-head-1 .primary-span {
    font-size: 48px;
  }
  .Startup .section-1 .main-head-1 .secondary-span {
    font-size: 30px;
  }
  .Startup .main-head-div {
    margin-bottom: 30px;
  }
  .Startup .company-info .custom-main-head-div .main-head .primary-span {
    font-size: 24px ;
    line-height: 30px;
    margin-top: 0px;
  }
  .Startup .company-info .custom-main-head-div .main-head .secondary-span {
    font-size: 36px ;
    line-height: 45px;
  }
  .Startup .company-info .highlight-text h4 span {
    font-size: 30px;
    line-height: 37px;
  }
  .Startup .section-2 .main-head-1 .primary-span {
    font-size: 24px!important;
    line-height: 30px;
    color: #1E4B5E;
    font-family: Object Sans Heavy;
  }
  .Startup .main-head-1 .secondary-span {
    font-size: 36px!important;
    line-height: 45px;
  }
  .Startup .section-2 .portfolio-card-info h4 {
    font-family: Heldane Display;
    font-size: 24px;
  }
  .Startup .work-with-row .comp-info-right-col {
    top: 30px;
  }
}
