@font-face {
  font-family: 'Inter';
  src: url('/assets/fonts/inter/Inter-Bold.woff2') format('woff2'), url('/assets/fonts/inter/Inter-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: url('/assets/fonts/inter/Inter-Medium.woff2') format('woff2'), url('/assets/fonts/inter/Inter-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: url('/assets/fonts/inter/Inter-Regular.woff2') format('woff2'), url('/assets/fonts/inter/Inter-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: url('/assets/fonts/inter/Inter-SemiBold.woff2') format('woff2'), url('/assets/fonts/inter/Inter-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: url('/assets/fonts/inter/Inter-Light.woff2') format('woff2'), url('/assets/fonts/inter/Inter-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: url('/assets/fonts/inter/Inter-Thin.woff2') format('woff2'), url('/assets/fonts/inter/Inter-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
.design-studio-wrap {
  font-family: 'Inter';
}
.design-studio-wrap br {
  display: block !important;
}
.design-studio-wrap .simplifying-section {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-transform: uppercase;
  padding-top: 50px;
  position: relative;
}
.design-studio-wrap .simplifying-section .design-img {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  height: 100vh;
}
.design-studio-wrap .simplifying-section .design-img img {
  height: 100%;
  width: 100%;
}
.design-studio-wrap .simplifying-section .first-inner {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  text-align: left;
  width: 100%;
  position: relative;
}
.design-studio-wrap .simplifying-section .first-inner::before {
  content: "";
  position: absolute;
  top: -15%;
  right: 0;
  width: 250px;
  height: 3px;
  background: linear-gradient(90deg, #01B5F2 0%, rgba(196, 255, 195, 0) 0.01%, #98FEC7 49.17%, rgba(1, 242, 40, 0) 100%, rgba(193, 255, 203, 0) 100%);
}
.design-studio-wrap .simplifying-section .first-inner::after {
  content: "";
  position: absolute;
  bottom: -30%;
  left: 0;
  width: 250px;
  height: 3px;
  background: linear-gradient(90deg, #01B5F2 0%, rgba(196, 255, 195, 0) 0.01%, #98FEC7 49.17%, rgba(1, 242, 40, 0) 100%, rgba(193, 255, 203, 0) 100%);
}
.design-studio-wrap .simplifying-section .first-inner h1 {
  text-align: left;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 30px;
  text-shadow: 0px 0px 30px #000000B2;
  display: inline-flex;
  justify-content: flex-start;
  width: 100%;
}
.design-studio-wrap .simplifying-section .first-inner h2 {
  color: #FFFFFF;
  font-size: 110px;
  font-weight: 300;
  width: 100%;
  position: relative;
  left: -5px;
  margin-bottom: 0px;
  text-shadow: 0px 0px 30px #F852DD99;
}
.design-studio-wrap .simplifying-section .first-inner h3 {
  color: #FFFFFF;
  font-weight: 100;
  font-size: 60px;
  width: 100%;
  margin-bottom: 0px;
}
.design-studio-wrap .simplifying-section .container {
  padding: 20px 100px;
}
.design-studio-wrap .good-design-section {
  padding: 120px 0px;
  background: #FBFBFF;
}
.design-studio-wrap .good-design-section .container .second-inner {
  border-style: solid;
  border-width: 1px;
  border-image: linear-gradient(180deg, rgba(49, 186, 158, 0.5) 0%, rgba(196, 58, 192, 0.5) 23.5%, #5BC3E8 42.4%, rgba(49, 186, 158, 0.19) 60.92%, #EDFEFF 90.17%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 100px 50px;
  position: relative;
  z-index: 2;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.design-studio-wrap .good-design-section .container .second-inner .animated {
  z-index: 2;
}
.design-studio-wrap .good-design-section .container .second-inner::before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  margin: -1px;
  z-index: 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: linear-gradient(180deg, rgba(49, 186, 158, 0.5) 0%, rgba(196, 58, 192, 0.5) 23.5%, #5BC3E8 42.4%, rgba(49, 186, 158, 0.19) 60.92%, #EDFEFF 90.17%);
}
.design-studio-wrap .good-design-section .container .second-inner::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  margin: auto;
  background: #FFFFFF;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  z-index: 1;
}
.design-studio-wrap .good-design-section .container .second-inner h2 {
  font-weight: 400;
  font-size: 70px;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  z-index: 2;
  position: relative;
}
.design-studio-wrap .good-design-section .container .second-inner h2 em {
  font-style: normal;
  width: 100%;
  color: #1A1A1A;
  z-index: 2;
}
.design-studio-wrap .good-design-section .container .second-inner h2 span:nth-of-type(1) {
  background: url("/assets/img/design-studio/good-design-bg.png");
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-right: 10px;
}
.design-studio-wrap .good-design-section .container .second-inner p {
  text-align: center;
  color: #2A2A2A;
  font-size: 20px;
  line-height: 30px;
  width: 100%;
  margin: 0px auto;
  z-index: 2;
  margin-top: 30px;
  position: relative;
}
.design-studio-wrap .design-service-section {
  padding: 120px 0px;
  background: url("/assets/img/design-studio/service-bg.png") no-repeat;
  background-size: cover;
}
.design-studio-wrap .design-service-section .service-inner .service-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}
.design-studio-wrap .design-service-section .service-inner .service-top button {
  border-left: 1px solid #1dc1b3cc;
  border-right: 1px solid #dc477fcc;
  border-top: 1px solid #1dc1b3cc;
  border-bottom: 1px solid #dc477fcc;
  background-image: linear-gradient(90deg, #1dc1b3cc, #dc477fcc), linear-gradient(90deg, #1dc1b3cc, #dc477fcc);
  background-size: 100% 1px;
  background-position: 0 0, 0 100%;
  background-repeat: no-repeat;
  color: #ffffff;
  background: transparent;
  padding: 10px 20px;
  border-radius: 4px;
  box-shadow: 0px 5px 20px 0px #D84D814D;
}
.design-studio-wrap .design-service-section .service-inner h3 {
  color: #fff;
  font-size: 70px;
  line-height: 100px;
  font-weight: 400;
}
.design-studio-wrap .design-service-section .service-inner h3 span {
  background: url("/assets/img/design-studio/good-design-bg.png");
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-position: 60% 95%;
}
.design-studio-wrap .design-service-section .service-inner .other-service {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.design-studio-wrap .design-service-section .service-inner .other-service .service-left {
  width: 48%;
}
.design-studio-wrap .design-service-section .service-inner .other-service .service-left .service-list {
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 60px;
  background-position: 50%;
}
.design-studio-wrap .design-service-section .service-inner .other-service .service-left .service-list:nth-of-type(1) {
  margin-bottom: 15%;
  padding-top: 100px;
}
.design-studio-wrap .design-service-section .service-inner .other-service .service-right {
  width: 48%;
  margin-left: 5%;
  margin-top: -25%;
}
.design-studio-wrap .design-service-section .service-inner .other-service .service-right .service-list {
  padding-left: 50px;
  padding-right: 50px;
  background-position: 50%;
  padding-top: 150px;
  padding-bottom: 150px;
}
.design-studio-wrap .design-service-section .service-inner .service-list {
  padding: 50px 30px;
  border-radius: 20px;
  color: #ffffff;
  display: inline-flex;
  flex-wrap: wrap;
  align-items: flex-start;
  background-image: url("/assets/img/design-studio/service-list-bg.png");
  background-size: cover;
  position: relative;
}
.design-studio-wrap .design-service-section .service-inner .service-list.mobile-show {
  display: none;
}
.design-studio-wrap .design-service-section .service-inner .service-list::after {
  content: "";
  position: absolute;
  background-image: radial-gradient(21.19% 40.1% at 100.03% 0%, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%), radial-gradient(24.21% 39.21% at 0% 0%, rgba(255, 255, 255, 0.81) 0%, rgba(255, 255, 255, 0.19) 100%), radial-gradient(98% 49.86% at 100.03% 100%, #33A36D 0%, rgba(51, 163, 109, 0.05) 100%);
  left: -1px;
  top: -1px;
  right: -1px;
  bottom: -1px;
  margin: 0px;
  z-index: -1;
  border-radius: 20px;
}
.design-studio-wrap .design-service-section .service-inner .service-list:nth-of-type(1) {
  margin-bottom: 5%;
}
.design-studio-wrap .design-service-section .service-inner .service-list strong {
  font-size: 45px;
  font-weight: 500;
  margin-bottom: 60px;
  line-height: 57px;
}
.design-studio-wrap .design-service-section .service-inner .service-list strong span {
  display: inline-flex;
}
.design-studio-wrap .design-service-section .service-inner .service-list p {
  font-size: 18px;
  line-height: 30px;
  width: 100%;
  flex: 0 0 100%;
  margin-bottom: 30px;
}
.design-studio-wrap .design-service-section .service-inner .service-list .service-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
.design-studio-wrap .design-service-section .service-inner .service-list .service-top strong {
  font-size: 45px;
  font-weight: 500;
  width: 40%;
  flex: 0 0 40%;
  margin: 0px;
  line-height: 40px;
}
.design-studio-wrap .design-service-section .service-inner .service-list .service-top p {
  font-size: 17px;
  line-height: 30px;
  width: 60%;
  flex: 0 0 60%;
}
.design-studio-wrap .design-service-section .service-inner .service-list .service-content ul {
  display: flex;
  list-style-type: none;
  padding: 0px;
  margin: 0;
}
.design-studio-wrap .design-service-section .service-inner .service-list .service-content ul li {
  display: inline-flex;
  flex-wrap: wrap;
  position: relative;
  padding: 30px 3% 30px 0px;
}
.design-studio-wrap .design-service-section .service-inner .service-list .service-content ul li::after {
  content: "";
  position: absolute;
  right: 10%;
  top: 0;
  bottom: 0;
  width: 5px;
  height: 100%;
  background-size: cover !important;
  background: url("/assets/img/design-studio/service-border.png") no-repeat;
}
.design-studio-wrap .design-service-section .service-inner .service-list .service-content ul li:last-child::after {
  display: none;
}
.design-studio-wrap .design-service-section .service-inner .service-list .service-content ul li b {
  width: 100%;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
}
.design-studio-wrap .design-service-section .service-inner .service-list .service-content ul li:last-child {
  padding-right: 0px;
}
.design-studio-wrap .design-service-section .service-inner .service-list .service-content ul li span {
  display: inline-flex;
  align-items: flex-start;
  font-size: 14px;
}
.design-studio-wrap .design-service-section .service-inner .design-process-idea {
  margin: 100px 0px 0px;
  padding: 50px 100px;
  position: relative;
}
.design-studio-wrap .design-service-section .service-inner .design-process-idea::before {
  content: "";
  position: absolute;
  width: 170px;
  height: 96px;
  border-top: 1px solid #ffffff;
  border-left: 1px solid #ffffff;
  left: 0;
  top: 0;
  opacity: 0.7;
}
.design-studio-wrap .design-service-section .service-inner .design-process-idea::after {
  content: "";
  position: absolute;
  width: 170px;
  height: 96px;
  border-bottom: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
  right: 0;
  bottom: 0;
  opacity: 0.7;
}
.design-studio-wrap .design-service-section .service-inner .design-process-idea h4 {
  color: #FFFFFF;
  font-size: 40px;
  font-weight: 400;
  text-align: center;
}
.design-studio-wrap .design-service-section .service-inner .design-process-idea .idea-bottom {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
}
.design-studio-wrap .design-service-section .service-inner .design-process-idea .idea-bottom a {
  position: relative;
  padding-bottom: 20px;
  color: #22ABAE;
  font-size: 24px;
}
.design-studio-wrap .design-service-section .service-inner .design-process-idea .idea-bottom a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60%;
  background: url("/assets/img/design-studio/design-line.png") no-repeat;
  transition: all linear 0.3s;
  background-size: 100%;
  height: 7px;
}
.design-studio-wrap .design-service-section .service-inner .design-process-idea .idea-bottom a:hover {
  cursor: pointer;
}
.design-studio-wrap .design-service-section .service-inner .design-process-idea .idea-bottom a:hover::after {
  width: 110%;
  transition: all linear 0.3s;
}
.design-studio-wrap .design-process-section {
  background: url("/assets/img/design-studio/design-process.png") no-repeat;
  background-size: cover;
  padding: 80px 0px;
}
.design-studio-wrap .design-process-section .fourth-inner {
  padding: 50px 100px 100px;
  box-shadow: 0px 4px 20px 5px #00000026;
  background: linear-gradient(90.9deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.1) 100%) padding-box, linear-gradient(86.33deg, rgba(220, 71, 127, 0.1) 0.57%, rgba(29, 193, 179, 0.1) 100%) border-box;
  position: relative;
  z-index: 1;
  border-radius: 45px;
  border: 1px solid transparent;
}
.design-studio-wrap .design-process-section .fourth-inner p {
  color: #FFFFFF;
  font-size: 40px;
  font-weight: 400;
  line-height: 52px;
  margin-bottom: 15px;
}
.design-studio-wrap .design-process-section .fourth-inner .contact-view {
  width: 100%;
  text-align: right;
}
.design-studio-wrap .design-process-section .fourth-inner button {
  background: transparent;
  border: 0px;
  color: #24C4B5;
  font-size: 24px;
  font-weight: 400;
  position: relative;
  padding: 25px 0px 10px;
}
.design-studio-wrap .design-process-section .fourth-inner button::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -5px;
  height: 6px;
  width: 100%;
  background: url("/assets/img/design-studio/blog-line.png") no-repeat;
}
.design-studio-wrap .portfolio-section {
  padding: 120px 0px;
}
.design-studio-wrap .portfolio-section .portfolio-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.design-studio-wrap .portfolio-section .portfolio-top a {
  position: relative;
  font-style: normal;
  color: #0D8B8E;
  font-size: 24px;
  padding-bottom: 20px;
}
.design-studio-wrap .portfolio-section .portfolio-top a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -5%;
  right: 0;
  width: 65%;
  transition: all linear 0.3s;
  height: 7px;
  background: url("/assets/img/design-studio/work-line.png") no-repeat;
  background-size: 100%;
}
.design-studio-wrap .portfolio-section .portfolio-top a:hover {
  cursor: pointer;
}
.design-studio-wrap .portfolio-section .portfolio-top a:hover::after {
  width: 110%;
  transition: all linear 0.3s;
}
.design-studio-wrap .portfolio-section h3 {
  color: #1A1A1A;
  font-size: 70px;
  line-height: 100px;
  font-weight: 400;
}
.design-studio-wrap .portfolio-section h3 span {
  background: url("/assets/img/design-studio/good-design-bg.png");
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-position: 0% 50%;
}
.design-studio-wrap .portfolio-section .portfolio-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 50px 40px;
}
.design-studio-wrap .portfolio-section .portfolio-wrap .portfolio-list {
  width: 48%;
}
.design-studio-wrap .portfolio-section .portfolio-wrap .portfolio-list .portfolio-list-inner {
  box-shadow: 0px 8px 24px 0px #00000099;
  border-radius: 9px;
  padding: 3px;
  background: #D9D9D9;
}
.design-studio-wrap .portfolio-section .portfolio-wrap .portfolio-list .portfolio-inner {
  background: url("/assets/img/design-studio/port-bg.png") no-repeat;
  background-size: cover;
  color: #FFFFFF;
  border-radius: 15px;
  padding: 30px 30px 0px;
  display: flex;
  flex-direction: column;
}
.design-studio-wrap .portfolio-section .portfolio-wrap .portfolio-list .portfolio-inner strong {
  font-size: 34px;
  font-weight: 500;
}
.design-studio-wrap .portfolio-section .portfolio-wrap .portfolio-list .portfolio-inner span {
  font-size: 20px;
  font-weight: 400;
  margin-top: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.design-studio-wrap .portfolio-section .portfolio-wrap .portfolio-list .portfolio-inner .portfolio-action {
  display: flex;
  width: 50%;
  justify-content: space-between;
  margin: 10px 0px 30px;
}
.design-studio-wrap .portfolio-section .portfolio-wrap .portfolio-list .portfolio-inner .portfolio-action a {
  font-style: normal;
  font-size: 22px;
  color: #24C4B5;
  position: relative;
  padding: 20px 0px;
}
.design-studio-wrap .portfolio-section .portfolio-wrap .portfolio-list .portfolio-inner .portfolio-action a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -5%;
  right: 0;
  width: 65%;
  transition: all linear 0.3s;
  height: 7px;
  background: url("/assets/img/design-studio/work-line.png") no-repeat;
  background-size: 100%;
}
.design-studio-wrap .portfolio-section .portfolio-wrap .portfolio-list .portfolio-inner .portfolio-action a:hover {
  cursor: pointer;
}
.design-studio-wrap .portfolio-section .portfolio-wrap .portfolio-list .portfolio-inner .portfolio-action a:hover::after {
  width: 105%;
  transition: all linear 0.3s;
}
.design-studio-wrap .portfolio-section .portfolio-wrap .portfolio-list .portfolio-inner .portfolio-img {
  border-left: 2px solid #1dc1b3cc;
  border-right: 2px solid #dc477fcc;
  background-image: linear-gradient(90deg, #1dc1b3cc, #dc477fcc), linear-gradient(90deg, #1dc1b3cc, #dc477fcc);
  background-size: 100% 1px;
  background-position: 0 0, 0 100%;
  background-repeat: no-repeat;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 5px;
}
.design-studio-wrap .portfolio-section .portfolio-wrap .portfolio-list .portfolio-inner .portfolio-img img {
  width: 100%;
}
.design-studio-wrap .portfolio-section .portfolio-wrap .portfolio-list:nth-of-type(2) {
  margin-top: 80px;
}
.design-studio-wrap .portfolio-section .portfolio-wrap .portfolio-list:nth-of-type(3) {
  width: 100%;
  margin-top: 30px;
}
.design-studio-wrap .portfolio-section .portfolio-wrap .portfolio-list:nth-of-type(3) .portfolio-inner {
  display: inline-flex;
  flex-direction: row;
  background: url("/assets/img/design-studio/case-bg.png") no-repeat;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.design-studio-wrap .portfolio-section .portfolio-wrap .portfolio-list:nth-of-type(3) .portfolio-inner .portfolio-detail {
  width: 50%;
  flex-wrap: wrap;
  flex-direction: column;
  display: inline-flex;
  flex: 0 0 50%;
}
.design-studio-wrap .portfolio-section .portfolio-wrap .portfolio-list:nth-of-type(3) .portfolio-inner .portfolio-img {
  width: 50%;
}
.design-studio-wrap .portfolio-section .portfolio-wrap .portfolio-list:nth-of-type(3) .portfolio-inner .portfolio-img img {
  width: 100%;
}
.design-studio-wrap .newsletter-section {
  padding: 120px 0px;
  background: url("/assets/img/design-studio/contact-bg.png") no-repeat;
  background-size: cover;
}
.design-studio-wrap .newsletter-section .blog-wrap {
  border-radius: 10px;
  display: flex;
  padding: 30px;
  position: relative;
  justify-content: space-between;
  align-items: center;
}
.design-studio-wrap .newsletter-section .blog-wrap .blog-content {
  z-index: 3;
  width: 45%;
}
.design-studio-wrap .newsletter-section .blog-wrap .blog-content p {
  font-size: 32px;
  color: #1A1A1A;
  line-height: 50px;
  margin-bottom: 40px;
}
.design-studio-wrap .newsletter-section .blog-wrap .blog-content a {
  font-style: normal;
  color: #0D8B8E;
  font-size: 24px;
  font-weight: 400;
  position: relative;
  padding: 0px 20px 20px 0px;
  cursor: pointer;
}
.design-studio-wrap .newsletter-section .blog-wrap .blog-content a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 60%;
  height: 7px;
  background: url("/assets/img/design-studio/blog-line.png") no-repeat;
  background-size: 100%;
  transition: all linear 0.3s;
}
.design-studio-wrap .newsletter-section .blog-wrap .blog-content a:hover::after {
  width: 100%;
  transition: all linear 0.3s;
}
.design-studio-wrap .newsletter-section .blog-wrap .blog-img {
  z-index: 3;
}
.design-studio-wrap .newsletter-section .blog-wrap .blog-img .blog-img-inner {
  background: #F1F1F1;
  box-shadow: 0px 0px 20px 0px #0000004D;
  padding: 5px;
  border-radius: 9px;
}
.design-studio-wrap .newsletter-section .blog-wrap::before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  margin: -2px;
  z-index: 0;
  border-radius: 10px;
  box-shadow: 0px 4px 20px 5px #00000026;
  border: 2px solid;
  background: linear-gradient(86.33deg, rgba(220, 71, 127, 0.8) 0.57%, rgba(29, 193, 179, 0.8) 100%);
}
.design-studio-wrap .newsletter-section .blog-wrap::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  margin: auto;
  background: #FFFFFF;
  border-radius: 10px;
  z-index: 1;
}
.design-studio-wrap .newsletter-section .newsletter-wrap {
  margin-top: 50px;
  border-radius: 10px;
  display: flex;
  padding: 30px;
  position: relative;
  justify-content: space-between;
  flex-direction: column;
}
.design-studio-wrap .newsletter-section .newsletter-wrap .newsletter-success {
  font-size: 26px;
  color: #fff;
  z-index: 2;
  text-align: center;
  position: relative;
}
.design-studio-wrap .newsletter-section .newsletter-wrap h3 {
  z-index: 3;
  color: #FFFFFF;
  font-size: 34px;
  font-weight: 600;
  position: relative;
}
.design-studio-wrap .newsletter-section .newsletter-wrap h3::after {
  content: "";
  position: absolute;
  left: 0;
  height: 3px;
  width: 80px;
  background: #FFFFFF;
  bottom: -15px;
}
.design-studio-wrap .newsletter-section .newsletter-wrap strong {
  z-index: 3;
  color: #FFFFFF;
  font-size: 26px;
  font-weight: 400;
  margin-top: 60px;
}
.design-studio-wrap .newsletter-section .newsletter-wrap strong em {
  font-style: normal;
  font-weight: 600;
}
.design-studio-wrap .newsletter-section .newsletter-wrap .newsletter-form {
  z-index: 3;
  display: inline-flex;
  flex-direction: row;
  margin: 50px 0px 0px;
  justify-content: space-between;
  flex-wrap: wrap;
}
.design-studio-wrap .newsletter-section .newsletter-wrap .newsletter-form .newsletter-input {
  width: 30%;
  position: relative;
}
.design-studio-wrap .newsletter-section .newsletter-wrap .newsletter-form .newsletter-input .error {
  position: absolute;
  top: 100%;
  color: #f00;
}
.design-studio-wrap .newsletter-section .newsletter-wrap .newsletter-form input {
  background: transparent;
  border: transparent;
  border-bottom: 1px solid #FFFFFF;
  color: #FFFFFF;
  padding: 10px 0px;
  width: 100%;
}
.design-studio-wrap .newsletter-section .newsletter-wrap .newsletter-form input:focus {
  outline: none;
}
.design-studio-wrap .newsletter-section .newsletter-wrap .newsletter-form input::placeholder {
  color: #FFFFFF;
}
.design-studio-wrap .newsletter-section .newsletter-wrap .newsletter-form .form-button {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 30px;
}
.design-studio-wrap .newsletter-section .newsletter-wrap .newsletter-form .form-button button.newsletter-sub {
  background: transparent;
  color: #ffffff;
  position: relative;
  z-index: 1;
  box-shadow: 0px 5px 20px 0px #D84D8133;
  padding: 8px 30px;
  border-radius: 5px;
}
.design-studio-wrap .newsletter-section .newsletter-wrap .newsletter-form .form-button button.newsletter-sub::before {
  content: "";
  position: absolute;
  left: -1px;
  right: -1px;
  top: -1px;
  bottom: -1px;
  margin: -1px;
  z-index: -1;
  border-radius: 5px;
  background: linear-gradient(85.48deg, rgba(216, 77, 129, 0.8) 0%, rgba(43, 195, 182, 0.8) 98.61%);
}
.design-studio-wrap .newsletter-section .newsletter-wrap .newsletter-form .form-button button.newsletter-sub::after {
  content: "";
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  margin: auto;
  z-index: -1;
  background: #000000;
  border-radius: 5px;
}
.design-studio-wrap .newsletter-section .newsletter-wrap::before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  margin: -2px;
  z-index: 0;
  border-radius: 10px;
  border: 2px solid;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
  background: linear-gradient(86.33deg, rgba(220, 71, 127, 0.5) 0.57%, rgba(29, 193, 179, 0.5) 100%);
}
.design-studio-wrap .newsletter-section .newsletter-wrap::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  margin: auto;
  border-radius: 10px;
  z-index: 1;
  background: linear-gradient(90.9deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%);
}
.design-studio-wrap .client-feedback {
  padding: 120px 0px 70px;
}
.design-studio-wrap .client-feedback h3 {
  background: url("/assets/img/design-studio/good-design-bg.png");
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-position: 50%;
  font-size: 70px;
  line-height: 100px;
  font-weight: 400;
  text-align: center;
  background-size: cover;
}
.design-studio-wrap .client-feedback .client-wrap {
  padding: 50px;
}
.design-studio-wrap .client-feedback .client-wrap .slick-next,
.design-studio-wrap .client-feedback .client-wrap .slick-prev {
  width: 55px;
  height: 55px;
}
.design-studio-wrap .client-feedback .client-wrap .slick-next {
  right: -45px;
}
.design-studio-wrap .client-feedback .client-wrap .slick-next:before {
  background: url("/assets/img/design-studio/slider-arrow.svg") no-repeat;
  border-radius: 0px;
}
.design-studio-wrap .client-feedback .client-wrap .slick-prev {
  left: -45px;
}
.design-studio-wrap .client-feedback .client-wrap .slick-prev:before {
  background: url("/assets/img/design-studio/slider-arrow.svg") no-repeat;
  border-radius: 0px;
  transform: rotate3d(180deg);
}
.design-studio-wrap .client-feedback .client-wrap .client-list-wrap {
  padding: 0px 2% 20px;
  position: relative;
}
.design-studio-wrap .client-feedback .client-wrap .client-list-wrap::after {
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  background: url("/assets/img/design-studio/quote-arrow.png") no-repeat;
  left: 50px;
  bottom: -28px;
  background-size: 20px;
  transform: rotate(1deg);
}
.design-studio-wrap .client-feedback .client-wrap .client-list {
  border-width: 2px;
  border: double 2px transparent;
  border-radius: 10px;
  background-image: linear-gradient(#fff, #fff), linear-gradient(to right, #dc477fcc, #1dc1b3cc);
  background-origin: border-box;
  background-clip: content-box, border-box;
  width: 100%;
  padding: 20px;
  position: relative;
}
.design-studio-wrap .client-feedback .client-wrap .client-list::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: #fff;
  border-radius: 10px;
}
.design-studio-wrap .client-feedback .client-wrap .client-list::after {
  content: "";
  position: absolute;
  left: 20px;
  bottom: 80px;
  width: 160px;
  height: 160px;
  background: url("/assets/img/design-studio/quote-bg.png") no-repeat;
}
.design-studio-wrap .client-feedback .client-wrap .client-list .client-top {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  z-index: 1;
}
.design-studio-wrap .client-feedback .client-wrap .client-list .client-top .client-info {
  display: flex;
  align-items: center;
  width: 100%;
}
.design-studio-wrap .client-feedback .client-wrap .client-list .client-top .client-info .client-name {
  display: inline-flex;
  flex-direction: column;
  margin-right: 15px;
  width: 65%;
}
.design-studio-wrap .client-feedback .client-wrap .client-list .client-top .client-info .client-name span {
  position: relative;
  color: #0D8B8E;
  font-size: 18px;
  font-weight: 600;
  padding: 15px 40px 15px 20px;
}
.design-studio-wrap .client-feedback .client-wrap .client-list .client-top .client-info .client-name span::before {
  content: "";
  width: 14px;
  height: 14px;
  background: #0DACB0;
  border-radius: 100%;
  left: 0;
  position: absolute;
  top: 21px;
}
.design-studio-wrap .client-feedback .client-wrap .client-list .client-top .client-info .client-name span::after {
  content: "";
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  width: 100%;
  height: 6px;
  background: url("/assets/img/design-studio/client-line.png") no-repeat;
  background-size: 100%;
}
.design-studio-wrap .client-feedback .client-wrap .client-list .client-top .client-info .client-name em {
  color: #717171;
  font-size: 16px;
  font-style: normal;
  margin-top: 10px;
}
.design-studio-wrap .client-feedback .client-wrap .client-list .client-top .client-info .client-img {
  width: 145px;
  height: 145px;
  border-radius: 100%;
  background: #000000;
  box-shadow: 0px 4px 20px 5px #00000026;
  z-index: 1;
  position: relative;
}
.design-studio-wrap .client-feedback .client-wrap .client-list .client-top .client-info .client-img .client-img-inner {
  position: absolute;
  width: 135px;
  height: 135px;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  margin: auto;
  z-index: 1;
  border-radius: 100%;
  overflow: hidden;
  border-bottom: 2px solid #1dc1b3cc;
  border-left: 2px solid #1dc1b3cc;
  border-right: 2px solid #dc477fcc;
  border-top: 2px solid #dc477fcc;
}
.design-studio-wrap .client-feedback .client-wrap .client-list .client-top .client-info .client-img .client-img-inner img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.design-studio-wrap .client-feedback .client-wrap .client-list .client-message {
  position: relative;
}
.design-studio-wrap .client-feedback .client-wrap .client-list .client-message::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: url("/assets/img/design-studio/quotes.png") no-repeat;
  width: 60px;
  height: 60px;
}
.design-studio-wrap .client-feedback .client-wrap .client-list .client-message p {
  padding: 30px 60px 0px;
  font-size: 20px;
  line-height: 30px;
  min-height: 180px;
}
.design-studio-wrap .contact-us {
  background: url("/assets/img/design-studio/contact-bg.png") no-repeat;
  display: flex;
  justify-content: center;
  background-size: cover;
  padding: 100px 0;
}
.design-studio-wrap .contact-us .contact-wrap {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 90%;
  margin: 0 auto;
}
.design-studio-wrap .contact-us .contact-wrap .flex-container {
  display: flex;
  justify-content: center;
  min-width: 90%;
}
.design-studio-wrap .contact-us .contact-wrap .flex-container .scroll-sec1 {
  flex: 0 0 50%;
  border-width: 1px;
  border: double 1px transparent;
  border-radius: 10px;
  border-left: 1px solid #1dc1b3cc;
  border-right: 1px solid #dc477fcc;
  background-image: linear-gradient(90deg, #1dc1b3cc, #dc477fcc), linear-gradient(90deg, #1dc1b3cc, #dc477fcc);
  background-size: 100% 1px;
  background-position: 0 0, 0 100%;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
}
.design-studio-wrap .contact-us .contact-wrap .flex-container .scroll-sec1::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: -1;
  border-radius: 15px;
}
.design-studio-wrap .contact-us .contact-wrap .flex-container .scroll-sec2 {
  flex: 0 0 50%;
  border-width: 1px;
  border-radius: 10px;
  padding: 50px;
  border-left: 1px solid #1dc1b3cc;
  border-right: 1px solid #dc477fcc;
  background-image: linear-gradient(90deg, #1dc1b3cc, #dc477fcc), linear-gradient(90deg, #1dc1b3cc, #dc477fcc);
  background-size: 100% 1px;
  background-position: 0 0, 0 100%;
  background-repeat: no-repeat;
  margin-left: 30px;
  position: relative;
}
.design-studio-wrap .contact-us .contact-wrap .flex-container .scroll-sec2::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(90.9deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.04) 100%);
  z-index: 0;
  border-radius: 10px;
  background-size: 100%;
}
.design-studio-wrap .contact-us .contact-detail h2 {
  padding: 30px 50px 10px;
  font-size: 54px;
  background: url("/assets/img/design-studio/good-design-bg.png");
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0;
}
.design-studio-wrap .contact-us .contact-detail a {
  color: #FFFFFF;
}
.design-studio-wrap .contact-us .contact-detail ul {
  margin-bottom: 0;
  list-style: none;
  padding: 30px 50px;
}
.design-studio-wrap .contact-us .contact-detail ul h5,
.design-studio-wrap .contact-us .contact-detail ul .follow-us-head {
  font-family: Inter;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: -0.02em;
  text-align: left;
  color: #fff;
  margin: 60px 0 40px;
}
.design-studio-wrap .contact-us .contact-detail ul .follow-us-head {
  margin: 0;
}
.design-studio-wrap .contact-us .contact-detail ul .follow-us-head h4 {
  position: relative;
  font-family: Inter;
  font-size: 28px;
  font-weight: 400;
  line-height: 97px;
  letter-spacing: -0.02em;
  text-align: left;
}
.design-studio-wrap .contact-us .contact-detail ul .follow-us-head h4::before {
  content: "";
  position: absolute;
  bottom: 12px;
  left: 0;
  width: 250px;
  height: 1px;
  border: 0px solid;
  background: linear-gradient(270deg, rgba(36, 196, 181, 0) 0%, #24c4b5 100%);
}
.design-studio-wrap .contact-us .contact-detail ul li {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
.design-studio-wrap .contact-us .contact-detail ul li svg {
  margin-right: 12px;
  min-width: 25px;
}
.design-studio-wrap .contact-us .contact-detail ul li:nth-of-type(3) {
  align-items: flex-start;
}
.design-studio-wrap .contact-us .contact-detail ul li span {
  display: -webkit-inline-box;
  width: 92%;
  margin-left: auto;
  color: #ffffff;
  line-height: 35px;
  font-size: 20px;
}
.design-studio-wrap .contact-us .contact-detail ul li .follow-us-common {
  width: 100%;
}
.design-studio-wrap .contact-us .contact-detail ul li .follow-us-common ul.follow-us-ul {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
}
.design-studio-wrap .contact-us .contact-detail ul li .follow-us-common ul.follow-us-ul li {
  margin-bottom: 0;
}
.design-studio-wrap .contact-us .contact-detail ul li .follow-us-common ul.follow-us-ul li a span svg {
  width: 40px;
  height: 40px;
}
.design-studio-wrap .contact-us .contact-form .form-group {
  position: relative;
  margin-bottom: 60px;
  margin-top: 20px;
  /* Optional: Change border color when input is focused */
}
.design-studio-wrap .contact-us .contact-form .form-group .error {
  color: #f00;
  position: absolute;
  top: 100%;
  left: 0;
}
.design-studio-wrap .contact-us .contact-form .form-group:last-child {
  margin-bottom: 0;
}
.design-studio-wrap .contact-us .contact-form .form-group button {
  border-width: 1px;
  border-radius: 7px;
  padding: 12px 50px;
  border-left: 1px solid #1dc1b3cc;
  border-right: 1px solid #dc477fcc;
  background-image: linear-gradient(90deg, #1dc1b3cc, #dc477fcc), linear-gradient(90deg, #1dc1b3cc, #dc477fcc);
  background-size: 100% 1px;
  background-position: 0 0, 0 100%;
  background-repeat: no-repeat;
  font-size: 18px;
  background-color: #00000000;
  color: #fff;
  margin-top: 20px;
  box-shadow: 0px 5px 20px 0px #D84D814D;
  box-shadow: 5px 0px 20px 0px #2bc3b64d;
}
.design-studio-wrap .contact-us .contact-form .form-group .label {
  font-size: 20px;
  position: absolute;
  top: 10px;
  left: 0;
  transition: transform 0.3s ease-in-out;
  pointer-events: none;
  color: #fff;
}
.design-studio-wrap .contact-us .contact-form .form-group .input {
  width: 100%;
  padding: 10px 0;
  border: 0;
  transition: border-color 0.3s ease-in-out;
  background: transparent;
  border-bottom: 1px solid #fff;
  outline: none;
  resize: none;
  font-size: 20px;
  color: #fff;
}
.design-studio-wrap .contact-us .contact-form .form-group .input.select-box {
  color: #fff;
  font-size: 20px !important;
  line-height: 40px;
}
.design-studio-wrap .contact-us .contact-form .form-group .input.select-box option {
  color: #000000;
  background: #ffffff;
  font-size: 20px !important;
}
.design-studio-wrap .contact-us .contact-form .form-group .input.select-box:selected {
  color: #ffffff;
}
.design-studio-wrap .contact-us .contact-form .form-group .input:focus {
  border-color: #fff;
}
.design-studio-wrap .contact-us .contact-form .form-group textarea {
  min-height: 45px;
  max-height: 45px;
  line-height: 18px;
}
.design-studio-wrap .contact-us .contact-form .form-group .input:focus + .label,
.design-studio-wrap .contact-us .contact-form .form-group .input.filled + .label {
  transform: translateY(-20px);
  font-size: 16px;
  /* optional: reduce font size when label is translated */
}
.design-studio-wrap .contact-us .contact-form .error-message {
  position: absolute;
  bottom: 70px;
  color: red;
}
.design-studio-wrap section.design-process-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
  padding: 20px 0;
}
.design-studio-wrap section.design-process-footer .footer-right-link a {
  color: #333333;
  margin: 0px 5px;
}
.design-studio-wrap section.design-process-footer .footer-right-link span {
  margin-right: 15px;
}
@media only screen and (max-width: 768px) {
  .design-studio-wrap .simplifying-section .container {
    padding: 20px;
  }
  .design-studio-wrap .simplifying-section .first-inner h2 {
    font-size: 80px;
  }
  .design-studio-wrap .simplifying-section .first-inner h3 {
    font-size: 40px;
  }
  .design-studio-wrap .good-design-section .container .second-inner {
    padding: 40px 20px;
  }
  .design-studio-wrap .good-design-section .container .second-inner h2 {
    font-size: 45px;
  }
  .design-studio-wrap .good-design-section .container .second-inner p {
    font-size: 16px;
  }
  .design-studio-wrap .design-service-section .service-inner h3,
  .design-studio-wrap .portfolio-section h3 {
    font-size: 48px;
    margin-bottom: 30px;
  }
  .design-studio-wrap .design-service-section .service-inner .service-list {
    padding: 25px;
  }
  .design-studio-wrap .design-service-section .service-inner .service-list.mobile-show {
    display: block;
  }
  .design-studio-wrap .design-service-section .other-service .service-right.mobile-hide {
    display: none;
  }
  .design-studio-wrap .design-service-section .service-inner .service-list .service-top {
    flex-direction: column;
    align-items: flex-start;
  }
  .design-studio-wrap .design-service-section .service-inner .service-list .service-top strong {
    width: 100%;
    flex: 0 0 100%;
    margin-bottom: 30px;
  }
  .design-studio-wrap .design-service-section .service-inner .service-list .service-content ul {
    flex-wrap: wrap;
  }
  .design-studio-wrap .design-service-section .service-inner .service-list .service-content ul li {
    width: 33.3%;
    margin-bottom: 15px;
  }
  .design-studio-wrap .design-service-section .service-inner .other-service {
    display: block;
  }
  .design-studio-wrap .design-service-section .service-inner .other-service .service-left {
    width: 100%;
  }
  .design-studio-wrap .design-service-section .service-inner .other-service .service-right {
    width: 100%;
    margin-left: 0px;
  }
  .design-studio-wrap .design-service-section .service-inner .design-process-idea h4 {
    font-size: 24px;
  }
  .design-studio-wrap .design-service-section .service-inner .design-process-idea .idea-bottom {
    font-size: 18px;
  }
  .design-studio-wrap .design-service-section .service-inner .design-process-idea::before,
  .design-studio-wrap .design-service-section .service-inner .design-process-idea::after {
    width: 120px;
    height: 60px;
  }
  .design-studio-wrap .design-service-section,
  .design-studio-wrap .good-design-section,
  .design-studio-wrap .portfolio-section,
  .design-studio-wrap .newsletter-section,
  .design-studio-wrap .client-feedback {
    padding: 60px 0px;
  }
  .design-studio-wrap .design-service-section .service-inner .design-process-idea {
    margin-top: 50px;
  }
  .design-studio-wrap .portfolio-section .portfolio-wrap {
    padding: 20px 0px;
  }
  .design-studio-wrap .portfolio-section .portfolio-wrap .portfolio-list {
    width: 100%;
  }
  .design-studio-wrap .portfolio-section .portfolio-wrap .portfolio-list:nth-of-type(2) {
    margin-top: 30px;
  }
  .design-studio-wrap .newsletter-section .blog-wrap {
    flex-direction: column;
  }
  .design-studio-wrap .newsletter-section .blog-wrap .blog-content {
    width: 100%;
  }
  .design-studio-wrap .newsletter-section .blog-wrap .blog-content p {
    font-size: 24px;
    line-height: 36px;
  }
  .design-studio-wrap .newsletter-section .blog-wrap .blog-img {
    margin-top: 50px;
  }
  .design-studio-wrap .client-feedback h3 {
    font-size: 48px;
  }
  .design-studio-wrap .portfolio-section .portfolio-wrap .portfolio-list:nth-of-type(3) .portfolio-inner {
    flex-direction: column;
    align-items: flex-start;
  }
  .design-studio-wrap .portfolio-section .portfolio-wrap .portfolio-list:nth-of-type(3) .portfolio-inner .portfolio-detail {
    width: 100%;
  }
}
@media only screen and (max-width: 568px) {
  .design-studio-wrap .portfolio-section .portfolio-wrap .portfolio-list .portfolio-inner .portfolio-action {
    width: 100%;
  }
  .design-studio-wrap .container {
    padding-left: 0px;
    padding-right: 0px;
  }
  .design-studio-wrap .simplifying-section .container {
    padding: 20px;
  }
  .design-studio-wrap .simplifying-section .first-inner h2 {
    font-size: 48px;
  }
  .design-studio-wrap .simplifying-section .first-inner h3 {
    font-size: 28px;
  }
  .design-studio-wrap .good-design-section .container .second-inner {
    padding: 40px 20px;
  }
  .design-studio-wrap .good-design-section .container .second-inner h2 {
    font-size: 30px;
  }
  .design-studio-wrap .good-design-section .container .second-inner p {
    font-size: 14px;
  }
  .design-studio-wrap .design-service-section .service-inner h3,
  .design-studio-wrap .portfolio-section h3 {
    font-size: 28px;
    margin-bottom: 0px;
  }
  .design-studio-wrap .design-service-section .service-inner .service-top button {
    padding: 6px 12px;
  }
  .design-studio-wrap .design-service-section .service-inner .service-list {
    padding: 15px;
  }
  .design-studio-wrap .design-service-section .service-inner .service-list .service-top strong,
  .design-studio-wrap .design-service-section .service-inner .service-list strong {
    font-size: 24px;
    margin-bottom: 15px;
    line-height: 24px;
    display: flex;
  }
  .design-studio-wrap .design-service-section .service-inner .other-service .service-left .service-list {
    padding-bottom: 30px;
  }
  .design-studio-wrap .design-service-section .service-inner .service-list p {
    font-size: 14px;
  }
  .design-studio-wrap .portfolio-section .portfolio-wrap .portfolio-list .portfolio-inner span,
  .design-studio-wrap .newsletter-section .blog-wrap .blog-content a {
    font-size: 16px;
  }
  .design-studio-wrap .portfolio-section .portfolio-wrap .portfolio-list .portfolio-inner strong {
    font-size: 26px;
  }
  .design-studio-wrap .portfolio-section .portfolio-wrap .portfolio-list .portfolio-inner .portfolio-action a {
    font-size: 16px;
    padding: 10px 0px;
  }
  .design-studio-wrap .design-service-section .service-inner .service-list .service-top {
    flex-direction: column;
    align-items: flex-start;
  }
  .design-studio-wrap .design-service-section .service-inner .service-list .service-top strong {
    width: 100%;
    flex: 0 0 100%;
    margin-bottom: 15px;
  }
  .design-studio-wrap .design-service-section .service-inner .other-service .service-left .service-list:nth-of-type(1) {
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .design-studio-wrap .design-service-section .service-inner .service-list .service-content ul {
    flex-wrap: wrap;
  }
  .design-studio-wrap .design-service-section .service-inner .service-list .service-content ul li {
    width: 100%;
    margin-bottom: 10px;
    padding: 15px 0px;
  }
  .design-studio-wrap .design-service-section .service-inner .other-service {
    display: block;
  }
  .design-studio-wrap .design-service-section .service-inner .other-service .service-left {
    width: 100%;
  }
  .design-studio-wrap .design-service-section .service-inner .other-service .service-right {
    width: 100%;
    margin-left: 0px;
  }
  .design-studio-wrap .design-service-section .service-inner .design-process-idea h4 {
    font-size: 18px;
  }
  .design-studio-wrap .design-service-section .service-inner .design-process-idea .idea-bottom a {
    font-size: 18px;
  }
  .design-studio-wrap .design-service-section .service-inner .design-process-idea::before,
  .design-studio-wrap .design-service-section .service-inner .design-process-idea::after {
    width: 120px;
    height: 60px;
  }
  .design-studio-wrap .portfolio-section h3 {
    line-height: 40px;
  }
  .design-studio-wrap .portfolio-section .portfolio-top a {
    font-size: 16px;
    padding-bottom: 10px;
  }
  .design-studio-wrap .design-service-section,
  .design-studio-wrap .good-design-section,
  .design-studio-wrap .portfolio-section,
  .design-studio-wrap .newsletter-section,
  .design-studio-wrap .client-feedback {
    padding: 30px 0px;
  }
  .design-studio-wrap .design-service-section .service-inner .design-process-idea {
    padding: 20px;
  }
  .design-studio-wrap .design-service-section .service-inner .design-process-idea {
    margin-top: 50px;
  }
  .design-studio-wrap .portfolio-section .portfolio-wrap {
    padding: 20px 0px;
  }
  .design-studio-wrap .portfolio-section .portfolio-wrap .portfolio-list {
    width: 100%;
  }
  .design-studio-wrap .portfolio-section .portfolio-wrap .portfolio-list:nth-of-type(2) {
    margin-top: 30px;
  }
  .design-studio-wrap .newsletter-section .blog-wrap {
    flex-direction: column;
  }
  .design-studio-wrap .newsletter-section .blog-wrap .blog-content {
    width: 100%;
  }
  .design-studio-wrap .newsletter-section .blog-wrap .blog-content p {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 20px;
  }
  .design-studio-wrap .contact-us .contact-form .form-group .input {
    font-size: 16px;
  }
  .design-studio-wrap .contact-us .contact-form .form-group .input::placeholder {
    font-size: 16px;
  }
  .design-studio-wrap .contact-us .contact-form .form-group {
    margin-bottom: 30px;
  }
  .design-studio-wrap .contact-us .contact-form .form-group .input.select-box {
    font-size: 16px !important;
  }
  .design-studio-wrap .contact-us .contact-form .form-group .label {
    font-size: 16px;
  }
  .design-studio-wrap .newsletter-section .blog-wrap .blog-content em {
    font-size: 18px;
  }
  .design-studio-wrap .newsletter-section .blog-wrap .blog-img {
    margin-top: 30px;
  }
  .design-studio-wrap .client-feedback h3 {
    font-size: 28px;
  }
  .design-studio-wrap .contact-us .contact-wrap {
    flex-direction: column;
  }
  .design-studio-wrap .contact-us .contact-wrap .flex-container .scroll-sec1,
  .design-studio-wrap .contact-us .contact-wrap .flex-container .scroll-sec2 {
    flex: 0 0 100%;
  }
  .design-studio-wrap .contact-us .contact-wrap .flex-container {
    display: block;
    max-width: 100% !important;
    min-width: 100% !important;
  }
  .design-studio-wrap .contact-us .contact-detail h2 {
    padding: 20px 20px 10px;
    font-size: 28px;
  }
  .design-studio-wrap .contact-us .contact-detail ul {
    padding: 20px;
  }
  .design-studio-wrap .contact-us {
    padding: 30px 0px;
  }
  .design-studio-wrap .contact-us .contact-detail ul li span {
    font-size: 16px;
  }
  .design-studio-wrap .contact-us .contact-detail ul h5,
  .design-studio-wrap .contact-us .contact-detail ul .follow-us-head {
    font-size: 18px;
    margin: 20px;
  }
  .design-studio-wrap .contact-us .contact-detail ul .follow-us-head h4 {
    font-size: 18px;
  }
  .design-studio-wrap .contact-us .contact-detail ul .follow-us-head {
    margin: 0px;
  }
  .design-studio-wrap .contact-us .contact-wrap .flex-container .scroll-sec2 {
    margin-left: 0px;
    margin-top: 30px;
    padding: 20px;
  }
  .design-studio-wrap br {
    display: none !important;
  }
  .design-studio-wrap .design-service-section .service-inner .service-list .service-top p {
    width: 100%;
    font-size: 14px;
  }
  .design-studio-wrap .design-service-section .service-inner .service-list .service-top {
    margin-bottom: 0px;
  }
  .design-studio-wrap .design-service-section .service-inner .service-list .service-content ul li span {
    font-size: 14px;
  }
  .design-studio-wrap .design-service-section .service-inner .other-service .service-right .service-list {
    padding: 20px;
    margin-top: 80px;
  }
  .design-studio-wrap .design-service-section .service-inner .other-service .service-right .service-list strong {
    display: flex;
  }
  .design-studio-wrap .design-service-section .service-inner .other-service .service-left {
    margin-top: 30px;
  }
  .design-studio-wrap .newsletter-section .newsletter-wrap strong {
    font-size: 18px;
  }
  .design-studio-wrap .newsletter-section .newsletter-wrap h3 {
    font-size: 28px;
  }
  .design-studio-wrap .newsletter-section .newsletter-wrap .newsletter-form {
    flex-direction: column;
  }
  .design-studio-wrap .newsletter-section .newsletter-wrap .newsletter-form .newsletter-input {
    width: 100%;
    margin-bottom: 30px;
  }
  .design-studio-wrap .newsletter-section .newsletter-wrap .newsletter-form .form-button {
    justify-content: flex-start;
  }
  .design-studio-wrap .client-feedback .client-wrap .client-list .client-message p {
    font-size: 14px;
    padding: 0px;
    min-height: auto;
  }
  .design-studio-wrap .client-feedback .client-wrap {
    padding: 10px;
  }
  .design-studio-wrap .client-feedback .client-wrap .client-list .client-message::before {
    display: none;
  }
  .design-studio-wrap .client-feedback .client-wrap .slick-next {
    right: -10px !important;
  }
  .design-studio-wrap .client-feedback .client-wrap .slick-prev {
    left: -10px !important;
    z-index: 2;
  }
  .design-studio-wrap .client-feedback .client-wrap .client-list .client-top .client-info .client-name {
    margin-bottom: 20px;
    width: 100%;
  }
  .design-studio-wrap .client-feedback .client-wrap .slick-next,
  .design-studio-wrap .client-feedback .client-wrap .slick-prev {
    width: 40px;
    height: 40px;
  }
  .design-studio-wrap .client-feedback .client-wrap .slick-next::before {
    background-size: 100%;
  }
  .design-studio-wrap .client-feedback .client-wrap .slick-prev::before {
    background-size: 100%;
  }
  .design-studio-wrap .client-feedback .client-wrap .client-list .client-top {
    flex-direction: column;
  }
  .design-studio-wrap .client-feedback .client-wrap .client-list .client-top .client-info {
    flex-direction: column;
    margin-bottom: 20px;
  }
  .design-studio-wrap .portfolio-section .portfolio-wrap .portfolio-list:nth-of-type(3) .portfolio-inner .portfolio-img {
    width: 100%;
  }
  .design-studio-wrap .design-service-section .service-inner .service-list .service-content ul li::after {
    left: 0;
    right: 0;
    background: url("/assets/img/design-studio/design-line.png") no-repeat;
    background-size: cover;
    height: 6px;
    width: 100%;
    bottom: 0;
    top: 100%;
  }
  .design-studio-wrap section.design-process-footer,
  .design-studio-wrap.design-process section.design-process-footer {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .design-studio-wrap .simplifying-section .first-inner h1 img {
    width: 70%;
  }
  .design-studio-wrap .newsletter-section .newsletter-wrap strong,
  .design-studio-wrap .newsletter-section .newsletter-wrap .newsletter-form {
    margin-top: 30px;
  }
  .design-studio-wrap section.design-process-footer .footer-right-link {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
  .design-studio-wrap section.design-process-footer .footer-right-link span {
    display: flex;
    width: 100%;
    margin-bottom: 15px;
  }
  .design-studio-wrap section.design-process-footer .footer-right-link span a {
    margin-left: 0px;
    margin-right: 10px;
  }
}
