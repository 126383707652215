.crypto-otc-development {
  margin: 0!important;
  width: 100%;
}
.crypto-otc-development .lets-connect-form {
  padding: 50px 80px 50px 150px;
  background: linear-gradient(90deg, #fffbf1, #f9f9f9 50%), #f9f9f9;
}
.crypto-otc-development .lets-connect-form .heading.main-para-div .secondary-span {
  font-size: 36px;
  line-height: 37px;
  color: #1E4B5E;
  font-family: 'Object Sans Heavy';
  font-weight: 800;
}
.crypto-otc-development .lets-connect-form .heading.main-para-div .primary-span {
  font-size: 60px;
  line-height: 63px;
  color: #24A989;
  font-family: 'Heldane Display';
  margin-top: 10px;
  font-weight: 800;
}
.crypto-otc-development .lets-connect-form .contact-modal {
  width: 100%;
  margin: auto;
  display: block;
  padding: 80px 20% 13% 15%;
  background: #FFFFFF;
  box-shadow: 0 4px 4px #00000014;
  border-radius: 8px;
}
.crypto-otc-development .lets-connect-form .contact-modal form {
  display: flex;
  align-items: flex-start;
  flex-flow: row wrap;
  justify-content: space-between;
}
.crypto-otc-development .lets-connect-form .contact-modal form .form-group {
  width: 100%;
}
.crypto-otc-development .lets-connect-form .contact-modal form .form-group .default-alert {
  font-weight: normal;
  font-size: 10px;
  color: #717981;
  font-family: 'Object Sans';
}
.crypto-otc-development .lets-connect-form .contact-modal form .form-group .form-element {
  margin-top: 30px;
}
.crypto-otc-development .lets-connect-form .contact-modal form .form-group.custom-rct-phn .react-tel-input input {
  padding: 0.375rem 0.75rem;
  padding-left: 48px;
}
.crypto-otc-development .lets-connect-form .contact-modal form .form-group .contact-form-label i {
  font-style: normal;
}
.crypto-otc-development .lets-connect-form .contact-modal form .form-group.w-70 {
  width: 63%!important;
}
.crypto-otc-development .lets-connect-form .contact-modal form .form-group.w-30 {
  width: 35%!important;
}
.crypto-otc-development .lets-connect-form .contact-modal form .form-group input,
.crypto-otc-development .lets-connect-form .contact-modal form .form-group .contact-form-txt-area {
  resize: none;
}
.crypto-otc-development .lets-connect-form .contact-modal .contact-submit {
  float: right;
  margin-top: 10px;
}
.crypto-otc-development .crypto-banner-otc {
  margin-top: 100px;
  padding: 30px 80px 30px 150px;
  background-image: url(/assets/img/crypto-otc-development/banner-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.crypto-otc-development .crypto-banner-otc .main-head-1 .primary-span,
.crypto-otc-development .crypto-banner-otc .main-head-1 .secondary-span {
  color: #26C9A2;
}
.crypto-otc-development .crypto-banner-otc .custom-section .main-head-1 .primary-span {
  font-size: 35px;
  line-height: 48px;
}
.crypto-otc-development .professionals-hold {
  padding-right: 50px;
  margin-top: -30px;
}
.crypto-otc-development .professionals-hold .professionals-ul li {
  width: 100%;
  margin-bottom: 20px;
  align-items: flex-start;
}
.crypto-otc-development .professionals-hold .professionals-ul li .professionals-info {
  font-size: 15px;
  color: #fff;
  letter-spacing: 0.5px;
  line-height: 24px;
}
.crypto-otc-development .professionals-hold .professionals-ul li .professionals-info strong {
  font-weight: 800;
  font-family: 'Object Sans Heavy';
  letter-spacing: 0px;
  font-size: 16px;
}
.crypto-otc-development .contact-details {
  padding-right: 90px;
  margin-top: -60px;
}
.crypto-otc-development .contact-details .main-head-1 .secondary-span {
  font-size: 13px;
  line-height: 18px;
  margin: 0px 0;
  color: #fff;
  letter-spacing: 0.2px;
  font-family: 'Object Sans';
}
.crypto-otc-development .contact-details .contact-modal {
  padding: 20px;
  border-radius: 5px;
  background: rgba(10, 122, 128, 0.5);
}
.crypto-otc-development .contact-details .contact-modal .secondary-span {
  font-size: 15px;
  color: #26c9a2;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  font-family: 'Object Sans Heavy';
}
.crypto-otc-development .contact-details form {
  padding: 0px;
  border-radius: 12px;
}
.crypto-otc-development .contact-details form .form-group label.contact-form-label.form-label {
  color: #fff;
  font-size: 14px;
}
.crypto-otc-development .contact-details form .form-group p {
  font-size: 11px;
  margin: 0;
  margin-top: 5px;
}
.crypto-otc-development .contact-details form .contact-submit-btn {
  text-align: right;
  margin-top: 30px;
}
.crypto-otc-development .contact-details .main-head-div {
  margin-bottom: 20px;
}
.crypto-otc-development .contact-details .mail-to-ix a {
  color: #078ffa !important;
  font-weight: 700;
  text-decoration: underline!important;
  cursor: pointer;
}
.crypto-otc-development .contact-details .mail-to-ix {
  font-size: 14px;
  line-height: 22px;
  margin: 10px 0 0 0;
  color: #fff;
}
.crypto-otc-development .vedio-explanation {
  background: linear-gradient(90deg, #F7FFFD 0%, #FDFDFD 100%), #F9F9F9;
  width: 100%;
  max-width: 100%;
  display: flex;
  margin: 100px 0 50px 0;
  padding: 30px 0;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;
}
.crypto-otc-development .vedio-explanation .main-head-div {
  width: 100%;
}
.crypto-otc-development .vedio-explanation .main-head-div .main-head-1 .primary-span {
  font-size: 40px;
  line-height: 50px;
}
.crypto-otc-development .offer-crypto .main-head-1 .primary-span {
  font-size: 40px;
  line-height: 50px;
  text-transform: capitalize;
}
.crypto-otc-development .offer-crypto .main-head-1 .secondary-span {
  font-size: 30px;
  line-height: 36px;
  margin-top: 20px;
}
.crypto-otc-development .offer-crypto .main-head-1 .secondary-span em {
  font-style: normal;
}
.crypto-otc-development .custom-section.offer-crypto {
  padding: 60px 140px 30px;
  padding-left: 150px;
}
.crypto-otc-development .custom-section.offer-crypto .sec-22 {
  padding-left: 0;
}
.crypto-otc-development .custom-section.offer-crypto .sec-22 .main-para-div {
  padding-right: 90px;
}
.crypto-otc-development .c-cards-mg .service-img,
.crypto-otc-development .c-cards-mg .service-img.ylow-bx:before {
  width: 100px;
  height: 100px;
}
.crypto-otc-development .c-cards-mg .service-head h4,
.crypto-otc-development .c-cards-mg .service-head h3 {
  font-size: 25px;
  line-height: 32px;
}
.crypto-otc-development .c-cards-mg .main-para-div p {
  font-size: 16px;
}
.crypto-otc-development .testimonials.crypto-blog-slider {
  background: linear-gradient(90deg, #F7FFFD 0%, #FDFDFD 100%), #F9F9F9;
  width: 100%;
  max-width: 100%;
  padding: 0px 110px;
  padding-left: 150px;
}
.crypto-otc-development .testimonials.crypto-blog-slider .section-4.MarketPlace {
  padding-top: 50px!important;
  padding-bottom: 40px!important;
}
.crypto-otc-development .testimonials.crypto-blog-slider .custom-container {
  width: 100%;
  max-width: 100%;
}
.crypto-otc-development .testimonials.crypto-blog-slider .custom-container .main-head-1 .primary-span {
  font-size: 40px;
  line-height: 50px;
}
.crypto-otc-development .testimonials.crypto-blog-slider ul.crypt-blog-ul.principles-common-ul {
  width: 100%;
}
.crypto-otc-development .testimonials.crypto-blog-slider ul.crypt-blog-ul.principles-common-ul li.crypt-blog-li {
  width: 100%;
  max-width: 100%;
}
.crypto-otc-development .testimonials.crypto-blog-slider ul.crypt-blog-ul.principles-common-ul li.crypt-blog-li .blog-colunm-card {
  box-shadow: 0 0 10px #ddd;
  padding: 10px;
  border-radius: 5px;
  min-height: 460px;
}
.crypto-otc-development .testimonials.crypto-blog-slider ul.crypt-blog-ul.principles-common-ul li.crypt-blog-li .blog-colunm-card .principles-info-hold h4 {
  max-width: 100%;
}
.crypto-otc-development .testimonials.crypto-blog-slider ul.crypt-blog-ul.principles-common-ul li.crypt-blog-li .blog-colunm-card .principles-info-hold h4 a {
  width: 100%;
  display: block;
}
.crypto-otc-development .testimonials.crypto-blog-slider ul.crypt-blog-ul.principles-common-ul li.crypt-blog-li .blog-colunm-card .principles-info-hold h4 a img {
  width: 100%;
  height: 100%;
  object-position: center;
}
.crypto-otc-development .testimonials.crypto-blog-slider ul.crypt-blog-ul.principles-common-ul li.crypt-blog-li .blog-colunm-card .principles-info-hold p {
  font-family: 'Heldane Display';
  font-size: 25px;
  line-height: 34px;
}
.crypto-otc-development .more-text a {
  font-family: 'Object Sans';
  font-style: italic;
  font-size: 16px;
}
.crypto-otc-development .crypt-blog-post-details a {
  font-style: italic;
}
.vedio-explanation .video-file {
  width: 100%;
}
@media only screen and (max-width: 1347px) and (min-width: 1201px) {
  .crypto-otc-development .professionals-hold .professionals-ul li .professionals-info {
    font-size: 14px;
    line-height: 20px;
  }
  .crypto-otc-development .contact-details .contact-modal span.secondary-span {
    top: 9px;
  }
  .crypto-otc-development .contact-details {
    padding-right: 0;
  }
  br {
    display: none!important;
  }
}
@media only screen and (max-width: 1200px) and (min-width: 768px) {
  br {
    display: none!important;
  }
  .crypto-otc-development .testimonials.crypto-blog-slider ul.crypt-blog-ul.principles-common-ul li.crypt-blog-li .blog-colunm-card {
    min-height: 450px;
  }
  .crypto-otc-development .contact-details {
    margin-top: -65px;
  }
  .crypto-otc-development .contact-details .main-head-1 .secondary-span {
    font-size: 12px;
  }
  .crypto-otc-development .contact-details .main-head-div {
    margin-bottom: 20px;
  }
  .crypto-otc-development .contact-details {
    padding-right: 0;
  }
  .crypto-otc-development .crypto-banner-otc .custom-section .main-head-1 .primary-span,
  .crypto-otc-development .vedio-explanation .main-head-div .main-head-1 .primary-span,
  .crypto-otc-development .offer-crypto .main-head-1 .primary-span,
  .testimonials.crypto-blog-slider .custom-container .main-head-1 .primary-span {
    font-size: 33px;
    line-height: 42px;
  }
  .crypto-otc-development .professionals-hold .professionals-ul li .professionals-info {
    font-size: 14px!important;
    line-height: 19px!important;
    margin-left: 5px;
  }
  .crypto-otc-development .professionals-hold .professionals-ul li .professionals-info strong {
    font-size: 15px!important;
  }
}
@media only screen and (max-width: 1099px) and (min-width: 899px) {
  .crypto-otc-development .contact-details .main-head-1 .secondary-span {
    font-size: 13px;
  }
}
@media only screen and (max-width: 1200px) and (min-width: 991px) {
  .crypto-otc-development .professionals-hold,
  .crypto-otc-development .contact-details {
    padding-right: 0px;
  }
  .mp-common-hold .more-text {
    margin-top: 10px!important;
  }
  .crypto-otc-development .testimonials.crypto-blog-slider ul.crypt-blog-ul.principles-common-ul li.crypt-blog-li .blog-colunm-card .principles-info-hold p {
    font-size: 22px;
    line-height: 22px;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 769px) {
  .crypto-otc-development .lets-connect-form {
    padding: 50px 70px 50px 110px;
  }
  .crypto-otc-development .contact-details .main-head-div {
    margin-bottom: 5px;
  }
  .crypto-otc-development .contact-details {
    margin-top: -50px;
  }
  .crypto-otc-development .contact-details .main-head-1 .secondary-span {
    font-size: 11px;
  }
  .crypto-otc-development .contact-details .mail-to-ix {
    font-size: 12px;
    line-height: normal;
  }
  .crypto-otc-development .testimonials.crypto-blog-slider ul.crypt-blog-ul.principles-common-ul li.crypt-blog-li .blog-colunm-card {
    min-height: 430px;
  }
  .sec-22 .main-para-div {
    padding-right: 20px;
  }
  .mp-common-hold .more-text {
    margin-top: 10px!important;
  }
  .crypto-otc-development .testimonials.crypto-blog-slider ul.crypt-blog-ul.principles-common-ul li.crypt-blog-li .blog-colunm-card .principles-info-hold p {
    font-size: 22px;
    line-height: 22px;
  }
  .crypto-otc-development .professionals-hold,
  .crypto-otc-development .contact-details {
    padding-right: 0px;
  }
  .crypto-otc-development .testimonials.crypto-blog-slider ul.crypt-blog-ul.principles-common-ul li.crypt-blog-li .blog-colunm-card .principles-info-hold p {
    font-size: 24px;
    line-height: 30px;
  }
  .crypto-otc-development .crypto-banner-otc {
    padding: 30px 90px 30px 110px;
    padding-left: 110px;
    margin-top: 90px;
  }
  .crypto-otc-development .crypto-otc .crypto-banner-otc:before {
    background-size: 100%;
  }
  .crypto-otc-development .contact-details form {
    padding: 20px;
  }
  .crypto-otc-development .custom-section.offer-crypto {
    padding: 60px 80px 10px;
    padding-left: 110px;
  }
  .crypto-otc-development .testimonials.crypto-blog-slider {
    padding: 0px 80px;
    padding-left: 110px;
  }
  .crypto-otc-development .contact-details .contact-modal {
    padding: 0;
  }
}
@media only screen and (max-width: 991px) and (min-width: 320px) {
  .crypto-otc-developmentt {
    background-image: none;
  }
  .blog-colunm-card {
    margin-top: 20px;
  }
  .crypto-otc-development .contact-details .main-head-1 .secondary-span {
    font-size: 12px;
  }
  .crypto-otc-development .contact-details .contact-modal {
    padding: 10px!important;
  }
  .crypto-otc-development .professionals-hold {
    padding-right: 0;
  }
  .crypto-otc-development .contact-details form {
    padding: 10px;
  }
  .crypto-otc-development .crypto-banner-otc,
  .crypto-otc-development .custom-section.offer-crypto,
  .crypto-otc-development .testimonials.crypto-blog-slider,
  .crypto-otc-development .lets-connect-form {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media only screen and (max-width: 949px) and (min-width: 900px) {
  .crypto-otc-development .contact-details .main-head-1 .secondary-span {
    font-size: 13px;
  }
}
@media only screen and (max-width: 831px) and (min-width: 769px) {
  .crypto-otc-development .contact-details .contact-modal span.secondary-span {
    top: 12px;
    line-height: 14px;
    left: 15px;
    font-size: 12px;
  }
}
@media only screen and (max-width: 799px) and (min-width: 320px) {
  .crypto-otc-development .professionals-hold .professionals-ul li {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 768px) and (min-width: 320px) {
  .crypto-otc-development .lets-connect-form.responsive-mode {
    flex-flow: row wrap;
    padding: 30px;
  }
  .crypto-otc-development .lets-connect-form.responsive-mode .heading.main-para-div .secondary-span {
    font-size: 24px;
    line-height: 37px;
  }
  .crypto-otc-development .lets-connect-form.responsive-mode .heading.main-para-div .primary-span {
    font-size: 36px;
    line-height: 43px;
  }
  .crypto-otc-development .lets-connect-form.responsive-mode .contact-modal {
    display: block;
    width: 100%;
    margin: 0;
  }
  .crypto-otc-development .contact-details .main-head-1 .secondary-span {
    font-size: 13px!important;
  }
  .crypto-otc-development .contact-details .mail-to-ix {
    font-size: 13px;
    margin-top: 10px;
  }
  .crypto-otc-development .testimonials.crypto-blog-slider ul.crypt-blog-ul.principles-common-ul li.crypt-blog-li .blog-colunm-card .principles-info-hold p {
    font-size: 24px;
    line-height: 30px;
  }
  .crypto-otc-development .contact-details .contact-modal span.secondary-span {
    top: 28px;
    left: 25px;
    font-size: 12px;
  }
  .crypto-otc-development {
    margin-left: 0!important;
    margin-right: 0!important;
    margin-top: 40px;
  }
  .crypto-otc-development .professionals-ul li .professionals-info {
    font-size: 14px;
    line-height: 18px;
    margin-left: 0;
  }
  .crypto-otc-development .professionals-ul li .professionals-num {
    min-width: 30px;
  }
  .crypto-otc-development .contact-details {
    padding: 10px;
    margin-bottom: 40px;
  }
  .crypto-otc-development .contact-details .main-head-1 .secondary-span {
    font-size: 15px;
    line-height: 16px;
    margin: 10px 0;
  }
  .crypto-otc-development .contact-details .contact-modal {
    padding: 0px;
  }
  .crypto-otc-development .contact-details form {
    padding: 15px;
  }
  .crypto-otc-development .contact-details form .contact-submit-btn {
    margin-top: 10px;
  }
  .crypto-otc-development .vedio-explanation .main-head-1 .primary-span {
    font-size: 45px;
    line-height: 35px;
    margin-bottom: 20px;
  }
  .crypto-otc-development .offer-crypto .main-head-1 .primary-span {
    margin-bottom: 20px;
  }
  .crypto-otc-development .offer-crypto .main-head-1 .primary-span br {
    display: none;
  }
  .crypto-otc-development .offer-crypto .main-head-1 .secondary-span {
    font-size: 28px;
    line-height: 32px;
    margin-top: 10px;
  }
  .crypto-otc-development .offer-crypto .main-head-1 .secondary-span br {
    display: none;
  }
  .crypto-otc-development .custom-section.offer-crypto {
    padding: 30px;
  }
  .crypto-otc-development .c-cards-mg .service-img,
  .crypto-otc-development .c-cards-mg .service-img.ylow-bx:before {
    width: 100px;
    height: 100px;
  }
  .crypto-otc-development .testimonials.crypto-blog-slider {
    padding: 30px;
  }
  .crypto-otc-development .testimonials.crypto-blog-slider .section-4.MarketPlace {
    padding-bottom: 0!important;
    padding-top: 0!important ;
  }
  .crypto-otc-development .testimonials.crypto-blog-slider .testi-col {
    margin-bottom: 0;
  }
  .crypto-otc-development .testimonials.crypto-blog-slider .custom-container .main-head-1 .primary-span {
    font-size: 47px;
    line-height: 35px;
    margin-bottom: 20px;
  }
  .crypto-otc-development .testimonials.crypto-blog-slider .custom-container .principles-common-ul .principles-info-hold h4 {
    font-size: 34px;
    line-height: 43px;
  }
  .crypto-otc-development .testimonials.crypto-blog-slider ul.crypt-blog-ul.principles-common-ul li.crypt-blog-li {
    margin-bottom: 0;
  }
  .crypto-otc-development .testimonials.crypto-blog-slider ul.crypt-blog-ul.principles-common-ul li.crypt-blog-li .blog-colunm-card {
    min-height: 440px;
  }
}
@media only screen and (max-width: 767px) and (min-width: 320px) {
  .crypto-otc-development .lets-connect-form.responsive-mode {
    flex-flow: row wrap;
  }
  .crypto-otc-development .lets-connect-form.responsive-mode .contact-modal {
    display: block;
  }
  .crypto-otc-development .testimonials.crypto-blog-slider ul.crypt-blog-ul.principles-common-ul li.crypt-blog-li .blog-colunm-card {
    min-height: auto;
  }
  .blog-colunm-card {
    margin-top: 0px;
  }
  .crypto-otc-development .crypto-banner-otc .custom-section .main-head-div {
    margin-bottom: 20px;
  }
  .crypto-otc-development .testimonials.crypto-blog-slider ul.crypt-blog-ul.principles-common-ul li.crypt-blog-li .blog-colunm-card .principles-info-hold p {
    font-size: 23px;
    line-height: 28px;
    width: 100%;
    min-width: 100%;
  }
  .crypto-otc-development .custom-section.offer-crypto .sec-22 .main-para-div {
    padding-right: 0;
  }
  .crypto-otc-development .contact-details .contact-modal span.secondary-span {
    top: 70px;
    left: 25px;
  }
  .crypto-otc-development .professionals-hold,
  .crypto-otc-development .contact-details {
    margin-top: 0;
  }
  .crypto-otc-development .crypto-banner-otc,
  .crypto-otc-development .vedio-explanation,
  .crypto-otc-development .custom-section.offer-crypto,
  .crypto-otc-development .testimonials.crypto-blog-slider,
  .crypto-otc-development .lets-connect-form.responsive-mode {
    padding-left: 20px ;
    padding-right: 20px ;
  }
  .crypto-otc-development .contact-details form .form-group label.contact-form-label.form-label {
    font-size: 14px;
  }
  .crypto-otc-development .custom-section .main-head-1 .primary-span,
  .crypto-otc-development .offer-crypto .main-head-1 .primary-span,
  .crypto-otc-development .vedio-explanation .main-head-1 .primary-span,
  .crypto-otc-development .testimonials.crypto-blog-slider .custom-container .main-head-1 .primary-span,
  .crypto-otc-development .vedio-explanation .main-head-div .main-head-1 .primary-span {
    font-size: 28px!important;
    line-height: 36px!important;
  }
  .crypto-otc-development .contact-details {
    border-top: 1px solid #ddd;
    padding: 10px;
    padding-top: 0;
    margin-top: 0px;
  }
  .crypto-otc-development .contact-details form .contact-submit-btn {
    margin: 30px 20px 0px 20px;
    text-align: center;
  }
  .crypto-otc-development .custom-section .main-head-1 .primary-span,
  .crypto-otc-development .custom-section .main-head-1 .secondary-span,
  .crypto-otc-development .custom-section .testimonials.crypto-blog-slider .custom-container .principles-common-ul .principles-info-hold h4 {
    font-size: 24px;
    line-height: 30px;
  }
  .crypto-otc-development .vedio-explanation .video-file {
    width: 100%;
  }
  .crypto-otc-development .contact-details .main-head-1 .secondary-span {
    line-height: 20px;
    margin-top: 30px;
    margin-bottom: 40px;
  }
  .crypto-otc-development .professionals-hold {
    margin-bottom: 20px;
  }
  .crypto-otc-development .crypto-banner-otc {
    padding-bottom: 0;
    background-position: center;
  }
  .crypto-otc-development .vedio-explanation .main-head-div {
    margin-bottom: 30px;
  }
  .crypto-otc-development .crypto-banner-otc .custom-section .main-head-1 .primary-span br {
    display: none;
  }
  .crypto-otc-development .testimonials.crypto-blog-slider ul.crypt-blog-ul.principles-common-ul li.crypt-blog-li .blog-colunm-card {
    margin: 10px 0;
  }
}
@media only screen and (max-width: 425px) and (min-width: 320px) {
  .crypto-otc-development .lets-connect-form .contact-modal form .form-group label.contact-form-label.form-label {
    font-size: 14px;
    margin-bottom: 0;
  }
  .crypto-otc-development .crypto-banner-otc .custom-section .main-head-1 .primary-span {
    font-size: 36px;
    line-height: 45px;
  }
  .crypto-otc-development .crypto-banner-otc.white-bg .professionals-hold .professionals-ul li .professionals-info strong {
    background-position: 0px 13px!important;
    font-size: 20px;
    background-size: 37px 8px;
  }
  .crypto-otc-development .crypto-banner-otc.white-bg .professionals-hold .professionals-ul li .professionals-info span {
    font-size: 14px;
  }
  .crypto-otc-development .lets-connect-form.responsive-mode {
    flex-flow: row wrap;
  }
  .crypto-otc-development .lets-connect-form.responsive-mode .heading.main-para-div {
    margin-bottom: 0;
  }
  .crypto-otc-development .lets-connect-form.responsive-mode .heading.main-para-div .secondary-span {
    font-size: 20px;
    line-height: 30px;
  }
  .crypto-otc-development .lets-connect-form.responsive-mode .heading.main-para-div .primary-span {
    font-size: 30px;
    margin-top: 0;
  }
  .crypto-otc-development .lets-connect-form.responsive-mode .contact-modal {
    display: block;
    width: 100%;
    margin: 0;
    padding: 50px;
    margin-top: 10px;
    padding-bottom: 80px;
  }
  .crypto-otc-development .lets-connect-form.responsive-mode .contact-modal .form-group.w-30,
  .crypto-otc-development .lets-connect-form.responsive-mode .contact-modal .form-group.w-70 {
    width: 100%!important;
  }
  .crypto-otc-development .crypto-banner-otc.white-bg .professionals-hold .professionals-ul li button {
    font-size: 16px;
  }
  .crypto-otc-development .crypto-banner-otc,
  .crypto-otc-development .vedio-explanation,
  .crypto-otc-development .custom-section.offer-crypto,
  .crypto-otc-development .testimonials.crypto-blog-slider {
    padding-left: 20px ;
  }
  .crypto-otc-development .contact-details .contact-modal span.secondary-span {
    top: 80px;
  }
}
@media only screen and (max-width: 375px) and (min-width: 320px) {
  .crypto-otc-development .contact-details .contact-modal span.secondary-span {
    top: 73px;
  }
  .crypto-otc-development .lets-connect-form.responsive-mode .contact-modal {
    padding: 30px;
  }
}
@media only screen and (max-width: 349px) {
  .crypto-otc-development .contact-details .contact-modal span.secondary-span {
    top: 98px;
  }
}
