.MarketPlaceCommon .section-1 {
  padding: 94px 0px 53px 0px;
}
.MarketPlace.section-1 {
  background-image: url(/assets/img/hero-images/market-hero-bg.png) !important;
  background-repeat: no-repeat;
  background-position: right;
  position: relative;
  height: auto;
}
.tech-stack-row {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.tech-stack-head {
  font-family: 'Object Sans Heavy';
  font-size: 24px;
  text-align: center;
  color: #1E4B5E;
  margin-bottom: 30px;
}
.tech-stack-card {
  width: 295px;
  height: 295px;
  background: #FFFFFF;
  box-shadow: 3px 3px 0px #FFD028, 2px 2px 1px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
}
.MarketPlaceCommon .section-2 .tech-stack-left-col {
  padding: 150px 0px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.MarketPlaceCommon .section-2 .tech-stack-left-col .custom-section {
  max-width: 400px;
}
.tech-stack-head {
  font-size: 20px;
}
.tech-stack-right-col {
  background: rgba(233, 233, 233, 0.3);
  padding: 50px 30px!important;
}
.tc-col {
  margin-bottom: 30px;
}
.tech-stack-ul {
  padding: 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.tech-stack-ul li {
  list-style: none;
  margin-bottom: 30px;
  cursor: pointer;
}
.tech-name p {
  margin-bottom: 0px;
  margin-top: 25px;
  font-family: Object Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: #1E4B5E;
}
.tech-arrow-hold svg {
  cursor: pointer;
}
.MarketPlaceCommon .section-3 .custom-section {
  margin-top: 40px;
}
.MarketPlaceCommon .section-3 .custom-container {
  align-items: inherit;
}
.mp-common-hold {
  width: 100%;
}
.market-place-features ul {
  padding: 0px;
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex: 0;
  width: 100%;
  margin-bottom: 0px;
}
.market-place-features ul li {
  list-style: none;
  margin-bottom: 60px;
  display: inline-flex;
  box-sizing: border-box;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  justify-content: flex-start;
  width: 25%;
}
.market-place-features .mp-ft-card {
  width: 240px;
  height: 295px;
  background: #2692AA;
  box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 45px;
  border: none;
}
.market-place-features .mp-ft-card-dbg {
  background: #1E4B5E !important;
}
.mp-ft-info p {
  font-size: 16px;
  text-align: center;
  color: #FFFFFF;
  margin: 0px;
  line-height: 24px;
  letter-spacing: -1px;
}
.mp-ft-icon-hold,
.mp-ft-info {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mp-ft-icon-hold {
  height: 65px;
}
.mp-ft-info {
  margin-top: 30px;
}
.MarketPlaceCommon .main-para-div .short-span {
  width: 70% !important;
}
.testi-client-review p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 9;
  overflow: hidden;
}
@media only screen and (max-width: 2560px) and (min-width: 1456px) {
  .market-place-features ul li:nth-child(4n-2) .mp-ft-card {
    margin: 0 auto;
  }
  .market-place-features ul li:nth-child(4n-1) .mp-ft-card {
    margin-left: auto;
    margin-right: 10px;
  }
  .market-place-features ul li:nth-child(4n+4) .mp-ft-card {
    margin-left: auto;
  }
  .tc-col:nth-child(2n) .tech-stack-card {
    margin: 0 auto;
  }
  .tc-col:nth-child(2n+1) .tech-stack-card {
    margin: 0 auto;
  }
}
@media only screen and (max-width: 1455px) {
  .market-place-features ul li:nth-child(4n-2) .mp-ft-card {
    margin: 0 auto;
    margin-left: 10px;
  }
  .market-place-features ul li:nth-child(4n-1) .mp-ft-card {
    margin-left: auto;
    margin-right: 10px;
  }
  .market-place-features ul li:nth-child(4n+4) .mp-ft-card {
    margin-left: auto;
  }
  .tc-col .tech-stack-card {
    width: 100%;
  }
  .tc-col:nth-child(2n) .tech-stack-card {
    margin-left: auto;
  }
  .MarketPlaceCommon.section-1 .custom-section {
    padding-top: 0px;
  }
}
@media only screen and (max-width: 1400px) and (min-width: 1280px) {
  .market-place-features ul li {
    width: 33.33%;
  }
  .market-place-features ul li:nth-child(3n+1) .mp-ft-card {
    margin-right: auto;
    margin-left: 0px;
  }
  .market-place-features ul li:nth-child(3n+2) .mp-ft-card {
    margin: 0 auto;
    margin-right: auto;
  }
  .market-place-features ul li:nth-child(3n+3) .mp-ft-card {
    margin-left: auto;
    margin-right: 0;
  }
}
@media only screen and (max-width: 1280px) {
  .market-place-features ul li {
    list-style: none;
    width: 33.33%;
  }
  .market-place-features ul li:nth-child(3n+1) .mp-ft-card {
    margin-right: auto;
    margin-left: 0px;
  }
  .market-place-features ul li:nth-child(3n+2) .mp-ft-card {
    margin: 0 auto;
    margin-right: auto;
  }
  .market-place-features ul li:nth-child(3n+3) .mp-ft-card {
    margin-left: auto;
    margin-right: 0;
  }
  .MarketPlaceCommon .section-1 {
    height: 120vh;
  }
}
@media only screen and (max-width: 1067px) {
  .market-place-features ul li {
    list-style: none;
    width: 33.33%;
  }
  .market-place-features ul li:nth-child(3n+1) .mp-ft-card {
    margin-right: auto;
    margin-left: 0px;
  }
  .market-place-features ul li:nth-child(3n+2) .mp-ft-card {
    margin: 0 auto;
  }
  .market-place-features ul li:nth-child(3n+3) .mp-ft-card {
    margin-left: auto;
    margin-right: 0;
  }
  .tech-stack-right-col,
  .tech-stack-left-col {
    max-width: 100%!important;
    flex: inherit!important;
    display: block!important;
  }
  .MarketPlace .section-2 .tech-stack-left-col .custom-section {
    width: 100%;
    max-width: inherit;
  }
  .tc-col:nth-child(2n) .tech-stack-card {
    margin-left: auto;
  }
  .testi-card {
    margin: 0 auto;
  }
  .MarketPlaceCommon .section-1 {
    height: 145vh;
  }
}
@media only screen and (max-width: 992px) {
  .market-place-features ul li {
    list-style: none;
    width: 50%;
  }
  .mp-common-hold .market-place-features ul li:nth-child(2n+1) .mp-ft-card {
    margin-left: 0px;
    margin-right: auto;
  }
  .mp-common-hold .market-place-features ul li:nth-child(2n) .mp-ft-card {
    margin-right: 0px;
    margin-left: auto;
  }
  .MarketPlaceCommon .section-1 {
    height: 100%!important;
  }
}
.MarketPlace .section-4 {
  background: linear-gradient(90deg, #FFFBF1 0%, #F9F9F9 50%), #F9F9F9;
}
.testi-col:focus {
  outline: none;
}
.testi-card {
  width: 465px;
  height: 560px;
  background: #FFFFFF;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  border: none!important;
}
.testi-client-head {
  padding: 18px 40px;
  background: #EBF0F2;
  border-radius: 5px 5px 0px 0px;
  min-height: 80px;
}
.testi-client-head p {
  margin-bottom: 0px;
  font-size: 16px;
  color: #1E4B5E;
}
.testi-client-head p span:first-child {
  font-family: 'Object Sans Heavy';
}
.testi-client-head p span:last-child {
  padding-left: 5px;
}
.testi-review-common {
  padding: 30px 60px;
  min-height: 320px;
}
.testi-client-review p {
  font-size: 18px;
  color: #1E4B5E;
  line-height: 24px;
  margin-bottom: 0px;
  position: relative;
}
.testi-client-review:before {
  background-image: url(/assets/img/market-place-imgs/quote.png);
  background-repeat: no-repeat;
  background-position: left;
  content: '';
  position: absolute;
  width: 75px;
  height: 75px;
  left: -35px;
  top: -20px;
}
.testi-client-review {
  margin-bottom: 20px;
  min-height: 216px;
  max-height: 216px;
  position: relative;
}
.testi-detailed-review a {
  font-size: 18px;
  line-height: 125%;
  /* identical to box height, or 22px */
  text-decoration-line: underline;
  color: #007CC1;
}
.testi-footer {
  background: #EBF0F2;
  border-radius: 0px 0px 5px 5px;
  display: flex;
  align-items: center;
  padding: 30px 30px;
  width: 100%;
  min-height: 161px!important;
  max-height: 161px!important;
}
.testi-comp-info {
  padding-left: 15px;
}
.testi-comp-info p {
  margin-bottom: 0px;
}
.testi-comp-info p:first-child {
  font-family: Heldane Display;
  font-weight: bold;
  font-size: 30px;
  line-height: 37px;
  color: #24A989;
  padding-bottom: 7px;
}
.testi-comp-info p:last-child {
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  color: #1E4B5E;
}
.testi-comp-info .role {
  font-family: "Object Sans Heavy";
  padding-right: 5px;
  color: #7E97A1 !important;
}
.testi-comp-logo {
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  min-width: 90px;
}
.testi-comp-logo img {
  width: 100%;
}
.slick-slide:nth-child(2n) .testi-col .testi-card {
  margin: auto;
}
.testi-col {
  padding: 10px 5px;
}
@media only screen and (max-width: 768px) {
  .MarketPlaceCommon .tc-col {
    max-width: 50%;
    flex: inherit;
  }
  .tc-col .tech-stack-card {
    margin: 0 auto;
  }
  .mp-common-hold .market-place-features ul li:nth-child(2n+1) .mp-ft-card {
    margin-right: auto;
    margin-left: 0px;
  }
  .mp-common-hold .market-place-features ul li:nth-child(2n) .mp-ft-card {
    margin-left: auto;
    margin-right: 0px;
  }
  .testi-col {
    margin-bottom: 30px;
  }
  .testi-col .testi-card {
    margin: 0 auto;
    width: 100%;
  }
  .testi-col:last-child .testi-card {
    margin-bottom: 0px;
  }
  .testi-comp-info p:first-child {
    font-size: 24px;
  }
  .testi-comp-info p:last-child {
    font-size: 13px;
  }
}
@media only screen and (max-width: 767px) {
  .MarketPlaceCommon .tc-col {
    max-width: 100%;
    flex: inherit;
  }
  .market-place-features ul li {
    list-style: none;
    width: 100%;
  }
  .mp-common-hold .market-place-features ul li .mp-ft-card {
    margin: 0 auto!important;
  }
  .MarketPlaceCommon .main-para-div .short-span {
    width: 100% !important;
  }
}
@media only screen and (max-width: 768px) and (min-width: 425px) {
  .MarketPlace .market-place-features .mp-ft-card.card {
    margin: 0 auto!important;
  }
}
@media only screen and (max-width: 678px) {
  .MarketPlace.section-1 {
    background-image: url(/assets/img/market-place-imgs/market-place-bg-2.png);
    background-repeat: no-repeat;
    background-position: right;
    position: relative;
    background-size: contain;
  }
}
@media only screen and (max-width: 425px) {
  div.Enterprise .work-with-row .comp-info-right-col {
    position: static;
  }
  div.company-info .company-offer-imgs svg {
    width: 95%;
  }
  .MarketPlaceCommon .section-2 .tech-stack-left-col {
    padding: inherit;
    min-width: 70%;
    max-width: 70%!important;
    margin: 0 auto;
  }
  .MarketPlaceCommon .section-2 .tech-card-item {
    min-width: 90%;
    max-width: 90%!important;
    margin: 0 auto;
  }
  .MarketPlaceCommon .section-2 .card-tech-card {
    min-height: 260px!important;
    min-width: 260px !important;
    width: 260px;
    margin: 0 auto;
  }
  .MarketPlaceCommon .testimonials .section-4.MarketPlace .custom-container {
    min-width: 80%;
    max-width: 80%;
  }
  .MarketPlaceCommon .testimonials .section-4.MarketPlace .custom-container .main-head-1 {
    padding: 0px 20px;
  }
  .MarketPlaceCommon .section-2.marketplace {
    padding: 60px 0px;
  }
  .custom-section .main-para-div p {
    font-size: 16px!important;
    font-family: 'Object Sans' !important;
    line-height: 20px;
  }
  .tech-stack-row {
    margin: 0px!important;
    width: 100%;
  }
  .tech-stack-right-col {
    padding: 30px 30px!important;
  }
  .tech-stack-card {
    display: block;
    width: 261px!important;
    height: 261px;
    position: relative;
  }
  .market-place-features .mp-ft-card {
    padding: 20px!important;
  }
  .market-place-features .mp-ft-card .mp-ft-card-info-hold {
    min-height: 120px;
    max-height: 120px;
  }
  .tech-stack-info-common {
    display: block;
  }
  .tech-arrow-hold {
    position: absolute;
    bottom: 15px;
    left: 0;
    right: 0;
    top: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .tech-arrow-hold svg {
    transform: rotate(90deg);
  }
  .tech-name p {
    font-size: 18px;
  }
  .slick-arrow {
    width: 40px;
    height: 40px;
  }
  .testi-client-review p:before {
    left: 0px;
  }
  .testi-col .testi-card {
    width: 100%;
    min-width: 100%;
    height: 100%;
    min-height: 100%;
  }
  .testi-client-head {
    padding: 18px 15px;
    min-height: 100px;
  }
  .testi-review-common {
    padding: 30px 15px 0px;
    min-height: 330px;
  }
  .testi-client-head p {
    font-size: 16px;
  }
  .testi-detailed-review a {
    font-size: 16px;
  }
  .testi-comp-logo {
    width: 60px;
    height: 60px;
    min-width: 60px;
  }
  .testi-comp-info p:last-child {
    font-style: normal;
    font-size: 16px;
    color: #1E4B5E;
  }
  .testi-comp-info p:first-child {
    font-size: 24px;
    line-height: 28px;
  }
  .testi-footer {
    padding: 20px 15px;
  }
  .testi-card {
    height: auto;
  }
  .tech-stack-row {
    width: 100%;
    margin: 0 0;
  }
  .mp-ft-card-info-hold {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
  .mp-ft-icon-hold {
    width: 30%;
  }
  .mp-ft-info {
    margin: 0px;
    width: 60%;
  }
  .mp-ft-icon-hold svg {
    width: 60px;
    height: 60px;
  }
  .market-place-features .mp-ft-card {
    width: 100%;
    height: 100%;
    padding: 30px;
  }
  .mp-ft-info p {
    font-size: 16px;
    text-align: left;
  }
  .explore-hold p {
    font-size: 20px;
  }
  .testi-comp-info p span {
    display: block;
  }
}
@media only screen and (max-width: 375px) {
  .testi-footer {
    padding: 30px 10px;
  }
}
@media only screen and (max-width: 320px) {
  .tech-stack-card {
    width: 100%;
  }
  .testi-comp-logo {
    width: 60px;
    height: 60px;
    min-width: 60px;
  }
  .testi-client-head p {
    font-size: 14px;
  }
  .testi-client-review p {
    font-size: 14px;
  }
  .testi-detailed-review a {
    font-size: 14px;
  }
  .testi-comp-info p:last-child {
    font-size: 14px;
  }
  .testi-comp-info p:first-child {
    font-size: 22px;
  }
}
