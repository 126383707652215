.Resources .section-1 {
  padding: 0px;
  background: #1E4B5E;
  position: fixed;
  width: 100%;
  z-index: 9;
  top: 0px;
  height: auto;
  flex-direction: column;
}
.Resources .section-1 .container-fluid {
  padding: 0px;
}
.Resources .section-1 .custom-pill-links-section {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 15px;
  justify-content: space-between;
}
.Resources .section-1 .custom-pill-links-section .right-menu {
  display: inline-flex;
  align-items: center;
}
.Resources .section-1 .custom-pill-links-section .right-menu a.connect-btn {
  border: 2px solid #007CC1;
  border-radius: 3px;
  font-size: 15px;
  font-family: "Object Sans heavy";
  color: #007CC1;
  margin-right: 30px;
  padding: 7px 28px;
  background: #ffffff;
  -webkit-transition: all 0.7s ease;
  transition: all 0.7s ease;
}
.Resources .section-1 .custom-pill-links-section .right-menu .ham-menu-hold {
  display: inline-flex;
  align-items: center;
}
.Resources .section-1 .custom-nav {
  justify-content: space-between;
  flex: 0 0 100%;
  flex-wrap: nowrap;
}
.Resources .section-1 .custom-nav .nav-item {
  background-color: transparent;
}
.Resources .section-1 .custom-nav .nav-item .nav-link {
  font-family: 'Object Sans';
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  background-color: transparent;
  border-radius: 0px;
  border-bottom: 5px solid transparent;
  padding: 10px 0px;
  transition: all 0.2s ease;
}
.Resources .section-1 .custom-nav .nav-item .nav-link.active {
  color: #ffffff;
  background-color: transparent;
  border-bottom: 5px solid #ffffff;
}
.Resources .section-1 .custom-nav .nav-item .nav-link:hover {
  color: #ffffff;
  background-color: transparent;
  border-bottom: 5px solid #ffffff;
}
.Resources .section-1 .custom-nav .nav-item .nav-link:focus {
  outline: none;
}
.Resources .section-1 .custom-container {
  min-width: 85%;
  max-width: 85%;
  width: 85%;
}
.Resources .section-2 {
  padding: 230px 0px 50px;
}
.Resources .section-2.newsletter-wrap {
  padding: 140px 0px 50px;
}
.Resources .section-2.ppr-dnld-custom-sec .custom-container {
  max-width: 85%;
  min-width: 85%;
  width: 85%;
}
.Resources .section-2 .tab-content {
  width: 100%;
}
.Resources .section-2 .tab-content .press-common-hold {
  display: flex;
  align-self: flex-end;
  width: 100%;
  justify-content: space-between;
}
.Resources .section-2 .tab-content .press-common-hold .main-head-div {
  margin-bottom: 50px;
  width: 60%;
}
.Resources .section-2 .tab-content .press-common-hold .main-head-div .press-info {
  font-family: 'Object Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #1E4B5E;
}
.Resources .section-2 .tab-content .press-common-hold .main-head-div .press-info .press-contact {
  font-family: 'Object Sans Heavy';
  color: #007CC1 !important;
  padding-left: 3px;
  text-decoration: underline;
}
.Resources .section-2 .tab-content .press-common-hold .filter-button-hold {
  margin-bottom: 50px;
  display: flex;
  align-items: flex-end;
}
.Resources .section-2 .tab-content .press-common-hold .filter-button-hold .filter {
  margin-bottom: 1rem;
}
.Resources .section-2 .tab-content .social-media {
  position: absolute;
  right: 30px;
  margin: 0;
  padding: 0;
  background: #ffffff;
  border-radius: 30px;
  padding: 15px 10px;
  text-align: center;
  top: 470px;
  z-index: 1;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
}
.Resources .section-2 .tab-content .social-media li {
  list-style-type: none;
  margin-bottom: 15px;
  cursor: pointer;
}
.Resources .section-2 .tab-content .social-media li:last-child {
  margin-bottom: 0px;
}
.Resources .section-2 .tab-content .social-media.sticky {
  position: fixed;
  top: 200px;
  right: 40px;
}
.Resources .section-2 .tab-content .top-subscribe {
  position: absolute;
  right: 60px;
  top: 450px;
  z-index: 3;
  visibility: hidden;
}
.Resources .section-2 .tab-content .top-subscribe .subscribe-input {
  width: 100%;
}
.Resources .section-2 .tab-content .top-subscribe .subscribe-input span.stay {
  display: none;
  z-index: 2;
  font-size: 14px;
  color: #000A4F;
}
.Resources .section-2 .tab-content .top-subscribe .subscribe-input form {
  width: 100%;
}
.Resources .section-2 .tab-content .top-subscribe .subscribe-input input {
  border: 1px solid #007CC1;
  background: transparent;
  font-size: 14px;
  color: #000A4F;
  width: 100%;
  border-right: 0px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.Resources .section-2 .tab-content .top-subscribe .subscribe-input input:focus {
  outline: none;
}
.Resources .section-2 .tab-content .top-subscribe .subscribe-input button {
  background: #007CC1;
  color: #ffffff;
  font-size: 12px;
  border: 0px;
  border-radius: 30px;
  padding: 8px 12px;
}
.Resources .section-2 .tab-content .top-subscribe .subscribe-input .subscribe-hover {
  right: 0;
  background: #ECECEC;
  border-radius: 30px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
}
.Resources .section-2 .tab-content .top-subscribe .subscribe-input .subscribe-hover button {
  display: inline-flex;
  align-items: flex-end;
}
.Resources .section-2 .tab-content .top-subscribe .subscribe-input .subscribe-hover:hover span.stay {
  display: inline-flex;
  justify-content: flex-start;
  font-size: 13px;
  margin: 0px 10px;
  color: #000A4F;
}
.Resources .section-2 .tab-content .top-subscribe.sticky {
  position: fixed;
  top: 390px;
  right: 5px;
  visibility: visible;
}
.Resources .section-2 .tab-content .top-subscribe.sticky .subscribe-input {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background: #ffffff;
  padding: 2px;
  border-radius: 15px;
}
.Resources .section-2 .tab-content .top-subscribe.sticky .subscribe-input .subscribe-our-news .err-msg {
  top: -15px;
  left: 15px;
}
.Resources .section-2 .tab-card .press-row {
  margin-bottom: 40px;
}
.Resources .section-2 .tab-card .press-row .port-card {
  width: 330px;
  cursor: pointer;
}
.Resources .section-2 .tab-card .press-row .port-card .card-img {
  width: 100%;
  min-height: 165px;
  max-height: 165px;
  margin-bottom: 10px;
}
.Resources .section-2 .tab-card .press-row .port-card .card-img img {
  width: 100%;
}
.Resources .section-2 .tab-card .press-row .port-card .press-card-type span {
  line-height: 20px;
  padding: 0px 4px;
  color: #1E4B5E;
  margin-bottom: 0px;
  border-radius: 3px;
  min-width: auto;
  font-size: 12px;
  display: inline-block;
  text-transform: uppercase;
  vertical-align: middle;
  font-family: 'Poppins', sans-serif;
}
.Resources .section-2 .tab-card .press-row .port-card .press-card-type span.press-blue-bg {
  background: #A9EAFF;
}
.Resources .section-2 .tab-card .press-row .port-card .press-card-type span.press-orange-bg {
  background: #F5BA85;
}
.Resources .section-2 .tab-card .press-row .port-card .press-card-type span.press-green-bg {
  background: #65DC98;
}
.Resources .section-2 .tab-card .press-row .port-card .press-card-type span.press-purple-bg {
  background: #DEA7F2;
}
.Resources .section-2 .tab-card .press-row .port-card .detailed-card-head {
  margin: 13px 0px;
  min-height: 88px;
}
.Resources .section-2 .tab-card .press-row .port-card .detailed-card-head h4 {
  font-family: Object Sans Heavy;
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  color: #1E4B5E;
  margin-bottom: 0px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
.Resources .section-2 .tab-card .press-row .port-card .press-content-author p {
  font-size: 14px;
  line-height: 17px;
  color: #7E97A1;
  font-family: Object Sans;
  font-style: normal;
  margin-bottom: 5px;
}
.Resources .section-2 .tab-card .press-row .port-card .press-content-author p .author-name {
  font-family: Object Sans Heavy;
}
.Resources .section-2 .tab-card .press-row .port-card .press-content-author p .created-date {
  padding-left: 3px;
}
.Resources .section-2 .tab-card .press-row .port-card .watch-link-hold a {
  font-family: Object Sans Heavy;
  font-size: 14px;
  line-height: 17px;
  text-decoration: underline;
  color: #007CC1;
}
.Resources .section-2 .tab-card .press-row .port-card .watch-link-hold span {
  font-family: Object Sans Heavy;
  font-size: 14px;
  line-height: 17px;
  text-decoration: underline;
  color: #007CC1;
}
.Resources .section-2 .in-news-common {
  margin-bottom: 50px;
}
.Resources .section-2 .in-news-common .main-head-div {
  margin-bottom: 30px;
}
.Resources .section-2 .in-news-common .main-head-div .main-head-1 {
  padding: 0px 0px;
  margin-bottom: 20px;
}
.Resources .section-2 .in-news-common .main-head-div .main-head-1 .primary-span {
  font-family: Heldane Display;
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 52px;
  color: #1E4B5E;
  position: relative;
  display: initial;
}
.Resources .section-2 .in-news-common .main-head-div .main-head-1 .primary-span {
  background-position: 0 34px;
  background-size: 40px 10px;
  background-repeat: repeat-x;
}
.Resources .section-2 .in-news-common .news-cards-common ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  margin: 0px;
  width: 100%;
  flex-wrap: wrap;
}
.Resources .section-2 .in-news-common .news-cards-common ul li {
  list-style: none;
  width: 24%;
}
.Resources .section-2 .in-news-common .news-cards-common ul li .in-news-card {
  min-height: 235px;
  background: #EBF0F2;
  border-radius: 5px;
  padding: 10px;
  border: none;
  margin-bottom: 50px;
  cursor: pointer;
}
.Resources .section-2 .in-news-common .news-cards-common ul li .in-news-card .news-logo-hold {
  height: 40px;
  position: absolute;
  top: -22px;
  left: 20px;
  right: auto;
}
.Resources .section-2 .in-news-common .news-cards-common ul li .in-news-card .news-logo-hold.crypt-logo {
  top: -25px;
}
.Resources .section-2 .in-news-common .news-cards-common ul li .in-news-card .news-logo-hold.crypt-logo img {
  width: 70%;
}
.Resources .section-2 .in-news-common .news-cards-common ul li .in-news-card .news-logo-hold.india-current {
  top: -27px;
}
.Resources .section-2 .in-news-common .news-cards-common ul li .in-news-card .news-logo-hold.india-current img {
  width: 70%;
}
.Resources .section-2 .in-news-common .news-cards-common ul li .in-news-card .news-logo-hold img {
  width: 80%;
}
.Resources .section-2 .in-news-common .news-cards-common ul li .in-news-card .news-card-content {
  min-height: 165px;
  max-height: 165px;
  margin-top: 20px;
  margin-bottom: 10px;
  overflow: hidden;
}
.Resources .section-2 .in-news-common .news-cards-common ul li .in-news-card .news-card-content p {
  font-family: Object Sans Heavy;
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  color: #1E4B5E;
  margin-bottom: 0px;
}
.Resources .section-2 .in-news-common .news-cards-common ul li .in-news-card .press-content-author p {
  font-size: 14px;
  line-height: 17px;
  color: #7E97A1;
  font-family: Object Sans Heavy;
  font-style: normal;
  margin-bottom: 5px;
}
.Resources .section-2 .blog-content .press-info {
  max-width: 630px;
  font-size: 18px;
}
.Resources .section-2 .blog-content .upcoming-detail-common {
  margin-bottom: 50px;
}
.Resources .section-2 .blog-content .upcoming-detail-common .upcoming-detail-card {
  background: #EBF0F2;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: all 0.2s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
.Resources .section-2 .blog-content .upcoming-detail-common .upcoming-detail-card .event-detail-card-img {
  min-width: 380px;
  max-width: 380px;
}
.Resources .section-2 .blog-content .upcoming-detail-common .upcoming-detail-card .event-detail-card-img img {
  width: 100%;
  max-height: 100%!important;
  object-fit: cover;
}
.Resources .section-2 .blog-content .upcoming-detail-common .upcoming-detail-card .event-detailed-right-info {
  padding: 0px 35px;
}
.Resources .section-2 .blog-content .upcoming-detail-common .upcoming-detail-card .event-detailed-right-info .detailed-head {
  font-family: Heldane Display;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 37px;
  color: #1E4B5E;
}
.Resources .section-2 .blog-content .upcoming-detail-common .upcoming-detail-card .event-detailed-right-info .event-desc {
  font-family: Object Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #1E4B5E;
}
.Resources .section-2 .blog-content .upcoming-detail-common .upcoming-detail-card .event-detailed-right-info .event-info-head {
  font-family: Object Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #7E97A1;
}
.Resources .section-2 .blog-content .upcoming-detail-common .upcoming-detail-card .event-detailed-right-info .event-info-detail {
  font-family: Object Sans Heavy;
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  color: #7E97A1;
  padding-left: 5px;
}
.Resources .section-2 .blog-content .upcoming-detail-common .upcoming-detail-card .event-detailed-right-info .register-btn {
  min-width: 161px;
  height: 60px;
  background: #FFCC4A;
  border-radius: 3px;
  border: none;
  transition: all 0.2s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
.Resources .section-2 .blog-content .upcoming-detail-common .upcoming-detail-card .event-detailed-right-info .register-btn .event-link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.Resources .section-2 .blog-content .upcoming-detail-common .upcoming-detail-card .event-detailed-right-info .register-btn .event-link span {
  font-family: Object Sans Heavy;
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #1E4B5E;
}
.Resources .section-2 .blog-content .tab-card .event-card-head {
  min-height: 50px!important;
}
.Resources .section-2 .blog-content .tab-card .press-content-author p {
  margin-bottom: 5px;
}
.Resources .section-2 .blog-content .tab-card .press-content-author p span:last-child {
  font-family: Object Sans Heavy;
  padding-left: 5px;
}
.Resources .section-2 .newsletter-content .newsletter-common .main-head-div {
  margin-bottom: 15px;
}
.Resources .section-2 .newsletter-content .newsletter-common .primary-span {
  position: relative;
  display: inline-block;
}
.Resources .section-2 .newsletter-content .newsletter-common .primary-span::after {
  content: '';
  position: absolute;
  bottom: 15px;
  height: 10px;
  background: #FFCC4A;
  width: 100%;
  left: 0;
  z-index: -1;
}
.Resources .section-2 .newsletter-content .newsletter-common .cate-head {
  color: #24A989;
  font-family: 'avenir_lt_std95_black';
  font-size: 36px;
  font-weight: 800;
  margin-top: 15px;
  border-bottom: 1px solid #24A989;
  padding-bottom: 10px;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  top: 280px;
  padding-bottom: 280px;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .filter-wrap {
  margin-bottom: 50px;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .filter-wrap .category-filter {
  background: #D3EFFF;
  padding: 10px;
  border-radius: 30px;
  width: 65%;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .filter-wrap .category-filter ul {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  display: flex;
  position: relative;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .filter-wrap .category-filter ul li {
  display: inline-block;
  background: #FFFFFF;
  border: 1px solid #007CC1;
  border-radius: 36px;
  color: #007CC1;
  font-size: 14px;
  padding: 4px 15px;
  margin-right: 15px;
  cursor: pointer;
  display: inline-flex;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .filter-wrap .category-filter ul li .MuiFormControl-root.dropdown-wrap {
  position: absolute;
  top: 50px;
  width: 200px;
  right: 0px;
  z-index: 2;
  background: #FFFFFF;
  box-shadow: 11px 13px 5px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .filter-wrap .category-filter ul li .MuiFormControl-root.dropdown-wrap .MuiFormGroup-root {
  width: 100%;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .filter-wrap .category-filter ul li .MuiFormControl-root.dropdown-wrap .MuiFormControlLabel-root:hover {
  background: #F3FBFF;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .filter-wrap .category-filter ul li .MuiFormControl-root.dropdown-wrap .MuiRadio-colorSecondary.Mui-checked {
  color: #007CC1;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .filter-wrap .category-filter ul li .MuiFormControl-root.dropdown-wrap label {
  margin: 0px;
  padding: 5px 5px;
  border-bottom: 1px solid #ECECEC;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .filter-wrap .category-filter ul li .MuiFormControl-root.dropdown-wrap label .MuiTypography-body1 {
  color: #475467;
  font-weight: 400;
  font-size: 14px;
  padding-left: 5px;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .filter-wrap .category-filter ul li .MuiFormControl-root.dropdown-wrap label:nth-of-type(5) {
  border-bottom: 0px;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .filter-wrap .category-filter ul li:last-child {
  position: absolute;
  right: 0px;
  margin-right: 0px;
  padding: 4px 10px;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .filter-wrap .category-filter ul li:last-child i {
  margin-right: 8px;
  height: 22px;
  display: inline-block;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .filter-wrap .category-filter ul li:last-child em {
  margin-left: 10px;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .filter-wrap .category-filter ul li:last-child .datepicker {
  position: absolute;
  top: 200px;
  z-index: 1;
  display: flex;
  background: #fff;
  padding: 10px;
  left: 75%;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .filter-wrap .category-filter ul li:last-child .datepicker .MuiTextField-root {
  width: 120px;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .filter-wrap .subscribe-float {
  position: relative;
  display: inline-flex;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .filter-wrap .subscribe-float .err-msg {
  position: absolute;
  bottom: -25px;
  left: 15px;
  font-size: 12px;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .filter-wrap .subscribe-float input {
  border: 0px;
  background: transparent;
  font-size: 14px;
  color: #000A4F;
  width: 100%;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .filter-wrap .subscribe-float input:focus {
  outline: none;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .tab-card.porti-card.news-card {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .tab-card.porti-card.news-card .list-card {
  width: 23.5%;
  display: inline-flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  position: relative;
  cursor: pointer;
  margin-right: 2%;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .tab-card.porti-card.news-card .list-card:nth-of-type(4n) {
  margin-right: 0px;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .tab-card.porti-card.news-card .list-card .list-card-inner {
  background: #123245;
  border-radius: 16px;
  padding: 15px;
  position: relative;
  overflow: hidden;
  min-height: 216px;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .tab-card.porti-card.news-card .list-card .list-card-inner .number-bg {
  position: absolute;
  z-index: 0;
  font-weight: 700;
  font-size: 180px;
  color: rgba(255, 255, 255, 0.1);
  top: 0;
  right: 0;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .tab-card.porti-card.news-card .list-card .list-card-inner b {
  color: #ffffff;
  font-family: 'avenir_lt_std55_roman';
  font-size: 18px;
  width: 100%;
  font-weight: 500;
  display: flex;
  margin: 20px 0px 10px;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .tab-card.porti-card.news-card .list-card .list-card-inner em {
  color: #ffffff;
  border: 1px solid #FFFFFF;
  border-radius: 30px;
  font-style: normal;
  font-size: 13px;
  padding: 3px 12px;
  min-height: 26px;
  display: inline-flex;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .tab-card.porti-card.news-card .list-card .list-card-inner em.blue {
  background: #3E8BFF;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .tab-card.porti-card.news-card .list-card .list-card-inner em.pink {
  background: #F05DEA;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .tab-card.porti-card.news-card .list-card .list-card-inner em.green {
  background: #33C0A7;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .tab-card.porti-card.news-card .list-card .list-card-inner em.violet {
  background: #843DF5;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .tab-card.porti-card.news-card .list-card .list-card-inner em.orange {
  background: #F45757;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .tab-card.porti-card.news-card .list-card .list-card-inner span {
  color: #FFFFFF;
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
  position: absolute;
  left: 15px;
  bottom: 15px;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .tab-card.porti-card.news-card .list-card strong {
  color: #7E97A1;
  font-size: 15px;
  margin-top: 10px;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .tab-card.porti-card.news-card .list-card strong span {
  font-weight: 400;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .tab-card.porti-card.news-card .list-card i.download {
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: pointer;
  display: none;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .tab-card.porti-card.news-card .no-data {
  margin-top: 180px;
  color: #fff;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .filter-wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .filter-wrap .subscribe-hover {
  right: 0;
  background: #ECECEC;
  border-radius: 30px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 320px;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .filter-wrap .subscribe-hover input {
  background: transparent;
  border: 0px;
  font-size: 11px;
  width: 80%;
  padding: 0px 5px;
  text-transform: capitalize;
  padding-right: 0;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .filter-wrap .subscribe-hover input:hover,
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .filter-wrap .subscribe-hover input:active,
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .filter-wrap .subscribe-hover input:focus {
  outline: none;
  border: 0px;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .filter-wrap .subscribe-hover span.stay {
  display: inline-flex;
  justify-content: flex-start;
  font-size: 13px;
  margin: 0px 10px;
  color: #000A4F;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .filter-wrap .subscribe-hover button {
  background: #007CC1;
  color: #ffffff;
  font-size: 12px;
  border: 0px;
  border-radius: 30px;
  padding: 8px 12px;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .filter-wrap .subscribe-hover.sticky {
  display: none;
  position: fixed;
  top: 120px;
  right: 50px;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .filter-wrap .subscribe-hover.sticky input {
  display: none;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .filter-wrap .subscribe-input {
  width: 100%;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .filter-wrap .subscribe-input span.stay {
  display: none;
  z-index: 2;
  font-size: 14px;
  color: #000A4F;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .filter-wrap .subscribe-input form {
  width: 100%;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .filter-wrap .subscribe-input input {
  border: 0px;
  background: transparent;
  font-size: 14px;
  color: #000A4F;
  width: 100%;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .filter-wrap .subscribe-input input:focus {
  outline: none;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .filter-wrap div.subscribe-our-news {
  width: 100%;
  left: 0;
  z-index: 3;
  background: transparent;
  padding-top: 10px;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .filter-wrap div.subscribe-our-news .form-group.newsletter-form-group {
  width: 100%;
  margin: 10px 0;
  position: unset;
  background: #FFFFFF;
  border-top-right-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .filter-wrap div.subscribe-our-news .form-group.newsletter-form-group input.form-control {
  font-size: 12px;
  padding: 10px;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .filter-wrap div.subscribe-our-news div.newsletter-arrow button {
  font-size: 12px;
  padding: 11px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.Resources .section-2 .newsletter-content .newsletter-common .category-wrap .filter-wrap div.subscribe-our-news div.newsletter-arrow button img {
  position: unset;
  width: 14px;
  height: 14px;
  transform: rotate(180deg);
}
.Resources .section-2 .newsletter-content .newsletter-common .featured-articles {
  width: 90%;
  position: absolute;
  top: 150px;
  left: 0;
  right: 0;
  margin: auto;
  margin-top: 0px;
}
.Resources .section-2 .newsletter-content .newsletter-common .featured-articles strong,
.Resources .section-2 .newsletter-content .newsletter-common .featured-articles h1 {
  color: #FFFFFF;
  font-size: 36px;
  text-align: center;
  width: 100%;
  display: block;
  margin-bottom: 30px;
  font-weight: 600;
}
.Resources .section-2 .newsletter-content .newsletter-common .featured-articles .featured-article-wrap {
  background: #D3EFFF;
  border-radius: 16px;
  padding: 20px 15px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.Resources .section-2 .newsletter-content .newsletter-common .featured-articles .featured-article-wrap .featured-left {
  width: 40%;
  display: inline-flex;
}
.Resources .section-2 .newsletter-content .newsletter-common .featured-articles .featured-article-wrap .featured-left img {
  width: 100%;
}
.Resources .section-2 .newsletter-content .newsletter-common .featured-articles .featured-article-wrap .featured-right {
  width: 60%;
  display: inline-flex;
  flex-wrap: wrap;
  padding-left: 30px;
}
.Resources .section-2 .newsletter-content .newsletter-common .featured-articles .featured-article-wrap .featured-right .category-list {
  margin: 0px;
  padding: 0px;
}
.Resources .section-2 .newsletter-content .newsletter-common .featured-articles .featured-article-wrap .featured-right .category-list li {
  list-style-type: none;
  border-radius: 20px;
  color: #ffffff;
  font-size: 12px;
  display: inline-block;
  padding: 4px 12px;
  margin-right: 10px;
}
.Resources .section-2 .newsletter-content .newsletter-common .featured-articles .featured-article-wrap .featured-right .category-list li:nth-of-type(1) {
  background: #33C0A7;
}
.Resources .section-2 .newsletter-content .newsletter-common .featured-articles .featured-article-wrap .featured-right .category-list li:nth-of-type(2) {
  background: #F45757;
}
.Resources .section-2 .newsletter-content .newsletter-common .featured-articles .featured-article-wrap .featured-right b {
  color: #fff;
  font-family: 'avenir_lt_std95_black';
  font-size: 32px;
  line-height: 36px;
  font-weight: 500;
  margin-top: 10px;
  display: block;
  margin-bottom: 10px;
}
.Resources .section-2 .newsletter-content .newsletter-common .featured-articles .featured-article-wrap .featured-right em {
  font-style: normal;
  color: #707070;
  font-size: 14px;
}
.Resources .section-2 .newsletter-content .newsletter-common .featured-articles .featured-article-wrap .featured-right p {
  color: #475467;
  font-size: 16px;
  margin-top: 25px;
  margin-bottom: 0px;
}
.Resources .section-2 .newsletter-content .newsletter-common .featured-articles .featured-arrow {
  display: flex;
  justify-content: center;
  margin: 25px 0px 15px;
  align-items: center;
}
.Resources .section-2 .newsletter-content .newsletter-common .featured-articles .featured-arrow span {
  display: inline-flex;
}
.Resources .section-2 .newsletter-content .newsletter-common .featured-articles .featured-arrow span:last-child {
  transform: rotate(180deg);
  margin-left: 25px;
}
.Resources .section-2 .newsletter-content .newsletter-common .tech-nexus {
  background: #1E4B5E;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0px 230px;
  margin: 30px 0px;
  position: relative;
  width: 100vw;
  left: -9%;
  right: 0%;
}
.Resources .section-2 .newsletter-content .newsletter-common .tech-nexus h4 {
  color: #ffffff;
  font-size: 58px;
  font-family: 'avenir_lt_std95_black';
}
.Resources .section-2 .newsletter-content .newsletter-common a.connect-btn {
  font-size: 24px;
  color: #1E4B5E;
  font-family: "Object Sans heavy";
  line-height: 24px;
}
.Resources .section-2 .newsletter-content .newsletter-common .list {
  margin: 0px;
}
.Resources .section-2 .newsletter-content .newsletter-common .list .list-capt h4 {
  font-family: Heldane Display;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 37px;
  color: #1E4B5E;
}
.Resources .section-2 .newsletter-content .newsletter-common .list .list-capt h4 span {
  position: relative;
}
.Resources .section-2 .newsletter-content .newsletter-common .list .list-capt .newsletter-publish-info {
  font-family: Object Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #7E97A1;
}
.Resources .section-2 .newsletter-content .newsletter-common .list .list-capt .newsletter-publish-info span:last-child {
  font-family: Object Sans;
}
.Resources .section-2 .newsletter-content .newsletter-common .list .whitepaper-type {
  margin: 10px 0px;
}
.Resources .section-2 .newsletter-content .newsletter-common .list .whitepaper-type span {
  line-height: 20px;
  padding: 0px 4px;
  color: #1E4B5E;
  margin-bottom: 0px;
  border-radius: 3px;
  min-width: auto;
  font-size: 12px;
  display: inline-block;
  text-transform: uppercase;
  vertical-align: middle;
  font-family: 'Poppins', sans-serif;
}
.Resources .section-2 .newsletter-content .newsletter-common .list .whitepaper-type span.paper-yellow-bg {
  background: #9ED9F3;
}
.Resources .section-2 .newsletter-content .newsletter-common .list .whitepaper-type span.paper-green-bg {
  background: #6FCF97;
}
.Resources .section-2 .newsletter-content .newsletter-common .view-more-action {
  display: flex;
  justify-content: center;
  margin: 25px 0px;
  width: 100%;
}
.Resources .section-2 .newsletter-content .newsletter-common .view-more-action span {
  border: 3px solid #007CC1;
  border-radius: 30px;
  color: #007CC1;
  padding: 15px 30px;
  cursor: pointer;
}
.Resources .section-2 .detailed-news-common {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}
.Resources .section-2 .detailed-news-common .detailed-news {
  width: 67%;
}
.Resources .section-2 .detailed-news-common .detailed-news a {
  font-family: Object Sans Heavy;
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  text-decoration-line: underline;
  color: #007CC1;
}
.Resources .section-2 .detailed-news-common .detailed-news .detailed-news-main-info {
  margin-top: 30px;
}
.Resources .section-2 .detailed-news-common .detailed-news .detailed-news-main-info h4 {
  font-family: Heldane Display;
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 52px;
  color: #24A989;
}
.Resources .section-2 .detailed-news-common .detailed-news .detailed-news-main-info p {
  font-family: Object Sans;
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  color: #7E97A1;
}
.Resources .section-2 .detailed-news-common .detailed-news .detailed-news-main-info p span:first-child {
  font-family: Object Sans Heavy;
}
.Resources .section-2 .detailed-news-common .detailed-news .detialed-news-sub-info {
  margin-top: 50px;
  margin-bottom: 30px;
}
.Resources .section-2 .detailed-news-common .detailed-news .detialed-news-sub-info .news-sub-head {
  font-family: Heldane Display;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 34px;
  color: #1E4B5E;
}
.Resources .section-2 .detailed-news-common .detailed-news .detialed-news-sub-info .full-new-content {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #1E4B5E;
  margin-top: 15px;
}
.Resources .section-2 .detailed-news-common .news-floating-sidebar {
  width: 23%;
}
.Resources .section-2 .detailed-news-common .news-floating-sidebar .stories-card {
  min-width: 265px;
  min-height: auto;
  max-height: 422px;
  background: #EBF0F2;
  border-radius: 10px;
  border: none;
}
.Resources .section-2 .detailed-news-common .news-floating-sidebar .stories-card .stories-card-body h4 {
  font-family: Heldane Display;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 37px;
  color: #1E4B5E;
  margin-bottom: 30px;
}
.Resources .section-2 .detailed-news-common .news-floating-sidebar .stories-card .stories-card-body .stories-ul {
  padding: 0px;
  margin: 0px;
}
.Resources .section-2 .detailed-news-common .news-floating-sidebar .stories-card .stories-card-body .stories-ul li {
  padding: 10px 0px;
  border: 0.5px solid #C7D0D3;
  border-left: none;
  border-right: none;
  list-style: none;
  cursor: pointer;
}
.Resources .section-2 .detailed-news-common .news-floating-sidebar .stories-card .stories-card-body .stories-ul li .story-type {
  padding: 3px 4px;
  margin-bottom: 8px;
  border-radius: 3px;
  font-size: 12px;
  color: #1E4B5E !important;
}
.Resources .section-2 .detailed-news-common .news-floating-sidebar .stories-card .stories-card-body .stories-ul li .story-type.story-blue-bg {
  background: #A9EAFF;
}
.Resources .section-2 .detailed-news-common .news-floating-sidebar .stories-card .stories-card-body .stories-ul li .story-type.story-orange-bg {
  background: #F5BA85;
}
.Resources .section-2 .detailed-news-common .news-floating-sidebar .stories-card .stories-card-body .stories-ul li .story-type.story-green-bg {
  background: #65DC98;
}
.Resources .section-2 .detailed-news-common .news-floating-sidebar .stories-card .stories-card-body .stories-ul li .story-desc {
  font-family: Object Sans Heavy;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  color: #1E4B5E;
  margin-bottom: 0px;
  margin-top: 8px;
}
.Resources .section-2 .detailed-news-common .email-signup-common {
  font-family: Heldane Display;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 37px;
  color: #FFFFFF;
  background: #1E4B5E;
  border-radius: 10px;
  padding: 15px;
  margin-top: 30px;
  min-width: 265px;
}
.Resources .section-2 .detailed-news-common .email-signup-common .signup-head {
  font-family: Object Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #C7D0D3;
  margin-top: 30px;
}
.Resources .section-2 .detailed-news-common .email-signup-common .newsletter-form-group {
  position: relative;
}
.Resources .section-2 .detailed-news-common .email-signup-common .newsletter-form-group input {
  font-family: Object Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  height: 40px;
  padding-right: 50px;
}
.Resources .section-2 .detailed-news-common .email-signup-common .newsletter-form-group input:focus {
  outline: none;
  box-shadow: none;
}
.Resources .section-2 .detailed-news-common .email-signup-common .newsletter-form-group .newsletter-arrow {
  background: #FFCC4A;
  border-radius: 0px 3px 3px 0px;
  cursor: pointer;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  width: 40px;
  height: 40px;
  bottom: 0;
  margin: auto;
  right: 0;
  left: auto;
}
.Resources .section-2 .detailed-news-common .email-signup-common .newsletter-form-group .newsletter-arrow span svg {
  margin-top: -3px;
}
.Resources .custom-inx-logo .web-logo {
  display: block;
}
.Resources .custom-inx-logo .mob-logo {
  display: none;
}
.news-floating-sidebar .err-msg {
  line-height: 16px!important;
  font-family: "object sans";
  padding: 0px!important;
}
.news-floating-sidebar .newsletter-form-group {
  margin-bottom: 10px;
}
.press-card {
  transition: all 0.2s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
.press-card:hover {
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
}
.Resources .press-col:nth-child(2) .port-card {
  margin: 0 auto;
}
.Resources .press-col:last-child .port-card {
  margin-left: auto;
}
#press .in-news-common .main-head-div {
  margin-bottom: 50px;
}
.Resources .Header.scrollHeader {
  background: linear-gradient(90deg, #FFFFFF 0%, #F1F1F1 100%), #F0F0F0;
  height: 90px;
  transition: none;
}
.author-name .cust-auth-name {
  margin-right: 5px;
}
.Resources .section-2 .tab-card .press-row .port-card .press-content-author .author-names p {
  font-family: object sans heavy;
}
.Resources .section-2 .tab-card .press-row .port-card .press-content-author .author-name span {
  padding: 0px;
}
.Resources .section-2 .tab-card .press-row .port-card .press-content-author .author-title span {
  padding: 0px;
  font-family: object sans!important;
}
.Resources .section-2 .tab-card .press-row .port-card .press-content-author .author-common-hold {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}
.Resources .section-2 .tab-card .press-row .port-card .press-content-author .author-common-hold:first-child .author-title {
  width: 43%;
}
.Resources .section-2 .tab-card .press-row .port-card .press-content-author .author-common-hold .author-names {
  width: 56%;
}
.btn.register-btn.btn-primary:hover {
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
}
.webinar-info-paras {
  margin: 20px 0px;
}
.Resources.Events .tab-card .press-row .press-col .port-card .detailed-card-head {
  min-height: 66px!important;
}
.Resources.Events .tab-card .press-row .press-col .port-card .press-content-author {
  min-height: 60px;
}
.Resources.Events .tab-card .press-row .press-col .port-card {
  min-height: 66px;
}
.Resources.Events .tab-card .press-row .press-col:nth-child(3n+1) .port-card {
  margin-right: auto;
  margin-left: 0px;
}
.Resources.Events .tab-card .press-row .press-col:nth-child(3n+2) .port-card {
  margin: 0 auto;
}
.Resources.Events .tab-card .press-row .press-col:nth-child(3n+3) .port-card {
  margin-left: auto;
}
.PaperBookDownload .download-common.inner-section-1 .main-head-div .main-head-1 .primary-span {
  font-size: 40px!important;
}
.PaperBookDownload .read-story-section .main-head-div {
  margin-bottom: 0px;
}
.PaperBookDownload .read-story-section .main-head-div h4 {
  margin-bottom: 0px;
}
.PaperBookDownload .download-common.inner-section-1 .main-head-div .press-info {
  color: #1E4B5E;
  font-size: 18px;
}
.PaperBookDownload .main-para-div .connect-btn {
  min-width: 180px;
  height: 60px;
  padding: 15px;
}
.PaperBookDownload .main-para-div .connect-btn span {
  font-family: 'Object Sans Heavy';
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #1E4B5E;
}
.BlockchainDetailed .download-common.inner-section-1 .main-head-div .press-info {
  color: #1E4B5E;
  font-family: Object Sans Heavy;
  font-style: normal;
  font-size: 20px;
  line-height: 32px;
}
.BlockchainDetailed .bulletin-info ul {
  margin: 0px;
}
.BlockchainDetailed .bulletin-head-details h4 .primary-span {
  color: #1E4B5E;
  font-family: Object Sans Heavy;
  font-style: normal;
  font-size: 20px;
  line-height: 32px;
}
.BlockchainDetailed .bulletin-head-details .press-info {
  color: #1E4B5E;
  font-family: Object Sans Heavy;
  font-style: normal;
  font-size: 14px;
  line-height: 32px;
}
.BlockchainDetailed .bulletin-sub-heads .bulletin-links-ul {
  margin: 0px;
}
.BlockchainDetailed .bulletin-sub-heads .bulletin-links-ul li a {
  font-family: Object Sans;
  font-size: 16px;
  line-height: 26px;
}
.BlockchainDetailed .bulletin-sub-heads .bulletin-links-ul li {
  margin-bottom: 10px;
}
.Resources .Header .custom-inx-navbar {
  height: 120px!important;
}
.Resources .Header.scrollHeader .custom-inx-navbar {
  height: 90px!important;
}
.newsissuedetailed .issue-main-head h4 {
  font-family: Heldane Display;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  margin-bottom: 20px;
  color: #24A989;
}
.newsissuedetailed .issue-sub-head h4 {
  font-family: Object Sans Heavy;
  font-style: normal;
  font-size: 20px;
  margin-bottom: 15px;
  color: #1E4B5E;
}
.newsissuedetailed .issue-main-info {
  margin-bottom: 35px;
}
.back-to-common {
  display: inline-flex;
  align-self: center;
  justify-content: flex-start;
  margin-bottom: 30px;
  cursor: pointer;
  border-bottom: 1px solid #007CC1;
}
.back-to-common a {
  text-decoration-line: none!important;
}
.back-to-icon-hold {
  width: 15px;
  height: auto;
  margin-right: 10px;
  display: inline-flex;
}
.back-to-icon-hold img {
  width: 100%;
}
.back-to-link {
  font-family: Object Sans Heavy;
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  color: #007CC1;
}
.Resources #press .tab-card .press-row .port-card .card-img {
  width: 100%;
  min-height: 165px!important;
  max-height: 165px!important;
}
.Resources #press .tab-card .press-row .port-card .card-img img {
  width: 100%;
  min-height: 165px!important;
  max-height: 100%!important;
  object-fit: cover;
  margin: auto;
}
.Resources .section-2 .tab-card .press-row .port-card .card-img img {
  width: 100%;
  min-height: 165px!important;
  max-height: 100%!important;
  object-fit: cover;
  margin: auto;
}
.newdesigned_issue_details {
  margin-top: 100px;
}
.newdesigned_issue_details .detailed-common .overall-heading {
  font-size: 38px;
  color: #1E4B5E;
  font-family: 'avenir_lt_std95_black';
  margin-bottom: 50px;
  display: block;
  text-align: center;
  text-transform: capitalize;
}
.newdesigned_issue_details .banner-section {
  display: flex;
  width: 65%;
  background: #FFFFFF;
  box-shadow: 0px 0px 33px 2px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin: 0px auto;
  position: relative;
  top: -50px;
}
.newdesigned_issue_details .banner-section .banner-left {
  width: 50%;
  display: inline-flex;
}
.newdesigned_issue_details .banner-section .banner-left img {
  width: 100%;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}
.newdesigned_issue_details .banner-section .banner-right {
  width: 50%;
  padding: 30px;
  position: relative;
}
.newdesigned_issue_details .banner-section .banner-right span {
  font-weight: 800;
  margin-bottom: 10px;
  display: block;
}
.newdesigned_issue_details .banner-section .banner-right strong {
  width: 100%;
  display: flex;
  margin-top: 20px;
}
.newdesigned_issue_details .banner-section .banner-right a.read-more {
  border: 3px solid #007CC1;
  border-radius: 30px;
  padding: 12px 35px;
  position: absolute;
  left: 30px;
  bottom: 30px;
  font-size: 16px;
  font-weight: 400;
  background: #007CC1;
  color: #ffffff;
}
.newdesigned_issue_details .headlines-warp {
  display: flex;
}
.newdesigned_issue_details .headlines-warp .top-news {
  width: 30%;
  margin-top: 80px;
}
.newdesigned_issue_details .headlines-warp .top-news h4 {
  color: #1E4B5E;
  font-size: 32px;
  font-weight: 800;
  font-family: 'avenir_lt_std95_black';
  margin-top: 15px;
}
.newdesigned_issue_details .headlines-warp .top-news span {
  color: #1E4B5E;
  font-size: 16px;
  line-height: 32px;
  padding-right: 40px;
  display: block;
}
.newdesigned_issue_details .headlines-warp .top-news a {
  background: #007CC1;
  color: #ffffff;
  padding: 8px 15px;
  border-radius: 15px;
  margin: 10px 0px;
  display: inline-block;
}
.newdesigned_issue_details .headlines-warp .headlines-content {
  width: 70%;
}
.newdesigned_issue_details .headlines-warp .headlines-content strong.overall-heading {
  font-size: 28px;
  color: #1E4B5E;
  font-family: 'avenir_lt_std95_black';
  margin-bottom: 30px;
  display: block;
  text-align: left;
  padding-left: 40px;
}
.newdesigned_issue_details .headlines-warp .headlines-content .headlines-list {
  border-bottom: 1px solid #1E4B5E;
  padding-left: 40px;
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
}
.newdesigned_issue_details .headlines-warp .headlines-content .headlines-list:nth-of-type(1) {
  padding-top: 0;
}
.newdesigned_issue_details .headlines-warp .headlines-content .headlines-list::before {
  content: '';
  width: 22px;
  height: 22px;
  background: #1e4b5e;
  border: 6px solid #e0ecff;
  border-radius: 100%;
  position: absolute;
  left: 0;
  margin-top: 4px;
}
.newdesigned_issue_details .headlines-warp .headlines-content .headlines-list a {
  color: #1E4B5E;
  font-size: 20px;
  display: block;
  margin-bottom: 30px;
  text-decoration: none;
  font-weight: 800;
}
.newdesigned_issue_details .headlines-warp .headlines-content .headlines-list p {
  color: #1E4B5E;
  margin-bottom: 0px;
  line-height: 32px;
}
.newdesigned_issue_details .new-detail-option {
  margin: 50px 0px;
}
.newdesigned_issue_details .new-detail-option .news-first-section:nth-of-type(5) .newsissuedetailed {
  background: url('/assets/newsletter_images/ebook-notepad-pink-grid.png') no-repeat;
  background-size: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  min-height: 800px;
  padding-top: 50px;
}
.newdesigned_issue_details .new-detail-option .news-first-section:nth-of-type(5) .newsissuedetailed .newissuedetailed_banner {
  background: #fff;
  border: 40px solid #eaecf0;
  border-radius: 50px;
  max-width: 85%;
  padding: 50px 30px;
  position: relative;
  margin-top: 140px;
}
.newdesigned_issue_details .new-detail-option .news-first-section:nth-of-type(5) .newsissuedetailed .newissuedetailed_banner span.banner-wall img {
  position: absolute;
  top: -160px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
}
.newdesigned_issue_details .new-detail-option .news-first-section:nth-of-type(5) .newsissuedetailed .newissuedetailed_banner p {
  width: 100%;
  max-width: 100%;
}
.newdesigned_issue_details .new-detail-option .news-first-section .heading {
  background: #123245;
  width: 100vw;
  left: 0%;
  right: 0;
  margin: auto;
  display: flex;
  min-height: 480px;
  position: relative;
}
.newdesigned_issue_details .new-detail-option .news-first-section .heading .detail-heading {
  width: 77%;
  margin: 0px auto;
  position: relative;
  display: flex;
  align-items: center;
}
.newdesigned_issue_details .new-detail-option .news-first-section .heading h5 {
  color: #ffffff;
  font-weight: 800;
  font-size: 60px;
  display: flex;
  padding-left: 40%;
  text-transform: capitalize;
  flex-direction: column;
  line-height: 70px;
}
.newdesigned_issue_details .new-detail-option .news-first-section .heading h5 span {
  color: rgba(255, 255, 255, 0.15);
  font-family: 'avenir_lt_std95_black';
  font-size: 620px;
  line-height: 620px;
  position: absolute;
  bottom: -100px;
  left: 0;
}
.newdesigned_issue_details .new-detail-option .news-first-section .heading h5 em {
  font-style: normal;
  display: flex;
  width: 100%;
  font-size: 48px;
  font-weight: 400;
}
.newdesigned_issue_details .new-detail-option .news-first-section .newsissuedetailed {
  padding: 50px 0px 3px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.newdesigned_issue_details .new-detail-option .news-first-section .newsissuedetailed p {
  color: #475467;
  font-size: 16px;
  padding-bottom: 30px;
  line-height: 30px;
  width: 100%;
  display: flex;
  flex-flow: wrap;
}
.newdesigned_issue_details .new-detail-option .news-first-section .newsissuedetailed p em {
  font-style: normal;
  font-weight: 600;
  color: #1D2939;
  font-size: 20px;
  font-family: 'avenir_lt_std95_black';
  margin-bottom: 10px;
}
.newdesigned_issue_details .new-detail-option .news-first-section .newsissuedetailed .news-left {
  width: 40%;
  flex-wrap: wrap;
  background: #E0ECFF;
  border-radius: 24px;
  padding: 20px;
  margin-bottom: 50px;
}
.newdesigned_issue_details .new-detail-option .news-first-section .newsissuedetailed .news-left i img {
  width: 170px;
}
.newdesigned_issue_details .new-detail-option .news-first-section .newsissuedetailed .news-left strong {
  width: 100%;
  display: flex;
  font-size: 26px;
  font-family: 'avenir_lt_std95_black';
  margin: 15px 0px;
}
.newdesigned_issue_details .new-detail-option .news-first-section .newsissuedetailed .news-left b {
  width: 100%;
  display: flex;
  color: #032C6B;
  margin-top: 15px;
  line-height: 36px;
}
.newdesigned_issue_details .new-detail-option .news-first-section .newsissuedetailed .news-right {
  width: 60%;
  padding-left: 50px;
}
.newdesigned_issue_details .new-detail-option .news-first-section .newsissuedetailed .news-right b.sub-heading {
  margin-bottom: 30px;
  display: block;
  font-size: 28px;
  font-family: 'avenir_lt_std95_black';
}
.newdesigned_issue_details .new-detail-option .news-first-section .newsissuedetailed .news-right ul {
  list-style-type: none;
  padding: 0;
}
.newdesigned_issue_details .new-detail-option .news-first-section .newsissuedetailed .news-right ul li {
  position: relative;
  padding-left: 40px;
}
.newdesigned_issue_details .new-detail-option .news-first-section .newsissuedetailed .news-right ul li span {
  color: #667085;
  position: absolute;
  left: 0;
  width: 22px;
  height: 22px;
  background: #1e4b5e;
  border: 6px solid #e0ecff;
  border-radius: 100%;
  top: 4px;
}
.newdesigned_issue_details .new-detail-option .news-first-section .newsissuedetailed .news-right ul li b {
  color: #1D2939;
  font-size: 21px;
  font-family: 'avenir_lt_std95_black';
}
.newdesigned_issue_details .new-detail-option .news-first-section .newsissuedetailed .news-right ul li p {
  color: #475467;
  font-size: 16px;
  margin-top: 15px;
}
.newdesigned_issue_details .final-section {
  background: #F3FBFF;
  padding: 20px;
  border-radius: 15px;
}
.newdesigned_issue_details .final-section strong {
  color: #007CC1;
  line-height: 32px;
  font-family: 'avenir_lt_std95_black';
}
.newdesigned_issue_details .final-section strong span {
  display: block;
  font-size: 32px;
}
.newdesigned_issue_details .final-section .checkout-interesting {
  display: block;
  margin: 30px 0px 10px;
}
.newdesigned_issue_details .final-section .checkout-interesting b {
  font-size: 18px;
  line-height: 14px;
}
.newdesigned_issue_details .final-section .checkout-interesting a {
  color: #007CC1;
  border-radius: 30px;
  text-decoration: underline;
}
.newdesigned_issue_details .events-wrap {
  margin: 30px auto;
  width: 77%;
}
.newdesigned_issue_details .events-wrap strong {
  color: #363A45;
  font-size: 24px;
  display: block;
  border-bottom: 1px solid #123245;
  padding-bottom: 30px;
}
.newdesigned_issue_details .events-wrap .events-list {
  display: flex;
  justify-content: space-between;
  padding-top: 40px;
}
.newdesigned_issue_details .events-wrap .events-list .events {
  width: 47%;
}
.newdesigned_issue_details .events-wrap .events-list .events img {
  width: 100%;
}
.newdesigned_issue_details .events-wrap .events-list .events p {
  color: #1B1D22;
  font-size: 14px;
  padding: 15px 0px;
}
@media only screen and (max-width: 1430px) and (min-width: 1280px) {
}
@media only screen and (max-width: 1260px) {
  .Resources .section-2 .newsletter-content .newsletter-common .tab-card.porti-card.news-card .list-card .list-card-inner b {
    font-size: 16px;
  }
}
@media only screen and (max-width: 1050px) {
  .Resources .section-2 .newsletter-content .newsletter-common .tab-card.porti-card.news-card .list-card .list-card-inner b {
    font-size: 14px;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .tab-card.porti-card.news-card .list-card .list-card-inner {
    min-height: 185px;
  }
}
@media only screen and (max-width: 1280px) and (min-width: 980px) {
  .Resources .section-2 .tab-card .press-row .port-card .press-content-author .author-common-hold {
    flex-direction: column;
  }
  .Resources .section-2 .tab-card .press-row .port-card .press-content-author .author-common-hold:first-child .author-title,
  .Resources .section-2 .tab-card .press-row .port-card .press-content-author .author-common-hold .author-names {
    width: 100%;
  }
  .Resources .section-2 .tab-card .port-card {
    width: 300px!important;
  }
  .Resources .section-1 .custom-pill-links-section.sticky .custom-nav .nav-item .nav-link {
    padding: 36px 0px 29px;
    margin: 0px 8px;
  }
}
@media only screen and (max-width: 1140px) {
  .Resources .section-1 .custom-pill-links-section .custom-nav .nav-item .nav-link {
    margin: 0px 10px !important;
    font-size: 15px !important;
  }
  .resource-logo {
    padding-right: 10px !important;
  }
  .Resources .section-2 .in-news-common .news-cards-common ul li .in-news-card .news-logo-hold.crypt-logo {
    top: -20px;
  }
  .Resources .section-2 .in-news-common .news-cards-common ul li .in-news-card .news-logo-hold.india-current {
    top: -20px;
  }
}
@media only screen and (max-width: 1200px) and (min-width: 1068px) {
  .Resources .press-row {
    justify-content: space-between;
  }
  .Resources .press-col {
    max-width: 50%;
  }
  .Resources .section-2 .tab-card .port-card {
    width: 330px!important;
  }
}
@media only screen and (max-width: 1067px) {
  .Resources .press-row {
    justify-content: space-between;
  }
  .Resources .press-col {
    max-width: 50%;
  }
  .Resources .section-2 .tab-card .port-card {
    width: 330px!important;
  }
}
@media only screen and (max-width: 979px) and (min-width: 576px) {
  .Resources .section-2 .tab-content .in-news-common .news-cards-common ul li {
    list-style: none;
    width: 45%;
  }
}
@media only screen and (max-width: 880px) and (min-width: 768px) {
  .Resources .section-2 .tab-card .port-card {
    width: 300px!important;
  }
}
@media only screen and (max-width: 920px) and (min-width: 576px) {
  .Resources .section-2 .in-news-common .news-cards-common ul li {
    width: 100%;
  }
  .Resources .section-2 .in-news-common .news-cards-common ul li .in-news-card {
    width: 100%;
  }
}
@media only screen and (max-width: 1024px) {
  .Resources .section-2 .newsletter-content .newsletter-common .primary-span::after {
    height: 5px;
    bottom: 10px;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .top-subscribe .subscribe-input {
    width: 45%;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .featured-articles .featured-article-wrap .featured-right b {
    font-size: 21px;
    line-height: 28px;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .featured-articles .social-media {
    right: -8%;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .featured-articles {
    width: 92%;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .featured-articles .featured-article-wrap .featured-right {
    padding-left: 15px;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .featured-articles .featured-article-wrap {
    align-items: center;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .featured-articles .featured-article-wrap .featured-right p {
    font-size: 13px;
    margin-top: 15px;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .tab-card.porti-card.news-card .list-card {
    width: 31.33%;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .category-filter {
    width: 100%;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .cate-head {
    font-size: 24px;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .category-filter ul li {
    font-size: 12px;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .tab-card.porti-card.news-card .list-card:nth-of-type(4n) {
    margin-right: auto;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .category-wrap .category-filter {
    width: 95%;
    margin-right: 12px;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .category-wrap {
    top: 200px;
    padding-bottom: 200px;
  }
  .newdesigned_issue_details .new-detail-option .news-first-section .heading h5 {
    font-size: 48px;
    padding-left: 45%;
  }
  .newdesigned_issue_details .new-detail-option .news-first-section:nth-of-type(5) .newsissuedetailed {
    min-height: 700px;
    padding-top: 0px;
  }
  .newdesigned_issue_details .new-detail-option .news-first-section:nth-of-type(5) .newsissuedetailed p {
    font-size: 13px;
    line-height: 25px;
    margin-bottom: 0px;
    padding: 10px 20px;
  }
}
@media only screen and (max-width: 991px) {
  .Resources .section-2 .newsletter-content .newsletter-common .category-wrap .tab-card.porti-card.news-card .list-card {
    width: 48%;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .category-wrap .category-filter {
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0px;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .category-wrap .filter-wrap .subscribe-hover {
    margin-bottom: 0px;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .category-wrap .tab-card.porti-card.news-card {
    margin-top: 20px;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .category-wrap .filter-wrap {
    flex-flow: row wrap;
    justify-content: flex-end;
  }
  .Resources .section-1 {
    height: auto;
  }
  .Resources .section-1 .custom-nav .nav-item .nav-link {
    font-size: 14px;
  }
  .Resources .section-1 .custom-pill-links-section .right-menu button.connect-btn {
    font-size: 16px;
  }
  .Resources .section-1 .custom-pill-links-section {
    width: 100%;
    align-items: center;
    height: 115px;
  }
  .Resources .section-1 .custom-pill-links-section .custom-nav .nav-item .nav-link {
    margin: 0px 20px !important;
    white-space: nowrap;
    padding: 0px 0px 16px;
  }
  .Resources .resource-sec-1 {
    padding: 0px 20px ;
  }
  .Resources .section-1 .custom-nav {
    justify-content: space-between;
    overflow-x: scroll;
    width: 100%;
    flex-wrap: nowrap;
    margin-top: 0px;
    white-space: nowrap;
  }
  .Resources .section-1 .custom-container {
    width: 100%;
    min-width: 100%;
  }
  .Resources .section-2 {
    padding: 180px 0px 60px 0px!important;
    height: 100%;
  }
  .Resources .mob-fluid {
    padding: 0px;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .category-wrap .filter-wrap .category-filter {
    width: 100%;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .category-wrap .filter-wrap .category-filter ul li:last-child {
    position: unset;
  }
  .newdesigned_issue_details .banner-section {
    position: unset;
    margin: 35px auto;
    flex-flow: row wrap;
  }
  .newdesigned_issue_details .banner-section .banner-left,
  .newdesigned_issue_details .banner-section .banner-right,
  .newdesigned_issue_details .headlines-warp .top-news,
  .newdesigned_issue_details .headlines-warp .headlines-content {
    width: 100%;
  }
  .newdesigned_issue_details .banner-section .banner-right a.read-more {
    position: unset;
    margin: 25px 0 10px 0;
    display: inline-block;
  }
  .newdesigned_issue_details .headlines-warp {
    flex-flow: row wrap;
  }
  .newdesigned_issue_details .headlines-warp .top-news i {
    display: block;
    text-align: center;
  }
  .newdesigned_issue_details .headlines-warp .top-news span {
    padding: 0;
    text-align: justify;
    margin-bottom: 20px;
  }
  .newdesigned_issue_details .new-detail-option .news-first-section .heading h5 {
    flex-flow: row wrap;
    padding: 0;
    line-height: 85px;
  }
  .newdesigned_issue_details .events-wrap .events-list {
    flex-flow: row wrap;
  }
  .newdesigned_issue_details .events-wrap {
    width: 100%;
  }
  .newdesigned_issue_details .new-detail-option {
    margin-bottom: 0;
  }
  .newdesigned_issue_details .new-detail-option .news-first-section .newsissuedetailed .news-left,
  .newdesigned_issue_details .new-detail-option .news-first-section .newsissuedetailed .news-right {
    width: 100%;
  }
  .newdesigned_issue_details .new-detail-option .news-first-section .newsissuedetailed .news-right {
    padding-left: 0px;
  }
  .newdesigned_issue_details .new-detail-option .news-first-section:nth-of-type(5) .newsissuedetailed {
    background: transparent;
  }
}
@media only screen and (max-width: 768px) {
  svg.icon-resize {
    width: 120px;
  }
  .newdesigned_issue_details .detailed-common .overall-heading {
    font-size: 24px;
  }
  .Resources .section-1 .custom-pill-links-section .right-menu button.connect-btn {
    font-size: 14px;
    padding: 8px 20px;
    margin-right: 15px;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .featured-articles {
    width: 100%;
  }
  .Resources .resource-sec-1 .resource-text svg {
    width: 120px;
  }
  .Resources .section-1 .custom-pill-links-section .right-menu .ham-menu-hold {
    width: 28px;
  }
  .Resources .Header .custom-inx-navbar {
    height: 90px!important;
  }
  .Resources .section-2 .in-news-common .news-cards-common ul li:nth-child(2) .in-news-card {
    margin-right: 0px;
    margin-left: auto;
  }
  .Resources .section-2 .in-news-common .news-cards-common ul li:nth-child(3) .in-news-card {
    margin-right: auto;
    margin-left: 0px;
  }
  .Resources .section-2 .in-news-common .news-cards-common ul li:nth-child(4) .in-news-card {
    margin-right: 0px;
    margin-left: auto;
  }
  .Resources .section-2 .blog-content .upcoming-detail-card {
    display: block!important;
  }
  .upcoming-detail-card:hover {
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
  }
  .Resources .section-2 .blog-content .upcoming-detail-card .event-detail-card-img {
    min-width: 100%!important;
    max-width: 100%!important;
    height: 100%;
  }
  .Resources .section-2 .blog-content .upcoming-detail-card .event-detailed-right-info {
    padding: 15px 0px!important;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .list {
    display: block;
  }
  .Resources .list .list-capt {
    width: 100%;
  }
  .Resources .section-2 .detailed-news-common {
    display: block;
  }
  .Resources .section-2 .detailed-news-common .detailed-news {
    width: 100%;
  }
  .Resources .section-2 .detailed-news-common .news-floating-sidebar {
    width: 100%;
  }
  .Resources .section-2 .tab-card .port-card {
    width: 280px!important;
  }
  .Resources .resource-sec-1 {
    padding: 0px;
  }
  .Resources .section-1 .custom-pill-links-section .right-menu a.connect-btn {
    font-size: 14px;
    padding: 8px 12px;
    margin-right: 15px;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .tab-card.porti-card.news-card .list-card {
    width: 47%;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .category-wrap .category-filter ul li:last-child {
    position: unset;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .category-wrap {
    top: 550px;
    padding-bottom: 550px;
  }
  .newdesigned_issue_details .new-detail-option .news-first-section:nth-of-type(4) .newsissuedetailed p {
    max-width: 100%;
    line-height: 26px;
  }
  .newdesigned_issue_details .banner-section .banner-right {
    padding: 12px;
    font-size: 13px;
    line-height: 25px;
  }
  .newdesigned_issue_details .banner-section .banner-right strong {
    margin-top: 10px;
  }
  .newdesigned_issue_details .banner-section .banner-right a.read-more {
    padding: 8px;
    text-align: center;
  }
  .newdesigned_issue_details .headlines-warp .top-news h4 {
    margin-top: 30px;
    font-size: 26px;
  }
  .newdesigned_issue_details .headlines-warp .top-news span {
    font-size: 14px;
    line-height: 24px;
  }
  .newdesigned_issue_details .headlines-warp .headlines-content .headlines-list:nth-of-type(1),
  .newdesigned_issue_details .headlines-warp .headlines-content .headlines-list,
  .newdesigned_issue_details .headlines-warp .headlines-content strong.overall-heading {
    padding-left: 30px;
  }
  .newdesigned_issue_details .headlines-warp .headlines-content strong.overall-heading {
    font-size: 24px;
  }
  .newdesigned_issue_details .headlines-warp .headlines-content .headlines-list b {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .newdesigned_issue_details .new-detail-option .news-first-section .heading h5 span {
    font-size: 370px;
    line-height: 470px;
    left: auto;
    right: 0;
  }
}
@media only screen and (max-width: 768px) {
  .Resources .Header.scrollHeader .custom-inx-logo svg.web-logo {
    opacity: 1;
    display: block;
    transition: all 0.7s ease-in;
  }
  .Resources .Header .custom-inx-logo svg {
    width: 100px;
    height: 60px;
  }
  .Resources .Header .resouce-logo-text {
    font-size: 8px!important;
    letter-spacing: 6px!important;
  }
  .Resources .section-1 .custom-pill-links-section.sticky .custom-nav {
    justify-content: space-between !important;
    height: auto;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .primary-span {
    font-size: 26px;
    margin-bottom: 0;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .featured-articles {
    top: 150px;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .cate-head {
    font-size: 18px;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .category-filter ul {
    flex-flow: row wrap;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .category-filter ul li {
    margin: 0 10px 10px 0px;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .category-filter ul li:last-child {
    position: unset;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .category-filter ul li:last-child i svg {
    width: 20px;
    height: 20px;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .featured-articles strong,
  .Resources .section-2 .newsletter-content .newsletter-common .featured-articles h1 {
    font-size: 26px;
  }
  .subscribe-hover {
    display: none;
  }
  .Resources .section-2 .tab-content .social-media {
    right: 0;
    box-shadow: 0 0 10px #ddd;
  }
  .Resources .section-2 .tab-content .social-media.sticky {
    right: 0;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .tech-nexus h4 {
    font-size: 34px;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .tab-card.porti-card.news-card .list-card strong {
    font-size: 13px;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .view-more-action span {
    padding: 7px 10px;
    font-size: 13px;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .featured-articles .featured-article-wrap .featured-left,
  .Resources .section-2 .newsletter-content .newsletter-common .featured-articles .featured-article-wrap .featured-right {
    width: 100%;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .featured-articles .featured-article-wrap .featured-right {
    padding: 20px 0 0 0;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .featured-articles .featured-article-wrap .featured-right b {
    font-size: 18px;
    line-height: 22px;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .featured-articles .featured-article-wrap .featured-left a {
    width: 100%;
  }
  .newdesigned_issue_details .new-detail-option .news-first-section:nth-of-type(5) .newsissuedetailed .newissuedetailed_banner span.banner-wall img {
    width: 280px;
    top: -110px;
  }
}
@media only screen and (max-width: 576px) {
  .Resources .section-2 .newsletter-content .newsletter-common .tab-card.porti-card.news-card .list-card {
    width: 100%;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .category-wrap .filter-wrap .subscribe-float {
    width: 100%;
  }
  .Resources .section-2 .in-news-common .news-cards-common ul li {
    width: 100%;
  }
  .Resources .section-2 .in-news-common .news-cards-common ul li .in-news-card {
    width: 100%;
  }
  .Resources .main-head-1 .primary-span {
    margin-bottom: 30px;
  }
  .Resources .section-2 .in-news-common .news-cards-common ul li .in-news-card .news-logo-hold.crypt-logo {
    top: -30px;
  }
  .Resources .section-2 .in-news-common .news-cards-common ul li .in-news-card .news-logo-hold.india-current {
    top: -36px;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .category-wrap {
    top: 410px;
    padding-bottom: 350px;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .category-wrap .tab-card.porti-card.news-card .list-card .list-card-inner b {
    font-size: 15px;
    line-height: 20px;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .category-wrap .tab-card.porti-card.news-card .list-card strong {
    font-size: 14px;
  }
  .newdesigned_issue_details .new-detail-option .news-first-section .heading h5,
  .newdesigned_issue_details .new-detail-option .news-first-section .heading h5 em {
    font-size: 37px;
    line-height: 63px;
  }
  .newdesigned_issue_details .new-detail-option .news-first-section .heading {
    min-height: 320px;
  }
  .newdesigned_issue_details .headlines-warp .headlines-content .headlines-list p {
    line-height: 25px;
  }
  .newdesigned_issue_details .banner-section .banner-right a.read-more {
    width: 210px;
    margin: 20px auto;
    display: block;
  }
  .newdesigned_issue_details .events-wrap .events-list .events {
    width: 100%;
  }
  .newdesigned_issue_details .new-detail-option .news-first-section:nth-of-type(4) .newsissuedetailed {
    padding-top: 50px;
    min-height: 600px;
  }
  .newdesigned_issue_details .new-detail-option .news-first-section .heading .detail-heading {
    width: 86%;
  }
  span.banner-wall {
    display: none;
  }
  .newdesigned_issue_details .new-detail-option .news-first-section:nth-of-type(5) .newsissuedetailed .newissuedetailed_banner {
    max-width: 100%;
    border: 0;
    margin: 0px 0;
    padding: 0;
    background: none;
  }
  .newdesigned_issue_details.issue_83_design .new-detail-option .news-first-section .heading h5,
  .newdesigned_issue_details.issue_83_design .news-first-section:nth-child(5) .heading h5 {
    font-size: 30px!important;
    line-height: 44px!important;
  }
  .newdesigned_issue_details .new-detail-option .news-first-section .heading {
    min-height: 400px;
  }
}
@media only screen and (max-width: 425px) {
  .Resources .section-2 .newsletter-content .newsletter-common a.connect-btn {
    line-height: 60px !important;
  }
  .newdesigned_issue_details .new-detail-option .news-first-section .heading h5,
  .newdesigned_issue_details .new-detail-option .news-first-section .heading h5 em {
    font-size: 30px;
    line-height: 43px;
  }
  .newdesigned_issue_details .new-detail-option .news-first-section:nth-of-type(5) .newsissuedetailed p {
    max-width: 100%;
  }
  .newdesigned_issue_details .new-detail-option .news-first-section .newsissuedetailed p,
  .newdesigned_issue_details .final-section {
    font-size: 14px;
    line-height: 26px;
  }
  .newdesigned_issue_details .events-wrap .events-list .events p {
    font-size: 13px;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .category-wrap .tab-card.porti-card.news-card .list-card {
    width: 100%;
    margin: 0 auto 20px auto;
  }
  .BlockchainDetailed .download-common.inner-section-1 .main-head-div .main-head-1 .primary-span {
    font-size: 36px!important;
    line-height: 45px;
  }
  .newsissuedetailed .issue-main-head h4 {
    font-size: 36px!important;
    line-height: 45px;
  }
  .Resources .press-col {
    max-width: 100%!important;
  }
  .Resources .section-2 .tab-content .press-common-hold .main-head-div {
    width: 100%;
  }
  .Resources .section-2 .tab-card .port-card {
    width: 100%!important;
  }
  .Resources .section-2 .in-news-common .news-cards-common ul li {
    width: 100%;
  }
  .Resources .section-2 .in-news-common .news-cards-common ul li .in-news-card.card {
    margin: 20px auto;
    padding: 30px 10px 15px 10px;
  }
  .Resources .section-2 .in-news-common .news-cards-common ul li .in-news-card .news-logo-hold.crypt-logo img {
    width: 65%;
  }
  .Resources .section-2 .in-news-common .news-cards-common ul li .in-news-card .news-logo-hold.crypt-logo img {
    width: 65%;
  }
  .Resources .section-2 .in-news-common .news-cards-common ul li .in-news-card .news-logo-hold.india-current img {
    width: 65%;
  }
  .Resources .section-2 .in-news-common .news-cards-common ul li .in-news-card .news-card-content {
    min-height: 95px;
    max-height: 95px;
  }
  .Resources .section-2 .in-news-common .news-cards-common ul li .in-news-card {
    min-height: 200px;
  }
  .Resources .section-2 .blog-content .upcoming-detail-card .event-detail-card-img {
    min-width: 100%!important;
    height: 100%!important;
  }
  .Resources .section-2 .tab-content .press-common-hold .main-head-div .main-head-1 .primary-span {
    font-size: 36px;
  }
  .Resources .section-2 .tab-card .port-card .press-card-type span {
    font-size: 12px;
  }
  .Resources .section-2 .tab-card .port-card .detailed-card-head h4 {
    font-size: 14px;
  }
  .Resources .section-2 .in-news-common .main-head-1 .primary-span {
    line-height: 30px !important;
    font-size: 24px !important;
  }
  .Resources .section-2 .in-news-common .main-head-div .main-head-1 .primary-span {
    background-position: 0px 17px !important;
    background-size: 40px 10px !important;
    background-repeat: repeat-x;
  }
  .Resources .section-2 .in-news-common .news-cards-common ul li .in-news-card {
    width: 100%;
  }
  .Resources .section-2 .in-news-common .news-cards-common ul li .in-news-card .news-card-content p {
    font-size: 16px;
  }
  .Resources .section-1 .custom-pill-links-section .custom-nav .nav-item .nav-link {
    white-space: nowrap;
    font-size: 14px;
    margin-right: 30px;
  }
  .Resources .section-2 .blog-content .upcoming-detail-card .event-detailed-right-info .register-btn {
    width: 100%;
    min-width: 100%;
  }
  .upcoming-detail-common {
    margin-bottom: 50px;
  }
  .Resources .section-2 .blog-content .upcoming-detail-card .event-detailed-right-info .detailed-head {
    font-size: 24px;
  }
  .Resources .section-2 .blog-content .upcoming-detail-card .event-detailed-right-info .event-desc {
    font-size: 16px!important;
  }
  .Resources .section-2 .blog-content .upcoming-detail-card .event-detailed-right-info .event-info-head {
    font-size: 16px!important;
  }
  .Resources .section-2 .blog-content .upcoming-detail-card .event-detailed-right-info .event-info-detail {
    font-size: 16px!important;
  }
  .Resources .section-2 .tab-card .press-row .port-card .detailed-card-head h4 {
    font-size: 16px;
  }
  .Resources .section-2 .tab-card .press-row .port-card .press-content-author p {
    margin-bottom: 10px;
  }
  .Resources .section-2 .tab-card .press-row .port-card .detailed-card-head {
    margin-bottom: 10px;
  }
  .Resources .section-2 .blog-content .press-info {
    font-size: 16px;
    line-height: 20px;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .list .list-capt h4 span {
    position: relative;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .list .list-capt h4 {
    font-size: 24px;
    line-height: 30px;
  }
  .list .list-capt p {
    font-size: 16px;
    line-height: 20px;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .list .list-capt .newsletter-publish-info {
    font-size: 16px;
  }
  .list .download-btn button {
    font-size: 16px;
  }
  .list .download-btn a {
    font-size: 16px;
  }
  .Resources .section-2 .newsletter-content .newsletter-common .list .whitepaper-type {
    margin-bottom: 18px!important;
  }
  .Resources .section-2 .in-news-common .main-head-div .main-head-1 .primary-span::after {
    bottom: 5px!important;
  }
  .Resources .section-2 .blog-content .upcoming-detail-common .upcoming-detail-card .event-detailed-right-info .detailed-head {
    font-size: 24px;
    line-height: 30px;
  }
  .Resources .section-2 .detailed-news-common .detailed-news .detailed-news-main-info h4 {
    font-size: 32px;
    line-height: 38px;
  }
  .Resources .section-2 .detailed-news-common .detailed-news .detailed-news-main-info p {
    font-size: 16px;
  }
  .Resources .section-2 .detailed-news-common .detailed-news .detialed-news-sub-info .news-sub-head {
    font-size: 24px;
    line-height: 30px;
  }
  .Resources .section-2 .detailed-news-common .detailed-news .detialed-news-sub-info .full-new-content {
    font-size: 16px;
    line-height: 20px;
  }
  .BlockchainDetailed .bulletin-sub-heads .bulletin-links-ul li a {
    font-size: 14px;
  }
  .BlockchainDetailed .bulletin-sub-heads .bulletin-links-ul {
    padding-left: 18px;
  }
  .BlockchainDetailed .bulletin-info ul {
    padding-left: 18px;
  }
  .Resources .ppr-dnld-custom-sec.section-2 {
    padding-bottom: 70px!important;
  }
  .custom-inx-navbar {
    padding: 15px 15px!important;
  }
}
.list .download-btn a {
  font-family: Object Sans Heavy;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  text-decoration-line: underline;
  color: #007CC1;
}
/* contact us modal css starts */
.ebook-dwnld-form {
  background: #fff;
  padding: 30px;
  border-radius: 3px;
  box-shadow: 0px 0px 10px #f1f1f1;
}
.ebook-dwnld-form .contact-form-label {
  font-size: 16px;
  color: #1E4B5E;
  font-family: 'Object Sans Heavy';
}
.ebook-dwnld-form .form-control:focus {
  outline: none;
  border-color: #1E4B5E;
  box-shadow: none;
}
.ebook-dwnld-form .form-group {
  margin-bottom: 12px;
}
.ebook-dwnld-form .form-group .form-control::placeholder {
  font-size: 12px!important;
  color: #1e4b5e85;
}
.ebook-dwnld-form .form-group input {
  font-size: 12px!important;
  min-height: 38px;
  padding-top: 13px;
  padding-bottom: 13px;
}
.ebook-dwnld-form .form-check .form-check-label {
  font-size: 14px;
  color: #1E4B5E;
  font-family: 'Object Sans';
}
.ebook-dwnld-form .text-muted.form-text {
  font-size: 12px;
}
.ebook-dwnld-form .contact-form-group {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 0px;
  margin-top: 20px;
}
.ebook-dwnld-form .contact-submit {
  background: #FFCC4A !important;
  color: #1E4B5E !important;
  border: transparent;
  font-family: 'object sans heavy';
  padding: 8px 25px;
}
.ebook-dwnld-form .contact-submit:focus {
  box-shadow: none!important;
  outline: none!important;
}
/* check box */
.ebook-dwnld-form.inp-cbx:checked + .cbx span:first-child {
  background: #1E4B5E;
  border-color: #1E4B5E;
  animation: wave 0.4s ease;
}
.ebook-dwnld-form .cbx span:first-child {
  width: 16px;
  height: 16px;
  top: 0px;
}
.ebook-dwnld-form .cbx span:first-child {
  position: relative;
  min-width: 14px;
  height: 14px;
  max-width: 14px;
  border-radius: 2px;
  transform: scale(1);
  vertical-align: middle;
  border: 1px solid #9098A9;
  transition: all 0.2s ease;
}
.ebook-dwnld-form.cbx span {
  display: inline-block;
}
.ebook-dwnld-form .inp-cbx {
  display: none;
}
.ebook-dwnld-form .inp-cbx:checked + .cbx span:first-child:before {
  transform: scale(1.5);
  opacity: 0;
  transition: all 0.6s ease;
  border-radius: 50%;
}
.ebook-dwnld-form .form-element.ripple-effect span:before {
  content: "";
  width: 32px;
  height: 32px;
  left: -8px;
  top: -8px;
  margin: auto;
  position: absolute;
  background: #1E4B5E;
  display: block;
  transform: scale(0);
  opacity: 1;
  border-radius: 50%;
}
.ebook-dwnld-form .cbx span:first-child:before {
  content: "";
  width: 32px;
  height: 32px;
  left: -8px;
  top: -8px;
  margin: auto;
  position: absolute;
  background: #1E4B5E;
  display: block;
  transform: scale(0);
  opacity: 1;
  border-radius: 50%;
}
.ebook-dwnld-form .inp-cbx:checked + .cbx span:first-child svg {
  stroke-dashoffset: 0;
}
.ebook-dwnld-form .cbx span:first-child svg {
  left: 1px;
}
.ebook-dwnld-form .cbx span:first-child svg {
  position: absolute;
  top: 2px;
  left: 2px;
  fill: none;
  stroke: #1E4B5E;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
  transform: rotate(5deg);
  width: 9px;
  height: 9px;
}
.ebook-dwnld-form.form-element.ripple-effect span:before {
  content: "";
  width: 32px;
  height: 32px;
  left: -8px;
  top: -8px;
  margin: auto;
  position: absolute;
  background: #1E4B5E;
  display: block;
  transform: scale(0);
  opacity: 1;
  border-radius: 50%;
}
.ebook-dwnld-form .even-btn .check-content label {
  align-items: inherit;
}
.ebook-dwnld-form .check-content label {
  padding-right: 15px;
  display: flex;
  align-items: center;
}
.ebook-dwnld-form .check-custom-text {
  font-size: 14px;
  color: #1E4B5E;
  font-family: 'Object Sans';
}
.ebook-dwnld-form .contact-form-txt-area {
  max-height: 60px;
  min-height: 60px;
  font-size: 12px;
}
/* contact us modal css ends */
/* modal css ends */
.white-paper-modal .modal-title.h4 {
  font-family: Heldane Display;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 32px;
  color: #24A989;
}
.white-paper-modal .modal-dialog {
  transform: scale(0.95) translateY(20px) !important;
}
.white-paper-modal .modal-content {
  padding: 65px;
  border: none;
  border-radius: 8px;
}
.white-paper-modal .modal-header {
  border-bottom: none;
  padding-bottom: 10px!important;
  position: relative;
}
.white-paper-modal .modal-header,
.white-paper-modal .modal-body {
  padding: 0px;
}
.white-paper-modal .modal-body {
  padding-top: 10px;
}
.white-paper-modal .modal-body .ebook-dwnld-form {
  box-shadow: none;
  padding: 0px;
}
.white-paper-modal .modal-side-close {
  position: absolute;
  right: 0px;
  left: auto;
  width: 24px;
  height: 24px;
  z-index: 0;
  top: -11px;
  display: flex;
  bottom: 0;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.white-paper-modal .close {
  color: transparent;
  opacity: 0!important;
  padding-right: 0px!important;
  margin: 0px!important;
  padding-top: 0px;
  padding-bottom: 0px;
}
.ebook-dwnld-form .contact-form-label {
  margin-bottom: 10px;
}
.ebook-dwnld-form .form-group {
  margin-bottom: 15px;
}
.Blockchain-guide .section-1 {
  background-image: none;
}
.Blockchain-guide .comp-info-right-img {
  width: auto;
}
.Blockchain-guide .section-1 .row {
  align-items: center;
}
.Blockchain-guide .chain-dev ul li {
  font-weight: 500;
  font-size: 18px;
  color: #1E4B5E;
}
.Blockchain-guide .sec-2-row {
  align-items: center;
  width: 100%;
}
.Blockchain-guide .qns-p {
  font-family: 'Object Sans Heavy';
  font-size: 20px;
  color: #1E4B5E;
  line-height: 28px !important;
}
.Blockchain-guide .qns-p-1 {
  color: #1E4B5E;
  font-family: 'Object Sans Heavy';
  font-size: 20px;
  line-height: 28px !important;
}
.Blockchain-guide .service-img img {
  width: 60%;
}
.Blockchain-guide .main-head-div {
  margin-bottom: 30px;
}
.Blockchain-guide .right-img-hold {
  margin-left: 0px;
}
.Data-guide .section-1 {
  background-image: none;
}
.Data-guide .comp-info-right-img {
  width: auto;
}
.Data-guide .section-1 .row {
  align-items: center;
}
.Data-guide .chain-dev ul li {
  font-weight: 500;
  font-size: 15px;
  color: #1E4B5E;
}
.Data-guide .sec-2-row {
  align-items: center;
  width: 100%;
}
.Data-guide .app-p {
  font-family: 'Object Sans Heavy';
  font-size: 20px;
  color: #1E4B5E;
  text-align: left;
  line-height: 24px !important;
}
.Data-guide .app-p-1 {
  color: #24A989;
  font-family: 'Object Sans Heavy';
  font-size: 26px;
  line-height: 28px !important;
}
.Data-guide .service-img img {
  width: 60%;
}
.Data-guide .main-head-div {
  margin-bottom: 30px;
}
.Data-guide .right-img-hold {
  margin-left: 0px;
}
.resource-sec-1 {
  display: flex;
  align-items: center;
}
.resource-text,
.resource-by {
  padding-right: 12px;
  margin-top: -4px;
}
.resource-logo {
  padding-right: 40px;
  margin-top: -8px;
  cursor: pointer;
}
.resource-by svg path {
  fill: #444544;
}
.Blockchain-guide .p-center {
  font-family: "Object Sans Heavy";
  font-size: 24px;
  text-align: center;
  color: #1e4b5e;
  line-height: 35px!important;
}
.app-p-head {
  font-family: 'Object Sans Heavy';
  font-size: 26px;
  text-align: center;
  color: #24A989;
}
@media only screen and (max-width: 490px) {
  .Blockchain-guide .chain-dev ul li {
    font-weight: 500;
    font-size: 14px;
    color: #1E4B5E;
  }
  .Blockchain-guide .chain-dev ul {
    padding-left: 20px;
  }
  .Blockchain-guide .chain-dev ul li {
    font-size: 14px;
  }
  .Blockchain-guide .qns-p-1 {
    font-size: 20px;
  }
  .Blockchain-guide .service-head h4 {
    font-style: normal;
    line-height: 18px;
  }
  .Blockchain-guide .work-with-sub-head .app-p {
    font-size: 20px;
  }
  .Blockchain-guide .comp-info-right-img {
    width: 100%!important;
  }
  .Data-guide span.company-offer-imgs,
  .Data-guide .comp-info-right-img,
  .Blockchain-guide span.company-offer-imgs,
  .Blockchain-guide .comp-info-right-img {
    margin-bottom: 15px;
    display: inline-block;
  }
  .Data-guide .chain-dev ul li {
    font-weight: 500;
    font-size: 16px;
    color: #1E4B5E;
  }
  .Data-guide .app-p {
    font-size: 17px;
  }
  .Data-guide .left-info-hold .app-p-1 {
    line-height: 24px !important;
  }
  .Blockchain-guide .p-center {
    line-height: 26px!important;
  }
  .Data-guide .app-p-1 {
    line-height: 22px !important;
    font-size: 20px;
  }
}
.blog-custom-link {
  display: inline-block;
}
.modal.show .modal-dialog .modal-content .webinar-modal-body .react-player {
  min-height: 430px;
  height: 430px !important;
}
@media only screen and (max-width: 480px) {
  .webinar-info-paras p {
    display: flex;
    flex-direction: column;
  }
  .webinar-info-paras p span.event-info-detail {
    padding-left: 0px !important;
  }
  .Resources .section-2 .tab-card .press-row .port-card .press-content-author .author-common-hold {
    flex-direction: column;
  }
  .modal.show .modal-dialog .modal-content .webinar-modal-body .react-player {
    min-height: auto;
    height: 350px !important;
  }
}
/*new design styles*/
.resources-wrapper.Events .Resources .section-2 {
  padding: 0px 0px 50px;
  width: 85%;
  margin: 0px auto;
  position: relative;
  z-index: 2;
}
.resources-wrapper.Events .Resources .section-2 .newsletter-content .newsletter-common {
  position: relative;
}
.resources-wrapper.Events .Resources .section-2 .newsletter-content .newsletter-common .tech-nexus {
  display: none;
}
.resources-wrapper.Events .Resources .section-2 .newsletter-content .newsletter-common .featured-articles {
  position: relative;
  width: 100%;
  top: 0px;
}
.resources-wrapper.Events .Resources .section-2 .newsletter-content .newsletter-common .featured-articles .featured-article-wrap {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  background: rgba(41, 45, 70, 0.5);
  border: 2px solid #24A989;
  border-radius: 10px;
  z-index: 2;
  position: relative;
}
.resources-wrapper.Events .Resources .section-2 .newsletter-content .newsletter-common .featured-articles .featured-article-wrap .featured-right a {
  position: relative;
  z-index: 2;
}
.resources-wrapper.Events .Resources .section-2 .newsletter-content .newsletter-common .featured-articles .featured-article-wrap .featured-right a b {
  color: #fff;
}
.resources-wrapper.Events .Resources .section-2 .newsletter-content .newsletter-common .featured-articles .featured-article-wrap .featured-right p {
  color: #ffffff;
}
.resources-wrapper.Events .Resources .section-2 .newsletter-content .newsletter-common .category-wrap {
  position: relative;
  padding-bottom: 0px;
  margin: 100px 0px 0px;
  top: 0px;
  z-index: 2;
}
.resources-wrapper.Events .Resources .section-2 .newsletter-content .newsletter-common .category-wrap .filter-wrap .category-filter {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  background: rgba(41, 45, 70, 0.5);
  border: 2px solid #24A989;
}
.resources-wrapper.Events .Resources .section-2 .newsletter-content .newsletter-common .category-wrap .filter-wrap .subscribe-hover.sticky {
  display: flex;
  position: static;
}
.resources-wrapper.Events .Resources .section-2 .newsletter-content .newsletter-common .category-wrap .filter-wrap .subscribe-hover.sticky input {
  display: block;
}
.resources-wrapper {
  background: linear-gradient(101.06deg, #0E2E40 0%, #145554 52.87%, #208F71 99.96%);
  position: relative;
  padding-top: 100px;
}
.resources-wrapper::before {
  content: '';
  position: fixed;
  width: 50%;
  height: 100%;
  background: url("/assets/img/resource/left-bg.png") no-repeat;
  left: -5%;
  top: 0px;
  background-size: 100%;
}
.resources-wrapper::after {
  content: '';
  position: fixed;
  width: 100%;
  height: 100%;
  background: url("/assets/img/resource/right-bg.png") no-repeat;
  right: 0%;
  top: 12%;
  z-index: 0;
  background-size: 100%;
}
.resources-wrapper .resource-top {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 50px 0px;
}
.resources-wrapper .resource-top h1 {
  color: #FFCC4A;
  font-family: "Heldane display";
  margin: 50px 0px 80px !important;
  width: 100%;
  display: block;
  text-align: center;
}
.resources-wrapper .resource-top .resource-menu {
  width: 100%;
  display: flex;
  border-bottom: 2px solid #ffffff;
}
.resources-wrapper .resource-top .resource-menu ul {
  padding: 0px;
  margin: 0px;
  list-style-type: none;
  display: flex;
  justify-content: center;
  max-width: 90%;
  margin: 0px auto;
  width: 90%;
}
.resources-wrapper .resource-top .resource-menu ul li {
  display: inline-flex;
  width: 23%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin: 0 1%;
  position: relative;
  border: 2px solid #8BA1AB;
  border-bottom: 2px solid transparent;
  z-index: 1;
  justify-content: center;
  height: 175px;
  align-items: center;
  background: #001822;
}
.resources-wrapper .resource-top .resource-menu ul li a {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.resources-wrapper .resource-top .resource-menu ul li:hover,
.resources-wrapper .resource-top .resource-menu ul li.active {
  border: 2px solid #24A989;
  border-bottom: 2px solid transparent;
}
.resources-wrapper .resource-top .resource-menu ul li:hover span,
.resources-wrapper .resource-top .resource-menu ul li.active span {
  background: #24A989;
}
.resources-wrapper .resource-top .resource-menu ul li img {
  width: auto;
  margin-bottom: 25px;
}
.resources-wrapper .resource-top .resource-menu ul li span {
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  text-align: center;
  padding: 10px;
  z-index: 1;
  font-family: "Object Sans Heavy";
}
@media only screen and (max-width: 768px) {
  div.whitepaper-inner-content.events-main-wrapper,
  div.events-inner-content.events-main-wrapper {
    width: 100%;
  }
  div.resource-tab-wrapper .MuiFormControl-root .category-list ul {
    background: #fff;
    z-index: 9999;
  }
  div.resource-tab-wrapper .MuiFormControl-root .category-list ul li {
    color: #123245;
  }
  div.search-box {
    width: 50%;
    top: 60px;
  }
  div.whitepaper-inner-content .past-events {
    padding-top: 60px;
  }
  .resources-wrapper.Events .Resources .section-2 {
    padding-top: 0px !important;
  }
}
@media only screen and (max-width: 767px) {
  .resources-wrapper .resource-top .resource-menu ul {
    flex-wrap: wrap;
  }
  .resources-wrapper .resource-top .resource-menu ul li {
    width: 48%;
  }
  .events-wrapper .events-inner h1 {
    line-height: 30px;
    font-size: 21px!important;
  }
}
@media only screen and (max-width: 480px) {
  .resources-tab-view {
    flex-direction: column;
  }
  .resources-tab-view .resource-tab-wrapper div.MuiFormControl-root {
    width: 100%;
    margin-right: 0px;
    position: absolute;
    top: 70px;
  }
  .resources-tab-view .search-box {
    width: 100%;
    top: 0px;
  }
  div.events-wrapper .events-inner header.MuiAppBar-root {
    width: 100%;
    margin-top: 130px;
  }
  .resources-wrapper.Events .Resources .section-2 {
    padding-top: 0px !important;
  }
  .resources-wrapper .resource-top .resource-menu {
    flex-direction: row;
    overflow-x: auto;
  }
  .resources-wrapper .resource-top .resource-menu ul {
    flex-wrap: nowrap;
    min-width: 800px;
  }
  .resources-wrapper .resource-top .resource-menu ul li {
    min-width: 185px;
  }
  .resources-wrapper .resource-top .resource-menu ul li a {
    flex-direction: column-reverse;
    justify-content: flex-start;
  }
  .resources-wrapper .resource-top .resource-menu ul li a span {
    position: static;
    width: 100%;
  }
}
