.Enterprise.Healthcare .white-bg {
  background: linear-gradient(90deg, #FFFFFF 36.46%, rgba(241, 241, 241, 0) 78.89%);
}
.Enterprise.Healthcare .custom-container {
  min-width: 85%;
  width: 85%;
  max-width: 85%;
}
.Enterprise.Healthcare .section-1 .main-para-div.talk-to-us {
  margin-top: 50px;
}
.Healthcare .section-8 .custom-container .webinar-list p a {
  text-decoration: underline;
  font-weight: 800;
  padding-left: 3px;
  font-size: 12px;
}
.testimonial-scroll-margin .testimonials.new-testi .testi-col .testi-card {
  margin: 0 auto;
}
.blog-module .main-head-1 .primary-span {
  font-size: 40px;
}
.Enterprise.Healthcare .section-1 {
  background: linear-gradient(101.06deg, #0E2E40 0%, #145554 42.87%, #208F71 99.96%) !important;
  background-size: cover;
  display: flex;
  align-items: center;
  height: 100vh;
  text-align: center;
  position: relative;
}
.Enterprise.Healthcare .section-1::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  background: url('/assets/img/health-img/health-bg-new.png') no-repeat;
  z-index: 1;
  width: 100%;
  background-size: 100%;
  height: 65%;
}
.Enterprise.Healthcare .section-1 .main-para-div.short-div {
  width: 100%;
}
.Enterprise.Healthcare .section-1 .main-para-div.short-div p {
  color: #FFFFFF;
  line-height: 30px;
}
.Enterprise.Healthcare .section-1 span.connect-btn {
  font-size: 24px;
  color: #1E4B5E;
  font-family: "Object Sans heavy";
  line-height: 24px;
  background: #FFCC4A;
}
.Enterprise.Healthcare .section-1 .main-head-1 .primary-span {
  font-size: 46px;
  color: #FFCC4A;
  margin-bottom: 30px;
}
.Enterprise.Healthcare .section-1 .main-head-1 .secondary-span {
  font-size: 32px;
  color: #FFFFFF;
}
.Healthcare .section-2 {
  padding: 100px 0px;
}
.Healthcare .section-2 .content-section {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
}
.Healthcare .section-2 .content-section .solution-list {
  text-align: center;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 50px;
  align-items: center;
}
.Healthcare .section-2 .content-section .solution-list i {
  display: flex;
  width: auto;
  justify-content: center;
  box-shadow: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2));
  border-radius: 30px 0px;
  border: 3px solid #FFCC4A;
  padding: 20px;
}
.Healthcare .section-2 .content-section .solution-list strong {
  width: 100%;
  color: #1E4B5E;
  font-size: 24px;
  margin-top: 15px;
  margin-bottom: 15px;
  font-family: "Object sans heavy";
}
.Healthcare .section-2 .content-section .solution-list span {
  color: #777777;
  font-size: 15px;
  font-family: "Object Sans";
  font-weight: 400;
}
.Healthcare .section-2 .content-section .solution-list em {
  display: inline-flex;
  font-size: 14px;
  font-family: "Object Sans";
  font-style: normal;
  color: #007CC1;
  text-decoration: underline;
  cursor: pointer;
}
.Healthcare .section-3 {
  background: linear-gradient(99.45deg, #0E2E40 -9.39%, #145554 28.48%, #208F71 100%);
}
.Healthcare .section-4 .business-showcase {
  display: flex;
  justify-content: space-between;
}
.Healthcare .section-4 .business-showcase .business-list {
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
  border-radius: 0px 30px;
  border: 2px solid rgba(74, 74, 74, 0.1);
  background: #FAFAFF;
  padding: 0px;
  width: 47%;
  flex: 0 0 47%;
  cursor: pointer;
}
.Healthcare .section-4 .business-showcase .business-list .business-img {
  width: 100%;
  padding: 20px;
  position: relative;
  height: 300px;
}
.Healthcare .section-4 .business-showcase .business-list .business-img img {
  width: 100%;
  height: 100%;
  filter: drop-shadow(1px 1px 0px #FFD028);
}
.Healthcare .section-4 .business-showcase .business-list .business-img .talk-section {
  display: none;
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  border-radius: 0px 30px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
}
.Healthcare .section-4 .business-showcase .business-list .business-img .talk-section .talk-detail {
  height: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
}
.Healthcare .section-4 .business-showcase .business-list .business-img .talk-section .talk-detail span {
  color: #FFFFFF;
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.Healthcare .section-4 .business-showcase .business-list .business-img .talk-section .talk-detail a {
  background: transparent;
  padding: 15px;
  border: 2px solid #FFFFFF;
  border-radius: 5px;
  color: #FFF;
  font-family: "Object Sans Heavy";
}
.Healthcare .section-4 .business-showcase .business-list .business-img:hover .talk-section {
  display: block;
}
.Healthcare .section-4 .business-showcase .business-list .business-detail {
  background: #FFFFFF;
  padding: 15px;
  border-bottom-left-radius: 30px;
  display: flex;
  flex-wrap: wrap;
}
.Healthcare .section-4 .business-showcase .business-list .business-detail b {
  color: #1E4B5E;
  font-family: "Object Sans Heavy";
  font-size: 21px;
  display: block;
  margin-bottom: 15px;
}
.Healthcare .section-4 .business-showcase .business-list .business-detail p {
  color: #1E4B5E;
  font-size: 15px;
  width: 100%;
}
.Healthcare .section-4 .business-showcase .business-list .business-detail .business-link {
  display: inline-flex;
  justify-content: flex-end;
  width: 100%;
}
.Healthcare .section-4 .business-showcase .business-list .business-detail .business-link a {
  font-weight: 800;
  font-family: "Object Sans Heavy";
  text-decoration: underline;
}
.Healthcare .section-5 {
  background: linear-gradient(99.45deg, #0E2E40 -9.39%, #145554 28.48%, #208F71 100%);
}
.Healthcare .section-5 .compilance-section {
  display: flex;
  align-items: flex-start;
}
.Healthcare .section-5 .compilance-section .compilance-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.Healthcare .section-5 .compilance-section .compilance-list strong {
  display: flex;
  width: 100%;
  text-align: center;
  color: #FFFFFF;
  font-family: "Object Sans Heavy";
  font-size: 21px;
  margin: 15px 0px;
  justify-content: center;
}
.Healthcare .section-5 .compilance-section .compilance-list span {
  color: #FFFFFF;
  text-align: center;
  font-weight: 400;
  font-size: 15px;
}
.Healthcare .section-6 {
  padding: 100px 0px;
}
.Healthcare .section-6 .robust-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Healthcare .section-6 .robust-container .right-robust {
  margin-left: 30px;
}
.Healthcare .section-6 .robust-container .right-robust h3 {
  color: #208F71;
  font-family: "Heldane display";
  font-size: 42px;
}
.Healthcare .section-6 .robust-container .right-robust strong {
  font-family: "Object Sans Heavy";
  font-size: 24px;
  margin: 15px 0px;
  display: block;
}
.Healthcare .section-6 .robust-container .right-robust p {
  color: #1E4B5E;
  line-height: 29px;
}
.Healthcare .section-7 {
  background: #FAFAFF;
  background-size: 100%;
  padding: 100px 0px;
  display: flex;
  flex-wrap: wrap;
}
.Healthcare .section-7 .custom-container {
  flex-wrap: wrap;
}
.Healthcare .section-7 .main-head-div {
  display: flex;
  width: 100%;
}
.Healthcare .section-7 .health-blogs {
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}
.Healthcare .section-7 .health-blogs .health-wrap {
  padding: 20px 5px;
}
.Healthcare .section-7 .health-blogs .health-blog-list {
  border: 2px solid rgba(74, 74, 74, 0.1);
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 0px 60px;
  display: flex;
  flex-wrap: wrap;
  padding: 0px;
  max-width: 90%;
  min-height: 330px;
}
.Healthcare .section-7 .health-blogs .health-blog-list strong {
  padding: 20px;
  width: 100%;
  display: flex;
  border-bottom: 2px solid rgba(74, 74, 74, 0.1);
  color: #1E4B5E;
  font-family: "Object Sans Heavy";
  justify-content: center;
}
.Healthcare .section-7 .health-blogs .health-blog-list em {
  padding: 10px 20px;
  font-style: normal;
  color: #208F71;
  display: block;
  min-height: 92px;
}
.Healthcare .section-7 .health-blogs .health-blog-list p {
  padding: 0px 20px 20px;
  color: #1E4B5E;
  font-size: 15px;
  margin-bottom: 0px;
  min-height: 155px;
}
.Healthcare .section-7 .health-blogs .health-blog-list .readmore {
  display: flex;
  justify-content: flex-end;
  font-family: "Object sans heavy";
  width: 100%;
  font-size: 16px;
  padding: 0px 10px 10px;
}
.Healthcare .section-7 .health-blogs .health-blog-list .readmore a {
  text-decoration: underline;
}
.Healthcare .section-8 {
  padding: 100px 0px;
}
.Healthcare .section-8 .custom-container {
  flex-wrap: wrap;
}
.Healthcare .section-8 .custom-container .main-head-div {
  width: 100%;
}
.Healthcare .section-8 .custom-container .webinar-showcase {
  justify-content: space-between;
  width: 100%;
}
.Healthcare .section-8 .custom-container .webinar-wrap {
  padding: 20px 5px;
}
.Healthcare .section-8 .custom-container .webinar-list {
  border: 2px solid rgba(74, 74, 74, 0.1);
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 0px 30px;
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  max-width: 90%;
}
.Healthcare .section-8 .custom-container .webinar-list .webinar-img {
  height: 150px;
  overflow: hidden;
  object-fit: cover;
  width: 100%;
  filter: drop-shadow(1px 1px 0px #FFD028);
}
.Healthcare .section-8 .custom-container .webinar-list img {
  width: 100%;
  height: 100%;
  border-radius: 3px;
}
.Healthcare .section-8 .custom-container .webinar-list strong {
  color: #1E4B5E;
  font-family: "Object Sans Heavy";
  font-size: 16px;
  min-height: 72px;
  display: block;
  max-width: 95%;
}
.Healthcare .section-8 .custom-container .webinar-list p {
  color: #1E4B5E;
  line-height: 23px;
  font-size: 14px;
  margin-top: 15px;
  min-height: 92px;
}
.Healthcare .section-8 .custom-container .webinar-list .webinar-foot {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
}
.Healthcare .section-8 .custom-container .webinar-list .webinar-foot a {
  font-weight: 800;
  text-decoration: underline;
  font-size: 15px;
}
.Healthcare .section-9 .custom-container {
  background: linear-gradient(173.59deg, #208F71 4.49%, #1E4B5E 94.38%);
  border-radius: 20px;
  padding: 30px;
  flex-wrap: wrap;
  margin-bottom: 50px;
  width: 90%;
  max-width: 90%;
}
.Healthcare .section-9 .custom-container .main-head-div {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.Healthcare .section-9 .custom-container .main-head-div .primary-span {
  color: #FFFFFF;
}
.Healthcare .section-9 .custom-container .expert-speak {
  display: flex;
  width: 100%;
  margin-top: 120px;
  align-items: flex-start;
  justify-content: space-between;
}
.Healthcare .section-9 .custom-container .expert-speak .expert-left .expert-info {
  background: #FFFFFF;
  box-shadow: 0px 6px 30px rgba(45, 44, 85, 0.1);
  border-radius: 30px;
  position: relative;
}
.Healthcare .section-9 .custom-container .expert-speak .expert-left .expert-info::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: -130px;
  background: linear-gradient(173.59deg, #20756A 4.49%, #1F7E6C 94.38%);
  border-radius: 100%;
  height: 200px;
  width: 200px;
  margin: auto;
}
.Healthcare .section-9 .custom-container .expert-speak .expert-left .expert-info .expert-img {
  position: absolute;
  top: -120px;
  left: 0;
  right: 0;
  margin: auto;
  width: 180px;
  height: 180px;
  border-radius: 100%;
  overflow: hidden;
  box-shadow: 3px 3px 0px #FFD028, 2px 2px 5px rgba(30, 75, 94, 0.3);
}
.Healthcare .section-9 .custom-container .expert-speak .expert-left .expert-info .expert-img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.Healthcare .section-9 .custom-container .expert-speak .expert-left .expert-info .expert-detail {
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  padding: 50px 30px 20px;
  padding-top: 80px;
}
.Healthcare .section-9 .custom-container .expert-speak .expert-left .expert-info .expert-detail strong {
  color: #1E4B5E;
  font-family: "Object Sans Heavy";
  font-size: 21px;
}
.Healthcare .section-9 .custom-container .expert-speak .expert-left .expert-info .expert-detail em {
  font-style: normal;
  color: #000000;
  font-size: 18px;
  margin: 5px 0px 0px;
}
.Healthcare .section-9 .custom-container .expert-speak .expert-left .expert-info .expert-detail span {
  color: #2692AA;
  font-size: 14px;
}
.Healthcare .section-9 .custom-container .expert-speak .expert-right {
  width: 60%;
  justify-content: center;
  padding-right: 30px;
  margin-top: -100px;
}
.Healthcare .section-9 .custom-container .expert-speak .expert-right p {
  color: #FFFFFF;
  line-height: 32px;
  margin-bottom: 30px;
}
.Healthcare .section-9 .custom-container .expert-speak .expert-right .book-consult {
  display: flex;
  justify-content: center;
}
.Healthcare .section-9 .custom-container .expert-speak .expert-right a {
  background: #FFCC4A;
  box-shadow: 0px 6px 10px rgba(30, 75, 94, 0.2);
  border-radius: 3px;
  color: #1E4B5E;
  font-family: "Object Sans Heavy";
  padding: 15px 25px;
  font-size: 18px;
}
.Healthcare .company-info .main-para-div p {
  width: 400px;
}
.Healthcare .section-8 .custom-container .webinar-list strong {
  min-height: auto;
}
.Healthcare .section-8 .custom-container .webinar-list p {
  position: relative;
  height: 70px;
  overflow: hidden;
  min-height: unset;
  text-overflow: ellipsis;
}
.Healthcare .section-8 .custom-container .webinar-list p a {
  position: absolute;
  right: 0;
  bottom: 0;
  background: #fff;
}
.Healthcare .section-8 .custom-container .webinar-list p a:before {
  content: "...";
  position: absolute;
  left: -22px;
  background: #fff;
  color: #000;
  letter-spacing: 1px;
  width: 22px;
}
.Healthcare .section-4 .business-showcase {
  display: inline-flex;
  width: 100%;
}
.Healthcare .section-4 .business-showcase .business-list {
  padding: 0px;
  width: 50%;
  flex: 1 1 50%;
  margin: 10px;
  cursor: pointer;
  align-self: stretch;
  justify-self: stretch;
  background: #fff;
}
.Healthcare .section-4 .business-showcase .business-list .business-img {
  height: auto;
  background: #f9f9fe;
  border-radius: 0 28px 0px 0px;
}
.Healthcare .section-4 .business-showcase .business-list .business-detail {
  display: inline-flex;
  flex-wrap: wrap;
  min-height: 100px;
  align-self: stretch;
  justify-self: stretch;
  min-height: 140px;
  width: 100%;
}
.Healthcare .main-head-1.main-head-2 span.primary-span.new-heading {
  font-size: 48px;
}
.Healthcare .why-choose .main-head-1.main-head-2 span.primary-span.new-heading {
  font-size: 48px;
  text-align: left;
  color: #fff;
}
.Healthcare .left-section .main-head-1.main-head-2 span.primary-span.new-heading {
  font-size: 48px;
  text-align: left;
  color: #24A989;
}
.Healthcare .health-faq .main-head-div.heading_lines .main-head-1 .primary-span {
  color: #fff;
}
.Healthcare .health-faq.faq-listwrp .faq-list .summary .MuiAccordionSummary-content .summary-content,
.Healthcare .health-faq.faq-listwrp .faq-list .faq-details P {
  color: #fff;
}
.Healthcare .health-faq .inline-accordion-details {
  margin: 20px 0px;
}
.Healthcare .health-faq .inline-accordion-details p:before {
  background: #fff;
}
.Healthcare .health-faq .inline-accordion-details {
  margin-top: 20px;
}
.custom-section.connect-with-custom-section p.health-para.text-center {
  max-width: 80%;
  margin: auto;
  line-height: 30px;
}
.Healthcare .section-2.exp-contaniner .content-section .solution-list {
  justify-content: flex-start;
}
.Healthcare .section-2.exp-contaniner .content-section .solution-list strong,
.Healthcare .section-2.exp-contaniner .content-section .solution-list span {
  text-align: left;
}
@media only screen and (min-width: 1580px) and (max-width: 4600px) {
  .Healthcare .section-8 .custom-container .webinar-list img {
    object-fit: contain;
  }
  .Enterprise.Healthcare div.custom-container {
    max-width: 1280px;
    min-width: 1280px;
    width: 1280px;
  }
}
@media only screen and (min-width: 1800px) and (max-width: 3400px) {
  .Enterprise.Healthcare .section-1 .main-head-1 .primary-span {
    font-size: 78px;
  }
  .Enterprise.Healthcare .section-1 .main-head-1 .secondary-span {
    font-size: 46px;
  }
  .Enterprise.Healthcare .section-1 .main-para-div.short-div p {
    font-size: 28px;
    line-height: 42px;
  }
  .Enterprise.Healthcare .section-1 a.connect-btn.btn-primary {
    font-size: 34px !important;
    width: 350px !important;
    height: 100px !important;
    line-height: 95px !important;
  }
  .Enterprise.Healthcare .section-2 .content-section .solution-list strong {
    font-size: 28px;
  }
  .Enterprise.Healthcare .section-2 .content-section .solution-list span {
    font-size: 22px;
  }
  .Enterprise.Healthcare div.custom-container {
    max-width: 1640px;
    min-width: 1640px;
    width: 1640px;
  }
  .Enterprise.Healthcare .main-head-1.main-head-2 .primary-span {
    font-size: 60px;
  }
  .Enterprise.Healthcare .section-3.testimonial-scroll-margin .testi-client-review p {
    font-size: 18px !important;
  }
  .Enterprise.Healthcare .section-4 .business-showcase .business-list .business-img {
    height: auto;
  }
  .Enterprise.Healthcare .section-4 .business-showcase .business-list .business-detail b {
    font-size: 36px;
  }
  .Enterprise.Healthcare .section-4 .business-showcase .business-list .business-detail p {
    font-size: 28px;
  }
  .Enterprise.Healthcare .section-5 .compilance-section .compilance-list strong {
    font-size: 34px;
  }
  .Enterprise.Healthcare .section-5 .compilance-section .compilance-list span {
    font-size: 24px;
  }
  .Enterprise.Healthcare .section-6 .robust-container .right-robust h3 {
    font-size: 64px;
  }
  .Enterprise.Healthcare .section-6 .robust-container .right-robust strong {
    font-size: 48px;
  }
  .Enterprise.Healthcare .section-6 .robust-container .right-robust p {
    font-size: 28px;
    line-height: 38px;
  }
  .Enterprise.Healthcare .section-7 .health-blogs .health-blog-list strong {
    font-size: 34px;
  }
  .Enterprise.Healthcare .section-7 .health-blogs .health-blog-list em {
    font-size: 24px;
  }
  .Enterprise.Healthcare .section-7 .health-blogs .health-blog-list p {
    font-size: 21px;
  }
  .Enterprise.Healthcare .section-7 .health-blogs .health-blog-list .readmore {
    font-size: 18px;
  }
  .Enterprise.Healthcare .section-8 .custom-container .webinar-list strong {
    font-size: 24px;
  }
  .Enterprise.Healthcare .section-8 .custom-container .webinar-list p {
    font-size: 21px;
    height: 200px;
    line-height: 38px;
  }
  .Enterprise.Healthcare .section-8 .custom-container .webinar-list a {
    font-size: 18px;
  }
  .Enterprise.Healthcare .section-8 .custom-container .webinar-list .webinar-img {
    height: 230px;
  }
  .Enterprise.Healthcare .section-9 .custom-container .expert-speak .expert-left .expert-info::before {
    width: 280px;
    height: 280px;
  }
  .Enterprise.Healthcare .section-9 .custom-container .expert-speak .expert-left .expert-info .expert-img {
    width: 250px;
    height: 250px;
  }
  .Enterprise.Healthcare .section-9 .custom-container .expert-speak .expert-left .expert-info .expert-detail {
    padding-top: 175px;
  }
  .Enterprise.Healthcare .section-9 .custom-container .expert-speak .expert-left .expert-info .expert-detail strong {
    font-size: 28px;
  }
  .Enterprise.Healthcare .section-9 .custom-container .expert-speak .expert-left .expert-info .expert-detail em {
    font-size: 20px;
  }
  .Enterprise.Healthcare .section-9 .custom-container .expert-speak .expert-left .expert-info .expert-detail span {
    font-size: 18px;
  }
  .Enterprise.Healthcare .section-9 .custom-container .expert-speak .expert-right p {
    font-size: 28px;
    line-height: 46px;
  }
  .Enterprise.Healthcare .section-9 .custom-container .expert-speak .expert-right a {
    font-size: 24px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1327px) {
  .Healthcare .section-8 .custom-container .webinar-list strong {
    font-size: 15px;
  }
  .Enterprise.Healthcare .section-1 .main-head-1 .primary-span {
    font-size: 35px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 1200px) {
  .Healthcare .section-8 .custom-container .webinar-list strong {
    min-height: 70px;
    font-size: 14px;
  }
}
@media only screen and (max-width: 1024px) {
  .Enterprise.Healthcare .section-1 {
    height: auto;
  }
  div.new-design-home .section-1 .custom-section.preHeaderAdded {
    padding-top: 0px;
  }
  .Healthcare .main-head-1.main-head-2 span.primary-span.new-heading,
  .Healthcare .why-choose .main-head-1.main-head-2 span.primary-span.new-heading,
  .Healthcare .left-section .main-head-1.main-head-2 span.primary-span.new-heading,
  .Healthcare .section-6 .robust-container .right-robust h3 {
    font-size: 30px;
    line-height: 38px;
  }
}
@media only screen and (max-width: 980px) {
  .Enterprise.Healthcare .work-with-row .comp-info-right-col {
    top: 55px;
  }
  .Healthcare .company-info .main-para-div p {
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .Enterprise.Healthcare .section-1 {
    height: auto;
  }
  .Healthcare .section-6 .robust-container .left-robust {
    width: 40%;
  }
  .Healthcare .section-6 .robust-container .left-robust img {
    width: 100%;
  }
  .Healthcare .section-6 .robust-container .right-robust {
    width: 60%;
  }
  .Healthcare .section-7 .health-blogs .health-blog-list,
  .Healthcare .section-8 .custom-container .webinar-list {
    max-width: 100%;
    flex: 0 0 100%;
    margin-bottom: 30px;
  }
  .Healthcare .section-8 .custom-container .webinar-showcase {
    flex-wrap: wrap;
  }
  .Healthcare .section-8 .custom-container .webinar-list .webinar-img {
    height: auto;
  }
  .Healthcare .section-7 {
    background-size: cover;
  }
  .Healthcare .section-9 .custom-container .expert-speak .expert-right {
    padding-left: 30px;
    padding-right: 0px;
  }
}
@media only screen and (max-width: 678px) {
  .Enterprise.Healthcare .section-1 {
    background-image: url(/assets/img/health-img/health-mob-bg.svg) !important;
  }
  .testimonials.new-testi .testi-col .testi-card .testi-client-head b {
    font-size: 20px;
    line-height: 22px;
  }
  .testimonial-scroll-margin .testimonials.new-testi .testi-col .testi-card {
    height: 540px!important;
  }
  .Healthcare .section-4 .business-showcase .business-list .business-img img {
    height: auto;
  }
  .Healthcare .section-4 .business-showcase .business-list .business-img {
    height: auto;
  }
  .Healthcare .section-8 .custom-container .webinar-list .webinar-foot a {
    font-size: 13px;
  }
}
@media only screen and (max-width: 480px) {
  .Enterprise.Healthcare .section-1 .main-para-div.short-div p br {
    display: none !important;
  }
  .Healthcare .section-4 .business-showcase,
  .Healthcare .section-5 .compilance-section,
  .Healthcare .section-7 .health-blogs,
  .Healthcare .section-8 .custom-container .webinar-showcase,
  .Healthcare .section-9 .custom-container .expert-speak,
  .Healthcare .section-6 .robust-container {
    flex-direction: column;
  }
  .Healthcare .section-4 .business-showcase .business-list,
  .Healthcare .section-7 .health-blogs .health-blog-list,
  .Healthcare .section-8 .custom-container .webinar-list,
  .Healthcare .section-5 .compilance-section .compilance-list {
    width: 100%;
    margin: 0px;
    max-width: 100%;
    margin-bottom: 30px;
  }
  .Healthcare .section-4 .business-showcase .business-list:last-child,
  .Healthcare .section-7 .health-blogs .health-blog-list:last-child,
  .Healthcare .section-8 .custom-container .webinar-list:last-child {
    margin-bottom: 0px;
  }
  .Enterprise.Healthcare .section-1 {
    background: linear-gradient(101.06deg, #0E2E40 0%, #145554 42.87%, #208F71 99.96%) !important;
  }
  .Enterprise.Healthcare .section-1 .main-head-1 .primary-span {
    font-size: 36px;
  }
  .Enterprise.Healthcare .section-1 {
    padding: 100px 0px;
    height: auto;
  }
  .Enterprise.Healthcare .section-1 .main-head-1 .secondary-span {
    font-size: 26px;
  }
  .Healthcare .section-9 .custom-container .expert-speak .expert-right {
    width: 100%;
    margin-top: 30px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .Healthcare .section-6 .robust-container .right-robust {
    margin-left: 0px;
    margin-top: 30px;
    width: 100%;
  }
  button.slick-arrow {
    height: 35px;
    width: 35px;
  }
  .slick-next {
    right: -20px !important;
  }
  .slick-prev {
    left: -20px !important;
  }
  .testimonials.new-testi .testi-col .testi-card .testi-footer {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .testimonials.new-testi .testi-comp-info {
    padding-left: 0px;
  }
  .testimonials.new-testi .testi-col .testi-card .testi-detailed-review {
    justify-content: flex-start;
  }
}
