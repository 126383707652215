@font-face {
  font-family: 'Poiret One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poiretone/v16/UqyVK80NJXN4zfRgbdfbk5k.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrFJA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9V1s.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6V1s.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7V1s.ttf) format('truetype');
}
.banner-component {
  width: 100%;
  height: 309px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.05);
  position: fixed;
  z-index: 10;
  background-size: cover;
  display: flex;
  align-items: flex-start;
  top: -150%;
  transition: all 0.5s linear;
  justify-content: flex-start;
}
.banner-component img {
  width: 100%;
  cursor: pointer;
}
.banner-component img.mob-img {
  display: none;
}
.banner-component.show {
  top: 0;
  transition: all 0.5s linear;
}
.banner-component .close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 12;
}
.banner-component .close-btn svg {
  cursor: pointer;
}
.banner-component i.bottom-logo {
  position: absolute;
  bottom: 30px;
  left: 45px;
}
.banner-component .banner-detail {
  color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  width: 40%;
  padding: 50px 0px;
  position: relative;
}
.banner-component .banner-detail em {
  font-size: 32px;
  display: flex;
  width: 100%;
  font-family: "Object Sans";
  font-weight: 600;
  font-style: normal;
  color: #26A7B0;
}
.banner-component .banner-detail strong {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 15px 0px 5px;
  font-size: 34px;
  font-weight: 800;
}
.banner-component .banner-detail span {
  color: #fff;
  font-size: 26px;
  font-weight: 600;
}
.banner-component .banner-detail span br {
  display: block !important;
}
.banner-component .banner-info {
  padding: 50px 0px 30px;
  flex-wrap: wrap;
  width: 35%;
  position: relative;
  z-index: 1;
  bottom: 0;
  margin: auto 0 0;
}
.banner-component .banner-info h1 {
  color: #ffffff;
  font-size: 26px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  position: relative;
}
.banner-component .banner-info h1 span {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}
.banner-component .banner-info em {
  color: #26A7B0;
  font-family: 'Poppins', sans-serif;
  font-size: 28px;
  margin: 0px 0px 0px;
  display: flex;
  font-style: normal;
}
.banner-component .banner-info .book-btn {
  color: #26A7B0;
  border-radius: 50px;
  background: #ffffff;
  font-size: 21px;
  padding: 10px 20px;
  display: flex;
  width: 250px;
  font-family: 'Poppins', sans-serif;
  justify-content: center;
  font-weight: 600;
  margin-top: 10px;
}
.banner-component .banner-info .book-btn i {
  margin-left: 30px;
}
.banner-component .doctor-info {
  display: flex;
  flex-direction: column;
  margin: 0px;
  width: 30%;
  z-index: 2;
  color: #fff;
  align-items: flex-end;
  justify-content: flex-end;
  position: absolute;
  right: 0;
}
.banner-component .doctor-info .doctor-img {
  z-index: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.banner-component .doctor-info .doctor-img img {
  width: 65%;
}
.banner-component .doctor-info .doctor-detail {
  text-align: center;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  width: 100%;
  padding: 5px 15px;
  position: relative;
}
.banner-component .doctor-info .doctor-detail::after,
.banner-component .doctor-info .doctor-detail::before {
  content: "";
  width: 100%;
  height: 3px;
  position: absolute;
  bottom: 0;
  background: #26A7B0;
  border-radius: 50%;
  filter: blur(1.5px);
  left: 0px;
  right: 0px;
}
.banner-component .doctor-info .doctor-detail::before {
  top: 0;
}
.banner-component .doctor-info .doctor-detail::after {
  margin: auto;
  width: 80%;
  bottom: -10px;
}
.banner-component .doctor-info strong {
  font-size: 28px;
  font-weight: 600;
}
.banner-component .doctor-info p {
  color: #fff;
  font-size: 14px;
  margin-bottom: 0px;
}
.banner-component .event-info {
  background: transparent;
  box-shadow: 3px 3px 0px #FFD028, 2px 2px 5px rgba(30, 75, 94, 0.3);
  padding: 20px;
  text-align: right;
  margin-top: 260px;
  position: relative;
}
.banner-component .event-info b {
  color: #ffffff;
  font-size: 24px;
  font-family: 'Poppins', sans-serif;
}
.banner-component .event-info span {
  display: flex;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  justify-content: center;
  margin-bottom: 10px;
}
.banner-component .event-info em {
  display: flex;
  color: #ffffff;
  font-family: 'Poiret One';
  font-size: 48px;
  justify-content: flex-end;
  font-style: normal;
}
.banner-component .event-info::before {
  content: '';
  position: absolute;
  top: -15%;
  left: 30%;
  width: 150px;
  height: 130px;
  background: url('/assets/img/banner-img/arrow.svg') no-repeat;
}
.overlay-banner {
  display: none;
}
.show.overlay-banner {
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 5;
  display: block;
}
.show.overlay-banner:before {
  content: "";
  width: 100%;
  position: absolute;
  z-index: 0;
  height: 100vh;
  left: 0;
  cursor: pointer;
  backdrop-filter: blur(3px);
  background: #ffffffb0;
}
@media only screen and (max-width: 1280px) {
  .banner-component .doctor-info {
    margin: 0px;
  }
  .banner-component .doctor-info .doctor-img img {
    width: 70%;
  }
}
@media only screen and (max-width: 1200px) {
  .banner-component .banner-detail em {
    font-size: 30px;
  }
  .banner-component .doctor-info p {
    font-size: 12px;
  }
}
@media only screen and (max-width: 1024px) {
  .banner-component {
    padding: 10px;
  }
  .banner-component .banner-info em {
    font-size: 21px;
  }
  .banner-component .banner-detail em {
    font-size: 26px;
  }
  .banner-component .banner-detail span {
    font-size: 22px;
  }
  .banner-component i.bottom-logo {
    left: 5px;
  }
  .banner-component .doctor-info {
    width: 25%;
  }
  .banner-component .banner-info h1 br {
    display: block !important;
  }
  .banner-component .banner-info h1 {
    font-size: 28px;
  }
  .banner-component .banner-info .doctor-info strong {
    font-size: 24px;
  }
  .banner-component .banner-info .doctor-info p {
    font-size: 16px;
  }
  .banner-component .banner-info .book-btn {
    font-size: 18px;
    width: 260px;
  }
  .banner-component .event-info em {
    font-size: 28px;
  }
  .banner-component .event-info span,
  .banner-component .event-info strong {
    font-size: 18px;
    display: block;
  }
  .banner-component .event-info {
    padding: 25px;
  }
  .banner-component .event-info::before {
    top: -25%;
  }
}
@media only screen and (max-width: 768px) {
  .banner-component .banner-detail em {
    font-size: 22px;
  }
  .banner-component .doctor-info strong {
    font-size: 21px;
  }
  .banner-component .doctor-img img {
    width: 70%;
    right: 0;
  }
  .banner-component .doctor-img {
    right: 5%;
  }
  .banner-component .banner-info h1 {
    font-size: 24px;
  }
  .banner-component .banner-info .doctor-info strong {
    font-size: 21px;
  }
  .banner-component .banner-info .doctor-info p {
    font-size: 14px;
  }
  .banner-component .banner-info .book-btn {
    font-size: 16px;
    width: 220px;
    padding: 15px 25px;
  }
  .banner-component .event-info {
    padding: 15px;
  }
  .banner-component .event-info::before {
    top: -35%;
  }
}
@media only screen and (max-width: 600px) {
  .banner-component {
    flex-wrap: wrap;
    height: auto;
    padding: 25px;
    height: 100vh;
    align-items: inherit;
    display: flex;
    padding-top: 20px 50px;
    top: -150vh;
    align-items: flex-start;
  }
  .banner-component i.bottom-logo {
    display: none;
  }
  .banner-component .doctor-img {
    width: 65%;
    position: relative;
    right: 0px;
    margin: auto;
  }
  .banner-component .doctor-img img {
    width: 100%;
    left: 0;
    right: 0;
  }
  .banner-component .banner-info .book-btn {
    position: static;
    margin-top: 10px;
  }
  .banner-component .banner-info h1 {
    font-size: 18px;
    line-height: 34px;
    width: 90%;
    padding-left: 15px;
  }
  .banner-component .doctor-info {
    position: static;
    bottom: 0;
    width: 100%;
    margin: 0px;
    justify-content: flex-start;
    padding: 0px;
  }
  .banner-component .doctor-info .doctor-detail {
    text-align: center;
    padding-top: 10px;
  }
  .banner-component .banner-info .doctor-info strong {
    font-size: 18px;
  }
  .banner-component .banner-info .doctor-info p {
    font-size: 14px;
  }
  .banner-component .banner-info .doctor-info {
    margin: 10px 0px;
  }
  .banner-component div.banner-info {
    padding: 0px;
    position: static;
    margin: 0px;
  }
  .banner-component div.banner-info br {
    display: none !important;
  }
  .banner-component div.banner-info h1 {
    padding: 0px;
  }
  .banner-component div.banner-info em {
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .banner-component .event-info {
    margin-top: 180px;
  }
  .banner-component .event-info::before {
    width: 60px;
    height: 80px;
    background-size: 100%;
    left: 70%;
    top: -10%;
  }
  .banner-component .event-info .hims-logo img {
    width: 50%;
  }
  .banner-component .event-info .event-details strong {
    font-size: 22px;
  }
  .banner-component .event-info b {
    font-size: 18px;
  }
  .banner-component .event-info .event-details span,
  .banner-component .event-info .event-details em {
    font-size: 14px;
  }
  .banner-component .banner-detail {
    width: 100%;
    align-items: center;
    padding: 5px;
    border-radius: 20px;
    position: relative;
    z-index: 2;
  }
  .banner-component .banner-detail em {
    display: inline-flex;
    width: auto;
    font-size: 24px;
    border-bottom: 0px;
    padding: 0px 10px 0px 0px;
  }
  .banner-component .banner-detail strong {
    width: auto;
    display: inline-flex;
    font-size: 21px;
    padding: 0px 10px 0px 10px;
  }
  .banner-component .banner-detail span {
    font-size: 18px;
  }
  .banner-component .banner-info {
    width: 100%;
  }
  .banner-component .doctor-info strong {
    font-size: 16px;
  }
  .banner-component .doctor-info p {
    font-size: 14px;
  }
}
@media only screen and (max-width: 420px) {
  .banner-component .banner-detail em,
  .banner-component .banner-detail strong {
    font-size: 24px;
  }
  .banner-component .banner-detail span {
    font-size: 21px;
  }
  .banner-component .close-btn {
    top: 10px;
    right: 10px;
  }
  .banner-component .banner-info .book-btn {
    width: 190px;
    padding: 15px;
  }
  .banner-component .banner-info .book-btn i {
    margin-left: 15px;
  }
}
@media only screen and (max-width: 320px) {
  div.custom-inx-navbar.kyc-container.home-header {
    top: 30px;
  }
}
@media only screen and (max-width: 480px) {
  .banner-component {
    padding: 0px;
    height: auto;
  }
  .banner-component .close-btn {
    top: 3px;
    right: 3px;
    opacity: 0.6;
  }
  .banner-component img.web-img {
    display: none;
  }
  .banner-component img.mob-img {
    display: block;
  }
}
