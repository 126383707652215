body {
  display: block;
}


.content-padding {
  padding-right: 156px;
  padding-left: 156px;
}

.trading-container {
  padding-bottom: 106px;
}

.trading-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.trading-content {
  gap: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header h1 {
  font-size: 51px;
  font-weight: 800;
  line-height: 62px;
  color: #FC9E1E;
}

@media (max-width: 1240px) {
  .trading-grid {
    grid-template-columns: 1fr;
  margin-top: 50px;
  }
}

@media (max-width: 900px) {
  .right-content img {
    width: 80%;
    margin-top: 12px;
  }
}

@media (max-width: 600px){
  .content-padding {
    padding-right: 20px;
    padding-left: 20px;
  }
  .top-left-image{
    width: 50%;
  }
}

.left-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.crypto-content {
  text-align: left;
  color: aliceblue;
}

.crypto-content p {
  font-size: 1.2rem;
}

.horizontal-line {
  border: none;
  width: 365.13px;
  height: 1.43px;
  background: linear-gradient(to left, rgba(252, 158, 30, 0), rgba(252, 158, 30, 1));
}

/* background-content*/
.background-header h1{
  font-size: 40px;
  font-weight: 800;
  line-height: 62px;
  color: #FC9E1E;
}
.background-horizontal-line{
  border: none;
  width: 250px;
  height: 1.43px;
  background: linear-gradient(to left, rgba(252, 158, 30, 0), rgba(252, 158, 30, 1));
}
.background-content{
  padding-top: 40px;
  text-align: left;
  color: #fff;
  font-weight: 300;
  font-family: inter;
  font-size: 22px;
  line-height: 30px;
}
.background-listContent{
  text-align: left;
  color: #fff;
  font-weight: 300;
  font-family: inter;
  font-size: 22px;
  line-height: 30px;
}
.background-listContent{
  padding-left: 4rem;
  display: flex;
  align-items: start;
}
.background-listContent img {
  margin-right: 20px; 
}
.experience-box{
  background: linear-gradient(
    to left, rgba(252, 158, 30, 0), rgba(128, 90, 30, 1));
  padding-left: 104px;
  padding-right: 108px;
  padding-top: 42px;
  padding-bottom: 42px;
  border-radius: 10px;
  margin-top: 20px;
  width: 100%;
  height: auto;
  margin-bottom: 60px;
}
.experience-content{
  margin-top: 40px;
  text-align: left;
  color: #fff;
  font-weight: 500;
  font-family: inter;
  font-size: 30px;
  line-height: 44px;
}


.right-side-img{
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 600px){
  .right-side-img{
    height: auto;
    display: flex;
  } 
}
@media(max-width: 480px) {
  .right-side-img img {
    width: auto !important;
    margin: auto !important;
    padding-left: 200px;
  }
  .right-side-img {
    justify-content: flex-end;
  }
}

/* key area */
.keyArea-heading h1{
  font-size: 42px;
  font-weight: 800;
  line-height: 62px;
  font-family: inter;
  color: #FC9E1E;
}
.percentage-box {
  background: linear-gradient(
    to left, rgba(252, 158, 30, 0), rgba(118, 80, 30, 1)
  );
  padding-left: 104px;
  padding-right: 108px;
  padding-top: 42px;
  padding-bottom: 42px;
  margin-top: 20px;
  width: 100%;
  height: auto;
  margin-bottom: 60px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: white;
  text-align: center;
}

.percentage-item {
  margin: 0 20px;
}

.percentage-item h2 {
  font-size: 2em;
  margin: 0;
}

.percentage-item p {
  margin: 10px 0 0;
  font-size: 1em;
}
.vertical-line {
  border: none;
  width: 1.43px;
  height: 100px;
  background: linear-gradient(to bottom, rgba(252, 158, 30, 0), rgba(252, 158, 30, 1));
}
@media (max-width: 600px) {
  .percentage-box {
    flex-direction: column;
    align-items: flex-start;
  }

  .vertical-line {
    width: 100%;
    height: 1.43px;
    background: linear-gradient(to right, rgba(252, 158, 30, 0), rgba(252, 158, 30, 1));
    margin: 20px 0;
  }

  .percentage-item {
    width: 100%;
    margin: 10px 0;
  }

  .percentage-item h2 {
    font-size: 1.5em;
  }

  .percentage-item p {
    font-size: 0.9em;
  }
}

/* key area */
.key-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.key-features {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 1200px;
  font-size: 22;
  font-weight: 300;
  line-height: 31px;
  color: #FFFEFE; 
  margin: 0 20px; 
}

.features, .tech-stack {
  padding-left: 50px;
}

h2 {
  margin-bottom: 10px;
  color: #ff9900; 
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
  position: relative;
  padding-left: 20px; 
}

.custom-li::before {
  content: '•'; 
  position: absolute;
  left: 0;
  color:#fff
}
.key-horizontal-line{
  border: none;
  width: 180px;
  margin-bottom: 30px;
  height: 1.43px;
  background: linear-gradient(
    to left, rgba(252, 158, 30, 0), rgba(252, 158, 30, 1));
}

@media (max-width: 768px) {
  .key-features {
    grid-template-columns: 1fr;
  }
}
/*step-Progress*/
.key-list-container{
  justify-content: center;
  align-items: center;
  padding-left: 160px;
}
.step-progress-header{
  color: #ff9900;
  font-weight: 800;
  font-size: 40px;
  line-height: 62px;
  margin-top: 40px;
}
.progress-horizontal-line{
    margin-top: 20px;
    margin-bottom: 50px;
  border: none;
  width: 450px;
  height: 1.43px;
  background: linear-gradient(to left, rgba(252, 158, 30, 0), rgba(252, 158, 30, 1));
}
.key-progress-content{
  text-align: left;
  color: #fff;
  font-weight: 300;
  font-family: inter;
  font-size: 22px;
  line-height: 30px;
}
.key-list-container {
  padding-left: 200px;
}

.key-listContent {
  text-align: left;
  color: #fff;
  font-weight: 300;
  font-family: Inter, sans-serif;
  font-size: 22px;
  line-height: 30px;
  display: flex;
  align-items: center;  
}

.key-listContent img {
  margin-right: 30px; 
}

.list-content {
  display: flex;
  align-items: center;
  margin-top: 15px;
}


@media (max-width: 600px){
  .key-list-container {
    padding-left: 40px;
  }
  .key-listContent img {
    width: 50px;
  }
}

/*account-content*/

.overall-grid-padding {
  padding-left: 156px;
  padding-right: 156px;
}
.wallets-padding{
  padding-left: 156px;
  padding-right: 156px;
}
.kyc-overall-padding{
  padding-left: 156px;
  padding-right: 156px;
}
.account-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.account-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  align-items: center;
}

.account-grid.reverse-order {
  grid-template-columns: 1fr 1fr;
}

.account-image {
  padding-bottom: 150px;
}

.account-grid-left-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.account-heading h1 {
  color: #FC9E1E;
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  font-family: Inter, sans-serif;
}

.account-horizontal-line {
  margin-top: 10px;
  margin-bottom: 40px;
  border: none;
  width: 380px;
  height: 1.43px;
  background: linear-gradient(to left, rgba(252, 158, 30, 0), rgba(252, 158, 30, 1));
}

.account-left-content {
  text-align: left;
  color: #fff;
  font-weight: 300;
  font-family: Inter, sans-serif;
  font-size: 22px;
  line-height: 30px;
}

.account-span-text {
  color: #FC9E1E;
  font-weight: 400;
}

@media (max-width: 700px) {
  .overall-grid-padding {
    padding-left: 20px;
    padding-right: 20px;
  }
  .kyc-overall-padding {
    padding-left: 20px;
    padding-right: 20px;
  }
  .wallets-padding {
    padding-left: 20px;
    padding-right: 20px;
  }

  .account-grid {
    grid-template-columns: 1fr;
    gap: 40px;
  }

  .account-grid.reverse-order {
    grid-template-columns: 1fr;
  }

  .account-grid-left-content,
  .account-left-content {
    align-items: center;
    text-align: center;
  }

  .account-container {
    align-items: center;
  }

  .account-grid-left-content {
    order: 2;
  }

  .account-image {
    order: 1;
  }
}
@media (max-width: 1000px) {
  .overall-grid-padding {
    padding-left: 20px;
    padding-right: 20px;
  }
  .kyc-overall-padding {
    padding-left: 20px;
    padding-right: 20px;
  }
  .wallets-padding {
    padding-left: 20px;
    padding-right: 20px;
  }

  .account-grid {
    grid-template-columns: 1fr;
    gap: 40px;
  }

  .account-grid.reverse-order {
    grid-template-columns: 1fr;
  }

  .account-grid-left-content,
  .account-left-content {
    align-items: center;
    text-align: center;
  }

  .account-container {
    align-items: center;
  }

  .account-grid-left-content {
    order: 2;
  }

  .account-image {
    order: 1;
  }
}
.three-step-process{
  padding-left: 156px;
  padding-right: 156px;
  margin-bottom: 80px;
}

.step-box{
  background: linear-gradient(
    to left, rgba(252, 158, 30, 0), rgba(128, 90, 30, 1));
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 42px;
  padding-bottom: 42px;
  border-radius: 10px;
  margin-top: 20px;
  width: 100%;
  height: auto;
  margin-bottom: 60px;
  justify-content: center;
}
.step-content{
  margin-top: 40px;
  text-align: left;
  color: #fff;
  font-weight: 500;
  font-family: inter;
  font-size: 30px;
  line-height: 44px;
}
@media (max-width: 600px){
  .step-box {
    padding-left: 10px;
    padding-right: 40px;
    justify-content: left;
    align-items: start ;
    min-width: 300px; 
    padding-top: 2px;
  }
  .three-step-process{
    padding-left: 20px;
    padding-right: 20px;
  }
}



/*stepProgress*/
.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.circle {
  width: 125px;
  height: 125px;
  border: 8px solid #ffa500;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 16px;
  text-align: center;
}

.step-number {
  color: #b4adad;
  font-size: 14px;
  text-align: center;
  position: absolute;
  left: 0;
  margin: 0px auto;
  right: 0;
  margin-top: 45px;
}

.step-number2 {
  margin-top: 0px;
  top: -60px;

}

.img1 {
  height: 183px;
  position: absolute;
  top: -30px;
  left: -25px;
}

.img2 {
  height: 183px;
  position: absolute;
  top: -30px;
  left: 62px;
}

.rotate {
  transform: rotate(180deg);
  top: -25px;
  left: 65px;
}

.step:first-child::before {
  display: none;
}

.step:last-child::after {
  display: none;
}

.App {
  margin-top: 100px;
  margin-bottom: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.steps-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
}

.svg1 svg {
  position: absolute;
  top: -25px;
  width: 200px;
  height: 200px;
  right: -125px;
}

.img4 {
  height: 68px;
  position: absolute;
  left: -23px;
  bottom: -28px;
}

.parent {
  position: relative;
}

.d-none-web {
  display: none;
}

@media only screen and (max-width: 768px) {
  .steps-container {
    display: grid;
    gap: 35px;
  }

  .img1 {
    height: 182px;
    position: absolute;
    top: -70px;
    left: 12px;
    transform: rotate(90deg);
  }

  .img2 {
    height: 182px;
    position: absolute;
    top: 60px;
    left: -32px;
    transform: rotate(90deg);
  }

  .img4 {
    height: 64px;
    position: absolute;
    left: -40px;
    top: -13px;
    transform: rotate(90deg);
  }

  .step-number,
  .step-number2 {
    margin-top: 0px;
    left: -101px;
    top: 0;
    bottom: 0;
    align-items: center;
    display: flex;
  }
}
@media only screen and (max-width: 485px) {
  .steps-container {
    display: grid;
    gap: 35px;
  }

  .img1 {
    display: none;
  }

  .img2 {
    display: none;
  }

  .img4 {
    display: none;
  }

  .step-number,
  .step-number2 {
    margin-top: 0px;
    left: -101px;
    top: 0;
    bottom: 0;
    align-items: center;
    display: flex;
  }
}


/*interface-content*/
.user-interface-content {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}
.interface-header{
  color: #FC9E1E;
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  font-family: Inter, sans-serif;
}
.interface-horizontal-line{
  margin-top: 10px;
  margin-bottom: 40px;
  border: none;
  width: 380px;
  height: 1.43px;
  background: linear-gradient(to left, rgba(252, 158, 30, 0), rgba(252, 158, 30, 1));
}
.interface-content{
  text-align: left;
  color: #fff;
  font-weight: 300;
  font-family: Inter, sans-serif;
  font-size: 22px;
  line-height: 30px;
}

.wallets-padding {
  padding-left: 156px;
  padding-right: 156px;
  margin-bottom: 120px;
}

.information-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.information-left-content{
  color: #fff;
  font-weight: 300;
  font-family: Inter, sans-serif;
  font-size: 22px;
  line-height: 30px;
  text-align: left;
  justify-self: start;
  align-items: start;
  padding-bottom: -100px;
}

.information-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  align-items: center;
}
.information-image{
  height: 400px;
  width: 100%;
}

@media (max-width: 1200px) {
  .wallets-padding {
    padding-left: 20px;
    padding-right: 20px;
  }

  .information-grid {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 700px) {
  .wallets-padding {
    padding-left: 20px;
    padding-right: 20px;
  }

  .information-grid {
    grid-template-columns: 1fr;
  }
}
.information-span-text{
  color: #FC9E1E;
  font-weight: 400; 
}
.information-image{
  padding-bottom: 0px;
}
/*more-users*/
.more-users-padding{
  padding-left: 156px;
  padding-right: 156px;
  margin-top: 300px;
}
.more-users-heading{
  color: #FC9E1E;
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  font-family: Inter, sans-serif;
}
.more-users-horizontal-line{
  margin-top: 10px;
  margin-bottom: 40px;
  border: none;
  width: 580px;
  height: 1.43px;
  background: linear-gradient(to left, rgba(252, 158, 30, 0), rgba(252, 158, 30, 1));
}
.more-users-bottom-content{
  text-align: left;
  color: #fff;
  font-weight: 300;
  font-family: Inter, sans-serif;
  font-size: 22px;
  line-height: 30px;
  padding-top: 30px;
}
@media (max-width: 800px){
  .more-users-padding {
    padding-left: 20px;
    padding-right: 20px;
  }
}

/*about-content*/
.about-content {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  padding-left: 153px;
  padding-right: 153px;
  margin-top: 80px;
}
.about-header{
  color: #FC9E1E;
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  font-family: Inter, sans-serif;
}
.about-bottom-content{
  text-align: left;
  color: #fff;
  font-weight: 300;
  font-family: Inter, sans-serif;
  font-size: 22px;
  line-height: 30px;
  padding-top: 30px;
}
.about-horizontal-line{
  margin-top: 10px;
  margin-bottom: 20px;
  border: none;
  width: 380px;
  height: 1.43px;
  background: linear-gradient(to left, rgba(252, 158, 30, 0), rgba(252, 158, 30, 1));
} 
.service-box{
  background: linear-gradient(
    to left, rgba(252, 158, 30, 0), rgba(128, 90, 30, 1));
 justify-content: center;
 align-items: center;
  padding-top: 42px;
  padding-bottom: 42px;
  border-radius: 10px;
  margin-top: 20px;
  width: 100%;
  height: auto;
  margin-bottom: 60px;
}
.service-content{
  text-align: start;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 300;
  font-family: Inter, sans-serif;
  font-size: 22px;
  line-height: 30px;
  padding-top: 30px;
  padding-left: 153px;
  padding-right: 80px;
}
.link-text {
  border-bottom: 2px solid #fff; 
  cursor: pointer; 
  transition: color 0.3s ease, border-bottom 0.3s ease;
}
@media (max-width: 500px){
  .service-content{
    padding-left: 20px;
    padding-right: 20px;
  }
  .about-content {
    padding-left: 20px;
    padding-right: 20px;
  }
}
