/* Developed by Praveenkumar.G */
@font-face {
    font-family: 'Heldane Display';
    src: url('/assets/fonts/Heldane-Display/heldane-display-web-bold.woff2') format('woff2'),
        url('/assets/fonts/Heldane-Display/heldane-display-web-bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Object Sans Heavy';
    src: url('/assets/fonts/Object-Sans-Heavy/ObjectSans-Heavy.woff2') format('woff2'),
        url('/assets/fonts/Object-Sans-Heavy/ObjectSans-Heavy.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Object Sans';
    src: url('/assets/fonts/Object-Sans-Regular/ObjectSans-Regular.woff2') format('woff2'),
        url('/assets/fonts/Object-Sans-Regular/ObjectSans-Regular.woff') format('woff');
    font-style: normal;
}



@font-face {
    font-family: 'Inter', sans-serif;
    src: url('/public/assets/fonts/inter/Inter-Regular.ttf');
    font-weight: normal;
}
@font-face {
    font-family: 'Inter', sans-serif;
    src: url('/public/assets/fonts/inter/Inter-Medium.ttf');
    font-weight: medium;
}
@font-face {
    font-family: 'Inter', sans-serif;
    src: url('/public/assets/fonts/inter/Inter-ExtraBold.ttf');
    font-weight: bold;
}

