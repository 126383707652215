.oms.Healthcare .section-1 .main-head-div {
  margin-bottom: 30px;
}
.marketting-team {
  width: 70%;
  margin: 0 auto;
}
.team-detail {
  text-align: center;
}
.marketting-team p {
  line-height: 33px;
  color: #1E4B5E;
  font-size: 20px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.team-detail p b {
  color: #24A989;
  font-size: 25px;
  font-family: 'Object Sans Heavy';
  font-weight: 800;
}
.marketting-team p small {
  font-family: 'Object Sans';
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 125%;
  color: #1E4B5E;
}
.team-detail p {
  font-family: 'Object Sans';
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #1E4B5E;
  font-style: normal;
  margin: 8px 0 0 0;
  filter: none;
}
.team-detail a em {
  font-family: 'Object Sans Heavy';
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  text-decoration-line: underline;
  color: #007CC1;
  margin: 25px 0 0 0;
  display: block;
}
.team-detail a em:hover {
  cursor: pointer;
}
.marketting-team p.semi-colon {
  padding: 0 34px;
  text-align: justify;
}
.marketting-team p.semi-colon img {
  position: absolute;
  left: 0;
  top: 0;
  width: 22px;
}
.marketting-team p.semi-colon img:last-child {
  right: 0;
  left: auto;
}
.Enterprise.Healthcare.oms .section-1::before {
  background-image: url("/assets/img/oms/background-banner.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.Healthcare.oms .section-2 .content-section .solution-list strong {
  color: #208F71;
  font-size: 22px;
  line-height: 30px;
  margin-top: 22px;
}
.Healthcare.oms br {
  display: block!important;
}
.Healthcare.oms .section-2 .content-section .solution-list span {
  color: #4A4A4A;
  font-size: 16px;
  max-width: 260px;
  min-width: 260px;
  min-height: 90px;
}
.Healthcare.oms .section-2 .content-section .solution-list .solution-list-item {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px 0;
  align-items: center;
  padding: 20px 0;
}
.Healthcare.oms .section-3 .content-section .solution-list:nth-child(4),
.Healthcare.oms .section-3 .content-section .solution-list:nth-child(5),
.Healthcare.oms .section-3 .content-section .solution-list:nth-child(6) {
  margin-bottom: 0;
}
.Healthcare.oms .section-2 .content-section .solution-list {
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.15));
  border-radius: 0 30px 0 30px;
  max-width: 29%;
  margin-right: 15px;
  margin-left: 20px;
  background-color: #fff;
  position: relative;
}
.Healthcare.oms .section-2 .content-section .solution-list:before {
  content: '';
  width: 96%;
  height: 97%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #f9f9f9;
  z-index: -1;
  border-radius: 0 30px;
}
.Healthcare.oms .section-3 .content-section .solution-list:before {
  display: none;
}
.Healthcare.oms .section-2 .content-section {
  align-items: unset;
  width: 100%;
  justify-content: center;
}
.Healthcare.oms .section-2.section-3 .content-section {
  align-items: flex-start;
  width: 100%;
  justify-content: center;
}
.Healthcare.oms .section-2 .content-section .solution-list .solution-list-item img {
  width: 70px;
}
.Healthcare.oms .section-3 .main-head-1.main-head-2 .primary-span {
  color: #fff;
}
.Healthcare.oms .section-3 .main-head-1.main-head-2 span.secondary-span {
  color: #FFCC4A;
}
.Healthcare.oms .section-3 .content-section .solution-list i {
  border-radius: 20px;
  background: #FFFFFF;
  border: 4px solid #FFCC4A;
  box-shadow: 0px 0px 14px;
  height: 80px;
  width: 80px;
  padding: 15px;
  margin: auto;
}
.checking-lists {
  padding-left: 15px;
  width: 100%;
}
.Healthcare.oms .section-3 .content-section .solution-list i img {
  width: 60px;
  object-fit: contain;
}
.Healthcare.oms .section-3 .content-section .solution-list {
  margin-top: 50px;
  margin-bottom: 30px;
  box-shadow: none;
  background: transparent;
  align-items: flex-start;
  justify-content: flex-start;
}
.Healthcare.oms .section-3 .content-section .solution-list strong {
  color: #fff;
  margin: 15px 0px 10px;
  display: block;
  font-size: 18px;
}
.Healthcare.oms .section-3 .content-section .solution-list p {
  color: #fff;
  font-size: 16px;
}
.Healthcare.oms .section-4 .content-section {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
}
.Enterprise.Healthcare.oms .section-4 {
  background: linear-gradient(90deg, #FFFBF1 0%, #F9F9F9 50%);
  border-radius: 10px;
  width: 90%;
  margin: 0px auto;
  padding: 70px 0 50px;
}
.Healthcare.oms .section-4 .content-section .checking-lists p,
.Healthcare.oms .section-7 .content-section .checking-lists p {
  position: relative;
  padding-left: 22px;
  font-family: 'Object Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 40px;
  color: #1E4B5E;
}
.Healthcare.oms .section-4 .content-section .checking-lists p:before,
.Healthcare.oms .section-7 .content-section .checking-lists p:before {
  content: '';
  width: 10px;
  height: 10px;
  display: block;
  position: absolute;
  left: 0;
  top: 14px;
  background: #FFCC4A;
  border-radius: 100px;
  bottom: 3px;
}
.delivering-result img {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #43587C;
  border-radius: 20px;
}
.Healthcare.oms .section-5 .compilance-section .compilance-list i {
  display: block;
  width: 100%;
  text-align: center;
}
.Enterprise.Healthcare.oms .custom-container .left-info-hold .main-head-1.main-head-2 .secondary-span {
  margin-top: 18px;
}
.Healthcare.oms .section-5 .compilance-section .compilance-list span {
  display: block;
  width: 100%;
  margin-top: 20px;
  font-size: 22px;
}
.Healthcare.oms .section-5 .compilance-section .compilance-list span a {
  display: block;
}
.Healthcare.oms .section-5 .compilance-section .compilance-list {
  flex-flow: row wrap;
}
.Healthcare.oms .section-7 .content-section,
.Healthcare.oms .section-8 .content-section,
.Healthcare.oms .section-5 .compilance-section,
.Healthcare.oms .section-3 .content-section {
  width: 100%;
}
.open-architechture {
  display: flex;
  align-items: center;
  position: relative;
}
.live-market {
  border-radius: 20px 120px 120px 20px;
  text-align: center;
  background: #FAFAFF;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  padding: 22px;
  width: 17%;
}
.integration-steps {
  width: 83%;
  display: flex;
}
.live-market img {
  width: 100px;
  margin-top: 20px;
}
.live-market h5 {
  font-family: 'Object Sans';
  font-style: normal;
  font-weight: 800;
  font-size: 26px;
  line-height: 32px;
  color: #1E4B5E;
  margin: 13px 0 10px 0;
  text-align: center;
  width: 100%;
}
.live-market p {
  margin: 0;
  padding: 0 10px 20px 0px;
  font-family: 'Object Sans';
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 21px;
  text-align: center;
  color: #1E4B5E;
}
.integration-steps ul {
  padding: 0;
  list-style: none;
  flex-flow: row wrap;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
.integration-steps ul li {
  width: 25%;
  background: #FAFAFF;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 10px 0;
  font-family: 'Object Sans';
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 22px;
  height: 200px;
  text-align: center;
  color: #1E4B5E;
  display: flex;
  flex-direction: column;
  padding: 30px;
  position: relative;
  margin: 20px;
  justify-content: center;
}
.integration-steps ul li span {
  position: absolute;
  right: -20px;
  background: #FFCC4A;
  width: 50px;
  top: -10px;
  height: 50px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.integration-steps ul li:nth-child(1),
.integration-steps ul li:nth-child(2),
.integration-steps ul li:nth-child(3) {
  margin-bottom: 80px;
}
.integration-steps ul li:nth-child(1):before,
.integration-steps ul li:nth-child(2):before,
.integration-steps ul li:nth-child(4):before,
.integration-steps ul li:nth-child(5):before {
  content: '';
  position: absolute;
  right: -50%;
  width: 50%;
  height: 1px;
  background: #cdcbcb;
}
.dots:before {
  content: '';
  position: absolute;
  width: 85%;
  height: 1px;
  border: 1px dashed #1E4B5E;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
span.circle {
  display: block;
  width: 10px;
  height: 10px;
  background: #1E4B5E;
  border-radius: 100px;
  margin-left: 16%;
}
.dots {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 1px;
  display: flex;
  align-items: center;
}
.dots label {
  width: 23%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
}
.dots label:nth-child(1) {
  margin-left: 26px;
}
.dots label:nth-child(2) {
  margin-left: 65px;
}
.dots label:nth-child(3) {
  padding-left: 110px;
}
.dots label:nth-child(4) {
  position: absolute;
  right: 0;
  width: 36%;
}
.Healthcare.oms .section-6 {
  padding-bottom: 80px;
}
.Healthcare.oms .section-2 {
  padding-bottom: 70px;
}
.Healthcare.oms .section-3 {
  padding-bottom: 100px;
}
.Healthcare.oms .section-2 .content-section .solution-list .oms-img-tag {
  min-height: 90px;
  display: block;
  margin-top: 15px;
}
.Healthcare.oms .section-2 .content-section .solution-list:nth-of-type(4),
.Healthcare.oms .section-2 .content-section .solution-list:nth-of-type(5) {
  margin-bottom: 50px;
}
.oms-trading-software .main-head-1 .primary-span {
  color: #fff;
}
.Enterprise.Healthcare.oms .section-5 .faq.main-head-1 .primary-span,
.Enterprise.Healthcare.oms .order-management-system .main-head-1 .primary-span,
.Healthcare .main-head-1.main-head-2 span.primary-span.new-heading1 {
  color: #fff;
}
.oms .faq-listwrp .faq .faq-list .summary .MuiAccordionSummary-content .summary-content,
.oms .faq-listwrp .faq .faq-list .faq-details P {
  color: #fff;
}
.order-management-system .oms-list.why-oms strong {
  font-size: 19px;
  font-family: "Object sans heavy";
  text-align: left;
}
.order-management-system .oms-list.why-oms p {
  text-align: left;
  max-width: 95%;
}
.order-management-system .oms-list.why-oms .why-list {
  margin-bottom: 20px;
  flex: auto;
}
.order-management-system .oms-list.why-oms .why-list:after {
  border-right: 1px solid #dddddd2b;
  margin: auto;
  content: "";
  width: 1px;
  height: 70%;
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  border-radius: 50%;
}
.order-management-system .oms-list.why-oms .why-list:last-child:after {
  display: none;
}
@media only screen and (max-width: 1280px) {
  .Healthcare.oms .section-2 .content-section .solution-list span {
    max-width: 100%;
  }
  .Healthcare.oms .section-2 .content-section .solution-list strong {
    font-size: 18px;
  }
}
@media screen and (max-width: 1024px) and (min-width: 320px) {
  .Healthcare.oms .section-3 .content-section .solution-list strong,
  .Healthcare.oms .section-5 .compilance-section .compilance-list span {
    font-size: 16px;
    line-height: 24px;
  }
  .Healthcare.oms br {
    display: none!important;
  }
  .Healthcare.oms .section-3 .content-section .solution-list strong {
    font-size: 20px;
  }
  .Healthcare.oms .section-5 .compilance-section {
    flex-flow: row wrap;
    margin-bottom: 50px;
  }
  .Healthcare.oms .section-5 .compilance-section .compilance-list:last-child {
    margin-bottom: 0;
  }
  .integration-steps ul li {
    font-size: 14px;
  }
  .Healthcare.oms .section-3 .content-section .solution-list i img {
    width: 60px;
  }
}
@media only screen and (max-width: 991px) {
  .order-management-system .oms-list.why-oms .why-list:after {
    display: none;
  }
  .order-management-system .oms-list.why-oms .why-list p {
    max-width: 100%;
  }
}
@media only screen and (max-width: 991px) and (min-width: 320px) {
  .Healthcare.oms .section-2 .content-section .solution-list {
    max-width: 50%;
  }
  .Enterprise.Healthcare.oms .section-1 .main-para-div.short-div p br {
    display: none!important;
  }
  .Enterprise.Healthcare.oms .section-2,
  .Enterprise.Healthcare.oms .section-3,
  .Enterprise.Healthcare.oms .section-4,
  .Enterprise.Healthcare.oms .section-5,
  .Enterprise.Healthcare.oms .section-6,
  .Enterprise.Healthcare.oms .section-7 {
    padding: 40px 0 40px!important;
  }
  .Healthcare.oms .section-7 .main-head-div {
    margin-bottom: 10px;
  }
  .Healthcare.oms .section-3 .content-section .solution-list:nth-child(4),
  .Healthcare.oms .section-3 .content-section .solution-list:nth-child(5),
  .Healthcare.oms .section-3 .content-section .solution-list:nth-child(6) {
    margin-bottom: 20px;
  }
  .Enterprise.Healthcare.oms .section-1 {
    padding-bottom: 30px;
  }
  .Healthcare.oms .section-4 .work-with-col {
    padding: 0;
  }
  .Healthcare.oms .section-4 .content-section {
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
  }
  .checking-lists {
    width: 100%;
    margin: 20px auto 0 auto;
    padding: 0;
  }
  .oms.Healthcare .section-7 .checking-lists {
    padding-left: 15px;
  }
  .Healthcare.oms .section-5 .compilance-section .compilance-list {
    margin-bottom: 30px;
  }
  .open-architechture {
    flex-flow: wrap;
  }
  .live-market {
    width: 30%;
    margin: 0 auto;
    border-radius: 20px 20px 120px 120px;
    padding: 0px 0 20px 0;
  }
  .integration-steps {
    width: 100%;
  }
  .dots {
    display: none;
  }
  .Healthcare.oms .section-3 .content-section .solution-list {
    max-width: 43%;
    margin: 30px auto;
  }
  .Healthcare.oms .section-3 .content-section .solution-list strong {
    font-size: 18px;
  }
  .order-management-system .oms-list {
    flex-direction: column;
  }
  .order-management-system .oms-list > div {
    width: 100%;
    max-width: 100%;
  }
  .order-management-system .oms-list.why-oms strong {
    font-size: 18px;
  }
}
@media only screen and (max-width: 767px) and (min-width: 320px) {
  .Healthcare.oms .section-7 .content-section,
  .Healthcare.oms .section-8 .content-section,
  .Healthcare.oms .section-5 .compilance-section,
  .Healthcare.oms .section-3 .content-section {
    flex-flow: wrap;
  }
  .Healthcare.oms .section-2 .content-section .solution-list span {
    width: 80%;
  }
  .Healthcare.oms .section-7 .content-section,
  .Healthcare.oms .section-8 .content-section,
  .Healthcare.oms .section-5 .compilance-section,
  .Healthcare.oms .section-3 .content-section {
    flex-flow: wrap;
  }
  .marketting-team p.semi-colon {
    font-size: 16px;
    line-height: 22px;
  }
  .team-detail p b {
    font-size: 20px;
  }
  .marketting-team p small {
    font-size: 14px;
  }
  .team-detail p {
    font-size: 14px;
    line-height: 20px;
  }
  .Healthcare.oms .section-2 .marketting-team {
    width: 90%;
  }
  .team-detail {
    width: 76%;
    margin: 0 auto;
  }
  .team-detail a em {
    font-size: 15px;
  }
  .Healthcare.oms .section-2 .content-section .solution-list {
    max-width: 90%;
  }
  .Healthcare.oms .section-2 .content-section .solution-list {
    margin: 30px auto;
  }
  .Healthcare.oms .section-3 .content-section .solution-list i img {
    width: 50px;
  }
  .Healthcare.oms .section-3 .content-section .solution-list {
    flex-flow: wrap;
    margin: 20px 0;
  }
  .Healthcare.oms .section-3 .content-section .solution-list strong,
  .Healthcare.oms .section-5 .compilance-section .compilance-list span {
    margin-top: 20px;
    text-align: center;
    font-size: 18px;
    line-height: 24px;
  }
  .Healthcare.oms .section-4 .content-section .checking-lists p,
  .Healthcare.oms .section-7 .content-section .checking-lists p {
    font-size: 15px;
    line-height: 25px;
  }
  .Healthcare.oms .section-4 .content-section .checking-lists p:before,
  .Healthcare.oms .section-7 .content-section .checking-lists p:before {
    top: 6px;
  }
  .live-market {
    width: 220px;
  }
  .integration-steps ul li,
  .integration-steps ul li:nth-child(1),
  .integration-steps ul li:nth-child(2),
  .integration-steps ul li:nth-child(3) {
    width: 85%;
    margin: 30px auto;
    height: auto;
    margin-top: 50px;
  }
  .Healthcare.oms .section-4 .content-section .checking-lists {
    width: 100%;
    margin: 40px auto 0;
  }
  .integration-steps ul li:before {
    right: auto;
    bottom: -30px;
    width: auto;
    height: 40%;
  }
  .integration-steps ul li:nth-child(1):before,
  .integration-steps ul li:nth-child(2):before,
  .integration-steps ul li:nth-child(4):before,
  .integration-steps ul li:nth-child(5):before {
    display: none;
  }
  .integration-steps:before {
    content: "";
    position: absolute;
    width: 1px;
    height: 100vh;
    border: 1px dashed #1e4b5e;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
  .integration-steps:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 1px;
    height: 60vh;
    border: 1px dashed #1e4b5e;
    bottom: 50px;
    left: 0;
    right: 0;
    margin: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
  .integration-steps ul li span {
    position: absolute;
    background: #ffcc4a;
    width: 50px;
    left: 0;
    right: 0;
    margin: auto;
    top: -30px;
    height: 50px;
    border-radius: 100px;
    -webkit-align-items: center;
    align-items: center;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }
  .integration-steps {
    position: relative;
  }
  .Healthcare.oms .section-2 .content-section .solution-list span {
    width: 80%;
  }
  .Healthcare.oms .section-2 .content-section .solution-list strong br {
    display: block!important;
  }
}
@media only screen and (max-width: 375px) and (min-width: 320px) {
  .Healthcare.oms .main-head-1.main-head-2 .primary-span {
    font-size: 28px;
  }
  .Enterprise.Healthcare.oms .custom-container .left-info-hold .main-head-1.main-head-2 .secondary-span {
    font-size: 18px;
  }
  .Healthcare.oms .section-2 .content-section .solution-list strong {
    font-size: 18px;
    margin: 16px auto;
    line-height: 24px;
  }
  .Healthcare.oms .section-2 .content-section .solution-list span {
    width: 90%;
    font-size: 15px;
  }
}
.oms-trading-software {
  background: linear-gradient(99.45deg, #0E2E40 -9.39%, #145554 28.48%, #208F71 100%);
  padding: 75px 0px;
  text-align: center;
  color: #FFFFFF;
}
.oms-trading-software p {
  max-width: 75%;
  margin: 0px auto;
}
.Enterprise.Healthcare.oms .section-5 .main-head-1 {
  text-align: center;
}
.order-management-system {
  background: linear-gradient(99.45deg, #0E2E40 -9.39%, #145554 28.48%, #208F71 100%);
  padding: 75px 0px;
  text-align: center;
  color: #FFFFFF;
}
.order-management-system h2.main-head-1 {
  font-size: 36px;
  font-weight: 700;
  color: #24A989;
}
.order-management-system .oms-list {
  display: flex;
}
.order-management-system .oms-list strong {
  font-size: 26px;
  margin-bottom: 20px;
  display: block;
}
