.section-1.design-page {
  background-image: url(/assets/img/design-img/design-bg.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
}
#discovery .main-head-div {
  margin: 0px 15px 30px;
}
#discovery .main-head-div .img-width {
  transform: scale(0.9);
  margin-top: -100px;
}
.work-p {
  font-family: 'Object Sans Heavy';
  font-size: 24px;
  line-height: 30px;
  display: block;
  margin: 50px 0px 10px;
  color: #1E4B5E;
}
.step-div h6,
.step-div h3 {
  font-size: 16px;
  line-height: 28px;
  color: #7E97A2;
  font-family: 'Object Sans Heavy';
  margin-bottom: 0px;
}
.design-section .step-div.step-count h6,
.design-section .step-div.step-count h3 {
  font-family: "Heldane Display";
  color: #1E4B5E;
  font-size: 36px;
  line-height: 45px;
  margin: 15px 15px 20px;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
}
.design-section .step-div.step-count h6:after,
.design-section .step-div.step-count h3:after {
  content: "";
  width: 42px;
  height: 42px;
  background: #FFCC4A;
  border-radius: 50%;
  position: absolute;
  left: -15px;
  z-index: -1;
  border: 2px solid #FFCC4A;
}
.highlight-blue h4 {
  font-size: 30px;
  line-height: 37px;
  color: #24A989;
  font-family: 'Heldane Display';
  font-weight: bold;
}
.Design-content .img-width {
  width: 80% !important;
}
.Design-content .scrollable-container {
  /*overflow-y: scroll;*/
  /*scroll-snap-type: y mandatory;*/
  width: 100%;
  scroll-behavior: smooth;
  padding: 0;
}
.Design-content .sticky-title {
  position: -webkit-sticky;
  position: sticky;
  top: 90px;
  background-color: transparent;
  padding: 0;
  z-index: 15;
  font-size: 20px;
}
.Design-content .progressbar-container {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
  display: inline-flex;
  align-items: center;
  height: 80vh;
}
.Design-content ul.progressbar {
  list-style-type: none;
}
.Design-content ul.progressbar li {
  position: relative;
}
.Design-content ul.progressbar li .line-style {
  position: relative;
}
.Design-content .circle {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  padding-top: 2px;
  justify-content: center;
  border-radius: 15px;
  background-color: #C7D0D3;
  transition: all 0.7s ease;
  position: relative;
  left: 0;
  z-index: 1;
  border-radius: 50%;
}
.Design-content .progressbar-active .circle {
  background-color: #FFCC4A;
  width: 60px;
  left: -15px;
  height: 60px;
  transition: all 0.7s ease;
}
.Design-content .progressbar-visited .circle {
  background-color: #FFCC4A;
  transition: all 0.7s ease;
}
.Design-content .progressbar-text {
  text-align: center;
  color: white;
}
.Design-content .circle {
  text-align: center;
  font-size: large;
  line-height: 30px;
  transition: all 0.7s ease;
}
.Design-content .circle.text-white {
  color: #7E97A1 !important;
  cursor: pointer;
  font-family: 'Object Sans Heavy';
  font-size: 14px;
}
.Design-content .progressbar-visited .circle.text-white.text-grey {
  color: #1E4B5E !important;
}
.Design-content .progressbar-active .circle.text-white {
  color: #1E4B5E !important;
  font-size: 24px;
}
.Design-content ul.progressbar li:nth-child(4) .line-style {
  display: none;
}
.line-style {
  z-index: -1;
  background-color: #c7d0d3;
}
.line-style::after {
  background: #FFCC4A;
  margin-left: 13px;
  height: 0px;
  width: 2px;
  content: '';
  position: absolute;
  left: -13px;
}
.progressbar-visited .line-style::after {
  -webkit-animation: 0.9s forwards progresstransition;
  animation: 0.9s forwards progresstransition;
}
@-webkit-keyframes progresstransition {
  from {
    opacity: 0;
    height: 0;
  }
  to {
    opacity: 1;
    height: 50px;
  }
}
@keyframes progresstransition {
  from {
    opacity: 0;
    height: 0;
  }
  to {
    opacity: 1;
    height: 50px;
  }
}
@media only screen and (max-width: 1280px) {
  .Design-content .company-info .highlight-text h4 span {
    background-position: 0px 23px;
    background-size: 40px 10px;
    background-repeat: repeat-x;
  }
}
@media only screen and (max-width: 425px) {
  #discovery .main-head-div {
    margin: 0px 0px 30px ;
  }
}
@media only screen and (max-width: 768px) and (min-width: 576px) {
  .Design-content .overal-sec {
    padding: 0px;
  }
}
