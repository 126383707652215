.oms.Healthcare .section-1 .main-head-div {
  margin-bottom: 30px;
}
.marketting-team {
  width: 70%;
  margin: 0 auto;
}
.team-detail {
  text-align: center;
}
.marketting-team p {
  line-height: 33px;
  color: #1E4B5E;
  font-size: 20px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.team-detail p b {
  color: #24A989;
  font-size: 25px;
  font-family: 'Object Sans Heavy';
  font-weight: 800;
}
.marketting-team p small {
  font-family: 'Object Sans';
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 125%;
  color: #1E4B5E;
}
.team-detail p {
  font-family: 'Object Sans';
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #1E4B5E;
  font-style: normal;
  margin: 8px 0 0 0;
  filter: none;
}
.team-detail a em {
  font-family: 'Object Sans Heavy';
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  text-decoration-line: underline;
  color: #007CC1;
  margin: 25px 0 0 0;
  display: block;
}
.team-detail a em:hover {
  cursor: pointer;
}
.marketting-team p.semi-colon {
  padding: 0 34px;
  text-align: justify;
}
.marketting-team p.semi-colon img {
  position: absolute;
  left: 0;
  top: 0;
  width: 22px;
}
.marketting-team p.semi-colon img:last-child {
  right: 0;
  left: auto;
}
.Enterprise.Healthcare.post-trade .section-1 {
  background: #FFF !important;
  text-align: left;
}
.Enterprise.Healthcare.post-trade .section-1::before {
  display: none;
}
.Enterprise.Healthcare.post-trade .section-1 .inner-content {
  display: flex;
  justify-content: space-between;
}
.Enterprise.Healthcare.post-trade .section-1 .inner-content .right-section img {
  width: 100%;
}
.Enterprise.Healthcare.post-trade .section-1 .main-head-1 .primary-span {
  text-align: left;
  color: #1C4758;
  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.Enterprise.Healthcare.post-trade .section-1 .main-head-1 .secondary-span {
  color: #208F71;
}
.Enterprise.Healthcare.post-trade .section-1 .main-para-div.short-div p {
  color: #1D485A;
}
.Enterprise.Healthcare.post-trade .section-1 .main-para-div a.connect-btn.btn-primary {
  color: #fff !important;
}
.Enterprise.Healthcare.oms.post-trade .section-1 .main-para-div.short-div p {
  margin-bottom: 30px;
}
.Enterprise.Healthcare.oms.post-trade .section-1 .top-list {
  display: flex;
  list-style-type: none;
  width: 70%;
  margin: auto;
  justify-content: space-between;
}
.Enterprise.Healthcare.oms.post-trade .section-1 .top-list li {
  color: #fff;
  position: relative;
  padding-left: 20px;
}
.Enterprise.Healthcare.oms.post-trade .section-1 .top-list li::before {
  position: absolute;
  content: "";
  left: 0;
  top: 6px;
  margin: auto;
  width: 15px;
  height: 15px;
  background: url('/assets/img/post-trade/tick.png') no-repeat;
}
.Healthcare.oms.post-trade .section-2 .content-section .solution-list strong {
  color: #208F71;
  font-size: 22px;
  line-height: 30px;
  margin-top: 22px;
}
.Healthcare.oms br {
  display: block!important;
}
.Healthcare.oms.post-trade .section-2 .content-section .solution-list span {
  color: #4A4A4A;
  font-size: 16px;
  max-width: 260px;
  min-width: 260px;
  min-height: 90px;
}
.Healthcare.oms.post-trade .section-2 .content-section .solution-list .solution-list-item {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px 0;
  align-items: center;
  padding: 20px 0;
}
.Healthcare.oms .section-3 .content-section .solution-list:nth-child(4),
.Healthcare.oms .section-3 .content-section .solution-list:nth-child(5),
.Healthcare.oms .section-3 .content-section .solution-list:nth-child(6) {
  margin-bottom: 0;
}
.Healthcare.oms.post-trade .section-2 .content-section .solution-list {
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.15));
  border-radius: 0 30px 0 30px;
  max-width: 29%;
  margin-right: 15px;
  margin-left: 20px;
  background-color: #fff;
  position: relative;
}
.Healthcare.oms.post-trade .section-2 .content-section .solution-list:before {
  content: '';
  width: 96%;
  height: 97%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #f9f9f9;
  z-index: -1;
  border-radius: 0 30px;
}
.Healthcare.oms.post-trade .section-2.integrated-system {
  background: linear-gradient(135deg, #0E2E40 0%, #145554 42.89%, #208F71 100%);
}
.Healthcare.oms.post-trade .section-2 .content-section .solution-list:before {
  display: none;
}
.Healthcare.oms.post-trade .section-2 .content-section {
  align-items: unset;
  width: 100%;
  justify-content: center;
}
.Healthcare.oms.post-trade .section-2 .content-section .solution-list .solution-list-item img {
  width: 70px;
}
.Healthcare.oms.post-trade .section-2 .main-head-1.main-head-2 .primary-span {
  color: #FFCC4A;
}
.Healthcare.oms.post-trade .section-2 .main-head-1.main-head-2 span.secondary-span {
  color: #fff;
  font-size: 28px;
}
.Healthcare.oms.post-trade .section-2 .content-section .solution-list i {
  border-radius: 20px;
  background: #FFFFFF;
  border: 4px solid #FFCC4A;
  box-shadow: 0px 0px 14px;
}
.checking-lists {
  padding-left: 15px;
  width: 100%;
}
.Healthcare.oms.post-trade .section-2 .content-section .solution-list i img {
  width: 60px;
}
.Healthcare.oms.post-trade .section-2 .content-section .solution-list {
  flex-wrap: nowrap;
  margin-top: 50px;
  margin-bottom: 30px;
  box-shadow: none;
  background: transparent;
}
.Healthcare.oms.post-trade .section-2 .content-section .solution-list strong {
  color: #fff;
  text-align: left;
  margin: 0 10px 0 20px;
}
.Healthcare.oms .section-4 .content-section {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
}
.Enterprise.Healthcare.oms.post-trade .section-4 {
  background: #fff;
  border-radius: 10px;
  width: 100%;
  margin: 0px;
  padding: 70px 0 50px;
}
.Enterprise.Healthcare.oms.post-trade .section-4 img {
  width: 80%;
  margin: 0px auto;
}
.Enterprise.Healthcare.oms.post-trade .section-4 img.mob-img {
  display: none;
}
.Healthcare.oms .section-4 .content-section .checking-lists p,
.Healthcare.oms .section-7 .content-section .checking-lists p {
  position: relative;
  padding-left: 22px;
  font-family: 'Object Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 40px;
  color: #1E4B5E;
}
.Healthcare.oms .section-4 .content-section .checking-lists p:before,
.Healthcare.oms .section-7 .content-section .checking-lists p:before {
  content: '';
  width: 10px;
  height: 10px;
  display: block;
  position: absolute;
  left: 0;
  top: 14px;
  background: #FFCC4A;
  border-radius: 100px;
  bottom: 3px;
}
.delivering-result img {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #43587C;
  border-radius: 20px;
}
.Healthcare.oms .section-5 .compilance-section .compilance-list i {
  display: block;
  width: 100%;
  text-align: center;
}
.Enterprise.Healthcare.oms .custom-container .left-info-hold .main-head-1.main-head-2 .secondary-span {
  margin-top: 18px;
}
.Healthcare.oms .section-5 .compilance-section .compilance-list span {
  display: block;
  width: 100%;
  margin-top: 20px;
  font-size: 22px;
}
.Healthcare.oms .section-5 .compilance-section .compilance-list span a {
  display: block;
}
.Healthcare.oms .section-5 .compilance-section .compilance-list {
  flex-flow: row wrap;
}
.Healthcare.oms .section-7 .content-section,
.Healthcare.oms .section-8 .content-section,
.Healthcare.oms .section-5 .compilance-section,
.Healthcare.oms .section-3 .content-section {
  width: 100%;
}
div.Healthcare.oms.post-trade .section-2.integrated-system .main-head-div .secondary-span {
  color: #fff;
}
div.Healthcare.oms.post-trade .section-2.integrated-system .main-head-div .primary-span {
  color: #FFCC4A;
  margin-bottom: 30px;
}
div.Healthcare.oms.post-trade .section-2.integrated-system .integrated-system-list {
  border-radius: 15px;
  background: linear-gradient(90deg, #FFFBF1 0%, #F9F9F9 100%);
  padding: 30px 40px;
  width: 47%;
  max-width: 47%;
  text-align: center;
}
div.Healthcare.oms.post-trade .section-2.integrated-system .integrated-system-list b {
  display: block;
  margin-bottom: 15px;
  color: #1E4B5E;
  font-family: "Object Sans Heavy";
}
div.Healthcare.oms.post-trade .section-2.integrated-system .integrated-system-list span {
  color: #4A4A4A;
}
div.Healthcare.oms.post-trade .section-2.integrated-system .integrated-system-list:nth-of-type(odd) {
  margin-right: 6%;
}
div.Healthcare.oms.post-trade .section-2.integrated-system .integrated-system-list:nth-of-type(1),
div.Healthcare.oms.post-trade .section-2.integrated-system .integrated-system-list:nth-of-type(2) {
  margin-bottom: 4%;
}
div.Healthcare.oms .section-6.market-exposure .main-head-div .secondary-span {
  color: #fff;
}
div.Healthcare.oms .section-6.market-exposure .main-head-div .primary-span {
  color: #FFCC4A;
  margin-bottom: 30px;
}
div.Healthcare.oms .section-6.market-exposure .integrated-system-list {
  border-radius: 15px;
  background: linear-gradient(90deg, #FFFBF1 0%, #F9F9F9 100%);
  padding: 30px 40px;
  width: 32%;
  max-width: 32%;
  text-align: center;
  margin-right: 2%;
}
div.Healthcare.oms .section-6.market-exposure .integrated-system-list b {
  display: block;
  margin-bottom: 15px;
  color: #1E4B5E;
  font-family: "Object Sans Heavy";
}
div.Healthcare.oms .section-6.market-exposure .integrated-system-list span {
  color: #4A4A4A;
}
div.Healthcare.oms .section-6.market-exposure .integrated-system-list:nth-of-type(1),
div.Healthcare.oms .section-6.market-exposure .integrated-system-list:nth-of-type(2) {
  margin-bottom: 4%;
}
div.Healthcare.oms.post-trade .section-6.market-exposure .main-head-div .secondary-span {
  color: #1E4B5E;
}
div.Healthcare.oms.post-trade .section-6.market-exposure .main-head-div .primary-span {
  color: #208F71;
  margin-bottom: 30px;
}
div.Healthcare.oms .section-6.market-exposure .integrated-system-list {
  background: transparent;
}
div.Healthcare.oms .section-6.market-exposure .integrated-system-list b {
  margin-top: 15px;
}
.Healthcare.oms .section-5.trade-operation,
.Healthcare.oms .section-7.trade-operation,
.Healthcare.oms .section-10.trade-operation,
.Healthcare.oms .section-8.trade-operation,
.Healthcare.oms .section-9.trade-operation {
  background: linear-gradient(90deg, #FFFBF1 0%, #F9F9F9 100%);
}
.Healthcare.oms .section-5.trade-operation .main-head-div,
.Healthcare.oms .section-7.trade-operation .main-head-div,
.Healthcare.oms .section-10.trade-operation .main-head-div,
.Healthcare.oms .section-8.trade-operation .main-head-div,
.Healthcare.oms .section-9.trade-operation .main-head-div {
  margin-bottom: 30px;
}
.Healthcare.oms .section-5.trade-operation .integrated-system-list b,
.Healthcare.oms .section-7.trade-operation .integrated-system-list b,
.Healthcare.oms .section-10.trade-operation .integrated-system-list b,
.Healthcare.oms .section-8.trade-operation .integrated-system-list b,
.Healthcare.oms .section-9.trade-operation .integrated-system-list b {
  color: #1E4B5E;
  font-family: "Object sans heavy";
  font-size: 26px;
}
.Healthcare.oms .section-5.trade-operation .integrated-system-list ul,
.Healthcare.oms .section-7.trade-operation .integrated-system-list ul,
.Healthcare.oms .section-10.trade-operation .integrated-system-list ul,
.Healthcare.oms .section-8.trade-operation .integrated-system-list ul,
.Healthcare.oms .section-9.trade-operation .integrated-system-list ul {
  padding: 0px;
  list-style-type: none;
  margin: 15px 0px;
}
.Healthcare.oms .section-5.trade-operation .integrated-system-list ul li,
.Healthcare.oms .section-7.trade-operation .integrated-system-list ul li,
.Healthcare.oms .section-10.trade-operation .integrated-system-list ul li,
.Healthcare.oms .section-8.trade-operation .integrated-system-list ul li,
.Healthcare.oms .section-9.trade-operation .integrated-system-list ul li {
  position: relative;
  padding: 10px 5px 10px 25px;
}
.Healthcare.oms .section-5.trade-operation .integrated-system-list ul li::before,
.Healthcare.oms .section-7.trade-operation .integrated-system-list ul li::before,
.Healthcare.oms .section-10.trade-operation .integrated-system-list ul li::before,
.Healthcare.oms .section-8.trade-operation .integrated-system-list ul li::before,
.Healthcare.oms .section-9.trade-operation .integrated-system-list ul li::before {
  content: '';
  width: 14px;
  height: 14px;
  background: #1E4B5E;
  border: 4px solid #24A989;
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: 15px;
}
.Healthcare.oms .section-5.trade-operation .integrated-system-list strong,
.Healthcare.oms .section-7.trade-operation .integrated-system-list strong,
.Healthcare.oms .section-10.trade-operation .integrated-system-list strong,
.Healthcare.oms .section-8.trade-operation .integrated-system-list strong,
.Healthcare.oms .section-9.trade-operation .integrated-system-list strong {
  color: #208F71;
  font-family: "Object Sans Heavy";
  font-size: 18px;
}
.Healthcare.oms .section-5.trade-operation .integrated-system-list img,
.Healthcare.oms .section-7.trade-operation .integrated-system-list img,
.Healthcare.oms .section-10.trade-operation .integrated-system-list img,
.Healthcare.oms .section-8.trade-operation .integrated-system-list img,
.Healthcare.oms .section-9.trade-operation .integrated-system-list img {
  width: 100%;
}
.Healthcare.oms .section-10.trade-operation {
  background: #fff;
  padding: 0px 0px 100px;
}
.Healthcare.oms .section-10.trade-operation .custom-container {
  background: #fff;
}
.Healthcare.oms .section-10.trade-operation .custom-container .main-head-div .primary-span {
  color: #208F71;
}
.Healthcare.oms .section-10.trade-operation .custom-container .main-para-div {
  margin-top: 50px;
}
.Healthcare.oms .section-10.trade-operation .custom-container .main-para-div a.connect-btn.btn-primary {
  color: #fff !important;
}
div.Healthcare.oms .section-9.trade-operation {
  padding: 100px 0px 0px;
  background: #fff;
}
div.Healthcare.oms .section-9.trade-operation .custom-container {
  border-radius: 20px;
  background: linear-gradient(135deg, #0E2E40 0%, #145554 34.62%, #208F71 100%);
  padding: 50px 80px 50px;
}
div.Healthcare.oms .section-9.trade-operation .custom-container .main-head-div {
  justify-content: center;
}
div.Healthcare.oms .section-9.trade-operation .custom-container .do-more-list {
  background: #fff;
  padding: 50px;
  border-radius: 20px;
}
div.Healthcare.oms .section-9.trade-operation .custom-container .do-more-list .integrated-system-list:nth-of-type(1) {
  max-width: 40%;
  flex: 0 0 40%;
}
div.Healthcare.oms .section-9.trade-operation .custom-container .do-more-list .integrated-system-list:nth-of-type(1) ul {
  max-width: 85%;
}
div.Healthcare.oms .section-9.trade-operation .custom-container .do-more-list .integrated-system-list:nth-of-type(2) {
  max-width: 30%;
  flex: 0 0 30%;
}
div.Healthcare.oms .section-9.trade-operation .custom-container .do-more-list .integrated-system-list:nth-of-type(3) {
  max-width: 30%;
  flex: 0 0 30%;
}
div.Healthcare.oms .section-9.trade-operation .custom-container .do-more-list .integrated-system-list b {
  font-size: 21px;
}
.Healthcare.oms .section-8.trade-operation {
  clear: both;
}
.Healthcare.oms .section-8.trade-operation .left-info-hold {
  margin-left: 51%;
}
.open-architechture {
  display: flex;
  align-items: center;
  position: relative;
}
.live-market {
  border-radius: 20px 120px 120px 20px;
  text-align: center;
  background: #FAFAFF;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  padding: 22px;
  width: 17%;
}
.integration-steps {
  width: 83%;
  display: flex;
}
.live-market img {
  width: 100px;
  margin-top: 20px;
}
.live-market h5 {
  font-family: 'Object Sans';
  font-style: normal;
  font-weight: 800;
  font-size: 26px;
  line-height: 32px;
  color: #1E4B5E;
  margin: 13px 0 10px 0;
  text-align: center;
  width: 100%;
}
.live-market p {
  margin: 0;
  padding: 0 10px 20px 0px;
  font-family: 'Object Sans';
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 21px;
  text-align: center;
  color: #1E4B5E;
}
.integration-steps ul {
  padding: 0;
  list-style: none;
  flex-flow: row wrap;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
.integration-steps ul li {
  width: 25%;
  background: #FAFAFF;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 10px 0;
  font-family: 'Object Sans';
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 22px;
  height: 200px;
  text-align: center;
  color: #1E4B5E;
  display: flex;
  flex-direction: column;
  padding: 30px;
  position: relative;
  margin: 20px;
  justify-content: center;
}
.integration-steps ul li span {
  position: absolute;
  right: -20px;
  background: #FFCC4A;
  width: 50px;
  top: -10px;
  height: 50px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.integration-steps ul li:nth-child(1),
.integration-steps ul li:nth-child(2),
.integration-steps ul li:nth-child(3) {
  margin-bottom: 80px;
}
.integration-steps ul li:nth-child(1):before,
.integration-steps ul li:nth-child(2):before,
.integration-steps ul li:nth-child(4):before,
.integration-steps ul li:nth-child(5):before {
  content: '';
  position: absolute;
  right: -50%;
  width: 50%;
  height: 1px;
  background: #cdcbcb;
}
.dots:before {
  content: '';
  position: absolute;
  width: 85%;
  height: 1px;
  border: 1px dashed #1E4B5E;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
span.circle {
  display: block;
  width: 10px;
  height: 10px;
  background: #1E4B5E;
  border-radius: 100px;
  margin-left: 16%;
}
.dots {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 1px;
  display: flex;
  align-items: center;
}
.dots label {
  width: 23%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
}
.dots label:nth-child(1) {
  margin-left: 26px;
}
.dots label:nth-child(2) {
  margin-left: 65px;
}
.dots label:nth-child(3) {
  padding-left: 110px;
}
.dots label:nth-child(4) {
  position: absolute;
  right: 0;
  width: 36%;
}
.Healthcare.oms .section-6 {
  padding-bottom: 80px;
}
.Healthcare.oms .section-2 {
  padding-bottom: 70px;
}
.Healthcare.oms .section-3 {
  padding-bottom: 100px;
}
.Healthcare.oms .section-2 .content-section .solution-list .oms-img-tag {
  min-height: 90px;
  display: block;
  margin-top: 15px;
}
.Healthcare.oms .section-2 .content-section .solution-list:nth-of-type(4),
.Healthcare.oms .section-2 .content-section .solution-list:nth-of-type(5) {
  margin-bottom: 50px;
}
div.Healthcare.oms .section-2.integrated-system .integrated-system-list {
  width: 47%;
  max-width: 47%;
}
div.Healthcare.oms .section-6.market-exposure .integrated-system-list {
  width: 32%;
  max-width: 32%;
}
div.Healthcare.oms .section-2.integrated-system .integrated-system-list:nth-of-type(odd) {
  margin-right: 8%;
}
div.Healthcare.oms .section-6.market-exposure .integrated-system-list {
  margin-right: 2%;
}
div.Healthcare.oms .section-6.market-exposure .integrated-system-list:nth-of-type(3) {
  margin-right: 0px;
}
.Enterprise.mob-view.Healthcare.oms.post-trade .section-1 .primary-span h1 {
  font-size: 46px;
  display: unset;
}
.Enterprise.mob-view.Healthcare.oms.post-trade .section-2.integrated-system span.primary-span {
  font-size: 36px;
}
.Enterprise.mob-view.Healthcare.oms.post-trade .section-2.integrated-system span.primary-span h3,
.Enterprise.mob-view.Healthcare.oms.post-trade .section-2.integrated-system span.primary-span h2 {
  display: unset;
  font-size: 36px;
}
.Enterprise.mob-view.Healthcare.oms.post-trade .section-2.integrated-system .secondary-span {
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  font-family: 'Object Sans';
}
.Enterprise.mob-view.Healthcare.oms.post-trade .section-4,
.Enterprise.mob-view.Healthcare.oms.post-trade .section-6.market-exposure {
  border-radius: 0;
  background: linear-gradient(90deg, #EEFEFA 0%, #FDFDFD 100%);
}
.Enterprise.mob-view.Healthcare.oms.post-trade .development-solution.section-5 {
  background: #fff;
}
.Enterprise.mob-view.Healthcare.oms.post-trade .development-solution.section-5 .heading_lines .main-head-1 .secondary-span {
  line-height: 37px;
  margin-top: 20px;
  font-size: 20px;
}
.Enterprise.mob-view.Healthcare.oms.post-trade .development-solution.section-5 .heading_lines .main-head-1 p {
  font-size: 18px;
  margin-top: 10px;
  font-family: Helvetica;
  color: #1E4B5E;
}
.Enterprise.mob-view.Healthcare.oms.post-trade .development-solution.section-5 .text p {
  font-weight: 800;
}
.Enterprise.mob-view.Healthcare.oms.post-trade .development-solution.section-5 .text p a {
  display: block;
  font-weight: 400;
  font-size: 16px;
}
.Enterprise.mob-view.Healthcare.oms.post-trade .development-solution.section-5.section-11 {
  background: linear-gradient(99.45deg, #0E2E40 -9.39%, #145554 28.48%, #208F71 100%);
}
.Enterprise.mob-view.Healthcare.oms.post-trade .development-solution.section-5.section-11 .main-head-div.heading_lines .primary-span {
  color: #FFCC4A;
}
.Enterprise.mob-view.Healthcare.oms.post-trade .development-solution.section-5.section-11 .main-head-div.heading_lines .secondary-span {
  color: #fff;
  font-size: 16px;
  font-family: 'Object Sans';
}
.Enterprise.mob-view.Healthcare.oms.post-trade .development-solution.section-5.section-11 .text p,
.Enterprise.mob-view.Healthcare.oms.post-trade .development-solution.section-5.section-11 h3 {
  color: #fff;
  padding-left: 0;
  font-family: 'Object Sans Heavy';
  font-size: 31px;
}
.Enterprise.mob-view.Healthcare.oms.post-trade .development-solution.section-5.section-11 .text p::after,
.Enterprise.mob-view.Healthcare.oms.post-trade .development-solution.section-5.section-11 h3::after {
  display: none;
}
.Enterprise.mob-view.Healthcare.oms.post-trade .development-solution.section-5.section-11 .text p a,
.Enterprise.mob-view.Healthcare.oms.post-trade .development-solution.section-5.section-11 h3 a {
  font-family: 'Object Sans';
}
.Enterprise.mob-view.Healthcare.oms.post-trade .development-solution.section-5.section-11 .text p a:first-child,
.Enterprise.mob-view.Healthcare.oms.post-trade .development-solution.section-5.section-11 h3 a:first-child {
  margin-top: 30px;
}
.Enterprise.mob-view.Healthcare.oms.post-trade .development-solution.section-5.section-11 .text p a.bullets,
.Enterprise.mob-view.Healthcare.oms.post-trade .development-solution.section-5.section-11 h3 a.bullets {
  color: #fff;
  padding-left: 50px;
  margin-bottom: 20px;
  position: relative;
}
.Enterprise.mob-view.Healthcare.oms.post-trade .development-solution.section-5.section-11 .text p a.bullets::before,
.Enterprise.mob-view.Healthcare.oms.post-trade .development-solution.section-5.section-11 h3 a.bullets::before {
  content: '';
  width: 11px;
  height: 11px;
  background: #ffcc4a;
  border-radius: 100%;
  position: absolute;
  left: 20px;
  top: 13px;
}
.Enterprise.mob-view.Healthcare.oms.post-trade .development-solution.section-5.section-11 .inline-accordion-details p::before {
  top: 9px;
}
@media only screen and (max-width: 1280px) {
  .Enterprise.Healthcare.post-trade .section-1 .inner-content .right-section img {
    width: 95%;
  }
  .Enterprise.Healthcare.oms.post-trade .section-1 .custom-section {
    padding-top: 10px;
  }
  div.Healthcare.oms .section-2.integrated-system .integrated-system-list,
  div.Healthcare.oms .section-6.market-exposure .integrated-system-list {
    padding: 30px 20px;
  }
  div.Healthcare.oms .section-2.integrated-system .integrated-system-list,
  div.Healthcare.oms .section-6.market-exposure .integrated-system-list {
    width: 47%;
    max-width: 47%;
  }
  div.Healthcare.oms .section-6.market-exposure .integrated-system-list {
    width: 32%;
    max-width: 32%;
  }
  div.Healthcare.oms .section-2.integrated-system .integrated-system-list:nth-of-type(odd),
  div.Healthcare.oms .section-6.market-exposure .integrated-system-list:nth-of-type(odd) {
    margin-right: 2%;
  }
  div.Healthcare.oms .section-6.market-exposure .integrated-system-list:nth-of-type(3) {
    margin-right: 0px;
  }
  .Healthcare.oms.post-trade .section-2 .main-head-1.main-head-2 span.secondary-span {
    color: #fff;
    font-size: 26px;
  }
  .Healthcare.oms .section-2 .content-section .solution-list span {
    max-width: 100%;
  }
  .Healthcare.oms .section-2 .content-section .solution-list strong {
    font-size: 18px;
  }
}
@media screen and (max-width: 1024px) and (min-width: 320px) {
  .Healthcare.oms .section-3 .content-section .solution-list strong,
  .Healthcare.oms .section-5 .compilance-section .compilance-list span {
    font-size: 16px;
    line-height: 24px;
  }
  .Healthcare.oms br {
    display: none!important;
  }
  .Healthcare.oms .section-3 .content-section .solution-list strong {
    font-size: 20px;
  }
  .Healthcare.oms .section-5 .compilance-section {
    flex-flow: row wrap;
    margin-bottom: 50px;
  }
  .Healthcare.oms .section-5 .compilance-section .compilance-list:last-child {
    margin-bottom: 0;
  }
  .integration-steps ul li {
    font-size: 14px;
  }
  .Healthcare.oms .section-3 .content-section .solution-list i img {
    width: 60px;
  }
}
@media only screen and (max-width: 991px) and (min-width: 320px) {
  .Healthcare.oms .section-2 .content-section .solution-list {
    max-width: 50%;
  }
  .Enterprise.Healthcare.oms .section-1 .main-para-div.short-div p br {
    display: none!important;
  }
  .Enterprise.Healthcare.oms .section-2,
  .Enterprise.Healthcare.oms .section-3,
  .Enterprise.Healthcare.oms .section-4,
  .Enterprise.Healthcare.oms .section-5,
  .Enterprise.Healthcare.oms .section-6,
  .Enterprise.Healthcare.oms .section-7 {
    padding: 40px 0 40px!important;
  }
  .Healthcare.oms .section-7 .main-head-div {
    margin-bottom: 10px;
  }
  .Healthcare.oms .section-3 .content-section .solution-list:nth-child(4),
  .Healthcare.oms .section-3 .content-section .solution-list:nth-child(5),
  .Healthcare.oms .section-3 .content-section .solution-list:nth-child(6) {
    margin-bottom: 20px;
  }
  .Enterprise.Healthcare.oms .section-1 {
    padding-bottom: 30px;
  }
  .Healthcare.oms .section-4 .work-with-col {
    padding: 0;
  }
  .Healthcare.oms .section-4 .content-section {
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
  }
  .checking-lists {
    width: 100%;
    margin: 20px auto 0 auto;
    padding: 0;
  }
  .oms.Healthcare .section-7 .checking-lists {
    padding-left: 15px;
  }
  .Healthcare.oms .section-5 .compilance-section .compilance-list {
    margin-bottom: 30px;
  }
  .open-architechture {
    flex-flow: wrap;
  }
  .live-market {
    width: 30%;
    margin: 0 auto;
    border-radius: 20px 20px 120px 120px;
    padding: 0px 0 20px 0;
  }
  .integration-steps {
    width: 100%;
  }
  .dots {
    display: none;
  }
  .Healthcare.oms .section-3 .content-section .solution-list {
    max-width: 43%;
    margin: 30px auto;
  }
  .Healthcare.oms .section-3 .content-section .solution-list strong {
    font-size: 18px;
  }
}
@media only screen and (max-width: 767px) and (min-width: 320px) {
  .Enterprise.Healthcare .section-1 .inner-content {
    flex-direction: column;
  }
  .Enterprise.Healthcare .section-1,
  .Enterprise.Healthcare .section-1 .main-head-1 .primary-span {
    text-align: center;
  }
  .Healthcare.oms .section-7 .content-section,
  .Healthcare.oms .section-8 .content-section,
  .Healthcare.oms .section-5 .compilance-section,
  .Healthcare.oms .section-3 .content-section {
    flex-flow: wrap;
  }
  .Healthcare.oms .section-2 .content-section .solution-list span {
    width: 80%;
  }
  .Healthcare.oms .section-7 .content-section,
  .Healthcare.oms .section-8 .content-section,
  .Healthcare.oms .section-5 .compilance-section,
  .Healthcare.oms .section-3 .content-section {
    flex-flow: wrap;
  }
  .marketting-team p.semi-colon {
    font-size: 16px;
    line-height: 22px;
  }
  .team-detail p b {
    font-size: 20px;
  }
  .marketting-team p small {
    font-size: 14px;
  }
  .team-detail p {
    font-size: 14px;
    line-height: 20px;
  }
  .Healthcare.oms .section-2 .marketting-team {
    width: 90%;
  }
  .team-detail {
    width: 76%;
    margin: 0 auto;
  }
  .team-detail a em {
    font-size: 15px;
  }
  .Healthcare.oms .section-2 .content-section .solution-list {
    max-width: 90%;
  }
  .Healthcare.oms .section-2 .content-section .solution-list {
    margin: 30px auto;
  }
  .Healthcare.oms .section-3 .content-section .solution-list i img {
    width: 50px;
  }
  .Healthcare.oms .section-3 .content-section .solution-list {
    flex-flow: wrap;
    margin: 20px 0;
  }
  .Healthcare.oms .section-3 .content-section .solution-list strong,
  .Healthcare.oms .section-5 .compilance-section .compilance-list span {
    margin-top: 20px;
    text-align: center;
    font-size: 18px;
    line-height: 24px;
  }
  .Healthcare.oms .section-4 .content-section .checking-lists p,
  .Healthcare.oms .section-7 .content-section .checking-lists p {
    font-size: 15px;
    line-height: 25px;
  }
  .Healthcare.oms .section-4 .content-section .checking-lists p:before,
  .Healthcare.oms .section-7 .content-section .checking-lists p:before {
    top: 6px;
  }
  .live-market {
    width: 220px;
  }
  .integration-steps ul li,
  .integration-steps ul li:nth-child(1),
  .integration-steps ul li:nth-child(2),
  .integration-steps ul li:nth-child(3) {
    width: 85%;
    margin: 30px auto;
    height: auto;
    margin-top: 50px;
  }
  .Healthcare.oms .section-4 .content-section .checking-lists {
    width: 100%;
    margin: 40px auto 0;
  }
  .integration-steps ul li:before {
    right: auto;
    bottom: -30px;
    width: auto;
    height: 40%;
  }
  .integration-steps ul li:nth-child(1):before,
  .integration-steps ul li:nth-child(2):before,
  .integration-steps ul li:nth-child(4):before,
  .integration-steps ul li:nth-child(5):before {
    display: none;
  }
  .integration-steps:before {
    content: "";
    position: absolute;
    width: 1px;
    height: 100vh;
    border: 1px dashed #1e4b5e;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
  .integration-steps:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 1px;
    height: 60vh;
    border: 1px dashed #1e4b5e;
    bottom: 50px;
    left: 0;
    right: 0;
    margin: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
  .integration-steps ul li span {
    position: absolute;
    background: #ffcc4a;
    width: 50px;
    left: 0;
    right: 0;
    margin: auto;
    top: -30px;
    height: 50px;
    border-radius: 100px;
    -webkit-align-items: center;
    align-items: center;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }
  .integration-steps {
    position: relative;
  }
  .Healthcare.oms .section-2 .content-section .solution-list span {
    width: 80%;
  }
  .Healthcare.oms .section-2 .content-section .solution-list strong br {
    display: block!important;
  }
  div.Healthcare.oms .section-2.integrated-system .integrated-system-list,
  div.Healthcare.oms .section-6.market-exposure .integrated-system-list {
    width: 100% !important;
    max-width: 100% !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-bottom: 4% !important;
  }
  div.Healthcare.oms .section-9.trade-operation .custom-container {
    padding: 30px;
  }
  div.Healthcare.oms.post-trade .section-9.trade-operation .custom-container .do-more-list .integrated-system-list {
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
  div.Healthcare.oms .section-9.trade-operation .custom-container .do-more-list {
    padding: 50px 20px;
  }
  .Enterprise.Healthcare.oms.post-trade .section-4 img.web-img {
    display: none;
  }
  .Enterprise.Healthcare.oms.post-trade .section-4 img.mob-img {
    display: block;
  }
  .Healthcare.oms .section-5.trade-operation .integrated-system-list img {
    margin-top: 30px;
  }
  .Healthcare.oms .section-8.trade-operation .left-info-hold {
    margin-left: 0px;
  }
  .Healthcare.oms .section-8.trade-operation .integrated-system-list img {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 480px) and (min-width: 320px) {
  .Enterprise.mob-view.Healthcare.oms.post-trade .section-1 .primary-span h1 {
    font-size: 36px;
  }
}
@media only screen and (max-width: 375px) and (min-width: 320px) {
  .Healthcare.oms .main-head-1.main-head-2 .primary-span {
    font-size: 28px;
  }
  .Enterprise.Healthcare.oms .custom-container .left-info-hold .main-head-1.main-head-2 .secondary-span {
    font-size: 18px;
  }
  .Healthcare.oms .section-2 .content-section .solution-list strong {
    font-size: 18px;
    margin: 16px auto;
    line-height: 24px;
  }
  .Healthcare.oms .section-2 .content-section .solution-list span {
    width: 90%;
    font-size: 15px;
  }
}
/*section 2 style*/
.graphics-wrapper {
  padding: 0 15px;
}
.graphics-wrapper .graphics-cont {
  position: relative;
  max-width: 1174px;
  margin: 200px auto 0px;
}
.graphics-wrapper .svg-file svg {
  width: 100%;
  height: 100%;
}
.graphics-wrapper .list-cont {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.graphics-wrapper .list-cont ul {
  list-style-type: none;
}
.graphics-wrapper .list-cont ul li {
  position: absolute;
  width: 300px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #279376;
  background: #FFF;
  z-index: 1;
}
.graphics-wrapper .list-cont ul li::after {
  position: absolute;
  content: '';
  bottom: -9px;
  right: -68px;
  width: 120px;
  height: 0px;
  border: 1px dashed #269275;
  transform: rotate(45deg);
  z-index: -1;
}
.graphics-wrapper .list-cont ul li p {
  text-align: center;
  margin-bottom: 0px;
  font-family: "Object Sans Heavy";
}
.graphics-wrapper .list-cont ul li p span {
  color: #1E4B5E;
  text-align: center;
  font-size: 18px;
  font-weight: 800;
  line-height: 125.6%;
}
/* .graphics-wrapper .list-cont ul li  */
.heading-block {
  display: flex;
  justify-content: center;
  position: relative;
}
.list-1 {
  position: relative;
  width: 460px;
  height: 460px !important;
  /* margin-top: -50px; */
  height: auto;
  background: #fff;
  border-radius: 50%;
  margin: 16px;
  z-index: 0;
}
.list-1::before {
  position: absolute;
  content: '';
  width: calc(100% + 16px);
  height: calc(100% + 16px);
  top: -8px;
  left: -8px;
  /* background-color: red; */
  border-radius: 50%;
  background: #208f71;
  /* background: linear-gradient(0deg, rgba(32,143,113,0.0) 0%,rgba(32,143,113,0.1) 0%,rgba(32,143,113,0.1) 0%, rgba(32,143,113,1) 100%); */
  /* background: linear-gradient(5deg, rgb(255 255 255) 0%,rgb(255 255 255) 0%,rgb(255 255 255) 0%, rgba(32,143,113,1) 100%); */
  background: linear-gradient(0deg, rgba(32, 143, 113, 0) 10%, #208f71 100%);
  z-index: -1;
}
.list-1 p.graphics-heading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #279376;
  border-radius: 50%;
  border: 30px solid #fff;
}
.list-1 p.graphics-heading span {
  color: #fff;
  text-align: center;
  font-size: 35px;
  font-weight: 800;
  line-height: 44px;
  text-transform: capitalize;
  font-weight: bolder;
  padding: 10px;
  width: 70%;
  font-family: "Object Sans Heavy";
}
.graphics-wrapper .list-cont ul li.list-2 {
  bottom: 100%;
  right: calc(100% - 30px);
}
.graphics-wrapper .list-cont ul li.list-3 {
  top: 26%;
  right: calc(100% + 50px);
}
.graphics-wrapper .list-cont ul li.list-4 {
  top: auto;
  bottom: 20%;
  right: calc(100% + 50px);
}
.graphics-wrapper .list-cont ul li.list-5 {
  top: auto;
  bottom: 20%;
  left: calc(100% + 50px);
  right: auto;
}
.graphics-wrapper .list-cont ul li.list-6 {
  /* left: calc(100% + 50px); */
  right: auto;
  top: 26%;
  left: calc(100% + 50px);
}
.graphics-wrapper .list-cont ul li.list-7 {
  right: auto;
  /* top: 26%; */
  /* left: calc(100% + 50px); */
  bottom: 100%;
  left: calc(100% - 30px);
}
.graphics-wrapper .list-cont ul li.list-8 {
  bottom: calc(100% + 90px);
  left: 50%;
  right: auto;
  top: auto;
  transform: translate(-50%, 0px);
}
.graphics-wrapper .list-cont ul li::after {
  position: absolute;
  content: '';
  bottom: -9px;
  right: -68px;
  width: 120px;
  height: 0px;
  border: 1px dashed #269275;
  transform: rotate(45deg);
  z-index: -1;
}
.graphics-wrapper .list-cont ul li.list-2::after {
  bottom: -9px;
  right: -68px;
  width: 120px;
}
.graphics-wrapper .list-cont ul li.list-3::after {
  bottom: 22px;
  right: -68px;
  width: 120px;
  transform: rotate(25deg);
}
.graphics-wrapper .list-cont ul li.list-4::after {
  bottom: 37px;
  right: -90px;
  width: 120px;
  transform: rotate(-22deg);
}
.graphics-wrapper .list-cont ul li.list-5::after {
  bottom: 37px;
  width: 120px;
  transform: rotate(22deg);
  right: auto;
  left: -90px;
}
.graphics-wrapper .list-cont ul li.list-6::after {
  transform: rotate(-25deg);
  left: -90px;
  bottom: 17px;
  right: auto;
  width: 120px;
}
.graphics-wrapper .list-cont ul li.list-7::after {
  bottom: -9px;
  width: 120px;
  transform: rotate(-45deg);
  right: auto;
  left: -68px;
}
.graphics-wrapper .list-cont ul li.list-8::after {
  bottom: -42px;
  width: 80px;
  right: auto;
  left: 50%;
  transform: translate(-50%, 0) rotate(90deg);
}
.graphics-wrapper .list-cont ul li.list-2 p {
  max-width: 180px;
}
.graphics-wrapper .list-cont ul li.list-3 p {
  max-width: 230px;
}
.graphics-wrapper .list-cont ul li.list-4 p {
  max-width: 220px;
}
.graphics-wrapper .list-cont ul li.list-5 p {
  max-width: 250px;
}
.graphics-wrapper .list-cont ul li.list-6 p {
  max-width: 200px;
}
.graphics-wrapper .list-cont ul li.list-7 p {
  max-width: 250px;
}
.graphics-wrapper .list-cont ul li.list-8 p {
  max-width: 190px;
}
.Enterprise.Healthcare.oms .section-5 .main-head-1.head-para {
  text-align: left;
}
@media screen and (max-width: 1150px) {
  .list-1 {
    position: relative;
    width: 400px;
    height: 400px !important;
  }
  .list-1 p.graphics-heading span {
    width: 80%;
  }
  .graphics-wrapper .list-cont ul li {
    width: 52%;
    /* height: 9%; */
  }
  .graphics-wrapper .list-cont ul li p span {
    font-size: 16px;
    line-height: 20px;
  }
}
@media screen and (max-width: 991px) {
  .list-1 {
    position: relative;
    width: 310px;
    height: 310px !important;
  }
  .list-1 p.graphics-heading span {
    font-size: 25px;
    line-height: 30px;
  }
  .graphics-wrapper .list-cont ul li p span {
    font-size: 13px;
    line-height: 20px;
  }
  .graphics-wrapper .list-cont ul li.list-3,
  .graphics-wrapper .list-cont ul li.list-6 {
    top: 20%;
  }
}
@media screen and (max-width: 767px) {
  .list-1 {
    position: relative;
    width: 220px;
    height: 220px !important;
  }
  .list-1 p.graphics-heading {
    border: 10px solid #fff;
  }
  .list-1 p.graphics-heading span {
    font-size: 20px;
    line-height: 26px;
  }
  .graphics-wrapper .list-cont ul li.list-3,
  .graphics-wrapper .list-cont ul li.list-6 {
    top: 12%;
  }
  .Enterprise.mob-view.Healthcare.oms.post-trade .development-solution.section-5.section-11 .text p,
  .Enterprise.mob-view.Healthcare.oms.post-trade .development-solution.section-5.section-11 .text h3 {
    line-height: 38px;
    font-size: 27px;
  }
  .Enterprise.mob-view.Healthcare.oms.post-trade .section-5.beige-bg.company-info.development-solution .faq-list .summary .MuiAccordionSummary-content .summary-content {
    font-size: 17px;
    line-height: 30px;
  }
  .Enterprise.mob-view.Healthcare.oms.post-trade .section-5.beige-bg.company-info.development-solution .MuiAccordionSummary-expandIcon svg {
    width: 16px;
    height: 16px;
  }
}
@media screen and (max-width: 550px) {
  .graphics-wrapper .graphics-cont {
    margin: 0;
  }
  .heading-block {
    justify-content: flex-start;
  }
  .graphics-wrapper .svg-file {
    display: none;
  }
  .graphics-wrapper .list-cont {
    position: relative;
    width: calc(100vw - 60px);
    height: auto;
  }
  .graphics-wrapper .list-cont ul li.list-2::after,
  .graphics-wrapper .list-cont ul li.list-3::after,
  .graphics-wrapper .list-cont ul li.list-4::after,
  .graphics-wrapper .list-cont ul li.list-5::after,
  .graphics-wrapper .list-cont ul li.list-6::after,
  .graphics-wrapper .list-cont ul li.list-7::after,
  .graphics-wrapper .list-cont ul li.list-8::after {
    transform: none;
  }
  .graphics-wrapper .list-cont ul li.list-8 {
    bottom: auto;
    left: 50%;
    right: auto;
    top: auto;
    transform: none;
  }
  .graphics-wrapper .list-cont ul li.list-2::after,
  .graphics-wrapper .list-cont ul li.list-3::after,
  .graphics-wrapper .list-cont ul li.list-4::after,
  .graphics-wrapper .list-cont ul li.list-5::after,
  .graphics-wrapper .list-cont ul li.list-6::after,
  .graphics-wrapper .list-cont ul li.list-7::after,
  .graphics-wrapper .list-cont ul li.list-8::after {
    bottom: auto;
    width: 35px;
    right: auto;
    left: -36px;
    height: 0;
    background: transparent;
  }
  .graphics-wrapper .list-cont::before {
    position: absolute;
    content: '';
    top: -12%;
    left: 85px;
    width: 4px;
    height: calc(100% + 40px);
    background-color: #279376;
    z-index: -1;
  }
  .graphics-wrapper .list-cont li {
    position: relative !important;
    top: auto !important;
    left: auto !important;
    right: 0 !important;
    width: auto !important;
    height: auto !important;
    background: #1E4B5E;
    margin: 34px 12px 22px 120px;
    padding: 10px 15px;
    /* box-shadow: 6px 6px 4px 0px #FFCC4A; */
  }
  .graphics-wrapper .list-cont ul li.list-1 {
    margin-top: 0;
    transform: translate(0);
    width: 160px !important;
    height: 160px !important;
    border-radius: 50%;
    border: 19px dashed #1E4B5E;
    position: relative !important;
    margin: 15px 12px 32px 0px;
    box-shadow: none;
    animation: none;
  }
  .graphics-wrapper .list-cont ul li.list-1::before {
    position: absolute;
    content: '';
    top: -5px;
    left: -5px;
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    background-color: #fff;
    border-radius: 50%;
    z-index: 1;
    background-image: none;
  }
  .graphics-wrapper .list-cont ul li::before {
    /* position: absolute;
        content: '';
        top: -14px;
        left: -36px;
        width: 65px;
        height: 65px; */
    /* background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="98" height="108" viewBox="0 0 98 108" fill="none"><path d="M5.67184 92.1992C13.028 99.5634 22.4613 104.528 32.7783 106.466C43.0952 108.404 53.8321 107.227 63.6305 103.085C73.4289 98.9422 81.8486 92.0205 87.8241 83.1951C93.7997 74.3697 97.0627 64.0373 97.2003 53.5051C97.3379 42.9729 94.3438 32.7142 88.597 24.0269C82.8502 15.3397 74.6088 8.61431 64.9156 4.70167C55.2224 0.789037 44.5129 -0.135022 34.1422 2.04641C23.7715 4.22784 14.2056 9.41673 6.65468 16.9566L43.7818 54.0295L5.67184 92.1992Z" fill="%23FFCC4A"/><path d="M18.4642 89.3464C-0.743744 75.6159 -4.85594 48.7122 9.27941 29.2554C23.4148 9.79857 50.4449 5.15647 69.6529 18.887C88.8609 32.6175 92.973 59.5212 78.8377 78.978C64.7023 98.4349 37.6722 103.077 18.4642 89.3464Z" fill="white"/></svg>');
        background-color: #fff; */
    /* border-radius: 50%; */
    /* z-index: 1;
        background-repeat: no-repeat;
        background-size: 60px 60px;
        background-position: left center;
        z-index: -1;
        transform: rotate(175deg); */
  }
  .graphics-wrapper .list-cont ul li::after {
    position: absolute;
    content: '';
    top: 50%;
    left: -64px;
    transform: translate(0, -50%);
    width: 70px;
    height: 3px;
    background-color: #1E4B5E;
  }
  .graphics-wrapper .list-cont ul li.list-1::after {
    display: none;
  }
  .graphics-wrapper .list-cont ul li.list-1 p {
    z-index: 2;
    position: relative;
  }
  .graphics-wrapper .list-cont ul li p {
    line-height: 12px;
  }
  .graphics-wrapper .list-cont ul li.list-1 p span {
    font-size: 18px;
    line-height: 22px;
  }
  .graphics-wrapper .list-cont ul li p span {
    font-size: 10px;
    line-height: 10px;
  }
}
@media screen and (max-width: 374px) {
  /* .graphics-wrapper .list-cont::before{
        height: calc(100% - 4%);
    } */
}
.anmte {
  /* opacity: 0; */
  -webkit-animation: fadeIn 0.6s linear;
  animation: fadeIn 0.6s linear;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.anmte {
  /* opacity: 0; */
}
.anmte.path1 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.anmte.path2 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.anmte.path3 {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.anmte.path4 {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}
.anmte.path5 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.anmte.path6 {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}
.anmte.path7 {
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}
.anmte.path8 {
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
}
.anmte.path9 {
  -webkit-animation-delay: 1.8s;
  animation-delay: 1.8s;
}
.anmte.path10 {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
.anmte.path11 {
  -webkit-animation-delay: 2.2s;
  animation-delay: 2.2s;
}
.anmte.path12 {
  -webkit-animation-delay: 2.4s;
  animation-delay: 2.4s;
}
.anmte.path13 {
  -webkit-animation-delay: 2.6s;
  animation-delay: 2.6s;
}
.anmte.path14 {
  -webkit-animation-delay: 2.8s;
  animation-delay: 2.8s;
}
.anmte.path15 {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}
.anmte.path16 {
  -webkit-animation-delay: 3.2s;
  animation-delay: 3.2s;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0.6;
  }
  100% {
    opacity: 2;
  }
}
/*section 2 end*/
/*section 4 start*/
div.Enterprise.Healthcare.oms .section-4 {
  width: 100%;
}
.section-4 .graphics-wrapper {
  padding: 0 15px;
}
.section-4 .graphics-cont {
  display: flex;
  max-width: 1110px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.section-4 .graphics-cont .left-block,
.section-4 .graphics-cont .right-block {
  display: flex;
  width: 50%;
}
.section-4 .graphics-cont .left-block {
  flex-direction: column;
}
.section-4 .graphics-cont .left-block ul {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.section-4 .graphics-cont .left-block ul li {
  margin: 15px 0;
}
.section-4 .graphics-cont .left-block ul li p {
  height: 70px;
  display: inline-flex;
  align-items: center;
  color: #1E4B5E;
  font-size: 22px;
  font-weight: 800;
  min-width: 370px;
  padding: 20px 15px;
  border-radius: 40px;
  background: #fff;
  /* box-shadow: 3px 2px 1px 1px #f5da75; */
  box-shadow: 3px 2px 1px 1px #f5da75;
  position: relative;
}
.section-4 .graphics-cont .left-block ul li p::after {
  position: absolute;
  content: '';
  height: 120px;
  width: 0;
  top: 50%;
  left: calc(100% + 19%);
  border: 1px dashed #000;
}
.section-4 .graphics-cont .left-block ul li.item-4 p::after {
  display: none;
}
.section-4 .graphics-cont .left-block ul li p::before {
  position: absolute;
  content: '';
  height: 0px;
  width: 19%;
  top: 50%;
  left: calc(100%);
  border: 1px dashed #000;
}
.section-4 .graphics-cont .left-block ul li p span {
  color: #FFF;
  text-align: center;
  display: flex;
  font-size: 20px;
  font-weight: 800;
  width: 48px;
  height: 48px;
  background-color: #208F71;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}
.svg-list-block {
  position: relative;
}
.svg-list-block .list-block {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.svg-list-block .list-block::before {
  position: absolute;
  content: '';
  left: -20%;
  top: calc(50% - 4px);
  transform: translate(0, -50%);
  border: 1px dashed #000;
  width: 50%;
  height: 0;
}
.svg-list-block .list-block::after {
  position: absolute;
  content: '';
  left: 30%;
  top: calc(50% - 4px);
  transform: translate(0, -50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-left: 10px solid black;
  border-bottom: 10px solid transparent;
}
.svg-list-block .list-block ul {
  list-style-type: none;
}
.svg-list-block .list-block ul li {
  position: absolute;
}
.svg-list-block .list-block ul li p {
  display: flex;
  justify-content: center;
}
.svg-list-block .list-block ul li p span {
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-weight: 800;
  width: 80%;
}
.svg-list-block .list-block ul li.item-1 {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.svg-list-block .list-block ul li.item-1 p span {
  width: 100%;
}
.svg-list-block .list-block ul li.item-2 {
  top: 14%;
  left: 22%;
}
.svg-list-block .list-block ul li.item-3 {
  top: 33%;
  left: 1%;
}
.svg-list-block .list-block ul li.item-4 {
  top: 57%;
  left: 6%;
}
.svg-list-block .list-block ul li.item-4 p span {
  width: 100%;
}
.svg-list-block .list-block ul li.item-4 p span span {
  display: block;
}
.svg-list-block .list-block ul li.item-5 {
  top: 81%;
  left: 22%;
}
.svg-list-block .list-block ul li.item-6 {
  top: 79%;
  right: 24%;
  left: auto;
}
.svg-list-block .list-block ul li.item-6 p span {
  width: 70%;
}
.svg-list-block .list-block ul li.item-7 {
  top: 58%;
  right: 7%;
  left: auto;
}
.svg-list-block .list-block ul li.item-8 {
  top: 33%;
  right: 0.3%;
  left: auto;
}
.svg-list-block .list-block ul li.item-9 {
  top: 14%;
  right: 20%;
  left: auto;
}
@media screen and (max-width: 1200px) {
  .svg-list-block .svg-file-block svg {
    width: 100%;
    height: auto;
  }
}
@media screen and (max-width: 1100px) {
  .left-block ul li p {
    min-width: auto;
    width: 67%;
  }
  .svg-list-block .list-block ul li.item-1 p span svg {
    width: 80%;
    height: auto;
  }
}
@media screen and (max-width: 991px) {
  .left-block ul {
    gap: 0;
  }
  .left-block ul li {
    margin: 10px 0;
  }
  .left-block ul li p {
    height: 55px;
    font-size: 15px;
    padding: 15px 15px;
  }
  .left-block ul li p span {
    font-size: 18px;
    font-weight: 800;
    width: 38px;
    height: 38px;
  }
  .left-block ul li p::after {
    height: 73px;
  }
  .svg-list-block .list-block ul li p span {
    font-size: 13px;
  }
  .svg-list-block .list-block ul li.item-1 p span svg {
    width: 60%;
    height: auto;
  }
}
@media screen and (max-width: 767px) {
  .graphics-cont .left-block {
    width: 40%;
  }
  .left-block ul li p {
    height: 50px;
    font-size: 13px;
    padding: 12px 8px;
  }
  .left-block ul li p span {
    margin-right: 5px;
  }
  .graphics-cont .right-block {
    width: 60%;
  }
  .svg-list-block .list-block::before {
    left: -13%;
    width: 43%;
  }
  .svg-list-block .list-block ul li.item-5 {
    left: 19%;
  }
  .svg-list-block .list-block ul li.item-8 {
    right: -2%;
  }
  .svg-list-block .list-block ul li.item-9 {
    right: 16%;
  }
  .svg-list-block .list-block ul li.item-6 {
    right: 20%;
  }
}
@media screen and (max-width: 600px) {
  .svg-list-block .list-block ul li p span {
    font-size: 11px;
  }
}
@media screen and (max-width: 500px) {
  .graphics-cont {
    flex-direction: column;
  }
  .graphics-cont .left-block {
    position: relative;
    z-index: 3;
  }
  .graphics-cont .left-block,
  .graphics-cont .right-block {
    width: 100%;
  }
  .left-block ul li p {
    width: 100%;
    background-color: #fff;
  }
  .left-block ul li p::before {
    display: none;
  }
  .left-block ul li p::after {
    height: 120px;
    top: 100%;
    left: 50%;
    z-index: -1;
  }
  .svg-list-block .list-block::before {
    left: calc(50% - 0px);
    width: 0px;
    height: 30%;
    top: 70px;
    z-index: 0;
  }
  .svg-list-block .list-block::after {
    left: calc(50% - 4px);
    top: calc(30% - 4px);
    transform: translate(0, -50%);
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-left: 10px solid black;
    border-bottom: 10px solid transparent;
    transform: rotate(90deg);
  }
  .svg-list-block .list-block ul li.item-1 p {
    background-color: #fff;
    height: 86px;
    width: 90px;
    display: flex;
    align-items: center;
    border-radius: 50%;
  }
  .svg-list-block .list-block ul li.item-1 p span svg {
    width: 90%;
    height: auto;
  }
}
/*section 4 end*/
/*section 5 start*/
.section-5.trade-operation.post-solutions .integrated-system-list .slick-slider .slick-slide {
  padding: 3px;
}
.section-5.trade-operation.post-solutions .integrated-system-list .slick-slider .slick-slide .health-wrap {
  border: 1px solid #8992d0;
  padding: 5px;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 50px auto 20px;
}
.section-5.trade-operation.post-solutions .integrated-system-list .slick-slider ul li::before {
  display: none;
}
@media screen and (max-width: 768px) {
  .section-5.trade-operation.post-solutions .integrated-system-list .slick-slider .slick-slide .health-wrap {
    margin: auto;
  }
  .section-5.trade-operation.post-solutions .integrated-system-list .slick-slider .slick-slide .health-wrap img {
    margin: 0;
  }
}
/*section 5 end*/
