.read-to-subscribe-wrap {
  width: 100%;
  padding: 80px 0px;
  position: relative;
}
.read-to-subscribe-wrap::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(-94deg, #FFCC4A 0%, #208F71 99.99%, #145554 100%);
  opacity: 0.9;
  z-index: -1;
}
.read-to-subscribe-wrap::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: url("/assets/img/case-study-images/sub-group-bg.png") no-repeat;
  background-position: bottom;
  background-size: 100%;
  z-index: -1;
}
.read-to-subscribe-wrap .custom-container {
  width: 90%;
  min-width: 90%;
  max-width: 90%;
  flex-wrap: wrap;
}
.read-to-subscribe-wrap .custom-container .section-heading {
  width: 100%;
  display: flex;
}
.read-to-subscribe-wrap .custom-container .section-heading h3 {
  color: #fff;
  text-shadow: 0px 4px rgba(0, 0, 0, 0.15);
  font-size: 26px;
  display: flex;
  flex-direction: column;
  font-family: "Object Sans Heavy";
  line-height: 42px;
}
.read-to-subscribe-wrap .custom-container .section-heading h3 span {
  font-size: 21px;
}
.read-to-subscribe-wrap .custom-container .read-more-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
  margin-bottom: 50px;
}
.read-to-subscribe-wrap .custom-container .read-more-list .more-card {
  width: 30%;
  display: inline-flex;
  flex-direction: column;
  background: #055B46;
  border: 1px solid #88E7EA;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.32);
  padding: 65px;
  color: #fff;
  font-family: "Object Sans";
  position: relative;
  border-radius: 5px;
}
.read-to-subscribe-wrap .custom-container .read-more-list .more-card::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: -20px;
  width: 60px;
  height: 60px;
  background: url("/assets/img/case-study-images/tick.svg") no-repeat;
  margin: auto;
  z-index: 1;
}
.read-to-subscribe-wrap .custom-container .read-more-list .more-card::after {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  right: 1px;
  height: 45px;
  width: 75px;
  border-radius: 0 0 150px 150px;
  border: 1px solid #8ce7e9;
  border-top: 0;
  margin: auto;
}
.read-to-subscribe-wrap .custom-container .read-more-list .more-card:nth-of-type(1)::after {
  background: #5c9f78;
}
.read-to-subscribe-wrap .custom-container .read-more-list .more-card:nth-of-type(2)::after {
  background: #95ae70;
}
.read-to-subscribe-wrap .custom-container .read-more-list .more-card:nth-of-type(3)::after {
  background: #cdbc67;
}
.read-to-subscribe-wrap .custom-container .read-more-list .more-card span {
  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
  max-width: 80%;
}
.read-to-subscribe-wrap .custom-container .read-more-list .more-card strong {
  font-size: 30px;
  margin-top: 10px;
  line-height: 34px;
}
.read-to-subscribe-wrap .custom-container .sign-up {
  width: 100%;
}
.read-to-subscribe-wrap .custom-container .sign-up input {
  border-radius: 75px;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border: 0px;
  padding: 10px 20px;
  min-height: 60px;
  width: 35%;
  margin-right: 15px;
  font-size: 16px;
}
.read-to-subscribe-wrap .custom-container .sign-up input:focus {
  outline: none;
}
.read-to-subscribe-wrap .custom-container .sign-up button {
  border-radius: 75px;
  background: #FFCC4A;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border: 0px;
  padding: 10px 40px;
  font-family: "Object Sans Heavy";
  font-size: 16px;
  min-height: 60px;
}
@media only screen and (max-width: 1024px) {
  .read-to-subscribe-wrap .custom-container .read-more-list .more-card span {
    font-size: 18px;
  }
  .read-to-subscribe-wrap .custom-container .read-more-list .more-card strong {
    font-size: 24px;
  }
  .read-to-subscribe-wrap .custom-container .read-more-list .more-card {
    padding: 20px;
    padding-top: 70px;
    padding-bottom: 30px;
  }
  .read-to-subscribe-wrap .custom-container .sign-up input {
    width: 40%;
  }
}
@media only screen and (max-width: 480px) {
  .read-to-subscribe-wrap .custom-container .read-more-list {
    flex-direction: column;
  }
  .read-to-subscribe-wrap .custom-container .read-more-list .more-card {
    width: 100%;
    margin-bottom: 50px;
  }
  .read-to-subscribe-wrap .custom-container .read-more-list .more-card:nth-of-type(3) {
    margin-bottom: 0px;
  }
  .read-to-subscribe-wrap .custom-container .sign-up input {
    width: 60%;
  }
  .read-to-subscribe-wrap .custom-container .read-more-list .more-card:nth-of-type(1)::after {
    background: #9ab473;
  }
  .read-to-subscribe-wrap .custom-container .read-more-list .more-card:nth-of-type(3)::after {
    background: #8aab72;
  }
}
