.secondary-footer .custom-container {
  min-width: 95%;
  max-width: 95%;
}
.footer-logo-size {
  width: 150px;
}
.secondary-footer .secondary-footer-common-section {
  background: #1E4B5E;
  position: relative;
  z-index: 1;
  padding: 50px 0px 0px;
}
.secondary-footer .sec-footer-row {
  margin: 0px;
  width: 100%;
}
.secondary-footer .all-branches-hold {
  margin-top: 50px;
}
.secondary-footer .all-branches-ul {
  padding: 0px;
}
.secondary-footer .all-branches-ul li {
  list-style: none;
  margin: 18px 0px;
}
.secondary-footer .all-branches-ul li p {
  margin-bottom: 0px;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #BCC4C7;
  cursor: pointer;
}
.secondary-footer .footer-col-text {
  font-family: 'Heldane Display';
  font-size: 42px;
  line-height: 52px;
  color: #F7FFFD;
  margin-bottom: 0px;
}
.secondary-footer .footer-col-head {
  margin-top: 0px;
  margin-left: -50px;
}
.secondary-footer .quick-links-hold .quick-links-ul {
  padding: 0px;
}
.secondary-footer .quick-links-hold .quick-links-ul li {
  list-style: none;
  margin-bottom: 0px;
  line-height: 35px;
}
.secondary-footer .quick-links-hold .quick-links-ul li a {
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #FFCC4A;
  transition: 0.3s ease;
  margin-bottom: 0px;
}
.footer-secondary {
  margin-top: 30px;
}
.secondary-footer .quick-links-hold .quick-links-ul li a:hover {
  text-decoration: none;
}
.secondary-footer .newsletter-form-group {
  width: 95%;
  margin-bottom: 0px;
}
.secondary-footer .newsletter-form-group input {
  height: 40px;
  padding: 10px!important;
  border: none;
  padding-right: 50px!important;
}
.secondary-footer .newsletter-form-group .form-control:focus {
  box-shadow: none!important;
}
.secondary-footer .newsletter-form-group {
  position: relative;
  margin-top: 25px;
  margin-bottom: 15px;
}
.secondary-footer .newsletter-form-group .form-control::placeholder {
  font-family: Object Sans;
  font-size: 16px;
  line-height: 20px;
  color: #BCC4C7;
}
.secondary-footer .newsletter-arrow {
  background: #FFCC4A;
  border-radius: 0px 3px 3px 0px;
  cursor: pointer;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  width: 40px;
  height: 40px;
  bottom: 0;
  margin: auto;
  right: 0;
  left: auto;
  transition: 0.3s ease;
}
.secondary-footer .newsletter-arrow span {
  display: flex;
  align-items: center;
  justify-content: center;
}
.secondary-footer .follow-us-common .follow-us-head {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.secondary-footer .follow-us-common .follow-us-head h4,
.secondary-footer .follow-us-common .follow-us-head span {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #fff;
  margin-bottom: 0px;
  display: inline-flex;
}
.secondary-footer .follow-us-common .follow-us-head span {
  margin-left: 5px;
  display: inherit;
}
.secondary-footer .follow-us-common .follow-us-ul {
  padding: 0px;
  display: inline-flex;
  justify-content: space-between;
  margin-bottom: 0px;
  align-items: center;
}
.secondary-footer .follow-us-common .follow-us-ul li {
  list-style: none;
  width: 25%;
  height: 30px;
  display: inline-flex;
  align-items: center;
}
.secondary-footer .follow-us-common .follow-us-ul li a {
  display: inline-flex;
  align-items: center;
}
.secondary-footer .follow-us-common .follow-us-ul li:last-child svg {
  margin-top: 0px;
  margin-left: 3px;
}
.secondary-footer .follow-us-common .follow-us-icons-hold {
  width: 185px;
}
.copyright-text span {
  font-family: Object Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  margin: 15px 0px 0px;
}
.secondary-footer .footer-policy {
  display: flex;
  justify-content: space-between;
  padding: 0px 80px 15px;
  align-items: center;
}
.policy-text {
  display: flex;
  justify-content: center;
  margin: 15px 0px 0px;
}
.policy-text a {
  color: #FFFFFF;
  margin: 0px 10px;
  font-size: 13px;
}
.policy-text a:hover {
  text-decoration: underline !important;
  color: #FFFFFF;
}
.secondary-footer .follow-us-common span img {
  width: 90px;
}
.secondary-footer .quick-links-hold .quick-links-ul li a:hover {
  color: #ffcc4acc;
  transition: 0.3s ease;
}
.form-group.newsletter-form-group:hover .newsletter-arrow {
  opacity: 0.8;
  transition: 0.3s ease;
}
.footer-col-1,
.footer-col-2,
.footer-col-3 {
  display: inline-flex;
  flex-direction: column;
}
.footer-link-head {
  color: #FFFFFF;
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 20px;
}
.footer-text {
  color: #FFFFFF;
  font-size: 16px;
  line-height: 34px;
  width: 330px;
  font-family: "Object Sans";
}
.footer-bottom {
  margin: 15px 0px 0px;
  padding: 15px 0px 0px !important;
}
.footer-location {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  border-bottom: 1px solid #3C6D82;
  padding-bottom: 30px;
}
.footer-location span {
  display: inline-flex;
}
.footer-location-links {
  margin: 0px;
  padding: 0px;
}
.footer-location-links li {
  list-style-type: none;
  color: #FFFFFF;
  display: inline-block;
  position: relative;
  padding: 0px 15px;
}
.footer-location-links li::after {
  content: "|";
  position: absolute;
  right: 0px;
  height: 100%;
  width: 2px;
  color: #FFFFFF;
  top: -1px;
}
.footer-location-links li:last-child::after {
  display: none;
}
.secondary-footer .follow-us-common .follow-us-head span a.link-to-connect {
  color: #fff;
}
.secondary-footer .follow-us-common .follow-us-head span a.link-to-connect:hover {
  text-decoration: underline!important;
}
@media only screen and (max-width: 1024px) {
  .secondary-footer .newsletter-form-group {
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .secondary-footer .sec-footer-row .footer-inner,
  .secondary-footer .sec-footer-row .footer-inner .footer-logo {
    padding: 0px !important;
  }
  .footer-secondary {
    padding: 0px !important;
  }
  .secondary-footer-common-section .custom-container {
    max-width: 90%;
    min-width: 90%;
  }
  .secondary-footer .newsletter-form-group {
    width: 100%;
    transition: 0.3s ease;
  }
  .secondary-footer .all-branches-hold {
    margin-top: 25px!important;
  }
  .secondary-footer .copyright-text {
    position: relative;
    top: inherit;
    bottom: inherit;
  }
  .footer-text {
    width: 100%;
    font-size: 14px;
  }
  .secondary-footer .quick-links-hold .quick-links-ul li a {
    font-size: 14px;
  }
  .secondary-footer .follow-us-common .follow-us-ul {
    flex-wrap: wrap;
  }
  .secondary-footer .email-signup-common h4.footer-link-head {
    font-size: 24px;
  }
  .secondary-footer .follow-us-common .follow-us-head h4,
  .secondary-footer .follow-us-common .follow-us-head span {
    font-size: 14px;
  }
}
@media only screen and (max-width: 767px) {
  .secondary-footer .newsletter-common {
    margin-top: 0;
  }
  .secondary-footer .copyright-text {
    margin-top: 20px;
  }
  .secondary-footer .footer-policy {
    display: flex;
    flex-direction: column;
    padding: 0px;
  }
}
@media only screen and (max-width: 425px) {
  .secondary-footer .footer-policy {
    display: flex;
    flex-direction: column;
    padding: 0px;
  }
  .secondary-footer .copyright-text {
    position: absolute;
    bottom: -40px;
    top: auto;
  }
  .secondary-footer .policy-text {
    flex-direction: column;
    align-items: center;
  }
  .secondary-footer .follow-us-common .follow-us-ul {
    flex-wrap: inherit;
  }
  .secondary-footer .secondary-footer-common-section {
    padding-bottom: 15px !important;
  }
  .footer-bottom {
    padding: 0px !important;
  }
  .footer-bottom li {
    font-size: 14px;
  }
}
.secondary-footer .newsletter-common .email-signup-common .footer-contact {
  min-width: 135px !important;
  padding: 10px 15px;
  background: #fecb57 !important;
  color: #204b5d !important;
  border: 0px;
  font-size: 18px;
  margin: 0px 0 20px;
  border-radius: 5px;
}
.secondary-footer .newsletter-form-group {
  display: none;
}
.secondary-footer .newsletter-common .follow-us-common {
  margin: 25px 0;
}
