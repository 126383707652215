@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtZ6Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM70w-.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrFJA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9V1s.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6V1s.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7V1s.ttf) format('truetype');
}
.defi-services {
  font-family: 'Montserrat', sans-serif;
}
.defi-services .container {
  max-width: 90%;
  margin: 0px auto;
  /* width: 100%; */
  padding: 0px;
}
.defi-services .header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 15px 30px;
  z-index: 3;
}
.defi-services .settlement-solution {
  position: relative;
  padding-top: 85px;
  background: url("/assets/img/defi-services/defi-banner-bg.png") no-repeat;
  background-size: cover;
  display: flex;
}
.defi-services .settlement-solution .left-content h1 {
  font-weight: 700;
  font-size: 30px;
  position: relative;
  line-height: 46px;
  color: #5C7AEA;
}
.defi-services .settlement-solution .left-content {
  width: 60%;
  position: relative;
  padding: 0px 100px;
}
.defi-services .settlement-solution .left-content br {
  display: block !important;
}
.defi-services .settlement-solution .left-content em {
  color: #FFFFFF;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  margin: 30px 0px 30px;
  display: block;
}
.defi-services .settlement-solution .left-content ul {
  padding: 0px 30px;
  margin: 0px;
}
.defi-services .settlement-solution .left-content ul li {
  color: #787878;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 25px;
}
.defi-services .settlement-solution .settlement-solution-inner {
  display: flex;
  align-items: center;
}
.defi-services .settlement-solution .right-content {
  width: 40%;
  position: relative;
  left: -30px;
}
.defi-services .settlement-solution .right-content span {
  position: absolute;
  color: #5C7AEA;
  font-weight: bold;
  font-size: 21px;
  top: -50px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: right;
}
.defi-services .settlement-solution .right-content span::after {
  content: '';
  position: absolute;
  right: 180px;
  top: 15px;
  background: url("/assets/img/defi-services/arrow-icon.png") no-repeat;
  width: 55px;
  height: 55px;
}
.defi-services .settlement-solution .right-content .get-in-touch {
  background: #FFF;
  padding: 10px 20px 30px;
  margin-bottom: 100px;
}
.defi-services .settlement-solution .right-content .get-in-touch strong {
  color: #444544;
  font-size: 36px;
  margin-bottom: 20px;
  display: block;
}
.defi-services .settlement-solution .right-content .get-in-touch .input-field {
  display: block;
  margin-bottom: 20px;
}
.defi-services .settlement-solution .right-content .get-in-touch .input-field label {
  display: block;
  font-size: 16px;
  color: #757575;
  margin-bottom: 0px;
}
.defi-services .settlement-solution .right-content .get-in-touch .input-field input {
  border: 0px;
  border-bottom: 1px solid #E6E7E8;
  padding: 5px 0px;
  width: 100%;
}
.defi-services .settlement-solution .right-content .get-in-touch .input-field input:focus {
  outline: none;
}
.defi-services .settlement-solution .right-content .get-in-touch .input-field textarea {
  border: 0px;
  border-bottom: 1px solid #E6E7E8;
  padding: 10px 0px;
  width: 100%;
  resize: none;
  min-height: 80px;
  max-height: 100px;
}
.defi-services .settlement-solution .right-content .get-in-touch .input-field textarea:focus {
  outline: none;
}
.defi-services .settlement-solution .right-content .get-in-touch .button-field {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.defi-services .settlement-solution .right-content .get-in-touch .button-field em {
  display: block;
  text-align: center;
  margin: 10px 0px;
  font-style: normal;
}
.defi-services .settlement-solution .right-content .get-in-touch .button-field button {
  background: #5A79EB;
  color: #FFF;
  border: 0px;
  padding: 10px;
}
.defi-services .settlement-solution .right-content .get-in-touch .button-field button:disabled {
  cursor: not-allowed;
}
.defi-services .settlement-solution .right-content .get-in-touch .button-field button:nth-of-type(2) {
  background: #FFFFFF;
  border: 1px solid #5A79EB;
  color: #5A79EB;
}
.defi-services .settlement-solution .right-content .get-in-touch-form {
  margin-bottom: 100px;
  padding: 0px;
}
.defi-services .settlement-solution .right-content .get-in-touch-form .defi-button {
  background: #fff;
  padding: 20px;
  margin-top: -10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.defi-services .settlement-solution .right-content .get-in-touch-form .defi-button button {
  width: 100%;
}
.defi-services .settlement-solution .right-content iframe {
  border-radius: 0px;
  border: 0px solid #E6E7E8;
  background: url(<path-to-image>), lightgray -40.919px -0.36057862px 123.318% no-repeat;
  box-shadow: 0px 15px 40px 0px rgba(0, 0, 0, 0.1);
  background: #fff;
}
.defi-services .settlement-solution .left-content .more-features a {
  color: #5C7AEA;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-decoration-line: underline;
}
.defi-services .partners-say {
  padding: 100px 0px;
}
.defi-services .partners-say h2 {
  color: #444544;
  text-align: center;
  font-size: 54px;
  font-weight: 700;
  margin-bottom: 50px;
}
.defi-services .partners-say .partner-say-list {
  width: 80%;
  margin: 0px auto;
}
.defi-services .partners-say .partner-say-card {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #DFDFDF;
  margin-right: 5%;
  padding: 15px;
  width: 95% !important;
  min-height: 360px;
}
.defi-services .partners-say .partner-say-card:nth-of-type(3n) {
  margin-right: 0%;
}
.defi-services .partners-say .partner-say-card strong {
  color: #5C7AEA;
  font-size: 18px;
  display: block;
  margin-bottom: 5px;
}
.defi-services .partners-say .partner-say-card b {
  color: #757575;
  display: block;
  width: 100%;
  margin-bottom: 5px;
}
.defi-services .partners-say .partner-say-card em {
  font-size: 14px;
}
.defi-services .partners-say .partner-say-card p {
  color: #757575;
  margin-top: 30px;
  font-size: 14px;
}
.defi-services .partners-say .partner-say-card i img {
  width: 60%;
}
.defi-services .about-info {
  background: #5C7AEA;
  padding: 80px 0px;
}
.defi-services .about-info .about-info-inner {
  display: flex;
  justify-content: space-between;
}
.defi-services .about-info .about-info-inner .about-detail {
  display: inline-flex;
  flex-direction: column;
  color: #FFF;
  width: 25%;
}
.defi-services .about-info .about-info-inner .about-detail br {
  display: block !important;
}
.defi-services .about-info .about-info-inner .about-detail strong {
  font-size: 46px;
  font-weight: 700;
  text-align: center;
}
.defi-services .about-info .about-info-inner .about-detail span {
  text-align: center;
}
.defi-services .features {
  padding: 100px 0px;
}
.defi-services .features h3 {
  text-align: center;
  color: #5C7AEA;
  font-size: 48px;
  font-weight: 700;
}
.defi-services .features .features-wrap {
  width: 80%;
  margin: 50px auto 0px;
  display: flex;
  flex-wrap: wrap;
}
.defi-services .features .features-wrap .feature-list {
  width: 50%;
  display: inline-flex;
}
.defi-services .features .features-wrap .feature-list:last-child {
  width: 100%;
}
.defi-services .features .features-wrap .feature-list i {
  margin-right: 15px;
}
.defi-services .features .features-wrap .feature-list i img {
  width: 50px;
}
.defi-services .features .features-wrap .feature-list b {
  color: #757575;
  font-weight: 700;
  margin-bottom: 20px;
  display: block;
  font-size: 24px;
}
.defi-services .features .features-wrap .feature-list b br {
  display: block !important;
}
.defi-services .features .features-wrap .feature-list p {
  width: 90%;
  font-size: 14px;
}
.defi-services .why-choose-us {
  background: url("/assets/img/defi-services/why-us.png") no-repeat;
  background-size: cover;
  padding: 100px 0px;
}
.defi-services .why-choose-us h4 {
  color: #FFFFFF;
  text-align: center;
  font-size: 48px;
  line-height: 45px;
  font-weight: 700;
}
.defi-services .why-choose-us h4 br {
  display: block !important;
}
.defi-services .why-choose-us p.sub-head {
  margin: 50px auto 50px;
  width: 60%;
  text-align: center;
}
.defi-services .why-choose-us .why-choose-wrap {
  width: 90%;
  margin: 0px auto;
}
.defi-services .why-choose-us .why-choose-wrap .why-choose-card {
  background: #FFFFFF;
  width: 90% !important;
  margin-right: 10% !important;
  padding: 25px;
  display: inline-flex;
  align-items: flex-end;
  min-height: 340px;
}
.defi-services .why-choose-us .why-choose-wrap .why-choose-card strong {
  color: #5C7AEA;
  font-weight: 700;
  font-size: 28px;
  line-height: 28px;
  margin-bottom: 20px;
  display: block;
}
.defi-services .why-choose-us .why-choose-wrap .why-choose-card p {
  color: #757575;
  margin-bottom: 0px;
}
.defi-services .why-choose-us p {
  color: #FFF;
}
.defi-services .question {
  background: #5C7AEA;
  padding: 50px 0px;
}
.defi-services .question h2 {
  text-align: center;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 38px;
}
.defi-services .question .question-inner {
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
}
.defi-services .question .question-inner span {
  color: #FFFFFF;
}
.defi-services .question .question-inner span a {
  color: #FFF;
}
.defi-services .awards-section {
  padding: 100px 0px 50px;
}
.defi-services .awards-section .call-schedule {
  background: #5C7AEA;
  color: #FFFFFF;
  width: 50%;
  margin: 50px auto;
  border: 0px;
  padding: 15px;
  display: flex;
  justify-content: center;
}
.defi-services .awards-section .award-container {
  display: flex;
  justify-content: space-between;
}
.defi-services .awards-section .award-container .award-list {
  display: flex;
  border-radius: 20px;
  background: #FFF;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
  padding: 20px;
  width: 250px;
}
.defi-services .awards-section .award-container .award-list .award-img-wrap {
  min-height: 120px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.defi-services .awards-section .award-container .award-list img {
  height: 120px;
}
.defi-services .awards-section .award-container .award-list:nth-of-type(2) img {
  height: 80px;
}
.defi-services .awards-section .award-container .award-list b {
  color: #444544;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  margin: 10px 0px;
}
.defi-services .awards-section .award-container .award-list p {
  color: #757575;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0px;
}
.defi-services .solution-section-inner {
  display: flex;
  justify-content: space-between;
}
.defi-services .solution-section .left-content {
  width: 35%;
  display: inline-flex;
  flex-direction: column;
}
.defi-services .solution-section .left-content h2 {
  line-height: 46px;
}
.defi-services .solution-section .left-content strong {
  color: #222;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
}
.defi-services .solution-section .left-content ul {
  padding: 0px;
  margin: 50px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.defi-services .solution-section .left-content ul li {
  list-style-type: none;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}
.defi-services .solution-section .left-content ul li img {
  width: 60px;
  height: 60px;
}
.defi-services .solution-section .left-content ul li b {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
}
.defi-services .solution-section .right-content {
  width: 60%;
  display: inline-flex;
  flex-wrap: wrap;
  padding-left: 10%;
}
.defi-services .solution-section .right-content p {
  color: #787878;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 30px;
  line-height: 36px;
}
.defi-services .legacy-system {
  margin: 100px 0px;
  display: flex;
  width: 100%;
}
.defi-services .legacy-system .container {
  border: 1px solid #e6e7e8;
  border-radius: 15px;
  position: relative;
  width: 100%;
}
.defi-services .legacy-system .legacy-system-inner {
  position: relative;
  display: flex;
  justify-content: space-between;
}
.defi-services .legacy-system .legacy-system-inner::before {
  content: '';
  position: absolute;
  background: #EDF1FC;
  width: 100%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  height: 100px;
}
.defi-services .legacy-system .legacy-system-inner i {
  content: "";
  position: absolute;
  width: 63px;
  height: 120px;
  left: 0;
  right: 0;
  top: 100px;
  bottom: 0;
  margin: auto;
}
.defi-services .legacy-system .left-content {
  position: relative;
  width: 40%;
  display: inline-flex;
  flex-direction: column;
  padding: 25px 30px;
}
.defi-services .legacy-system .left-content ul {
  margin-top: 50px;
}
.defi-services .legacy-system .left-content ul li {
  padding: 15px 0px;
  list-style-type: none;
  position: relative;
  padding-left: 50px;
}
.defi-services .legacy-system .left-content ul li::before {
  content: '';
  width: 33px;
  height: 33px;
  background: url("/assets/img/defi-services/left-icon.svg") no-repeat;
  background-size: 100%;
  top: 10px;
  left: 0;
  position: absolute;
}
.defi-services .legacy-system .left-content strong {
  background: #FFF;
  color: #1E4B5E;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  padding: 15px 30px;
  text-align: center;
}
.defi-services .legacy-system .right-content {
  position: relative;
  width: 40%;
  display: inline-flex;
  flex-direction: column;
  padding: 25px 30px;
}
.defi-services .legacy-system .right-content strong {
  background: #5C7AEA;
  color: #FFF;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  padding: 15px 30px;
  text-align: center;
}
.defi-services .legacy-system .right-content ul {
  margin-top: 50px;
}
.defi-services .legacy-system .right-content ul li {
  padding: 15px 0px;
  list-style-type: none;
  position: relative;
  padding-left: 50px;
}
.defi-services .legacy-system .right-content ul li::before {
  content: '';
  width: 33px;
  height: 33px;
  background: url("/assets/img/defi-services/right-icon.svg") no-repeat;
  background-size: 100%;
  top: 10px;
  left: 0;
  position: absolute;
}
.defi-services .solution-features {
  position: relative;
  padding-bottom: 200px;
}
.defi-services .solution-features::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  background: url("/assets/img/defi-services/footer.svg") no-repeat;
  background-size: cover;
  width: 100%;
  height: 140px;
}
.defi-services .solution-features-inner {
  display: flex;
  justify-content: space-between;
}
.defi-services .solution-features .left-content {
  width: 50%;
  display: inline-flex;
  flex-direction: column;
}
.defi-services .solution-features .left-content .feature-mob-img {
  display: none;
}
.defi-services .solution-features .left-content strong {
  color: #222;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 38px;
}
.defi-services .solution-features .left-content ul {
  padding: 0px;
  margin-top: 30px;
  display: block;
  padding-left: 20px;
}
.defi-services .solution-features .left-content ul li {
  color: #787878;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 10px;
}
.defi-services .solution-features .left-content a {
  background: #5C7AEA;
  color: #FFF;
  text-align: center;
  padding: 15px;
  border: 0px;
  width: 250px;
  margin: 15px 0px;
  text-decoration: none;
}
.defi-services .solution-features .right-content {
  width: 50%;
}
.defi-services .solution-features .right-content img {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .defi-services br {
    display: none !important;
  }
  .defi-services .partners-say .partner-say-card {
    margin-right: 5% !important;
  }
  .defi-services .settlement-solution .left-content {
    width: 100%;
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .defi-services .settlement-solution .left-content em {
    text-align: center;
  }
  .defi-services .settlement-solution .left-content br {
    display: none !important;
  }
  .defi-services .settlement-solution .settlement-solution-inner {
    flex-direction: column;
  }
  .defi-services .settlement-solution::after {
    display: none;
  }
  .defi-services .settlement-solution::before {
    width: 100%;
  }
  .defi-services .settlement-solution .right-content {
    width: 100%;
    left: 0;
  }
  .defi-services .awards-section .award-container {
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .defi-services .awards-section .award-container .award-list {
    margin-bottom: 0px;
  }
  .defi-services .awards-section .award-container .award-list img {
    height: 80px;
  }
  .defi-services .awards-section .award-container .award-list:nth-of-type(1) img {
    height: 100px;
    width: auto;
  }
  .defi-services .awards-section {
    padding: 0px;
    margin-bottom: 50px;
  }
  .defi-services .solution-section-inner {
    flex-direction: column;
    align-items: center;
  }
  .defi-services .solution-section-inner .left-content,
  .defi-services .solution-section-inner .right-content {
    width: 100%;
  }
  .defi-services .solution-section-inner .left-content ul {
    flex-direction: column;
  }
  .defi-services .solution-section-inner .left-content ul li:nth-of-type(2) {
    margin: 30px 0px;
  }
  .defi-services .solution-section-inner .right-content {
    padding-left: 0px;
  }
  .defi-services .legacy-system .legacy-system-inner {
    flex-direction: column;
  }
  .defi-services .legacy-system .legacy-system-inner .left-content,
  .defi-services .legacy-system .legacy-system-inner .right-content {
    width: 100%;
  }
  .defi-services .legacy-system .legacy-system-inner .left-content {
    margin-bottom: 100px;
  }
  .defi-services .legacy-system .legacy-system-inner i {
    transform: rotate(90deg);
    top: 0px;
  }
  .defi-services .solution-features-inner {
    flex-direction: column;
  }
  .defi-services .solution-features-inner .left-content {
    width: 100%;
    margin-bottom: 50px;
  }
  .defi-services .solution-features-inner .right-content {
    width: 100%;
  }
  .defi-services .defi-services .solution-features-inner .left-content .feature-mob-img {
    margin-top: 30px;
    display: block;
  }
  .defi-services .defi-services .solution-features-inner .right-content img {
    display: none;
  }
}
@media screen and (orientation: landscape) {
  .defi-services .settlement-solution::before {
    height: 100%;
  }
  .defi-services .solution-section-inner .left-content ul {
    flex-direction: row;
  }
}
.defi-services .awards-section .award-container .mob-view {
  display: none;
}
.defi-services section.awards-section.mob-view {
  display: none;
}
.modal-content .modal-body iframe {
  border: 0;
}
.defi-services button.slick-arrow {
  z-index: 1;
}
.defi-services .slick-prev:before,
.defi-services .slick-next:before {
  box-shadow: 0 0 10px #ddd;
}
@media screen and (max-width: 768px) {
  .defi-services .header {
    padding: 10px 30px 0;
  }
  .defi-services .settlement-solution .right-content {
    margin-top: 30px;
  }
  .defi-services .settlement-solution .right-content span {
    font-size: 16px;
    top: -45px;
  }
  .defi-services .settlement-solution .right-content span::after {
    right: 140px;
  }
  .defi-services .settlement-solution {
    padding-top: 70px;
  }
  .defi-services .settlement-solution .left-content {
    margin-bottom: 20px;
    padding: 0 15px;
  }
  .defi-services .settlement-solution .left-content h1 {
    font-size: 20px;
    line-height: 30px;
    text-align: center;
  }
  .defi-services .settlement-solution .left-content em {
    font-size: 14px;
    margin: 10px 0px 15px;
  }
  .defi-services .settlement-solution .left-content ul li {
    font-size: 13px;
    margin-bottom: 15px;
  }
  .defi-services .settlement-solution .left-content .more-features a {
    font-size: 12px;
  }
  .defi-services .settlement-solution .right-content iframe {
    min-height: 530px;
  }
  .defi-services .awards-section {
    margin: 10px 0px;
  }
  .defi-services .awards-section .container {
    padding: 0;
  }
  .defi-services .awards-section .award-container .award-list {
    display: none;
  }
  .defi-services .awards-section .award-container .mob-view {
    display: inline-flex;
    margin: 20px 0px 0;
  }
  .defi-services .solution-section .left-content h2 {
    line-height: 30px;
    font-size: 20px;
    text-align: center;
  }
  .defi-services .solution-section-inner .left-content ul {
    margin: 10px 0px 15px;
    flex-direction: row;
  }
  .defi-services .solution-section-inner .left-content ul li:nth-of-type(2) {
    margin: 0;
  }
  .defi-services .solution-section .left-content ul li img {
    width: 40px;
    height: 40px;
  }
  .defi-services .solution-section .left-content ul li b {
    font-size: 13px;
    margin-top: 5px;
  }
  .defi-services .solution-section .right-content p {
    font-size: 13px;
    line-height: 26px;
    margin-bottom: 10px;
  }
  .defi-services .legacy-system {
    margin: 20px 0px 30px;
  }
  .defi-services .legacy-system .container {
    padding: 0;
  }
  .defi-services .legacy-system .legacy-system-inner::before {
    height: 50px;
  }
  .defi-services .legacy-system .legacy-system-inner .left-content {
    margin-bottom: 10px;
  }
  .defi-services .legacy-system .legacy-system-inner .left-content,
  .defi-services .legacy-system .legacy-system-inner .right-content {
    padding: 10px;
  }
  .defi-services .legacy-system .left-content strong,
  .defi-services .legacy-system .right-content strong {
    font-size: 15px;
    line-height: 24px;
    padding: 10px 30px;
  }
  .defi-services .legacy-system .left-content ul {
    margin-top: 30px;
    padding-left: 20px;
  }
  .defi-services .legacy-system .left-content ul li {
    padding: 10px 0px;
    padding-left: 50px;
    font-size: 13px;
  }
  .defi-services .legacy-system .left-content ul li::before,
  .defi-services .legacy-system .right-content ul li::before {
    width: 28px;
    height: 28px;
    background-size: 80%;
  }
  .defi-services .legacy-system .legacy-system-inner i {
    top: 30px;
  }
  .defi-services .legacy-system .legacy-system-inner i svg {
    width: 20px;
  }
  .defi-services .legacy-system .right-content ul {
    margin-top: 10px;
    padding-left: 20px;
  }
  .defi-services .legacy-system .right-content ul li {
    padding: 10px 0px;
    padding-left: 50px;
    font-size: 13px;
  }
  .defi-services .solution-features {
    padding-bottom: 100px;
  }
  .defi-services .solution-features-inner .left-content {
    margin-bottom: 30px;
  }
  .defi-services .solution-features-inner .left-content .feature-mob-img {
    margin-top: 30px;
    display: block;
  }
  .defi-services .solution-features-inner .right-content img {
    display: none;
  }
  .defi-services .solution-features-inner .left-content h3 {
    font-size: 20px;
    line-height: 30px;
    margin: 0;
  }
  .defi-services .solution-features .left-content ul {
    margin: 20px 0;
  }
  .defi-services .solution-features .left-content ul li {
    font-size: 13px;
    line-height: 26px;
  }
  .defi-services .solution-features .left-content a {
    margin: 0px 0px;
    font-size: 15px;
  }
  .defi-services .legacy-system .legacy-system-inner {
    flex-direction: row;
  }
  .defi-services .legacy-system .legacy-system-inner .left-content,
  .defi-services .legacy-system .legacy-system-inner .right-content {
    padding: 10px;
    margin: 0;
  }
  .defi-services .legacy-system .left-content strong,
  .defi-services .legacy-system .right-content strong {
    font-size: 12px;
    line-height: 18px;
    padding: 5px 8px;
  }
  .defi-services .legacy-system .left-content ul,
  .defi-services .legacy-system .right-content ul {
    padding-left: 0;
    margin: 30px 0 0;
  }
  .defi-services .legacy-system .left-content ul li,
  .defi-services .legacy-system .right-content ul li {
    padding: 10px 0px;
    padding-left: 25px;
    font-size: 13px;
    min-height: 60px;
    line-height: 22px;
  }
  .defi-services .legacy-system .left-content ul li::before,
  .defi-services .legacy-system .right-content ul li::before {
    width: 25px;
    height: 25px;
    background-size: 70%;
    top: 10px;
  }
  .defi-services .legacy-system .legacy-system-inner i {
    top: 40px;
    transform: rotate(0deg);
    right: -30px;
    position: static;
    width: 40px;
  }
  .defi-services section.awards-section.mob-view {
    display: block;
    margin: 0;
  }
  .defi-services .awards-section .award-container .mob-view img {
    width: 100%;
  }
  .defi-services .solution-features-inner .left-content .feature-mob-img img {
    width: 100%;
  }
  .defi-services .settlement-solution .right-content .thank-you-screen {
    padding: 50px 0px;
  }
  .defi-services .settlement-solution .right-content .thank-you-screen b {
    text-align: center;
  }
  .defi-services .partners-say h2,
  .defi-services .settlement-solution .right-content .get-in-touch strong,
  .defi-services .why-choose-us h4 {
    font-size: 28px;
  }
  .defi-services .features h3 {
    font-size: 38px;
  }
  .defi-services .settlement-solution .right-content .get-in-touch {
    padding: 25px;
  }
  .defi-services .why-choose-us p.sub-head {
    width: 100%;
  }
  .defi-services .awards-section .call-schedule {
    width: 100%;
    margin: 30px 0px;
  }
  .defi-services .question h2 {
    text-align: left;
    font-size: 28px;
    margin: 0px auto;
    max-width: 90%;
  }
  .defi-services .question .question-inner {
    flex-direction: column;
    line-height: 35px;
    margin: 20px 0px;
  }
  .defi-services .features .features-wrap {
    width: 100%;
  }
}
@media screen and (max-width: 520px) {
  .defi-services .features .features-wrap .feature-list {
    width: 100%;
  }
  .defi-services .features .features-wrap {
    width: 95%;
  }
  .defi-services .about-info .about-info-inner {
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
  .defi-services .about-info .about-info-inner .about-detail {
    width: 50%;
    margin-bottom: 20px;
    flex: 0 0 50%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
  }
  .defi-services .partners-say .partner-say-card,
  .defi-services .why-choose-us .why-choose-wrap .why-choose-card {
    margin-right: 0px !important;
    width: 100% !important;
  }
  .defi-services .partners-say h2,
  .defi-services .settlement-solution .right-content .get-in-touch strong,
  .defi-services .why-choose-us h4 {
    font-size: 25px;
  }
}
