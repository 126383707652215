.stickyNotePostTrade.icon-sticky {
  display: inline-flex;
  flex-direction: column;
  padding: 10px;
  width: 150px;
}
.stickyNotePostTrade.icon-sticky img {
  width: 30px;
  height: 30px;
  margin: 0;
  margin-right: 5px;
}
.stickyNotePostTrade.icon-sticky a {
  display: inline-flex;
  flex-direction: column;
}
.stickyNotePostTrade.icon-sticky a:hover {
  color: #16181b;
}
.stickyNotePostTrade.icon-sticky p {
  font-size: 8px;
  margin: 0;
}
.stickyNotePostTrade.icon-sticky span {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  margin: 3px 0px;
}
.stickyNotePostTrade.icon-sticky h6 {
  font-weight: bold;
  font-size: 13px;
  line-height: 20px;
  margin: 0;
}
.stickyNotePostTrade.icon-sticky strong {
  font-size: 20px;
}
@media only screen and (max-width: 768px) {
  .stickyNotePostTrade {
    transform: translateX(100px);
    transition: all 0.7s ease-in;
    border-radius: 0px 0px 0px 13px!important;
  }
  .stickyNotePostTrade:hover {
    transform: translateX(0px);
    transition: all 0.7s ease-in;
  }
  .stickyNotePostTrade:before {
    content: "<<";
    position: absolute;
    left: -20px;
    top: 0px;
    background: #204b5d;
    width: 20px;
    height: 20px;
    font-size: 10px;
    color: #fff;
    padding: 5px 5px 3px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px 0px 0px 20px;
  }
}
