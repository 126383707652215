.career .section-1 {
  padding-top: 150px;
  height: auto;
  background: linear-gradient(90deg, #F7FFFD 0%, #FDFDFD 100%), #F9F9F9;
}
.career .section-1 .main-head-1 b {
  color: #1E4B5E;
  font-family: 'Object Sans Heavy';
  font-size: 16px;
}
.career .section-1 .main-head-1 b a {
  text-decoration: underline;
  color: #1E4B5E;
}
.career .custom-container {
  flex-wrap: wrap;
}
.career-inner .MuiAccordionDetails-root {
  flex-wrap: wrap;
}
.career-inner .MuiAccordionSummary-content {
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
}
.career-inner .MuiTypography-root {
  font-family: "Object Sans";
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 15px;
  color: #1E4B5E;
  line-height: 22px;
}
.career-inner .MuiAccordionSummary-content .heading,
.career-inner .MuiAccordionSummary-content .secondaryHeading {
  font-family: 'Object Sans Heavy';
  color: #1E4B5E;
  margin-bottom: 0px;
}
.career-inner .MuiAccordionSummary-content .secondaryHeading {
  margin-right: 15px;
  color: #24A989;
}
.career-inner .MuiAccordionSummary-content .secondaryHeading svg {
  display: inline-flex;
  margin-top: -3px;
}
.career-inner .MuiAccordionSummary-content .secondaryHeading svg path {
  fill: #FFCC4A;
}
.career-inner .MuiAccordionDetails-root strong {
  color: #24A989;
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 10px;
  display: block;
  width: 100%;
}
.career-inner .MuiAccordionDetails-root ul li {
  font-family: "Object Sans";
  font-weight: 500;
  font-size: 16px;
  color: #1E4B5E;
  line-height: 22px;
  margin-bottom: 10px;
}
.career-inner .MuiAccordionDetails-root ul li em {
  font-style: normal;
  font-weight: 700;
}
.career-inner .MuiAccordionDetails-root .job-details {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 15px;
}
.career-inner .MuiAccordionDetails-root .job-details span {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 5px;
}
.career-inner .MuiAccordionDetails-root .job-details span b {
  color: #1E4B5E;
}
.career-inner .MuiAccordionDetails-root .job-details i {
  font-style: normal;
  color: #24A989;
  margin-left: 5px;
}
.career-inner .MuiAccordion-root {
  padding: 0px 15px;
}
