.modern-post-trade .container {
  max-width: 90%;
  margin: 0px auto;
  /* width: 100%; */
  padding: 0px;
}
.modern-post-trade .header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 15px 30px;
  z-index: 1;
}
.modern-post-trade .settlement-solution {
  position: relative;
  padding-top: 100px;
}
.modern-post-trade .settlement-solution .left-content h1 {
  font-weight: 500;
  font-size: 30px;
  position: relative;
  line-height: 46px;
}
.modern-post-trade .settlement-solution .left-content {
  width: 50%;
  position: relative;
}
.modern-post-trade .settlement-solution .left-content em {
  color: #5C7AEA;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  margin: 15px 0px 30px;
  display: block;
}
.modern-post-trade .settlement-solution .left-content ul {
  padding: 0px 30px;
  margin: 0px;
}
.modern-post-trade .settlement-solution .left-content ul li {
  color: #787878;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 25px;
}
.modern-post-trade .settlement-solution .settlement-solution-inner {
  display: flex;
}
.modern-post-trade .settlement-solution .right-content {
  width: 50%;
  position: relative;
  left: -30px;
}
.modern-post-trade .settlement-solution .right-content span {
  position: absolute;
  color: #5C7AEA;
  font-weight: bold;
  font-size: 21px;
  top: -50px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: right;
}
.modern-post-trade .settlement-solution .right-content span::after {
  content: '';
  position: absolute;
  right: 180px;
  top: 15px;
  background: url("/assets/img/modern-post-trade/arrow-icon.png") no-repeat;
  width: 55px;
  height: 55px;
}
.modern-post-trade .settlement-solution .right-content iframe {
  border-radius: 20px;
  border: 2px solid #E6E7E8;
  background: url(<path-to-image>), lightgray -40.919px -0.36057862px 123.318% no-repeat;
  box-shadow: 0px 15px 40px 0px rgba(0, 0, 0, 0.1);
}
.modern-post-trade .settlement-solution::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  background: #EDF1FC;
  backdrop-filter: blur(5px);
  height: 100vh;
  z-index: -1;
}
.modern-post-trade .settlement-solution::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -150px;
  width: 100%;
  height: 100%;
  background: url("/assets/img/modern-post-trade/line-bg.png") no-repeat;
  background-size: 100%;
  z-index: -1;
}
.modern-post-trade .settlement-solution .left-content .more-features a {
  color: #5C7AEA;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-decoration-line: underline;
}
.modern-post-trade .awards-section {
  margin: 100px 0px;
  padding: 100px 0px 0px;
}
.modern-post-trade .awards-section .award-container {
  display: flex;
  justify-content: space-between;
}
.modern-post-trade .awards-section .award-container .award-list {
  display: flex;
  border-radius: 20px;
  border: 2px solid #E6E7E8;
  background: #FFF;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.05);
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
  padding: 20px;
  width: 250px;
}
.modern-post-trade .awards-section .award-container .award-list .award-img-wrap {
  min-height: 120px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.modern-post-trade .awards-section .award-container .award-list img {
  height: 120px;
}
.modern-post-trade .awards-section .award-container .award-list:nth-of-type(2) img {
  height: 80px;
}
.modern-post-trade .awards-section .award-container .award-list b {
  color: #444544;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  margin: 10px 0px;
}
.modern-post-trade .awards-section .award-container .award-list p {
  color: #757575;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0px;
}
.modern-post-trade .solution-section-inner {
  display: flex;
  justify-content: space-between;
}
.modern-post-trade .solution-section .left-content {
  width: 35%;
  display: inline-flex;
  flex-direction: column;
}
.modern-post-trade .solution-section .left-content h2 {
  line-height: 46px;
}
.modern-post-trade .solution-section .left-content strong {
  color: #222;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
}
.modern-post-trade .solution-section .left-content ul {
  padding: 0px;
  margin: 50px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modern-post-trade .solution-section .left-content ul li {
  list-style-type: none;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}
.modern-post-trade .solution-section .left-content ul li img {
  width: 60px;
  height: 60px;
}
.modern-post-trade .solution-section .left-content ul li b {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
}
.modern-post-trade .solution-section .right-content {
  width: 60%;
  display: inline-flex;
  flex-wrap: wrap;
  padding-left: 10%;
}
.modern-post-trade .solution-section .right-content p {
  color: #787878;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 30px;
  line-height: 36px;
}
.modern-post-trade .legacy-system {
  margin: 100px 0px;
  display: flex;
  width: 100%;
}
.modern-post-trade .legacy-system .container {
  border: 1px solid #e6e7e8;
  border-radius: 15px;
  position: relative;
  width: 100%;
}
.modern-post-trade .legacy-system .legacy-system-inner {
  position: relative;
  display: flex;
  justify-content: space-between;
}
.modern-post-trade .legacy-system .legacy-system-inner::before {
  content: '';
  position: absolute;
  background: #EDF1FC;
  width: 100%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  height: 100px;
}
.modern-post-trade .legacy-system .legacy-system-inner i {
  content: "";
  position: absolute;
  width: 63px;
  height: 120px;
  left: 0;
  right: 0;
  top: 100px;
  bottom: 0;
  margin: auto;
}
.modern-post-trade .legacy-system .left-content {
  position: relative;
  width: 40%;
  display: inline-flex;
  flex-direction: column;
  padding: 25px 30px;
}
.modern-post-trade .legacy-system .left-content ul {
  margin-top: 50px;
}
.modern-post-trade .legacy-system .left-content ul li {
  padding: 15px 0px;
  list-style-type: none;
  position: relative;
  padding-left: 50px;
}
.modern-post-trade .legacy-system .left-content ul li::before {
  content: '';
  width: 33px;
  height: 33px;
  background: url("/assets/img/modern-post-trade/left-icon.svg") no-repeat;
  background-size: 100%;
  top: 10px;
  left: 0;
  position: absolute;
}
.modern-post-trade .legacy-system .left-content strong {
  background: #FFF;
  color: #1E4B5E;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  padding: 15px 30px;
  text-align: center;
}
.modern-post-trade .legacy-system .right-content {
  position: relative;
  width: 40%;
  display: inline-flex;
  flex-direction: column;
  padding: 25px 30px;
}
.modern-post-trade .legacy-system .right-content strong {
  background: #5C7AEA;
  color: #FFF;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  padding: 15px 30px;
  text-align: center;
}
.modern-post-trade .legacy-system .right-content ul {
  margin-top: 50px;
}
.modern-post-trade .legacy-system .right-content ul li {
  padding: 15px 0px;
  list-style-type: none;
  position: relative;
  padding-left: 50px;
}
.modern-post-trade .legacy-system .right-content ul li::before {
  content: '';
  width: 33px;
  height: 33px;
  background: url("/assets/img/modern-post-trade/right-icon.svg") no-repeat;
  background-size: 100%;
  top: 10px;
  left: 0;
  position: absolute;
}
.modern-post-trade .solution-features {
  position: relative;
  padding-bottom: 200px;
}
.modern-post-trade .solution-features::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  background: url("/assets/img/modern-post-trade/footer.svg") no-repeat;
  background-size: cover;
  width: 100%;
  height: 140px;
}
.modern-post-trade .solution-features-inner {
  display: flex;
  justify-content: space-between;
}
.modern-post-trade .solution-features .left-content {
  width: 50%;
  display: inline-flex;
  flex-direction: column;
}
.modern-post-trade .solution-features .left-content .feature-mob-img {
  display: none;
}
.modern-post-trade .solution-features .left-content strong {
  color: #222;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 38px;
}
.modern-post-trade .solution-features .left-content ul {
  padding: 0px;
  margin-top: 30px;
  display: block;
  padding-left: 20px;
}
.modern-post-trade .solution-features .left-content ul li {
  color: #787878;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 10px;
}
.modern-post-trade .solution-features .left-content a {
  background: #5C7AEA;
  color: #FFF;
  text-align: center;
  padding: 15px;
  border: 0px;
  width: 250px;
  margin: 15px 0px;
  text-decoration: none;
}
.modern-post-trade .solution-features .right-content {
  width: 50%;
}
.modern-post-trade .solution-features .right-content img {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .modern-post-trade br {
    display: none;
  }
  .modern-post-trade .settlement-solution .left-content {
    width: 100%;
    margin-bottom: 30px;
  }
  .modern-post-trade .settlement-solution .settlement-solution-inner {
    flex-direction: column;
  }
  .modern-post-trade .settlement-solution::after {
    display: none;
  }
  .modern-post-trade .settlement-solution::before {
    width: 100%;
  }
  .modern-post-trade .settlement-solution .right-content {
    width: 100%;
    left: 0;
  }
  .modern-post-trade .awards-section .award-container {
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .modern-post-trade .awards-section .award-container .award-list {
    margin-bottom: 20px;
  }
  .modern-post-trade .awards-section .award-container .award-list img {
    height: 80px;
  }
  .modern-post-trade .awards-section .award-container .award-list:nth-of-type(1) img {
    height: 100px;
    width: auto;
  }
  .modern-post-trade .awards-section {
    padding: 0px;
    margin-bottom: 50px;
  }
  .modern-post-trade .solution-section-inner {
    flex-direction: column;
    align-items: center;
  }
  .modern-post-trade .solution-section-inner .left-content,
  .modern-post-trade .solution-section-inner .right-content {
    width: 100%;
  }
  .modern-post-trade .solution-section-inner .left-content ul {
    flex-direction: column;
  }
  .modern-post-trade .solution-section-inner .left-content ul li:nth-of-type(2) {
    margin: 30px 0px;
  }
  .modern-post-trade .solution-section-inner .right-content {
    padding-left: 0px;
  }
  .modern-post-trade .legacy-system .legacy-system-inner {
    flex-direction: column;
  }
  .modern-post-trade .legacy-system .legacy-system-inner .left-content,
  .modern-post-trade .legacy-system .legacy-system-inner .right-content {
    width: 100%;
  }
  .modern-post-trade .legacy-system .legacy-system-inner .left-content {
    margin-bottom: 100px;
  }
  .modern-post-trade .legacy-system .legacy-system-inner i {
    transform: rotate(90deg);
    top: 0px;
  }
  .modern-post-trade .solution-features-inner {
    flex-direction: column;
  }
  .modern-post-trade .solution-features-inner .left-content {
    width: 100%;
    margin-bottom: 50px;
  }
  .modern-post-trade .solution-features-inner .right-content {
    width: 100%;
  }
  .modern-post-trade .modern-post-trade .solution-features-inner .left-content .feature-mob-img {
    margin-top: 30px;
    display: block;
  }
  .modern-post-trade .modern-post-trade .solution-features-inner .right-content img {
    display: none;
  }
}
@media screen and (orientation: landscape) {
  .modern-post-trade .settlement-solution::before {
    height: 100%;
  }
  .modern-post-trade .solution-section-inner .left-content ul {
    flex-direction: row;
  }
}
.modern-post-trade .awards-section .award-container .mob-view {
  display: none;
}
.modern-post-trade section.awards-section.mob-view {
  display: none;
}
@media screen and (max-width: 768px) {
  .modern-post-trade .header {
    padding: 10px 30px 0;
  }
  .modern-post-trade .settlement-solution .right-content {
    margin-top: 30px;
  }
  .modern-post-trade .settlement-solution .right-content span {
    font-size: 16px;
    top: -45px;
  }
  .modern-post-trade .settlement-solution .right-content span::after {
    right: 140px;
  }
  .modern-post-trade .settlement-solution {
    padding-top: 70px;
  }
  .modern-post-trade .settlement-solution .left-content {
    margin-bottom: 20px;
  }
  .modern-post-trade .settlement-solution .left-content h1 {
    font-size: 20px;
    line-height: 30px;
    text-align: center;
  }
  .modern-post-trade .settlement-solution .left-content em {
    font-size: 14px;
    margin: 10px 0px 15px;
  }
  .modern-post-trade .settlement-solution .left-content ul li {
    font-size: 13px;
    margin-bottom: 15px;
  }
  .modern-post-trade .settlement-solution .left-content .more-features a {
    font-size: 12px;
  }
  .modern-post-trade .settlement-solution .right-content iframe {
    min-height: 530px;
  }
  .modern-post-trade .awards-section {
    margin: 10px 0px;
  }
  .modern-post-trade .awards-section .container {
    padding: 0;
  }
  .modern-post-trade .awards-section .award-container .award-list {
    display: none;
  }
  .modern-post-trade .awards-section .award-container .mob-view {
    display: inline-flex;
  }
  .modern-post-trade .solution-section .left-content h2 {
    line-height: 30px;
    font-size: 20px;
    text-align: center;
  }
  .modern-post-trade .solution-section-inner .left-content ul {
    margin: 10px 0px 15px;
    flex-direction: row;
  }
  .modern-post-trade .solution-section-inner .left-content ul li:nth-of-type(2) {
    margin: 0;
  }
  .modern-post-trade .solution-section .left-content ul li img {
    width: 40px;
    height: 40px;
  }
  .modern-post-trade .solution-section .left-content ul li b {
    font-size: 13px;
    margin-top: 5px;
  }
  .modern-post-trade .solution-section .right-content p {
    font-size: 13px;
    line-height: 26px;
    margin-bottom: 10px;
  }
  .modern-post-trade .legacy-system {
    margin: 20px 0px 30px;
  }
  .modern-post-trade .legacy-system .container {
    padding: 0;
  }
  .modern-post-trade .legacy-system .legacy-system-inner::before {
    height: 50px;
  }
  .modern-post-trade .legacy-system .legacy-system-inner .left-content {
    margin-bottom: 10px;
  }
  .modern-post-trade .legacy-system .legacy-system-inner .left-content,
  .modern-post-trade .legacy-system .legacy-system-inner .right-content {
    padding: 10px;
  }
  .modern-post-trade .legacy-system .left-content strong,
  .modern-post-trade .legacy-system .right-content strong {
    font-size: 15px;
    line-height: 24px;
    padding: 10px 30px;
  }
  .modern-post-trade .legacy-system .left-content ul {
    margin-top: 30px;
    padding-left: 20px;
  }
  .modern-post-trade .legacy-system .left-content ul li {
    padding: 10px 0px;
    padding-left: 50px;
    font-size: 13px;
  }
  .modern-post-trade .legacy-system .left-content ul li::before,
  .modern-post-trade .legacy-system .right-content ul li::before {
    width: 28px;
    height: 28px;
    background-size: 80%;
  }
  .modern-post-trade .legacy-system .legacy-system-inner i {
    top: 30px;
  }
  .modern-post-trade .legacy-system .legacy-system-inner i svg {
    width: 20px;
  }
  .modern-post-trade .legacy-system .right-content ul {
    margin-top: 10px;
    padding-left: 20px;
  }
  .modern-post-trade .legacy-system .right-content ul li {
    padding: 10px 0px;
    padding-left: 50px;
    font-size: 13px;
  }
  .modern-post-trade .solution-features {
    padding-bottom: 100px;
  }
  .modern-post-trade .solution-features-inner .left-content {
    margin-bottom: 30px;
  }
  .modern-post-trade .solution-features-inner .left-content .feature-mob-img {
    margin-top: 30px;
    display: block;
  }
  .modern-post-trade .solution-features-inner .right-content img {
    display: none;
  }
  .modern-post-trade .solution-features-inner .left-content h3 {
    font-size: 20px;
    line-height: 30px;
    margin: 0;
  }
  .modern-post-trade .solution-features .left-content ul {
    margin: 20px 0;
  }
  .modern-post-trade .solution-features .left-content ul li {
    font-size: 13px;
    line-height: 26px;
  }
  .modern-post-trade .solution-features .left-content a {
    margin: 0px 0px;
    font-size: 15px;
  }
  .modern-post-trade .legacy-system .legacy-system-inner {
    flex-direction: row;
  }
  .modern-post-trade .legacy-system .legacy-system-inner .left-content,
  .modern-post-trade .legacy-system .legacy-system-inner .right-content {
    padding: 10px;
    margin: 0;
  }
  .modern-post-trade .legacy-system .left-content strong,
  .modern-post-trade .legacy-system .right-content strong {
    font-size: 12px;
    line-height: 18px;
    padding: 5px 8px;
  }
  .modern-post-trade .legacy-system .left-content ul,
  .modern-post-trade .legacy-system .right-content ul {
    padding-left: 0;
    margin: 30px 0 0;
  }
  .modern-post-trade .legacy-system .left-content ul li,
  .modern-post-trade .legacy-system .right-content ul li {
    padding: 10px 0px;
    padding-left: 25px;
    font-size: 13px;
    min-height: 60px;
    line-height: 22px;
  }
  .modern-post-trade .legacy-system .left-content ul li::before,
  .modern-post-trade .legacy-system .right-content ul li::before {
    width: 25px;
    height: 25px;
    background-size: 70%;
    top: 10px;
  }
  .modern-post-trade .legacy-system .legacy-system-inner i {
    top: 40px;
    transform: rotate(0deg);
    right: -30px;
    position: static;
    width: 40px;
  }
  .modern-post-trade section.awards-section.mob-view {
    display: block;
    margin: 0;
  }
  .modern-post-trade .awards-section .award-container .mob-view img {
    width: 100%;
  }
  .modern-post-trade .solution-features-inner .left-content .feature-mob-img img {
    width: 100%;
  }
  .modern-post-trade .settlement-solution .right-content .thank-you-screen {
    padding: 50px 0px;
  }
  .modern-post-trade .settlement-solution .right-content .thank-you-screen b {
    text-align: center;
  }
}
