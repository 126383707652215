.FinTech.Enterprise .section-1 {
  background-image: url(/assets/img/fintech-img/fintech-bg.png) !important;
  background-size: cover;
  display: flex;
  align-items: center;
}
.FinTech.Enterprise .section-1 a.connect-btn {
  font-size: 24px;
  color: #1E4B5E;
  font-family: "Object Sans heavy";
  line-height: 24px;
}
.FinTech .section-2.marketplace {
  padding: 100px 0px;
}
.FinTech .company-info .comp-info-right-col {
  justify-content: flex-end;
}
.FinTech .main-head-1.custom-fin-head {
  max-width: 720px;
}
.FinTech .service-head h4 {
  width: 400px;
}
.port-card .more-text {
  min-height: 26px;
}
.connect-text {
  color: #007CC1;
  font-size: 18px;
  text-decoration: underline;
  font-family: 'Object Sans Heavy';
  font-style: normal;
  cursor: pointer;
  transition: all 0.5s ease;
  padding: 0;
  background-color: transparent;
  border: 1px solid transparent;
  transition: all 0.3s ease;
}
.connect-text:hover {
  text-decoration: none;
  color: #4283a7;
}
.section-5 a.connect-btn {
  font-size: 24px;
  color: #1E4B5E;
  font-family: "Object Sans heavy";
  line-height: 24px;
}
@media only screen and (max-width: 768px) {
  .Enterprise.FinTech .technology .tc-col {
    max-width: 50%;
    flex: inherit;
  }
  .resp-health-cards {
    max-width: 50%;
    flex: inherit;
  }
  .Enterprise.FinTech .service-head h4 {
    font-style: normal;
    line-height: 30px;
    font-size: 24px;
  }
  .Enterprise.FinTech .appr-col {
    max-width: 45%;
    flex: inherit;
  }
  .Enterprise.FinTech .offset-xl-4.appr-col {
    margin-left: 0px !important;
  }
  .FinTech .service-head h4 {
    width: 100%;
  }
}
@media only screen and (max-width: 678px) {
  .Enterprise.FinTech .section-1 {
    height: auto;
    padding: 140px 0px 0px 0px;
    background-image: url(/assets/img/fintech-img/fintech-mob-bg.svg) !important;
  }
  .Enterprise.FinTech .company-info .offset-xl-4,
  .Enterprise.FinTech .crypto-service .offset-xl-4 {
    margin-left: 0px !important;
  }
  .Enterprise.FinTech .service-head h4 {
    font-size: 24px;
    line-height: 30px;
  }
  .Enterprise.FinTech .connect-with-custom-section .main-head-div .secondary-span {
    font-size: 30px !important;
    line-height: 37px !important;
  }
  .Enterprise.FinTech .technology .tc-col {
    max-width: 100%;
    flex: inherit;
  }
  .resp-health-cards {
    max-width: 100%;
    flex: inherit;
  }
  .Enterprise.FinTech .appr-col {
    max-width: 100%;
    flex: inherit;
    margin-right: 0px;
  }
  .Enterprise.FinTech .appr-col .ylow-bx {
    margin: 0 auto;
  }
  .Enterprise.FinTech .appr-col .service-head h4 {
    max-width: 100%;
    text-align: center;
    margin: 10px 0px;
  }
  .Enterprise.FinTech .service-head h4 {
    font-size: 20px;
    line-height: 25px;
  }
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-1 {
  text-align: left;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-1::before {
  background-image: url(/assets/img/trade-automation/trade-automation-banner.png) !important;
  background-size: 100%;
  background-position: center;
  height: 100%;
  opacity: 1;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-1 h1.main-head-1.custom-htech-head .primary-span,
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-1 h1.main-head-1.custom-htech-head .secondary-span {
  font-size: 46px;
  font-weight: 800;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: center;
  color: #24A989;
  margin: 40px auto 30px;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-1 h2 {
  font-size: 30px;
  font-weight: 600;
  line-height: 52px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #fff;
  margin: 0px;
  font-family: 'Object Sans Heavy';
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-1 h2 span.primary-span {
  color: #24A989;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-1 p {
  color: #fff;
  text-align: center;
  margin: 30px 0px 50px;
  font-size: 20px;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .main-para-div {
  text-align: center;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .main-para-div.short-div {
  position: relative;
  bottom: 0px;
  padding: 0px;
  background: #000000;
  width: 100%;
  left: 0;
  margin-bottom: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .main-para-div.short-div ul {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #fff;
  margin: 0;
  padding: 0;
  width: 80%;
  margin: 0 auto;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .main-para-div.short-div ul li {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0px 50px;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .main-para-div.short-div ul li a {
  color: #fff;
  margin: 15px auto;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .main-para-div.short-div ul li::before {
  position: absolute;
  content: '';
  width: 1px;
  height: 25px;
  background: #fff;
  right: -50px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .main-para-div.short-div ul li:last-child::before {
  display: none;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section1 .section-1 h2 .primary-span {
  font-size: 46px;
  font-weight: 800;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: center;
  color: #24A989;
  margin: 40px auto 30px;
  font-family: 'Heldane Display';
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section2 {
  background: linear-gradient(90deg, #103744 0%, #1C7E67 100%);
  background: linear-gradient(180deg, #103744 0.81%, #1C7E67 97.84%);
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section2 .title-center {
  margin: auto;
  width: 100%;
  text-align: center;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section2 .title-center h2 span {
  color: #fff;
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  width: 100%;
  margin: auto;
  line-height: 45px;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section2 .title-center h1 span {
  font-weight: 600;
  font-size: 35px;
  line-height: 45px;
  font-family: 'Object Sans Heavy';
  margin: 30px 0;
  color: #24A989;
  display: inline-flex;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section2 .title-center p {
  color: #fff;
  letter-spacing: 0.5px;
  font-weight: 600;
  font-family: 'Object Sans';
  line-height: 30px;
  text-align: center;
  font-size: 16px;
  margin-top: 20px;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section3 {
  padding: 0;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section3 .section-2.white-bg .main-head-div.custom-main-head-div h3 span.primary-span {
  text-align: center;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section3 .content-section .solution-list {
  background: linear-gradient(90deg, #103744 0%, #1C7E67 100%);
  align-items: center;
  justify-content: center;
  margin: 0 !important;
  padding: 80px;
  border: none;
  border-radius: 0;
  min-height: 250px;
  min-width: 33.3%;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section3 .content-section .solution-list:nth-of-type(4),
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section3 .content-section .solution-list:nth-of-type(5) {
  min-width: 50%;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section3 .content-section .solution-list:hover {
  background: linear-gradient(90deg, #103744 0%, #168d71 100%);
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section3 .content-section .solution-list p {
  max-width: 200px;
  font-size: 18px;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section4 .solution-list {
  margin: 20px auto;
  box-shadow: none;
  background: #fff;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  max-width: 48%;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section4 .solution-list i {
  border-radius: 0;
  background: transparent;
  border: none;
  box-shadow: none;
  width: 50px;
  padding: 0px;
  margin: 10px 0px;
  margin-right: 10px;
  display: inline-flex;
  flex: 1 1 20%;
  align-items: center;
  justify-content: center;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section4 .solution-list i img {
  width: 100%;
  height: 50px;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section4 .solution-list p {
  color: #000;
  font-weight: 400;
  margin: 15px 0px 10px;
  display: inline-flex;
  font-size: 18px;
  width: auto;
  flex: 1 1 70%;
  text-align: left;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section4 .solution-list:nth-of-type(4),
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section4 .solution-list:nth-of-type(5) {
  margin-bottom: 20px;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section4 .trdae-surveillance-list ul {
  margin: 25px 0px;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section4 .trdae-surveillance-list ul li {
  line-height: 26px;
  color: #fff;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section4 .trdae-surveillance-list ul li p {
  margin: 10px 0px;
  color: #fff;
  font-size: 15px;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section5 {
  background: #121212;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section5 .left-info-hold .main-head-div h4 span.primary-span {
  text-align: center;
  font-size: 40px;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section5 .left-info-hold .main-head-div p {
  color: #fff;
  letter-spacing: 0.5px;
  font-weight: 600;
  font-family: 'Object Sans';
  line-height: 30px;
  text-align: center;
  font-size: 16px;
  margin-top: 30px;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section5 .compilance-section {
  display: inline-flex;
  flex-wrap: wrap;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section5 .compilance-section .compilance-list {
  margin: 20px auto;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section5 .compilance-section .compilance-list i img {
  width: 50px;
  height: 50px;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section5 .compilance-section .compilance-list span {
  font-size: 18px;
  line-height: 30px;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section5 .compilance-section .compilance-list p {
  color: #fff;
  font-size: 14px;
  text-align: center;
  max-width: 60%;
  margin: 10px auto;
  min-height: 80px;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section5 .section-2.white-bg .left-info-hold .main-head-div h2 .primary-span {
  text-align: center;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section5 .section-2.white-bg .left-info-hold .main-head-div P {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section6 .section-2.white-bg {
  background: transparent;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section6 .section-2.white-bg .left-info-hold .main-head-div h3 .primary-span {
  color: #fff;
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  width: 60%;
  margin: 0 auto 30px;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section6 .section-2.white-bg .left-info-hold .main-head-div h4 .primary-span {
  font-size: 40px;
  color: #24A989;
  font-family: 'Object Sans';
  text-align: center;
  font-weight: 600;
  margin: 30px 0;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section6 .section-2.white-bg .left-info-hold .main-head-div P {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  margin-top: 0;
  line-height: 24px;
  color: #24A989;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section6 .section-2.white-bg .content-section > div:first-child {
  width: 100%;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section6 .section-2.white-bg .content-section > div .solution-list-item .solution-card-data strong,
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section6 .section-2.white-bg .content-section > div .solution-list-item .solution-card-data span {
  color: #fff;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section6 .marketting-teams h4 .primary-span {
  font-size: 40px;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section7 .section-2.white-bg {
  background: linear-gradient(90deg, #FFFFFF 36.46%, rgba(241, 241, 241, 0) 78.89%);
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section7 .section-2.white-bg .left-info-hold .main-head-div {
  margin-bottom: 0;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section7 .section-2.white-bg .left-info-hold .main-head-div h4 .primary-span {
  color: #24A989;
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  width: 60%;
  margin: 0 auto;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section7 .section-2.white-bg .left-info-hold .main-head-div P {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  margin-top: 0;
  line-height: 24px;
  color: #24A989;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section7 .section-2.white-bg .content-section > div.solution-list {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 10px 32px 72px 0px rgba(37, 129, 107, 0.25);
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section7 .section-2.white-bg .content-section > div.solution-list i {
  background: linear-gradient(90deg, #103744 0%, #1C7E67 100%);
  border: none;
  border-radius: 50%;
  box-shadow: none;
  width: 50px;
  height: 50px;
  margin: 20px auto 0;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section7 .section-2.white-bg .content-section > div.solution-list i img {
  max-width: 100%;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section7 .section-2.white-bg .content-section > div.solution-list strong {
  color: #1B1D21;
  line-height: 22px;
  font-weight: 500;
  font-size: 18px;
  font-family: 'Object Sans';
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section7 .section-2.white-bg .content-section > div.solution-list p {
  color: #65646B;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section8 .section-7 .left-info-hold .main-head-div {
  margin-bottom: 0px;
  display: initial;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section8 .section-7 .left-info-hold .main-head-div h4 .primary-span {
  color: #24A989;
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  width: 60%;
  margin: 0 auto;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section8 .section-7 .left-info-hold .main-head-div P {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  margin-top: 0;
  line-height: 24px;
  color: #24A989;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section8 .section-7 .solution-list-item {
  width: 100%;
  margin: 30px 0px;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section9 .section-2.white-bg {
  background: linear-gradient(90deg, #103744 0%, #1C7E67 100%);
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section9 .section-2.white-bg .left-info-hold .main-head-div h2 .primary-span {
  color: #fff;
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  width: 60%;
  margin: 0 auto;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section9 .section-2.white-bg .left-info-hold .main-head-div h4 .primary-span {
  font-size: 22px;
  color: #fff;
  font-family: 'Object Sans';
  text-align: center;
  font-weight: 600;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section9 .section-2.white-bg .left-info-hold .main-head-div P {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  margin-top: 0;
  line-height: 24px;
  color: #fff;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section9 .section-2.white-bg .solution-list-item .solution-card-data strong,
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section9 .section-2.white-bg .solution-list-item .solution-card-data span {
  color: #fff;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section9 .section-2.white-bg .solution-list-item .solution-card-data span {
  min-height: 120px;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-2.grey-fintech-bg h1 {
  margin: 0px auto!important;
  margin-bottom: 30px!important;
  font-size: 38px;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-2.grey-fintech-bg h1,
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-2.grey-fintech-bg p {
  font-size: 40px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 40px;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-2.grey-fintech-bg h1.main-head-1 .primary-span {
  font-size: 40px;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-2.grey-fintech-bg p {
  font-size: 18px;
  line-height: 34px;
  margin-bottom: 20px;
  font-weight: 400;
  color: #000000;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-2.grey-fintech-bg .team-detail {
  text-align: left;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-2.grey-fintech-bg .team-detail .Book-a-demo,
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-2.grey-fintech-bg .team-detail .consultation {
  font-size: 22px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: center;
  background: linear-gradient(90deg, #103744 0%, #1C7E67 100%);
  color: #fff;
  outline: none;
  border: 0;
  margin: 30px 0;
  margin-right: 40px;
  padding: 5px 29px;
  min-width: 280px;
  border: 1px solid #1C7E67;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-2.grey-fintech-bg .team-detail .consultation {
  background: transparent;
  border: 1px solid #1C7E67;
  color: #1C7E67;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-2.white-bg .main-head-div.custom-main-head-div h2 .primary-span,
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-2.white-bg .main-head-div.custom-main-head-div p {
  font-size: 40px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: left;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-2.white-bg .main-head-div.custom-main-head-div p {
  font-size: 18px;
  line-height: 36px;
  margin: 30px 0;
  font-weight: 400;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-2.white-bg .list-first-content .solution-list-item {
  max-width: 45%;
  margin: 20px auto 50px;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-2.white-bg .solution-list-item {
  display: flex;
  flex-flow: nowrap;
  align-items: flex-start;
  width: 85%;
  margin-bottom: 40px;
  border-bottom: 1px solid #DEDEDE;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-2.white-bg .solution-list-item .oms-img-tag img {
  width: 50px;
  margin: 0px 30px 0 0px;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-2.white-bg .solution-list-item .solution-card-data strong,
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-2.white-bg .solution-list-item .solution-card-data span {
  display: block;
  margin: 0 0 20px 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0.02em;
  text-align: left;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-2.white-bg .solution-list-item .solution-card-data strong {
  font-family: "Object Sans";
  font-size: 16px;
  line-height: 30px;
  margin: 0 0 10px;
  color: #1B1D21;
  font-weight: 600;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-2.white-bg .solution-list-item .solution-card-data span {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #65646B;
  min-height: 80px;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-7.financial-industries {
  background: linear-gradient(90deg, #103744 0%, #1C7E67 100%);
  padding: 50px 0px;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-7.financial-industries .main-head-div h3 span {
  font-size: 40px;
  font-weight: 700;
  line-height: 70px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #ffcc4a;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-7.financial-industries .main-head-div p {
  font-size: 17px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #fff;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-7.financial-industries .health-blogs {
  margin: 50px 0;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-7.financial-industries .health-blogs .health-blog-list {
  border-radius: 15px;
  background: transparent;
  border: 1px solid #FFFFFF;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-7.financial-industries .health-blogs .health-blog-list strong {
  color: #fff;
  font-family: "Object Sans Heavy";
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.02em;
  text-align: left;
  font-weight: 700;
  justify-content: flex-start;
  display: flex;
  align-items: center;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-7.financial-industries .health-blogs .health-blog-list p {
  color: #fff;
  font-size: 17px;
  font-family: Montserrat;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-7.financial-industries .slick-prev {
  top: 120%;
  left: -80px;
  right: 0;
  margin: auto;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-7.financial-industries .slick-next {
  top: 120%;
  left: 0;
  right: -90px !important;
  margin: auto;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-5.white-bg {
  background: linear-gradient(90deg, #FFFBF1 0%, #F9F9F9 50%), #F9F9F9;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-5.white-bg .main-head-1 .primary-span {
  font-size: 40px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: left;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-5.white-bg .content-section {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-5.white-bg .content-section .solution-list-item {
  width: 18%;
  padding: 20px;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-5.white-bg .content-section .solution-list-item .solution-card-data span {
  display: block;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  margin: 10px 0;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-6 {
  background-color: #fff;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-6 h4.main-head-1.main-head-2 span {
  font-size: 40px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: left;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-6 .content-section.oms-sol-pro {
  width: 100%;
  margin: 0 auto;
  display: inline-flex;
  align-items: center;
  justify-self: center;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-6 .content-section.oms-sol-pro img {
  width: 80%;
  margin: auto;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-7 {
  background-color: #F4F4F4;
  background: linear-gradient(90deg, #FFFBF1 0%, #F9F9F9 50%), #F9F9F9;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-7.financial-industries .custom-containers {
  max-width: 100%;
  width: 100%;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-7 h3.main-head-1.main-head-2 span.primary-span {
  font-size: 40px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: left;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-7 h3.main-head-1.main-head-2 span.secondary-span {
  font-size: 30px;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-7 h2.main-head-1.main-head-2 .secondary-span {
  font-size: 30px;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-7 .solution-list-item {
  width: 85%;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-7 .solution-list-item strong {
  width: 100%;
  background: #FFFFFF;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 170px;
  border-radius: 15px;
  margin-bottom: 20px;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-7 .solution-list-item p,
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-7 .solution-list-item a {
  font-size: 17px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.02em;
  text-align: left;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-7 .solution-list-item a {
  font-weight: 700;
  color: #1C7E67;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-8 {
  background: linear-gradient(90deg, #103744 0%, #1C7E67 100%);
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-8 h2.main-head-1.main-head-2 .primary-span {
  font-size: 45px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #ffcc4a;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-8 p {
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
  color: #fff;
  margin: 10px auto;
  opacity: 0.9;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-8 .solution-list-item {
  width: 80%;
  margin-bottom: 60px;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-8 .solution-list-item strong {
  display: block;
  padding: 0;
  font-size: 22px;
  line-height: 30px;
  color: #fff;
  font-family: "Object Sans Heavy";
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-8 .solution-list-item p {
  font-size: 18px;
  text-align: left;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-9 .custom-container {
  background: transparent;
  padding: 70px 0;
  margin: 0 auto;
  max-width: 85%;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-9 .custom-container h3.main-head-1.main-head-2 span {
  font-size: 40px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #24A989;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-9 .custom-container p {
  font-size: 18px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
  color: #444544;
  margin-top: 40px;
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .section-10 {
  padding: 100px 0;
  background: linear-gradient(90deg, #eefefa, #fdfdfd);
}
.fintech-updaed-design.Enterprise.Healthcare.trade-surveillance a.connect-btn.btn-primary {
  background: #FFCC49 !important;
  color: #000000 !important;
  border: 1px solid #FFCC49 !important;
}
@media only screen and (max-width: 1456px) and (min-width: 1025px) {
  .fintech-updaed-design.Enterprise.Healthcare .section-1 .main-para-div.short-div ul {
    width: 100%;
  }
  .fintech-updaed-design.Enterprise.Healthcare .section-1 .main-para-div.short-div ul li {
    width: 25%;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 320px) {
  .fintech-updaed-design.Enterprise.Healthcare .section-1 {
    height: 100vh;
  }
  .fintech-updaed-design.Enterprise.Healthcare .section-1 .main-para-div.short-div ul {
    width: 100%;
    font-size: 20px;
  }
  .fintech-updaed-design.Enterprise.Healthcare .section-2.white-bg .main-head-div.custom-main-head-div h2 .primary-span,
  .fintech-updaed-design.Enterprise.Healthcare .section-2.grey-fintech-bg h2,
  .fintech-updaed-design.Enterprise.Healthcare .financial-industries .main-head-div h3 span,
  .fintech-updaed-design.Enterprise.Healthcare .section-5.white-bg .main-head-1 .primary-span,
  .fintech-updaed-design.Enterprise.Healthcare .section-6 h2.main-head-1.main-head-2 span,
  .fintech-updaed-design.Enterprise.Healthcare .section-7 h2.main-head-1.main-head-2 span,
  .fintech-updaed-design.Enterprise.Healthcare .section-8 h2.main-head-1.main-head-2 .primary-span,
  .fintech-updaed-design.Enterprise.Healthcare .section-9 .custom-container h2.main-head-1.main-head-2 span,
  .fintech-updaed-design.Enterprise.Healthcare .section-1 h1.main-head-1.custom-htech-head .primary-span {
    font-size: 40px;
  }
  .fintech-updaed-design.Enterprise.Healthcare .section-7 h2.main-head-1.main-head-2 .secondary-span {
    font-size: 26px;
  }
  .fintech-updaed-design.Enterprise.Healthcare .section-1 h2 {
    font-size: 22px;
  }
  .fintech-updaed-design.Enterprise.Healthcare p,
  .fintech-updaed-design.Enterprise.Healthcare .section-2.white-bg .main-head-div.custom-main-head-div p,
  .fintech-updaed-design.Enterprise.Healthcare .section-2.white-bg .solution-list-item .solution-card-data span,
  .fintech-updaed-design.Enterprise.Healthcare .financial-industries .main-head-div p,
  .fintech-updaed-design.Enterprise.Healthcare .financial-industries .health-blogs .health-blog-list strong,
  .fintech-updaed-design.Enterprise.Healthcare .section-5.white-bg .content-section .solution-list-item .solution-card-data span,
  .fintech-updaed-design.Enterprise.Healthcare .section-7 .solution-list-item p,
  .fintech-updaed-design.Enterprise.Healthcare .section-7 .solution-list-item a,
  .fintech-updaed-design.Enterprise.Healthcare .section-8 p,
  .fintech-updaed-design.Enterprise.Healthcare .section-8 .solution-list-item p,
  .fintech-updaed-design.Enterprise.Healthcare .section-9 .custom-container p,
  .fintech-updaed-design.Enterprise.Healthcare .MuiAccordionSummary-content .MuiAccordionDetails-root.faq-details {
    font-size: 18px;
    line-height: 40px;
  }
  .fintech-updaed-design.Enterprise.Healthcare .financial-industries .health-blogs .health-blog-list p {
    font-size: 16px;
  }
  .fintech-updaed-design.Enterprise.Healthcare .team-detail button,
  .fintech-updaed-design.Enterprise.Healthcare .section-8 .solution-list-item strong,
  .fintech-updaed-design.Enterprise.Healthcare .MuiAccordionSummary-content h2.summary-content span,
  .fintech-updaed-design.Enterprise.Healthcare .section-2.grey-fintech-bg .team-detail .Book-a-demo,
  .fintech-updaed-design.Enterprise.Healthcare .section-2.grey-fintech-bg .team-detail .consultation {
    font-size: 20px;
  }
  .fintech-updaed-design.Enterprise.Healthcare .section-8 .solution-list-item,
  .fintech-updaed-design.Enterprise.Healthcare .section-2.white-bg .solution-list-item {
    width: 100%;
  }
  .fintech-updaed-design.Enterprise.Healthcare .section-2.white-bg .solution-list-item,
  .fintech-updaed-design.Enterprise.Healthcare .section-7 .solution-list-item {
    width: 100%;
  }
  .fintech-updaed-design.Enterprise.Healthcare .section-2.white-bg .solution-list-item .solution-card-data strong,
  .fintech-updaed-design.Enterprise.Healthcare .section-7 .solution-list-item .solution-card-data strong {
    font-size: 22px;
    line-height: 35px;
  }
  .fintech-updaed-design.Enterprise.Healthcare .section-2.white-bg .solution-list-item .solution-card-data span,
  .fintech-updaed-design.Enterprise.Healthcare .section-7 .solution-list-item .solution-card-data span {
    font-size: 18px;
  }
  .fintech-updaed-design.Enterprise.Healthcare .section-5.white-bg .content-section .solution-list-item {
    width: 30%;
  }
  .fintech-updaed-design.Enterprise.Healthcare .financial-industries .health-blogs {
    margin-bottom: 100px;
  }
}
@media only screen and (max-width: 991px) and (min-width: 320px) {
  .fintech-updaed-design.Enterprise.Healthcare .section-1 {
    background: linear-gradient(101.06deg, #0E2E40 0%, #145554 42.87%, #208F71 99.96%) !important;
  }
  .fintech-updaed-design.Enterprise.Healthcare .section-1 .main-para-div.short-div ul {
    font-size: 18px;
  }
}
@media only screen and (max-width: 768px) and (min-width: 320px) {
  .fintech-updaed-design.Enterprise.Healthcare .section-1 .main-para-div.short-div ul {
    font-size: 17px;
  }
  .fintech-updaed-design.Enterprise.Healthcare .team-detail button,
  .fintech-updaed-design.Enterprise.Healthcare .section-8 .solution-list-item strong,
  .fintech-updaed-design.Enterprise.Healthcare .MuiAccordionSummary-content h2.summary-content span,
  .fintech-updaed-design.Enterprise.Healthcare .section-2.grey-fintech-bg .team-detail .Book-a-demo,
  .fintech-updaed-design.Enterprise.Healthcare .section-2.grey-fintech-bg .team-detail .consultation {
    font-size: 18px !important;
  }
}
@media only screen and (max-width: 767px) and (min-width: 320px) {
  .fintech-updaed-design.Enterprise.Healthcare .section-2.white-bg .main-head-div.custom-main-head-div h2 .primary-span,
  .fintech-updaed-design.Enterprise.Healthcare .section-2.grey-fintech-bg h2,
  .fintech-updaed-design.Enterprise.Healthcare .financial-industries .main-head-div h3 span,
  .fintech-updaed-design.Enterprise.Healthcare .section-5.white-bg .main-head-1 .primary-span,
  .fintech-updaed-design.Enterprise.Healthcare .section-6 h2.main-head-1.main-head-2 span,
  .fintech-updaed-design.Enterprise.Healthcare .section-7 h2.main-head-1.main-head-2 span,
  .fintech-updaed-design.Enterprise.Healthcare .section-8 h2.main-head-1.main-head-2 .primary-span,
  .fintech-updaed-design.Enterprise.Healthcare .section-9 .custom-container h2.main-head-1.main-head-2 span,
  .fintech-updaed-design.Enterprise.Healthcare .section-1 h1.main-head-1.custom-htech-head .primary-span {
    font-size: 36px;
    line-height: 60px;
  }
  .fintech-updaed-design.Enterprise.Healthcare p,
  .fintech-updaed-design.Enterprise.Healthcare .section-2.white-bg .main-head-div.custom-main-head-div p,
  .fintech-updaed-design.Enterprise.Healthcare .section-2.white-bg .solution-list-item .solution-card-data span,
  .fintech-updaed-design.Enterprise.Healthcare .financial-industries .main-head-div p,
  .fintech-updaed-design.Enterprise.Healthcare .financial-industries .health-blogs .health-blog-list strong,
  .fintech-updaed-design.Enterprise.Healthcare .section-5.white-bg .content-section .solution-list-item .solution-card-data span,
  .fintech-updaed-design.Enterprise.Healthcare .section-7 .solution-list-item p,
  .fintech-updaed-design.Enterprise.Healthcare .section-7 .solution-list-item a,
  .fintech-updaed-design.Enterprise.Healthcare .section-8 p,
  .fintech-updaed-design.Enterprise.Healthcare .section-8 .solution-list-item p,
  .fintech-updaed-design.Enterprise.Healthcare .section-9 .custom-container p,
  .fintech-updaed-design.Enterprise.Healthcare .MuiAccordionSummary-content .MuiAccordionDetails-root.faq-details {
    font-size: 16px !important;
  }
  .fintech-updaed-design.Enterprise.Healthcare .section-2.grey-fintech-bg .team-detail .Book-a-demo,
  .fintech-updaed-design.Enterprise.Healthcare .section-2.grey-fintech-bg .team-detail .consultation {
    margin-bottom: 10px;
    min-width: 200px;
    padding: 0 20px;
  }
  .fintech-updaed-design.Enterprise.Healthcare .section-1 h2,
  .fintech-updaed-design.Enterprise.Healthcare .financial-industries .health-blogs .health-blog-list strong {
    font-size: 20px !important;
    margin: 0;
  }
  .fintech-updaed-design.Enterprise.Healthcare .team-detail {
    width: 100%;
  }
  .fintech-updaed-design.Enterprise.Healthcare .section-7 .solution-list-item {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 50px;
  }
  .fintech-updaed-design.Enterprise.Healthcare .section-5.white-bg .content-section .solution-list-item {
    width: 40%;
  }
}
@media only screen and (max-width: 567px) and (min-width: 320px) {
  .fintech-updaed-design.Enterprise.Healthcare .section-2.white-bg .main-head-div.custom-main-head-div h2 .primary-span,
  .fintech-updaed-design.Enterprise.Healthcare .section-2.grey-fintech-bg h2,
  .fintech-updaed-design.Enterprise.Healthcare .financial-industries .main-head-div h3 span,
  .fintech-updaed-design.Enterprise.Healthcare .section-5.white-bg .main-head-1 .primary-span,
  .fintech-updaed-design.Enterprise.Healthcare .section-6 h2.main-head-1.main-head-2 span,
  .fintech-updaed-design.Enterprise.Healthcare .section-7 h2.main-head-1.main-head-2 span,
  .fintech-updaed-design.Enterprise.Healthcare .section-8 h2.main-head-1.main-head-2 .primary-span,
  .fintech-updaed-design.Enterprise.Healthcare .section-9 .custom-container h2.main-head-1.main-head-2 span,
  .fintech-updaed-design.Enterprise.Healthcare .section-1 h1.main-head-1.custom-htech-head .primary-span {
    font-size: 30px;
    line-height: 45px;
  }
  .fintech-updaed-design.Enterprise.Healthcare .section-1 h2,
  .fintech-updaed-design.Enterprise.Healthcare .financial-industries .health-blogs .health-blog-list strong {
    font-size: 16px !important;
    margin: 0;
  }
  .fintech-updaed-design.Enterprise.Healthcare .section-1 .main-para-div.short-div ul {
    font-size: 13px;
    flex-flow: row wrap;
  }
  .fintech-updaed-design.Enterprise.Healthcare .section-1 .main-para-div.short-div ul li {
    width: 40%;
  }
  .fintech-updaed-design.Enterprise.Healthcare .section-1 .main-para-div.short-div ul li::before {
    display: none;
  }
  .fintech-updaed-design.Enterprise.Healthcare p,
  .fintech-updaed-design.Enterprise.Healthcare .section-2.white-bg .main-head-div.custom-main-head-div p,
  .fintech-updaed-design.Enterprise.Healthcare .section-2.white-bg .solution-list-item .solution-card-data span,
  .fintech-updaed-design.Enterprise.Healthcare .financial-industries .main-head-div p,
  .fintech-updaed-design.Enterprise.Healthcare .financial-industries .health-blogs .health-blog-list strong,
  .fintech-updaed-design.Enterprise.Healthcare .section-5.white-bg .content-section .solution-list-item .solution-card-data span,
  .fintech-updaed-design.Enterprise.Healthcare .section-7 .solution-list-item p,
  .fintech-updaed-design.Enterprise.Healthcare .section-7 .solution-list-item a,
  .fintech-updaed-design.Enterprise.Healthcare .section-8 p,
  .fintech-updaed-design.Enterprise.Healthcare .section-8 .solution-list-item p,
  .fintech-updaed-design.Enterprise.Healthcare .section-9 .custom-container p,
  .fintech-updaed-design.Enterprise.Healthcare .MuiAccordionSummary-content .MuiAccordionDetails-root.faq-details {
    font-size: 14px !important;
    line-height: 26px;
  }
  .fintech-updaed-design.Enterprise.Healthcare .section-2.grey-fintech-bg .team-detail .Book-a-demo,
  .fintech-updaed-design.Enterprise.Healthcare .section-2.grey-fintech-bg .team-detail .consultation {
    font-size: 15px !important;
  }
  .fintech-updaed-design.Enterprise.Healthcare .section-1 h2,
  .fintech-updaed-design.Enterprise.Healthcare .financial-industries .health-blogs .health-blog-list strong {
    font-size: 16px !important;
  }
  .fintech-updaed-design.Enterprise.Healthcare .section-5.white-bg .content-section .solution-list-item {
    width: 45%;
    box-shadow: 0 0 10px #ddd;
    margin-bottom: 30px;
    border-radius: 4px;
  }
  .fintech-updaed-design.Enterprise.Healthcare .section-5.white-bg .content-section .solution-list-item .solution-card-data span img {
    width: auto;
  }
  .fintech-updaed-design.Enterprise.Healthcare .section-8.grey-bg .content-section.row {
    width: 100%;
    margin-left: 0px;
  }
  .fintech-updaed-design.Enterprise.Healthcare .section-9.grey-bg .content-section {
    margin-left: 15px;
  }
  .fintech-updaed-design.Enterprise.Healthcare.trade-surveillance .newcamp-section4 .solution-list {
    max-width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .fintech-updaed-design.Enterprise.Healthcare .main-para-div.short-div ul {
    justify-content: space-evenly;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    overflow-x: auto;
  }
  .fintech-updaed-design.Enterprise.Healthcare .main-para-div.short-div ul li {
    justify-content: space-evenly;
    margin: 10px auto;
  }
  .fintech-updaed-design.Enterprise.Healthcare.cap-market .section-1 {
    height: 100%;
  }
  .fintech-updaed-design.Enterprise.Healthcare.cap-market .section-1::before {
    background-size: cover;
  }
  .fintech-updaed-design.Enterprise.Healthcare.cap-market .newcamp-section3 {
    padding: 0!important;
  }
  .fintech-updaed-design.Enterprise.Healthcare.cap-market .newcamp-section2 .title-center h3 {
    font-size: 25px;
    line-height: 35px;
    width: 100%;
  }
  .fintech-updaed-design.Enterprise.Healthcare.cap-market .newcamp-section3 .content-section .solution-list {
    min-height: 140px;
    min-width: 50%;
    padding: 30px;
  }
  .fintech-updaed-design.Enterprise.Healthcare.cap-market .newcamp-section3 .content-section .solution-list p {
    max-width: 90%;
    line-height: 24px;
  }
  .fintech-updaed-design.Enterprise.Healthcare.trade-automation .newcamp-section4 .left-info-hold .main-head-div h4 span.primary-span,
  .fintech-updaed-design.Enterprise.Healthcare.trade-automation .newcamp-section5 .section-2.white-bg .left-info-hold .main-head-div h2 .primary-span,
  .fintech-updaed-design.Enterprise.Healthcare.trade-automation .newcamp-section6 .section-2.white-bg .left-info-hold .main-head-div h3 .primary-span,
  .fintech-updaed-design.Enterprise.Healthcare.trade-automation .newcamp-section7 .section-2.white-bg .left-info-hold .main-head-div h4 .primary-span,
  .fintech-updaed-design.Enterprise.Healthcare.trade-automation .newcamp-section8 .section-7 .left-info-hold .main-head-div h4 .primary-span,
  .fintech-updaed-design.Enterprise.Healthcare.trade-automation .newcamp-section9 .section-2.white-bg .left-info-hold .main-head-div h3 .primary-span {
    font-size: 32px;
    line-height: 36px;
  }
  .fintech-updaed-design.Enterprise.Healthcare.trade-automation .newcamp-section2 .title-center h1 span,
  .fintech-updaed-design.Enterprise.Healthcare.trade-automation .newcamp-section2 .title-center h2 Span,
  .fintech-updaed-design.Enterprise.Healthcare.trade-automation .newcamp-section3 .section-2.white-bg .main-head-div.custom-main-head-div h2 span.primary-span,
  .fintech-updaed-design.Enterprise.Healthcare.trade-automation .newcamp-section5 .left-info-hold .main-head-div h4 span.primary-span,
  .fintech-updaed-design.Enterprise.Healthcare.trade-automation .newcamp-section6 .section-2.grey-fintech-bg h4 .primary-span {
    font-size: 32px;
    line-height: 36px;
  }
  .fintech-updaed-design.Enterprise.Healthcare.cap-market .section-2.white-bg .solution-list-item {
    width: 100%;
  }
  .fintech-updaed-design.Enterprise.Healthcare.cap-market .newcamp-section7 .section-2.white-bg .content-section > div.solution-list {
    flex: 1 1 100%;
    max-width: 100%;
  }
  .fintech-updaed-design.Enterprise.Healthcare.trade-automation .newcamp-section5 .compilance-section .compilance-list span br {
    display: block!important;
  }
}
@media only screen and (max-width: 425px) and (min-width: 320px) {
  .fintech-updaed-design.Enterprise.Healthcare .section-2.white-bg .main-head-div.custom-main-head-div h2 .primary-span,
  .fintech-updaed-design.Enterprise.Healthcare .section-2.grey-fintech-bg h2,
  .fintech-updaed-design.Enterprise.Healthcare .financial-industries .main-head-div h3 span,
  .fintech-updaed-design.Enterprise.Healthcare .section-5.white-bg .main-head-1 .primary-span,
  .fintech-updaed-design.Enterprise.Healthcare .section-6 h2.main-head-1.main-head-2 span,
  .fintech-updaed-design.Enterprise.Healthcare .section-7 h2.main-head-1.main-head-2 span,
  .fintech-updaed-design.Enterprise.Healthcare .section-8 h2.main-head-1.main-head-2 .primary-span,
  .fintech-updaed-design.Enterprise.Healthcare .section-9 .custom-container h2.main-head-1.main-head-2 span,
  .fintech-updaed-design.Enterprise.Healthcare .section-1 h1.main-head-1.custom-htech-head .primary-span {
    font-size: 26px;
    line-height: 36px;
  }
  .fintech-updaed-design.Enterprise.Healthcare .section-1 h2,
  .fintech-updaed-design.Enterprise.Healthcare .financial-industries .health-blogs .health-blog-list strong {
    font-size: 14px !important;
    line-height: 21px;
  }
  .fintech-updaed-design.Enterprise.Healthcare a.connect-btn.btn-primary {
    font-size: 20px;
  }
  .fintech-updaed-design.Enterprise.Healthcare .section-2.white-bg .solution-list-item {
    flex-flow: row wrap;
  }
  .fintech-updaed-design.Enterprise.Healthcare .section-2.white-bg .solution-list-item .solution-card-data strong,
  .fintech-updaed-design.Enterprise.Healthcare .section-7 .solution-list-item .solution-card-data strong {
    font-size: 18px;
    line-height: 28px;
  }
  .fintech-updaed-design.Enterprise.Healthcare p,
  .fintech-updaed-design.Enterprise.Healthcare .section-2.white-bg .main-head-div.custom-main-head-div p,
  .fintech-updaed-design.Enterprise.Healthcare .section-2.white-bg .solution-list-item .solution-card-data span,
  .fintech-updaed-design.Enterprise.Healthcare .financial-industries .main-head-div p,
  .fintech-updaed-design.Enterprise.Healthcare .financial-industries .health-blogs .health-blog-list strong,
  .fintech-updaed-design.Enterprise.Healthcare .section-5.white-bg .content-section .solution-list-item .solution-card-data span,
  .fintech-updaed-design.Enterprise.Healthcare .section-7 .solution-list-item p,
  .fintech-updaed-design.Enterprise.Healthcare .section-7 .solution-list-item a,
  .fintech-updaed-design.Enterprise.Healthcare .section-8 p,
  .fintech-updaed-design.Enterprise.Healthcare .section-8 .solution-list-item p,
  .fintech-updaed-design.Enterprise.Healthcare .section-9 .custom-container p,
  .fintech-updaed-design.Enterprise.Healthcare .MuiAccordionSummary-content .MuiAccordionDetails-root.faq-details {
    line-height: 30px;
  }
  .fintech-updaed-design.Enterprise.Healthcare .section-5.white-bg .content-section .solution-list-item {
    width: 85%;
    margin: 0 auto 30px auto;
  }
  .fintech-updaed-design.Enterprise.Healthcare .financial-industries .health-blogs .health-blog-list strong {
    font-size: 17px!important;
  }
  .fintech-updaed-design.Enterprise.Healthcare .section-2.white-bg .solution-list-item .oms-img-tag img {
    margin-bottom: 15px;
  }
  .Enterprise.fintech-updaed-design.Healthcare.oms .section-1 .main-head-1 .primary-span {
    font-size: 30px;
  }
  .Enterprise.fintech-updaed-design.Healthcare.oms .section-1 h2,
  .Enterprise.fintech-updaed-design.Healthcare.oms .section-1 .main-para-div {
    text-align: center;
  }
  .Enterprise.fintech-updaed-design.Healthcare.oms .section-1 .custom-section {
    padding-top: 90px;
  }
  .fintech-updaed-design.Enterprise.Healthcare .section-2.grey-fintech-bg h1.main-head-1 .primary-span,
  .fintech-updaed-design.Enterprise.Healthcare .section-7.financial-industries .main-head-div h3 span,
  .fintech-updaed-design.Enterprise.Healthcare .section-6 h4.main-head-1.main-head-2 span,
  .fintech-updaed-design.Enterprise.Healthcare .section-7 h3.main-head-1.main-head-2 span.primary-span,
  .fintech-updaed-design.Enterprise.Healthcare .section-9 .custom-container h3.main-head-1.main-head-2 span {
    font-size: 26px;
    line-height: 36px;
  }
  .Enterprise.Healthcare.oms .custom-container .left-info-hold .main-head-1.main-head-2 .secondary-span {
    font-size: 16px;
    line-height: 26px;
  }
  .fintech-updaed-design.Enterprise.Healthcare .section-6 .content-section.oms-sol-pro img {
    width: 100%;
  }
  .fintech-updaed-design.Enterprise.Healthcare .main-para-div.short-div ul li a {
    margin: 0px 10px;
    font-size: 12px;
    line-height: 20px;
    text-align: left;
  }
  .fintech-updaed-design.Enterprise.Healthcare .main-para-div.short-div ul li::before {
    opacity: 0;
  }
  .fintech-updaed-design.Enterprise.Healthcare.cap-market .section-1 h1.main-head-1.custom-htech-head .primary-span,
  .fintech-updaed-design.Enterprise.Healthcare.cap-market .section-1 h1.main-head-1.custom-htech-head .secondary-span {
    font-size: 30px;
    line-height: 43px;
  }
  .fintech-updaed-design.Enterprise.Healthcare.cap-market .section-1 h2 {
    text-align: center;
  }
  .fintech-updaed-design.Enterprise.Healthcare.cap-market .newcamp-section6 .section-2.white-bg .left-info-hold .main-head-div h4 .primary-span {
    font-size: 18px;
    line-height: 30px;
  }
  .fintech-updaed-design.Enterprise.Healthcare.cap-market .newcamp-section2 .title-center div.subheading,
  .fintech-updaed-design.Enterprise.Healthcare.cap-market .newcamp-section2 .title-center div.subheading h1 {
    font-size: 28px;
    line-height: 36px;
    width: 100%;
    margin: auto;
  }
  .fintech-updaed-design.Enterprise.Healthcare.cap-market .newcamp-section4 .left-info-hold .main-head-div h4 span.primary-span,
  .fintech-updaed-design.Enterprise.Healthcare.cap-market .newcamp-section5 .section-2.white-bg .left-info-hold .main-head-div h2 .primary-span,
  .fintech-updaed-design.Enterprise.Healthcare.cap-market .newcamp-section6 .section-2.white-bg .left-info-hold .main-head-div h3 .primary-span,
  .fintech-updaed-design.Enterprise.Healthcare.cap-market .newcamp-section7 .section-2.white-bg .left-info-hold .main-head-div h4 .primary-span,
  .fintech-updaed-design.Enterprise.Healthcare.cap-market .newcamp-section8 .section-7 .left-info-hold .main-head-div h4 .primary-span,
  .fintech-updaed-design.Enterprise.Healthcare.cap-market .newcamp-section9 .section-2.white-bg .left-info-hold .main-head-div h3 .primary-span {
    font-size: 28px;
    line-height: 36px;
    width: 100%;
  }
  .fintech-updaed-design.Enterprise.Healthcare.cap-market .section-2.white-bg .list-first-content .solution-list-item {
    max-width: 100%;
    margin: 20px auto 50px;
  }
  .newcamp-section8 .row.work-with-row,
  .newcamp-section8 .section-7 .content-section.row {
    width: 100%;
    margin: auto;
  }
  .fintech-updaed-design.Enterprise.Healthcare.trade-automation .newcamp-section2 .title-center h1 span,
  .fintech-updaed-design.Enterprise.Healthcare.trade-automation .newcamp-section2 .title-center h2 Span,
  .fintech-updaed-design.Enterprise.Healthcare.trade-automation .newcamp-section3 .section-2.white-bg .main-head-div.custom-main-head-div h2 span.primary-span,
  .fintech-updaed-design.Enterprise.Healthcare.trade-automation .newcamp-section5 .left-info-hold .main-head-div h4 span.primary-span,
  .fintech-updaed-design.Enterprise.Healthcare.trade-automation .newcamp-section6 .section-2.grey-fintech-bg h4 .primary-span {
    font-size: 28px;
    line-height: 36px;
  }
}
@media only screen and (max-width: 325px) {
  .fintech-updaed-design.Enterprise.Healthcare.cap-market .section-2.white-bg .solution-list-item .oms-img-tag img {
    width: 33px;
  }
}
@media only screen and (max-width: 1024px) {
  .fintech-updaed-design.Enterprise.Healthcare .section-1 {
    height: 100%;
  }
  .fintech-updaed-design.Enterprise.Healthcare.cap-market .newcamp-section2 .title-center div.subheading {
    width: 100%;
  }
  .fintech-updaed-design.Enterprise.Healthcare.cap-market .newcamp-section3 .content-section .solution-list {
    padding: 30px;
  }
}
