.wizard-wrapper {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
.wizard-wrapper .wizard-content {
  width: 100%;
}
.wizard-wrapper .wizard-content .count {
  position: absolute;
  right: 50px;
  top: 30px;
  color: #FFFFFF;
  font-size: 16px;
}
.wizard-wrapper .wizard-content .count b {
  font-family: "Object Sans Heavy";
}
.wizard-wrapper .wizard-content strong {
  color: #ffffff;
  font-size: 24px;
  font-weight: 800;
  font-family: "Heldane display";
  margin-bottom: 20px;
  display: flex;
}
.wizard-wrapper .wizard-content .wizard-detail {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
}
.wizard-wrapper .wizard-content .wizard-detail .err-msg {
  left: 0;
  bottom: -40px;
  background: #fff;
  padding: 2px 10px;
  border-radius: 5px;
  font-size: 0.9vw;
  margin-bottom: 15px;
}
.wizard-wrapper .wizard-content .wizard-detail .err-msg-info {
  position: absolute;
  bottom: -60px;
}
.wizard-wrapper .wizard-content .wizard-detail label {
  display: inline-flex;
  color: #BCC4C7;
  font-family: "Object Sans";
  margin-bottom: 0px;
}
.wizard-wrapper .wizard-content .wizard-detail textarea,
.wizard-wrapper .wizard-content .wizard-detail input,
.wizard-wrapper .wizard-content .wizard-detail select {
  background: #2E5961;
  border: 1px solid #FFFFFF;
  border-radius: 5px;
  display: block;
  width: 100%;
  height: 120px;
  margin: 15px 0px;
  padding: 15px;
  resize: none;
  color: #FDFDFD;
}
.wizard-wrapper .wizard-content .wizard-detail textarea::placeholder,
.wizard-wrapper .wizard-content .wizard-detail input::placeholder,
.wizard-wrapper .wizard-content .wizard-detail select::placeholder {
  color: #FDFDFD;
}
.wizard-wrapper .wizard-content .wizard-detail textarea:hover,
.wizard-wrapper .wizard-content .wizard-detail input:hover,
.wizard-wrapper .wizard-content .wizard-detail select:hover {
  background: #ffffff;
  color: #495057;
}
.wizard-wrapper .wizard-content .wizard-detail textarea:hover::placeholder,
.wizard-wrapper .wizard-content .wizard-detail input:hover::placeholder,
.wizard-wrapper .wizard-content .wizard-detail select:hover::placeholder {
  color: #495057;
}
.wizard-wrapper .wizard-content .wizard-detail textarea:focus,
.wizard-wrapper .wizard-content .wizard-detail input:focus,
.wizard-wrapper .wizard-content .wizard-detail select:focus {
  outline: none;
}
.wizard-wrapper .wizard-content .wizard-detail textarea.validation-err,
.wizard-wrapper .wizard-content .wizard-detail input.validation-err,
.wizard-wrapper .wizard-content .wizard-detail select.validation-err {
  border: 1px solid #f00;
}
.wizard-wrapper .wizard-content .wizard-detail input,
.wizard-wrapper .wizard-content .wizard-detail select {
  height: auto;
  padding: 15px;
  color: #FDFDFD;
}
.wizard-wrapper .wizard-content .wizard-detail input::placeholder,
.wizard-wrapper .wizard-content .wizard-detail select::placeholder {
  color: #FDFDFD;
}
.wizard-wrapper .wizard-content .wizard-detail .wizard-button {
  display: inline-flex;
  background: #FFCC4A;
  box-shadow: 0px 6px 10px rgba(30, 75, 94, 0.2);
  border-radius: 3px;
  color: #1E4B5E;
  font-family: "Object Sans Heavy";
  font-weight: 800;
  width: auto;
  border: 0px;
  padding: 10px 20px;
  min-width: 120px;
  text-align: center;
  justify-content: center;
}
.wizard-wrapper .wizard-content .wizard-detail .wizard-button:hover {
  box-shadow: 0px 2px 10px rgba(255, 255, 255, 0.4);
}
.wizard-wrapper .wizard-content .wizard-detail .wizard-bottom {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
}
.wizard-wrapper .wizard-content .wizard-detail .wizard-bottom span {
  color: #FFFFFF;
  text-decoration: underline;
  cursor: pointer;
}
.wizard-wrapper .wizard-content .wizard-detail .wizard-bottom .proceed-anyone {
  font-size: 14px;
  display: inline-block;
  padding-left: 15px;
  text-shadow: 0 0 #fff;
  text-decoration: none;
  cursor: auto;
  letter-spacing: 1px;
}
.wizard-wrapper .wizard-content .wizard-detail .wizard-bottom a.book-appointment {
  background: #24A989;
  color: #1E4B5E;
  font-family: "Object Sans Heavy";
  margin-left: 20px;
  padding: 10px 15px;
  box-shadow: 0px 6px 10px rgba(30, 75, 94, 0.2);
  border-radius: 3px;
}
.wizard-wrapper .wizard-content .wizard-detail .wizard-bottom a.book-appointment:hover {
  box-shadow: 0px 2px 10px rgba(255, 255, 255, 0.4);
}
.wizard-wrapper .wizard-content .wizard-detail .c-form-select-grp {
  width: 100%;
  margin: 15px 0px;
}
.wizard-wrapper .wizard-content .wizard-detail .c-form-select {
  display: block;
  width: 100%;
  height: 55px;
  padding: 15px 0px;
  line-height: 55px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  position: relative;
  background: #2E5961;
  border: 1px solid #FFFFFF;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.wizard-wrapper .wizard-content .wizard-detail .c-form-select::placeholder {
  color: #FDFDFD;
}
.wizard-wrapper .wizard-content .wizard-detail .contact-form-label {
  font-size: 16px;
  color: #1e4b5e;
  font-family: "Object Sans Heavy";
  margin-bottom: 10px;
}
.wizard-wrapper .wizard-content .wizard-detail .c-form-select .selected-value {
  line-height: 21px;
  text-indent: 10px;
  font-size: 16px;
  color: #FDFDFD;
  text-align: left;
}
.wizard-wrapper .wizard-content .wizard-detail .c-form-select .selected-value span {
  padding: 0px 5px;
}
.wizard-wrapper .wizard-content .wizard-detail .c-form-select .selected-value label {
  color: #FDFDFD;
}
.wizard-wrapper .wizard-content .wizard-detail .c-form-select .cfsel-drop-span {
  top: 24px;
}
.wizard-wrapper .wizard-content .wizard-detail .c-form-select .cfsel-drop-span svg path {
  fill: #fff;
}
.wizard-wrapper .wizard-content .wizard-detail .select-option {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  position: absolute;
  width: 100%;
  background: #fff;
  z-index: 2;
}
.wizard-wrapper .wizard-content .wizard-detail .select-option input {
  padding: 10px;
  font-size: 12px;
  margin: 0px;
}
.wizard-wrapper .wizard-content .wizard-detail .select-option input:hover {
  background: #2E5961;
  color: #FDFDFD;
}
.wizard-wrapper .wizard-content .wizard-detail .select-option input:hover::placeholder {
  color: #FDFDFD;
}
.wizard-wrapper .wizard-content .wizard-detail .select-option .d-block {
  padding: 6px 15px;
  color: #1E4B5E;
  font-size: 13px;
  cursor: pointer;
  font-family: "Object Sans";
  border: 1px solid transparent;
  text-align: left;
}
.wizard-wrapper .wizard-content .wizard-detail .select-option .d-block:hover {
  background: rgba(36, 169, 137, 0.1);
  border: 1px solid rgba(36, 169, 137, 0.4);
  font-weight: 800;
}
.wizard-wrapper .wizard-content .next-btn {
  position: absolute;
  bottom: -10px;
  right: 50px;
  background: #79C9BC;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 4px;
}
.wizard-wrapper .wizard-content .next-btn i {
  display: inline-flex;
  align-items: center;
  height: 7px;
  width: 7px;
}
.wizard-wrapper .wizard-content .prev-btn {
  position: absolute;
  bottom: -10px;
  right: 80px;
  background: #fff;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 4px;
}
.wizard-wrapper .wizard-content .prev-btn i {
  display: inline-flex;
  align-items: center;
  height: 7px;
  width: 7px;
}
.wizard-wrapper .wizard-content .prev-btn i svg {
  transform: rotate(180deg);
}
.skip-to-next {
  margin-left: auto;
}
@media only screen and (max-width: 480px) {
  .wizard-wrapper .wizard-content .wizard-detail .wizard-bottom {
    flex-direction: column;
  }
  .wizard-wrapper .wizard-content .wizard-detail .wizard-bottom .wizard-button {
    margin-bottom: 15px;
  }
  .wizard-wrapper .wizard-content .wizard-detail .wizard-bottom .proceed-anyone {
    padding-left: 0;
    padding-bottom: 13px;
  }
  .wizard-wrapper .wizard-content .wizard-detail .wizard-bottom a.book-appointment {
    margin-left: 0px;
  }
}
