.events-wrapper .events-inner {
  padding: 30px 0px;
  max-width: 85%;
  margin: 0px auto;
  position: relative;
  z-index: 1;
}
.events-wrapper .events-inner p {
  color: #fff;
  text-align: center;
  margin-bottom: 0px;
}
.events-wrapper .events-inner header.MuiAppBar-root {
  display: block !important;
}
.events-wrapper .events-inner .events-top-wrap {
  margin: 50px 0px;
}
.whitepaper-inner-content.events-main-wrapper {
  width: calc(100% - 200px);
}
.whitepaper-inner-content .latest-events,
.whitepaper-inner-content .past-events {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 50px;
}
.whitepaper-inner-content .latest-events h3,
.whitepaper-inner-content .past-events h3 {
  font-size: 18px;
  margin-bottom: 25px;
  display: block;
}
.whitepaper-inner-content .latest-events .whitepaper-card-wrapper,
.whitepaper-inner-content .past-events .whitepaper-card-wrapper {
  border: 2px inset #24A989;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  background: #fff;
  margin-bottom: 15px;
}
.whitepaper-inner-content .latest-events .whitepaper-card-wrapper .whitepaper-img,
.whitepaper-inner-content .past-events .whitepaper-card-wrapper .whitepaper-img {
  width: 30%;
  height: 165px;
  overflow: hidden;
  background: #D9D9D9;
}
.whitepaper-inner-content .latest-events .whitepaper-card-wrapper .whitepaper-img img,
.whitepaper-inner-content .past-events .whitepaper-card-wrapper .whitepaper-img img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}
.whitepaper-inner-content .latest-events .whitepaper-card-wrapper .whitepaper-content,
.whitepaper-inner-content .past-events .whitepaper-card-wrapper .whitepaper-content {
  width: 70%;
  padding: 0px 15px;
  display: flex;
  flex-wrap: wrap;
}
.whitepaper-inner-content .latest-events .whitepaper-card-wrapper .whitepaper-content .content-top,
.whitepaper-inner-content .past-events .whitepaper-card-wrapper .whitepaper-content .content-top {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.whitepaper-inner-content .latest-events .whitepaper-card-wrapper .whitepaper-content .content-top span,
.whitepaper-inner-content .past-events .whitepaper-card-wrapper .whitepaper-content .content-top span {
  color: #292D46;
  font-size: 14px;
  font-family: "Object Sans";
}
.whitepaper-inner-content .latest-events .whitepaper-card-wrapper .whitepaper-content .content-top span.keyword,
.whitepaper-inner-content .past-events .whitepaper-card-wrapper .whitepaper-content .content-top span.keyword {
  background: rgba(146, 242, 255, 0.4);
  padding: 0px 10px;
  border-radius: 5px;
  color: #292D46;
  font-weight: 400;
}
.whitepaper-inner-content .latest-events .whitepaper-card-wrapper .whitepaper-content .content-top strong,
.whitepaper-inner-content .past-events .whitepaper-card-wrapper .whitepaper-content .content-top strong {
  width: 100%;
  color: #292D46;
  font-size: 16px;
  margin: 5px 0px;
}
.whitepaper-inner-content .latest-events .whitepaper-card-wrapper .whitepaper-content .content-top a,
.whitepaper-inner-content .past-events .whitepaper-card-wrapper .whitepaper-content .content-top a {
  color: #005CD2;
  font-size: 14px;
  font-family: "Object Sans Heavy";
  width: auto;
  border-radius: 4px;
  display: inline-flex;
  text-decoration: underline;
}
.whitepaper-inner-content .latest-events .whitepaper-card-wrapper .whitepaper-content .content-top a i,
.whitepaper-inner-content .past-events .whitepaper-card-wrapper .whitepaper-content .content-top a i {
  margin-left: 5px;
}
.whitepaper-inner-content .latest-events .whitepaper-card-wrapper .whitepaper-content .content-top .card-detail,
.whitepaper-inner-content .past-events .whitepaper-card-wrapper .whitepaper-content .content-top .card-detail {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
}
.whitepaper-inner-content .latest-events .whitepaper-card-wrapper .whitepaper-content .content-top .card-info,
.whitepaper-inner-content .past-events .whitepaper-card-wrapper .whitepaper-content .content-top .card-info {
  margin: 0px;
  padding: 0px;
}
.whitepaper-inner-content .latest-events .whitepaper-card-wrapper .whitepaper-content .content-top .card-info li,
.whitepaper-inner-content .past-events .whitepaper-card-wrapper .whitepaper-content .content-top .card-info li {
  position: relative;
  padding-right: 15px;
  color: #17A4BCFC;
  list-style-type: none;
  display: inline-flex;
  font-size: 14px;
}
.whitepaper-inner-content .latest-events .whitepaper-card-wrapper .whitepaper-content .content-top .card-info li::after,
.whitepaper-inner-content .past-events .whitepaper-card-wrapper .whitepaper-content .content-top .card-info li::after {
  content: '|';
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
}
.whitepaper-inner-content .latest-events .whitepaper-card-wrapper .whitepaper-content .content-top .card-info li:last-child::after,
.whitepaper-inner-content .past-events .whitepaper-card-wrapper .whitepaper-content .content-top .card-info li:last-child::after {
  display: none;
}
.whitepaper-inner-content .latest-events .whitepaper-card-wrapper .whitepaper-content .content-bottom,
.whitepaper-inner-content .past-events .whitepaper-card-wrapper .whitepaper-content .content-bottom {
  display: flex;
  align-items: flex-end;
}
.whitepaper-inner-content .latest-events .whitepaper-card-wrapper .whitepaper-content .content-bottom em,
.whitepaper-inner-content .past-events .whitepaper-card-wrapper .whitepaper-content .content-bottom em {
  font-style: normal;
}
.whitepaper-inner-content .past-events {
  margin-bottom: 0px;
}
.whitepaper-inner-content .past-events .whitepaper-card-wrapper {
  margin-bottom: 30px;
}
.whitepaper-inner-content .past-events .whitepaper-card-wrapper:hover {
  border: 2px inset #FFD028;
}
.whitepaper-inner-content .past-events .whitepaper-card-wrapper:last-child {
  margin-bottom: 0px;
}
.whitepaper-inner-content .past-events .whitepaper-card-wrapper .event-img {
  width: 30%;
}
.whitepaper-inner-content .past-events .whitepaper-card-wrapper .event-img img {
  width: 100%;
}
.whitepaper-inner-content .past-events .whitepaper-card-wrapper .event-content {
  width: 70%;
  display: flex;
  align-items: flex-start;
}
.whitepaper-inner-content .past-events .whitepaper-card-wrapper .event-content span {
  width: 100%;
}
.whitepaper-inner-content .past-events .whitepaper-card-wrapper .event-content strong {
  width: 100%;
  margin: 15px 0px;
}
.news-wrapper .past-news .news-card-wrapper {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.news-wrapper .past-news .news-card-wrapper .news-card {
  width: 31%;
  padding: 20px;
  border-radius: 0px 0px 10px 10px;
  border: 2px solid #24A989;
  background: rgba(41, 45, 70, 0.5);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  margin-bottom: 2%;
}
.news-wrapper .past-news .news-card-wrapper .news-card .news-img {
  background: #D9D9D9;
  width: 100%;
  height: 165px;
}
.news-wrapper .past-news .news-card-wrapper .news-card .news-category label {
  border-radius: 5px;
  background: rgba(146, 242, 255, 0.4);
  padding: 5px 10px;
  margin-top: 15px;
  color: #292D46;
  text-align: center;
  font-family: "Object Sans";
  font-size: 14px;
}
.news-wrapper .past-news .news-card-wrapper .news-card .news-title {
  margin: 15px 0px;
}
.news-wrapper .past-news .news-card-wrapper .news-card .news-location {
  color: #D9D9D9;
  font-family: "Object Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 767px) {
  .whitepaper-inner-content.events-main-wrapper {
    width: 100%;
  }
  .whitepaper-inner-content .latest-events .whitepaper-card-wrapper,
  .whitepaper-inner-content .past-events .whitepaper-card-wrapper {
    flex-direction: column;
  }
  .whitepaper-inner-content .latest-events .whitepaper-card-wrapper .whitepaper-img,
  .whitepaper-inner-content .past-events .whitepaper-card-wrapper .whitepaper-img {
    width: 100%;
  }
  .whitepaper-inner-content .latest-events .whitepaper-card-wrapper .whitepaper-content,
  .whitepaper-inner-content .past-events .whitepaper-card-wrapper .whitepaper-content {
    width: 100%;
    padding: 0px;
    margin-top: 15px;
  }
  .resource-tab-wrapper .tab-content .MuiBox-root p {
    justify-content: center;
  }
  .events-wrapper .events-inner {
    max-width: 90%;
    margin: 0px auto;
  }
  .whitepaper-inner-content .latest-events .whitepaper-card-wrapper .whitepaper-content .content-top .card-detail,
  .whitepaper-inner-content .past-events .whitepaper-card-wrapper .whitepaper-content .content-top .card-detail {
    flex-direction: column;
    align-items: flex-start;
  }
}
.disable-click {
  pointer-events: none;
}
